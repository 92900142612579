import React from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import useAuthContext from 'helpers/UseAuthContext';
import styled from 'styled-components';
import Button from '@mui/material/Button';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 75vh;
  margin: 0 20rem;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

const LockIcon = styled.span`
  font-size: 12rem;

  @media (max-width: 768px) {
    line-height: 18rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  margin-right: 1rem;
  color: #666;

  @media (max-width: 768px) {
    max-width: 90%;
    margin-left: 0rem;
  }
`;

const ErrorText = styled.div`
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0 0 2rem 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

const Home = () => {
  const { isAnalyst, logOut } = useAuthContext();
  const navigate = useNavigate();

  const goToSignIn = () => {
    logOut().then(() => {
      navigate('/sign-in');
    });
  };

  if (isAnalyst()) {
    return <Navigate to={'/insights/home'} />;
  }

  return (
    <>
      <Container>
        <LockIcon role="img" aria-label="access-denied">
          &#128274;
        </LockIcon>
        <TextContainer>
          <ErrorText>
            Your account does not have valid permissions to view this tool. Please ask your account administrator to
            grant you analyst level permissions.
          </ErrorText>
          <Button onClick={goToSignIn}>Back to Sign In</Button>
        </TextContainer>
      </Container>
    </>
  );
};

export default Home;
