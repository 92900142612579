import React, { useCallback, useMemo, ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import AsyncSelect from 'react-select/async';
import UseCompanyContext from 'helpers/UseCompanyContext';
import customStyles from 'helpers/asyncSelectStylesHelper';
import RoleComparisonColumnSkillRow from 'components/molecules/RoleComparisonColumnSkillRow';
import RolesService from 'services/RolesService';
import BodyColumn from 'components/atoms/BodyColumn';
import { Role } from 'types/types';

const ComparisonColumnWrapper = styled.div`
  background-color: white;
  border-radius: ${props => props.theme.borderRadius.default};
  box-shadow: ${props => props.theme.boxShadow.surface1};
  display: flex;
  flex-direction: column;
  font-weight: 600;
  height: fit-content;
  min-height: 18rem;
  justify-content: flex-start;
  margin-bottom: 10px;
  overflow: hidden;
  width: 100%;
  margin: 10px;
`;

const ComparisonColumnHeaderBar = styled.div`
  background-color: ${props => props.color};
  height: 1rem;
  width: 100%;
`;

const ComparisonColumnHeader = styled.div`
  padding: ${props => props.theme.customSpacing.px.base * 4}px;
  display: block;
`;

type roleComparisonColumnProps = {
  position: number;
  comparedRoles: Role[];
  replaceRoleHandler: (value: any, position: number) => void;
  highlightedSkills: string[];
  setHighlightedSkillsHandler: (skillId: string) => void;
};

const noOptionsMessage = ({ inputValue }) => (inputValue ? 'no results' : '');

const RoleComparisonColumn = ({
  position = 0,
  comparedRoles = [{}, {}, {}],
  replaceRoleHandler,
  highlightedSkills = [],
  setHighlightedSkillsHandler,
}: roleComparisonColumnProps): ReactElement => {
  const theme = useTheme();
  const { companyId } = UseCompanyContext();

  const currentRole = comparedRoles[position] || { name: '', skills: [] };

  const skillsHashArray = useMemo(() => {
    return comparedRoles.map(r => {
      const skillsHash = {};
      if (r?.skills) {
        r.skills.forEach(s => {
          skillsHash[s.skillId] = true;
        });
      }
      return skillsHash;
    });
  }, [comparedRoles]);

  const getHitMatrix = useCallback(
    skillId => {
      return [
        skillsHashArray[0] ? skillsHashArray[0][skillId] : false,
        skillsHashArray[1] ? skillsHashArray[1][skillId] : false,
        skillsHashArray[2] ? skillsHashArray[2][skillId] : false,
      ];
    },
    [skillsHashArray]
  );

  const commonSkills = useMemo(() => {
    const returnSkills = currentRole.skills || [];

    return returnSkills.sort((a, b) => {
      const aHM = getHitMatrix(a.skillId);
      const bHM = getHitMatrix(b.skillId);
      let aScore = 0;
      let bScore = 0;

      a.required && (aScore += 1000);
      const numAMatches = aHM.filter(x => x === true).length;
      aScore += 10 ** numAMatches;

      b.required && (bScore += 1000);
      const numBMatches = bHM.filter(x => x === true).length;
      bScore += 10 ** numBMatches;

      return bScore > aScore ? 1 : -1;
    });
  }, [currentRole.skills, getHitMatrix]);

  const uniqueSkills = useMemo(() => {
    const returnSkills = currentRole.skills || [];
    return returnSkills.filter(s => {
      const hitMatrix = getHitMatrix(s.skillId);
      hitMatrix.splice(position, 1);
      const matches = hitMatrix.find(v => v === true);
      return !matches;
    });
  }, [currentRole.skills, getHitMatrix, position]);

  const getColor = () => {
    switch (position) {
      case 0:
        return theme.colors.icon.success.hex;
      case 1:
        return theme.colors.icon.warning.hex;
      case 2:
        return theme.colors.icon.information.hex;
      default:
        return theme.colors.icon.warning.hex;
    }
  };

  const isHighlighted = skillId => {
    return highlightedSkills.includes(skillId);
  };

  const getRolesToCompare = async q => {
    let { roles } = await RolesService.getRoles({ companyId, searchTerms: q, fields: ['roleId', 'roleName'] });
    roles = roles.filter(role => {
      return !comparedRoles.find(comparedRole => {
        return role.roleId === comparedRole?.roleId;
      });
    });
    return roles.map(r => {
      const roleValue = {
        value: { roleId: r.roleId, roleName: r.roleName, skills: r.skills },
        label: r.roleName,
      };
      return roleValue;
    });
  };

  return (
    <ComparisonColumnWrapper>
      <ComparisonColumnHeaderBar color={getColor()} />
      <BodyColumn>
        <ComparisonColumnHeader>
          <div>Role {position + 1}</div>

          <AsyncSelect
            value={{ label: currentRole.roleName, value: { name: currentRole.roleName, skills: currentRole.skills } }}
            onChange={(x: any) => {
              replaceRoleHandler(x.value, position);
            }}
            styles={customStyles}
            noOptionsMessage={noOptionsMessage}
            loadOptions={async q => {
              return await getRolesToCompare(q);
            }}
            placeholder="select a role to compare"
            classNamePrefix={`compare-roles-role-${position + 1}-autocomplete`}
          />
        </ComparisonColumnHeader>

        <div>
          {commonSkills.map(s => {
            return (
              <RoleComparisonColumnSkillRow
                key={s.skillId}
                skill={s}
                hitMatrix={getHitMatrix(s.skillId)}
                onClick={() => setHighlightedSkillsHandler(s.skillId)}
                highlighted={isHighlighted(s.skillId)}
                dataCy={`compare-roles-role-${position + 1}-skills`}
              />
            );
          })}
        </div>

        {uniqueSkills.length > 0 && (
          <>
            <ComparisonColumnHeader data-cy={`compare-roles-role-${position + 1}-unique-skills-comparisoncolumnheader`}>
              Unique Skills
            </ComparisonColumnHeader>
            <div>
              {uniqueSkills.map(s => {
                return (
                  <RoleComparisonColumnSkillRow
                    key={s.skillId}
                    skill={s}
                    hitMatrix={getHitMatrix(s.skillId)}
                    showDots={false}
                    onClick={() => setHighlightedSkillsHandler(s.skillId)}
                    highlighted={isHighlighted(s.skillId)}
                    dataCy={`compare-roles-role-${position + 1}-unique-skills`}
                  />
                );
              })}
            </div>
          </>
        )}
      </BodyColumn>
    </ComparisonColumnWrapper>
  );
};

export default RoleComparisonColumn;
