import React, { ReactElement, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { useD3 } from 'helpers/useD3Helper';
import { useTheme } from 'styled-components';
import Typography from '@mui/material/Typography';

export interface RadialLollipopItemProps {
  id: string;
  label: string;
  value: number;
  count?: number;
  isActive: boolean;
  color?: string;
  tooltipText?: string;
}

export interface CenterCircleDataProps {
  id: string;
  name: string;
  tooltip?: string;
}

export interface RadialLollipopDataProps {
  centerItem: CenterCircleDataProps;
  dataItems: RadialLollipopItemProps[];
}

type RadialLollipopChartProps = {
  chartName: string;
  data: RadialLollipopDataProps;
  valueType: 'percent' | 'value';
  labelType?: 'tooltip' | 'none';
  chartSize?: number;
  margin?: number;
  colorRange?: string[];
  isCirclesVisible?: boolean;
  isNodeCircleDynamic?: boolean;
  chartRangeSectionsCount?: number;
  isDescendingDistance?: boolean;
  sectionClickHandler?(sectionData: RadialLollipopItemProps): void;
};

const RadialLollipop = ({
  chartName,
  data,
  valueType,
  labelType = 'tooltip',
  chartSize = 240,
  margin = 10,
  colorRange,
  isCirclesVisible = true,
  isNodeCircleDynamic = false,
  chartRangeSectionsCount = 3,
  isDescendingDistance = true,
  sectionClickHandler,
}: RadialLollipopChartProps): ReactElement => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [dataRanges, setDataRanges] = useState({
    minValue: 0,
    maxValue: 0,
    maxCount: 0,
    minCount: 0,
  });
  const [countInterval, setCountInterval] = useState<number | undefined>();

  const theme = useTheme();

  const percentage = (numerator: number, denominator: number) => {
    return Math.round((numerator / denominator) * 100) / 100;
  };

  /** Chart Dimensions */
  const width = chartSize;
  const height = chartSize;
  const nodeCircleRadiusDefault = 10;
  const nodeCircleRadii = [6, 8, 10, 11, 12, 13];
  const centerCircleRadius = 0.25;
  const centerCircleOffsetHeight = (chartSize * centerCircleRadius) / 2;

  /** Data Preparation */
  useEffect(() => {
    setIsDataLoading(true);
    if (data) {
      /** Offsets data line to not display straight up and down */
      if (data.dataItems.length === 2) {
        data.dataItems = data.dataItems.concat({
          id: '999',
          label: 'blank',
          value: 0,
          tooltipText: '',
          isActive: false,
          count: 0,
        });
      }

      const ranges = {
        minValue: Math.min(...data.dataItems.map((item: RadialLollipopItemProps) => item.value)),
        maxValue: Math.max(...data.dataItems.map((item: RadialLollipopItemProps) => item.value)),
        maxCount: Math.max(...data.dataItems.map((item: RadialLollipopItemProps) => item.count || 0)),
        minCount: Math.min(...data.dataItems.map((item: RadialLollipopItemProps) => item.count || 0)),
      };

      setDataRanges(ranges);
      setCountInterval(ranges.maxCount === 0 ? undefined : Math.ceil(ranges.maxCount / nodeCircleRadii.length));
    }

    setIsDataLoading(false);
  }, [data]);

  const getNodeCircleRadius = (value: number) => {
    if (isNodeCircleDynamic && dataRanges.maxCount > 0 && countInterval) {
      return nodeCircleRadii[Math.ceil(value / countInterval) - 1];
    } else {
      return nodeCircleRadiusDefault;
    }
  };

  const dataLineHeight = (value: number) => {
    const adjustedValue = (v: number) => {
      const pct = percentage(v, dataRanges.maxValue);
      switch (valueType) {
        case 'percent':
          return isDescendingDistance ? 1 - v : v;
        case 'value':
          return isDescendingDistance ? 1 - pct : pct;
      }
    };

    return adjustedValue(value) * (chartSize / 2) * 0.75 + centerCircleOffsetHeight;
  };

  const colors = colorRange || theme.colors.chart.colorRange10;

  const tooltip = d3
    .select('div')
    .append('div')
    .style('opacity', 0)
    .style('position', 'fixed')
    .style('display', 'inline-flex')
    .style('background-color', 'white')
    .style('border-radius', theme.borderRadius.default)
    .style('padding', `${theme.customSpacing.px.xs}px`)
    .style('font-size', theme.customTypography.desktop.caption.size)
    .style('box-shadow', theme.boxShadow.surface2)
    .style('max-width', '220px');

  // constraints for range circles, based on percentage
  const scale = d3
    .scaleLinear()
    .domain([0, 1])
    .range([0, height / 2]);

  const chartRangeValues = () => {
    let range = [centerCircleRadius];
    const sectionRadiusHeight = (1 - centerCircleRadius) / chartRangeSectionsCount;
    if (isCirclesVisible) {
      for (let i = 0; i < chartRangeSectionsCount; i++) {
        range = range.concat(range[range.length - 1] + sectionRadiusHeight);
      }
    }
    return range.sort((prev: number, curr: number) => curr - prev);
  };

  /** Data Node Tooltip */
  const mousemoveNode = function (d: any) {
    const defaultValue =
      valueType === 'percent' ? `${Math.round(d.target.__data__.value * 1000) / 10}%` : d.target.__data__.value;

    if (d.target.__data__.label !== 'blank') {
      tooltip
        .html(d.target.__data__.tooltipText || `${d.target.__data__.label}: ${defaultValue}`)
        .attr('class', `${chartName}-radial-chart-tooltip tooltip-role-${d.target.__data__.id}`)
        .style('opacity', 1)
        .style('left', `${d.clientX + 10}px`)
        .style('top', `${d.clientY + 10}px`)
        .style('z-index', 1);
    }
  };

  const mouseleave = function () {
    tooltip.style('opacity', 0);
  };

  /** Data Center Tooltip */
  const mouseoverCenter = function (d: any) {
    if (data && labelType === 'tooltip' && d.target.__data__ === centerCircleRadius) {
      tooltip
        .html(data.centerItem.tooltip || data.centerItem.name)
        .style('opacity', 1)
        .attr('class', `${chartName}-radial-chart-tooltip-center`)
        .style('left', `${d.clientX + 10}px`)
        .style('top', `${d.clientY + 10}px`);
    }
  };

  const mousemoveCenter = function (d: any) {
    if (data && labelType === 'tooltip' && d.target.__data__ === centerCircleRadius) {
      tooltip
        .html(data.centerItem.tooltip || data.centerItem.name)
        .style('left', `${d.clientX + 10}px`)
        .style('top', `${d.clientY + 10}px`);
    }
  };

  const handleOnClick = (sectionData: RadialLollipopItemProps) => {
    sectionClickHandler?.(sectionData);
    mouseleave();
  };

  function renderChart(svg: any) {
    if (data?.dataItems[0] && dataRanges.maxValue > 0) {
      const color = d3
        .scaleOrdinal()
        .domain(data.dataItems.map(x => x.label))
        .range(colors);

      /** Data Range Circles */
      svg
        .selectAll('circle')
        .data(chartRangeValues())
        .join('circle')
        .attr('r', (d: any) => scale(d))
        .attr('fill', (d: number) => (d === centerCircleRadius ? theme.colors.surface.neutral : 'transparent'))
        .attr('stroke', (d: number) =>
          d === centerCircleRadius ? theme.colors.border.default : theme.colors.border.subdued
        )
        .attr('transform', `translate(${width / 2}, ${height / 2})`)
        .on('mouseover', mouseoverCenter)
        .on('mousemove', mousemoveCenter)
        .on('mouseleave', mouseleave);

      svg = d3.select(`#${chartName}-radial-chart`).attr('viewBox', [0, 0, width, height]);

      /** Node Lines */
      const dataItemNode = svg
        .selectAll('g')
        .data(data.dataItems)
        .join('g')
        .attr('transform', (d: any, i: number, arr: RadialLollipopChartProps[]) => {
          return `translate(${width / 2}, ${height / 2}) rotate(${(i * 360) / arr.length})`;
        })
        .attr('class', (d: any) => `radial-chart-item-${d.id}`);

      dataItemNode
        .append('line')
        .attr('x1', 0)
        .attr('y1', (chartSize * centerCircleRadius) / 2)
        .attr('x2', 0)
        .attr('y2', (d: any) => {
          // measures to plot nodes to center of circle
          // includes offset of circle radius for when circle opacity is < 1
          return dataLineHeight(d.label === 'blank' ? 0 : d.value) - getNodeCircleRadius(d.count) >
            centerCircleOffsetHeight
            ? dataLineHeight(d.label === 'blank' ? 0 : d.value) - getNodeCircleRadius(d.count)
            : centerCircleOffsetHeight;
        })
        .attr('stroke-width', 1)
        .attr('stroke', (d: any) => (d.label === 'blank' ? 'none' : theme.colors.border.default));

      /** Node Circles */
      dataItemNode
        .append('circle')
        .attr('transform', (d: any, i: number, arr: RadialLollipopChartProps[]) => {
          return `translate(0, ${dataLineHeight(d.label === 'blank' ? 0 : d.value)}) rotate(${(i * 360) / arr.length})`;
        })
        .attr('fill', (d: any) => (d.label === 'blank' ? 'transparent' : d.color || color(d.label)))
        .attr('r', (d: any) => getNodeCircleRadius(d.count))
        .style('cursor', sectionClickHandler ? 'pointer' : 'default')
        .style('opacity', (d: any) => (d.isActive ? 1 : 0.24));

      if (sectionClickHandler) {
        // TODO: [TAL-1199] Bug Fix - tooltip shadow persists after click
        dataItemNode.on('click', (d: any) => {
          handleOnClick(d.target.__data__);
        });
      }

      if (labelType === 'tooltip') {
        dataItemNode.on('mousemove', mousemoveNode).on('mouseleave', mouseleave);
      }

      svg
        .attr('width', width)
        .attr('height', height)
        .style('margin', `${margin}px`)
        .style('padding', '10px')
        .style('overflow', 'visible');
    }
  }

  const svgRef = data.dataItems ? useD3(renderChart, [data]) : undefined;

  return (
    <>
      {isDataLoading && <Typography sx={{ display: 'flex', justifyContent: 'center' }}>loading...</Typography>}
      {!isDataLoading && (
        <div id={`${chartName}-radial-chart-container`} data-cy={`${chartName}-radial-chart-container`}>
          <svg id={`${chartName}-radial-chart`} ref={svgRef} />
        </div>
      )}
    </>
  );
};

export default RadialLollipop;
