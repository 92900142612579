import React, { ReactElement } from 'react';
import styled from 'styled-components';

type SelectProps = any;

const StyledSelect = styled.select`
  border-radius: 4px;
  border-color: ${props => props.theme.colors.border.default};
  color: ${props => props.theme.colors.text.default};
  padding: 0.5rem;
`;

const Select = (props: SelectProps): ReactElement => {
  return <StyledSelect {...props} />;
};

export default Select;
