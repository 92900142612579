import React, { ReactElement, useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';

type PasswordRequirementsProps = {
  show: boolean;
  password: string;
  setValid: (isValid: boolean) => void;
};

const InputSubLabel = styled(Typography)<{ $valid: boolean }>`
  display: block;
  color: ${props => (props.$valid ? `${props.theme.colors.text.success}` : `${props.theme.colors.text.critical}`)};
`;

const MIN_PASSWORD_LENGTH = 8;
const CONSECUTIVE_REPEATING_CHARACTER_LIMIT = 3;
const SEQUENTIAL_CHARACTER_LIMIT = 3;
const RESTRICTED_WORDS = ['skillid', 'talent', 'talenttransform', 'role', 'emsi', 'emsibg', 'lightcast'];
const NUMBERS = '0123456789';
const ALPHABETS = 'abcdefghijklmnopqrstuvwxyz';
const ALPHANUMERIC_CHARACTERS = ALPHABETS + NUMBERS;

const recordAnalytics = (pageLocation, labelValue) => {
  ReactGA.event({
    category: pageLocation.includes('sign-up') ? 'sign up' : 'reset password',
    action: 'form error: term blocked',
    label: labelValue,
  });
};

const callRecordAnalytics = debounce(recordAnalytics, 1000);

const PasswordRequirements = ({ show, password, setValid }: PasswordRequirementsProps): ReactElement => {
  const location = useLocation().pathname;
  const validCharacterCount = new RegExp(`.{${MIN_PASSWORD_LENGTH},}`).test(password); // Regex: /.{8,}/
  const containsSymbol = /[!@#$%^&*)(+=._-]/.test(password);
  const containsNumber = /\d+/.test(password);
  const containsUppercase = /[A-Z]+/.test(password);
  const containsLowercase = /[a-z]+/.test(password);
  const containsConsecutiveRepeatingCharacters = new RegExp(`(.)\\1{${CONSECUTIVE_REPEATING_CHARACTER_LIMIT},}`).test(
    password
  ); // Regex: /(.)\1{3,}/

  let containsSequentialCharacters = false;
  for (let i = 0; i <= password.length - (SEQUENTIAL_CHARACTER_LIMIT + 1); i++) {
    if (ALPHANUMERIC_CHARACTERS.includes(password.toLowerCase().slice(i, i + (SEQUENTIAL_CHARACTER_LIMIT + 1)))) {
      containsSequentialCharacters = true;
      break;
    }
  }

  let containsRestrictedWords = false;
  for (let i = 0; i < RESTRICTED_WORDS.length; i++) {
    if (password.toLowerCase().includes(RESTRICTED_WORDS[i])) {
      containsRestrictedWords = true;
      callRecordAnalytics(location, RESTRICTED_WORDS[i]);
      break;
    }
  }

  const isPasswordValid =
    validCharacterCount &&
    containsSymbol &&
    containsNumber &&
    containsUppercase &&
    containsLowercase &&
    !containsConsecutiveRepeatingCharacters &&
    !containsSequentialCharacters &&
    !containsRestrictedWords;

  useEffect(() => {
    setValid(isPasswordValid);
  }, [password]);

  if (show) {
    return (
      <>
        <InputSubLabel variant="strong" $valid={validCharacterCount}>
          &middot; At least {MIN_PASSWORD_LENGTH} characters.
        </InputSubLabel>
        <InputSubLabel variant="strong" $valid={containsSymbol}>
          &middot; At least 1 symbol.
        </InputSubLabel>
        <InputSubLabel variant="strong" $valid={containsNumber}>
          &middot; At least 1 number.
        </InputSubLabel>
        <InputSubLabel variant="strong" $valid={containsUppercase}>
          &middot; At least 1 uppercase.
        </InputSubLabel>
        <InputSubLabel variant="strong" $valid={containsLowercase}>
          &middot; At least 1 lowercase.
        </InputSubLabel>
        <InputSubLabel variant="strong" $valid={!containsConsecutiveRepeatingCharacters}>
          &middot; Should not contain more than {CONSECUTIVE_REPEATING_CHARACTER_LIMIT} consecutive repeating
          characters.
        </InputSubLabel>
        <InputSubLabel variant="strong" $valid={!containsSequentialCharacters}>
          &middot; Should not contain more than {SEQUENTIAL_CHARACTER_LIMIT} sequential characters.
        </InputSubLabel>
        <InputSubLabel variant="strong" $valid={!containsRestrictedWords}>
          &middot; Should not contain application related keywords.
        </InputSubLabel>
      </>
    );
  } else {
    return <></>;
  }
};

export default PasswordRequirements;
