import axios from 'helpers/api_helper';
import Company, { CompanyConstructorProps } from 'classes/company';

const getCompany = async ({ companyId }: { companyId: string }): Promise<Company> => {
  if (!companyId) return new Company({});

  const url = `${process.env.REACT_APP_API_MICRO_METADATA_ROOT}/companies/${companyId}`;
  const resp = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const company = new Company(resp.data);

  return company;
};

const getCompanies = async (): Promise<Array<Company>> => {
  const url = `${process.env.REACT_APP_API_MICRO_METADATA_ROOT}/companies`;
  const resp = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const rawCompanies = resp.data.companies || [];
  const companies = rawCompanies.map((c: CompanyConstructorProps) => new Company(c));

  return companies;
};

const createCompany = async ({ company, createdBy }: { company: Company; createdBy: string }): Promise<void> => {
  await axios.post(`${process.env.REACT_APP_API_MICRO_METADATA_ROOT}/companies`, {
    ...company,
    createdBy,
  });
};

const updateCompany = async ({ company, userId }: { company: Company; userId: string }): Promise<void> => {
  await axios.put(`${process.env.REACT_APP_API_MICRO_METADATA_ROOT}/companies/${company.customerId}`, {
    ...company,
    lastModifiedBy: userId,
  });
};

export default {
  getCompany,
  getCompanies,
  createCompany,
  updateCompany,
};
