import { DefaultTheme } from 'styled-components';
import { CustomThemeName } from 'types/types';

const globalStylesOriginal: DefaultTheme = {
  customThemeName: CustomThemeName.Original,
  brand: {
    svgLogo: '/images/talent-transform-logo.svg',
    pngHomeBackground: '/images/InsightsHomeBackgroundx2.png',
    noRolesBackground: '/images/no_roles.png',
    headerBarColor1: '#192640',
    headerBarColor2: '#48ae7d',
    get headerBarGradiant() {
      return `linear-gradient(92.12deg, ${this.headerBarColor1} 19.62%, ${this.headerBarColor2} 146.2%)`;
    },
  },
  customTypography: {
    fontFamily: 'Lato',
    desktop: {
      displayXLarge: {
        size: '40px',
        weight: '700',
        lineHeight: '44px',
        letterSpacing: '-0.02em',
      },
      displayLarge: {
        size: '28px',
        weight: '700',
        lineHeight: '32px',
        letterSpacing: '-0.02em',
      },
      displayMedium: {
        size: '20px',
        weight: '500',
        lineHeight: '24px',
      },
      displaySmall: {
        size: '18px',
        weight: '500',
        lineHeight: '22px',
      },
      heading: {
        size: '16px',
        weight: '700',
        lineHeight: '24px',
      },
      subHeading: {
        size: '12px',
        weight: '700',
        lineHeight: '16px',
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
      },
      body: {
        size: '14px',
        weight: '400',
        lineHeight: '20px',
      },
      strong: {
        size: '14px',
        weight: '700',
        lineHeight: '20px',
      },
      caption: {
        size: '12px',
        weight: '400',
        lineHeight: '16px',
      },
      button: {
        size: '14px',
        weight: '500',
        lineHeight: '20px',
      },
    },
    mobile: {
      displayXLarge: {
        size: '28px',
        weight: '700',
        lineHeight: '36px',
        letterSpacing: '-0.02em',
      },
      displayLarge: {
        size: '24px',
        weight: '700',
        lineHeight: '28px',
        letterSpacing: '-0.02em',
      },
      displayMedium: {
        size: '20px',
        weight: '500',
        lineHeight: '22px',
      },
      displaySmall: {
        size: '18px',
        weight: '500',
        lineHeight: '22px',
      },
      heading: {
        size: '16px',
        weight: '700',
        lineHeight: '24px',
      },
      subHeading: {
        size: '13px',
        weight: '500',
        lineHeight: '16px',
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
      },
      body: {
        size: '15px',
        weight: '400',
        lineHeight: '20px',
      },
      strong: {
        size: '14px',
        weight: '700',
        lineHeight: '20px',
      },
      caption: {
        size: '14px',
        weight: '400',
        lineHeight: '16px',
      },
      button: {
        size: '15px',
        weight: '500',
        lineHeight: '20px',
      },
    },
  },
  borderRadius: {
    small: '2px',
    default: '4px',
    medium: '6px',
    large: '8px',
    circle: '50%',
  },
  boxShadow: {
    default: '0px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
    surface1: '0px 0px 1px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.05)',
    surface2: '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)',
    surface3: '0px 0px 3px rgba(0, 0, 0, 0.2), 0px 4px 20px rgba(0, 0, 0, 0.1)',
    surface4: '0px 0px 4px rgba(0, 0, 0, 0.2), 0px 8px 32px rgba(0, 0, 0, 0.3)',
    buttonFocus:
      '0px 0px 0px 1px #FFFFFF, 0px 0px 0px 3px #4E91F5, 0px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
    textFieldFocus:
      '0px 0px 0px 1px #FFFFFF, 0px 0px 0px 3px #4E91F5, 0px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
    surfaceSelected: 'inset 0px 1px 0px rgba(0, 0, 0, 0.1)',
  },
  colors: {
    background: {
      default: '#F0F1F3',
    },
    surface: {
      default: '#FFFFFF',
      selected: '#D2E1FA',
      disabled: '#F2F3F7',
      neutral: '#D8DBE1',
      neutralSubdued: '#F0F2F5',
      information: '#C4EDF5',
      informationSubdued: '#E0FAFF',
      success: '#C0F0D7',
      successSubdued: '#DCFAEB',
      warning: '#FAE5C8',
      warningSubdued: '#FCF4E8',
      critical: '#F8D7D7',
      criticalSubdued: '#FAEBEB',
    },
    text: {
      default: '#212429',
      subdued: '#484B52',
      disabled: '#6B707A',
      information: '#0A89A3',
      success: '#09703B',
      warning: '#7A5A19',
      critical: '#A31414',
      onInteractive: '#FFFFFF',
      onPrimary: '#FFFFFF',
      onCritical: '#FFFFFF',
      interactive: '#2E6AD1',
      get onBackground() {
        return this.default;
      },
      get onHeaderBar() {
        return this.onPrimary;
      },
      get onHeaderBarAlt() {
        return this.onPrimary;
      },
      get onHeaderBarHover() {
        return this.default;
      },
    },
    border: {
      default: '#AAAFB9',
      subdued: '#DCDFE5',
      hovered: '#BBBFC7',
      disabled: '#C0C4CC',
      information: '#0A89A3',
      informationSubdued: '#BAE3EB',
      success: '#319863',
      successSubdued: '#B8E6CE',
      warning: '#A28241',
      warningSubdued: '#E8E0D4',
      critical: '#CB3C3C',
      criticalSubdued: '#E6D7D7',
    },
    icon: {
      // https://angel-rs.github.io/css-color-filter-generator/ to apply directly to <img/> using svg
      default: {
        hex: '#8B8D91',
        filter:
          'brightness(0) saturate(100%) invert(63%) sepia(13%) saturate(93%) hue - rotate(182deg) brightness(87%) contrast(82%)',
      },
      subdued: {
        hex: '#B2B4B9',
        filter:
          'brightness(0) saturate(100%) invert(82%) sepia(5%) saturate(190%) hue-rotate(185deg) brightness(86%) contrast(97%)',
      },
      hovered: {
        hex: '#95979B',
        filter:
          'brightness(0) saturate(100%) invert(66%) sepia(5%) saturate(207%) hue-rotate(182deg) brightness(92%) contrast(83%)',
      },
      disabled: {
        hex: '#ADB0B8',
        filter:
          'brightness(0) saturate(100%) invert(79%) sepia(9%) saturate(194%) hue-rotate(186deg) brightness(88%) contrast(92%)',
      },
      information: {
        hex: '#0A89A3',
        filter:
          'brightness(0) saturate(100%) invert(40%) sepia(47%) saturate(6543%) hue-rotate(168deg) brightness(92%) contrast(92%)',
      },
      success: {
        hex: '#319863',
        filter:
          'brightness(0) saturate(100%) invert(49%) sepia(64%) saturate(444%) hue-rotate(97deg) brightness(88%) contrast(84%)',
      },
      warning: {
        hex: '#E0A42D',
        filter:
          'brightness(0) saturate(100%) invert(89%) sepia(13%) saturate(6798%) hue-rotate(336deg) brightness(92%) contrast(90%)',
      },
      critical: {
        hex: '#DF5050',
        filter:
          'brightness(0) saturate(100%) invert(41%) sepia(21%) saturate(3574%) hue-rotate(328deg) brightness(97%) contrast(80%)',
      },
      onInteractive: {
        hex: '#FFFFFF',
        filter:
          'brightness(0) saturate(100%) invert(99%) sepia(2%) saturate(452%) hue-rotate(32deg) brightness(115%) contrast(100%)',
      },
      onPrimary: {
        hex: '#FFFFFF',
        filter:
          'brightness(0) saturate(100%) invert(99%) sepia(2%) saturate(452%) hue-rotate(32deg) brightness(115%) contrast(100%)',
      },
      onCritical: {
        hex: '#FFFFFF',
        filter:
          'brightness(0) saturate(100%) invert(99%) sepia(2%) saturate(452%) hue-rotate(32deg) brightness(115%) contrast(100%)',
      },
      interactive: {
        hex: '#2E6AD1',
        filter: 'invert(53%) sepia(81%) saturate(6502%) hue-rotate(210deg) brightness(90%) contrast(79%)',
      },
      get onHeaderBar() {
        return this.onPrimary;
      },
    },
    action: {
      primary: '#258553',
      primaryHovered: '#1B7A49',
      primaryPressed: '#11703F',
      primaryDepressed: '#125C2F',
      primaryDisabled: '#DFE5E8',
      secondary: '#FFFFFF',
      secondaryHovered: '#F0F2F5',
      secondaryPressed: '#E6E8EB',
      secondaryDepressed: '#787A7D',
      secondaryDisabled: '#E1E3E6',
      critical: '#B82C16',
      criticalHovered: '#992512',
      criticalPressed: '#752417',
      criticalDepressed: '#3D0F07',
      criticalDisabled: '#DFE5E8',
    },
    interactive: {
      default: '#2E6AD1',
      defaultDisabled: '#80807C',
      critical: '#B82C16',
      criticalDisabled: '#9E5C52',
    },
    decorative: {
      brand: '#41D592',
      focus: '#4E91F5',
    },
    chart: {
      variant1: '#CA61FF',
      variant2: '#13BAC4',
      variant3: '#85D9A1',
      variant4: '#FFA163',
      variant5: '#0A5D62',
      variant6: '#CAA722',
      variant7: '#83ACFF',
      variant8: '#D8DBE1',
      variant9: '#93293B',
      variant10: '#FB7D91',
      variant11: '#18E8F5',
      variant12: '#4D88FF',
      variant13: '#F54562',
      variant14: '#0E8B93',
      get colorRange3() {
        return [this.variant1, this.variant2, this.variant3];
      },
      get colorRange7() {
        return [
          this.variant1,
          this.variant2,
          this.variant3,
          this.variant4,
          this.variant5,
          this.variant6,
          this.variant7,
        ];
      },
      get colorRange10() {
        return [
          this.variant1,
          this.variant2,
          this.variant3,
          this.variant4,
          this.variant5,
          this.variant6,
          this.variant11,
          this.variant12,
          this.variant13,
          this.variant14,
        ];
      },
    },
    progressBar: {
      default: '#FFA163',
      segment1: '#0A5D62',
      segment2: '#83ACFF',
      segment3: '#CAA722',
      segment4: '#93293B',
      segment5: '#CA61FF',
      segment6: '#FB7D91',
    },
    chart1: {
      purple: '#CA61FF',
      turquoise: '#7BD8DE',
      lightGreen: '#85D9A1',
    },
  },
  customSpacing: {
    px: {
      none: 0,
      base: 4,
      xxs: 8,
      xs: 12,
      s: 24,
      m: 40,
      l: 64,
      xl: 80,
    },
  },
};

export default globalStylesOriginal;
