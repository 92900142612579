import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

const Filter = ({ color = '#8B8D91', size = 16, dataCy = 'filterIcon' }) => {
  return (
    <Container size={size * 1.25}>
      <svg
        width={size}
        height={size}
        data-cy={dataCy}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 7C4 6.44772 4.44772 6 5 6H19C19.5523 6 20 6.44772 20 7C20 7.55228 19.5523 8 19 8H5C4.44772 8 4 7.55228 4 7ZM6 12C6 11.4477 6.44772 11 7 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H7C6.44772 13 6 12.5523 6 12ZM8 17C8 16.4477 8.44772 16 9 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H9C8.44772 18 8 17.5523 8 17Z"
          fill={color}
        />
      </svg>
    </Container>
  );
};

Filter.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  dataCy: PropTypes.string,
};

export default Filter;
