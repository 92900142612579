import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

const Check = ({ color, size = 24 }) => {
  const theme = useTheme();
  color = color || theme.colors.icon.default.hex;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.6644 5.2526C21.0771 5.61952 21.1143 6.25159 20.7474 6.66437L10.0807 18.6644C9.89098 18.8779 9.61897 19 9.33333 19C9.0477 19 8.77569 18.8779 8.58592 18.6644L3.25259 12.6644C2.88567 12.2516 2.92285 11.6195 3.33564 11.2526C3.74842 10.8857 4.38049 10.9229 4.74741 11.3356L9.33333 16.4948L19.2526 5.33565C19.6195 4.92286 20.2516 4.88568 20.6644 5.2526Z"
        fill={color}
      />
    </svg>
  );
};

Check.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default Check;
