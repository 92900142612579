import React, { useState } from 'react';
import styled from 'styled-components';
import InputRow from 'components/atoms/InputRow';
import InputBar from 'components/molecules/InputBar';
import HeaderBar from 'components/organisms/HeaderBar';
import { Auth } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const Wrapper = styled.div`
  font-size: 12px;
  width: 864px;
  margin: auto;
`;

const Title = styled(Typography)`
  width: 547px;
  margin: ${props => props.theme.customSpacing.px.m}px auto;
  text-align: center;
  display: block;
`;

const ColumnWrapper = styled.div`
  display: flex;
  font-size: 12px;
`;

function SignIn() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [authenticationCode, setAuthenticationCode] = useState('');

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      await Auth.confirmSignUp(email, authenticationCode);
      navigate('/upload');
    } catch (e) {
      alert(e.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>Confirm Account</title>
      </Helmet>
      <HeaderBar />
      <Wrapper>
        <Title variant="displayLarge">Confirm Account</Title>
        <ColumnWrapper>
          <div style={{ width: '45%', color: '#999' }}>
            Please confirm your account with the authentication code that was emailed to you.
          </div>
          <div style={{ width: '45%' }}>
            <form onSubmit={handleSubmit}>
              <InputRow>
                <InputBar autoFocus onChange={e => setEmail(e.target.value)} placeholder="email address" type="email" />
              </InputRow>
              <InputRow>
                <InputBar
                  onChange={e => setAuthenticationCode(e.target.value)}
                  placeholder="authentication code"
                  type="text"
                />
              </InputRow>
              <Button type="submit" variant="contained" color="actionPrimary">
                confirm account
              </Button>
            </form>
          </div>
        </ColumnWrapper>
      </Wrapper>
    </>
  );
}

export default SignIn;
