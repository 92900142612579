import React, { useEffect } from 'react';
import styled from 'styled-components';
import UseAuthContext from 'helpers/UseAuthContext';
import { Outlet, useLocation, Navigate, useNavigate } from 'react-router-dom';
import useCompanyContext from 'helpers/UseCompanyContext';
import queryString from 'query-string';
import { Auth } from '@aws-amplify/auth';

const LoadingWrapper = styled.div`
  font-family: ${props => props.theme.customTypography.fontFamily};
  padding: 20px;
  width: 100%;
  text-align: center;
`;

const performSessionCheck = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // should refresh the session while possible
  useEffect(() => {
    Auth.currentSession().catch(() => {
      navigate('/', { replace: true, state: { from: location } });
    });
  }, []);
};

export const PublicRoute = () => {
  const { companyId, setInitialCompanyId } = useCompanyContext();
  const location = useLocation();

  // if there is no companyId, we can check if the user has provided one
  if (!companyId) {
    const params = queryString.parse(location.search);
    const companyId = params.companyId;
    // set the companyId on the context so that we can render company specific information to non-loggedin users
    companyId && setInitialCompanyId(companyId);
  }

  return <Outlet />;
};

export const PrivateRoute = () => {
  const { authLoaded, loggedIn } = UseAuthContext();
  const { companyDetailsLoaded } = useCompanyContext();
  performSessionCheck();
  const location = useLocation();

  return (
    <>
      {authLoaded && companyDetailsLoaded ? (
        <>{loggedIn ? <Outlet /> : <Navigate to={'/sign-in'} state={{ from: location }} replace />}</>
      ) : (
        <LoadingWrapper id="loadingMessage" data-cy="loading-message">
          loading your session...
        </LoadingWrapper>
      )}
    </>
  );
};

export const AnalystUserRoute = () => {
  const { authLoaded, loggedIn, isAnalyst } = UseAuthContext();
  const { companyDetailsLoaded } = useCompanyContext();
  const location = useLocation();

  performSessionCheck();

  return (
    <>
      {authLoaded && companyDetailsLoaded ? (
        <>
          {!loggedIn && <Navigate to={'/sign-in'} state={{ from: location }} replace />}
          {loggedIn && !isAnalyst() && <Navigate to="/home" replace />}
          {loggedIn && isAnalyst() && <Outlet />}
        </>
      ) : (
        <LoadingWrapper id="loadingMessage" data-cy="loading-message">
          loading your session...
        </LoadingWrapper>
      )}
    </>
  );
};

export const AdminUserRoute = () => {
  const { authLoaded, loggedIn, isAdmin } = UseAuthContext();
  const { companyDetailsLoaded } = useCompanyContext();

  performSessionCheck();

  const location = useLocation();

  return (
    <>
      {authLoaded && companyDetailsLoaded ? (
        <>
          {!loggedIn && <Navigate to={'/sign-in'} state={{ from: location }} replace />}
          {loggedIn && !isAdmin() && <Navigate to="/home" replace />}
          {loggedIn && isAdmin() && <Outlet />}
        </>
      ) : (
        <LoadingWrapper id="loadingMessage" data-cy="loading-message">
          loading your session...
        </LoadingWrapper>
      )}
    </>
  );
};

export const LightcastAdminUserRoute = () => {
  const { authLoaded, loggedIn, isEmsiAdmin } = UseAuthContext();
  const { companyDetailsLoaded } = useCompanyContext();

  performSessionCheck();

  const location = useLocation();

  return (
    <>
      {authLoaded && companyDetailsLoaded ? (
        <>
          {!loggedIn && <Navigate to={'/sign-in'} state={{ from: location }} replace />}
          {loggedIn && !isEmsiAdmin() && <Navigate to="/home" replace />}
          {loggedIn && isEmsiAdmin() && <Outlet />}
        </>
      ) : (
        <LoadingWrapper id="loadingMessage" data-cy="loading-message">
          loading your session...
        </LoadingWrapper>
      )}
    </>
  );
};
