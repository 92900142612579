import React, { ReactElement } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'helpers/api_helper';
import { EmsiCompanyTaxonomy } from 'types/types';

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided: any) => ({
    ...provided,
    fontSize: '1.16em',
    color: '#666666',
    borderRadius: '5px',
    border: '1px solid #CCCCCC',
    paddingLeft: '0.6em',
    width: '100%',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    marginRight: -8,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#AAAAAA',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    paddingLeft: 0,
    paddingTop: 1,
  }),
};

type CompanyAutocompleteProps = {
  changeHandler: (company: EmsiCompanyTaxonomy) => void;
  inputValue?: any;
};

const CompanyAutocomplete = ({ changeHandler, inputValue }: CompanyAutocompleteProps): ReactElement => {
  const customTheme = (theme: any) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
    },
  });

  const noOptionsMessage = ({ inputValue }) => (inputValue ? 'No results' : 'Type to search');

  return (
    <AsyncSelect
      value={inputValue}
      onChange={(x: { value: EmsiCompanyTaxonomy; label: string }) => changeHandler(x.value)}
      styles={customStyles}
      noOptionsMessage={noOptionsMessage}
      theme={customTheme}
      cacheOptions
      loadOptions={async (q: string) => {
        const res = await axios.get(`${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/postings/taxonomies/company?q=${q}`);
        return res.data.results.map((i: EmsiCompanyTaxonomy) => ({ value: i, label: i.name }));
      }}
      placeholder="Search to Find a Company"
    />
  );
};

export default CompanyAutocomplete;
