import React, { useState, useEffect, ReactElement } from 'react';
import styled from 'styled-components';
import axios from 'helpers/api_helper';
import UseUserContext from 'helpers/UseUserContext';
import UseCompanyContext from 'helpers/UseCompanyContext';
import InputBar from 'components/molecules/InputBar';
import ReactGA from 'react-ga';
import 'pages/ProfilePage.css';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { IconColorFilter, SpacingSizes } from 'types/types';
import Button from '@mui/material/Button';
import ContentPlaceHolder from 'pages/templates/ContentPlaceHolder';
import { UserDetails } from 'contexts/UserContext';

const Wrapper = styled.div`
  text-align: left;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: start;
  align-items: flex-start;
  position: relative;
  height: 100%;
  width: 80%;
  margin-right: auto;
  border-left: 1px solid #eee;
  padding: 2em;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const NameRow = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 2em;
  line-height: 1.66em;
  flex: left;
  color: #222222;
  letter-spacing: -0.01em;

  @media (max-width: 768px) {
    line-height: 1.25em;
  }
`;

const NameRowEdit = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  line-height: 0.66em;
  flex: left;
  color: #222222;
  letter-spacing: -0.01em;
`;

const LocationRow = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1.16em;
  line-height: 2em;
  color: #999999;
`;

const StateSelect = styled.select`
  padding: 0.6em;
  color: #666666;
  font-size: 1.16em;
  border-radius: ${props => props.theme.borderRadius.default};
  border: 1px solid #cccccc;
`;

const ProfilePage = (): ReactElement => {
  const { userDetails, userId, triggerReload } = UseUserContext();
  const { companyId } = UseCompanyContext();
  const [editMode, setEditMode] = useState<'location' | 'name' | null>(null);
  const [userDetailsCopy, setUserDetailsCopy] = useState<UserDetails>(userDetails);

  useEffect(() => {
    setUserDetailsCopy({ ...userDetails });
  }, [userDetails]);

  const saveUserDetails = async () => {
    // save the title to the user now that the user is made
    await axios.put(`${process.env.REACT_APP_API_ROOT}/companies/${companyId}/user/${userId}/details`, userDetailsCopy);
    setEditMode(null);
    triggerReload();
  };

  const updateFirstName = (firstName: string) => {
    const tmpUserDetailsCopy = { ...userDetailsCopy };
    tmpUserDetailsCopy.first_name = firstName;
    setUserDetailsCopy(tmpUserDetailsCopy);
  };

  const updateLastName = (lastName: string) => {
    const tmpUserDetailsCopy = { ...userDetailsCopy };
    tmpUserDetailsCopy.last_name = lastName;
    setUserDetailsCopy(tmpUserDetailsCopy);
  };

  const updateCity = (city: string) => {
    const tmpUserDetailsCopy = { ...userDetailsCopy };
    tmpUserDetailsCopy.location = { ...userDetailsCopy.location, city };
    setUserDetailsCopy(tmpUserDetailsCopy);
  };

  const updateState = (state: string) => {
    const tmpUserDetailsCopy = { ...userDetailsCopy };
    tmpUserDetailsCopy.location = { ...userDetailsCopy.location, state };
    setUserDetailsCopy(tmpUserDetailsCopy);
  };

  const saveName = async () => {
    await saveUserDetails();
    ReactGA.event({
      category: 'profile',
      action: 'update',
      label: 'name',
    });
    setEditMode(null);
  };

  const saveLocation = async () => {
    await saveUserDetails();
    ReactGA.event({
      category: 'profile',
      action: 'update',
      label: 'location',
    });
    setEditMode(null);
  };

  return (
    <ContentPlaceHolder pageTitle="Profile">
      <Wrapper>
        {editMode !== 'name' && (
          <NameRow>
            {userDetails.first_name} {userDetails.last_name}
            <BaseIcon type={IconType.Edit} onIconClick={() => setEditMode('name')} />
          </NameRow>
        )}
        {editMode === 'name' && (
          <NameRowEdit>
            <InputBar
              style={{ width: '150px' }}
              value={userDetailsCopy.first_name}
              onChange={e => updateFirstName(e.target.value)}
            />
            <InputBar
              style={{ width: '150px' }}
              value={userDetailsCopy.last_name}
              onChange={e => updateLastName(e.target.value)}
            />
            <div className="flex-container" style={{ display: 'inline-flex', marginLeft: '8px' }}>
              <Button
                variant="contained"
                color="actionPrimary"
                size="small"
                onClick={() => saveName()}
                startIcon={
                  <BaseIcon type={IconType.Check} alt="save" size="10px" colorFilter={IconColorFilter.OnPrimary} />
                }
              >
                Save
              </Button>
              <Button
                size="small"
                onClick={() => setEditMode(null)}
                startIcon={
                  <BaseIcon type={IconType.Close} alt="cancel" size="10px" colorFilter={IconColorFilter.Default} />
                }
              >
                Cancel
              </Button>
            </div>
          </NameRowEdit>
        )}

        {editMode !== 'location' && (
          <LocationRow>
            <BaseIcon type={IconType.Location} alt="location pin" marginRight={SpacingSizes.XXS} />
            {userDetails.location && userDetails.location.city}, {userDetails.location && userDetails.location.state}
            <BaseIcon type={IconType.Edit} onIconClick={() => setEditMode('location')} />
          </LocationRow>
        )}

        {editMode === 'location' && (
          <NameRowEdit>
            <InputBar
              style={{ width: '150px' }}
              value={userDetailsCopy.location && userDetailsCopy.location.city}
              onChange={e => updateCity(e.target.value)}
            />
            <StateSelect
              onChange={e => updateState(e.target.value)}
              value={userDetailsCopy.location && userDetailsCopy.location.state}
            >
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </StateSelect>
            <div className="flex-container" style={{ display: 'inline-flex', marginLeft: '8px' }}>
              <Button
                variant="contained"
                color="actionPrimary"
                size="small"
                onClick={() => saveLocation()}
                startIcon={
                  <BaseIcon type={IconType.Check} alt="save" size="10px" colorFilter={IconColorFilter.OnPrimary} />
                }
              >
                Save
              </Button>
              <Button
                size="small"
                onClick={() => setEditMode(null)}
                startIcon={
                  <BaseIcon type={IconType.Close} alt="cancel" size="10px" colorFilter={IconColorFilter.Default} />
                }
              >
                Cancel
              </Button>
            </div>
          </NameRowEdit>
        )}
      </Wrapper>
    </ContentPlaceHolder>
  );
};

export default ProfilePage;
