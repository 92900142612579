import React, { ReactNode, ReactElement } from 'react';
import styled from 'styled-components';
import { CssFlexDirection } from 'types/types';
import { DrawerProps } from '@mui/material/Drawer';
import ContentPlaceHolder from 'pages/templates/ContentPlaceHolder';
import SidePanelTemplate from 'pages/templates/SidePanelTemplate';

type MainContentTemplateProps = {
  children: ReactNode;
  topChildrenComponent?: ReactNode;
  contentFlexDirection?: CssFlexDirection;
  pageTitle: string;
  isFullWidth?: boolean;
  sidePanelTitle?: string;
  sidePanelContent?: ReactNode;
  sidePanelMuiDrawerProps?: DrawerProps;
  sidePanelWidth?: number;
  sidePanelActions?: ReactElement;
  sidePanelHasHeaderDivider?: boolean;
};

const fixedWrapperWidth = '1160px';

const Wrapper = styled.div<{ $templatewidth: string; $templateminwidth: string }>`
  display: flex;
  flex-wrap: wrap;
  width: inherit;
  margin: 40px 48px;
  width: ${props => props.$templatewidth};
  min-width: ${props => props.$templateminwidth};
`;

const WrapperMainContent = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  min-height: 600px;
  width: inherit;
  background-color: ${props => props.theme.colors.surface.default};
  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.2),
    0px 2px 1px rgba(0, 0, 0, 0.2);
  @media (max-width: 768px) {
    margin: 0;
  }
`;

const WrapperTopContent = styled.div`
  width: inherit;
  padding-bottom: 16px;
`;

const MainContentTemplate = ({
  children,
  topChildrenComponent,
  contentFlexDirection,
  pageTitle,
  isFullWidth = true,
  sidePanelTitle,
  sidePanelContent,
  sidePanelMuiDrawerProps,
  sidePanelWidth,
  sidePanelActions,
  sidePanelHasHeaderDivider,
}: MainContentTemplateProps): ReactElement => {
  const mainContentFlexDirection = () => {
    if (sidePanelContent) {
      return sidePanelMuiDrawerProps?.anchor === 'left' ? CssFlexDirection.RowReverse : CssFlexDirection.Row;
    } else {
      return contentFlexDirection || CssFlexDirection.Unset;
    }
  };

  const wrapperDataCy = 'main-content-wrapper-' + pageTitle.trim().replaceAll(' ', '-').toLowerCase();

  const templateWidth = isFullWidth ? '100%' : fixedWrapperWidth;
  const templateMinWidth = sidePanelContent === undefined ? 'unset' : '400px';

  return (
    <ContentPlaceHolder pageTitle={pageTitle} flexDirection={mainContentFlexDirection()}>
      <Wrapper className="main-wrapper" $templatewidth={templateWidth} $templateminwidth={templateMinWidth}>
        {topChildrenComponent && <WrapperTopContent>{topChildrenComponent}</WrapperTopContent>}
        <WrapperMainContent className="main-content-wrapper" data-cy={wrapperDataCy}>
          {children}
        </WrapperMainContent>
      </Wrapper>
      {sidePanelContent && (
        <SidePanelTemplate
          drawerProps={sidePanelMuiDrawerProps}
          width={sidePanelWidth}
          headerTitle={sidePanelTitle}
          headerActions={sidePanelActions}
          displayHeaderDivider={sidePanelHasHeaderDivider}
        >
          {sidePanelContent}
        </SidePanelTemplate>
      )}
    </ContentPlaceHolder>
  );
};

export default MainContentTemplate;
