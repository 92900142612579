import styled from 'styled-components';

const InputBar = styled.input`
  width: 100%;
  padding: 1em;
  font-weight: 500;
  color: ${props => props.theme.colors.text.default};
  border-radius: ${props => props.theme.borderRadius.default};
  border: 1px solid ${props => props.theme.colors.border.default};
  background-color: ${props => props.theme.colors.surface.default};

  &::placeholder {
    color: ${props => props.theme.colors.text.disabled};
  }
`;

export default InputBar;
