import { useNavigate } from 'react-router-dom';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import useCompanyContext from 'helpers/UseCompanyContext';
import RolesService, { RoleStats } from 'services/RolesService';
import dayjs from 'dayjs';
import Role from 'classes/role';
import ReactGA from 'react-ga';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { productModule } from 'helpers/product_modules';
import SkillProfileSearchParams from 'classes/SkillProfileSearchParams';

const Wrapper = styled.div`
  padding: 0px 1.25rem;
  width: 100%;
`;

const SectionTitle = styled(Typography)`
  margin-bottom: ${props => props.theme.customSpacing.px.base * 4}px;
  display: block;
`;

const MainStatsRow = styled(Grid)`
  display: flex;
  justify-content: space-around;
  padding-bottom: ${props => props.theme.customSpacing.px.s}px;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const MainStatsCard = styled(Grid)<{ $hasBorders?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px ${props => props.theme.customSpacing.px.base * 4}px 0px 0px;
  text-align: left;
  width: 100%;

  &:not(:last-child) {
    border-width: 0px 1px 0px 0px;
    border-color: ${props => props.theme.colors.border.default};
    border-style: solid;
    margin-right: ${props => props.theme.customSpacing.px.base * 4}px;
  }
`;

const MainStat = styled(Grid)`
  font-size: 1.75rem;
  font-weight: 600;
  height: 72px;
`;

const DetailedStatsRow = styled(Grid)`
  border-top: 1px solid ${props => props.theme.colors.border.default};
  display: flex;
  padding-top: 2rem;
`;

const DetailedStatsCard = styled.div`
  width: 50%;
  padding-right: ${props => props.theme.customSpacing.px.base * 4}px;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      text-align: left;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 98%;
    }
  }
`;

const PercentStat = styled.div`
  margin-bottom: ${props => props.theme.customSpacing.px.xs}px;
`;

const RoleLinkRow = styled.li`
  height: 24px;
`;

const RolesAnalytics = (): ReactElement => {
  const theme = useTheme();
  const { companyId, hasProductModule } = useCompanyContext();
  const navigate = useNavigate();
  const [rolesLoading, setRolesLoading] = useState(false);
  const [roleStats, setRoleStats] = useState<RoleStats>({
    totalRoles: 0,
    numberReviewed: 0,
    numberWithSkillProfiles: 0,
    topSkills: [],
  });
  const [recentRoles, setRecentRoles] = useState<Role[]>([]);

  useEffect(() => {
    (async () => {
      setRolesLoading(true);
      const stats = await RolesService.getRoleStats(companyId);
      setRolesLoading(false);
      setRoleStats(stats);
    })();
  }, [companyId]);

  const totalRoles = useMemo(() => {
    return roleStats.totalRoles;
  }, [roleStats]);

  const percentMapped = useMemo(() => {
    const result = Math.round((roleStats.numberReviewed / roleStats.totalRoles) * 100);
    return isNaN(result) ? 0 : result;
  }, [roleStats]);

  const percentWithSkillProfiles = useMemo(() => {
    const result = Math.round((roleStats.numberWithSkillProfiles / roleStats.totalRoles) * 100);
    return isNaN(result) ? 0 : result;
  }, [roleStats]);

  const totalMapped = useMemo(() => {
    return roleStats.numberReviewed;
  }, [roleStats]);

  const totalWithSkillProfiles = useMemo(() => {
    return roleStats.numberWithSkillProfiles;
  }, [roleStats]);

  useEffect(() => {
    const loadRecentRoles = async () => {
      const { roles } = await RolesService.getRoles({
        companyId,
        limit: 5,
        fields: ['roleId', 'roleName', 'updatedAt'],
        sortColumn: 'updatedAt',
        sortDirection: 'desc',
      });
      setRecentRoles(roles);
    };
    loadRecentRoles();
  }, []);

  const topSkills = useMemo(() => {
    return roleStats.topSkills || [];
  }, [roleStats]);

  const recordAnalytics = (action: string, label: string) => {
    ReactGA.event({
      category: 'home page',
      action,
      label,
    });
  };

  const filterRolesUnmapped = {
    matchStatus: [
      { key: 'Needs Review', value: 'Needs Review' },
      // { key: 'No Match', value: 'No Match' },
    ],
  };

  const filterNoSkillProfiles = {
    skillProfileStatus: [{ key: 'No Skill Profile', value: 'No Skill Profile' }],
  };

  const skillProfileSearchQueryString = (path: string, appliedFilters: any): string => {
    const searchParams = new SkillProfileSearchParams();
    searchParams.set('af', JSON.stringify(appliedFilters));
    const searchParamsString = searchParams.toString();

    return `${path}?${searchParamsString}`;
  };

  const PercentageProgressBar = (progressPercent: number, navigateTo: string, dataCy: string): ReactElement => {
    return (
      <>
        <PercentStat data-cy={`roles-analytics-${dataCy}-percentage`}>{progressPercent}%</PercentStat>
        {totalRoles !== 0 && (
          <>
            <div style={{ display: 'flex', opacity: '0.7' }}>
              <div
                style={{
                  width: `${progressPercent}%`,
                  height: '0.3rem',
                  padding: '0px',
                  backgroundColor: theme.colors.decorative.focus,
                }}
              />
              <div
                style={{
                  width: `${100 - progressPercent}%`,
                  height: '0.3rem',
                  padding: '0px',
                  backgroundColor: theme.colors.surface.neutral,
                }}
              />
            </div>
            <div style={{ lineHeight: '0.75rem' }}>
              <Link
                variant="caption"
                onClick={() => navigate(navigateTo)}
                sx={{ paddingLeft: '0px' }}
                data-cy={`roles-analytics-${dataCy}-link`}
              >
                review remaining {100 - progressPercent}%
              </Link>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <Wrapper>
      {rolesLoading ? (
        <>loading.....</>
      ) : (
        <>
          <MainStatsRow container>
            <MainStatsCard $hasBorders={true}>
              <SectionTitle variant="strong" data-cy={'roles-analytics-total-roles-header'}>
                Total Roles
              </SectionTitle>
              <MainStat data-cy={'roles-analytics-total-roles-count'}>
                {totalRoles && totalRoles.toLocaleString()}
              </MainStat>
            </MainStatsCard>
            <MainStatsCard $hasBorders={true}>
              <SectionTitle variant="strong" data-cy={'roles-analytics-reviewed-roles-header'}>
                Reviewed Roles
              </SectionTitle>
              <MainStat title={`${totalMapped} roles are mapped to a title`}>
                {PercentageProgressBar(
                  percentMapped,
                  skillProfileSearchQueryString('/roles', filterRolesUnmapped),
                  'reviewed-roles'
                )}
              </MainStat>
            </MainStatsCard>
            {hasProductModule(productModule.SkillId) && (
              <MainStatsCard>
                <SectionTitle variant="strong" data-cy={'roles-analytics-skill-profiles-header'}>
                  Skill Profiles
                </SectionTitle>
                <MainStat title={`${totalWithSkillProfiles} roles have a skill profile`}>
                  {PercentageProgressBar(
                    percentWithSkillProfiles,
                    skillProfileSearchQueryString('/skill-profiles', filterNoSkillProfiles),
                    'skill-profiles'
                  )}
                </MainStat>
              </MainStatsCard>
            )}
          </MainStatsRow>
          <DetailedStatsRow data-cy={'roles-analytics-top-stats-section'}>
            {recentRoles?.length !== 0 && (
              <DetailedStatsCard>
                <SectionTitle variant="strong" data-cy={'roles-analytics-recent-roles-header'}>
                  Recent Roles
                </SectionTitle>
                <div>
                  <ul>
                    {recentRoles?.map(r => (
                      <RoleLinkRow key={r.roleId}>
                        <Link
                          title={`modified on: ${dayjs(r.updatedAt).format('MM/DD/YYYY')}`}
                          onClick={() => {
                            recordAnalytics('role analytics', 'view recent role');
                            setTimeout(() => {
                              navigate(`/role-edit/${r.roleId}?rf=&af={}`);
                            }, 500);
                          }}
                          data-cy={`roles-analytics-recent-roles-${r.roleId}`}
                        >
                          {r.roleName}
                        </Link>
                      </RoleLinkRow>
                    ))}
                  </ul>
                </div>
              </DetailedStatsCard>
            )}
            {topSkills.length !== 0 && (
              <DetailedStatsCard>
                <SectionTitle variant="strong" data-cy={'roles-analytics-top-skills-header'}>
                  Top Skills Company-Wide
                </SectionTitle>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <ul>
                    {topSkills.map(s => {
                      const appliedFilter = {
                        skills: [{ key: s.skillName, value: s.skillId }],
                      };

                      const skillLink = hasProductModule(productModule.SkillId)
                        ? skillProfileSearchQueryString('/skill-profiles', appliedFilter)
                        : skillProfileSearchQueryString('/roles', appliedFilter);

                      return (
                        <RoleLinkRow key={s.skillId}>
                          <Link
                            title={
                              hasProductModule(productModule.SkillId)
                                ? `View skill profiles that include ${s.skillName}`
                                : `View roles requesting ${s.skillName}`
                            }
                            onClick={() => {
                              if (hasProductModule(productModule.SkillId)) {
                                recordAnalytics('role analytics', 'view top skill');
                              }
                              setTimeout(() => {
                                navigate(`${skillLink}`);
                              }, 500);
                            }}
                            data-cy={`roles-analytics-top-skills-${s.skillId}`}
                          >
                            {s.skillName} ({s.count.toLocaleString()})
                          </Link>
                        </RoleLinkRow>
                      );
                    })}
                  </ul>
                  <Link
                    onClick={() => navigate('/skills-summary')}
                    underline={'always'}
                    sx={{ padding: '0px', marginTop: `${theme.customSpacing.px.base * 4}px` }}
                    data-cy={'roles-analytics-skills-summary-link'}
                  >
                    View Skills Summary
                  </Link>
                </div>
              </DetailedStatsCard>
            )}
          </DetailedStatsRow>
        </>
      )}
    </Wrapper>
  );
};

export default RolesAnalytics;
