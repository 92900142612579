import React, { useState, useEffect } from 'react';
import Role from 'classes/role';
import useCompanyContext from 'helpers/UseCompanyContext';
import loadRoles from 'helpers/loadRolesHelper';
import { RoleFilters, RoleListSort } from 'types/types';
import rolesPageHelper from 'helpers/RolesPageHelper';

export interface BulkRoleEditContextState {
  roleListUnsorted: Role[];
  roleList: Role[];
  roleListSort: RoleListSort;
  activeRoleIndex: number;
  activeRoleId?: string;
  isAllRolesLoading?: boolean;
  isSaving?: boolean;
  refreshKey?: number;
  showMetaData: boolean;
  roleFilter?: string;
  appliedFilters?: RoleFilters;
}

interface BulkRoleEditContextProviderProps {
  children?: React.ReactElement;
  initialRoleId?: string;
  roleFilter?: string;
  appliedFilters?: RoleFilters;
}

const defaultContext: BulkRoleEditContextState = {
  roleListUnsorted: new Array<Role>(),
  roleList: new Array<Role>(),
  roleListSort: {
    sortBy: 'mappingStatus',
    sortDirection: 'asc',
  },
  activeRoleIndex: -1,
  activeRoleId: '',
  isAllRolesLoading: false,
  isSaving: false,
  refreshKey: Math.random(),
  showMetaData: false,
  roleFilter: undefined,
  appliedFilters: undefined,
};

const BulkRoleEditContext = React.createContext<[BulkRoleEditContextState, React.Dispatch<any>]>([
  defaultContext,
  () => undefined,
]);

const BulkRoleEditContextProvider: React.FC<BulkRoleEditContextProviderProps> = props => {
  const [state, setState] = useState({
    ...defaultContext,
    activeRoleId: props.initialRoleId,
    roleFilter: props.roleFilter,
    appliedFilters: props.appliedFilters,
  });
  const { companyId } = useCompanyContext();

  useEffect(() => {
    (async () => {
      setState(prevState => ({ ...prevState, isAllRolesLoading: true }));
      try {
        const roleList = (await loadRoles(companyId, state.roleFilter || '', state.appliedFilters)) || [];
        setState(prevState => ({ ...prevState, roleListUnsorted: roleList, isAllRolesLoading: false }));
        console.log('BulkRoleEditContext load completed');
      } catch (e) {
        setState(prevState => ({ ...prevState, isAllRolesLoading: false }));
      }
    })();
  }, [companyId, state.roleFilter, state.appliedFilters]);

  useEffect(() => {
    let roleListSorted = Array<Role>();
    if (state.roleListSort.sortBy === 'roleName')
      roleListSorted = rolesPageHelper.sortByRoleName(state.roleListUnsorted, state.roleListSort.sortDirection);
    else if (state.roleListSort.sortBy === 'mappingStatus')
      roleListSorted = rolesPageHelper.sortByReviewStatus(state.roleListUnsorted, state.roleListSort.sortDirection);
    const index = roleListSorted.findIndex(role => role.roleId === state.activeRoleId);
    setState(prevState => ({ ...prevState, roleList: roleListSorted, activeRoleIndex: index }));
  }, [state.roleListSort, state.roleListUnsorted]);

  return <BulkRoleEditContext.Provider value={[state, setState]}>{props.children}</BulkRoleEditContext.Provider>;
};

export { BulkRoleEditContext, BulkRoleEditContextProvider };
