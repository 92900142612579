import { Typography } from '@mui/material';
import ButtonGroup from 'components/atoms/ButtonGroup';
import { Form, Formik } from 'formik';
import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import FormikSelect from 'components/atoms/formElements/FormikSelect';
import DialogModal from 'components/molecules/DialogModal';
import {
  IntegrationMappings,
  GenericOptions,
  JobCodeMappingOption,
  JobTitleMappingOption,
  JobDescriptionMappingOption,
  JobFamilyMappingOption,
  JobProfilePrivateTitleMappingOption,
  IntegrationSettingType,
} from 'types/types';

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

type IntegrationMappingsFormProps = {
  vendorId: string;
  vendorName: string;
  integrationMappings: IntegrationMappings;
  handleSave: (vendorId: string, integrationMappingConfiguration: IntegrationMappings) => Promise<void>;
  handleCancel: () => void;
  fieldMapper: Map<string, string>;
  onAfterSave: (integrationSettingType: IntegrationSettingType, success: boolean) => void;
};

const getOptions = (
  mappingOptions: Array<string>,
  fieldMapper: Map<string, string>,
  addGenericNoneSpecified = false
): Array<{ label: string; value: string }> => {
  const options = new Array<{ label: string; value: string }>();
  mappingOptions.map(optionVal => {
    return options.push({ label: fieldMapper.get(optionVal) || '', value: optionVal });
  });
  if (addGenericNoneSpecified) {
    options.push(
      Object.values(GenericOptions)
        .map((value: string) => ({ label: value, value }))
        .filter(o => o.value === 'None Specified')[0]
    );
  }
  return options;
};

const IntegrationMappingsForm = ({
  vendorId,
  vendorName,
  integrationMappings,
  handleSave,
  handleCancel,
  fieldMapper,
  onAfterSave,
}: IntegrationMappingsFormProps): ReactElement => {
  const [saving, setSaving] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const submitMappings = async (values, formikHelpers) => {
    formikHelpers.setStatus(null);
    setSaving(true);
    try {
      await handleSave(vendorId, values);
      onAfterSave('Mappings', true);
    } catch (e) {
      onAfterSave('Mappings', false);
    } finally {
      setSaving(false);
      setShowModal(false);
    }
  };

  return (
    <>
      <Formik<IntegrationMappings>
        onSubmit={submitMappings}
        initialValues={integrationMappings}
      >
        {props => {
          return (
            <>
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  props.handleSubmit();
                }}
              >
                <FormRow>
                  <Typography variant="displayLarge">{vendorName}</Typography>
                  <ButtonGroup
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    spacing={2}
                    buttons={[
                      {
                        text: 'Reset',
                        disabled: !props.dirty || saving,
                        onClickEvent: () => {
                          if (props.dirty) {
                            props.resetForm();
                          } else {
                            handleCancel?.();
                          }
                        },
                      },
                      {
                        text: 'Save',
                        disabled: saving,
                        onClickEvent: () => setShowModal(true),
                        variant: 'contained',
                        color: 'actionPrimary',
                      },
                    ]}
                  />
                </FormRow>
                <FormRow>
                  <FormikSelect
                    formPropertyName="jobCode"
                    options={getOptions(Object.values(JobCodeMappingOption), fieldMapper)}
                    label="Job Code"
                    required={true}
                  />
                </FormRow>
                <FormRow>
                  <FormikSelect
                    formPropertyName="roleName"
                    options={getOptions(
                      [
                        ...Object.values<string>(JobTitleMappingOption),
                        ...Object.values<string>(JobProfilePrivateTitleMappingOption),
                      ],
                      fieldMapper
                    )}
                    label="Role Name"
                    required={true}
                  />
                </FormRow>
                <FormRow>
                  <FormikSelect
                    formPropertyName="jobTitle"
                    options={getOptions(
                      [...Object.values(JobTitleMappingOption), ...Object.values(JobProfilePrivateTitleMappingOption)],
                      fieldMapper
                    )}
                    label="Job Title"
                    required={true}
                  />
                </FormRow>
                <FormRow>
                  <FormikSelect
                    formPropertyName="description"
                    options={getOptions(Object.values(JobDescriptionMappingOption), fieldMapper, true)}
                    label="Job Description"
                  />
                </FormRow>
                <FormRow>
                  <FormikSelect
                    formPropertyName="jobFamily"
                    options={getOptions(Object.values(JobFamilyMappingOption), fieldMapper, true)}
                    label="Job Family"
                  />
                </FormRow>
                <FormRow>
                  <FormikSelect
                    formPropertyName="jobFunction"
                    options={getOptions(Object.values(JobProfilePrivateTitleMappingOption), fieldMapper, true)}
                    label="Job Function"
                  />
                </FormRow>
                <FormRow>
                  <FormikSelect
                    formPropertyName="notes"
                    options={getOptions(
                      [
                        ...Object.values(JobCodeMappingOption),
                        ...Object.values(JobTitleMappingOption),
                        ...Object.values(JobProfilePrivateTitleMappingOption),
                        ...Object.values(JobDescriptionMappingOption),
                        ...Object.values(JobFamilyMappingOption),
                      ],
                      fieldMapper,
                      true
                    )}
                    label="Notes"
                  />
                </FormRow>
              </Form>
              <DialogModal
                dialogOpen={showModal}
                closeModal={() => setShowModal(false)}
                title="Save Changes?"
                content={'Are you sure you want to save these mappings?'}
                buttonGroup={[
                  {
                    text: 'Cancel',
                    disabled: saving,
                    onClickEvent: () => setShowModal(false),
                  },
                  {
                    text: 'Save',
                    disabled: saving,
                    onClickEvent: props.handleSubmit,
                    variant: 'contained',
                    color: 'actionPrimary',
                  },
                ]}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default IntegrationMappingsForm;
