import Role from 'classes/role';
import { useTheme } from 'styled-components';
import { EmsiUIBadgeColorPalette, RoleListSortDirection } from 'types/types';

const gapBadgeDetails = (
  exists: boolean,
  percentage: number,
  source = ''
): {
  color: EmsiUIBadgeColorPalette;
  text: string;
  message: string;
} => {
  if (!exists) {
    return {
      color: EmsiUIBadgeColorPalette.SurfaceCritical,
      text: 'Missing',
      message: `This skill is not present in ${source} postings.`,
    };
  } else if (!isFinite(percentage)) {
    return {
      color: EmsiUIBadgeColorPalette.SurfaceCritical,
      text: 'Missing',
      message: 'The skill occurance could not be calculated.',
    };
  } else {
    if (percentage >= 0.1) {
      return {
        color: EmsiUIBadgeColorPalette.SurfaceSuccess,
        text: 'Essential',
        message: `This skill occurs in ${Math.round(percentage * 100)}% of ${source} postings.`,
      };
    } else if (percentage >= 0.01) {
      return {
        color: EmsiUIBadgeColorPalette.SurfaceInformation,
        text: 'Relevant',
        message: `This skill occurs in only ${Math.round(percentage * 100)}% of ${source} postings.`,
      };
    } else {
      return {
        color: EmsiUIBadgeColorPalette.SurfaceWarning,
        text: 'Unique',
        message: `This skill occurs in just ${Math.round(percentage * 100)}% of ${source} postings.`,
      };
    }
  }
};

const sortByRoleName = (roles: Role[], sortDirection: RoleListSortDirection): Role[] => {
  const rolesLite = roles.map((role, index) => {
    return { index, roleName: role.roleName.toLowerCase() };
  });

  if (sortDirection === 'asc') {
    return rolesLite.sort((a, b) => (a.roleName > b.roleName ? 1 : -1)).map(item => roles[item.index]);
  } else {
    return rolesLite.sort((a, b) => (b.roleName > a.roleName ? 1 : -1)).map(item => roles[item.index]);
  }
};

const sortByReviewStatus = (roles: Role[], sortDirection: RoleListSortDirection): Role[] => {
  const getStatusCode = (reviewStatus: string): number => {
    switch (reviewStatus) {
      case 'No Match':
        return 1;
      case 'Low':
        return 2;
      case 'Medium':
        return 3;
      case 'High':
        return 4;
      case 'User Selected':
        return 5;
      case 'Needs Approval':
        return 6;
      case 'Reviewed':
        return 7;
      default:
        return 8;
    }
  };

  const rolesLite = roles.map((role, index) => {
    return { index, reviewStatus: getStatusCode(role.reviewStatus) };
  });

  if (sortDirection === 'asc') {
    return rolesLite.sort((a, b) => a.reviewStatus - b.reviewStatus).map(item => roles[item.index]);
  } else {
    return rolesLite.sort((a, b) => b.reviewStatus - a.reviewStatus).map(item => roles[item.index]);
  }
};

const mappingConfidenceIndicator = (
  mappingConfidenceLevel: string,
  mappingConfidenceValue?: number
): { mappingConfidenceText: string; mappingConfidenceColor: string; mappingConfidenceMessage: string } => {
  const theme = useTheme();
  let mappingConfidenceText: string;
  let mappingConfidenceColor: string;
  let mappingConfidenceMessage: string;

  switch (mappingConfidenceLevel) {
    case 'Reviewed':
      mappingConfidenceText = 'n/a';
      mappingConfidenceColor = theme.colors.text.default;
      mappingConfidenceMessage = 'Selected title has been confirmed';
      break;
    case 'Needs Approval':
      mappingConfidenceText = 'n/a';
      mappingConfidenceColor = theme.colors.text.warning;
      mappingConfidenceMessage = 'Role mapping requires approval';
      break;
    case 'No Match':
      mappingConfidenceText = 'No Match';
      mappingConfidenceColor = theme.colors.text.critical;
      mappingConfidenceMessage = 'There are no title matches for this role';
      break;
    case 'User Selected':
      mappingConfidenceText = 'n/a';
      mappingConfidenceColor = theme.colors.text.warning;
      mappingConfidenceMessage = 'Mapped title selection was not suggested by Lightcast';
      break;
    case 'Low':
      mappingConfidenceText = 'Low';
      mappingConfidenceColor = theme.colors.text.critical;
      mappingConfidenceMessage = `Mapping confidence is low for this role, needs a review ${
        mappingConfidenceValue ? `- ${Math.floor(mappingConfidenceValue * 100)}%` : ''
      }`;
      break;
    case 'Medium':
      mappingConfidenceText = 'Medium';
      mappingConfidenceColor = theme.colors.text.warning;
      mappingConfidenceMessage = `Mapping confidence is medium for this role ${
        mappingConfidenceValue ? `- ${Math.floor(mappingConfidenceValue * 100)}%` : ''
      }`;
      break;
    case 'High':
      mappingConfidenceText = 'High';
      mappingConfidenceColor = theme.colors.text.success;
      mappingConfidenceMessage = `Mapping confidence is high for this role ${
        mappingConfidenceValue ? `- ${Math.floor(mappingConfidenceValue * 100)}%` : ''
      }`;
      break;
    default:
      mappingConfidenceText = mappingConfidenceLevel;
      mappingConfidenceColor = theme.colors.text.critical;
      mappingConfidenceMessage = mappingConfidenceLevel;
  }

  return {
    mappingConfidenceText,
    mappingConfidenceColor,
    mappingConfidenceMessage,
  };
};

const RoleConfidenceLevelColor = (roleConfidenceLevel: string): EmsiUIBadgeColorPalette => {
  let color: EmsiUIBadgeColorPalette;
  let label: string = roleConfidenceLevel;
  switch (label) {
    case 'User Selected':
      label = 'n/a';
      color = EmsiUIBadgeColorPalette.surfaceDisabled;
      break;
    case 'No Match':
      color = EmsiUIBadgeColorPalette.SurfaceCritical;
      break;
    case 'Low':
      color = EmsiUIBadgeColorPalette.SurfaceCritical;
      break;
    case 'Medium':
      color = EmsiUIBadgeColorPalette.SurfaceWarning;
      break;
    case 'High':
      color = EmsiUIBadgeColorPalette.SurfaceSuccess;
      break;
    default:
      color = EmsiUIBadgeColorPalette.surfaceDisabled;
  }
  return color;
};

export default {
  gapBadgeDetails,
  sortByRoleName,
  sortByReviewStatus,
  mappingConfidenceIndicator,
  RoleConfidenceLevelColor,
};
