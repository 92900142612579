import React, { useMemo, ReactElement } from 'react';
import DialogModal from 'components/molecules/DialogModal';
import CompanyForm from 'components/organisms/CompanyForm';

type CompanyEditModalProps = {
  isOpen: boolean;
  closeHandler: () => void;
  company: any;
  refreshHandler: () => void;
};

const CompanyEditModal = ({
  isOpen = true,
  closeHandler,
  company,
  refreshHandler,
}: CompanyEditModalProps): ReactElement => {
  const isEditing = useMemo(() => {
    return company.customerId;
  }, [company]);

  return (
    <DialogModal
      dialogOpen={isOpen}
      closeModal={closeHandler}
      title={!isOpen ? '' : isEditing ? 'Edit Company' : 'Create Company'}
      titleSizeVariant="displayLarge"
      content={<CompanyForm originalCompany={company} cancelHandler={closeHandler} triggerRefresh={refreshHandler} />}
      buttonGroup={[]}
      showCloseIcon={false}
      maxWidth="md"
      fullWidth={true}
    />
  );
};

export default CompanyEditModal;
