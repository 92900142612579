import React, { ReactElement } from 'react';
import styled from 'styled-components';
import SubNavLink from 'components/atoms/SubNavLink';
import Typography from '@mui/material/Typography';

const SubNavContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.colors.surface.default};
  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.2),
    0px 2px 1px rgba(0, 0, 0, 0.05);
  padding: 0px ${props => props.theme.customSpacing.px.base * 4}px;
  line-height: 24px;
`;

type ModuleRoute = {
  displayName: string;
  path: string;
  clickEvent?: () => void;
  disabled?: boolean;
};

type SubNavProps = {
  moduleRoutes: ModuleRoute[];
  moduleTitle?: string;
  activeRoute: string;
  showing?: boolean;
  dataCy?: string;
};

const SubNav = ({
  moduleRoutes,
  moduleTitle,
  activeRoute,
  showing,
  dataCy = 'subnav-container',
}: SubNavProps): ReactElement => {
  // Dynamically render the UI
  return showing ? (
    <SubNavContainer data-cy={dataCy}>
      {moduleTitle && (
        <Typography
          variant="heading"
          sx={{
            textAlign: 'left',
            padding: '14px 16px',
            textTransform: 'capitalize',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {moduleTitle}
        </Typography>
      )}
      {moduleRoutes?.map(route => {
        return (
          <SubNavLink
            key={route.displayName}
            text={route.displayName}
            url={route.path}
            clickEvent={route.clickEvent}
            active={route.displayName === activeRoute}
            disabled={route.disabled}
            dataCy={`subnav-link-${route.displayName}`}
          />
        );
      })}
    </SubNavContainer>
  ) : (
    <></>
  );
};

export default SubNav;
