import React, { ReactElement, useEffect, useState, useMemo } from 'react';
import { areaColor, areaTitle, ProcessingState } from 'helpers/RoleProcessingProgressBarHelper';
import ProcessingProgressBar, { ProcessingBarData } from 'components/atoms/ProcessingProgressBar';
import ProcessingProgressBarKey from 'components/molecules/ProcessingProgressBarKey';
import useBatchLogs from 'helpers/useBatchLogs';
import { useTheme } from 'styled-components';

const useProcessingStatus = () => {
  const defaultStatuses: StatusValues = {
    created: 0,
    updated: 0,
    inserted: 0,
    processing: 0,
    skipped: 0,
    error: 0,
    total: 1,
  };

  const [statuses, setStatuses] = useState<StatusValues>(defaultStatuses);

  const updateStatuses = (statuses: StatusValues) => {
    setStatuses(statuses);
  };

  return { updateStatuses, statuses };
};

type RoleProcessingProgressBarProps = {
  width: string;
  requestId: string;
  showLabel?: boolean;
  onSegmentClick?: (segmentId: ProcessingState | string) => void;
  highlights?: string[];
  autoRefresh?: boolean;
};

const SegmentData = ({ count, total, segmentId, theme }): ProcessingBarData => {
  total = total || 1;
  const progressPercent = (count / total) * 100;
  const color = areaColor(segmentId, theme);
  const title = areaTitle(segmentId, count, progressPercent);
  return {
    count,
    title,
    color,
    segmentId,
  };
};

type StatusValues = {
  created: number;
  updated: number;
  inserted: number;
  processing: number;
  skipped: number;
  error: number;
  total: number;
};

const RoleProcessingProgressBar = ({
  width,
  requestId,
  showLabel = true,
  onSegmentClick,
  highlights,
  autoRefresh = false,
}: RoleProcessingProgressBarProps): ReactElement => {
  const [refreshToken, setRefreshToken] = useState<number>(Math.random());
  const [refreshCount, setRefreshCount] = useState<number>(0);

  const { getBatchLogsFacets } = useBatchLogs();

  const { updateStatuses, statuses } = useProcessingStatus();
  const theme = useTheme();

  const sumUsed = useMemo(() => {
    return Object.keys(statuses).reduce((previous, segmentId) => {
      if (['total', 'inserted'].includes(segmentId)) {
        // these are the non-active values
        return previous;
      } else {
        return previous + statuses[segmentId];
      }
    }, 0);
  }, [statuses]);

  const dynamicTotal = useMemo(() => {
    return Math.max(1, Math.max(sumUsed, statuses.total));
  }, [sumUsed, statuses.total]);

  const data = useMemo(() => {
    const dataPoints = [
      SegmentData({ count: statuses.created, total: dynamicTotal, segmentId: 'created', theme }),
      SegmentData({ count: statuses.updated, total: dynamicTotal, segmentId: 'updated', theme }),
      SegmentData({ count: statuses.skipped, total: dynamicTotal, segmentId: 'skipped', theme }),
      SegmentData({ count: statuses.processing, total: dynamicTotal, segmentId: 'processing', theme }),
      SegmentData({ count: dynamicTotal - sumUsed, total: dynamicTotal, segmentId: 'remaining', theme }),
      SegmentData({ count: statuses.error, total: dynamicTotal, segmentId: 'error', theme }),
    ];
    return dataPoints;
  }, [JSON.stringify(statuses)]);

  const keyData = useMemo(() => {
    return ['created', 'updated', 'skipped', 'processing', 'remaining', 'error'].map(segmentId => ({
      label: segmentId,
      color: areaColor(segmentId as ProcessingState, theme),
    }));
  }, []);

  const getBatchLogData = async (requestId: string) => {
    const allBatchLogFacets = await getBatchLogsFacets([requestId]);
    return allBatchLogFacets.batchLogStats?.find(r => r.requestId === requestId);
  };

  useEffect(() => {
    const loadData = async () => {
      const currentBatchLogFacets = await getBatchLogData(requestId);
      const newStatuses = {};
      currentBatchLogFacets.statuses?.forEach(s => {
        newStatuses[s.name] = s.count;
      });
      updateStatuses(newStatuses as StatusValues);
    };
    loadData();
    if (autoRefresh && sumUsed < dynamicTotal && refreshCount < 20) {
      // maximum of 20 pulls, so about 5 minutes of pulling, enough for 7,000+ roles
      setTimeout(() => {
        console.log('refreshing progress bar');
        setRefreshToken(Math.random());
        setRefreshCount(refreshCount + 1);
      }, 10000); // re-pull every 10s
    }
  }, [refreshToken, autoRefresh]);

  return (
    <div key={`roles-progress-bar${requestId}`}>
      {dynamicTotal !== 0 && (
        <>
          <div style={{ display: 'inline' }}>
            {showLabel && <ProcessingProgressBarKey data={keyData} />}
            <ProcessingProgressBar
              width={width}
              total={dynamicTotal}
              data={data}
              onSegmentClick={onSegmentClick}
              highlights={highlights}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default RoleProcessingProgressBar;
