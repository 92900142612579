declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    surfaceInformation: true;
    surfaceNeutral: true;
    surfaceCritical: true;
    surfaceSuccess: true;
    surfaceWarning: true;
    surfaceDisabled: true;
    surfaceProcessing: true;

    info: false;
    default: false;
    primary: false;
    secondary: false;
    error: false;
    success: false;
    warning: false;
  }
}

export const chipOverride = {
  styleOverrides: {
    root: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      height: '20px',
      borderRadius: '10px',
      width: 'fit-content',
      svg: {
        height: '50%',
        margin: '2px -8px 0 5px',
      },
    },
    label: {
      padding: '0px 8px 0px 8px',
    },
  },
};
