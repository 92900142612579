import styled from 'styled-components';

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 825px) {
    padding: 0px;
  }
`;

export default ButtonRow;
