import axios from 'helpers/api_helper';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import {
  IntegrationCredentials,
  SetIntegrationBody,
  VendorCompany,
  ApiVendorWithVendorCompany,
  ApiVendorCompanyConfiguration,
  VendorCompanyConfiguration,
  IntegrationSchedule,
} from 'types/types';
import {
  ExternalSkillMappingResponse,
  Integration,
  JobProfile,
  ExportSkillsResponse,
  ExportSkillsMappingResponse,
  GetIntegrationSkillVendorsResponse,
  CreateUpdateIntegrationSkillVendorResponse,
  DeleteIntegrationSkillVendorResponse,
  ErrorResponse,
  ApiErrorResponse,
  ErrorResponseWithStatus,
  ExternalSkillMapping,
  GetVendorsResponse,
} from 'types/api_responses';

const getIntegrations = async (companyId: string): Promise<Integration[]> => {
  try {
    const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integrations`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const setIntegration = async (
  companyId: string,
  vendorId: string,
  body: SetIntegrationBody,
  requestController: AbortController
): Promise<{ data: { vendorCompanyItem: VendorCompany; created: boolean } } | null> => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integrations/vendor/${vendorId}`;
  const response = await axios.post(url, body, { signal: requestController.signal });
  return response.data;
};

const getVendors = async (companyId: string): Promise<GetVendorsResponse> => {
  try {
    const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integrations/vendors`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const importRoles = async (
  companyId: string,
  vendorId: string
): Promise<{ status: 'success'; roles: [] | undefined; count: number } | ErrorResponseWithStatus> => {
  try {
    const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integrations/${vendorId}/job-profiles?import=true`;
    const response = await axios.post(url);
    return response.data;
  } catch (error) {
    console.error(error);
    if (isErrorApiErrorResponse(error)) {
      return {
        status: 'error',
        errorMessage: error.response.data,
      };
    } else {
      return { status: 'success', roles: undefined, count: 0 };
    }
  }
};

const exportRoles = async (companyId: string, vendorId: string): Promise<string> => {
  try {
    const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integrations/${vendorId}/job-profiles?export=true`;
    const response = await axios.post(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const fetchRolesToExport = async (
  companyId: string,
  vendorId: string
): Promise<{ roles: [] | undefined; count: number }> => {
  try {
    const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integrations/${vendorId}/job-profiles?toExport=true`;
    const response = await axios.post(url);
    return response.data;
  } catch (error) {
    console.error(error);
    return { roles: undefined, count: 0 };
  }
};

const fetchRolesToImport = async (companyId: string, vendorId: string): Promise<JobProfile[]> => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integrations/${vendorId}/job-profiles?toImport=true`;
  const jobProfiles = await axios.post<JobProfile[]>(url);
  return jobProfiles.data;
};

const fetchExternalSkills = async (companyId: string, vendorId: string): Promise<ExternalSkillMappingResponse> => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integrations/${vendorId}/external-skills?summary=true`;
  const externalSkills = await axios.post<ExternalSkillMappingResponse>(url, { params: { summary: true } });
  return externalSkills.data;
};

const exportSkills = async (companyId: string, vendorId: string): Promise<ExportSkillsResponse> => {
  try {
    const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integrations/${vendorId}/external-skills?import=true`;
    const response = await axios.post(url);
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      data: {
        status: 'Incomplete',
        message: 'Error importing skills',
      },
    };
  }
};
/* 
  This function calls the API to import external skills with mapping specifically for Workday. 
  Since this endpoint is exclusively for the Workday vendor, passing the vendorId as a parameter 
  is unnecessary. 
  We could check if the vendorId is Workday before calling this function, but that would require 
  the front-end to know the vendorId in advance. If a different vendorId is passed, the API call 
  will fail because the credentials are only valid for Workday.
*/
const exportSkillsMapping = (companyId: string): ExportSkillsMappingResponse => {
  const url = `${process.env.REACT_APP_API_COMPANY_DATASTORE_ROOT}/external-skill-mappings/${companyId}`;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-ecds-customerId': companyId,
    },
    scope: 'company_datastore_roles',
  };
  try {
    axios
      .post(url, config)
      .then(response => {
        console.log('API call succeeded:', response.data);
      })
      .catch(error => {
        console.error('API call failed:', error);
      });
    return {
      data: {
        status: 'Started',
        message: 'Importing skills with mapping started.',
      },
    };
  } catch (error) {
    console.error(error);
    return {
      data: {
        status: 'Incomplete',
        message: 'Error importing skills',
      },
    };
  }
};

type CreateSkillsResponse = {
  mappingType: string;
  count: number;
  result: {
    status: string;
    message: string;
    success: ExternalSkillMapping[];
    failed: ExternalSkillMapping[];
  };
};

const createSkills = async (companyId: string, vendorId: string): Promise<CreateSkillsResponse> => {
  try {
    const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integrations/${vendorId}/mappings/import`;
    const response = await axios.post(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getSkillVendors = async (
  companyId: string,
  vendorId: string,
  skillVendorId: string | undefined = undefined
): Promise<GetIntegrationSkillVendorsResponse> => {
  try {
    const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integrations/${vendorId}/get-skill-vendors`;
    const requestBody = {
      skillVendorId,
    };
    const response = await axios.post(url, requestBody);
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      data: [],
    };
  }
};

const createOrDeleteSkillVendor = async (
  companyId: string,
  vendorId: string,
  skillVendorId: string,
  skillVendorName: string,
  deleteSkillVendor = false
): Promise<CreateUpdateIntegrationSkillVendorResponse | DeleteIntegrationSkillVendorResponse | ErrorResponse> => {
  try {
    const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integrations/${vendorId}/skill-vendor`;
    const requestBody = {
      skillVendorId,
      skillVendorName,
      deleteSkillVendor,
    };
    const response = await axios.post(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error: unknown) {
    if (isErrorApiErrorResponse(error)) {
      return {
        errorMessage: error.response.data,
      };
    }
    return {
      errorMessage: 'Unknown error occurred',
    };
  }
};

const updateSkillVendor = async (
  companyId: string,
  vendorId: string,
  skillVendorId: string,
  skillVendorName: string
): Promise<CreateUpdateIntegrationSkillVendorResponse | DeleteIntegrationSkillVendorResponse | ErrorResponse> => {
  try {
    const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integrations/${vendorId}/skill-vendor`;
    const requestBody = {
      skillVendorId,
      skillVendorName,
    };
    const response = await axios.patch(url, requestBody, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return {
      errorMessage: `Unable to update Skill Vendor with id ${skillVendorId}`,
    };
  }
};

const getIntegrationCredentials = async (companyId: string, vendorId: string) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/vendor/${vendorId}/config`;
  const response = await axios.get<{ data: IntegrationCredentials }>(url);

  return response.data.data;
};

const setIntegrationCredentials = async (companyId: string, vendorId: string, data: IntegrationCredentials) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/vendor/${vendorId}`;
  const response = await axios.post(url, data);

  return response.data;
};

function isErrorApiErrorResponse(error: unknown): error is ApiErrorResponse {
  const err = error as Record<string, any>;
  return (
    typeof err === 'object' && err !== null && 'response' in err && 'data' in err.response && 'status' in err.response
  );
}

const verifyIntegrationCredentials = async (companyId: string, vendorId: string, vendorCompanyId: string) => {
  try {
    const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/vendor/${vendorId}/${vendorCompanyId}/verify`;
    await axios.get(url);
    return true;
  } catch (error) {
    return false;
  }
};

const getCompanyIntegrationConfig = async (
  companyId: string,
  vendorId: string,
  vendorCompanyId: string
): Promise<VendorCompany> => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integrations/${vendorId}/config/${vendorCompanyId}`;
  try {
    const { data } = await axios.get<VendorCompany>(url);
    return data;
  } catch (error: unknown) {
    const axiosError = error as AxiosError;
    Sentry.captureException(axiosError);
    const apiError = axiosError.response?.data as ApiErrorResponse;
    throw new Error(apiError?.response?.data || 'An error occurred. Please try again');
  }
};

const createOrUpdateCompanyIntegrationConfig = async (
  companyId: string,
  vendorId: string,
  vendorCompanyId: string,
  vendorCompanyConfig: Array<VendorCompanyConfiguration>
) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integrations/${vendorId}/config/${vendorCompanyId}`;
  try {
    const { data } = await axios.post<
      Array<VendorCompanyConfiguration>,
      { data: Array<ApiVendorCompanyConfiguration> }
    >(url, vendorCompanyConfig);
    return data;
  } catch (error: unknown) {
    const axiosError = error as AxiosError;
    Sentry.captureException(axiosError);
    const apiError = axiosError.response?.data as ApiErrorResponse;
    throw new Error(apiError?.response?.data || 'An error occurred. Please try again');
  }
};

const getVendorCompany = async (companyId: string, vendorId: string): Promise<VendorCompany> => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integrations/${vendorId}`;
  try {
    const { data } = await axios.get<ApiVendorWithVendorCompany>(url);
    return data.vendorCompany;
  } catch (error: unknown) {
    const axiosError = error as AxiosError;
    Sentry.captureException(axiosError);
    const apiError = axiosError.response?.data as ApiErrorResponse;
    throw new Error(apiError?.response?.data || 'An error occurred. Please try again');
  }
};

const getIntegrationSchedules = async (companyId: string): Promise<IntegrationSchedule[]> => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integration/schedule`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error: unknown) {
    const axiosError = error as AxiosError;
    Sentry.captureException(axiosError);
    const apiError = axiosError.response?.data as ApiErrorResponse;
    throw new Error(apiError?.response?.data || 'An error occurred. Please try again');
  }
};

const setIntegrationSchedules = async (schedule: IntegrationSchedule) => {
  const { companyId, vendorId } = schedule;
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integration/schedule/vendor/${vendorId}`;
  try {
    const response = await axios.post(url, schedule);
    return response.data;
  } catch (error: unknown) {
    const axiosError = error as AxiosError;
    Sentry.captureException(axiosError);
    const apiError = axiosError.response?.data as ApiErrorResponse;
    throw new Error(apiError?.response?.data || 'An error occurred. Please try again');
  }
};

const updateIntegrationSchedules = async ({ companyId, vendorId, job, schedule, id }: IntegrationSchedule) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/integration/vendor/${vendorId}/schedule/${id}`;
  try {
    const response = await axios.patch(url, { companyId, job, schedule });
    return response.data;
  } catch (error: unknown) {
    const axiosError = error as AxiosError;
    Sentry.captureException(axiosError);
    const apiError = axiosError.response?.data as ApiErrorResponse;
    throw new Error(apiError?.response?.data || 'An error occurred. Please try again');
  }
};

export default {
  getIntegrations,
  getIntegrationCredentials,
  setIntegrationCredentials,
  verifyIntegrationCredentials,
  setIntegration,
  getVendors,
  importRoles,
  exportRoles,
  fetchRolesToImport,
  fetchRolesToExport,
  fetchExternalSkills,
  exportSkills,
  exportSkillsMapping,
  getSkillVendors,
  createOrDeleteSkillVendor,
  updateSkillVendor,
  createSkills,
  getCompanyIntegrationConfig,
  createOrUpdateCompanyIntegrationConfig,
  getVendorCompany,
  getIntegrationSchedules,
  setIntegrationSchedules,
  updateIntegrationSchedules,
};
