import React, { useState, useEffect, ReactElement, useMemo } from 'react';
import styled from 'styled-components';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import UseCompanyContext from 'helpers/UseCompanyContext';
import ComplexInputBar from 'components/molecules/ComplexInputBar';
import RolesService from 'services/RolesService';

const HeaderSubnavContainer = styled(Box)`
  padding-bottom: 8px;
  margin: 0px ${props => props.theme.customSpacing.px.m}px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .complex-input-bar-container {
    max-width: calc(100vw - ${props => props.theme.customSpacing.px.base * 110}px);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: ${props => props.theme.customSpacing.px.xxs}px;

    .complex-input-bar-container {
      max-width: revert;
    }
  }
`;
interface Row {
  skillName: string;
  skillID: string;
  roleCount: number;
}
interface Skill {
  key: string;
  value: string;
  count: number;
}
function UniqueSkillsPage(): ReactElement {
  const columns: GridColDef<Row>[] = [
    { headerName: 'Skill Name', field: 'skillName', flex: 2.5, renderCell: param => <div>{param.value}</div> },
    { headerName: 'Skill ID', field: 'skillID', flex: 2.5, renderCell: param => <div>{param.value}</div> },
    {
      headerName: 'Role Count',
      field: `roleCount`,
      flex: 2.5,
      renderCell: param => (
        <Link
          href={`roles?rf=&af=%7B%22skills%22%3A%5B%7B%22key%22%3A%22${param.id}%22%2C%22value%22%3A%22${param.row.skillID}%22%7D%5D%7D&p=0&n=10&sc=roleName&sd=asc`}
        >
          {param.value}
        </Link>
      ),
    },
  ];

  const { companyId } = UseCompanyContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allSkills, setAllSkills] = useState<Skill[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const loadSkills = async () => {
    setIsLoading(true);
    await RolesService.getRoleFacets(companyId).then(response => {
      const skills = response.skills.sort((skillA: Skill, skillB: Skill) => {
        return skillA.key > skillB.key ? 1 : -1;
      });
      setAllSkills(skills);
    });
    setIsLoading(false);
  };

  useEffect(() => {
    loadSkills();
  }, [companyId]);

  const filteredSkills = useMemo(() => {
    const skills = allSkills.filter(skill => skill.key?.toLowerCase().includes(searchValue?.toLowerCase()));
    return skills;
  }, [searchValue, allSkills]);

  const rows: Row[] = filteredSkills.map(skill => {
    return {
      skillName: skill.key,
      skillID: skill.value,
      roleCount: skill.count,
    };
  });

  return (
    <MainContentTemplate pageTitle="Unique Skills" isFullWidth={false}>
      <ContentHeaderTemplate
        contentTitle="Unique Skills"
        subNavComponent={
          <HeaderSubnavContainer>
            <ComplexInputBar
              onChange={e => setSearchValue(e.target.value)}
              placeholder="Search Skills"
              value={searchValue}
            />
          </HeaderSubnavContainer>
        }
      />
      <Box sx={{ flexGrow: '1' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isLoading}
          getRowId={(row: Row) => {
            return row.skillName || row.skillID;
          }}
          disableSelectionOnClick
          autoHeight
          rowsPerPageOptions={[10, 25, 50, 100]}
          className="unique-skills-table"
          sortingOrder={['asc', 'desc']}
        />
      </Box>
    </MainContentTemplate>
  );
}

export default UniqueSkillsPage;
