import { useState, useMemo, useRef } from 'react';
import SkillsService from 'services/SkillsService';
import useCustomSkillsHelper from 'helpers/useCustomSkillsHelper';

interface LightcastSkill {
  id: string;
  name: string;
  infoUrl: string;
  type: { id: string; name: string };
}

interface CustomSkill {
  id: string;
  name: string;
}

export type AutocompleteSkill = LightcastSkill | CustomSkill;

type SkillSource = 'lightcast' | 'custom';

interface useGetSkillsReturn {
  updateSearch: (value?: string) => void;
  skillsList: AutocompleteSkill[];
  isLoading: boolean;
}

let abortController = new AbortController();

const useGetSkills = (source: SkillSource = 'lightcast'): useGetSkillsReturn => {
  const [skillsList, setSkillsList] = useState<AutocompleteSkill[]>([]);
  const { customSkills, loadingCustomSkills } = useCustomSkillsHelper();
  const [isLoading, setIsLoading] = useState(false);

  const searchableCustomSkills = useMemo(() => {
    const clean = (str: string) => str.trim().toLocaleLowerCase();

    return customSkills.map(skill => ({
      searchableString: `${clean(skill.skillName)}__${clean(skill.skillId)}`,
      customSkill: {
        name: skill.skillName,
        id: skill.skillId,
        customSkillId: skill.customSkillId,
      },
    }));
  }, [customSkills]);

  const updateSearch = async (value?: string) => {
    if (!value || value.length <= 0) return setSkillsList([]);

    try {
      setIsLoading(true);

      abortController?.abort();
      abortController = new AbortController();
      let skills;
      if (source === 'custom') {
        skills = searchableCustomSkills
          .filter(s => s.searchableString.match(value.trim().toLocaleLowerCase()))
          .map(s => s.customSkill);
      } else {
        const res = await SkillsService.getSkillFromAutocomplete(value, abortController);
        skills = res.data.skills;
      }

      setSkillsList(skills);
    } catch (e: any) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  // Unfortunately this is necessary because our AutocompleteInput uses a
  // debounce function that never updates, even if the function it is debouncing
  // changes.  This way the function we're returning doesn't ever really need to
  // change but will always call the current 'updateSearch' function.  We could
  // move this into a debounceCallback hook maybe, and then use that in the
  // AutocompleteInput component, but I'm not sure we have time for that at the
  // moment.
  const updateSearchRef = useRef(updateSearch);
  updateSearchRef.current = updateSearch;

  const updateSearchDereferenced = (...args) => {
    return updateSearchRef.current(...args);
  };

  return {
    updateSearch: updateSearchDereferenced,
    skillsList,
    isLoading: isLoading || (loadingCustomSkills && source === 'custom'),
  };
};

export default useGetSkills;
