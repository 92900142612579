import React from 'react';
import InputBar from 'components/molecules/InputBar';
import RequiredFieldIndicator from 'components/atoms/RequiredFieldIndicator';
import FormPartLabel from 'components/atoms/formElements/FormPartLabel';
import { useField, useFormikContext, ErrorMessage } from 'formik';
import CompanyAutocomplete from 'components/molecules/CompanyAutocomplete';
import PropTypes from 'prop-types';
import InputRow from 'components/atoms/InputRow';

const FormikCompanyAutocomplete = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const form = useFormikContext();

  return (
    <>
      <InputRow className="input-row">
        <FormPartLabel htmlFor={props.id || props.name}>
          {label}
          {meta.required && <RequiredFieldIndicator />}
        </FormPartLabel>
        <CompanyAutocomplete
          inputValue={{ value: props.value, label: props.value }}
          changeHandler={company => {
            form.setFieldValue(field.name, company.id);
          }}
        />
        <InputBar {...field} {...props} type="hidden" />
        <div>{field.value}</div>
        {meta.error ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
      </InputRow>
    </>
  );
};

FormikCompanyAutocomplete.propTypes = {
  label: PropTypes.string,
  id: PropTypes.any,
  name: PropTypes.any,
  value: PropTypes.any,
};

export default FormikCompanyAutocomplete;
