import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserForm from 'components/organisms/UserForm';
import useCompanyContext from 'helpers/UseCompanyContext';
import 'pages/ProfilePage.css';
import 'components/organisms/Form.css';
import axios from 'helpers/api_helper';
import ReactGA from 'react-ga';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import MainContentTemplate from 'pages/templates/MainContentTemplate';

const UserDetailsPage = () => {
  const { companyId } = useCompanyContext();
  const { profileId } = useParams();
  const navigate = useNavigate();
  const [profile, setProfile] = useState();
  const [fullName, setFullName] = useState();
  const [profileCopy, setProfileCopy] = useState();
  const [confirmCancelModalOpen, setConfirmCancelModalOpen] = useState(false);
  const [popupTitle, setPopupTitle] = useState();
  const [popupPrimaryMessage, setPopupPrimaryMessage] = useState();
  const [userDetailsChanged, setUserDetailsChanged] = useState(false);

  const navigateUserManagementPage = () => {
    navigate('/admin/user-management');
  };

  const pageTitle = useMemo(() => {
    return profileId ? 'Edit User' : 'Add User';
  }, [profileId]);

  const initialProfile = useMemo(() => {
    return {
      details: {
        first_name: '',
        last_name: '',
        email: '',
      },
      role: 'analysts_read_only',
      company_id: companyId,
    };
  }, [companyId]);

  useEffect(() => {
    if (!profile && profileId) {
      const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/user/${profileId}`;
      axios
        .get(url)
        .then(res => {
          setProfile(res.data.userInfo);
        })
        .catch(err => {
          if (err.response.status === 404) {
            navigate('/404');
          }
        });
    } else if (!profile && !profileId) {
      setProfile(initialProfile);
    }
  }, [profileId, initialProfile, companyId, profile]);

  useEffect(() => {
    setProfileCopy({ ...profile });
  }, [profile]);

  useEffect(() => {
    if (profileCopy !== undefined && Object.keys(profileCopy).length > 0) {
      const tempFullName = `${profileCopy.details.first_name} ${profileCopy.details.last_name}`;
      setFullName(tempFullName);
    }
  }, [profileCopy]);

  const handleSaveSuccess = async profileId => {
    // Update UserDetailsHeader & status message without page reload
    try {
      const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/user/${profileId}`;
      const resp = await axios.get(url);
      setProfile(resp.data.userInfo);
    } catch (e) {
      console.error(e);
    }
    setUserDetailsChanged(false);
  };

  const handleBackClick = () => {
    if (userDetailsChanged) {
      openConfirmCancelModal('back');
    } else {
      navigateUserManagementPage();
    }
  };

  const fullNameAlertHelper = () => {
    if (fullName && fullName.length > 0 && fullName !== ' ') {
      return ` ${fullName}`;
    }
    return '';
  };

  const openConfirmCancelModal = action => {
    if (action) {
      switch (action) {
        case 'cancel':
          if (profileId) {
            setPopupTitle('Cancel User Edits');
            setPopupPrimaryMessage(`Cancel without saving changes to user${fullNameAlertHelper()}?`);
          } else {
            setPopupTitle('Cancel Adding User');
            setPopupPrimaryMessage(`Cancel without creating new user${fullNameAlertHelper()}?`);
          }
          break;
        case 'back':
          setPopupTitle('Return to Previous Page');
          if (profileId) {
            setPopupPrimaryMessage(
              `Return to the previous page without saving changes to user${fullNameAlertHelper()}?`
            );
          } else {
            setPopupPrimaryMessage(`Return to the previous page without creating new user${fullNameAlertHelper()}?`);
          }
          break;
        default:
          break;
      }
      setConfirmCancelModalOpen(true);
    }
  };

  const closeConfirmCancelModal = action => {
    if (action) {
      switch (action) {
        case 'cancel':
          setConfirmCancelModalOpen(false);
          break;
        case 'confirm':
          if (profileId) {
            reactEvent('edit', 'cancel');
          } else {
            reactEvent('add', 'cancel');
          }
          navigateUserManagementPage();
          break;
        default:
          break;
      }
    }
  };

  const userDetailsChangedHandler = detailsChanged => {
    setUserDetailsChanged(detailsChanged);
  };

  // Log Google Analytics event
  const reactEvent = (action, label) => {
    ReactGA.event({
      category: 'user',
      action,
      label,
    });
  };

  return (
    <MainContentTemplate pageTitle={pageTitle} isFullWidth={false} showHeaderDivider={true}>
      <ContentHeaderTemplate
        contentTitle={profileId ? fullName : 'Add User'}
        backClickHandler={handleBackClick}
        fullWidthDivider={false}
        displayDivider={true}
      />
      <UserForm
        originalProfile={profile}
        fullName={fullName}
        profileCompanyId={companyId}
        profileId={profileId}
        confirmCancelModalOpen={confirmCancelModalOpen}
        openConfirmCancelModal={openConfirmCancelModal}
        closeConfirmCancelModal={closeConfirmCancelModal}
        popupPrimaryMessage={popupPrimaryMessage}
        popupTitle={popupTitle}
        reactEvent={reactEvent}
        handleSaveSuccess={handleSaveSuccess}
        userDetailsChangedHandler={userDetailsChangedHandler}
      />
    </MainContentTemplate>
  );
};

export default UserDetailsPage;
