import React, { useState } from 'react';
import styled from 'styled-components';
import { Editor } from 'draft-js';

const StyledWrapper = styled.div<{ $opaque: boolean; $editorFocus: boolean; $readOnly: boolean; $noPadding: boolean }>`
  width: 100%;
  padding: 1em;
  font-weight: 500;
  min-height: ${props => (props.$readOnly ? '' : '150px')};
  color: ${props => props.theme.colors.text.default};
  border-radius: ${props => props.theme.borderRadius.default};
  border: 1px solid ${props => props.theme.colors.border.default};

  ${props =>
    props.$editorFocus
      ? `
    & :focus-visible > .DraftEditor-root {
      box-shadow: ${props.theme.boxShadow.textFieldFocus};
      border:none;
      outline-offset: 0px;
    },
    & div > :focus > .DraftEditor-root {
      box-shadow: ${props.theme.boxShadow.textFieldFocus};
      border:none;
      outline-offset: 0px;
    },`
      : ''}
  ${props =>
    props.$readOnly
      ? `
    background-color: transparent;
    border: 0px;
    pointer: cursor;
    color: ${props.theme.colors.text.disabled} !important;
  `
      : ''}

  ${props =>
    props.$noPadding
      ? `
    padding: 0;
  `
      : ''}

  & .public-DraftEditor-content {
    line-height: ${props => props.theme.customTypography.desktop.body.lineHeight};
  }

  & .public-DraftEditor-content h1 {
    font-size: ${props => props.theme.customTypography.desktop.displayXLarge.size};
    font-weight: ${props => props.theme.customTypography.desktop.displayXLarge.weight};
    line-height: ${props => props.theme.customTypography.desktop.displayXLarge.lineHeight};
    margin: 0.5em 0px;
  }

  & .public-DraftEditor-content h2 {
    font-size: ${props => props.theme.customTypography.desktop.displayLarge.size};
    font-weight: ${props => props.theme.customTypography.desktop.displayLarge.weight};
    line-height: ${props => props.theme.customTypography.desktop.displayLarge.lineHeight};
    text-transform: inherit;
    text-align: inherit;
    margin: 0.5em 0px;
  }

  & .public-DraftEditorPlaceholder-inner {
    color: ${props => props.theme.colors.text.disabled};
    line-height: 1.8em;
    pointer-events: none;
    position: absolute;
  }

  ${props =>
    props.$opaque
      ? `
      opacity: .5;
      border-radius: ${props => props.theme.borderRadius.default};
      border: 1px solid ${props => props.theme.colors.border.default};
      pointer: cursor;
    `
      : ''}
`;

const StyledEditor = React.forwardRef((props: any, ref) => {
  const [editorFocus, setEditorFocus] = useState(false);

  return (
    <StyledWrapper
      $opaque={props.opaque}
      data-cy={props.dataCy}
      $editorFocus={editorFocus}
      $readOnly={props.readOnly}
      $noPadding={props.noPadding}
    >
      <Editor {...props} ref={ref} onFocus={() => setEditorFocus(true)} onBlur={() => setEditorFocus(false)} />
    </StyledWrapper>
  );
});
StyledEditor.displayName = 'StyledEditor';
export default StyledEditor;
