import React, { ReactElement, useMemo, useState } from 'react';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import debounce from 'lodash/debounce';

interface Item {
  id: string;
  label: string;
}

interface AutocompleteProps {
  list: Item[];
  onInputChange: (value: string) => void;
  onItemSelect: (item: any) => void;
  isLoading: boolean;
  placeholder?: string;
  isDisabled?: boolean;
}

const AutocompleteInput = ({
  list,
  onInputChange,
  onItemSelect,
  isLoading,
  placeholder = '',
  isDisabled = false,
}: AutocompleteProps): ReactElement => {
  const [selectedValue, setSelectedValue] = useState<Item | string | null>(null);
  const [inputValue, setInputvalue] = useState<string>('');

  const handleOnChange = (event, value) => {
    onItemSelect(value);
    setInputvalue('');
    setSelectedValue(null);
  };

  const debounceOnInputChange = useMemo(() => debounce(onInputChange, 300), []);

  const handleOnInputChange = (event, value, reason) => {
    if (reason === 'reset') return;
    setInputvalue(value);
    debounceOnInputChange(value);
  };

  return (
    <Autocomplete
      freeSolo
      id="autocomplete-skills"
      size="small"
      options={list}
      getOptionLabel={item => (typeof item === 'string' ? item : item?.label)}
      onChange={handleOnChange}
      loading={isLoading}
      value={selectedValue}
      inputValue={inputValue}
      onInputChange={handleOnInputChange}
      autoHighlight={true}
      sx={{ width: '100%' }}
      disabled={isDisabled}
      renderInput={params => (
        <TextField
          autoFocus
          {...params}
          size="small"
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            onKeyDown: e => {
              if (e.key === 'Enter' && list.length <= 0) {
                e.stopPropagation();
              }
            },
            startAdornment: (
              <InputAdornment position="start">
                <BaseIcon type={IconType.Search} />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteInput;
