import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { IconColorFilter, SpacingSizes } from 'types/types';

const Wrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.surface.informationSubdued};
  border-radius: ${props => props.theme.borderRadius.default};
  background-color: ${props => props.theme.colors.surface.informationSubdued};
  color: ${props => props.theme.colors.text.information};
  padding: 0.1rem 1rem;
  margin-right: 2px;
  margin-bottom: 2px;
  white-space: nowrap;

  font-style: normal;
  font-weight: 500;
`;

const GenericPill = ({ item, removeHandler, dataCy = 'pill-item' }) => {
  return (
    <Wrapper data-cy={dataCy} className="pill-item">
      {item.name}
      <div style={{ display: removeHandler ? 'inline-block' : 'none' }}>
        <BaseIcon
          type={IconType.Close}
          size="12px"
          onIconClick={() => removeHandler && removeHandler(item)}
          dataCy={`remove-item-${item.name}`}
          alt={`remove ${item.name}`}
          colorFilter={IconColorFilter.Subdued}
          marginLeft={SpacingSizes.XXS}
        />
      </div>
    </Wrapper>
  );
};

GenericPill.propTypes = {
  item: PropTypes.any,
  removeHandler: PropTypes.func,
  dataCy: PropTypes.string,
};

export default GenericPill;
