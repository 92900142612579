import React, { ReactElement } from 'react';
import DialogModal from 'components/molecules/DialogModal';
import '@pathofdev/react-tag-input/build/index.css';
import RoleEditForm from 'components/organisms/RoleEditForm';
import useRoleEditContext from 'helpers/useRoleEditContext';
import ReactGA from 'react-ga';
import styled from 'styled-components';

const FormWrapper = styled.div`
  & .edit-role-form {
    padding: ${props => props.theme.customSpacing.px.base * 8}px;
    padding-top: ${props => props.theme.customSpacing.px.base * 4}px;
  }
`;

interface RoleEditModalProps {
  isOpen?: boolean;
  closeHandler: (reload?: boolean) => void;
  isCopyingRole?: boolean;
  shouldClearRoleAfter?: boolean;
  callCopyRoleSuccessHandler: (roleId: string) => void;
}

const logAnalytics = (action, event) => {
  ReactGA.event({
    category: 'roles',
    action,
    label: event,
  });
};

const RoleEditModal = ({
  isOpen = false,
  closeHandler,
  callCopyRoleSuccessHandler,
  shouldClearRoleAfter = false,
}: RoleEditModalProps): ReactElement => {
  const { role, setRole, isLoading, isCopying, saveRole } = useRoleEditContext();
  const actionVerb = isCopying ? 'copy' : role?.roleId ? 'edit' : 'create';

  const saveRoleDetails = async (values): Promise<any> => {
    try {
      const saveResult = await saveRole(values);
      console.log('Details Saved');
      logAnalytics(actionVerb, `${actionVerb} role successful`);

      if (saveResult && isCopying) callCopyRoleSuccessHandler(saveResult.roleId);

      return saveResult;
    } catch (error: any) {
      if (error.response.status === 409) {
        logAnalytics(actionVerb, `${actionVerb} role failed - duplicate jobCode`);
        alert('Error saving role. Provided job code already exists');
      } else {
        console.error('ERROR SENDING TO LAMBDA TO SAVE ROLE', error);
        logAnalytics(actionVerb, `${actionVerb} role failed`);
        alert('Error saving role');
      }
      return false;
    }
  };

  const callCloseHandler = (reload?: boolean) => {
    if (shouldClearRoleAfter) {
      setRole(undefined);
    }
    closeHandler(reload);
  };
  return (
    <DialogModal
      dialogOpen={isOpen}
      showDialogTitle={false}
      content={
        <FormWrapper data-cy="role-edit-form-container">
          <RoleEditForm
            role={role}
            saveRole={saveRoleDetails}
            isLoading={isLoading}
            closeHandler={callCloseHandler}
            isCopyingRole={isCopying}
            isModal={true}
          />
        </FormWrapper>
      }
      buttonGroup={[]}
      maxWidth="md"
      fullWidth={true}
    />
  );
};

export default RoleEditModal;
