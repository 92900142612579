import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { SectionSticky, Section, Column } from 'pages/admin/uiLibrary/UILibraryComponents';
import Link from '@mui/material/Link';

const TextFieldCustom = styled(TextField)`
  & input {
    padding: 8px 16px;
  }
`;

const FormControlLabelCustom = styled(FormControlLabel)`
  white-space: nowrap;
`;

const FormControlLabelCustom2 = styled(FormControlLabel)`
  justify-content: space-between;
  margin: 0px;
  margin: 4px 0px 2px;

  & .MuiFormControlLabel-label {
    margin-right: 8px;
  }

  & .MuiSelect-select {
    padding: 8px 16px;
  }
`;

const CopyText = styled(Typography)`
  font-family: monospace;
  display: inline-block;
  padding: ${props => `${props.theme.customSpacing.px.xxs}px ${props.theme.customSpacing.px.base * 4}px`};
  border-radius: ${props => props.theme.borderRadius.default};
  background-color: ${props => props.theme.colors.surface.informationSubdued};
  white-space: nowrap;
  width: 325px;
  overflow: auto;
`;

const SwitchDemo = (): ReactElement<any> => {
  const [switchStatus, setSwitchStatus] = useState(false);
  const [labelledSwitchStatus, setLabelledSwitchStatus] = useState(false);
  const [labelledSwitchColor, setLabelledSwitchColor] = useState<any>('iconSuccess');
  const [isLabelledSwitchDisabled, setIsLabelledSwitchDisabled] = useState(false);
  const [uncheckedLabelText, setUncheckedLabelText] = useState('Hello');
  const [checkedLabelText, setCheckedLabelText] = useState('Goodbye');
  const [uncheckedThumbWidth, setUncheckedThumbWidth] = useState(50);
  const [checkedThumbWidth, setCheckedThumbWidth] = useState(65);
  const [labelledSwitchHeight, setLabelledSwitchHeight] = useState(24);

  const switchHandler = () => {
    setSwitchStatus(!switchStatus);
  };

  const labelledSwitchHandler = () => {
    setLabelledSwitchStatus(!labelledSwitchStatus);
  };

  const updateSize = (element: 'unchecked' | 'checked' | 'height', e: any) => {
    switch (element) {
      case 'unchecked':
        setUncheckedThumbWidth(e.target.value);
        break;
      case 'checked':
        setCheckedThumbWidth(e.target.value);
        break;
      case 'height':
        setLabelledSwitchHeight(e.target.value);
        break;
    }
  };

  const setDefaultValues = () => {
    setIsLabelledSwitchDisabled(false);
    setCheckedLabelText('checked');
    setUncheckedLabelText('unchecked');
    setUncheckedThumbWidth(80);
    setCheckedThumbWidth(70);
    setLabelledSwitchColor('iconSuccess');
    setLabelledSwitchHeight(24);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <SectionSticky
          defaultComponent={
            <>
              <div style={{ textAlign: 'center' }}>
                <Typography variant="strong" component="div" sx={{ whiteSpace: 'nowrap' }}>
                  Default Switch
                </Typography>
                <Switch checked={switchStatus} onChange={switchHandler} />
              </div>
              <br />
              <div style={{ textAlign: 'center' }}>
                <Typography variant="strong" component="div" sx={{ whiteSpace: 'nowrap' }}>
                  Labelled Switch
                </Typography>
                <Switch size="labelled" checked={switchStatus} onChange={switchHandler} />
              </div>
              <br />
              <br />
              <Typography variant="caption">{switchStatus ? 'Switch is on' : 'Switch is off'}</Typography>
            </>
          }
        />
        <Section>
          <Typography variant="strong">Prop Options</Typography>
          <Column>
            <FormControlLabelCustom control={<Switch defaultChecked />} labelPlacement="end" label="Default Checked" />
            <FormControlLabelCustom
              control={<Switch color="iconWarning" />}
              labelPlacement="end"
              label="Color Variant"
            />
            <FormControlLabelCustom control={<Switch disabled />} labelPlacement="end" label="Disabled" />
          </Column>
        </Section>
        <Section>
          <Typography variant="strong">Label Placement</Typography>
          <Column>
            <FormControlLabelCustom control={<Switch />} labelPlacement="start" label="Start" />
            <FormControlLabelCustom control={<Switch />} labelPlacement="end" label="End" />
            <FormControlLabelCustom control={<Switch />} labelPlacement="top" label="Top" />
            <FormControlLabelCustom control={<Switch />} labelPlacement="bottom" label="Bottom" />
          </Column>
        </Section>
        <Divider />
        <Section>
          <Typography variant="strong">Color Options</Typography>
          <Column>
            <FormControlLabelCustom
              control={<Switch defaultChecked color="iconSuccess" />}
              label="Color: 'iconSuccess' (default)"
            />
            <FormControlLabelCustom
              control={<Switch defaultChecked color="iconInformation" />}
              label="Color: 'iconInformation'"
            />
            <FormControlLabelCustom
              control={<Switch defaultChecked color="iconWarning" />}
              label="Color: 'iconWarning'"
            />
            <FormControlLabelCustom
              control={<Switch defaultChecked color="iconCritical" />}
              label="Color: 'iconCritical'"
            />
          </Column>
        </Section>
        <Divider />
        <Section>
          <Typography variant="strong">Size Options</Typography>
          <Column>
            <FormControlLabelCustom control={<Switch size="medium" />} label="Size: Medium (default)" />
            <FormControlLabelCustom control={<Switch size="small" />} label="Size: Small" />
            <FormControlLabelCustom
              control={<Switch size="labelled" checkedlabel="Reviewed" uncheckedlabel="Unreviewed" />}
              label="Size: Labelled"
            />
          </Column>
        </Section>
        <Section>
          <Typography variant="strong">Labelled Options</Typography>
          <Column>
            <div>
              <Typography variant="subHeading" component="div">
                Default
              </Typography>
              <FormControlLabelCustom
                control={<Switch size="labelled" checked={labelledSwitchStatus} onChange={labelledSwitchHandler} />}
                label=""
              />
              <CopyText variant="caption">{`<Switch size='labelled' checked='${labelledSwitchStatus}'/>`}</CopyText>
            </div>
            <br />
            <br />
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="subHeading">Custom</Typography>
                <Link variant="caption" onClick={setDefaultValues}>
                  Set to Defaults
                </Link>
              </div>
              <FormControlLabelCustom
                control={
                  <Switch
                    disabled={isLabelledSwitchDisabled}
                    size="labelled"
                    checked={labelledSwitchStatus}
                    color={labelledSwitchColor}
                    uncheckedlabel={uncheckedLabelText}
                    checkedlabel={checkedLabelText}
                    uncheckedthumbwidth={uncheckedThumbWidth || 80}
                    checkedthumbwidth={checkedThumbWidth || 70}
                    labelledheight={labelledSwitchHeight || 24}
                    onChange={labelledSwitchHandler}
                  />
                }
                label=""
              />
              <CopyText variant="caption">
                {`<Switch
                  size='labelled'
                  checked='${labelledSwitchStatus}'
                  disabled='${isLabelledSwitchDisabled}'
                  uncheckedlabel='${uncheckedLabelText}'
                  checkedlabel='${checkedLabelText}'
                  uncheckedthumbwidth='${uncheckedThumbWidth}'
                  checkedthumbwidth='${checkedThumbWidth}'
                  labelledheight='${labelledSwitchHeight}'
                  color='${labelledSwitchColor}'
                  />`}
              </CopyText>
            </div>
            <br />
            <FormControlLabelCustom2
              control={
                <Checkbox
                  value={isLabelledSwitchDisabled}
                  checked={isLabelledSwitchDisabled}
                  onChange={e => setIsLabelledSwitchDisabled(e.target.checked)}
                />
              }
              label="disable switch"
              labelPlacement="start"
            />
            <FormControlLabelCustom2
              control={
                <TextFieldCustom value={uncheckedLabelText} onChange={e => setUncheckedLabelText(e.target.value)} />
              }
              label="uncheckedlabel:"
              labelPlacement="start"
            />
            <FormControlLabelCustom2
              control={<TextFieldCustom value={checkedLabelText} onChange={e => setCheckedLabelText(e.target.value)} />}
              label="checkedlabel:"
              labelPlacement="start"
            />
            <FormControlLabelCustom2
              control={
                <TextFieldCustom value={uncheckedThumbWidth} onChange={e => updateSize('unchecked', e.nativeEvent)} />
              }
              label="uncheckedthumbwidth:"
              labelPlacement="start"
            />
            <FormControlLabelCustom2
              control={
                <TextFieldCustom value={checkedThumbWidth} onChange={e => updateSize('checked', e.nativeEvent)} />
              }
              label="checkedthumbwidth:"
              labelPlacement="start"
            />
            <FormControlLabelCustom2
              control={
                <TextFieldCustom value={labelledSwitchHeight} onChange={e => updateSize('height', e.nativeEvent)} />
              }
              label="labelledheight:"
              labelPlacement="start"
            />
            <FormControlLabelCustom2
              control={
                <Select
                  id="demo-switch-color"
                  value={labelledSwitchColor}
                  label="Color2"
                  onChange={(e: SelectChangeEvent) => setLabelledSwitchColor(e.target.value)}
                >
                  <MenuItem key="color-option-success" value="iconSuccess">
                    iconSuccess (default)
                  </MenuItem>
                  <MenuItem key="color-option-information" value="iconInformation">
                    iconInformation
                  </MenuItem>
                  <MenuItem key="color-option-warning" value="iconWarning">
                    iconWarning
                  </MenuItem>
                  <MenuItem key="color-option-critical" value="iconCritical">
                    iconCritical
                  </MenuItem>
                </Select>
              }
              label="color:"
              labelPlacement="start"
            />
          </Column>
        </Section>
      </div>
    </>
  );
};

export default SwitchDemo;
