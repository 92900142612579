import useCompanyContext from 'helpers/UseCompanyContext';
import IntegrationService from 'services/IntegrationService';

export type IntegrationOptionType = {
  id: string;
  vendorId: string;
  vendorName: string;
  loading: boolean;
};

type useGetCompanyVendorIntegrationOptionsReturnObject = {
  getCompanyIntegrations: () => Promise<Array<IntegrationOptionType>>;
};

const useGetCompanyVendorIntegrationOptions = (): useGetCompanyVendorIntegrationOptionsReturnObject => {
  const { companyId } = useCompanyContext();

  const getCompanyIntegrations = async () => {
    const response = await IntegrationService.getIntegrations(companyId);
    const intOptions: Array<IntegrationOptionType> = response.map(option => ({
      id: option.vendorCompanyId,
      vendorId: option.vendorId,
      vendorName: option.vendor.vendorName,
      loading: false,
    }));
    return intOptions;
  };

  return { getCompanyIntegrations };
};

export default useGetCompanyVendorIntegrationOptions;
