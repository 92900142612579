import React, { ReactElement } from 'react';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import ChevronRight from 'components/atoms/icons/ChevronRight';
import { To, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${props => props.theme.customSpacing.px.m}px;
`;

const RowItem = styled.div`
  cursor: pointer;
  padding: ${props => `${props.theme.customSpacing.px.base * 4}px`} 0px;
  margin: ${props => `0px ${props.theme.customSpacing.px.m}px`};
  border-bottom: 1px solid ${props => props.theme.colors.border.subdued};

  &:last-child {
    border-bottom: none;
  }

  &:hover svg path {
    fill: ${props => props.theme.colors.interactive.default};
  }

  @media (max-width: 768px) {
    margin: 0px;
  }
`;

const RowItemTitle = styled(Typography)`
  ${RowItem}:hover & {
    color: ${props => props.theme.colors.interactive.default};
  }
`;

const RowIconWrapper = styled.div`
  display: flex;
  justify-content: right;
  padding-right: ${props => `${props.theme.customSpacing.px.xs}px`};
  transition: padding-right ease-in-out;

  ${RowItem}:hover & {
    padding-right: 0px;
  }
`;

const SettingsOption = (title: string, description: string, navigateTo: To, dataCy: string): ReactElement => {
  const navigate = useNavigate();

  return (
    <RowItem onClick={() => navigate(navigateTo)} data-cy={dataCy}>
      <Grid container>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={12} sm={7}>
              <RowItemTitle variant="heading">{title}</RowItemTitle>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography color="text.subdued">{description}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
          <RowIconWrapper>
            <ChevronRight />
          </RowIconWrapper>
        </Grid>
      </Grid>
    </RowItem>
  );
};
const IntegrationSettingsPage = (): ReactElement => {
  return (
    <>
      <MainContentTemplate pageTitle="Integration Settings" isFullWidth={false}>
        <ContentHeaderTemplate contentTitle="Workday" fullWidthDivider={false} displayDivider={true} />
        <Wrapper>
          {SettingsOption(
            'Import Roles',
            'Import Roles from integration',
            '/admin/integration/roles/import',
            'link-integration-roles'
          )}
          {SettingsOption(
            'Export Roles',
            'Export Roles to integration',
            '/admin/integration/roles/export',
            'link-integration-roles'
          )}
          {SettingsOption(
            'External Skills',
            'Export Skills to integration',
            '/admin/integration/external-skills',
            'link-integration-external-skills'
          )}
        </Wrapper>
      </MainContentTemplate>
    </>
  );
};

export default IntegrationSettingsPage;
