import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { IconColorFilter } from 'types/types';

interface demoProps {
  title: string;
  children: ReactElement<any>;
  id?: string;
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void;
}
interface defaultSectionProps {
  defaultComponent: ReactElement<any>;
  componentOptions?: ReactElement<any>;
  alignComponentCenter?: boolean;
}

// Local Style Components

const AccordionDetailsContent = styled.div`
  overflow-x: auto;
  overflow-y: visible;
`;

// Exported Style Components & ReactElements

export const Section = styled.div`
  padding-right: ${props => props.theme.customSpacing.px.s}px;
  padding-bottom: ${props => props.theme.customSpacing.px.s}px;

  &:not(:last-child) {
    margin-right: ${props => props.theme.customSpacing.px.s}px;
    border-right: 1px solid ${props => props.theme.colors.border.default};
  }
`;

export const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${props => props.theme.customSpacing.px.xs}px;
  margin-top: ${props => props.theme.customSpacing.px.xs}px;
`;

export const UILibrarySection = ({ title, children, id, onChange }: demoProps): ReactElement<any> => {
  const theme = useTheme();
  return (
    <Accordion onChange={onChange}>
      <AccordionSummary
        expandIcon={<BaseIcon type={IconType.ChevronDown} colorFilter={IconColorFilter.Information} size="12px" />}
        sx={{ backgroundColor: `${theme.colors.surface.neutralSubdued}` }}
      >
        <div style={{ marginLeft: `${theme.customSpacing.px.xs}px` }}>
          <Typography
            color="text.information"
            id={id}
            variant="subHeading"
            style={{ marginLeft: `${theme.customSpacing.px.xs}px` }}
          >
            {title}
          </Typography>
        </div>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <AccordionDetailsContent>{children}</AccordionDetailsContent>
      </AccordionDetails>
    </Accordion>
  );
};

export const SectionSticky = ({
  defaultComponent,
  componentOptions,
  alignComponentCenter = true,
}: defaultSectionProps): ReactElement<any> => {
  const theme = useTheme();
  return (
    <Section
      style={{
        position: 'sticky',
        left: '0px',
        backgroundColor: `${theme.colors.surface.default}`,
        zIndex: '100',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div>
        <Typography variant="strong">Default</Typography>
        <Column style={{ alignItems: alignComponentCenter ? 'center' : 'normal' }}>{defaultComponent}</Column>
      </div>
      <br />
      <Divider />
      <br />
      {componentOptions && (
        <div>
          <Typography variant="strong">Options</Typography>
          <Column>{componentOptions}</Column>
        </div>
      )}
    </Section>
  );
};
