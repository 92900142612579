import * as Types from 'types/types';

class Role {
  data: any;

  constructor(roleJson = {}) {
    this.data = roleJson;
  }

  get id(): string {
    return this.data.roleId;
  }

  get showReason(): string {
    return this.data.showReason;
  }

  set showReason(val: string) {
    this.data.showReason = val;
  }

  get roleName(): string {
    return this.data.roleName || '';
  }

  set roleName(val: string) {
    this.data.roleName = val;
  }

  get roleId(): string {
    return this.data.roleId;
  }

  get customerId(): string {
    return this.data.customerId;
  }

  get emsiTitle(): Types.EmsiTitle {
    return {
      name: this.data.emsiTitleName || 'n/a',
      id: this.data.emsiTitleId || null,
    };
  }

  get emsiTitleId(): string {
    return this.data.emsiTitleId || 'ET0000000000000000';
  }

  get emsiTitleName(): string {
    return this.data.emsiTitleName || 'n/a';
  }

  get needsApproval(): boolean {
    return this.data.needsApproval;
  }

  set needsApproval(val: boolean) {
    this.data.needsApproval = val;
  }

  get soc(): string {
    return this.data.soc;
  }

  get socName(): string {
    return this.data.socName || 'n/a';
  }

  get specializedOccupationId(): string {
    return this.data.specializedOccupationId;
  }

  get specializedOccupationName(): string {
    return this.data.specializedOccupationName || 'n/a';
  }

  get jobFunction(): string {
    return this.data.jobFunction || 'n/a';
  }

  get jobFamily(): string {
    return this.data.jobFamily || 'n/a';
  }

  get jobFunctionRaw(): string {
    return this.data.jobFunction;
  }

  get jobFamilyRaw(): string {
    return this.data.jobFamily;
  }

  get jobCode(): string {
    return this.data.jobCode || '';
  }

  get jobTitle(): string {
    return this.data.jobTitle;
  }

  get description(): string | null {
    return this.data.details?.description || null;
  }

  get notes(): string | null {
    return this.data.details?.notes || null;
  }

  get skills(): any[] {
    return this.data.skills || [];
  }

  get ignoredSkills(): any[] {
    return this.data.ignoredSkills;
  }

  get benchmark(): any {
    return this.data.benchmark;
  }

  get benchmarkId(): string {
    return this.data.benchmarkId;
  }

  get reviewed(): boolean {
    return this.data.reviewed;
  }

  set reviewed(value: boolean) {
    this.data.reviewed = value;
  }

  get reviewStatus(): string {
    if (this.needsApproval) {
      return 'Needs Approval';
    }
    if (this.reviewed) {
      return 'Reviewed';
    }
    return this.mappingConfidenceLevel;
  }

  get reviewStatusCode(): number {
    switch (this.reviewStatus) {
      case 'No Match':
        return 0;
      case 'Needs Approval':
        return 1;
      case 'Reviewed':
        return 3;
      default:
        return 2;
    }
  }

  get skillProfileStatusCode(): number {
    if (this.skills.length > 0) {
      return 3;
    } else {
      if (this.matchedTitles.length > 0) {
        return 2;
      } else {
        return 1;
      }
    }
  }

  get tags(): Types.Tag[] {
    return this.data.tags || [];
  }

  get aliases(): Types.Alias[] {
    return this.data.aliases || [];
  }

  get updatedAt(): string {
    return this.data.updatedAt || 'n/a';
  }

  get matchedTitles(): any[] {
    let titles: Types.TopTitleMatch[] = [];
    try {
      titles = this.data.emsiData.topTitleMatches || [];
    } catch {}

    if (titles.length > 0) {
      titles[0].topMatch = true;
    }

    return titles;
  }

  get titleConfidence(): number | undefined {
    return this.data.titleConfidence;
  }

  get mappingConfidence(): number | undefined {
    const result = this.matchedTitles.filter(t => t.id === this.data.emsiTitleId);
    return this.data.emsiTitleId === 'ET0000000000000000' ? 0 : result[0]?.confidence;
  }

  get mappingConfidenceLevel(): string {
    const mappingConfidence = this.mappingConfidence;

    if (!this.data.emsiTitleId || this.data.emsiTitleId === 'ET0000000000000000') {
      return 'No Match';
    } else if (!mappingConfidence) {
      return 'User Selected';
    } else if (mappingConfidence >= 0.9) {
      return 'High';
    } else if (mappingConfidence >= 0.8) {
      return 'Medium';
    } else {
      return 'Low';
    }
  }

  get details(): Types.RoleDetails {
    return this.data.details;
  }

  get temporary(): boolean {
    return this.data.temporary;
  }
}

export default Role;
