import axios from 'helpers/api_helper';

interface fetchFacetDataProps {
  companyId?: string;
  facetName: string;
  searchContext: any;
  filterValues?: any[];
  limit?: number;
}

type fetchFacetDataResponse = {
  buckets: any[];
  total: number;
};

interface CacheRecord {
  requestTime: number;
  promise: Promise<fetchFacetDataResponse>;
}

const CACHE: Record<string, CacheRecord> = {};

const CACHE_TIMEOUT = 60 * 60 * 1000;

const fetchPostingFacetData = async ({
  companyId = '',
  facetName,
  searchContext,
  filterValues = [],
  limit = 10,
}: fetchFacetDataProps): Promise<fetchFacetDataResponse> => {
  try {
    const url = `${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/opportunities/jobs/counts?companyId=${companyId}&facet=${facetName}&limit=${limit}`;

    const updatedContext = { ...searchContext };
    updatedContext[facetName] = filterValues.filter(value => value !== undefined);
    const cacheKey = `${url} - ${JSON.stringify(updatedContext)}`;
    const cached = CACHE[cacheKey];

    // if it we have already have a cached request less than an hour old use it
    if (cached && cached.requestTime > Date.now() - CACHE_TIMEOUT) {
      return cached.promise;
    }

    // build a new cached request
    const newCached: CacheRecord = {
      requestTime: Date.now(),
      promise: axios
        .post<fetchFacetDataResponse>(url, updatedContext)
        .then(response => response.data)
        .catch(() => ({ buckets: [], total: 0 })),
    };

    // cache the request for future use
    CACHE[cacheKey] = newCached;

    return newCached.promise;
  } catch (err) {
    return { buckets: [], total: 0 };
  }
};

export const getPostings = fetchPostingFacetData;
