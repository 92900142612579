import React, { ReactElement } from 'react';
import AsyncSelect from 'react-select/async';
import CompanyService from 'services/CompanyService';
import Company from 'classes/company';

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided: any) => ({
    ...provided,
    fontSize: '1.16em',
    color: '#666666',
    borderRadius: '5px',
    border: '1px solid #CCCCCC',
    paddingLeft: '0.6em',
    width: '100%',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    marginRight: -8,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#AAAAAA',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    paddingLeft: 0,
    paddingTop: 1,
  }),
};

type CustomerAutocompleteProps = {
  changeHandler: (company: Company) => void;
  showing: boolean;
  inputValue?: any;
};

const CustomerAutocomplete = ({ changeHandler, inputValue, showing }: CustomerAutocompleteProps): ReactElement => {
  const customTheme = (theme: any) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
    },
  });

  const noOptionsMessage = ({ inputValue }) => (inputValue ? 'No results' : 'Type to search');

  return (
    <AsyncSelect
      value={inputValue}
      onChange={(x: { value: Company; label: string }) => changeHandler(x.value)}
      styles={customStyles}
      noOptionsMessage={noOptionsMessage}
      theme={customTheme}
      cacheOptions={showing}
      loadOptions={async (q: string) => {
        const companies = await CompanyService.getCompanies();
        const filteredCompanies = companies.filter((c: Company) => c.name?.toLowerCase().includes(q));
        return filteredCompanies.map((i: Company) => ({ value: i, label: i.name }));
      }}
      placeholder="Search to Find a Company"
    />
  );
};

export default CustomerAutocomplete;
