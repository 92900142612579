import React, { ReactElement } from 'react';
import CheckBox from 'components/atoms/CheckBox';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ReactGA from 'react-ga';
import { useTheme } from 'styled-components';

type TOSAgreementProps = {
  agreementStatusHandler: () => void;
  submitButtonName: string;
  agreementStatus: boolean;
  checkboxDisabled?: boolean;
  tabIndex?: number;
};

function TermsOfServiceStatement({
  agreementStatusHandler,
  submitButtonName,
  agreementStatus,
  checkboxDisabled = false,
  tabIndex = 0,
}: TOSAgreementProps): ReactElement {
  const theme = useTheme();
  const recordAnalytics = (label: string) => {
    ReactGA.event({
      category: 'password reset required',
      action: 'password reset',
      label,
    });
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <CheckBox
          checked={agreementStatus}
          onChange={agreementStatusHandler}
          dataCy="terms-of-service-agreement-checkbox"
          tabIndex={tabIndex}
          disabled={checkboxDisabled}
        />
        <Typography sx={{ marginLeft: `${theme.customSpacing.px.xxs}px` }}>
          By clicking &quot;{submitButtonName}&quot;,&nbsp;you agree to our
          <Link
            tabIndex={tabIndex + 1}
            href="/terms-of-service"
            target="_blank"
            rel="nonreferrer"
            onClick={() => recordAnalytics('terms of service viewed')}
          >
            Terms of Service
          </Link>
        </Typography>
      </div>
    </>
  );
}

export default TermsOfServiceStatement;
