import type {} from '@mui/lab/themeAugmentation';

export const muiLoadingButtonOverride = {
  defaultProps: {
    variant: 'contained',
    color: 'actionPrimary',
    loadingIndicator: 'Saving...',
  },
  styleOverrides: {
    root: {
      padding: '8px 16px',
      minWidth: '80px',
      boxSizing: 'border-box',
      // textTransform: 'capitalize'
    },
    loadingIndicator: {
      // position: 'relative',
      textTransform: 'capitalize',
    },
  },
  sizeSmall: {
    padding: '6px 12px',
    height: '32px',
  },
  sizeMedium: {
    padding: '8px 16px',
    height: '36px',
  },
  sizeLarge: {
    padding: '12px 24px',
    height: '44px',
  },
};
