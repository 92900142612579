import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';

type TextWithSubTextProps = {
  subText?: string;
  text?: string;
  isSubTextOnTop: boolean;
};

const TextBox = styled.div<{ isSubTextOnTop: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: ${props => (props.isSubTextOnTop ? 'column-reverse' : 'column')};
  width: 100%;
`;

const TextWithSubText = ({ subText, text, isSubTextOnTop = false }: TextWithSubTextProps): ReactElement => {
  return (
    <TextBox isSubTextOnTop={isSubTextOnTop}>
      <Typography variant="strong">{text}</Typography>
      <Typography variant="caption">{subText}</Typography>
    </TextBox>
  );
};

export default TextWithSubText;
