import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import PipIcon, { PipIconType } from 'components/atoms/PipIcon';

type SelectableCardWithPipContainerProps = {
  items: {
    title: string;
    titleIcon?: ReactElement;
    value?: number;
    color?: string;
    isActive: boolean;
    children?: ReactElement;
  }[];
  onClickHandler: (index: number) => void;
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const Container = styled(Box)<{ active: number }>`
  background-color: ${props =>
    props.active ? props.theme.colors.surface.default : props.theme.colors.background.default};
  cursor: pointer;
  margin: ${props => props.theme.customSpacing.px.base}px;
  width: 200px;
  padding: ${props => props.theme.customSpacing.px.base * 4}px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid transparent;
  @media (max-width: 768px) {
    width: 100%;
  }
  ${props =>
    props.active &&
    `
      border: 1px solid ${props.theme.colors.border.information};
      box-shadow: ${props.theme.boxShadow.surfaceSelected};
      border-radius: ${props.theme.borderRadius.default}
    `}
`;

const SelectableCardWithPipContainer = ({
  items,
  onClickHandler,
}: SelectableCardWithPipContainerProps): ReactElement => {
  return (
    <Wrapper>
      {items.map((item, index) => {
        return (
          <React.Fragment key={item.title + index}>
            <Container
              active={item.isActive ? 1 : 0}
              onClick={() => onClickHandler(index)}
              data-cy={`selectable-card-header-item-${item.title.replace(/\s+/g, '-').toLowerCase()}`}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <span style={{ opacity: item.isActive ? '1' : '0.56' }}>
                  {item.color && <PipIcon type={PipIconType.full} width="16" height="16" fillColor={item.color} />}
                </span>
                {item.titleIcon && <span>{item.titleIcon}</span>}
                <Typography variant={'strong'} data-cy={'selectable-card-header-title'}>
                  {item.title}
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '4px', marginTop: '8px' }}>
                {item.value ? (
                  <Typography variant={'displayMedium'} data-cy={'selectable-card-header-value'}>
                    {item.value.toLocaleString()}
                  </Typography>
                ) : (
                  item.children
                )}
              </div>
            </Container>
          </React.Fragment>
        );
      })}
      <div style={{ display: 'flex', flexGrow: 1 }}></div>
    </Wrapper>
  );
};

export default SelectableCardWithPipContainer;
