import React, { ReactElement } from 'react';
import { useField, useFormikContext } from 'formik';
import styled from 'styled-components';
import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`;

const RequiredIndicatorContainer = styled.span`
  color: ${props => props.theme.colors.text.critical};
`;

type FormikOption = {
  value: string;
  label: string;
};

type FormikSelectProps = {
  formPropertyName: string;
  label: string;
  options: Array<FormikOption>;
  required?: boolean;
};

const FormikSelect = ({ formPropertyName, label, options, required = false }: FormikSelectProps): ReactElement => {
  const [field] = useField(formPropertyName);
  const { setFieldValue } = useFormikContext();
  const handleChange = event => {
    setFieldValue(field.name, event.target.value);
  };

  return (
    <FormControl style={{ display: 'flex', flex: 1 }}>
      <LabelContainer>
        <FormLabel id={`${label}-label`}>{label}</FormLabel>
        {required && <RequiredIndicatorContainer>*</RequiredIndicatorContainer>}
      </LabelContainer>
      <Select
        labelId={`${label}-label`}
        id={formPropertyName}
        value={field.value || ''}
        onChange={handleChange}
        SelectDisplayProps={{ style: { padding: '10px' } }}
      >
        {options.map(item => {
          return (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default FormikSelect;
