import styled from 'styled-components';

const FormPartLabel = styled.label`
  color: ${props => props.theme.colors.text.default};
  display: inline-block;
  font-size: ${props => props.theme.customTypography.desktop.body.size};
  font-weight: ${props => props.theme.customTypography.desktop.body.weight};
  line-height: ${props => props.theme.customTypography.desktop.body.lineHeight};
  margin: 0px ${props => props.theme.customSpacing.px.base}px ${props => props.theme.customSpacing.px.base}px 0px;
  text-transform: capitalize;
`;

export default FormPartLabel;
