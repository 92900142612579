import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import Role from 'classes/role';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import EmsiUIBadge from 'components/atoms/EmsiUIBadge';
import { reviewStatusColor, reviewStatusLabel } from 'helpers/SkillProfilePageHelper';
import Skeleton from '@mui/material/Skeleton';

const RoleDataContainer = styled.div<{ $topBottomMargin: number; $sideMargins: number }>`
  display: flex;
  gap: ${props => props.theme.customSpacing.px.base * 5}px;
  margin: ${props => `${props.$topBottomMargin}px ${props.$sideMargins}px`};
`;

const RoleDataSection = styled.div`
  display: flex;
  gap: ${props => props.theme.customSpacing.px.base * 5}px;
  flex-wrap: wrap;
`;

const MetadataRow = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${props => props.theme.customSpacing.px.xxs}px;
`;

type RoleMetadataHorizontalProps = {
  role: Role | undefined;
  displayStatusBadge?: boolean;
  topBottomMargin?: number;
  sideMargins?: number;
};

const RoleMetadataHorizontal = ({
  role,
  displayStatusBadge = true,
  topBottomMargin = 20,
  sideMargins = 48,
}: RoleMetadataHorizontalProps): ReactElement => {
  const theme = useTheme();
  const RoleDataElement = (header: string, data: string | undefined): ReactElement => {
    return (
      <MetadataRow>
        <Typography
          variant="caption"
          color={theme.colors.text.disabled}
          component="div"
          data-cy={`skillprofile-skills-${header.replace(/\s+/g, '-').toLowerCase()}-header`}
        >
          {`${header}:`}
        </Typography>
        {!data ? (
          <Skeleton width={'100px'}>
            <Typography>{'.'}</Typography>
          </Skeleton>
        ) : (
          <Typography
            variant="caption"
            component="div"
            data-cy={`skillprofile-skills-${header.replace(/\s+/g, '-').toLowerCase()}-data`}
          >
            {data}
          </Typography>
        )}
      </MetadataRow>
    );
  };

  return (
    <RoleDataContainer $topBottomMargin={topBottomMargin} $sideMargins={sideMargins}>
      <RoleDataSection>
        {RoleDataElement('Code', role?.jobCode)}
        {RoleDataElement('Family', role?.jobFamily)}
        {RoleDataElement('Function', role?.jobFunction)}
      </RoleDataSection>
      <Divider orientation="vertical" flexItem />
      <RoleDataSection>
        {RoleDataElement('Lightcast Title', role?.emsiTitle?.name)}
        {RoleDataElement('Occupation', role?.socName)}
        {role && displayStatusBadge && (
          <EmsiUIBadge
            label={reviewStatusLabel(role.reviewStatus)}
            color={reviewStatusColor(role.reviewStatus)}
            size={'small'}
            data-cy={'skillprofile-skills-review-status'}
          />
        )}
      </RoleDataSection>
    </RoleDataContainer>
  );
};

export default RoleMetadataHorizontal;
