import React, { ReactElement, useState } from 'react';
import Popover from '@mui/material/Popover';
import styled, { useTheme } from 'styled-components';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const PopoverIconContainer = styled.div`
  div {
    cursor: pointer;
  }
`;

const PopoverDemo = (): ReactElement => {
  const theme = useTheme();
  const [htmlElementAnchor, setHtmlElementAnchor] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setHtmlElementAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setHtmlElementAnchor(null);
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <PopoverIconContainer onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
          <BaseIcon type={IconType.InformationFill} />
        </PopoverIconContainer>
        <Link variant="caption" href={'https://mui.com/material-ui/react-popover/#anchor-playground'}>
          {'Use official mui popover playground for getting styles required for positioning the popover'}
        </Link>
      </div>
      <Popover
        anchorEl={htmlElementAnchor}
        open={!!htmlElementAnchor}
        onClose={handlePopoverClose}
        disableScrollLock={true}
        sx={{ pointerEvents: 'none' }}
      >
        <Typography
          variant={'caption'}
          sx={{ maxWidth: '220px', padding: `${theme.customSpacing.px.xs}px` }}
          component={'div'}
        >
          {'Mui popover'}
        </Typography>
      </Popover>
    </>
  );
};

export default PopoverDemo;
