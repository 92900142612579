import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

const Select = ({ color, size = 24 }) => {
  const theme = useTheme();
  color = color || theme.colors.icon.default.hex;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 10L12 5.5L16 10L8 10Z" fill={color} />
      <path d="M16 15L12 19.5L8 15H16Z" fill={color} />
    </svg>
  );
};

Select.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default Select;
