import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import { Helmet } from 'react-helmet-async';
import HeaderBar from 'components/organisms/HeaderBar';
import useAuthContext from 'helpers/UseAuthContext';
import useUserContext from 'helpers/UseUserContext';
import axios from 'helpers/api_helper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import NotificationsService from 'services/NotificationsService';

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.surface.default};
  border-radius: ${props => props.theme.borderRadius.medium};
  box-shadow:
    0px 0px 0px 0.5px rgba(0, 0, 0, 0.15),
    0px 2px 8px -2px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  margin: auto;
  margin-top: 4rem;
  padding: 2rem;
  max-width: 864px;
`;

const ColumnWrapper = styled.div``;

const ColumnItem = styled.div`
  align-items: flex-end;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  display: flex;
  padding: 1rem 0rem;
`;

const ColumnItemSubtext = styled.div`
  font-size: 0.85rem;
  font-weight: 500;
  margin-left: auto;
`;

const AdminToolsPage = (): ReactElement => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isEmsiAdmin } = useAuthContext();
  const { userDetails } = useUserContext();

  const triggerFrontEndError = () => {
    console.log('throwing manual FRONTEND exception to test sentry');
    throw new Error(`Manual Testing Error from ${userDetails.first_name} ${userDetails.last_name}`);
  };

  const triggerBackEndError = () => {
    console.log('throwing manual BACKEND eception to test sentry');
    const url = `${process.env.REACT_APP_API_ROOT}/admin/tools/exception`;
    axios.post(url).catch(err => {
      console.error('backend error caught', err);
    });
  };

  const sendEmail = async (type?: 'ProcessingCompleteEmail') => {
    await NotificationsService.sendEmail(type);
    alert(`${type} email sent!`);
  };

  return (
    <>
      <Helmet>
        <title>Admin Tools</title>
      </Helmet>
      <HeaderBar />

      <Wrapper>
        <Typography variant="displayLarge">Admin Tools</Typography>

        {isEmsiAdmin() && (
          <ColumnWrapper>
            <ColumnItem>
              <Typography variant="heading" color="text.subdued">
                Test Errors
              </Typography>
              <ColumnItemSubtext>
                <Button
                  onClick={triggerFrontEndError}
                  variant="contained"
                  color="actionCritical"
                  sx={{ marginRight: `${theme.customSpacing.px.xxs}px` }}
                >
                  test front end
                </Button>
                <Button
                  onClick={triggerBackEndError}
                  variant="contained"
                  color="actionCritical"
                  sx={{ marginRight: `${theme.customSpacing.px.xxs}px` }}
                >
                  test back end
                </Button>
              </ColumnItemSubtext>
            </ColumnItem>
            <ColumnItem>
              <Typography variant="heading" color="text.subdued">
                Lightcast UI Library
              </Typography>
              <ColumnItemSubtext>
                <Button
                  onClick={() => navigate('/admin/ui-library')}
                  variant="contained"
                  color="actionCritical"
                  sx={{ marginRight: `${theme.customSpacing.px.xxs}px` }}
                >
                  View Library
                </Button>
              </ColumnItemSubtext>
            </ColumnItem>
            <ColumnItem>
              <Typography variant="heading" color="text.subdued">
                Email Testing
              </Typography>
              <ColumnItemSubtext>
                <Button
                  onClick={async () => await sendEmail()}
                  variant="contained"
                  color="actionCritical"
                  sx={{ marginRight: `${theme.customSpacing.px.xxs}px` }}
                >
                  Send test email
                </Button>
              </ColumnItemSubtext>
            </ColumnItem>
            <ColumnItem>
              <Typography variant="heading" color="text.subdued">
                Processing Complete Email Testing
              </Typography>
              <ColumnItemSubtext>
                <Button
                  onClick={async () => await sendEmail('ProcessingCompleteEmail')}
                  variant="contained"
                  color="actionCritical"
                  sx={{ marginRight: `${theme.customSpacing.px.xxs}px` }}
                >
                  Send test email
                </Button>
              </ColumnItemSubtext>
            </ColumnItem>
            <ColumnItem>
              <Typography variant="heading" color="text.subdued">
                API Meta Information
              </Typography>
              <ColumnItemSubtext>
                <Button
                  onClick={() => navigate('/admin/api-info')}
                  variant="contained"
                  color="actionCritical"
                  sx={{ marginRight: `${theme.customSpacing.px.xxs}px` }}
                >
                  View API Information
                </Button>
              </ColumnItemSubtext>
            </ColumnItem>
            <ColumnItem>
              <Typography variant="heading" color="text.subdued">
                Manual Integration Jobs
              </Typography>
              <ColumnItemSubtext>
                <Button
                  onClick={() => navigate('/admin/integration')}
                  variant="contained"
                  color="actionCritical"
                  sx={{ marginRight: `${theme.customSpacing.px.xxs}px` }}
                >
                  Workday
                </Button>
              </ColumnItemSubtext>
            </ColumnItem>
          </ColumnWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default AdminToolsPage;
