import React, { ReactElement } from 'react';
import { ProcessingState } from 'helpers/FileProcessingProgressBarHelper';

type ProgressProgressBarSegmentProps = {
  count: number;
  total: number;
  color: string;
  title: string;
  segmentId?: ProcessingState | string;
  onClick?: (segmentId: ProcessingState | string) => void;
  dimmed?: boolean;
};

const ProgressProgressBarSegment = ({
  count,
  total,
  color,
  title,
  dimmed = false,
  segmentId,
  onClick,
}: ProgressProgressBarSegmentProps): ReactElement => {
  const progressPercent = (count / total) * 100;
  const minWidth = progressPercent > 0 ? '5%' : '0px';
  return (
    <div
      style={{
        width: `${progressPercent}%`,
        minWidth,
        borderRadius: '0.2rem',
        height: '1.2rem',
        padding: '0px',
        margin: count > 0 ? '1px' : '0px',
        backgroundColor: color,
        transition: 'all 1200ms',
        cursor: onClick ? 'pointer' : 'cursor',
        opacity: dimmed ? 0.3 : 1,
      }}
      title={title}
      onClick={() => {
        onClick && segmentId && onClick(segmentId);
      }}
    />
  );
};

export default ProgressProgressBarSegment;
