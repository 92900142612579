import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';

const Wrapper = styled.div<{ $isFullHeight: boolean }>`
  display: flex;
  height: ${props => (props.$isFullHeight ? '100%' : 'unset')};
  align-items: center;
  justify-content: center;
`;

const MessageContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: ${props => props.theme.customSpacing.px.xs}px;
  color: ${props => props.theme.colors.text.disabled};
  background-color: ${props => props.theme.colors.background.default};
  border-radius: ${props => props.theme.borderRadius.default};
  margin: ${props => `${props.theme.customSpacing.px.xs}px ${props.theme.customSpacing.px.s}px`};
  align-items: center;
  justify-content: center;
`;

interface NoDataPlaceholderProps {
  children?: ReactNode;
  isFullHeight?: boolean;
  dataCy?: string;
}

function NoDataPlaceholder({
  children,
  isFullHeight = false,
  dataCy = 'no-data-placeholder',
}: NoDataPlaceholderProps): ReactElement {
  return (
    <Wrapper $isFullHeight={isFullHeight} data-cy={dataCy}>
      <MessageContainer className="no-data-message-container">{children}</MessageContainer>
    </Wrapper>
  );
}

export default NoDataPlaceholder;
