import { DefaultTheme } from 'styled-components';
import { SwitchProps } from '@mui/material/Switch';
import type {} from '@mui/lab/themeAugmentation';

declare module '@mui/material/Switch' {
  interface SwitchPropsColorOverrides {
    iconSuccess: true;
    iconInformation: true;
    iconWarning: true;
    iconCritical: true;

    info: false;
    primary: false;
    secondary: false;
    success: false;
    warning: false;
    error: false;
    default: false;
  }

  interface SwitchPropsSizeOverrides {
    labelled: true;
  }

  interface SwitchProps {
    checkedlabel?: string;
    uncheckedlabel?: string;
    checkedthumbwidth?: number;
    uncheckedthumbwidth?: number;
    labelledheight?: number;
  }
}

export const switchStyleOverride = (gs: DefaultTheme): any => ({
  defaultProps: {
    color: 'iconSuccess',
    disableRipple: true,
    checkedlabel: 'checked',
    uncheckedlabel: 'unchecked',
    uncheckedthumbwidth: 80,
    checkedthumbwidth: 70,
    labelledheight: 24,
  },
  styleOverrides: {
    root: {
      width: 60,
      height: 28,
      padding: '4px 12px',
    },
    edgeStart: {
      marginLeft: 'unset',
    },
    edgeEnd: {},
    switchBase: {
      padding: '6px 14px',
      background: 'none !important',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        '& + .MuiSwitch-track': {
          opacity: 1,
          justifyContent: 'flex-start',
          color: gs.colors.text.onPrimary,
          backgroundColor: gs.colors.icon.success.hex,
        },
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: gs.colors.icon.disabled.hex,
      },
    },
    colorPrimary: {
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: gs.colors.icon.success.hex,
        },
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: gs.colors.icon.success.hex,
      },
    },
    colorSecondary: {
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: gs.colors.icon.information.hex,
        },
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: gs.colors.icon.information.hex,
      },
    },
    colorIconSuccess: {
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: gs.colors.icon.success.hex,
        },
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: gs.colors.icon.success.hex,
      },
    },
    colorIconInformation: {
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: gs.colors.icon.information.hex,
        },
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: gs.colors.icon.information.hex,
      },
    },
    colorIconWarning: {
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: gs.colors.icon.warning.hex,
        },
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: gs.colors.icon.warning.hex,
      },
    },
    colorIconCritical: {
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: gs.colors.icon.critical.hex,
        },
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: gs.colors.icon.critical.hex,
      },
    },
    sizeSmall: {
      '&.MuiSwitch-root': {
        width: 50,
        height: 24,
      },
      '& .MuiSwitch-switchBase': {
        padding: '6px 14px',
        '&.Mui-checked': {
          transform: 'translateX(10px)',
        },
      },
      '& .MuiSwitch-thumb': {
        width: 12,
        height: 12,
      },
    },
    sizeMedium: {},
    sizeLabelled: ({ ownerState, theme }) => ({
      '&.MuiSwitch-root': {
        fontSize: gs.customTypography.desktop.caption.size,
        fontWeight: gs.customTypography.desktop.caption.weight,
        lineHeight: gs.customTypography.desktop.caption.lineHeight,
        width: `calc(${ownerState.uncheckedthumbwidth}px + ${ownerState.checkedthumbwidth}px + 34px)`,
        height: `calc(${ownerState.labelledheight}px + 8px)`,
      },
      '& .MuiSwitch-thumb': {
        width: `${ownerState.uncheckedthumbwidth}px`,
        height: `calc(${ownerState.labelledheight}px - 4px)`,
        borderRadius: `calc((${ownerState.labelledheight}px - 4px) / 2)`,
        '&:before': {
          content: `'${ownerState.uncheckedlabel}'`,
          color: gs.colors.text.default,
        },
      },
      '& .MuiSwitch-track': {
        borderRadius: `calc(${ownerState.labelledheight}px / 2)`,
        display: 'flex',
        justifyContent: 'flex-end',
        '&:before': {
          content: `'${ownerState.checkedlabel}'`,
          color: gs.colors.text.default,
          width: `${ownerState.checkedthumbwidth}px`,
          display: 'flex',
          justifyContent: 'center',
        },
      },
      '& .MuiSwitch-switchBase': {
        padding: '6px 14px',
        '&.Mui-checked': {
          transform: `translateX(calc(${ownerState.uncheckedthumbwidth}px + 6px))`,
          '& .MuiSwitch-thumb': {
            width: `${ownerState.checkedthumbwidth}px`,
            '&:before': {
              content: `'${ownerState.checkedlabel}'`,
              color: theme.palette[`${ownerState.color}`].main,
            },
          },
          '& + .MuiSwitch-track': {
            display: 'flex',
            justifyContent: 'flex-start',
            '&:before': {
              content: `'${ownerState.uncheckedlabel}'`,
              color: gs.colors.text.onPrimary,
              width: `${ownerState.uncheckedthumbwidth}px`,
              display: 'flex',
              justifyContent: 'center',
            },
          },
        },
        '&.Mui-disabled': {
          '& .MuiSwitch-thumb, & .MuiSwitch-track': {
            '&:before, &:after': {
              color: gs.colors.text.disabled,
            },
          },
        },
      },
    }),
    input: {},
    thumb: {
      width: 16,
      height: 16,
      opacity: 1,
      backgroundColor: gs.colors.icon.onPrimary.hex,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:before, &:after': {
        paddingLeft: gs.customSpacing.px.xxs,
        paddingRight: gs.customSpacing.px.xxs,
      },
    },
    track: {
      borderRadius: 10,
      backgroundColor: gs.colors.icon.subdued.hex,
      opacity: 1,
      display: 'inline-flex',
      alignItems: 'center',
      padding: `0px ${gs.customSpacing.px.base / 2}px`,
      '&:before, &:after': {
        paddingLeft: gs.customSpacing.px.xs,
        paddingRight: gs.customSpacing.px.xs,
      },
    },
  } as SwitchProps,
});
