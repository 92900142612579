import React, { ReactElement } from 'react';
import { Auth } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import UseAuthContext from 'helpers/UseAuthContext';

const SsoSignInPage = (): ReactElement => {
  const navigate = useNavigate();
  const { logOut } = UseAuthContext();
  Auth.currentUserPoolUser()
    .then(user => {
      const submittedEmail = window.localStorage.getItem('SSOSubmittedEmail');
      if (!user) {
        console.error('Error signing in. No current user pool user.');
        // Maybe we can check something here and do Auth.federatedSignIn
        // to send the user through the flow again if they are in
        // the first login state that has the userId as email on the cognito link?
        navigate('/sign-in');
      } else if (user.username !== submittedEmail) {
        window.localStorage.setItem('RedirectedAfterInvalidAuth', 'true');
        logOut();
      } else {
        navigate('/insights/home');
      }
    })
    .catch(error => {
      console.error('Error signing in', error);
      navigate('/sign-in');
    });
  return <>Handling SSO login...</>;
};

export default SsoSignInPage;
