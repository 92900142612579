import React, { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SsoSignOutPage = (): ReactElement => {
  const navigate = useNavigate();
  const redirectedAfterInvalidAuth = window.localStorage.getItem('RedirectedAfterInvalidAuth') === 'true';
  useEffect(() => {
    // Clean up from this login attempt
    window.localStorage.removeItem('RedirectedAfterInvalidAuth');
    if (redirectedAfterInvalidAuth) {
      navigate('/sign-in?invalidLogin=true');
    } else {
      navigate('/');
    }
  }, []);
  return <>Redirecting back to sign-in...</>;
};

export default SsoSignOutPage;
