import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Check from 'components/atoms/icons/Check';
import EmsiUIBadge from 'components/atoms/EmsiUIBadge';
import { badgeBuilder } from 'helpers/SkillProfilePageHelper';
import PipIcon, { PipIconType } from 'components/atoms/PipIcon';

const Section = styled.div`
  padding-right: ${props => props.theme.customSpacing.px.s}px;

  &:not(:last-child) {
    margin-right: ${props => props.theme.customSpacing.px.s}px;
    border-right: 1px solid ${props => props.theme.colors.border.default};
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const StyledEmsiUIBadge = styled(EmsiUIBadge)`
  margin: 0.5rem 0;
`;

const BadgeHelperDemoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PercentageInputWrapper = styled.div`
  padding-left: 4px;
`;

const badgeBuilderData = [
  { percentage: 1 / 0 },
  { percentage: 0 },
  { percentage: 0.15 },
  { percentage: 0.4 },
  { percentage: 0.9 },
];

const BadgeDemo = (): ReactElement => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Section>
          <Typography variant="strong">Badge</Typography>
          <Column>
            <StyledEmsiUIBadge color="surfaceNeutral" label="Neutral" title="Neutral" />
            <StyledEmsiUIBadge color="surfaceInformation" label="Information" title="Information" />
            <StyledEmsiUIBadge color="surfaceSuccess" label="Success" title="Success" />
            <StyledEmsiUIBadge color="surfaceWarning" label="Warning" title="Warning" />
            <StyledEmsiUIBadge color="surfaceCritical" label="Critical" title="Critical" />
          </Column>
        </Section>
        <Section>
          <Typography variant="strong">Badge with pip</Typography>
          <Column>
            <StyledEmsiUIBadge
              color="surfaceSuccess"
              label="Complete"
              title="Complete"
              icon={<PipIcon type={PipIconType.full} />}
            />
            <StyledEmsiUIBadge
              color="surfaceWarning"
              label="Partially Complete"
              title="Partially Complete"
              icon={<PipIcon type={PipIconType.partial} />}
            />
            <StyledEmsiUIBadge
              color="surfaceCritical"
              label="Empty"
              title="Empty"
              icon={<PipIcon type={PipIconType.empty} />}
            />
          </Column>
        </Section>
        <Section>
          <Typography variant="strong">Badge with Icon</Typography>
          <Column>
            <StyledEmsiUIBadge color="surfaceNeutral" label="Neutral" title="Neutral" icon={<Check />} />
            <StyledEmsiUIBadge color="surfaceInformation" label="Information" title="Information" icon={<Check />} />
            <StyledEmsiUIBadge color="surfaceSuccess" label="Success" title="Success" icon={<Check />} />
            <StyledEmsiUIBadge color="surfaceWarning" label="Warning" title="Warning" icon={<Check />} />
            <StyledEmsiUIBadge color="surfaceCritical" label="Critical" title="Critical" icon={<Check />} />
          </Column>
        </Section>
        <Divider />
        <Section>
          <Typography variant="strong">Assets</Typography>
          <Column>
            <PipIcon type={PipIconType.full} width="35" height="35" />
            <PipIcon type={PipIconType.partial} width="35" height="35" />
            <PipIcon type={PipIconType.empty} width="35" height="35" />
          </Column>
        </Section>
        <Divider />
        <Section>
          <Typography variant="strong">Test Badge return helper</Typography>
          <Column>
            {badgeBuilderData.map(data => (
              <BadgeHelperDemoWrapper key={data.percentage}>
                {badgeBuilder(data.percentage)}
                <PercentageInputWrapper>input: {data.percentage}</PercentageInputWrapper>
              </BadgeHelperDemoWrapper>
            ))}
          </Column>
        </Section>
      </div>
    </>
  );
};

export default BadgeDemo;
