import React, { useState, ReactElement } from 'react';
import { Auth } from '@aws-amplify/auth';
import styled, { useTheme } from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import InputBar from 'components/molecules/InputBar';
import PasswordRequirements from 'components/atoms/PasswordRequirements';
import ReactGA from 'react-ga';
import queryString from 'query-string';
import PasswordHelper from 'helpers/passwordHelper';
import AppLogo from 'components/atoms/appLogo';
import ContentCredentialDetails from 'components/atoms/ContainerCredentialDetails';
import FormPartLabel from 'components/atoms/formElements/FormPartLabel';
import InputRow from 'components/atoms/InputRow';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { IconColorFilter } from 'types/types';
import TermsOfServiceStatement from 'components/organisms/TermsOfServiceStatement';
import ContentPlaceHolder from 'pages/templates/ContentPlaceHolder';
import ContainerPage from 'components/atoms/ContainerPage';
import BodyPageFullScreen from 'components/atoms/BodyPageFullScreen';

const LogoSection = styled.div`
  margin-top: ${props => props.theme.customSpacing.px.base * 2}px;
  margin-bottom: ${props => props.theme.customSpacing.px.l}px;
  @media (max-width: 768px) {
    margin-bottom: ${props => props.theme.customSpacing.px.s}px;
  }
  display: flex;
  display-direction: row;
`;

const PasswordResetMessage = styled.p<{ isError?: boolean }>`
  color: ${props => (props.isError ? `${props.theme.colors.text.critical}` : `${props.theme.colors.text.success}`)};
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  margin: ${props => props.theme.customSpacing.px.s}px;
  text-align: center;
`;

const ForgotPasswordPage = (): ReactElement => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const qsParams = queryString.parse(location.search);

  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);
  const [resetCodeSent, setResetCodeSent] = useState(false);
  const [resetConfirmed, setResetConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [email, setEmail] = useState(qsParams.email as string);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [resetCode, setResetCode] = useState(
    qsParams.reset_code === 'resetRequired' ? '' : (qsParams.reset_code as string)
  );
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [isPasswordsMatch, setIsPasswordsMatch] = useState(true);
  const [forceResetShow] = useState(!!qsParams.reset_code);

  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isTosAccepted, setIsTosAccepted] = useState(false);
  const [displayTosAgreement] = useState(true);

  const isFormValid = !!email && isPasswordValid && isPasswordsMatch && isTosAccepted && resetCode;

  const recordAnalytics = (action: string, label: string) => {
    ReactGA.event({
      category: 'password reset',
      action: `reset requested ${action}`,
      label,
    });
  };

  const sendCodeHandler = async event => {
    event.preventDefault();

    if (!email || email.length < 0) {
      alert('Please enter an email address.');
      return;
    }

    try {
      setIsSendingCode(true);
      await Auth.forgotPassword(email);
      setResetCodeSent(true);
      recordAnalytics('forgotPassword:', 'success - email exists');
    } catch (error: any) {
      recordAnalytics('forgotPassword:', error.code);
      switch (error.code) {
        case 'LimitExceededException':
          alert('There have been too many password reset attempts. Please try again later.');
          break;
        case 'NotAuthorizedException':
          alert('User not authorized. Please contact your administrator.');
          break;
      }

      setIsSendingCode(false);
    }
  };

  const confirmCodeHandler = async event => {
    event.preventDefault();

    if (newPassword !== newPasswordConfirm) {
      alert('Your passwords must match.');
      return;
    }

    setIsConfirming(true);

    try {
      await PasswordHelper.checkIfPasswordIsCompromised(newPassword);

      await Auth.forgotPasswordSubmit(email, resetCode, newPassword);
      setResetConfirmed(true);
      recordAnalytics('forgotPasswordSubmit:', 'reset successful');
      recordAnalytics('forgotPasswordSubmit:', 'terms of service accepted');
    } catch (error: any) {
      const cause = error.code || error.name;
      recordAnalytics('forgotPasswordSubmit:', cause);
      switch (cause) {
        case 'UserNotFoundException':
          alert('There was an error resetting your password.');
          break;
        case 'CompromisedPasswordException':
          alert(`Password Reset Failed: ${error.message}`);
          break;
        case 'CodeMismatchException':
          alert('Incorrect code. Please check the code received in your password reset email.');
          break;
        case 'NotAuthorizedException':
          alert('User not authorized. Please contact your administrator.');
          break;
        case 'ExpiredCodeException':
          alert('Code expired. Please use the password reset link.');
          break;
        case 'LimitExceededException':
          alert('There have been too many password reset attempts. Please try again later.');
          break;
        default:
          alert('There was an unknown error while trying to reset your password.  Please try again.');
          break;
      }

      setIsConfirming(false);
    }
  };

  return (
    <>
      <ContentPlaceHolder pageTitle={'Forgot Password'}>
        <BodyPageFullScreen>
          <ContainerPage>
            <ContentCredentialDetails>
              <LogoSection>
                <AppLogo />
              </LogoSection>
              <Typography variant="displayMedium" sx={{ marginBottom: `${theme.customSpacing.px.base * 4}px` }}>
                Reset Password
              </Typography>
              {!resetCodeSent && !forceResetShow ? (
                <form onSubmit={sendCodeHandler}>
                  <Typography sx={{ marginBottom: `${theme.customSpacing.px.s}px` }}>
                    Please enter your email address. You will receive a link to create a new password via email.
                  </Typography>
                  <InputRow>
                    <FormPartLabel>Email</FormPartLabel>
                    <InputBar
                      key="emailInput"
                      required={false}
                      name="email"
                      id="email"
                      onChange={e => setEmail(e.target.value)}
                      placeholder="your@email.com"
                      type="email"
                      value={email}
                      style={{ marginBottom: `${theme.customSpacing.px.s}px` }}
                    />
                  </InputRow>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                    }}
                  >
                    <LoadingButton
                      variant="contained"
                      color="actionPrimary"
                      loading={isSendingCode}
                      data-cy="reset-password-email-send-button"
                      type="submit"
                      tabIndex={0}
                    >
                      Send
                    </LoadingButton>
                    <div>
                      <Button variant="text" onClick={() => navigate('/sign-in')}>
                        Back to Sign In
                      </Button>
                    </div>
                  </div>
                </form>
              ) : !resetConfirmed ? (
                <>
                  {resetCodeSent && <PasswordResetMessage>An email has been sent to {email}.</PasswordResetMessage>}

                  {qsParams.reset_code === 'resetRequired' && (
                    <PasswordResetMessage isError={true}>
                      Your account requires a password reset. Please use the password reset code that was sent to{' '}
                      {email}.
                    </PasswordResetMessage>
                  )}

                  <form onSubmit={confirmCodeHandler}>
                    {forceResetShow && (
                      <>
                        <InputRow>
                          <FormPartLabel>Your Email Address</FormPartLabel>
                          <InputBar
                            key="emailInput"
                            required
                            name="email"
                            id="email"
                            onChange={e => setEmail(e.target.value)}
                            placeholder="Email Address"
                            type="email"
                            value={email}
                            tabIndex={1}
                          />
                        </InputRow>
                      </>
                    )}
                    <InputRow>
                      <FormPartLabel>Confirmation Code</FormPartLabel>
                      <InputBar
                        key="resetCodeInput"
                        required
                        type="tel"
                        onChange={e => setResetCode(e.target.value)}
                        value={resetCode}
                        autoFocus={true}
                      />
                    </InputRow>
                    <InputRow>
                      <FormPartLabel>New Password</FormPartLabel>
                      <div style={{ marginLeft: '100%' }}>
                        <span style={{ position: 'absolute', margin: '12px 5px 0px 5px' }}>
                          {showPassword ? (
                            <BaseIcon
                              type={IconType.EyeOff}
                              size="20px"
                              colorFilter={IconColorFilter.Subdued}
                              onIconClick={() => setShowPassword(!showPassword)}
                              tabIndex={-1}
                            />
                          ) : (
                            <BaseIcon
                              type={IconType.Eye}
                              size="20px"
                              colorFilter={IconColorFilter.Subdued}
                              onIconClick={() => setShowPassword(!showPassword)}
                              tabIndex={-1}
                            />
                          )}
                        </span>
                      </div>
                      <InputBar
                        key="passwordInput1"
                        required
                        type={showPassword ? 'text' : 'password'}
                        placeholder="New Password"
                        onChange={e => setNewPassword(e.target.value)}
                        value={newPassword}
                        onFocus={() => setShowPasswordRequirements(true)}
                      />
                      <PasswordRequirements
                        setValid={setIsPasswordValid}
                        password={newPassword}
                        show={showPasswordRequirements && newPassword.length > 0 && !isPasswordValid}
                      />
                    </InputRow>
                    <InputRow></InputRow>
                    <InputRow>
                      <FormPartLabel>Confirm New Password</FormPartLabel>
                      <InputBar
                        key="passwordInput2"
                        required
                        type="password"
                        placeholder="Confirm New Password"
                        onChange={e => {
                          setNewPasswordConfirm(e.target.value);
                        }}
                        onBlur={() => setIsPasswordsMatch(newPassword === newPasswordConfirm)}
                        value={newPasswordConfirm}
                      />
                      {!isPasswordsMatch && (
                        <Typography paragraph={true} variant="strong" color="text.critical">
                          Passwords to not match
                        </Typography>
                      )}
                    </InputRow>
                    {displayTosAgreement && (
                      <InputRow>
                        <TermsOfServiceStatement
                          agreementStatusHandler={() => setIsTosAccepted(!isTosAccepted)}
                          submitButtonName="Change Password"
                          agreementStatus={isTosAccepted}
                        />
                      </InputRow>
                    )}
                    <LoadingButton loading={isConfirming} disabled={!isFormValid} type="submit" tabIndex={0}>
                      Change Password
                    </LoadingButton>
                  </form>
                </>
              ) : (
                <SuccessMessageContainer>
                  <Typography color="text.success">Your password has been reset!</Typography>
                  <Typography>
                    Click
                    <Button variant="text" onClick={() => navigate('/sign-in')}>
                      here
                    </Button>
                    to sign in with your new credentials.
                  </Typography>
                </SuccessMessageContainer>
              )}
            </ContentCredentialDetails>
          </ContainerPage>
        </BodyPageFullScreen>
      </ContentPlaceHolder>
    </>
  );
};

export default ForgotPasswordPage;
