import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';

type ComplexInputBarProps = {
  preItem?: ReactElement;
  postItem?: ReactElement;
  placeholder: string;
  ref?: HTMLInputElement;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number | readonly string[];
  name?: string;
  dataCy?: string;
};

const Wrapper = styled.div`
  align-items: center;
  border: 1px solid ${props => props.theme.colors.border.default};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  padding: ${props => `${props.theme.customSpacing.px.base}px ${props.theme.customSpacing.px.xxs}px`};
  width: 100%;
  max-width: calc(100vw - ${props => props.theme.customSpacing.px.base * 44}px);

  @media (max-width: 768px) {
    max-width: calc(100vw - ${props => props.theme.customSpacing.px.base * 20}px);
  }
`;

const Input = styled.input`
  font-size: ${props => props.theme.customTypography.desktop.body.size};
  border: 0px solid ${props => props.theme.colors.surface.default};
  padding: ${props => `${props.theme.customSpacing.px.base}px ${props.theme.customSpacing.px.xxs}px`};
  color: ${props => props.theme.colors.text.subdued};
  width: 100%;

  &::placeholder {
    color: ${props => props.theme.colors.text.disabled};
  }

  &:focus {
    outline: none;
  }
`;

const ComplexInputBar = ({
  preItem,
  postItem,
  placeholder,
  onChange,
  value,
  name = '',
  dataCy = 'complex-input-bar',
}: ComplexInputBarProps): ReactElement => {
  const theme = useTheme();

  return (
    <Wrapper className="complex-input-bar-container">
      {preItem}
      <div style={{ width: '100%', minWidth: `${theme.customSpacing.px.base * 45}px` }}>
        <Input type="text" name={name} placeholder={placeholder} onChange={onChange} value={value} data-cy={dataCy} />
      </div>
      {postItem}
    </Wrapper>
  );
};

export default ComplexInputBar;
