import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import Typography from '@mui/material/Typography';

const ContentWrapper = styled.div`
  padding: 0px ${props => props.theme.customSpacing.px.m}px ${props => props.theme.customSpacing.px.m}px;
  text-align: justify;
`;

const SectionWrapper = styled.div`
  margin-top: ${props => props.theme.customSpacing.px.s}px;
`;

const styleListItemAlpha = {
  listStyleType: 'lower-alpha',
  paddingLeft: `${props => props.theme.customSpacing.px.xxs}px`,
};

const styleSubListItemRoman = {
  listStyleType: 'lower-roman',
  marginBottom: `${props => props.theme.customSpacing.px.base}px`,
  paddingLeft: `${props => props.theme.customSpacing.px.xxs}px`,
};

const TermsOfServicePage = (): ReactElement => {
  const theme = useTheme();
  return (
    <>
      <MainContentTemplate pageTitle="Terms of Service" isFullWidth={false}>
        <ContentHeaderTemplate contentTitle="Terms of Service"></ContentHeaderTemplate>
        <ContentWrapper>
          <Typography gutterBottom={true} paragraph={true}>
            Lightcast provides online SaaS applications that use labor market data to connect and inform people,
            education, and business. Lightcast&apos;s applications include Analyst, Developer, Career Coach, Workforce
            Insight, Alumni Insight, Profile Analytics, Talent, Staffing, Talent Transform, and College Analyst. These
            Lightcast Terms of Service (&quot;ToS&quot;) apply to all Lightcast&apos;s current applications, as well as
            any applications Lightcast develops in the future whether or not they are named here (collectively
            &quot;Lightcast Apps&quot;).
          </Typography>
          <SectionWrapper>
            <Typography variant="heading" gutterBottom={true} paragraph={true}>
              General License
            </Typography>
            <Typography gutterBottom={true} paragraph={true}>
              Licensees of Lightcast Apps are granted a non-exclusive, nontransferable, non-assignable limited license
              to access data (the &quot;Licensed Dataset&quot;) through Lightcast Apps as follows:
            </Typography>
            <ol>
              <Typography component="li" style={styleListItemAlpha} gutterBottom={true} paragraph={true}>
                Unless otherwise stated in a written agreement, login credentials (a username and password) will be
                issued only to employees of a Licensee (&quot;authorized users&quot;). Login credentials may not be
                shared. Licensee will not allow access to Lightcast Apps by anyone who has not been assigned login
                credentials by Lightcast.
              </Typography>
              <Typography component="li" style={styleListItemAlpha} gutterBottom={true} paragraph={true}>
                Licensee and Licensee&apos;s authorized users may (i) download elements of the Licensed Dataset using
                the download tools in Lightcast Apps for Licensee&apos;s internal use, and may (ii) publish static
                elements (e.g., tables, charts, graphs) of the Licensed Dataset in work products created by Licensee in
                the normal course of Licensee&apos;s business, either for Licensee or for a third party, provided that
                Lightcast is cited as the source of the data. The citation shall be substantially in the form described
                in the Knowledge Base article, &quot;How Do I Cite Lightcast Data?&quot;
              </Typography>
              <Typography component="li" style={styleListItemAlpha} gutterBottom={true} paragraph={true}>
                Licensee may not distribute any part of the Licensed Dataset to a third party in any manner that allows
                it to be further manipulated for that third party&apos;s independent use.
              </Typography>
              <Typography component="li" style={styleListItemAlpha} gutterBottom={true} paragraph={true}>
                Licensee may not use any automated means or form of scraping or data extraction to access, query or
                otherwise collect Lightcast content from Lightcast Apps or the Licensed Dataset, or otherwise access
                Lightcast Apps or the Licensed Dataset by any automated means or process, except as expressly permitted
                by Lightcast.
              </Typography>
              <Typography component="li" style={styleListItemAlpha} gutterBottom={true} paragraph={true}>
                Neither Licensee nor Licensee&apos;s authorized users will attempt to replicate Lightcast Apps or the
                Licensed Dataset in design, content, or functionality.
              </Typography>
              <Typography component="li" style={styleListItemAlpha} gutterBottom={true} paragraph={true}>
                If a Lightcast App or Licensed Dataset includes access to individual profiles or personally identifiable
                information (&quot;PII&quot;), Licensee may only publish aggregated data that cannot be used to identify
                individual persons, and may not under any circumstance publish PII or data that may be manipulated or
                reverse engineered to create PII.
              </Typography>
              <Typography component="li" style={styleListItemAlpha} gutterBottom={true} paragraph={true}>
                Any Licensed Dataset that includes profile data or PII is to be used for research purposes only. No
                Licensed Dataset may be used to identify or evaluate a person in a way that violates applicable
                employment, privacy, or other laws.
              </Typography>
              <Typography component="li" style={styleListItemAlpha} gutterBottom={true} paragraph={true}>
                The individual job postings may only be used for, broadly defined, the discovery, interpretation and/ or
                communication of meaningful patterns in and/or aggregations or summaries of the respective data through
                software tools for any purpose, including but not limited to the calculation of aggregate statistics or
                use of the data for any investment purpose (individually and jointly also referred to as:
                &quot;Analytics&quot;). The use of the individual job postings or any subset, modified or derived
                version thereof for any other purpose than for Analytics, including but not limited to making the
                individual job postings available to third parties (e.g. on a website that is publicly accessible or as
                content in any software system) is prohibited; except that access to a limited number of individual job
                postings as illustrations of the use for Analytics purposes is permitted (up to a 100 individual job
                postings at maximum per illustration access).
              </Typography>
            </ol>
            <Typography gutterBottom={true} paragraph={true}>
              Licensees may contact Lightcast for permission to use the Licensed Dataset in a use case not covered by
              these ToS.
            </Typography>
          </SectionWrapper>
          <SectionWrapper>
            <Typography variant="heading" gutterBottom={true} paragraph={true}>
              Talent Transform Terms of Service
            </Typography>
            <ol>
              <Typography component="li" style={styleListItemAlpha} gutterBottom={true}>
                <Typography variant="strong">License</Typography>
                <Typography gutterBottom={true} paragraph={true}>
                  Licensee is granted a limited, non-exclusive, non-transferable, non-assignable license to use Talent
                  Transform for Licensee&apos;s own internal business purposes. Unless otherwise mutually agreed in
                  writing, Licensee may not use Talent Transform to provide services to any third party, whether or not
                  such third party is affiliated with Licensee.
                </Typography>
              </Typography>
              <Typography component="li" style={styleListItemAlpha} gutterBottom={true}>
                <Typography variant="strong">Uploaded and Usage Data</Typography>
                <Typography gutterBottom={true} paragraph={true}>
                  Talent Transform allows anyone with access to Licensee&apos;s Talent Transform instance to input and
                  upload descriptive information (&quot;data&quot;) related to Licensee&apos;s job roles and in turn
                  receive skill taggings and suggestions related to the data.
                </Typography>
                <Typography gutterBottom={true} paragraph={true}>
                  Licensee agrees that Lightcast may use any data inputted or uploaded to Talent Transform
                  (&quot;Uploaded Data&quot;), and any data derived from Licensee&apos;s use of Talent Transform
                  (&quot;Usage Data&quot;), for any purpose, including for commercial purposes, and subject to the
                  following terms:
                </Typography>
                <ol style={{ marginBottom: `${theme.customSpacing.px.xxs * 2}px` }}>
                  <Typography component="li" style={styleSubListItemRoman} paragraph={true}>
                    Licensee agrees that Lightcast will have all right, title, and interest in and to the Usage Data.
                  </Typography>
                  <Typography component="li" style={styleSubListItemRoman} paragraph={true}>
                    Licensee retains all right, title, and interest in and to the Uploaded Data.
                  </Typography>
                  <Typography component="li" style={styleSubListItemRoman} paragraph={true}>
                    Licensee agrees that Lightcast will have all right, title, and interest in and to any datasets,
                    products, or services derived in whole or in part from the Uploaded Data.
                  </Typography>
                  <Typography component="li" style={styleSubListItemRoman} paragraph={true}>
                    Lightcast represents and warrants that it will not use Uploaded Data or Usage Data in any way that
                    allows third parties to identify any individual person, or to identify Licensee as the originator of
                    the Uploaded Data or the Usage Data.
                  </Typography>
                </ol>
              </Typography>
              <Typography component="li" style={styleListItemAlpha} gutterBottom={true}>
                <Typography variant="strong">Disclaimers</Typography>
                <Typography gutterBottom={true} paragraph={true}>
                  The reports and forecasts in Lightcast Apps and Licensed Datasets are created using proprietary
                  analytical processes applied to data from public, proprietary, and government data sources. Lightcast
                  uses estimates when there are suppressed or missing data points, and such estimates are subject to
                  error. Data, reports, and forecasts included in Lightcast Apps and Licensed Datasets may differ
                  significantly from actual circumstances or outcomes. In addition, Lightcast cannot make any
                  representation of the completeness of data aggregated from any source.
                </Typography>
                <Typography gutterBottom={true} paragraph={true}>
                  Lightcast Apps use global professional profiles built with data posted online by individuals about
                  themselves. Such data comes from sources such as professional networking sites, talent hubs, blogs,
                  publications, journals, and social networks. Any information obtained from such sources cannot be
                  guaranteed or verified to be accurate or up to date.
                </Typography>
                <Typography gutterBottom={true} paragraph={true}>
                  Lightcast Apps and the Licensed Datasets are provided &quot;as is,&quot; without warranty for a
                  particular purpose or project. Lightcast is not liable for their misuse, or for the results of any
                  planning errors based thereon. Licensee is fully responsible for the decisions that are made based on
                  Lightcast Apps and the Licensed Datasets and the outcomes of those decisions, including any economic
                  loss.
                </Typography>
                <Typography gutterBottom={true} paragraph={true}>
                  LIGHTCAST&apos;s LIABILITY FOR DAMAGES TO LICENSEE SHALL NOT EXCEED THE AMOUNT LICENSEE PAID TO
                  LIGHTCAST FOR THE PRODUCT OR SERVICE IN QUESTION.
                </Typography>
                <Typography gutterBottom={true} paragraph={true}>
                  Licensee is responsible for providing computer equipment that meets the minimum technical requirements
                  for accessing Lightcast Apps described at&nbsp;
                  <a
                    href="http://www.economicmodeling.com/docs/analyst_technical_requirements.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    http://www.economicmodeling.com/docs/analyst_technical_requirements.pdf
                  </a>
                  .
                </Typography>
                <Typography gutterBottom={true} paragraph={true}>
                  Lightcast updates and upgrades products and services periodically. Lightcast is not obligated to
                  continue to support legacy versions of any product or service or make legacy versions of products or
                  services available to Licensee. Lightcast may update these ToS at any time without prior notice.
                  Substantial changes to these ToS will be presented to authorized users at their next login after the
                  changes are posted. Authorized users are required to read and agree to these ToS as a condition of
                  using Lightcast Apps and Licensed Datasets.
                </Typography>
                <Typography gutterBottom={true} paragraph={true}>
                  For answers to questions about Lightcast Apps, Licensed Datasets, or these ToS, go to Lightcast&apos;s
                  online Knowledge Base, use Lightcast&apos;s online Get In Touch contact form, or contact your
                  Lightcast customer service representative by email or at 208-883-3500.
                </Typography>
              </Typography>
            </ol>
          </SectionWrapper>
        </ContentWrapper>
      </MainContentTemplate>
    </>
  );
};

export default TermsOfServicePage;
