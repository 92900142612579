import React, { ReactElement } from 'react';
import ProcessingProgressBar from 'components/atoms/ProcessingProgressBar';
import ProcessingProgressBarKey from 'components/molecules/ProcessingProgressBarKey';
import { CategoryCount } from 'types/types';
import useProgressBarCategoryData from 'helpers/useProgressBarCategoryData';

type CategoryBreakdownProgressBarProps = {
  width: string;
  categoryCountList: Array<CategoryCount>;
  showLabel?: boolean;
  highlights?: string[];
};

const CategoryBreakdownProgressBar = ({
  width,
  categoryCountList,
  showLabel = true,
  highlights,
}: CategoryBreakdownProgressBarProps): ReactElement => {
  const categoryProgressBarData = useProgressBarCategoryData({ categoryCountData: categoryCountList });

  return (
    <div key={'skill-category-progress-bar'}>
      <>
        <div style={{ display: 'inline' }}>
          {showLabel && (
            <ProcessingProgressBarKey
              data={categoryProgressBarData.categoryProgressBarKeyData}
              showCount={true}
              key={'category-progress-bar'}
            />
          )}
          <ProcessingProgressBar
            width={width}
            total={categoryProgressBarData.total}
            data={categoryProgressBarData.categoryProgressBarDataList}
            highlights={highlights}
          />
        </div>
      </>
    </div>
  );
};

export default CategoryBreakdownProgressBar;
