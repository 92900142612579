import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

type DDNPopoverProps = {
  skillTypeHeaderAnchor: HTMLElement | null;
  handleSkillTypePopoverClose: () => void;
};

const DDNInfoPopover = ({ skillTypeHeaderAnchor, handleSkillTypePopoverClose }: DDNPopoverProps): ReactElement => {
  const theme = useTheme();

  return (
    <Popover
      anchorEl={skillTypeHeaderAnchor}
      open={!!skillTypeHeaderAnchor}
      onClose={handleSkillTypePopoverClose}
      disableAutoFocus={true}
      disableScrollLock={true}
      sx={{ pointerEvents: 'none' }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <div style={{ maxWidth: '320px', padding: `${theme.customSpacing.px.xs}px` }}>
        <Typography variant={'strong'} component={'div'}>
          Defining Skills:{' '}
          <Typography variant={'caption'}>
            Skills needed to qualify for a job and perform day-to-day tasks and responsibilities successfully.
          </Typography>
        </Typography>
        <Divider sx={{ margin: `${theme.customSpacing.px.base}px` }} />
        <Typography variant={'strong'} component={'div'}>
          Distinguishing Skills:{' '}
          <Typography variant={'caption'}>
            Skills that highlight technical proficiency and differentiate job seekers from other candidates.
          </Typography>
        </Typography>
        <Divider sx={{ margin: `${theme.customSpacing.px.base}px` }} />
        <Typography variant={'strong'} component={'div'}>
          Necessary Skills:{' '}
          <Typography variant={'caption'}>
            Skills required for a specific job, relevant across other similar jobs, and are building blocks for
            performing more complex defining skills.
          </Typography>
        </Typography>
      </div>
    </Popover>
  );
};

export default DDNInfoPopover;
