import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import 'index.css';
import App from 'App';
import { Amplify } from 'aws-amplify';
import * as serviceWorker from 'serviceWorker';

let env = 'prod';
if (process.env.REACT_APP_NODE_ENV === 'staging') {
  env = 'stage';
} else if (process.env.REACT_APP_NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'review') {
  env = 'dev';
}

let redirectDomain = 'https://www.talent-transform.com';
if (process.env.REACT_APP_STAGE === 'prod') {
  redirectDomain = 'https://www.talent-transform.com';
} else if (process.env.REACT_APP_STAGE === 'local') {
  redirectDomain = 'http://localhost:3000';
} else {
  redirectDomain = `https://www-${process.env.REACT_APP_STAGE}.talent-transform.com`;
}

Amplify.configure({
  Auth: {
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    mandatorySignIn: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    oauth: {
      domain: `emsi-skill-id-${env}.auth.${process.env.REACT_APP_COGNITO_REGION}.amazoncognito.com`,
      scope: ['email', 'aws.cognito.signin.user.admin', 'profile', 'openid'],
      redirectSignIn: `${redirectDomain}/sso/sign-in`,
      redirectSignOut: `${redirectDomain}/sso/sign-out`,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: 'testApiCall',
        endpoint: process.env.REACT_APP_APIGATEWAY_URL,
        region: process.env.REACT_APP_APIGATEWAY_REGION,
      },
    ],
  },
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
