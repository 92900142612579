import React, { ReactElement, useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import useCompanyContext from 'helpers/UseCompanyContext';
import RolesService, { RoleStats } from 'services/RolesService';
import PieChart, { PieChartDataProps } from 'components/charts/PieChart';
import { ColumnsWrapper, Column, Section, SectionSticky } from 'pages/admin/uiLibrary/UILibraryComponents';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';

const FormControlLabelCustom = styled(FormControlLabel)`
  white-space: nowrap;
`;

const D3PieDonutDemo = (): ReactElement => {
  const { companyId } = useCompanyContext();
  const [isSkillsStatsLoading, setIsSkillsStatsLoading] = useState(false);
  const [skillsStats, setSkillsStats] = useState<RoleStats>({
    totalRoles: 0,
    numberReviewed: 0,
    numberWithSkillProfiles: 0,
    topSkills: [],
  });
  const theme = useTheme();
  const [skillsStatsFormat, setSkillStatsFormat] = useState<PieChartDataProps[]>([]);
  const [chartType, setChartType] = useState<'donut' | 'pie'>('donut');
  const [labelType, setLabelType] = useState<'text' | 'tooltip' | 'none'>('tooltip');
  const [colorRange, setColorRange] = useState<'colorRange3' | 'colorRange7'>('colorRange3');
  const [isOnClickHandlerProvided, setIsOnClickHandlerProvided] = useState(true);
  const [clickedSectionData, setClickedSectionData] = useState<PieChartDataProps | undefined>();
  const [chartWidth, setChartWidth] = useState(240);
  const [chartHeight, setChartHeight] = useState(240);
  const [customColor, setCustomColor] = useState('none');
  const navigate = useNavigate();

  const chartDataFormat = (stats: any, activeId?: string) => {
    const formattedSkills = stats.map((skill, i) => {
      return {
        id: skill.skillId,
        label: skill.skillName,
        tooltipText: `${skill.skillName} (${skill.count})`,
        value: skill.count,
        isActive: activeId ? skill.skillId === activeId : true,
        color: customColor === 'none' || i !== 0 ? undefined : customColor,
      };
    });
    setSkillStatsFormat(formattedSkills);
  };

  const sectionClickHandler = (data: PieChartDataProps) => {
    console.log(`SkillsSummaryPage: Section has been clicked - ${data.label} (${data.id})`);
    setClickedSectionData(data);
    chartDataFormat(skillsStats.topSkills, data.id);
  };

  const loadChartData = async () => {
    setIsSkillsStatsLoading(true);
    const stats = await RolesService.getRoleStats(companyId);
    setSkillsStats(stats);
    chartDataFormat(stats.topSkills);
    setIsSkillsStatsLoading(false);
    setClickedSectionData(undefined);
  };

  useEffect(() => {
    loadChartData();
  }, [chartType, labelType, colorRange, chartWidth, chartHeight, customColor, isOnClickHandlerProvided]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <SectionSticky
          defaultComponent={
            <>
              <LoadingButton onClick={loadChartData} loading={isSkillsStatsLoading} loadingIndicator="Loading...">
                Refresh Data & Chart
              </LoadingButton>
            </>
          }
          componentOptions={
            <>
              <FormControlLabelCustom
                control={
                  <Slider
                    value={chartWidth}
                    onChange={(e: any) => setChartWidth(e.target.value)}
                    getAriaValueText={(value: number) => `${value}px`}
                    valueLabelDisplay="auto"
                    step={50}
                    marks
                    min={200}
                    max={400}
                  />
                }
                labelPlacement="top"
                label="Width"
              />
              <FormControlLabelCustom
                control={
                  <Slider
                    value={chartHeight}
                    onChange={(e: any) => setChartHeight(e.target.value)}
                    getAriaValueText={(value: number) => `${value}px`}
                    valueLabelDisplay="auto"
                    step={50}
                    marks
                    min={200}
                    max={400}
                  />
                }
                labelPlacement="top"
                label="Height"
              />
              <FormControlLabelCustom
                control={
                  <ToggleButtonGroup
                    size="small"
                    value={chartType}
                    exclusive
                    onChange={(e: any) => setChartType(e.target.value)}
                    aria-label="chart type"
                    color="success"
                  >
                    <ToggleButton value="donut">Donut (default)</ToggleButton>
                    <ToggleButton value="pie">Pie</ToggleButton>
                  </ToggleButtonGroup>
                }
                labelPlacement="top"
                label="Chart Type"
              />
              <br />
              <FormControlLabelCustom
                control={
                  <ToggleButtonGroup
                    size="small"
                    value={labelType}
                    exclusive
                    onChange={(e: any) => setLabelType(e.target.value)}
                    aria-label="label type"
                    color="success"
                  >
                    <ToggleButton value="text">Text</ToggleButton>
                    <ToggleButton value="tooltip">Tooltip (default)</ToggleButton>
                    <ToggleButton value="none">None</ToggleButton>
                  </ToggleButtonGroup>
                }
                labelPlacement="top"
                label="Label Type"
              />
              <br />
              <FormControlLabelCustom
                control={
                  <ToggleButtonGroup
                    size="small"
                    value={colorRange}
                    exclusive
                    onChange={(e: any) => setColorRange(e.target.value)}
                    aria-label="color range"
                    color="success"
                  >
                    <ToggleButton value={'colorRange3'}>3 Colors (default)</ToggleButton>
                    <ToggleButton value={'colorRange7'}>7 Colors</ToggleButton>
                  </ToggleButtonGroup>
                }
                labelPlacement="top"
                label="Color Range"
              />
              <br />
              <FormControlLabelCustom
                control={
                  <ToggleButtonGroup
                    size="small"
                    value={customColor}
                    exclusive
                    onChange={(e: any) => setCustomColor(e.target.value)}
                    aria-label="customColor"
                    color="success"
                  >
                    <ToggleButton value={'none'}>Reset</ToggleButton>
                    <ToggleButton value={theme.colors.icon.success.hex}>Success</ToggleButton>
                    <ToggleButton value={theme.colors.icon.warning.hex}>Warning</ToggleButton>
                    <ToggleButton value={theme.colors.icon.critical.hex}>Critical</ToggleButton>
                  </ToggleButtonGroup>
                }
                labelPlacement="top"
                label="Color Override by Item"
              />
              <br />
              <FormControlLabelCustom
                control={
                  <Switch
                    color="iconSuccess"
                    checked={isOnClickHandlerProvided}
                    onChange={() => setIsOnClickHandlerProvided(!isOnClickHandlerProvided)}
                  />
                }
                labelPlacement="top"
                label="onClickHandler"
              />
              <Divider />
              <Link
                onClick={() => navigate('/admin/d3-demo')}
                underline={'always'}
                sx={{ padding: '0px', marginTop: `${theme.customSpacing.px.base * 4}px` }}
                data-cy={'d3-demo-page-link'}
              >
                D3 Chart Demo Page w/ API data
              </Link>
            </>
          }
        />
        <Section style={{ width: '50%' }}>
          <Typography variant="strong">Chart Example</Typography>
          <ColumnsWrapper>
            <Column>
              {isSkillsStatsLoading && (
                <Typography sx={{ display: 'flex', justifyContent: 'center' }}>loading...</Typography>
              )}
              {!isSkillsStatsLoading && (
                <div style={{ display: 'inline-flex' }}>
                  <PieChart
                    data={skillsStatsFormat}
                    width={chartWidth}
                    height={chartHeight}
                    chartName="top-skills"
                    chartType={chartType}
                    labelType={labelType}
                    colorRange={theme.colors.chart[`${colorRange}`]}
                    sectionClickHandler={isOnClickHandlerProvided ? sectionClickHandler : undefined}
                  />
                </div>
              )}
              {isOnClickHandlerProvided && clickedSectionData && (
                <>
                  <br />
                  <Typography variant="strong">{`Chart section clicked: ${clickedSectionData.label}`}</Typography>
                  <Typography variant="caption">
                    <pre style={{ maxHeight: '250px', overflowY: 'scroll' }}>
                      {JSON.stringify(clickedSectionData, null, 2)}
                    </pre>
                  </Typography>
                </>
              )}
            </Column>
          </ColumnsWrapper>
        </Section>
        <Section>
          <Typography variant="strong">Mock Data</Typography>
          <ColumnsWrapper>
            <Column>
              {isSkillsStatsLoading && (
                <Typography sx={{ display: 'flex', justifyContent: 'center' }}>loading...</Typography>
              )}
              {!isSkillsStatsLoading && (
                <>
                  <Typography variant="body">Raw Data</Typography>
                  <Typography variant="caption">
                    <pre style={{ maxHeight: '250px', overflowY: 'scroll' }}>
                      {JSON.stringify(skillsStats, null, 2)}
                    </pre>
                  </Typography>
                  <br />
                  <Typography variant="body">Formatted Data (PieChartDataProps)</Typography>
                  <Typography variant="caption">
                    <pre style={{ maxHeight: '250px', overflowY: 'scroll' }}>
                      {JSON.stringify(skillsStatsFormat, null, 2)}
                    </pre>
                  </Typography>
                </>
              )}
            </Column>
          </ColumnsWrapper>
        </Section>
      </div>
    </>
  );
};

export default D3PieDonutDemo;
