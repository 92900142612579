import { useEffect, useRef, MutableRefObject } from 'react';
import * as d3 from 'd3';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useD3 = (renderChartFn: (arg0: any) => any, dependencies: any): MutableRefObject<any> => {
  const ref = useRef(undefined);

  useEffect(() => {
    if (ref.current) {
      renderChartFn(d3.select(ref.current));
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
  }, [dependencies, ref.current]);

  return ref;
};

// ref: https://jkeohan.medium.com/the-used3-custom-hook-889ddbd30f18
