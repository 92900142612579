import React, { useMemo, ReactElement } from 'react';
import ReactGA from 'react-ga';
import DialogModal from 'components/molecules/DialogModal';
import useCompanyContext from 'helpers/UseCompanyContext';
import BenchmarksService from 'services/BenchmarksService';
import Benchmark from 'classes/benchmark';
import BenchmarkForm from 'components/organisms/BenchmarkForm';

type BenchmarkEditModalProps = {
  isOpen: boolean;
  closeHandler: (triggerRefresh: boolean) => void;
  benchmark: any;
};

const BenchmarkEditModal = ({ isOpen = true, closeHandler, benchmark }: BenchmarkEditModalProps): ReactElement => {
  const { companyId } = useCompanyContext();

  const isEditing = useMemo(() => {
    return benchmark.benchmarkId && benchmark.benchmarkId.match(/^ecds-b-/i);
  }, [benchmark]);

  const cancelHandler = e => {
    // reset the create object
    closeHandler(e.triggerRefresh);
  };

  const saveHandler = async newBenchmarkData => {
    const benchmark = Benchmark.fromJson(newBenchmarkData);
    if (isEditing) {
      await BenchmarksService.updateBenchmark({
        companyId,
        benchmarkId: benchmark.benchmarkId,
        updateData: benchmark,
      });
    } else {
      await BenchmarksService.createBenchmark({ companyId, benchmark });
    }

    ReactGA.event({
      category: 'benchmarks',
      action: isEditing ? 'edit' : 'create',
      label: 'save success',
    });

    cancelHandler({ triggerRefresh: true });
  };

  return (
    <DialogModal
      dialogOpen={isOpen}
      closeModal={() => closeHandler(false)}
      title={!isOpen ? '' : isEditing ? 'Edit Benchmark' : 'Create Benchmark'}
      titleSizeVariant="displayLarge"
      showCloseIcon={false}
      content={<BenchmarkForm originalBenchmark={benchmark} saveHandler={saveHandler} cancelHandler={cancelHandler} />}
      buttonGroup={[]}
      maxWidth="md"
      fullWidth={true}
    />
  );
};

export default BenchmarkEditModal;
