import React, { ReactElement, useState, useEffect } from 'react';
import { SkillSuggestionBy, SkillDataCategory, SpacingSizes, SuggestedSkill } from 'types/types';
import { badgeBuilder, lightcastSkillLink, getSkillGrowthStatus } from 'helpers/SkillProfilePageHelper';
import { GridAlignment, GridColDef, DataGrid } from '@mui/x-data-grid';
import useSkillProfileContext from 'helpers/useSkillProfileContext';
import NoDataPlaceholder from 'components/atoms/NoDataPlaceholder';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import FormControlLabel from '@mui/material/FormControlLabel';
import ComparisonChip from 'components/atoms/ComparisonChip';
import styled, { useTheme } from 'styled-components';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
import sortBy from 'lodash/sortBy';

import EmsiUIBadge from 'components/atoms/EmsiUIBadge';
import DDNInfoPopover from 'components/organisms/popovers/DDNInfoPopover';
import SkillInfoPopover from 'components/organisms/popovers/SkillInfoPopover';
import ProjectedSkillGrowthInfoPopover from 'components/organisms/popovers/ProjectedSkillGrowthInfoPopover';
import SalaryWithSkillInfoPopover from 'components/organisms/popovers/SalaryWithSkillInfoPopover';
import useRoleSkillInfoDrawer from 'components/molecules/useRoleSkillInfoDrawer';
import DataGridExport from 'components/organisms/DataGridExport';

interface ExtendedSuggestedSkill extends SuggestedSkill {
  id: string;
  suggestedByGroup: string[];
}

const CustomDataGrid = styled(DataGrid)`
  .MuiDataGrid-columnHeaders {
    line-height: unset !important;
  }

  .MuiDataGrid-columnHeaderTitleContainer {
    white-space: normal;
  }
`;

const PopoverIconContainer = styled.div`
  display: flex;
  div {
    cursor: pointer;
  }
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  min-width: 0px;
`;

const SkillGrowthChip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

const SkillProfileSuggestedSkillsTable = (): ReactElement => {
  const theme = useTheme();
  const [salaryWithSkillHeaderAnchor, setSalaryWithSkillHeaderAnchor] = useState<HTMLElement | null>(null);
  const [projectedSkillGrowthHeaderAnchor, setProjectedSkillGrowthHeaderAnchor] = useState<HTMLElement | null>(null);
  const [skillTypeHeaderAnchor, setSkillTypeHeaderAnchor] = useState<HTMLElement | null>(null);
  const [skillInfoAnchor, setSkillInfoAnchor] = useState<HTMLElement | null>(null);
  const [isAllSkillsBySelected, setIsAllSkillsBySelected] = useState(false);
  const [skillInfo, setSkillInfo] = useState<SuggestedSkill | null>(null);

  let suggestedSkillsTableColumns: GridColDef[] = [];
  const {
    skillsBy,
    suggestedSkills,
    loadingSuggestedSkills,
    isUsingBenchmarkNationwide,
    selectTempSuggestedSkill,
    deselectTempSuggestedSkill,
    skillTypeFilter,
    skillTypes,
    activeSortBy,
    role,
  } = useSkillProfileContext();

  const [skillDrawerProps, openSkillDrawer, RoleSkillInfoDrawer] = useRoleSkillInfoDrawer(role);

  const skillsBySuggestionType = () => {
    if (skillsBy === SkillSuggestionBy.All) {
      return suggestedSkills || [];
    } else {
      return suggestedSkills?.filter(skill => skill.suggestedBy === skillsBy) || [];
    }
  };

  useEffect(() => {
    const allValuesSelected = skillsBySuggestionType().reduce((allPreviousTrue, skill) => {
      return allPreviousTrue && !!skill.isSelected;
    }, true as boolean);
    setIsAllSkillsBySelected(allValuesSelected);
  }, [suggestedSkills, skillsBy]);

  const handleSkillSalaryPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setSalaryWithSkillHeaderAnchor(event.currentTarget);
  };

  const handleSkillSalaryPopoverClose = () => {
    setSalaryWithSkillHeaderAnchor(null);
  };

  const handleProjectedSkillGrowthPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setProjectedSkillGrowthHeaderAnchor(event.currentTarget);
  };

  const handleProjectedSkillGrowthPopoverClose = () => {
    setProjectedSkillGrowthHeaderAnchor(null);
  };

  const handleSkillTypePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setSkillTypeHeaderAnchor(event.currentTarget);
  };

  const handleSkillTypePopoverClose = () => {
    setSkillTypeHeaderAnchor(null);
  };

  const handleSkillNamePopoverOpen = (event: React.MouseEvent<HTMLElement>, skill) => {
    setSkillInfo(skill);
    setSkillInfoAnchor(event.currentTarget);
  };

  const handleSkillNamePopoverClose = () => {
    setSkillInfo(null);
    setSkillInfoAnchor(null);
  };

  const rows = sortBy(
    suggestedSkills
      ?.filter(skill => skillsBy === `${SkillSuggestionBy.All}` || skill.suggestedBy === skillsBy)
      ?.reduce((uniqueSkills: ExtendedSuggestedSkill[], skill) => {
        const existingSkill = uniqueSkills.find(uniqueSkill => uniqueSkill.skillId === skill.skillId);
        const suggestedBy = skill?.suggestedBy || '';
        if (!existingSkill) {
          uniqueSkills.push({
            id: skill.skillId,
            suggestedByGroup: [suggestedBy],
            ...skill,
          });
        } else {
          if (!existingSkill?.suggestedByGroup.includes(suggestedBy)) {
            existingSkill?.suggestedByGroup.push(suggestedBy);
          }
        }
        if (skillTypeFilter !== skillTypes[0]) {
          return uniqueSkills.filter(skill => skill?.type?.id.includes(skillTypeFilter.id));
        }
        return uniqueSkills;
      }, []),
    skill => {
      const sortArray: number[] = [];

      if (skillsBy === `${SkillSuggestionBy.Benchmark}`) sortArray.push(skill.frequencyInBenchmark || 0);
      sortArray.push(skill.suggestedByGroup.includes(activeSortBy.id) ? 0 : 1);
      sortArray.push(skill.suggestedByGroup.length);

      return sortArray;
    }
  );

  const handleSelectAllSkillsToggle = () => {
    skillsBySuggestionType()?.forEach(skill => {
      if (isAllSkillsBySelected) {
        deselectTempSuggestedSkill(skill);
      } else {
        if (!skill.isSelected) {
          selectTempSuggestedSkill(skill);
        }
      }
    });
    setIsAllSkillsBySelected(!isAllSkillsBySelected);
  };

  const dataGridCommonColumnDefinition = (
    flex = 0.15,
    headerAlign: GridAlignment = 'left',
    align: GridAlignment = 'left'
  ) => {
    return {
      flex,
      headerAlign,
      align,
    };
  };

  const skillSuggestionTableColumnsByDefault: GridColDef[] = [
    {
      field: 'skillName',
      renderHeader: () => {
        return (
          <>
            <div onClick={e => e.stopPropagation()}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isAllSkillsBySelected}
                    onChange={() => {
                      handleSelectAllSkillsToggle();
                    }}
                    data-cy={'suggested-skills-table-select-all-switch'}
                    disabled={skillsBySuggestionType().length === 0}
                  />
                }
                label={SkillDataCategory.Skill}
              />
            </div>
          </>
        );
      },
      ...dataGridCommonColumnDefinition(),
      renderCell: param => {
        return (
          <>
            <FormControlLabel
              onMouseEnter={event => handleSkillNamePopoverOpen(event, param.row)}
              onMouseLeave={handleSkillNamePopoverClose}
              onClick={e => e.stopPropagation()}
              control={
                <Switch
                  checked={!!param.row.isSelected}
                  data-cy={`suggested-skills-table-switch-${param.value.replace(/\s+/g, '-').toLowerCase()}`}
                  onChange={event => {
                    const toggleState = event.target.checked;
                    if (toggleState) {
                      selectTempSuggestedSkill(param.row);
                    } else {
                      deselectTempSuggestedSkill(param.row);
                    }
                  }}
                />
              }
              label={
                <Link
                  href={lightcastSkillLink(param.row.skillId)}
                  onClick={e => e.stopPropagation()}
                  target="_blank"
                  data-cy={`suggested-skills-table-value-${param.value.replace(/\s+/g, '-').toLowerCase()}`}
                >
                  {param.value}
                </Link>
              }
            />
          </>
        );
      },
    },
  ];

  const skillSuggestionTableColumnsByDescription: GridColDef[] = [
    // TODO: Re-add column to correctly calculate frequencyInProfiles
    // {
    //   field: 'frequencyInProfiles',
    //   headerName: () => SkillDataCategory.FrequencyInProfiles,
    //   renderCell: (param) => {
    //     const profilesPercentage = param.row.frequencyInProfiles || 0;
    //     return badgeBuilder(profilesPercentage / 100);
    //   },
    //   ...dataGridCommonColumnDefinition,
    // },
    {
      field: 'frequencyInPostings',
      headerName: SkillDataCategory.FrequencyInPostings,
      valueFormatter: ({ value }: { value?: number }) => `${value || 0}%`,
      renderCell: param => {
        const postingsPercentage = param.row.frequencyInPostings || 0;
        return badgeBuilder(postingsPercentage / 100);
      },
      ...dataGridCommonColumnDefinition(),
    },
  ];

  const skillSuggestionTableColumnsByBenchmarkPercentages: GridColDef[] = [
    {
      field: 'frequencyInBenchmark',
      headerName: isUsingBenchmarkNationwide
        ? SkillDataCategory.FrequencyInBenchmarkNationwide
        : SkillDataCategory.FrequencyInBenchmark,
      valueFormatter: ({ value }: { value: number }) => `${value || 0}%`,
      renderCell: param => {
        const postingsPercentage = param.row.frequencyInBenchmark || 0;
        return badgeBuilder(postingsPercentage / 100);
      },
      ...dataGridCommonColumnDefinition(),
    },
  ];

  const skillSuggestionTableColumnsByRecommended: GridColDef[] = [
    {
      field: 'skillType',
      renderHeader: () => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', minWidth: '0px' }}>
            <PopoverIconContainer onMouseEnter={handleSkillTypePopoverOpen} onMouseLeave={handleSkillTypePopoverClose}>
              <BaseIcon
                type={IconType.InformationFill}
                marginLeft={SpacingSizes.None}
                marginRight={SpacingSizes.Base}
              />
            </PopoverIconContainer>
            {SkillDataCategory.SkillType}
          </div>
        );
      },
      ...dataGridCommonColumnDefinition(),
    },
  ];

  const appearsInSortComparator = (v1, v2, param1, param2) => {
    const suggestedByGroup1 = param1.value?.suggestedByGroup || [];
    const suggestedByGroup2 = param2.value?.suggestedByGroup || [];
    const allSuggestions = [
      SkillSuggestionBy.JobDescription,
      SkillSuggestionBy.Benchmark,
      SkillSuggestionBy.Recommended,
    ];

    const lengthDifference = suggestedByGroup2.length - suggestedByGroup1.length;

    const hasSkillBy1 = suggestedByGroup1.includes(activeSortBy.id) ? 0 : 1;
    const hasSkillBy2 = suggestedByGroup2.includes(activeSortBy.id) ? 0 : 1;

    if (hasSkillBy1 !== hasSkillBy2) {
      return hasSkillBy1 - hasSkillBy2;
    }

    if (lengthDifference === 0) {
      const combinedSuggestions1 = suggestedByGroup1.concat(allSuggestions.filter(s => !suggestedByGroup1.includes(s)));
      const combinedSuggestions2 = suggestedByGroup2.concat(allSuggestions.filter(s => !suggestedByGroup2.includes(s)));

      for (let i = 0; i < combinedSuggestions1.length && i < combinedSuggestions2.length; i++) {
        const index1 = allSuggestions.indexOf(combinedSuggestions1[i]);
        const index2 = allSuggestions.indexOf(combinedSuggestions2[i]);

        if (index1 !== index2) {
          return index1 - index2;
        }
      }
    }
    return lengthDifference;
  };

  const projectedGrowthSortComparator = (v1, v2, ess1, ess2) => {
    const percent1 = ess1.value?.growthPercent || 0;
    const percent2 = ess2.value?.growthPercent || 0;
    return percent1 - percent2;
  };

  const skillSuggestionTableColumnsByAll: GridColDef<ExtendedSuggestedSkill>[] = [
    {
      field: 'ProjectedGrowth',
      sortable: true,
      sortingOrder: ['desc', 'asc'],
      sortComparator: projectedGrowthSortComparator,
      valueGetter(params) {
        return params.row;
      },
      valueFormatter: ({ value }: { value: ExtendedSuggestedSkill }) =>
        `${value.growthCategory} ${Math.floor((value.growthPercent || 0) * 100)}%`,
      renderHeader: () => (
        <>
          <PopoverIconContainer
            onMouseEnter={handleProjectedSkillGrowthPopoverOpen}
            onMouseLeave={handleProjectedSkillGrowthPopoverClose}
          >
            <BaseIcon type={IconType.InformationFill} marginLeft={SpacingSizes.None} marginRight={SpacingSizes.Base} />
          </PopoverIconContainer>
          <TableHeader>Projected Growth</TableHeader>
        </>
      ),
      renderCell: param => {
        const skillGrowthInfo = getSkillGrowthStatus(param.row.growthCategory);
        return (
          <EmsiUIBadge
            size="medium"
            color={skillGrowthInfo.chipColor}
            label={
              <SkillGrowthChip>
                <div>
                  <BaseIcon
                    marginBottom={skillGrowthInfo.marginBottom}
                    type={skillGrowthInfo.icon}
                    colorFilter={skillGrowthInfo.iconColor}
                    size={`${skillGrowthInfo.iconSize}px`}
                  />
                </div>
                <div>{Math.floor((param.row.growthPercent || 0) * 100)}%</div>
              </SkillGrowthChip>
            }
          />
        );
      },
      ...dataGridCommonColumnDefinition(0.15, 'center', 'center'),
    },
    {
      field: 'SalaryWithSkill',
      sortable: true,
      sortingOrder: ['desc', 'asc'],
      sortComparator: (_1, _2, ess1, ess2) => {
        const salaryBySkill1 = ess1.value?.salaryBySkill || 0;
        const salaryBySkill2 = ess2.value?.salaryBySkill || 0;
        return salaryBySkill1 - salaryBySkill2;
      },
      valueGetter(params) {
        return params.row;
      },
      valueFormatter: ({ value }: { value: ExtendedSuggestedSkill }) =>
        value.salaryBySkill ? `$${value.salaryBySkill.toLocaleString('en')}` : 'N/A',
      renderHeader: () => (
        <>
          <PopoverIconContainer
            onMouseEnter={handleSkillSalaryPopoverOpen}
            onMouseLeave={handleSkillSalaryPopoverClose}
          >
            <BaseIcon type={IconType.InformationFill} marginLeft={SpacingSizes.None} marginRight={SpacingSizes.Base} />
          </PopoverIconContainer>
          <TableHeader>Salary With Skill</TableHeader>
        </>
      ),
      renderCell: param => {
        const salary = param.row.salaryBySkill ? `$${param.row.salaryBySkill.toLocaleString('en')}` : 'N/A';
        return <Typography variant="strong">{salary}</Typography>;
      },
      ...dataGridCommonColumnDefinition(0.15, 'center', 'center'),
    },
    {
      field: 'AppearsIn',
      sortable: true,
      sortingOrder: ['desc', 'asc'],
      sortComparator: appearsInSortComparator,
      valueGetter(params) {
        return params.row;
      },
      valueFormatter: ({ value }: { value: ExtendedSuggestedSkill }) => `${value.suggestedByGroup.join(', ')}`,
      renderHeader: () => {
        return <TableHeader>Appears In</TableHeader>;
      },
      renderCell: param => {
        return (
          <>
            {param.row?.suggestedByGroup?.includes(SkillSuggestionBy.JobDescription) && (
              <span style={{ marginLeft: '1px' }}>
                <ComparisonChip chipType="Job Description" />
              </span>
            )}
            {param.row?.suggestedByGroup?.includes(SkillSuggestionBy.Benchmark) && (
              <span style={{ marginLeft: '1px' }}>
                <ComparisonChip chipType="Benchmark" />
              </span>
            )}
            {param.row?.suggestedByGroup?.includes(SkillSuggestionBy.Recommended) && (
              <span style={{ marginLeft: '1px' }}>
                <ComparisonChip chipType="Occupation" />
              </span>
            )}
          </>
        );
      },
      ...dataGridCommonColumnDefinition(0.15, 'center', 'center'),
    },
  ];

  const createSkillTableColumns = (skillBy: SkillSuggestionBy) => {
    switch (skillBy) {
      case `${SkillSuggestionBy.All}`:
        suggestedSkillsTableColumns = new Array<GridColDef>().concat(
          skillSuggestionTableColumnsByDefault,
          skillSuggestionTableColumnsByAll
        );
        break;
      case `${SkillSuggestionBy.JobDescription}`:
        suggestedSkillsTableColumns = new Array<GridColDef>().concat(
          skillSuggestionTableColumnsByDefault,
          skillSuggestionTableColumnsByDescription
        );
        break;
      case `${SkillSuggestionBy.Benchmark}`:
        suggestedSkillsTableColumns = new Array<GridColDef>().concat(
          skillSuggestionTableColumnsByDefault,
          skillSuggestionTableColumnsByBenchmarkPercentages,
          skillSuggestionTableColumnsByDescription
        );
        break;
      case `${SkillSuggestionBy.Recommended}`:
        suggestedSkillsTableColumns = new Array<GridColDef>().concat(
          skillSuggestionTableColumnsByDefault,
          skillSuggestionTableColumnsByRecommended
        );
        break;
      default:
        suggestedSkillsTableColumns = skillSuggestionTableColumnsByDefault;
        break;
    }
    return suggestedSkillsTableColumns;
  };

  return (
    <>
      <CustomDataGrid
        key={skillsBy}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: skillsBy === SkillSuggestionBy.All ? 'AppearsIn' : 'skillName',
                sort: 'asc',
              },
            ],
          },
        }}
        columns={createSkillTableColumns(skillsBy || SkillSuggestionBy.JobDescription)}
        rows={loadingSuggestedSkills?.[skillsBy || SkillSuggestionBy.JobDescription] ? [] : rows}
        loading={loadingSuggestedSkills?.[skillsBy || SkillSuggestionBy.JobDescription]}
        onRowClick={params => !params.row.customSkillId && openSkillDrawer(params.row)}
        components={{
          LoadingOverlay: () => (
            <>
              <div style={{ margin: '12px 30px' }}>
                {Array.from(Array(3).keys()).map(x => (
                  <div style={{ display: 'flex', gap: `${theme.customSpacing.px.xxs}px` }} key={x}>
                    {Array.from(
                      Array(createSkillTableColumns(skillsBy || SkillSuggestionBy.JobDescription).length).keys()
                    ).map(y => (
                      <Skeleton variant="text" height={32} width={'100%'} key={(x + 1) * y} />
                    ))}
                  </div>
                ))}
              </div>
            </>
          ),
          NoRowsOverlay: () => (
            <NoDataPlaceholder isFullHeight={true} dataCy={'suggested-skills-no-data-container'}>
              <Typography>
                <>
                  {'No Skills to display. '}
                  {skillsBy === SkillSuggestionBy.JobDescription && (
                    <>{'Modify Job Description above to view skill suggestions.'}</>
                  )}
                  {skillsBy === SkillSuggestionBy.Benchmark && (
                    <>{'Modify Benchmark above to view benchmark skills.'}</>
                  )}
                  {skillsBy === SkillSuggestionBy.Recommended && (
                    <>{'Modify Role Mapping to view recommended skills.'}</>
                  )}
                </>
              </Typography>
            </NoDataPlaceholder>
          ),
          Footer: () => <DataGridExport />,
        }}
        hideFooter={false}
        autoHeight
        className={`skill-profile-suggested-skills-table-${skillsBy}`.replaceAll(' ', '-').toLowerCase()}
      />
      <SalaryWithSkillInfoPopover
        handleSalaryWithSkillInfoPopoverClose={handleSkillSalaryPopoverClose}
        salaryWithSkillHeaderAnchor={salaryWithSkillHeaderAnchor}
      />
      <ProjectedSkillGrowthInfoPopover
        handleProjectedSkillGrowthPopoverClose={handleProjectedSkillGrowthPopoverClose}
        projectedSkillGrowthHeaderAnchor={projectedSkillGrowthHeaderAnchor}
      />
      <DDNInfoPopover
        handleSkillTypePopoverClose={handleSkillTypePopoverClose}
        skillTypeHeaderAnchor={skillTypeHeaderAnchor}
      />
      <RoleSkillInfoDrawer {...skillDrawerProps} />
      <SalaryWithSkillInfoPopover
        handleSalaryWithSkillInfoPopoverClose={handleSkillSalaryPopoverClose}
        salaryWithSkillHeaderAnchor={salaryWithSkillHeaderAnchor}
      />
      <ProjectedSkillGrowthInfoPopover
        handleProjectedSkillGrowthPopoverClose={handleProjectedSkillGrowthPopoverClose}
        projectedSkillGrowthHeaderAnchor={projectedSkillGrowthHeaderAnchor}
      />
      <DDNInfoPopover
        handleSkillTypePopoverClose={handleSkillTypePopoverClose}
        skillTypeHeaderAnchor={skillTypeHeaderAnchor}
      />
      {skillInfo && (
        <SkillInfoPopover
          handleSkillNamePopoverClose={handleSkillNamePopoverClose}
          skillInfo={skillInfo}
          skillInfoAnchor={skillInfoAnchor}
        />
      )}
    </>
  );
};

export default SkillProfileSuggestedSkillsTable;
