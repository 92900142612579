import { useContext, useCallback } from 'react';
import { CompanyContext, CompanyContextState, companyOverrideInfoStorageKey } from 'contexts/CompanyContext';
import RolesService from 'services/RolesService';
import BenchmarksService from 'services/BenchmarksService';
import axios from 'helpers/api_helper';
import useAuthContext from 'helpers/UseAuthContext';
import { companyModifier } from 'helpers/company_modifiers';
import {
  IntegrationCredentials,
  IntegrationSchedule,
  SkillLevel,
  VendorCompanyConfiguration,
  IntegrationScheduleJobType,
  IntegrationScheduleType,
} from 'types/types';
import IntegrationService from 'services/IntegrationService';

const useCompanyContext = () => {
  const [state, setState, loadAdminSpecificSettings] = useContext(CompanyContext);
  const { isAnalyst } = useAuthContext();

  const triggerReload = () => {
    setState((state: CompanyContextState): CompanyContextState => ({ ...state, refreshKey: Math.random() }));
  };

  const setCompanyId = (companyId: string) => {
    setState((state: CompanyContextState): CompanyContextState => ({ ...state, companyId }));
  };

  const setCompanyName = (companyName: string) => {
    setState((state: CompanyContextState): CompanyContextState => ({ ...state, companyName }));
  };

  const setCompanyProfilesId = (companyProfilesId: string) => {
    setState((state: CompanyContextState): CompanyContextState => ({ ...state, companyProfilesId }));
  };

  const setActiveCompanyInfo = useCallback((newCompanyInfo: Partial<CompanyContextState>) => {
    setState((state: CompanyContextState): CompanyContextState => ({ ...state, ...newCompanyInfo }));
    window.sessionStorage.setItem(companyOverrideInfoStorageKey, JSON.stringify(newCompanyInfo));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setInitialCompanyId = (companyId: string) => {
    if (!state.companyId) {
      setState((state: CompanyContextState): CompanyContextState => ({ ...state, companyId }));
    }
  };

  const addCategory = async newCategory => {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_MICRO_METADATA_ROOT}/companies/${state.companyId}/meta/categories`,
      { ...newCategory, companyId: state.companyId }
    );
    resp.data.success && triggerReload();
    return resp.data.success;
  };

  const updateCategory = async category => {
    const resp = await axios.put(
      `${process.env.REACT_APP_API_MICRO_METADATA_ROOT}/companies/${state.companyId}/meta/categories/${category.categoryId}`,
      category
    );
    resp.data.success && triggerReload();
    return resp.data.success;
  };

  const deleteCategory = async (categoryId: string) => {
    const resp = await axios.delete(
      `${process.env.REACT_APP_API_MICRO_METADATA_ROOT}/companies/${state.companyId}/meta/categories/${categoryId}`
    );
    resp.data.success && triggerReload();
    return resp.data.success;
  };

  const getCategoryByName = (categoryName: string, parentId: string | null = null): any => {
    return Object.values(state.categories).filter((c: any) => {
      return c.parentId === parentId && c.name === categoryName;
    });
  };

  const getDepartmentsCategoryId = () => {
    const departmentsCategory = getCategoryByName('Departments')[0];
    return departmentsCategory?.categoryId;
  };

  const getDepartments = () => {
    return state.categoriesTree[getDepartmentsCategoryId()]?.children;
  };

  const getCategoryById = (categoryId: string): any => {
    return Object.values(state.categories).filter((c: any) => {
      return c.categoryId === categoryId;
    })[0];
  };

  const getCategoryAncestors = (categoryId: string) => {
    const ancestors: any[] = [];
    const seenIds = {}; // to prevent infinite loops
    let cat = state.categories[categoryId];
    seenIds[categoryId] = true;
    while (cat) {
      if (!seenIds[cat.parentId]) {
        cat = state.categories[cat.parentId];
        cat && ancestors.push(cat);
      } else {
        cat = null; // to abort loop
      }
    }
    return ancestors;
  };

  const getCategoryChildren = (categoryId: string) => {
    const children = Object.values(state.categories).filter((c: any) => {
      return c.parentId === categoryId;
    });

    return children;
  };

  const getCategorySiblings = (categoryId: string) => {
    const category = getCategoryById(categoryId);
    const silblings = Object.values(state.categories).filter((c: any) => {
      return c.categoryId !== categoryId && c.parentId === category.parentId;
    });

    return silblings;
  };

  const getCategoryByParentId = (parentId: string | null = null) => {
    return Object.values(state.categories).filter((c: any) => {
      return c.parentId === parentId;
    });
  };

  const getDisplayBreadcrumbForCategoryId = (categoryId: string) => {
    if (categoryId === undefined) {
      return '';
    }
    const deptName = getCategoryById(categoryId)?.name || categoryId;
    const orgStruct =
      getCategoryAncestors(categoryId)
        .map(c => c.name)
        .reverse()
        .join(' \\ ') || deptName;
    const teamName = getCategoryById(categoryId)?.name || categoryId;
    return `${orgStruct} \\ ${teamName}`;
  };

  const updateRole = role => {
    const index = state.roles.findIndex(r => r.roleId === role.roleId);
    if (index >= 0) {
      const rolesCopy = [...state.roles];
      rolesCopy[index] = role;
      setState((state: CompanyContextState): CompanyContextState => ({ ...state, roles: rolesCopy }));
    }
  };

  const deleteRole = async role => {
    const success = await RolesService.deleteRole(role);
    if (success) {
      const indexToRemove = state.roles.findIndex(r => r.roleId === role.roleId);
      if (indexToRemove >= 0) {
        const rolesCopy = [...state.roles];
        rolesCopy.splice(indexToRemove, 1);
        setState((state: CompanyContextState): CompanyContextState => ({ ...state, roles: rolesCopy }));
        return true;
      }
    }
    return false;
  };

  const loadBenchmarks = async () => {
    if (isAnalyst()) {
      setState(previous => ({ ...previous, benchmarksLoading: true }));
      const { benchmarks } = await BenchmarksService.getBenchmarks(state.companyId, 150);
      const defaultBenchmark = benchmarks.find(b => b.companyDefault) || benchmarks[0];
      setState(previous => ({
        ...previous,
        defaultBenchmark,
        benchmarksLoaded: true,
        benchmarks,
        benchmarksLoading: false,
      }));
    }
  };

  const hasProductModule = (productModuleName: string) => {
    return state.product_modules && state.product_modules.includes(productModuleName);
  };

  const setProductModules = (productModules: string[]) => {
    setState((state: CompanyContextState): CompanyContextState => ({ ...state, product_modules: productModules }));
    triggerReload();
  };

  const setCompanyModifiers = (companyModifiers: string[]) => {
    setState((state: CompanyContextState): CompanyContextState => ({ ...state, company_modifiers: companyModifiers }));
    triggerReload();
  };

  const isAR = () => {
    return state.company_modifiers && state.company_modifiers.includes(companyModifier.AR);
  };

  const getCompanySkillLevelOptions = () => {
    return state.hasActiveVendorCompanyIntegration
      ? state.workdayVendorCompany?.vendor?.skillLevel?.map(sl => ({
        id: sl.level.toString(),
        value: sl.label.toString(),
      })) || []
      : Object.values(SkillLevel)
        .filter(sl => typeof sl === 'string')
        .map((k, i) => ({ id: i.toString(), value: k.toString() }));
  };

  // TODO: confirm a single active vendor of type HRIS per company is what we expect
  const setIntegrationCredentials = async (vendorId: string, integrationCredentials: IntegrationCredentials) => {
    await IntegrationService.setIntegrationCredentials(state.companyId, vendorId, integrationCredentials);
    setState((state: CompanyContextState): CompanyContextState => ({ ...state, integrationCredentials: { ...state.integrationCredentials, [vendorId]: integrationCredentials } }));
  };

  const filterCompanyVendorConfigItems = (
    vendorCompanyConfig: VendorCompanyConfiguration[] | undefined,
    fieldIdFilterValue: string,
    filterOperation: 'include' | 'exclude'
  ): Array<VendorCompanyConfiguration> | undefined => {
    return vendorCompanyConfig
      ? filterOperation === 'include'
        ? vendorCompanyConfig.filter(config => config.fieldId === fieldIdFilterValue)
        : vendorCompanyConfig.filter(config => config.fieldId !== fieldIdFilterValue)
      : undefined;
  };

  const getMappingConfigItems = () => {
    return filterCompanyVendorConfigItems(
      state.workdayVendorCompany?.vendorCompanyConfigurations,
      'roleImportMapping',
      'include'
    );
  };

  const getIntegrationCriteria = (): VendorCompanyConfiguration[] => {
    return (
      filterCompanyVendorConfigItems(
        state.workdayVendorCompany?.vendorCompanyConfigurations,
        'roleExportCriteria',
        'include'
      ) || []
    );
  };

  const saveCompanyIntegrationConfig = async integrationConfigItems => {
    // I believe this can now just be the 'Field Mapping' subOption optionConfiguration which has been retrieved as vendorCompanyConfig above
    if (state.workdayVendorCompany) {
      return await IntegrationService.createOrUpdateCompanyIntegrationConfig(
        state.companyId,
        state.workdayVendorCompany.vendorId,
        state.workdayVendorCompany.id,
        integrationConfigItems
      );
    }
  };

  const setIntegrationCriteria = async (integrationCriteria: Array<VendorCompanyConfiguration>) => {
    const savedIntegrationConfig = await saveCompanyIntegrationConfig(integrationCriteria);
    await setIntegrationVendorCompanyConfiguration(integrationCriteria);
    return savedIntegrationConfig;
  };

  const setIntegrationMappings = async (integrationMappings: Array<VendorCompanyConfiguration>) => {
    const savedIntegrationConfig = await saveCompanyIntegrationConfig(integrationMappings);
    await setIntegrationVendorCompanyConfiguration(integrationMappings);
    return savedIntegrationConfig;
  };

  const setIntegrationVendorCompanyConfiguration = async (
    vendorCompanyConfiguration: Array<VendorCompanyConfiguration>
  ) => {
    const vendorCompanyWithConfiguration = state.vendorCompaniesWithConfiguration?.find(
      vcwc => vcwc.id === vendorCompanyConfiguration[0].vendorCompanyId
    );
    if (vendorCompanyWithConfiguration)
      vendorCompanyWithConfiguration.vendorCompanyConfigurations = vendorCompanyConfiguration;

    const updateActiveVendorCompanyIntegration = state.workdayVendorCompany && {
      ...state.workdayVendorCompany,
      vendorCompanyConfig: vendorCompanyConfiguration,
      vendorCompanyConfigurations: vendorCompanyConfiguration,
    };
    setState(
      (state: CompanyContextState): CompanyContextState => ({
        ...state,
        workdayVendorCompany: updateActiveVendorCompanyIntegration,
      })
    );
  };

  const setIntegrationSchedules = async (
    vendorId: string,
    schedules: { job: IntegrationScheduleJobType; schedule: IntegrationScheduleType }[]
  ) => {
    // current schedules
    const scheduleSettings = state.integrationSchedules || [];
    // schedules that dosen't exist in the current settings
    const scheduleToCreate = schedules.filter(
      schedule => !scheduleSettings?.some(existingSchedule => existingSchedule.job === schedule.job)
    );
    // schedules that need to be updated
    const schedulesToUpdate = scheduleSettings.reduce((accumulator: IntegrationSchedule[], existingSchedule) => {
      const updatedSchedule = schedules.find(schedule => schedule.job === existingSchedule.job);
      if (updatedSchedule && updatedSchedule.schedule !== existingSchedule.schedule) {
        accumulator.push({ ...existingSchedule, schedule: updatedSchedule.schedule as IntegrationScheduleType });
      }
      return accumulator;
    }, []);

    const schedulePromises: Promise<any>[] = [];

    scheduleToCreate.forEach(schedule =>
      schedulePromises.push(
        IntegrationService.setIntegrationSchedules({
          companyId: state.companyId,
          vendorId,
          job: schedule.job as IntegrationScheduleJobType,
          schedule: schedule.schedule as IntegrationScheduleType,
        })
      )
    );

    schedulesToUpdate.forEach(schedule =>
      schedulePromises.push(
        IntegrationService.updateIntegrationSchedules({
          companyId: state.companyId,
          vendorId,
          job: schedule.job,
          schedule: schedule.schedule,
          id: schedule.id,
        })
      )
    );

    const schedulesResponse = (await Promise.allSettled(schedulePromises)).map(response => {
      if (response.status === 'fulfilled') {
        const { id, job, schedule } = response.value.data;
        return {
          id,
          companyId: state.companyId,
          job,
          schedule,
        };
      }
      return response.reason;
    });

    // update the state with the new schedules
    const newScheduleSettings = scheduleSettings.map(existingSchedule => {
      const updatedSchedule = schedulesResponse.find(schedule => schedule.job === existingSchedule.job);
      if (updatedSchedule) {
        return { ...existingSchedule, ...updatedSchedule };
      }
      return existingSchedule;
    });

    setState(
      (state: CompanyContextState): CompanyContextState => ({
        ...state,
        integrationSchedules: newScheduleSettings,
      })
    );
  };

  return {
    clientId: state.clientId,
    companyName: state.companyName,
    safeCompanyName: state.safeCompanyName,
    subDomain: state.subDomain,
    companyId: state.companyId,
    companyPostingsId: state.companyPostingsId,
    companyProfilesId: state.companyProfilesId,
    companyEmailDomains: state.companyEmailDomains,
    categories: state.categories,
    categoriesTree: state.categoriesTree,
    categoriesLoaded: state.categoriesLoaded,
    benchmarksLoading: state.benchmarksLoading,
    benchmarks: state.benchmarks,
    defaultBenchmark: state.defaultBenchmark,
    vendorCompaniesWithConfiguration: state.vendorCompaniesWithConfiguration,
    companyDetailsLoaded: state.companyDetailsLoaded,
    activeVendorCompanyIntegration: state.workdayVendorCompany,
    setIntegrationVendorCompanyConfiguration,
    hasActiveVendorCompanyIntegration: state.hasActiveVendorCompanyIntegration,
    integrationSchedules: state.integrationSchedules,
    setIntegrationSchedules,
    integrationCredentials: state.integrationCredentials,
    setCompanyName,
    setCompanyId,
    setCompanyProfilesId,
    setActiveCompanyInfo,
    setInitialCompanyId,
    addCategory,
    updateCategory,
    deleteCategory,
    getDepartmentsCategoryId,
    getDepartments,
    getCategoryById,
    getCategoryByName,
    getCategoryByParentId,
    getCategoryAncestors,
    getCategoryChildren,
    getCategorySiblings,
    getDisplayBreadcrumbForCategoryId,
    isAR,
    loadBenchmarks,
    updateRole,
    deleteRole,
    hasProductModule,
    setProductModules,
    setCompanyModifiers,
    triggerReload,
    getCompanySkillLevelOptions,
    setIntegrationCredentials,
    setIntegrationMappings,
    setIntegrationCriteria,
    getMappingConfigItems,
    getIntegrationCriteria,
    loadAdminSpecificSettings,
  };
};

export default useCompanyContext;
