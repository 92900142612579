import React, { useState, useEffect, ReactElement } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import CompanyEditModal from 'components/organisms/modals/CompanyEditModal';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
// import CompaniesSearchBar from 'components/molecules/CompaniesSearchBar';
import AdvancedSearchBar from 'components/molecules/AdvancedSearchBar';
import TablePagination from 'components/molecules/TablePagination';
import useCompanyContext from 'helpers/UseCompanyContext';
import CompanyService from 'services/CompanyService';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import cloneDeep from 'lodash/cloneDeep';
import styled from 'styled-components';
import queryString from 'query-string';
import Company from 'classes/company';
import Box from '@mui/material/Box';
import ReactGA from 'react-ga';

const Wrapper = styled.div`
  font-size: 12px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0px ${props => props.theme.customSpacing.px.m}px;
`;

const HeaderSubnavContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;

  .complex-input-bar-container {
    max-width: calc(100vw - ${props => props.theme.customSpacing.px.base * 110}px);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: ${props => props.theme.customSpacing.px.xxs}px;

    .MuiTablePagination-toolbar {
      justify-content: space-between;
      padding: 0px;
    }

    .complex-input-bar-container {
      max-width: revert;
    }
  }
`;

const SearchBarWrapper = styled.div`
  display: flex;
  align-content: left;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
`;

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;

  &.pagination-top {
    padding-left: ${props => props.theme.customSpacing.px.s}px;

    @media (max-width: 768px) {
      padding: 0px;
    }
  }

  &.pagination-bottom {
    box-shadow: ${props => props.theme.boxShadow.surface1};
    padding: 0px ${props => props.theme.customSpacing.px.base * 10}px;
  }
`;

const ColumnItem = styled.div`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.border.subdued};
  color: ${props => props.theme.colors.text.subdued};
  display: flex;
  gap: ${props => props.theme.customSpacing.px.xxs}px;
  font-size: 1rem;
  font-weight: 800;
  padding: ${props => `${props.theme.customSpacing.px.base * 4}px ${props.theme.customSpacing.px.base * 10}px`};
`;

const defaultTotalFacets = {
  status: {
    key: 'status',
    name: 'Status',
    displayValue: 'Status',
    values: [
      { key: 'active', value: 'Active', count: 0 },
      { key: 'inactive', value: 'Inactive', count: 0 },
    ],
  },
};
function CompaniesManagementPage(): ReactElement {
  const qs: any = queryString.parse(window.location.search, { parseBooleans: true, parseNumbers: true });
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(qs.p || 0);
  const [rowsPerPage, setRowsPerPage] = useState(qs.n || 10);
  const [searchText, setSearchText] = useState(qs.s || '');
  const [appliedFilters, setAppliedFilters] = useState(JSON.parse(qs.af || '{}') || {});
  const [totalFacets, setTotalFacets] = useState(defaultTotalFacets);
  const { companyId, companyName, setActiveCompanyInfo } = useCompanyContext();
  const [editingCompany, setEditingCompany] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [companies, setCompanies] = useState([] as Company[]);
  const [loadingCompanies, setLoadingCompanies] = useState(true);
  const [allCompanies, setAllCompanies] = useState([] as Company[]);
  const [isPageChanged, setIsPageChanged] = useState(false);

  const columns: GridColDef<Company>[] = [
    {
      field: 'name',
      headerName: 'Company Name',
      flex: 1.5,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'customerId',
      headerName: 'Salesforce ID',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'demo',
      headerName: 'Demo',
      flex: 0.5,
      headerAlign: 'left',
      align: 'left',
      valueGetter(params) {
        return params.row?.company_modifiers?.includes('Demo') ? 'Demo' : '';
      },
    },
    {
      field: 'ar',
      headerName: 'AR',
      flex: 0.5,
      headerAlign: 'left',
      align: 'left',
      valueGetter(params) {
        return params.row?.company_modifiers?.includes('AR') ? 'AR' : '';
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1.5,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell(params) {
        return (
          <ColumnItem key={`${params.row.name}-${params.id}`}>
            <Button
              onClick={() => {
                showEditModal(params.row);
              }}
            >
              edit
            </Button>
            <Button
              onClick={() => {
                setActiveCompanyInfo({
                  companyId: params.row.customerId,
                  companyPostingsId: params.row.companyPostingsId,
                  companyProfilesId: params.row.companyProfilesId,
                  companyName: params.row.name,
                });
              }}
              disabled={params.row.customerId === companyId}
            >
              view account
            </Button>
          </ColumnItem>
        );
      },
    },
  ];

  const showCreateModal = () => {
    ReactGA.modalview('Role Create Modal');
    ReactGA.event({
      category: 'company management bar',
      action: 'company create',
      label: 'show modal',
    });
    setEditingCompany(new Company({}));
    setModalIsOpen(true);
  };

  const showEditModal = company => {
    ReactGA.modalview('Role Edit Modal');
    ReactGA.event({
      category: 'company management bar',
      action: 'company edit',
      label: 'show modal',
    });
    setEditingCompany(new Company(company));
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setEditingCompany({});
    setModalIsOpen(false);
  };

  const loadCompanies = async () => {
    setLoadingCompanies(true);
    const companies = await CompanyService.getCompanies();
    companies.map(company => {
      if (!company.company_modifiers?.includes('Active')) {
        if (company.status !== 'inactive') {
          company.company_modifiers
            ? company.company_modifiers.push('Active')
            : (company.company_modifiers = ['Active']);
        }
      }
      return company;
    });
    setAllCompanies(companies);
    setLoadingCompanies(false);
  };

  const pageChangeHandler = (): void => {
    setIsPageChanged(!isPageChanged);
  };

  const updateTotalFacetsCount = () => {
    const totalFacetsCopy = cloneDeep(totalFacets);
    Object.keys(totalFacetsCopy).forEach(area => {
      totalFacetsCopy[area].values.forEach(facet => {
        facet.count = 0;
      });
    });
    allCompanies.forEach(company => {
      Object.keys(totalFacetsCopy).forEach(area => {
        const facet = totalFacetsCopy[area].values.find(facet => facet.key === company[area]);
        if (facet) {
          facet.count++;
        }
      });
    });
    setTotalFacets(totalFacetsCopy);
  };

  useEffect(() => {
    loadCompanies();
  }, []);

  const handleClearAllFacets = area => {
    const appliedFiltersCopy = { ...appliedFilters };

    if (appliedFiltersCopy[area]) {
      delete appliedFiltersCopy[area];
      setCurrentPage(0);
      setAppliedFilters(appliedFiltersCopy);
    }
  };

  const handleSelectAllFacets = area => {
    const appliedFiltersCopy = { ...appliedFilters };
    const totalFacetsCopy = cloneDeep(totalFacets);
    appliedFiltersCopy[area] = totalFacetsCopy[area].values;
    setCurrentPage(0);
    setAppliedFilters(appliedFiltersCopy);
  };

  const handleSearchTextChange = (text: string) => {
    setCurrentPage(0);
    setSearchText(text);
  };

  useEffect(() => {
    const companies = filterCompanies(allCompanies);
    const qs = queryString.stringify({
      p: currentPage,
      n: rowsPerPage,
      s: searchText,
      af: JSON.stringify(appliedFilters),
    });
    window.history.replaceState(null, '', `${window.location.pathname}?${qs}`);
    setCompanies(companies.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage));
    setTotalCount(companies.length);
  }, [currentPage, rowsPerPage, searchText, appliedFilters, allCompanies]);

  const filterCompanies = (companies: Company[]) => {
    let filteredCompanies = companies;
    Object.keys(appliedFilters).forEach(area => {
      filteredCompanies = filteredCompanies.filter(company => {
        const facet = appliedFilters[area].find(facet => facet.key === company[area]);
        return facet;
      });
    });
    filteredCompanies = filteredCompanies.filter(company => {
      return company.name.toLowerCase().includes(searchText.toLowerCase());
    });
    return filteredCompanies;
  };

  useEffect(() => {
    updateTotalFacetsCount();
  }, [allCompanies]);

  const handleToggle = (area, value, displayValue) => {
    const appliedFiltersCopy = { ...appliedFilters };

    if (!appliedFiltersCopy[area]) {
      appliedFiltersCopy[area] = [
        {
          key: displayValue || value,
          value,
        },
      ];
    } else {
      const i = appliedFiltersCopy[area].findIndex(f => f.value === value);
      if (i >= 0) {
        appliedFiltersCopy[area].splice(i, 1);
      } else {
        appliedFiltersCopy[area].push({
          key: displayValue || value,
          value,
        });
      }
    }

    // remove empty collections
    if (appliedFiltersCopy[area].length === 0) {
      delete appliedFiltersCopy[area];
    }
    setCurrentPage(0);
    setAppliedFilters(appliedFiltersCopy);
  };

  return (
    <MainContentTemplate pageTitle="Manage Companies">
      <ContentHeaderTemplate
        contentTitle="Manage Companies"
        actionsComponent={
          <Button onClick={showCreateModal} variant="contained" color="actionPrimary">
            add new company
          </Button>
        }
        subNavComponent={
          <HeaderWrapper>
            <Typography>
              <b>Active Company: </b>
              {companyName}
            </Typography>
            <HeaderSubnavContainer>
              <SearchBarWrapper>
                <AdvancedSearchBar
                  placeholder={'Search Companies'}
                  searchText={searchText}
                  searchBarName="company-search-bar"
                  totalFacets={totalFacets}
                  facetsFilter={appliedFilters}
                  handleFacetsFilterToggle={handleToggle}
                  handleSearchTextChange={handleSearchTextChange}
                  handleClearAllFacets={handleClearAllFacets}
                  handleSelectAllFacets={handleSelectAllFacets}
                />
              </SearchBarWrapper>
              <Pagination className="pagination-top">
                <TablePagination
                  dataCy="roles-list-table-pagination"
                  total={totalCount}
                  page={totalCount <= 0 ? 0 : currentPage}
                  setPage={setCurrentPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  pageChangeHandler={pageChangeHandler}
                  justifyContent="flex-end"
                />
              </Pagination>
            </HeaderSubnavContainer>
          </HeaderWrapper>
        }
      />
      <Wrapper>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
          <DataGrid
            columns={columns}
            rows={companies}
            loading={loadingCompanies}
            getRowId={(row: Company) => row.customerId || row.name}
            pagination
            paginationMode="client"
            autoHeight
            rowsPerPageOptions={[10, 25, 50, 100]}
            hideFooterPagination={true}
            hideFooter={true}
            disableSelectionOnClick
          />
          <Pagination className="pagination-bottom">
            <TablePagination
              dataCy="roles-list-table-pagination-bottom"
              total={totalCount}
              page={totalCount <= 0 ? 0 : currentPage}
              setPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              pageChangeHandler={pageChangeHandler}
            />
          </Pagination>
        </div>
      </Wrapper>
      <CompanyEditModal
        isOpen={modalIsOpen}
        company={editingCompany}
        closeHandler={closeModal}
        refreshHandler={async () => await loadCompanies()}
      />
    </MainContentTemplate>
  );
}

export default CompaniesManagementPage;
