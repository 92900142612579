import React, { ReactElement, ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import RequiredFieldIndicator from 'components/atoms/RequiredFieldIndicator';
import AsyncSelect from 'react-select/async';
import customStyles from 'helpers/asyncSelectStylesHelper';
import styled, { useTheme } from 'styled-components';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { IconColorFilter, SpacingSizes } from 'types/types';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ActionItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.customSpacing.px.base}px;
  border: 1px solid ${props => props.theme.colors.border.default};
  border-radius: ${props => props.theme.borderRadius.default};
  margin: ${props => props.theme.customSpacing.px.xs}px ${props => props.theme.customSpacing.px.none}px;
  padding: ${props => props.theme.customSpacing.px.base * 4}px;
`;

const RadioInputOuterCircleIcon = styled.div<{ $checked?: boolean }>`
  grid-area: 1/1;
  border: 2px solid
    ${props => (props.$checked ? props.theme.colors.interactive.default : props.theme.colors.border.default)};
  border-radius: 50%;
  width: 18px;
  height: 18px;
`;

const RadioInputInnerCircleIcon = styled.div<{ $checked?: boolean }>`
  ${props =>
    props.$checked &&
    `
      grid-area: 1/1;
      background-color: ${props.theme.colors.interactive.default};
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: ${props.theme.customSpacing.px.base}px;
    `}
`;

const RadioListItemContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.customSpacing.px.xxs}px;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.border.default};
  border-radius: ${props => props.theme.borderRadius.default};
  cursor: pointer;
  margin: ${props => props.theme.customSpacing.px.base}px ${props => props.theme.customSpacing.px.none}px;
  padding: ${props => props.theme.customSpacing.px.xxs}px ${props => props.theme.customSpacing.px.base * 4}px;

  &:hover {
    background-color: ${props => props.theme.colors.action.secondaryHovered};
    border-color: ${props => props.theme.colors.border.hovered};

    ${RadioInputOuterCircleIcon} {
      border-color: ${props => props.theme.colors.interactive.default};
    }
  }
`;

const RadioListSelectedItemContainer = styled.div<{ $iconColor?: string }>`
  display: flex;
  gap: ${props => props.theme.customSpacing.px.xxs}px;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.border.default};
  border-radius: ${props => props.theme.borderRadius.default};
  margin: ${props => props.theme.customSpacing.px.base}px ${props => props.theme.customSpacing.px.none}px;
  padding: ${props => props.theme.customSpacing.px.xxs}px ${props => props.theme.customSpacing.px.base * 4}px;

  ${RadioInputOuterCircleIcon} {
    border: none;
    border-radius: 50%;
    background-color: ${props => props.$iconColor};
  }
`;

const EllipsizeText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type radioListItemProps = {
  key: string;
  value: string;
};

type CollapsibleAutocompleteRadioListProps = {
  title?: string;
  radioItemsSelector?: string;
  radioItemSelected?: string;
  radioItemSelectedLabel?: string;
  radioItemSelectedIconColor?: string;
  radioListItems?: radioListItemProps[];
  radioListItemsComponent?: ReactNode[];
  required?: boolean;
  collapse?: boolean;
  collapseHandler?: (arg: any) => void;
  collapseActionLabel?: string;
  description?: string;
  placeholder?: string;
  autocompleteFunction?: (q: string) => Promise<any>;
  autocompleteOnChange?: (arg: any) => void;
  radioListItemsLoadingMessage?: string;
  radioListItemsHeaderComponent?: ReactNode;
  radioListItemOnClick?: (arg: any) => void;
  dataCy?: string;
  keyId?: string;
};

const CollapsibleAutocompleteRadioList = ({
  title,
  radioItemsSelector,
  radioItemSelected,
  radioItemSelectedLabel = '',
  radioItemSelectedIconColor,
  radioListItems,
  radioListItemsComponent,
  required = true,
  collapse = true,
  collapseHandler,
  collapseActionLabel = '',
  description = '',
  placeholder = 'Search',
  autocompleteFunction,
  autocompleteOnChange,
  radioListItemsLoadingMessage = '',
  radioListItemsHeaderComponent,
  radioListItemOnClick,
  dataCy = 'collapsible-autocomplete-radio-list',
  keyId = '',
}: CollapsibleAutocompleteRadioListProps): ReactElement => {
  const theme = useTheme();
  radioItemSelectedIconColor = radioItemSelectedIconColor || theme.colors.icon.success.hex;
  const handleRadioItemClick = (radioItem: radioListItemProps) => {
    radioListItemOnClick && radioListItemOnClick({ ...radioItem, source: 'radio list' });
  };

  const handleAutocompleteItemChange = (autocompleteItem: any) => {
    autocompleteOnChange && autocompleteOnChange({ ...autocompleteItem, source: 'autocomplete' });
  };

  const customTheme = (theme: any) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
    },
  });

  const noOptionsMessage = ({ inputValue }) => (inputValue ? 'No results' : 'Type to search');

  return (
    <Wrapper key={title + '-' + keyId}>
      <Typography variant="heading" data-cy={dataCy + '-title'}>
        {title}
        {required && <RequiredFieldIndicator />}
      </Typography>
      {!collapse && (
        <>
          <Typography data-cy={dataCy + '-description'}>{description}</Typography>
          <ActionItemsWrapper>
            {autocompleteFunction && (
              <AsyncSelect
                value={''}
                styles={customStyles}
                noOptionsMessage={noOptionsMessage}
                theme={customTheme}
                cacheOptions
                loadOptions={autocompleteFunction}
                onChange={(item: any) => handleAutocompleteItemChange(item)}
                placeholder={placeholder}
                classNamePrefix={dataCy + '-autocomplete'}
              />
            )}
            <div
              style={{ width: '95%', margin: `0px ${theme.customSpacing.px.base * 4}px` }}
              data-cy={dataCy + '-radio-list-header'}
            >
              {radioListItemsHeaderComponent}
            </div>
            <div style={{ margin: `0px ${theme.customSpacing.px.base * 4}px` }} data-cy={dataCy + '-loading-message'}>
              {radioListItemsLoadingMessage}
            </div>
            <div data-cy={dataCy + '-radio-list'}>
              {radioListItems?.map((item, index) => {
                return (
                  <RadioListItemContainer
                    key={keyId + item.value + item.key}
                    onClick={() => handleRadioItemClick(item)}
                    tabIndex={0}
                  >
                    <div style={{ display: 'grid' }}>
                      <RadioInputOuterCircleIcon $checked={radioItemSelected === item.value} />
                      <RadioInputInnerCircleIcon $checked={radioItemSelected === item.value} />
                    </div>
                    <div style={{ width: '95%' }}>
                      <input
                        type="radio"
                        name={radioItemsSelector}
                        value={item.value}
                        checked={radioItemSelected === item.value}
                        readOnly={true}
                        style={{ display: 'none' }}
                        data-cy={dataCy + `-radio-input-item-${index}`}
                      />
                      {radioListItemsComponent?.[index] || (
                        <EllipsizeText title={item.value}>{item.value}</EllipsizeText>
                      )}
                    </div>
                  </RadioListItemContainer>
                );
              })}
            </div>
          </ActionItemsWrapper>
        </>
      )}
      {collapse && (
        <RadioListSelectedItemContainer $iconColor={radioItemSelectedIconColor} data-cy={dataCy + '-selected'}>
          <RadioInputOuterCircleIcon>
            <BaseIcon
              type={IconType.CircleCheck}
              size={'18px'}
              marginLeft={SpacingSizes.None}
              marginRight={SpacingSizes.None}
              colorFilter={IconColorFilter.OnPrimary}
            />
          </RadioInputOuterCircleIcon>
          <div style={{ display: 'flex', width: '95%', justifyContent: 'space-between' }}>
            <EllipsizeText title={radioItemSelectedLabel || radioItemSelected}>
              {radioItemSelectedLabel || radioItemSelected}
            </EllipsizeText>
            <Typography
              variant="strong"
              color={theme.colors.interactive.default}
              onClick={() => {
                collapseHandler && collapseHandler(false);
              }}
              sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
              data-cy={dataCy + '-collapse-action-label'}
            >
              {collapseActionLabel}
            </Typography>
          </div>
        </RadioListSelectedItemContainer>
      )}
    </Wrapper>
  );
};

export default CollapsibleAutocompleteRadioList;
