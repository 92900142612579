import { useContext } from 'react';
import { UserContext } from 'contexts/UserContext';
import axios from 'helpers/api_helper';
import useCompanyContext from 'helpers/UseCompanyContext';

const useUserContext = (): any => {
  const [state, setState] = useContext(UserContext);
  const { companyId } = useCompanyContext();

  const setJobTitle = async jobTitle => {
    const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/user/${state.userId}/jobTitle`;
    await axios.put(url, { jobTitle });
    setState(state => ({ ...state, jobTitle }));
  };

  const triggerReload = () => {
    setState(state => ({ ...state, refreshKey: Math.random() }));
  };

  return {
    userDetails: state.details || {},
    userId: state.userId || null,
    userLoaded: state.loaded,
    mentorshipInfo: state.mentorshipInfo || {},
    userRole: state.role,
    setJobTitle,
    triggerReload,
    currentRefreshKey: state.refreshKey,
  };
};

export default useUserContext;
