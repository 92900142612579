import { useContext, useEffect } from 'react';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import values from 'lodash/values';
import { RoleEditContext, RoleEditContextState } from 'contexts/RoleEditContext';
import useCompanyContext from 'helpers/UseCompanyContext';
import RolesService from 'services/RolesService';
import { SuggestedSkill, ExtractedSkill, Role } from 'types/types';

export interface UseRoleMappingReturnObject {
  role?: Role;
  keywordSearchMatchedTitles?: any;
  skillsFromDescription?: ExtractedSkill[];
  skillsFromDescriptionNormalized?: SuggestedSkill[];
  skillSearchSkillsList?: SuggestedSkill[];
  skillSearchManualSkills?: SuggestedSkill[];
  isSkillsExtracting?: boolean;
  isLoading?: boolean;
  isSaving?: boolean;
  isCopying?: boolean;
  refreshKey?: number;
  setSkillsFromDescription?: (skillsFromDescription: ExtractedSkill[]) => void;
  setIsSkillsExtracting?: (extracting: boolean) => void;
  addManualSkillSearchSkills?: (item: { label: string; value: any; source: string }) => void;
  updateSkillSearchListSelection?: (value: string, checked: boolean) => void;
  updateRoleMapping?: (toUpdate: { reviewed?: boolean; needsApproval?: boolean }) => Promise<boolean>;
  setIsSaving: (isSaving: boolean) => void;
}

const useRoleMapping = (): UseRoleMappingReturnObject => {
  const [state, setState] = useContext(RoleEditContext);
  const { companyId } = useCompanyContext();

  // GENERIC FUNCTIONS
  // Update object key / value pair in array
  const updatedArrayObjectValue = (
    list: any[],
    matchKey: string,
    matchValue: any,
    updateKey: string,
    updateValue: any
  ) => {
    for (const obj of list) {
      if (obj[`${matchKey}`] === matchValue) {
        obj[`${updateKey}`] = updateValue;
      }
    }
    return list;
  };

  const highlightItem = (item: { label: string; value: any; source: string }) => {
    const i = document.getElementById(`checkbox-list-item-${item.value}`);
    const originalClasses = i?.getAttribute('class');
    i?.setAttribute('class', originalClasses + ' highlight');

    setTimeout(() => {
      i?.setAttribute('class', originalClasses || '');
    }, 500);
  };

  // STATE UPDATES
  const setSkillsFromDescription = (skillsFromDescription: ExtractedSkill[]) => {
    setState((state: RoleEditContextState) => ({ ...state, skillsFromDescription }));
  };

  const setIsSkillsExtracting = (isSkillsExtracting: boolean) => {
    setState((state: RoleEditContextState) => ({ ...state, isSkillsExtracting }));
  };

  //  Broad resetting of state, to prevent too excess useEffects/state defaults
  const updateSkillSearchLists = (
    prevState: RoleEditContextState,
    props: { skillsDescNormal: SuggestedSkill[]; skillsManual: SuggestedSkill[] }
  ): void => {
    if (!prevState.isSkillsExtracting && prevState.isSkillsExtracting !== undefined) {
      const newSkillsList = values(
        merge(keyBy(props.skillsDescNormal, 'skillId'), keyBy(props.skillsManual, 'skillId'))
      );
      setState({
        ...prevState,
        previousState: prevState,
        skillsFromDescriptionNormalized: props.skillsDescNormal,
        skillSearchManualSkills: props.skillsManual,
        skillSearchSkillsList: newSkillsList,
      });
    }
  };

  // Convert extracted skills to common data type
  useEffect(() => {
    if (state.skillsFromDescription !== undefined && !state.isSkillsExtracting) {
      const extractedSkills: SuggestedSkill[] = [];

      state.skillsFromDescription.forEach((skill: ExtractedSkill) => {
        let previousSelection: SuggestedSkill | undefined;

        if (state.previousState?.skillSearchSkillsList?.some(({ skillId }) => skillId !== skill.id)) {
          previousSelection = state.previousState?.skillSearchSkillsList?.find(({ skillId }) => skillId === skill.id);
        }

        extractedSkills.push({
          skillId: skill.id,
          skillName: skill.name,
          isSelected: previousSelection?.isSelected || false,
          isManual: previousSelection?.isManual || false,
          percentPostings: skill.percentPostings,
        });
      });

      updateSkillSearchLists(state, {
        skillsDescNormal: extractedSkills,
        skillsManual: state.skillSearchManualSkills || [],
      });
    }
  }, [state.skillsFromDescription, state.isSkillsExtracting]);

  const addManualSkillSearchSkills = (item: { label: string; value: any; source: string }) => {
    let updatedSkills = state.skillSearchManualSkills || [];

    if (!updatedSkills.some(({ skillId }) => skillId === item.value)) {
      const newSkill = {
        skillId: item.value,
        skillName: item.label,
        isSelected: false,
        isManual: item.source === 'autocomplete',
        percentagePostings: null,
      };

      updatedSkills = updatedSkills.concat([newSkill]);

      updateSkillSearchLists(state, {
        skillsDescNormal: state.skillsFromDescriptionNormalized || [],
        skillsManual: updatedSkills,
      });
    } else {
      highlightItem(item);
    }
  };

  const updateSkillSearchListSelection = (id: string, selectionUpdate: boolean) => {
    let newDescNormalSkills: SuggestedSkill[] = state.skillsFromDescriptionNormalized || [];
    let newManualSkills: SuggestedSkill[] = state.skillSearchManualSkills || [];

    if (state.skillsFromDescriptionNormalized?.some(({ skillId }) => skillId === id)) {
      newDescNormalSkills = updatedArrayObjectValue(
        state.skillsFromDescriptionNormalized,
        'skillId',
        id,
        'isSelected',
        selectionUpdate
      );
    }

    if (state.skillSearchManualSkills?.some(({ skillId }) => skillId === id)) {
      newManualSkills = updatedArrayObjectValue(
        state.skillSearchManualSkills,
        'skillId',
        id,
        'isSelected',
        selectionUpdate
      );
    }
    updateSkillSearchLists(state, { skillsDescNormal: newDescNormalSkills, skillsManual: newManualSkills });
  };

  const setIsSaving = (isSaving: boolean) => {
    setState(prevState => ({ ...prevState, isSaving }));
  };

  const setRole = (role?: Role) => {
    setState((state: RoleEditContextState) => ({ ...state, role }));
  };

  const updateRoleMapping = async (elementsToUpdate): Promise<boolean> => {
    if (!state.role) return false;
    const role = state.role;
    role.data = { ...role.data, ...elementsToUpdate };
    const success = await RolesService.updateRole(companyId, state.role?.roleId, elementsToUpdate);
    setRole(role);
    return success;
  };

  return {
    role: state.role,
    isLoading: state.isLoading,
    isSkillsExtracting: state.isSkillsExtracting,
    skillSearchSkillsList: state.skillSearchSkillsList,
    skillsFromDescription: state.skillsFromDescription,
    setSkillsFromDescription,
    skillsFromDescriptionNormalized: state.skillsFromDescriptionNormalized,
    skillSearchManualSkills: state.skillSearchManualSkills,
    setIsSkillsExtracting,
    updateRoleMapping,
    updateSkillSearchListSelection,
    addManualSkillSearchSkills,
    setIsSaving,
  };
};

export default useRoleMapping;
