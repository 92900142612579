import React, { useEffect, useState, ReactElement, useCallback, useMemo } from 'react';
import Role from 'classes/role';
import ReactGA from 'react-ga';
import clone from 'lodash/clone';
import useCompanyContext from 'helpers/UseCompanyContext';
import RolesService from 'services/RolesService';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import ContentColumn2Template from 'pages/templates/ContentColumn2Template';
import ButtonGroup, { EMSIBGButton } from 'components/atoms/ButtonGroup';
import Divider from '@mui/material/Divider';
import SingleRoleMappingContent from 'components/organisms/SingleRoleMappingContent';
import useRoleMapping from 'helpers/useRoleMapping';
import RoleMappingMappedFields from 'components/molecules/RoleMappingMappedFields';
import useRoleEditContext from 'helpers/useRoleEditContext';
import RoleEditForm from 'components/organisms/RoleEditForm';
import SubNav from 'components/molecules/SubNav';
import styled, { useTheme } from 'styled-components';
import DialogModal from 'components/molecules/DialogModal';
import useBulkRoleEditContext from 'helpers/useBulkRoleEditContext';
import RoleStatusInfo from 'components/molecules/RoleStatusInfo';
import Link from '@mui/material/Link';
import { productModule } from 'helpers/product_modules';
import { useNavigate } from 'react-router-dom';
import useAuthContext from 'helpers/UseAuthContext';
import RoleSimilarity from './RoleSimilarity';

export enum ViewModes {
  RoleDetails = 'roleDetails',
  RoleMapping = 'roleMapping',
  RoleRelated = 'roleRelated',
}

const resizeThreshold = 1100;

const ViewModeDetails = {
  [ViewModes.RoleDetails]: { name: 'details', displayName: 'Role Details' },
  [ViewModes.RoleMapping]: { name: 'mapping', displayName: 'Role Mapping' },
  [ViewModes.RoleRelated]: { name: 'related', displayName: 'Related Roles' },
};

const ContentWrapper = styled.div`
  padding: ${props => props.theme.customSpacing.px.base * 10}px;
  padding-top: ${props => props.theme.customSpacing.px.base * 8}px;

  .MuiDataGrid-row.related-role-table-item-not-selected {
    opacity: 0.24;
  }
`;

type EditRoleContentProps = {
  isUsedInModal?: boolean;
  initialViewMode?: ViewModes;
  backClickHandler?(): void;
  closeModalHandler?: (refresh: boolean) => void;
  refreshRoleHandler?(): void;
};

const EditRoleContent = ({
  isUsedInModal = false,
  initialViewMode = ViewModes.RoleDetails,
  backClickHandler,
  closeModalHandler,
  refreshRoleHandler,
}: EditRoleContentProps): ReactElement => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAnalystReadOnly } = useAuthContext();
  const { companyId, updateRole, hasProductModule } = useCompanyContext();
  const { role, isLoading, isCopying, saveRole, setRole, isSaving, isModified, setIsModified, setRoleChanges } =
    useRoleEditContext();
  const { updateRoleByRoleId, setNextRole } = useBulkRoleEditContext();
  const { updateRoleMapping, setIsSaving } = useRoleMapping();
  const [editableMappingRole, setEditableMappingRole] = useState<Role>();
  const [updateMappingElements, setUpdateMappingElements] = useState<any>({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const needsApproval = !!role?.needsApproval;
  const reviewed = !!role?.reviewed;
  const [isWindowResized, setIsWindowResized] = useState(windowWidth <= resizeThreshold);
  const [roleValues, setRoleValues] = useState<any>();
  const [currentViewMode, setCurrentViewMode] = useState(initialViewMode);
  const [isDialogModalOpen, setIsDialogModalOpen] = useState(false);
  const [modalCallback, setModalCallback] = useState<any>();
  const [resetForm, setResetForm] = useState(false);

  useEffect(() => {
    setRoleChanges({ ...updateMappingElements, ...roleValues });
  }, [roleValues, updateMappingElements]);

  const currentViewName = useMemo(() => {
    return ViewModeDetails[currentViewMode].name;
  }, [currentViewMode]);

  const isActiveView = (view: ViewModes): boolean => {
    return currentViewMode === view;
  };

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    setIsWindowResized(windowWidth <= resizeThreshold);
  }, [windowWidth]);

  useEffect(() => {
    setEditableMappingRole(role);
  }, [role]);

  const componentDisplayType = isUsedInModal ? 'modal' : 'page';

  const updateMappingHandler = useCallback(
    ({ editableRole, updateElements }: { editableRole: Role; updateElements: any }) => {
      if (editableRole && updateElements) {
        setEditableMappingRole(editableRole);
        setUpdateMappingElements(updateElements);
      }
    },
    [editableMappingRole, updateMappingElements]
  );

  const openDialogModal = () => {
    setIsDialogModalOpen(true);
  };
  const closeDialogModal = () => {
    setIsDialogModalOpen(false);
  };

  // recieved a callback function that will be trigered after confirm save or don't save.
  const validateMoveWithChanges = callback => {
    if (isModified) {
      setModalCallback(() => callback);
      openDialogModal();
    } else {
      callback();
    }
  };

  const saveRoleDetails = async () => {
    try {
      await saveRole();
      const roleToSave = new Role({ ...role?.data, ...updateMappingElements, ...roleValues });
      // Update benchmark skills when reviewed is set to true
      if (role?.roleId && updateMappingElements.reviewed !== reviewed && updateMappingElements.reviewed) {
        await RolesService.addBenchmarkSkills(companyId, role.roleId);
      }
      updateRoleByRoleId(roleToSave);
    } catch (e: any) {
      if (e.response.status === 409) {
        alert('Error saving role. Provided job code already exists');
      } else {
        console.error('ERROR SENDING TO LAMBDA TO SAVE ROLE', e);
        alert('Error saving role');
      }
    }
  };

  // TODO: Move to RoleEditContext
  const saveRoleMapping = async () => {
    if (editableMappingRole) {
      try {
        setIsSaving(true);
        // Override mapping reviewed state update if user modified the flags
        const success = await updateRoleMapping?.(updateMappingElements);
        ReactGA.event({
          category: `role ${ViewModeDetails[currentViewMode].name} ${componentDisplayType} - single`,
          action: 'save role change',
          label: success ? 'success' : 'failed',
        });
        if (success) {
          // we make a copy here because updating state would happen in a different cycle
          // the clone is used to update the companycontext cache and to reset the selected value
          // so that the 'modified' indicator isn't triggered and that the selected and editable roles
          // are in sync
          const rc = clone(editableMappingRole);
          // Update benchmark skills when reviewed is set to true
          if (reviewed !== !!editableMappingRole.reviewed && !!editableMappingRole.reviewed) {
            await RolesService.addBenchmarkSkills(companyId, rc.roleId);
          }
          updateRole(rc);
          setRole(rc);
          updateRoleByRoleId(rc);
          refreshRoleHandler?.();
        } else {
          alert('there was a problem saving the role');
        }
      } catch (e) {
        alert('there was a problem saving the role');
        console.error(e);
      } finally {
        setUpdateMappingElements({});
        setIsSaving(false);
      }
    }
  };

  const dontSaveRoleEdits = (): boolean => {
    return isAnalystReadOnly() || !isModified;
  };

  const cancelEditRole = (type: 'cancel' | 'back') => {
    if (dontSaveRoleEdits()) {
      if (isUsedInModal) {
        closeModalHandler?.(true);
      } else {
        backClickHandler?.();
      }
    } else {
      ReactGA.event({
        category: `role ${currentViewName} ${componentDisplayType} - single`,
        action: `confirm lose data - ${type}`,
        label: 'shown',
      });

      const proceed = window.confirm('You have unsaved changes, continue without saving?');

      ReactGA.event({
        category: `role ${currentViewName} ${componentDisplayType} - single`,
        action: `confirm lose data - ${type}`,
        label: proceed ? 'proceed' : 'cancel',
      });

      if (proceed) {
        if (closeModalHandler) {
          closeModalHandler(true);
        } else if (backClickHandler) {
          backClickHandler();
        }
      }
    }
  };

  const resetRoleAndMoveNext = () => {
    setResetForm(true);
    setNextRole();
  };

  const skipButton: EMSIBGButton = {
    text: 'Skip ',
    onClickEvent: () => {
      if (isAnalystReadOnly()) {
        resetRoleAndMoveNext();
      } else {
        validateMoveWithChanges(() => {
          resetRoleAndMoveNext();
        });
      }
    },
    disabled: isLoading,
    dataCy: `role-${currentViewName}-${componentDisplayType}-skip-button`,
    variant: 'contained',
    color: 'actionPrimary',
  };

  const saveRoleChanges = async (nextRole = false) => {
    if (currentViewMode === ViewModes.RoleMapping) {
      await saveRoleMapping();
    } else if (currentViewMode === ViewModes.RoleDetails) {
      await saveRoleDetails();
    }
    if (nextRole) {
      setResetForm(true);
      setNextRole();
    }
  };

  const roleEditPageButtons: EMSIBGButton[] = [
    {
      text: 'Save',
      variant: 'outlined',
      onClickEvent: () => saveRoleChanges(),
      disabled: dontSaveRoleEdits(),
      isLoadingButton: true,
      loadingStatus: isSaving,
      dataCy: `role-${currentViewName}-${componentDisplayType}-save-button`,
    },
    {
      text: 'Save & Next',
      variant: 'outlined',
      onClickEvent: () => saveRoleChanges(true),
      disabled: dontSaveRoleEdits(),
      isLoadingButton: true,
      loadingStatus: isSaving,
      dataCy: `role-${currentViewName}-${componentDisplayType}-save-next-button`,
    },
    skipButton,
  ];

  const roleEditModalButtons: EMSIBGButton[] = [
    {
      text: 'Close',
      onClickEvent: () => cancelEditRole('cancel'),
      disabled: isLoading,
      color: 'actionSecondary',
      dataCy: `role-${currentViewName}-${componentDisplayType}-cancel-button`,
    },
    {
      text: 'Save',
      onClickEvent: saveRoleChanges,
      isLoadingButton: true,
      loadingStatus: isSaving,
      disabled: dontSaveRoleEdits() || isLoading,
      dataCy: `role-${currentViewName}-${componentDisplayType}-save-button`,
    },
  ];

  const buttonGroupHelper = () => {
    if (isUsedInModal) return roleEditModalButtons;
    if (currentViewMode === ViewModes.RoleDetails || currentViewMode === ViewModes.RoleMapping)
      return roleEditPageButtons;
    return [];
  };

  const updateView = (view: ViewModes) => {
    if (currentViewMode !== view) {
      if (isAnalystReadOnly()) {
        resetRoleAndMoveNext();
      }
      validateMoveWithChanges(() => setCurrentViewMode(view));
    }
  };

  const routes = [
    {
      displayName: ViewModeDetails[ViewModes.RoleDetails].displayName,
      path: '',
      clickEvent: () => updateView(ViewModes.RoleDetails),
    },
    {
      displayName: ViewModeDetails[ViewModes.RoleMapping].displayName,
      path: '',
      clickEvent: () => updateView(ViewModes.RoleMapping),
    },
    {
      displayName: ViewModeDetails[ViewModes.RoleRelated].displayName,
      path: '',
      clickEvent: () => updateView(ViewModes.RoleRelated),
    },
  ];
  // TODO: Remove container and dynamic position
  const ButtonsGroupContainer = ({ position }: { position: 'top' | 'bottom' }): ReactElement => {
    const positionName = position;
    return (
      <div data-cy={`action-buttons-${positionName}`}>
        <ButtonGroup
          buttons={buttonGroupHelper()}
          justifyContent={`${positionName}` === 'top' ? 'flex-end' : 'flex-start'}
        />
      </div>
    );
  };

  const roleMappingActionButtons: EMSIBGButton[] = [
    {
      text: 'Edit Role Mapping',
      onClickEvent: () => updateView(ViewModes.RoleMapping),
      color: 'actionSecondary',
      disabled: isLoading,
      dataCy: `role-${currentViewName}-${componentDisplayType}-edit-button`,
    },
  ];

  const mappingStatusChangeHandler = (updatedMappingStatus?: any) => {
    const clone = Object.assign(Object.create(Object.getPrototypeOf(editableMappingRole)), editableMappingRole);
    clone.data = { ...clone.data, ...updatedMappingStatus.data };
    setEditableMappingRole(clone);
    setUpdateMappingElements({ needsApproval: !!clone?.data.needsApproval, reviewed: !!clone?.data.reviewed });
    setIsModified(needsApproval !== !!clone?.data.needsApproval || !!reviewed !== !!clone?.data.reviewed);
  };

  const roleMappingMappedFieldsContent = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: `${theme.customSpacing.px.s}px` }}>
        <RoleMappingMappedFields
          role={role}
          loading={isLoading}
          reviewed={role?.reviewed}
          mappingConfidenceLevel={role?.mappingConfidenceLevel}
        />
        <Divider />
        <RoleStatusInfo
          role={editableMappingRole}
          loading={isLoading}
          recordModification={mappingStatusChangeHandler}
          sourceComponent={'role details'}
        />
        {!isUsedInModal && <ButtonGroup buttons={roleMappingActionButtons} justifyContent="flex-start" />}
      </div>
    );
  };

  const roleDetailsEditContent = () => {
    return (
      <RoleEditForm
        role={role}
        isLoading={isLoading}
        updateValues={setRoleValues}
        isCopyingRole={isCopying}
        isModifiedHandler={setIsModified}
        resetForm={resetForm}
        updateResetForm={() => setResetForm(false)}
      />
    );
  };

  const modalSaveChanges = async () => {
    await saveRoleChanges();
    modalCallback?.();
    closeDialogModal();
  };

  // we can perform here some kind of reset of the form if needed.
  //  right now the component will re-render on each callBack on validateMoveWithChanges
  const modalResetChanges = () => {
    modalCallback?.();
    setResetForm(true);
    setIsModified(false);
    setEditableMappingRole(role);
    setUpdateMappingElements({});
    closeDialogModal();
  };

  const modalBtnGroup: EMSIBGButton[] = [
    {
      text: 'Save',
      variant: 'contained',
      color: 'actionPrimary',
      isLoadingButton: true,
      loadingStatus: isSaving,
      onClickEvent: modalSaveChanges,
      dataCy: 'button-dialog-save',
      disabled: isAnalystReadOnly(),
    },
    {
      text: "Don't Save",
      onClickEvent: modalResetChanges,
      dataCy: 'button-dialog-not-save',
      disabled: isAnalystReadOnly(),
    },
    {
      text: 'Cancel',
      onClickEvent: () => {
        roleValues.reviewed = editableMappingRole?.reviewed;
        roleValues.needsApproval = editableMappingRole?.needsApproval;
        closeDialogModal();
      },
      dataCy: 'button-dialog-cancel',
    },
  ];

  const backButton = () => {
    if (backClickHandler) {
      if (isAnalystReadOnly()) {
        resetRoleAndMoveNext();
      }
      validateMoveWithChanges(backClickHandler);
    }
  };

  const navigateToSkillprofile = () => {
    const searchParams = new URLSearchParams(window.location.search);
    // Pass through the applied role filter to skill profile filter
    searchParams.append('spf', searchParams.get('rf') || '');
    searchParams.delete('rf');
    navigate(`/skill-profiles/${role?.roleId}?${searchParams.toString()}`);
  };

  return (
    <>
      {
        <div data-cy="edit-role-content">
          <ContentHeaderTemplate
            backClickHandler={isUsedInModal ? undefined : backButton}
            contentTitle={role?.roleName || 'Edit Role'}
            loading={isLoading}
            actionsComponent={<ButtonsGroupContainer position="top" />}
            displayDivider={true}
            fullWidthDivider={false}
            isUsedInModal={isUsedInModal}
            subNavComponent={
              <div style={{ display: 'grid', alignItems: 'center' }}>
                <SubNav
                  moduleRoutes={routes}
                  activeRoute={ViewModeDetails[currentViewMode].displayName}
                  showing={true}
                />
                {!isUsedInModal && hasProductModule(productModule.SkillId) && (
                  <Link
                    component={'button'}
                    onClick={navigateToSkillprofile}
                    sx={{
                      position: 'absolute',
                      right: 0,
                      paddingRight: `${theme.customSpacing.px.base * 4}px`,
                      // Handle loading, saving state style
                      pointerEvents: isLoading || isSaving ? 'none' : 'pointer',
                      color:
                        isLoading || isSaving
                          ? `${theme.colors.interactive.defaultDisabled}`
                          : `${theme.colors.interactive.default}`,
                    }}
                    data-cy={'sub-nav-link-skill-profiles'}
                  >
                    View skill profile
                  </Link>
                )}
              </div>
            }
          />
          <ContentWrapper
            style={{ display: isActiveView(ViewModes.RoleMapping) ? 'block' : 'none' }}
            data-cy={'role-mappings'}
          >
            <SingleRoleMappingContent
              role={role || new Role({})}
              loading={isLoading}
              isModifiedHandler={setIsModified}
              updateMappingHandler={updateMappingHandler}
              resetForm={resetForm}
              updateResetForm={() => setResetForm(false)}
            />
          </ContentWrapper>
          <ContentWrapper
            style={{ display: isActiveView(ViewModes.RoleDetails) ? 'block' : 'none' }}
            data-cy={'role-details'}
          >
            <ContentColumn2Template // TODO: Replace with RoleDetailsContent
              mainComponent={roleDetailsEditContent()}
              sideComponent={roleMappingMappedFieldsContent()}
              flexDirection={isWindowResized ? 'column' : 'row'}
              mainComponentPosition={'left'}
              breakpoint={resizeThreshold}
            />
          </ContentWrapper>
          <ContentWrapper
            style={{ display: isActiveView(ViewModes.RoleRelated) ? 'block' : 'none' }}
            data-cy={'related-roles'}
          >
            {role?.roleId && <RoleSimilarity role={role} />}
          </ContentWrapper>
        </div>
      }
      <DialogModal
        dialogOpen={isDialogModalOpen}
        closeModal={() => closeDialogModal()}
        title="Unsaved Changes"
        content={'Are you sure you want to exit without saving your changes?'}
        buttonGroup={modalBtnGroup}
      />
    </>
  );
};

export default EditRoleContent;
