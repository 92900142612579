import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { SkillCount } from 'types/types';
import Rectangle from 'components/atoms/Rectangle';

const SkillCountContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

type TopSkillTypesTableProps = {
  topSkillTypes: SkillCount[];
  color: string;
  sourceComponent: string;
};

const TopSkillTypesTable = ({ topSkillTypes, color, sourceComponent }: TopSkillTypesTableProps): ReactElement => {
  topSkillTypes = topSkillTypes.slice(0, 5);
  const topSkillTypeCount = Math.max(...topSkillTypes.map(topSkillType => topSkillType.count));

  const getPercentage = (skillCount: number) => {
    const result = Math.round((skillCount / topSkillTypeCount) * 100);
    return isNaN(result) ? 0 : result;
  };

  const columns: GridColDef<SkillCount>[] = [
    {
      field: 'skillName',
      headerName: 'Skill Name',
      flex: 6,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'count',
      headerName: '# of Roles',
      flex: 6,
      headerAlign: 'left',
      align: 'left',
      renderCell: param => {
        const percentage = getPercentage(param.value);
        return (
          <SkillCountContainer>
            <div style={{ width: '10%' }}>{param.value.toLocaleString()}</div>
            <div style={{ width: '90%' }}>
              <Rectangle widthPercentage={percentage} heightInPx={8} color={color} />
            </div>
          </SkillCountContainer>
        );
      },
    },
  ];

  return (
    <>
      <DataGrid
        columns={columns}
        rows={topSkillTypes}
        getRowId={(topSkill: SkillCount) => topSkill.skillId}
        autoHeight
        disableSelectionOnClick
        hideFooterPagination
        hideFooter
        className={`${sourceComponent}-top-skill-types-table`}
      />
    </>
  );
};

export default TopSkillTypesTable;
