import React, { useState, useEffect, useContext } from 'react';
import axios from 'helpers/api_helper';
import { AuthenticationContext } from 'contexts/AuthenticationContext';
import Role from 'classes/role';

interface UserMentorshipInfo {
  willingToMentor: boolean;
  skills: any;
}

export interface UserDetails {
  first_name: string;
  last_name: string;
  department: string;
  location: { city: string; state: string };
}

export interface UserContextState {
  company_id?: string;
  userId?: string;
  role?: Role;
  details: UserDetails;
  mentorshipInfo: UserMentorshipInfo;
  loaded: boolean;
  refreshKey: number;
}

interface UserContextProviderProps {
  children?: React.ReactElement;
}

const defaultContext: UserContextState = {
  details: {
    first_name: '',
    last_name: '',
    department: 'Management',
    location: { city: '', state: '' },
  },
  mentorshipInfo: {
    willingToMentor: false,
    skills: {},
  },
  loaded: false,
  refreshKey: Math.random(),
};

const UserContext = React.createContext<[UserContextState, React.Dispatch<any>]>([defaultContext, () => undefined]);

const UserContextProvider: React.FC<UserContextProviderProps> = props => {
  const [authContext] = useContext(AuthenticationContext);

  const [state, setState] = useState(defaultContext);

  useEffect(() => {
    if (authContext.userId && authContext.isLoggedIn) {
      axios
        .get(`${process.env.REACT_APP_API_ROOT}/companies/${authContext.companyId}/user/${authContext.userId}`)
        .then(results => {
          setState({ ...defaultContext, ...results.data.userInfo, userId: authContext.userId, loaded: true });
        });
    }
  }, [authContext.isLoggedIn, authContext.userId, state.refreshKey, authContext.companyId]);

  return <UserContext.Provider value={[state, setState]}>{props.children}</UserContext.Provider>;
};

export { UserContext, UserContextProvider };
