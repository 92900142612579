import React, { useState, useEffect, useRef, ReactElement, ReactNode } from 'react';
import styled, { css, useTheme } from 'styled-components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import Typography from '@mui/material/Typography';
import { SpacingSizes, UILayers } from 'types/types';
import Skeleton from '@mui/material/Skeleton';
import { Popover } from '@mui/material';

type ContentHeaderTemplateProps = {
  contentTitle: string | ReactNode;
  titleSizeVariant?: 'displayLarge' | 'heading';
  loading?: boolean;
  contentSubTitle?: string;
  fullWidthDivider?: boolean;
  backClickHandler?(): void;
  subNavComponent?: ReactNode;
  actionsComponent?: ReactNode;
  titleInformationPopoverComponent?: ReactNode;
  displayDivider?: boolean;
  isUsedInModal?: boolean;
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
`;

const TitleInnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  align-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledBox = styled(Box)<{ $headerShadow: string; $isUsedInModal?: boolean }>`
  position: sticky;
  top: 0;
  z-index: ${UILayers.PageHeader};
  background-color: ${props => props.theme.colors.surface.default};
  box-shadow: ${props => props.$headerShadow};
  border-radius: ${props => props.theme.borderRadius.default} ${props => props.theme.borderRadius.default} 0px 0px;

  ${props =>
    !props.$isUsedInModal &&
    css`
      @media (max-width: 768px) {
        top: ${props => props.theme.customSpacing.px.base * 12}px;
      }
    `};
`;

function ContentHeaderTemplate({
  contentTitle,
  titleSizeVariant = 'displayLarge',
  loading = false,
  contentSubTitle = '',
  fullWidthDivider = true,
  backClickHandler,
  subNavComponent = null,
  actionsComponent = null,
  titleInformationPopoverComponent = null,
  displayDivider = false,
  isUsedInModal = false,
}: ContentHeaderTemplateProps): ReactElement {
  const theme = useTheme();
  const headerShadowOnScroll = `${theme.boxShadow.surface2}`;
  const headerRef = useRef<HTMLInputElement>();
  const headerRefNextSiblingInitialTop =
    headerRef.current && headerRef.current?.nextElementSibling
      ? headerRef.current.nextElementSibling?.getBoundingClientRect().top
      : 148;
  const [headerShadow, setHeaderShadow] = useState('none');
  const [showDivider, SetShowDivider] = useState(false);
  const [titleInformationPopover, setTitleInformationPopover] = useState<HTMLElement | null>(null);

  const handleTitleInformationPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setTitleInformationPopover(event.currentTarget);
  };

  const handleTitleInformationPopoverClose = () => {
    setTitleInformationPopover(null);
  };

  const setScrollingStyling = () => {
    setHeaderShadow(headerShadowOnScroll);
    subNavComponent ? SetShowDivider(displayDivider) : SetShowDivider(false);
  };

  const handleScroll = (topPosition = 0) => {
    if (headerRef.current) {
      if (isUsedInModal || window.innerWidth < 768) {
        if (
          headerRef.current?.nextElementSibling &&
          headerRef.current?.nextElementSibling?.getBoundingClientRect().top < headerRefNextSiblingInitialTop
        ) {
          setScrollingStyling();
          return;
        }
      } else {
        if (headerRef.current.getBoundingClientRect().top <= topPosition) {
          setScrollingStyling();
          return;
        }
      }
      setHeaderShadow('none');
      SetShowDivider(displayDivider);
    }
  };

  const HeaderTitle = (title: string | ReactNode) => {
    return typeof title === 'string' ? (
      <Typography
        className="content-title"
        variant="displayLarge"
        sx={{
          marginRight: `${theme.customSpacing.px.xxs}px`,
          display: 'flex',
          flexShrink: 1,
        }}
        data-cy="content-header-title"
      >
        {title}
      </Typography>
    ) : React.isValidElement(title) ? (
      title
    ) : null;
  };

  useEffect(() => {
    if (headerRef) {
      if (isUsedInModal && headerRef) {
        const parentElement = headerRef.current?.parentElement;
        if (parentElement) {
          parentElement.onscroll = () => handleScroll(parentElement?.getBoundingClientRect().top);
        }
      } else {
        const topPosition = window.innerWidth > 768 ? 48 : 0;
        window.onscroll = () => handleScroll(topPosition);
      }
    }
  }, []);

  useEffect(() => {
    SetShowDivider(displayDivider);
  }, [displayDivider, SetShowDivider]);

  return (
    <StyledBox ref={headerRef} $headerShadow={headerShadow}>
      <Box
        data-cy="content-header"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding:
            titleSizeVariant === 'heading'
              ? `${theme.customSpacing.px.base * 12}px ${theme.customSpacing.px.s}px ${
                  theme.customSpacing.px.base * 7
                }px`
              : `${theme.customSpacing.px.m}px ${theme.customSpacing.px.m}px ${theme.customSpacing.px.base * 9}px`,
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ rowGap: `${theme.customSpacing.px.base * 6}px` }}
        >
          <Grid item sx={{ padding: '0px' }}>
            <TitleWrapper className="title-wrapper">
              {backClickHandler && (
                <>
                  <BaseIcon
                    tabIndex={0}
                    type={IconType.ArrowLeft}
                    size="13px"
                    marginLeft={SpacingSizes.None}
                    marginRight={SpacingSizes.S}
                    onIconClick={backClickHandler}
                    alt="previous button"
                  />
                </>
              )}
              <TitleInnerWrapper className="title-inner-wrapper" style={{ rowGap: `${theme.customSpacing.px.xxs}px` }}>
                {loading ? (
                  <Skeleton width={'100%'}>
                    <Typography variant="displayLarge">{'Content header template title'}</Typography>
                  </Skeleton>
                ) : (
                  HeaderTitle(contentTitle)
                )}
                {titleInformationPopoverComponent && (
                  <div
                    onMouseEnter={handleTitleInformationPopoverOpen}
                    onMouseLeave={handleTitleInformationPopoverClose}
                  >
                    <BaseIcon type={IconType.InformationFill} />
                  </div>
                )}
                {contentSubTitle && (
                  <Typography className="content-sub-title" variant="displayMedium" data-cy="content-header-subtitle">
                    {contentSubTitle}
                  </Typography>
                )}
              </TitleInnerWrapper>
            </TitleWrapper>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'right' }} className="actions-component-wrapper">
              {actionsComponent}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {showDivider && (
        <Divider
          sx={{ borderColor: `${theme.colors.border.subdued}` }}
          variant={fullWidthDivider ? 'fullWidth' : 'middle'}
        />
      )}
      <Box className="subnav-component">{subNavComponent}</Box>
      <Popover
        anchorEl={titleInformationPopover}
        open={!!titleInformationPopover}
        onClose={handleTitleInformationPopoverClose}
        disableScrollLock={true}
        sx={{ pointerEvents: 'none' }}
      >
        {titleInformationPopoverComponent}
      </Popover>
    </StyledBox>
  );
}

export default ContentHeaderTemplate;
