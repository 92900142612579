import React, { ReactElement, useEffect, useState, useMemo } from 'react';
import SimilarityService, { GetSimilarRolesParameters } from 'services/SimilarityService';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import UseCompanyContext from 'helpers/UseCompanyContext';
import SelectIcon from 'components/atoms/icons/Select';
import styled, { useTheme } from 'styled-components';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import Select from '@mui/material/Select';
import Role from 'classes/role';
import RadialLollipop, {
  RadialLollipopItemProps,
  CenterCircleDataProps,
  RadialLollipopDataProps,
} from 'components/charts/RadialLollipop';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import PipIcon, { PipIconType } from 'components/atoms/PipIcon';
import SimilarRoleList from './SimilarRoleList';
import { SpacingSizes } from 'types/types';
import Popover from '@mui/material/Popover';

interface RoleSimilarityProps {
  role: Role;
}

interface SimilarityFilterProp {
  id: GetSimilarRolesParameters['type'];
  name: string;
  itemId: string;
  itemName: string;
  showCode: boolean;
}

const similarityFilters: SimilarityFilterProp[] = [
  {
    id: 'occ',
    name: 'Specialized Occupation',
    itemId: 'specializedOccupationId',
    itemName: 'specializedOccupationName',
    showCode: false,
  },
  {
    id: 'soc',
    name: 'SOC',
    itemId: 'soc',
    itemName: 'socName',
    showCode: true,
  },
];

const PopoverIconContainer = styled.div`
  display: flex;
  margin: 0px 0px 0px 4px;
  div {
    cursor: pointer;
  }
`;

const EllipsizeText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  .MuiDataGrid-columnHeader {
    font-weight: ${props => props.theme.customTypography.desktop.strong.weight};
    font-size: ${props => props.theme.customTypography.desktop.strong.size};
    line-height: ${props => props.theme.customTypography.desktop.strong.lineHeight};
  }

  .MuiDataGrid-cell:first-child,
  .MuiDataGrid-columnHeader:first-child {
    padding-left: ${props => props.theme.customSpacing.px.base * 4}px;
  }

  .MuiDataGrid-cell:last-child,
  .MuiDataGrid-columnHeader:last-child {
    padding-right: ${props => props.theme.customSpacing.px.xxs}px;
  }

  .related-roles-table {
    border-top-style: none;
  }

  .pip-icon-container {
    display: flex;
  }
`;

const RoleSimilarity = ({ role }: RoleSimilarityProps): ReactElement => {
  const roleId = role.roleId;
  const { companyId } = UseCompanyContext();
  const [similarityList, setSimilarityList] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedRelatedRole, setSelectedRelatedRole] = useState<RadialLollipopItemProps | undefined>();
  const theme = useTheme();
  const colorPalette = theme.colors.chart.colorRange10;
  const [showRolesMappedToOcc, setShowRolesMappedToOcc] = useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState(0);
  const getRoleSimilarity = async () => {
    setIsLoading(true);
    const response = await SimilarityService.getRoleSimilarityBy({
      companyId,
      roleId,
      type: similarityFilters[activeFilter].id,
      threshold: 0.5,
      limit: 10,
    });
    setSimilarityList(response);
    setIsLoading(false);
  };
  const [confidenceScoreHeaderAnchor, setConfidenceScoreHeaderAnchor] = useState<HTMLElement | null>(null);

  const handleConfidenceScorePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setConfidenceScoreHeaderAnchor(event.currentTarget);
  };

  const handleConfidenceScorePopoverClose = () => {
    setConfidenceScoreHeaderAnchor(null);
  };

  useEffect(() => {
    (async () => {
      await getRoleSimilarity();
      setSelectedRelatedRole(undefined);
    })();
  }, [roleId, activeFilter]);

  const formattedRelatedRoles = useMemo(() => {
    const formattedItems = similarityList?.similarity.map((item, i) => {
      return {
        id: item[similarityFilters[activeFilter].itemId],
        label: item[similarityFilters[activeFilter].itemName],
        value: item.similarityScore,
        count: item.count,
        isActive: selectedRelatedRole ? item[similarityFilters[activeFilter].itemId] === selectedRelatedRole.id : true,
        tooltipText: `${item[similarityFilters[activeFilter].itemName]}: ${Math.round(item.similarityScore * 1000) / 10}% (${item.count} roles)`,
        color: colorPalette[i % colorPalette.length],
      };
    }) as RadialLollipopItemProps[];

    const graphData = formattedItems
      ? ({
          centerItem: {
            id: similarityList?.role.roleId,
            name: similarityList?.role.roleName,
            tooltip: `${similarityList?.role.roleName} (id: ${similarityList?.role.roleId})`,
          } as CenterCircleDataProps,
          dataItems: formattedItems,
        } as RadialLollipopDataProps)
      : undefined;

    return graphData;
  }, [selectedRelatedRole, similarityList]);

  const selectedRowHandler = (data: RadialLollipopItemProps) => {
    if (selectedRelatedRole && selectedRelatedRole.id === data.id) {
      setSelectedRelatedRole(undefined);
    } else {
      setSelectedRelatedRole(data);
      setShowRolesMappedToOcc(true);
    }
  };

  const columns: GridColDef<RadialLollipopItemProps>[] = [
    {
      field: 'label',
      headerName: similarityFilters[activeFilter].name,
      flex: 6,
      headerAlign: 'left',
      align: 'left',
      renderCell: param => {
        const displayLabel = `${param.row.label} ${similarityFilters[activeFilter].showCode ? param.row.id : ''}`;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PipIcon
              type={PipIconType.full}
              fillColor={param.row.color}
              width="16"
              height="16"
              margins={`0px ${theme.customSpacing.px.base * 5}px 0px 0px`}
            />
            <Typography component="span">{displayLabel}</Typography>
          </div>
        );
      },
    },
    {
      field: 'value',
      renderHeader: () => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', minWidth: '0px' }}>
            <EllipsizeText>{'Relevance'}</EllipsizeText>
            <PopoverIconContainer
              onMouseEnter={handleConfidenceScorePopoverOpen}
              onMouseLeave={handleConfidenceScorePopoverClose}
            >
              <BaseIcon
                type={IconType.InformationFill}
                marginLeft={SpacingSizes.None}
                marginRight={SpacingSizes.Base}
              />
            </PopoverIconContainer>
          </div>
        );
      },
      flex: 3,
      headerAlign: 'right',
      align: 'right',
      renderCell: param => {
        return <div>{`${Math.round(param.value * 1000) / 10}%`}</div>;
      },
    },
    {
      field: 'count',
      headerName: 'Number of Roles',
      flex: 3,
      headerAlign: 'right',
      align: 'right',
    },
  ];

  const IconComponent = props => {
    return (
      <SvgIcon {...props}>
        <SelectIcon />
      </SvgIcon>
    );
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BaseIcon type={IconType.RelatedRoles} />
          <Typography variant="h5">Related Roles</Typography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Select
            value={activeFilter}
            autoWidth={true}
            size="small"
            IconComponent={IconComponent}
            onChange={(e: any) => {
              setActiveFilter(e.target.value);
            }}
            sx={{ maxWidth: '100%' }}
          >
            {similarityFilters.map((item, index) => {
              return (
                <MenuItem key={item.id} value={index}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>
      <Wrapper>
        {!formattedRelatedRoles && <div>Loading...</div>}
        {formattedRelatedRoles && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <RadialLollipop
              chartName="related-roles"
              data={formattedRelatedRoles}
              valueType="percent"
              // TODO: Enable once TAL-1199 is fixed
              // sectionClickHandler={(data: RadialLollipopItemProps) => selectedRowHandler(data)}
              isNodeCircleDynamic={true}
              margin={theme.customSpacing.px.base * 4}
            />
            <DataGrid
              columns={columns}
              loading={isLoading}
              rows={formattedRelatedRoles ? formattedRelatedRoles.dataItems : []}
              getRowId={(item: RadialLollipopItemProps) => item.id}
              getRowClassName={(item: any) => {
                let suffix: string;
                if (selectedRelatedRole) {
                  suffix = item.id === selectedRelatedRole.id ? 'selected' : 'not-selected';
                } else {
                  suffix = item.id;
                }
                return `related-role-table-item-${suffix}`;
              }}
              sx={{ width: '100%' }}
              autoHeight
              onRowClick={(e: any) => selectedRowHandler(e.row)}
              hideFooterPagination
              hideFooter
              className="related-roles-table"
            />
            <Popover
              anchorEl={confidenceScoreHeaderAnchor}
              open={!!confidenceScoreHeaderAnchor}
              onClose={handleConfidenceScorePopoverClose}
              disableScrollLock={true}
              sx={{ pointerEvents: 'none' }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <Typography
                variant={'caption'}
                sx={{ maxWidth: '220px', padding: `${theme.customSpacing.px.xs}px` }}
                component={'div'}
              >
                {
                  'Relevancy percent is powered by a probability-based machine learning model that uses job postings data to calculate the similarity between labor market entities such as skills and occupations.'
                }
              </Typography>
            </Popover>
          </div>
        )}
        <SimilarRoleList
          setShowRolesMappedToOcc={setShowRolesMappedToOcc}
          role={role}
          filterType={similarityFilters[activeFilter].itemId}
          filterId={selectedRelatedRole?.id || ''}
          selectedSimilarRole={selectedRelatedRole}
          showRolesMappedToOcc={showRolesMappedToOcc}
          occPipFillColor={selectedRelatedRole?.color}
        ></SimilarRoleList>
      </Wrapper>
    </>
  );
};

export default RoleSimilarity;
