import { useContext } from 'react';
import Role from 'classes/role';
import { RoleEditContext, RoleEditContextState } from 'contexts/RoleEditContext';
import RolesService from 'services/RolesService';
import useCompanyContext from 'helpers/UseCompanyContext';
import { ExtractedSkill } from 'types/types';
import ReactGA from 'react-ga';

const logAnalytics = (action, event) => {
  ReactGA.event({
    category: 'roles',
    action,
    label: event,
  });
};

// Disabled the warning: "Missing return type on function  @typescript-eslint/explicit-module-boundary-types" so linting passes
// TODO: figure out what the return type should be
// eslint-disable-next-line
const useRoleEditContext = () => {
  const [state, setState] = useContext(RoleEditContext);
  const { companyId } = useCompanyContext();

  const setIsCopying = (isCopying: boolean) => {
    setState((state: RoleEditContextState) => ({ ...state, isCopying }));
  };

  const setRole = (role?: Role) => {
    setState((state: RoleEditContextState) => ({ ...state, role }));
  };

  const setRoleChanges = (roleChanges: Role | undefined) => {
    setState(prevState => ({ ...prevState, roleChanges }));
  };
  const setIsModified = (isModified: boolean) => {
    setState(prevState => ({ ...prevState, isModified }));
  };
  const setIsSaving = (isSaving: boolean) => {
    setState(prevState => ({ ...prevState, isSaving }));
  };

  const setKeywordSearchMatchedTitles = (keywordSearchMatchedTitles: any) => {
    setState((state: RoleEditContextState) => ({ ...state, keywordSearchMatchedTitles }));
  };

  const setSkillsFromDescription = (skillsFromDescription: ExtractedSkill[]) => {
    setState((state: RoleEditContextState) => ({ ...state, skillsFromDescription }));
  };

  const setIsSkillsExtracting = (isSkillsExtracting: boolean) => {
    setState((state: RoleEditContextState) => ({ ...state, isSkillsExtracting }));
  };

  const triggerReload = () => {
    setState((state: RoleEditContextState) => ({ ...state, refreshKey: Math.random() }));
  };

  const saveRole = async (values?: any, reload = false) => {
    setIsSaving(true);
    let actionVerb = state.role?.roleId ? 'update' : 'create';
    let saveStatus: any;
    if (state.role?.roleId && state.isCopying) {
      actionVerb = 'copying';
      saveStatus = await RolesService.copyRole(companyId, state.role.roleId, values);
      logAnalytics(actionVerb, `${actionVerb} role successful`);
    } else if (!state.role?.roleId) {
      saveStatus = await RolesService.createRole({
        ...state.role,
        ...values,
        companyId,
      });
      logAnalytics(actionVerb, `${actionVerb} role successful`);
    } else {
      const role = state.role;
      const changes = values || state.roleChanges;
      role.data = { ...role.data, ...changes };
      saveStatus = await RolesService.updateRole(companyId, state.role?.roleId, { ...changes });
      setRole(role);
      setRoleChanges(undefined);
      setIsModified(false);
      logAnalytics(actionVerb, `${actionVerb} role successful`);
      if (reload) triggerReload();
    }
    setIsSaving(false);
    return saveStatus;
  };

  const mapAndSetKeywordSearchMatchedTitles = (value: any) => {
    const titles = value?.map(title => {
      return {
        id: title.id,
        label: title.name,
        value: title.id,
        message: 'Skills & Keyword search',
      };
    });
    setKeywordSearchMatchedTitles?.(titles);
  };

  const setIsSkillsAndKeywordSearchEnabled = (enabled: boolean) => {
    setState(
      (previousState: RoleEditContextState): RoleEditContextState => ({
        ...previousState,
        isSkillsAndKeywordSearchEnabled: enabled,
      })
    );
  };

  const setKeywordSearchText = (keyword: string) => {
    setState((state: RoleEditContextState): RoleEditContextState => ({ ...state, keywordSearchText: keyword }));
  };

  return {
    role: state.role,
    isLoading: state.isLoading,
    isSaving: state.isSaving,
    isCopying: state.isCopying,
    isModified: state.isModified,
    isSkillsExtracting: state.isSkillsExtracting,
    setIsSkillsExtracting,
    isSkillsAndKeywordSearchEnabled: state.isSkillsAndKeywordSearchEnabled,
    keywordSearchMatchedTitles: state.keywordSearchMatchedTitles,
    keywordSearchText: state.keywordSearchText,
    skillsFromDescription: state.skillsFromDescription,
    skillSearchSkillsList: state.skillSearchSkillsList,
    setSkillsFromDescription,
    saveRole,
    setIsCopying,
    setRole,
    setIsSkillsAndKeywordSearchEnabled,
    mapAndSetKeywordSearchMatchedTitles,
    setKeywordSearchText,
    roleChanges: state.roleChanges,
    setRoleChanges,
    setIsModified,
  };
};

export default useRoleEditContext;
