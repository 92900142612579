import styled from 'styled-components';

const InputRow = styled.div`
  margin-bottom: ${props => props.theme.customSpacing.px.s}px;
  width: 100%;

  @media (max-width: 768px) {
    margin-bottom: ${props => props.theme.customSpacing.px.base * 4}px;
  }
`;

export default InputRow;
