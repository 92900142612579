import React, { ReactElement } from 'react';

type CircleDotProps = {
  size?: number;
  fill?: string;
  stroke?: string;
};

const CircleDot = ({ size = 10, fill = '#0000FF', stroke = '#0000FF' }: CircleDotProps): ReactElement => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle className="circleDot" cx={size / 2} cy={size / 2} r={(size / 2) * 0.875} fill={fill} stroke={stroke} />
    </svg>
  );
};

export default CircleDot;
