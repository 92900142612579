import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

type RoleUploadFileDetailsProps = {
  fileName: string;
  lastModified: string;
  jobCodes: string[];
  performUpdates: boolean;
  cancelHandler: () => void;
};

const FileDetail = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${props => props.theme.customSpacing.px.xxs}px;
  justify-content: flex-start;
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FileDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${props => props.theme.customSpacing.px.base}px;
  padding-left: ${props => props.theme.customSpacing.px.base}px;
`;

const RoleUploadFileDetails = ({
  fileName,
  lastModified,
  cancelHandler,
}: RoleUploadFileDetailsProps): ReactElement => {
  const FileDetailRow = (header: string, text?: string): ReactElement => {
    return (
      <>
        <FileDetail>
          <Typography
            variant="caption"
            color="text.default"
            data-cy={`role-upload-${header.replace(':', '').replace(/\s+/g, '-').toLowerCase()}-header`}
          >
            {header}
          </Typography>
          <Typography
            variant="caption"
            color="text.subdued"
            data-cy={`role-upload-${header.replace(':', '').replace(/\s+/g, '-').toLowerCase()}-value`}
          >
            {text}
          </Typography>
        </FileDetail>
      </>
    );
  };

  return (
    <DetailsContainer>
      <FileDetailsContainer>
        {FileDetailRow('File Name:', `${fileName}`)}
        {FileDetailRow('Last Modified:', lastModified)}
        <Button
          variant="text"
          onClick={() => cancelHandler()}
          size="small"
          data-cy="add-roles-selector-modal-upload-choose-different-file-link-button"
          sx={{ alignSelf: 'flex-start', paddingLeft: '0px' }}
        >
          choose a different file
        </Button>
      </FileDetailsContainer>
    </DetailsContainer>
  );
};

export default RoleUploadFileDetails;
