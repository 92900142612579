import React, { useEffect, useMemo, useState, ReactElement } from 'react';
import ReactGA from 'react-ga';
import { Formik, Form, FieldArray, FormikProps, Field } from 'formik';
import * as Yup from 'yup';
import CompanyService from 'services/CompanyService';
import isEqual from 'lodash/isEqual';
import useAuthContext from 'helpers/UseAuthContext';
import useUserContext from 'helpers/UseUserContext';
import Company from 'classes/company';
import FormikTextField from 'components/atoms/formElements/FormikTextField';
import FormikCompanyAutocomplete from 'components/atoms/formElements/FormikCompanyAutocomplete';
import productModules from 'helpers/product_modules';
import companyModifiers from 'helpers/company_modifiers';
import CheckBox from 'components/atoms/CheckBox';
import useCompanyContext from 'helpers/UseCompanyContext';
import ButtonRow from 'components/atoms/ButtonRow';
import InputRow from 'components/atoms/InputRow';
import FormPartLabel from 'components/atoms/formElements/FormPartLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import styled, { useTheme } from 'styled-components';
import { Typography } from '@mui/material';

interface CompanyProps {
  companyPostingsId: string;
  companyProfilesId: string;
  company_modifiers: string[];
  customerId: string;
  name: string;
  product_modules: string[];
  safeName: string;
  status: string;
  companyId: string;
  identityProviderName?: string;
}

type CompanyFormProps = {
  originalCompany: CompanyProps;
  cancelHandler: () => void;
  triggerRefresh: () => void;
};

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Wrapper = styled.div``;

const CompanyForm = ({ originalCompany, cancelHandler, triggerRefresh }: CompanyFormProps): ReactElement => {
  const theme = useTheme();
  const { userId } = useUserContext();
  const { isEmsiAdmin } = useAuthContext();
  const { setProductModules, setCompanyModifiers, companyId: currentActiveCompanyId } = useCompanyContext();

  const [company, setCompany] = useState(new Company({}));
  const [companyActiveStatus, setCompanyActiveStatus] = useState('');
  const [allowEditCompanyId, setAllowEditCompanyId] = useState(false);
  const [allowEditIdentityProviderName, setAllowEditIdentityProviderName] = useState(false);

  const isEditing = useMemo(() => {
    return company.customerId;
  }, [company]);

  useEffect(() => {
    if (originalCompany) {
      setCompany(new Company(originalCompany));
      setCompanyActiveStatus(originalCompany.status);
    }
  }, [originalCompany]);

  const benchmarkModified = useMemo(() => {
    if (!originalCompany) {
      // role not loaded, so don't compare
      return false;
    }
    return !isEqual(company, originalCompany);
  }, [originalCompany, company]);

  const updateCompany = async newCompanyData => {
    const company = new Company(newCompanyData);
    if (isEditing) {
      await CompanyService.updateCompany({ company, userId });
    } else {
      await CompanyService.createCompany({ company, createdBy: userId });
    }
    ReactGA.event({
      category: 'company',
      action: isEditing ? 'edit' : 'create',
      label: 'save success',
    });

    if (company.companyId === currentActiveCompanyId) {
      setProductModules(company.product_modules || []);
      setCompanyModifiers(company.company_modifiers || []);
      setCompanyActiveStatus(company.status ? company.status : '');
    }

    cancelHandler();
    triggerRefresh();
  };

  const StatusCheckBox = (props: FormikProps<CompanyProps>) => {
    return (
      <InputRow className="input-row">
        <FormPartLabel>Status</FormPartLabel>
        <FormPartLabel style={{ display: 'flex' }}>
          <CheckBox
            name="Status"
            value="Active"
            onChange={() => {
              if (props.values.status === 'active') {
                props.values.status = 'inactive';
                setCompanyActiveStatus('inactive');
                props.values.company_modifiers = props.values.company_modifiers.filter(
                  modifier => modifier !== 'Active'
                );
              } else {
                props.values.status = 'active';
                setCompanyActiveStatus('active');
                props.values.company_modifiers = [
                  ...props.values.company_modifiers.filter(modifier => modifier !== 'Inactive'),
                  'Active',
                ];
              }
            }}
            dataCy="company-form-active-status-checkbox"
            checked={companyActiveStatus === 'active'}
          >
            Active
          </CheckBox>
        </FormPartLabel>
      </InputRow>
    );
  };

  const CheckBoxGroup = (
    title: string,
    type: 'product_modules' | 'company_modifiers',
    items: string[],
    props: FormikProps<CompanyProps>
  ) => {
    return (
      <InputRow className="input-row">
        <FormPartLabel>{title}</FormPartLabel>
        <FieldArray
          name={type}
          render={arrayHelpers => {
            const changeHandler = (e, item) => {
              if (e.target.checked) {
                if (!props.values[type] || !props.values[type].includes(item)) {
                  arrayHelpers.push(e.target.value);
                }
              } else {
                const idx = props.values[type].indexOf(item);
                arrayHelpers.remove(idx);
              }
            };

            return (
              <CheckBoxWrapper>
                {items.map(item => {
                  const id = `${type}-${item}`;
                  return (
                    <FormPartLabel style={{ display: 'flex' }} key={id}>
                      <CheckBox
                        name={type}
                        value={item}
                        checked={props.values[type] && props.values[type].includes(item)}
                        onChange={e => changeHandler(e, item)}
                        // eslint-disable-next-line react/no-children-prop
                        children={item}
                        dataCy=""
                      />
                    </FormPartLabel>
                  );
                })}
              </CheckBoxWrapper>
            );
          }}
        />
      </InputRow>
    );
  };

  return (
    <Wrapper>
      {benchmarkModified && <div>modified</div>}

      <Formik
        initialValues={originalCompany}
        validationSchema={Yup.object({
          name: Yup.string()
            .min(2, 'Name must be at least 2 characters long')
            .max(100, 'Name must be less than 100 characters long')
            .required('You must provide a company name.'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          await updateCompany(values);
          setSubmitting(false);
        }}
      >
        {props => (
          <Form>
            <FormikTextField
              label="Company Name"
              name="name"
              placeholder="enter the customer name"
              required={true}
              dataCy="company-form-customer-name-textbox"
            />
            <FormikTextField
              label="Customer Id (SalesForce ID)"
              name="customerId"
              placeholder="enter the customer Id from salesforce"
              readOnly={!!(isEditing && !allowEditCompanyId)}
              dataCy="company-form-customer-id-textbox"
            />
            {isEmsiAdmin() && (
              <div style={{ textAlign: 'right' }}>
                <Button variant="text" onClick={() => setAllowEditCompanyId(!allowEditCompanyId)}>
                  {allowEditCompanyId ? 'disallow edit' : 'allow edit'}
                </Button>
              </div>
            )}
            {allowEditCompanyId && (
              <Typography color="text.critical">
                WARNING! Modifying the customerId could lead to orphaned data. Modify with extreme caution!
              </Typography>
            )}
            <FormikCompanyAutocomplete label="CompanyId For Postings" name="companyPostingsId" />
            <FormikCompanyAutocomplete label="CompanyId For Profiles" name="companyProfilesId" />

            <FormikTextField
              label="Identity Provider Name"
              name="identityProviderName"
              placeholder="SSO identity provider name"
              readOnly={!!(isEditing && !allowEditIdentityProviderName)}
              dataCy="company-form-identity-provider-name"
            />
            {isEmsiAdmin() && (
              <>
                <div style={{ textAlign: 'right' }}>
                  <Button
                    variant="text"
                    onClick={() => setAllowEditIdentityProviderName(!allowEditIdentityProviderName)}
                  >
                    {allowEditIdentityProviderName ? 'disallow edit' : 'allow edit'}
                  </Button>
                </div>
                {allowEditIdentityProviderName && (
                  <Typography color="text.critical">
                    WARNING! Modifying the Identity Provider Name could lead to issues signing in with SSO. Modify with
                    extreme caution!
                  </Typography>
                )}
              </>
            )}
            <br />

            {CheckBoxGroup('Product Modules', 'product_modules', productModules, props)}
            {CheckBoxGroup('Company Modifiers', 'company_modifiers', companyModifiers, props)}

            <Field type="checkbox" name="status">
              {() => StatusCheckBox(props)}
            </Field>

            <ButtonRow>
              {' '}
              {/* TODO: make this a ButtonGroup when we conver to typescript */}
              <LoadingButton
                type="submit"
                loading={props.isSubmitting}
                disabled={!props.touched || !props.isValid}
                sx={{ marginRight: `${theme.customSpacing.px.xxs}px` }}
              >
                Save
              </LoadingButton>
              <Button onClick={cancelHandler} sx={{ marginRight: `${theme.customSpacing.px.xxs}px` }}>
                Cancel
              </Button>
            </ButtonRow>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default CompanyForm;
