import React, { ReactElement, useState } from 'react';
import { useField } from 'formik';
import styled, { useTheme } from 'styled-components';
import InputTextArea from 'components/molecules/InputTextArea';
import RequiredFieldIndicator from 'components/atoms/RequiredFieldIndicator';
import PropTypes from 'prop-types';
import InputRow from 'components/atoms/InputRow';
import FormFieldErrorMessage from 'components/atoms/formElements/FormFieldErrorMessage';
import FormPartLabel from 'components/atoms/formElements/FormPartLabel';
import Skeleton from '@mui/material/Skeleton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { IconColorFilter, SpacingSizes } from 'types/types';

const PopoverIconContainer = styled.div`
  display: flex;
  div {
    cursor: pointer;
  }
`;

interface FormikTextAreaFieldProps {
  tabIndex?: number;
  id?: string;
  label: string;
  name: string;
  loading: boolean | undefined;
  required?: boolean;
  dataCy?: string;
  placeholder?: string;
  tooltipText?: string;
  tooltipIconColor?: string;
}

const FormikTextAreaField = ({
  tabIndex = 0,
  label,
  id,
  name,
  loading = false,
  required = false,
  dataCy = 'input-text-area',
  placeholder = '',
  tooltipText,
  tooltipIconColor = 'Default',
}: FormikTextAreaFieldProps): ReactElement => {
  const [field, meta] = useField(name);
  const [tooltipIconAnchor, setTooltipIconAnchor] = useState(null);

  const theme = useTheme();

  const handlePopoverOpen = (event: any) => {
    setTooltipIconAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setTooltipIconAnchor(null);
  };

  return (
    <>
      <InputRow className="input-row">
        <FormPartLabel htmlFor={id || name} tabIndex={tabIndex} style={{ display: 'flex', flexDirection: 'row' }}>
          {label}
          {tooltipText && (
            <PopoverIconContainer
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              data-cy={`tooltip-icon-${label}`}
            >
              <BaseIcon
                type={IconType.InformationFill}
                marginLeft={SpacingSizes.Base}
                marginRight={SpacingSizes.None}
                colorFilter={IconColorFilter[`${tooltipIconColor}`]}
              />
            </PopoverIconContainer>
          )}
          {required && <RequiredFieldIndicator />}
        </FormPartLabel>
        {loading ? (
          <Skeleton width={'100%'} height={128} />
        ) : (
          <>
            <InputTextArea {...field} placeholder={placeholder} data-cy={dataCy} />
            {meta.touched && meta.error ? <FormFieldErrorMessage errorMessage={meta.error} /> : null}
          </>
        )}
      </InputRow>
      <Popover
        anchorEl={tooltipIconAnchor}
        open={!!tooltipIconAnchor}
        onClose={handlePopoverClose}
        sx={{ pointerEvents: 'none' }}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography
          variant={'caption'}
          sx={{ maxWidth: '220px', padding: `${theme.customSpacing.px.xs}px` }}
          component={'div'}
          data-cy={`tooltip-message-${label}`}
        >
          {tooltipText}
        </Typography>
      </Popover>
    </>
  );
};

FormikTextAreaField.propTypes = {
  label: PropTypes.string,
  id: PropTypes.any,
  name: PropTypes.any,
};

export default FormikTextAreaField;
