import React from 'react';
import styled from 'styled-components';
import RadioListLineItem, { RadioListLineItemProps } from 'components/atoms/RadioListLineItem';

interface RadioListProps {
  items?: Array<RadioListLineItemProps>;
  clickHandler: (props: Record<string, string>) => void;
  selectedValue: string;
  tabIndex?: number;
  name: string;
  onFocus: () => void;
  dataCy?: string;
  hasPostingDataColumns?: boolean;
  disabled?: boolean;
  [x: string]: any;
}

const StyledWrapper = styled.div`
  margin-top: 0.25rem;
`;

const RadioList: React.FC<RadioListProps> = ({
  items = [],
  clickHandler,
  selectedValue,
  tabIndex,
  name,
  onFocus,
  dataCy = 'radio-list',
  hasPostingDataColumns,
  disabled,
  ...props
}) => {
  return (
    <StyledWrapper tabIndex={tabIndex} data-cy={dataCy}>
      {items.map((i, index) => {
        return (
          <RadioListLineItem
            {...props}
            dataCy={`input-radio-button-${index}`}
            key={`radio-list-item-${i.value}`}
            label={i.label}
            value={i.value}
            ourPostings={i.ourPostings}
            nationwidePostings={i.nationwidePostings}
            message={i.message}
            clickHandler={clickHandler}
            selected={i.value === selectedValue}
            name={name}
            onFocus={onFocus}
            hasPostingDataColumns={hasPostingDataColumns}
            disabled={disabled}
          />
        );
      })}
    </StyledWrapper>
  );
};

export default RadioList;
