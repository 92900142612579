import axios from 'helpers/api_helper';

const sendEmail = async (type?: string): Promise<void> => {
  const url = `${process.env.REACT_APP_API_ROOT}/admin/tools/email`;
  await axios.post(url, { type });
};

const getNotificationSetting = async (
  companyId: string,
  fallback: boolean,
  area: string,
  subArea: string,
  notification: string
): Promise<boolean> => {
  let notificationSetting: boolean = fallback;
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API_MICRO_METADATA_ROOT}/companies/${companyId}/meta/settings`,
      {
        params: {
          a: area,
          sa: subArea,
          n: notification,
        },
      }
    );
    notificationSetting = result.data.settings[0].settingValue;
  } catch (e) {
    console.error(e);
  }
  return notificationSetting;
};

const setNotificationSetting = async (
  companyId: string,
  area: string,
  subArea: string,
  notification: string,
  setting: boolean
): Promise<boolean> => {
  let setSettingSuccess: boolean;
  try {
    const result = await axios.put(
      `${process.env.REACT_APP_API_MICRO_METADATA_ROOT}/companies/${companyId}/meta/settings`,
      {
        area,
        subArea,
        settingName: notification,
        settingValue: setting,
      }
    );
    setSettingSuccess = result.data.success;
  } catch (e) {
    setSettingSuccess = false;
    console.error(e);
  }
  return setSettingSuccess;
};

export default {
  sendEmail,
  getNotificationSetting,
  setNotificationSetting,
};
