import styled from 'styled-components';

const ContainerPage = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 80px);
  padding-left: 40px;
  padding-right: 40px;
  min-height: 100%;
  margin: auto;
  padding: 3rem 0;

  @media (max-width: 768px) {
    display: flex;
    width: calc(100vw - 10px);
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export default ContainerPage;
