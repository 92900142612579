import { bucketizeTime } from 'helpers/analyticsHelper';
import BatchLogs from 'classes/batchLog';
import axios from 'helpers/api_helper';
import ReactGA from 'react-ga';
import { BatchDetails, BatchRowLog } from 'types/types';

type GetBatchLogsProps = {
  companyId: string;
  limit?: number;
  offset?: number;
};

type BatchLogsResponse = {
  batchLogs: BatchLogs[];
  totalCount: number;
};

type GetBatchDetailsProps = {
  companyId: string;
  batchId: string;
};

type GetBatchRowLogsProps = {
  companyId: string;
  batchId: string;
  limit?: number;
  offset?: number;
  filters?: any[];
  searchTerms?: string;
};

type BatchRowLogsResponse = {
  batchRowLogs: BatchRowLog[];
  totalCount: number;
};

type getBatchLogStatsProps = {
  companyId: string;
  batchIds: string[];
};

type BatchLogStatDetail = {
  name: string;
  count: number;
};

type BatchLogStatsItems = {
  requestId: string;
  statuses: BatchLogStatDetail[];
};

type BatchLogStatsResponse = {
  batchLogStats: BatchLogStatsItems[];
};

const getBatchLogs = async (params: GetBatchLogsProps): Promise<BatchLogsResponse> => {
  const startTime = performance.now();
  const { companyId, limit, offset } = params;
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/batchLogs?limit=${limit}&offset=${offset}`;
  const resp = await axios.get<BatchLogsResponse>(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const batchLogs = resp.data?.batchLogs || [];
  const totalCount = resp.data?.totalCount || 0;

  const endTime = performance.now();

  // track num of batchLogs
  ReactGA.event({
    category: 'batchLogs',
    action: 'loading',
    label: `num logs: ${batchLogs.length}`,
  });

  ReactGA.event({
    category: 'batchLogs',
    action: 'loading',
    label: `page size: ${params.limit}`,
  });

  // track load time
  ReactGA.event({
    category: 'batchLogs',
    action: 'loading',
    label: `load time (${params.limit}): ${bucketizeTime(endTime - startTime)}`,
  });

  return { batchLogs, totalCount };
};

const getBatchDetails = async (params: GetBatchDetailsProps): Promise<BatchDetails> => {
  const { companyId, batchId } = params;
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/batchLogs/${batchId}`;
  const resp = await axios.get<BatchDetails>(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return resp.data;
};

const getBatchRowLogs = async (params: GetBatchRowLogsProps): Promise<BatchRowLogsResponse> => {
  const startTime = performance.now();
  const { companyId, batchId, limit, offset, filters = [], searchTerms } = params;
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/batchLogs/${batchId}/items`;
  const resp = await axios.post<BatchRowLogsResponse>(
    url,
    {
      limit,
      offset,
      filters,
      searchTerms,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const batchRowLogs = resp.data?.batchRowLogs || [];
  const totalCount = resp.data?.totalCount || 0;

  const endTime = performance.now();

  // track num of batchLogs
  ReactGA.event({
    category: 'batchRowLogs',
    action: 'loading',
    label: `num logs: ${batchRowLogs.length}`,
  });

  ReactGA.event({
    category: 'batchRowLogs',
    action: 'loading',
    label: `page size: ${params.limit}`,
  });

  // track load time
  ReactGA.event({
    category: 'batchRowLogs',
    action: 'loading',
    label: `load time (${params.limit}): ${bucketizeTime(endTime - startTime)}`,
  });

  return { batchRowLogs, totalCount };
};

const getBatchLogStats = async (params: getBatchLogStatsProps): Promise<BatchLogStatsResponse> => {
  // const startTime = performance.now();
  const { companyId, batchIds } = params;
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/batchLogs/status-count`;
  const resp = await axios.post<BatchLogStatsResponse>(
    url,
    {
      batchIds,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const batchLogStats = resp.data?.batchLogStats || [];

  return { batchLogStats };
};
export default {
  getBatchLogs,
  getBatchDetails,
  getBatchRowLogs,
  getBatchLogStats,
};
