import React, { useState, useContext, useEffect, ReactElement } from 'react';
import Button from '@mui/material/Button';
import styled, { useTheme } from 'styled-components';
import InputBar from 'components/molecules/InputBar';
import { Auth } from '@aws-amplify/auth';
import { Navigate, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { AuthenticationContext } from 'contexts/AuthenticationContext';
import ReactGA from 'react-ga';
import UseAuthContext from 'helpers/UseAuthContext';
import Cookies from 'js-cookie';
import 'components/organisms/Form.css';
import AppLogo from 'components/atoms/appLogo';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { IconColorFilter } from 'types/types';
import ContentCredentialDetails from 'components/atoms/ContainerCredentialDetails';
import FormPartLabel from 'components/atoms/formElements/FormPartLabel';
import ContentPlaceHolder from 'pages/templates/ContentPlaceHolder';
import ContainerPage from 'components/atoms/ContainerPage';
import BodyPageFullScreen from 'components/atoms/BodyPageFullScreen';
import TagManager from 'react-gtm-module';
import { Typography, Alert } from '@mui/material';
import UserService from 'services/UserService';

const Title = styled.div`
  text-align: left;
  margin-top: 0.5rem;
  margin-bottom: ${props => props.theme.customSpacing.px.l}px;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: ${props => props.theme.customSpacing.px.s}px;
  }
  display: flex;
  flex-direction: row;
`;

const InputFieldUsername = styled.div`
  border: 1px solid #aaafb9;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding-left: ${props => props.theme.customSpacing.px.base * 4}px;
`;

const InputFieldCustom = styled.div`
  border: 1px solid #aaafb9;
  border-radius: 4px;
  margin-bottom: ${props => props.theme.customSpacing.px.m}px;
  display: flex;
  flex-direction: row;
  padding-left: ${props => props.theme.customSpacing.px.base * 4}px;
  @media (max-width: 768px) {
    margin-bottom: ${props => props.theme.customSpacing.px.base * 8}px;
  }
`;

const ValidationContainer = styled.div`
  margin-bottom: ${props => props.theme.customSpacing.px.m}px;
  @media (max-width: 768px) {
    margin-bottom: ${props => props.theme.customSpacing.px.base * 8}px;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const EmailWrapper = styled.div`
  margin: ${props => props.theme.customSpacing.px.base * 4}px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const InputBarCustom = styled(InputBar)`
  height: 36px;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  border: none;
  &:focus {
    border: none;
  }
`;

const RememberMeSection = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0;
  justify-content: flex-start;
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: space-between;
`;

function SignIn(): ReactElement {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || 'GTMIDNOTLOADED',
    dataLayer: {
      environment: process.env.REACT_APP_NODE_ENV,
    },
  });
  window.localStorage.removeItem('SSOSubmittedEmail');

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [authContext, setAuthContext] = useContext(AuthenticationContext);
  const { loggedIn, loggingIn, setIsLoggingIn } = UseAuthContext();

  const [email, setEmail] = useState<string>(
    Cookies.get('RememberMeEmail') || decodeURIComponent(searchParams.get('email') || '')
  );
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(Cookies.get('RememberMe') === 'true');
  const [loginProcessed, setLoginProcessed] = useState(false);
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [showPasswordSection, setShowPasswordSection] = useState<boolean>(false);
  const [usernameSubmissionAttempted, setUsernameSubmissionAttempted] = useState<boolean>(false);
  const isValidEmail = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const [showInvalidLoginMessage, setShowInvalidLoginMessage] = useState<boolean>(
    searchParams.get('invalidLogin') === 'true'
  );
  const [closeAlert, setCloseAlert] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState(false);
  let passwordResetRequired = false;

  useEffect(() => {
    setLoginProcessed(loggedIn);
  }, [loggedIn]);

  const getNextLink = (): string => {
    if (location.state?.from) {
      return location.state.from.pathname + location.state.from.search;
    } else {
      return '/insights/home';
    }
  };

  const handlePasswordSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const encodedEmail = encodeURIComponent(email);
    try {
      setIsLoggingIn(true);
      const userSession = await Auth.signIn(email, password);

      // If a user is trying to login with a newly created account with a temp password, send them to the password reset required page.
      if (userSession.challengeName === 'NEW_PASSWORD_REQUIRED') {
        passwordResetRequired = true;

        ReactGA.event({
          category: 'auth',
          action: 'login',
          label: 'redirected to pwd reset required',
        });

        navigate(`/password-reset-required?email=${encodedEmail}`);
      }

      // we set the auth context, but update the refresh token so that we force the user to upload
      setAuthContext({
        refreshToken: new Date().toString(),
      });

      ReactGA.event({
        category: 'auth',
        action: 'login',
        label: 'success',
      });

      if (rememberMe) {
        Cookies.set('RememberMe', true.toString());
        Cookies.set('RememberMeEmail', email);
      } else {
        Cookies.remove('RememberMe');
        Cookies.remove('RememberMeEmail');
      }

      const link = getNextLink();
      navigate(link);
    } catch (e: any) {
      const exceptionCode = e.code;
      ReactGA.event({
        category: 'auth',
        action: 'login',
        label: `failed-${exceptionCode}`,
      });
      if (exceptionCode === 'PasswordResetRequiredException') {
        navigate(`/forgot-password?email=${encodedEmail}&reset_code=resetRequired`);
      } else if (!passwordResetRequired) {
        alert('Login failed: enter a valid email and password');
      }
    }
    setIsLoggingIn(false);
  };

  const handleAuthFlow = async () => {
    const identityProviderName = await UserService.getIdentityProviderName(email);
    if (identityProviderName) {
      window.localStorage.setItem('SSOSubmittedEmail', email);
      await Auth.federatedSignIn({ customProvider: identityProviderName });
    } else {
      // Added this extra boolean as the final condition for when password section is shown so that SSO users won't immediately see
      // the password section upon entering a valid email (whilst we wait for the userExists and companySSO checks and redirect)
      setShowPasswordSection(true);
    }
  };

  const handleUsernameSubmit = async () => {
    setShowInvalidLoginMessage(false);
    setUsernameSubmissionAttempted(true);
    // Validate email
    if (email && email.match(isValidEmail)) {
      setSearchParams(params => {
        params.set('email', encodeURIComponent(email));
        return params;
      });
      setEmailValid(true);
      await handleAuthFlow();
    }
  };

  const handleChangeEmail = () => {
    setEmailValid(false);
    setShowPasswordSection(false);
    setUsernameSubmissionAttempted(false);
  };

  if (loginProcessed) {
    return <Navigate to={getNextLink()} replace />;
  } else {
    return (
      <>
        <ContentPlaceHolder pageTitle="Sign-In">
          <BodyPageFullScreen>
            {showInvalidLoginMessage && !closeAlert && (
              <Alert
                severity="error"
                onClose={() => {
                  setCloseAlert(true);
                }}
              >
                Error logging in — <strong>invalid auth for user</strong>
              </Alert>
            )}
            <ContainerPage>
              <ContentCredentialDetails id="signInPage">
                <div>
                  <Title>
                    <AppLogo />
                  </Title>
                  <form onSubmit={handlePasswordSubmit}>
                    {!emailValid && (
                      <>
                        <LabelWrapper>
                          <FormPartLabel htmlFor="username">Username</FormPartLabel>
                        </LabelWrapper>
                        <InputFieldUsername>
                          <BaseIcon type={IconType.User} size="18px" className="image" />
                          <InputBarCustom
                            tabIndex={1}
                            autoFocus
                            id="username"
                            data-cy="input-username"
                            autoComplete="username"
                            name="username"
                            onChange={e => setEmail(e.target.value)}
                            placeholder="email address"
                            type="email"
                            value={email}
                            onKeyDown={e => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                handleUsernameSubmit();
                              }
                            }}
                          ></InputBarCustom>
                        </InputFieldUsername>
                        <ValidationContainer>
                          {!emailValid && usernameSubmissionAttempted && (
                            <Typography variant="caption" color={'text.critical'}>
                              Please enter a valid email
                            </Typography>
                          )}
                        </ValidationContainer>
                        <ButtonSection>
                          <Button
                            tabIndex={3}
                            variant="contained"
                            color="actionPrimary"
                            type="button"
                            // className="btn-signin"
                            data-cy="submit-username-button"
                            onClick={handleUsernameSubmit}
                          >
                            Submit
                          </Button>
                          <RememberMeSection>
                            <input
                              type="checkbox"
                              id="rememberMe"
                              name="rememberMe"
                              onChange={e => setRememberMe(e.target.checked)}
                              checked={rememberMe}
                              style={{
                                height: '18px',
                                width: '18px',
                                marginRight: `${theme.customSpacing.px.base * 2}px`,
                              }}
                            ></input>
                            <FormPartLabel htmlFor="rememberMe" style={{ whiteSpace: 'nowrap' }}>
                              Remember me
                            </FormPartLabel>
                          </RememberMeSection>
                        </ButtonSection>
                      </>
                    )}
                    {emailValid && showPasswordSection && (
                      <>
                        <EmailWrapper>
                          <Typography color={'text.primary'}>{email}</Typography>
                          <Button variant="text" onClick={handleChangeEmail}>
                            Change
                          </Button>
                        </EmailWrapper>
                        <LabelWrapper>
                          <FormPartLabel htmlFor="password">Password</FormPartLabel>
                          <Button
                            variant="text"
                            data-cy="link-forgot-username-password"
                            onClick={() => navigate(`/forgot-password?email=${encodeURIComponent(email)}`)}
                          >
                            Lost Password?
                          </Button>
                        </LabelWrapper>
                        <div>
                          <div style={{ marginLeft: '100%' }}>
                            <span style={{ position: 'absolute', margin: '12px 5px 0px 5px' }}>
                              {showPassword ? (
                                <BaseIcon
                                  type={IconType.EyeOff}
                                  size="20px"
                                  colorFilter={IconColorFilter.Subdued}
                                  onIconClick={() => setShowPassword(!showPassword)}
                                />
                              ) : (
                                <BaseIcon
                                  type={IconType.Eye}
                                  size="20px"
                                  colorFilter={IconColorFilter.Subdued}
                                  onIconClick={() => setShowPassword(!showPassword)}
                                />
                              )}
                            </span>
                          </div>
                          <InputFieldCustom>
                            <BaseIcon type={IconType.Lock} className="image" />
                            <InputBarCustom
                              tabIndex={2}
                              id="password"
                              data-cy="input-password"
                              autoComplete="current-password"
                              name="password"
                              autoFocus
                              onChange={e => setPassword(e.target.value)}
                              placeholder="password"
                              type={showPassword ? 'text' : 'password'}
                            ></InputBarCustom>
                          </InputFieldCustom>
                        </div>
                        <ButtonSection>
                          <Button
                            tabIndex={3}
                            variant="contained"
                            color="actionPrimary"
                            type="submit"
                            className="btn-signin"
                            data-cy="signin-button"
                            disabled={loggingIn}
                          >
                            Sign In
                          </Button>
                        </ButtonSection>
                      </>
                    )}
                  </form>
                </div>
              </ContentCredentialDetails>
            </ContainerPage>
          </BodyPageFullScreen>
        </ContentPlaceHolder>
      </>
    );
  }
}

export default SignIn;
