import React, { useState, ReactElement, useMemo, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik } from 'formik';
import FormikTextField from 'components/atoms/formElements/FormikTextField';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { IconColorFilter, SpacingSizes, SuggestedSkill } from 'types/types';
import styled, { useTheme } from 'styled-components';
import SkillsService from 'services/SkillsService';
import TitlesService from 'services/TitlesService';
import CheckboxListWithAutoComplete from 'components/organisms/CheckboxListWithAutoComplete';
import { CheckboxListLineItemProps } from 'components/atoms/CheckboxListLineItem';

import useRoleEditContext from 'helpers/useRoleEditContext';
import useRoleMapping from 'helpers/useRoleMapping';
import ReactGA from 'react-ga';

const SearchElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.customSpacing.px.base * 4}px;
`;

const SearchElementsHeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const KeywordSearchContainer = styled.div<{ $isDisabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${props => props.theme.customSpacing.px.xxs}px;
  padding: ${props => props.theme.customSpacing.px.base * 4}px;
  border: 1px solid ${props => props.theme.colors.border.default};
  border-radius: ${props => props.theme.borderRadius.default};
  background-color: ${props => (props.$isDisabled ? '#FBFCFD' : 'unset')};
`;

const KeywordSearchExamplesContainer = styled.div`
  padding: ${props => props.theme.customSpacing.px.none}px ${props => props.theme.customSpacing.px.s}px;
`;

let skillKeywordSearchRequestController = new AbortController();

type RoleSkillKeywordSearchProps = {
  resultsLimit?: number;
  resultsAllowUnclassified?: boolean;
  loading?: boolean;
};

const RoleSkillKeywordSearch = ({
  resultsLimit = 5,
  resultsAllowUnclassified = true,
  loading = false,
}: RoleSkillKeywordSearchProps): ReactElement => {
  const theme = useTheme();
  const [isKeywordSearchLoading, setIsKeywordSearchLoading] = useState(false);
  const [showKeywordSearchExample, setShowKeywordSearchExample] = useState(false);

  const {
    role,
    isSkillsAndKeywordSearchEnabled: isSearchingEnabled,
    setIsSkillsAndKeywordSearchEnabled: setIsSearchingEnabled,
    mapAndSetKeywordSearchMatchedTitles,
    keywordSearchText,
    setKeywordSearchText,
  } = useRoleEditContext();
  const {
    addManualSkillSearchSkills,
    updateSkillSearchListSelection,
    skillSearchSkillsList,
    setIsSkillsExtracting,
    isSkillsExtracting,
  } = useRoleMapping();

  // Ensure description skills are extracted before associated skillsDescription lists are generated
  useEffect(() => {
    setIsSkillsExtracting?.(true);
  }, []);

  const handleSearchingToggle = event => {
    const toggleState = event.target.checked;
    setIsSearchingEnabled(toggleState);
    if (!toggleState) {
      setShowKeywordSearchExample(false);
      mapAndSetKeywordSearchMatchedTitles?.(undefined);
    }
    ReactGA.event({
      category: 'role mapping modal',
      action: 'skill keywords search toggle',
      label: toggleState ? 'on' : 'off',
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleInputTextUpdate = (target, fieldMeta, field, form) => {
    setKeywordSearchText(field.value);
  };

  const handleSkillKeywordSearch = async () => {
    const selectedSkillIds = skillSearchSkillsList
      ?.filter(skill => skill.isSelected === true)
      ?.map(skill => {
        return skill.skillId;
      });
    if (keywordSearchText.length !== 0 || (selectedSkillIds !== undefined && selectedSkillIds?.length > 0)) {
      setIsKeywordSearchLoading(true);
      let titles;
      const limit = resultsAllowUnclassified ? resultsLimit : resultsLimit + 1;
      const searchRequest = {
        skills: selectedSkillIds,
        keywords: keywordSearchText,
      };
      try {
        skillKeywordSearchRequestController?.abort();
        skillKeywordSearchRequestController = new AbortController();
        const titlesByKeyword = await TitlesService.getTitlesBySearch(
          searchRequest,
          limit,
          skillKeywordSearchRequestController
        );
        titles = titlesByKeyword?.data;
        if (!resultsAllowUnclassified) {
          titles = titles.filter(titleItem => titleItem.name !== 'Unclassified');
          titles.length === limit && titles.pop();
        }
      } catch (e: any) {
        console.error(e);
        if (e?.response?.status === 422) alert('Invalid keyword search text');
      } finally {
        mapAndSetKeywordSearchMatchedTitles?.(titles);
        setIsKeywordSearchLoading(false);
      }
    }
  };

  const keywordSearchExample = (keywordSearchExampleItem: string): ReactElement => {
    return (
      <li>
        <Typography variant="caption">{keywordSearchExampleItem}</Typography>
      </li>
    );
  };

  const skillAutoComplete = async (q: string) => {
    const res = await SkillsService.getSkillFromAutocomplete(q);
    return res.data.skills.map(i => ({ value: i, label: i.name }));
  };

  const handleSkillSearchToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateSkillSearchListSelection?.(event.target.value, event.target.checked);
  };

  useEffect(() => {
    handleSkillKeywordSearch();
  }, [skillSearchSkillsList]);

  const suggestedSkills: CheckboxListLineItemProps[] = useMemo(() => {
    const skills: CheckboxListLineItemProps[] = [];

    skillSearchSkillsList?.forEach((skill: SuggestedSkill) => {
      skills.push({
        label: skill.skillName,
        value: skill.skillId,
        name: 'skill-search-selection',
        isChecked: skill.isSelected || false,
        dataCy: `skill-search-${skill.skillId}`,
        dataColumn1: skill.isManual ? 'manually added' : '', // TODO: Option if product wants to display
        dataColumn2: skill.percentPostings || '-', // TODO: Display % postings when data available
        dataColumnsCount: 0,
      });
    });

    return skills;
  }, [skillSearchSkillsList, isSearchingEnabled]);

  const skillAutoCompleteClickHandler = (item: { label: string; value: any; source: string }) => {
    addManualSkillSearchSkills?.(item);
    ReactGA.event({
      category: 'role mapping',
      action: 'skill search - skill added',
      label: `${item.source}: ${item.label} (${item.value})`,
    });
  };

  return (
    <>
      <SearchElementsContainer data-cy={'role-skills-keywords-search-component'}>
        <SearchElementsHeaderSection>
          <Typography variant="displaySmall" component="div">
            {'Skills & Keyword Search'}
          </Typography>
          <Switch
            checked={isSearchingEnabled}
            onChange={event => handleSearchingToggle(event)}
            disabled={loading}
            data-cy={'role-skills-keywords-search-component-switch'}
          />
        </SearchElementsHeaderSection>
        <Typography
          variant="strong"
          component="div"
          color={isSearchingEnabled ? theme.colors.text.default : theme.colors.text.disabled}
        >
          {'Skills'}
        </Typography>
        <CheckboxListWithAutoComplete
          placeholder="Search to add skills"
          items={suggestedSkills}
          loading={loading}
          isAutoCompleteDisabled={!isSearchingEnabled || loading}
          isListDisabled={!isSearchingEnabled || loading}
          autocompleteClickHandler={i => skillAutoCompleteClickHandler(i)}
          autocompleteFunction={skillAutoComplete}
          handleCheckboxItemToggle={e => handleSkillSearchToggle(e)}
          dataCy="skill-profile-skill-search"
          listDataLoadingMessage={
            isSkillsExtracting && role?.description !== null ? 'Loading skills list' : 'Add skills to list'
          }
          listDataColumnsCount={0} // TODO: Update when data is available
          // TODO: Enable column titles when data available
          // listDataColumn1Title=''
          // listDataColumn2Title='% of Postings'
          listMaxHeight="200px"
        />
        <Typography
          variant="strong"
          component="div"
          color={isSearchingEnabled ? theme.colors.text.default : theme.colors.text.disabled}
        >
          {'Keyword Search'}
        </Typography>
        <KeywordSearchContainer $isDisabled={!isSearchingEnabled || loading}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              keywordSearchText,
            }}
            onSubmit={() => undefined}
          >
            <FormikTextField
              name={'keywordSearchText'}
              value={keywordSearchText}
              readOnly={!isSearchingEnabled || loading}
              startElement={
                <BaseIcon type={IconType.Search} marginLeft={SpacingSizes.None} marginRight={SpacingSizes.None} />
              }
              handleInputTextUpdate={handleInputTextUpdate}
              removeBottomMargin={true}
              dataCy={'role-skills-keywords-search-component-keywords-input'}
            />
          </Formik>
          <Button
            variant="text"
            disabled={!isSearchingEnabled || loading}
            onClick={() => setShowKeywordSearchExample(!showKeywordSearchExample)}
            data-cy={'role-skills-keywords-search-component-keywords-examples-button'}
          >
            <BaseIcon
              type={showKeywordSearchExample ? IconType.ChevronUp : IconType.ChevronDown}
              colorFilter={isSearchingEnabled ? IconColorFilter.Interactive : IconColorFilter.Disabled}
              marginLeft={SpacingSizes.None}
              marginRight={SpacingSizes.XXS}
              size={'8px'}
            />
            <Typography variant="caption">{'Example Searches'}</Typography>
          </Button>
          {showKeywordSearchExample && (
            <>
              <KeywordSearchExamplesContainer
                data-cy={'role-skills-keywords-search-component-keywords-examples-section'}
              >
                {keywordSearchExample('Javascript AND Python')}
                {keywordSearchExample('"registered nursing"')}
                {keywordSearchExample('mba OR "masters of business"')}
                {keywordSearchExample('(masters OR bachelors) AND economics')}
              </KeywordSearchExamplesContainer>
              <Button
                variant="text"
                disabled={!isSearchingEnabled || loading}
                href={'https://kb.emsidata.com/how-to/how-to-use-the-jpa-keyword-search/'}
                target={'_blank'}
                data-cy={'role-skills-keywords-search-component-keywords-learn-more-button'}
              >
                <Typography variant="caption">{'Learn more about keyword search'}</Typography>
              </Button>
            </>
          )}
          <LoadingButton
            variant={'outlined'}
            disabled={!isSearchingEnabled || loading}
            onClick={handleSkillKeywordSearch}
            loading={isKeywordSearchLoading}
            loadingIndicator={'Searching...'}
            sx={{ width: '100%' }}
            data-cy={'role-skills-keywords-search-component-keywords-search-button'}
          >
            {'Search'}
          </LoadingButton>
        </KeywordSearchContainer>
      </SearchElementsContainer>
    </>
  );
};

export default RoleSkillKeywordSearch;
