import axios from 'helpers/api_helper';

export type GetSimilarRolesParameters = {
  companyId: string;
  roleId: string;
  type: 'occ' | 'soc';
  threshold?: number;
  limit?: number;
};

type GetSimilarRolesResponse = {
  result: any[];
};

const getRoleSimilarityBy = async ({
  companyId,
  roleId,
  type,
  threshold = 0.0,
  limit = 10,
}: GetSimilarRolesParameters): Promise<any> => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/skills-profile/${roleId}/similarity/${type}`;
  const postBody = { threshold, limit };
  const resp = await axios.post<GetSimilarRolesResponse>(url, postBody);
  const summary = resp.data?.result || [];

  return summary;
};

export default {
  getRoleSimilarityBy,
};
