import React, { ReactElement, useState } from 'react';
import CustomerAutocomplete from 'components/molecules/CustomerAutocomplete';
import styled from 'styled-components';
import UseCompanyContext from 'helpers/UseCompanyContext';
import UseAuthContext from 'helpers/UseAuthContext';
import ArrowHead from 'components/atoms/ArrowHead';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { BorderColor, SpacingSizes } from 'types/types';
import Typography from '@mui/material/Typography';

const WarningBar = styled.div`
  background-color: ${props => props.theme.colors.interactive.critical};
  text-align: center;
  padding: ${props => props.theme.customSpacing.px.xxs}px 0px;
`;

const CompanyDetailsBar = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: ${props => props.theme.colors.surface.information};
  padding: ${props => props.theme.customSpacing.px.base}px 0px;
`;

const CallToAction = styled.div`
  background-color: ${props => props.theme.colors.surface.default};
  border-radius: ${props => props.theme.borderRadius.default} 0 0 ${props => props.theme.borderRadius.default};
  box-shadow: ${props => props.theme.boxShadow.surface2};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: ${props => props.theme.customSpacing.px.none}px;
  overflow: hidden;
  padding: ${props => props.theme.customSpacing.px.base}px ${props => props.theme.customSpacing.px.none}px;
  transition: all 300ms;
  width: 2.5rem;

  &:hover {
    width: 180px;
  }
`;

const CallToActionText = styled.div`
  color: ${props => props.theme.colors.text.subdued};
  font-weight: 600;
  white-space: nowrap;
`;

const CompanyOverrideBar = (): ReactElement => {
  const { companyName, companyId, companyPostingsId, companyProfilesId, setActiveCompanyInfo } = UseCompanyContext();
  const { isCustomerSupport } = UseAuthContext();
  const [showOverride, setShowOverride] = useState(false);

  if (!isCustomerSupport()) {
    return <></>;
  }

  return (
    <div>
      {showOverride && (
        <>
          <WarningBar>
            <Typography variant="strong" color={'text.onCritical'}>
              {`NOTICE! You are operating as Lightcast admin on the company: '${companyName}'`}
            </Typography>
          </WarningBar>
          <CompanyDetailsBar>
            <Typography variant="caption">{`Company Name: ${companyName}`}</Typography>
            <Typography variant="caption">{`Company Id: ${companyId || 'n/a'}`}</Typography>
            <Typography variant="caption">{`Company Postings Id: ${companyPostingsId || 'n/a'}`}</Typography>
            <Typography variant="caption">{`Company Profiles Id: ${companyProfilesId || 'n/a'}`}</Typography>
          </CompanyDetailsBar>
          <CustomerAutocomplete
            showing={showOverride}
            changeHandler={company => {
              setActiveCompanyInfo({
                companyName: company.name,
                companyId: company.companyId,
                companyPostingsId: company.companyPostingsId,
                companyProfilesId: company.companyProfilesId,
              });
            }}
          />
          <div
            style={{ cursor: 'pointer', textAlign: 'center' }}
            onClick={() => {
              setShowOverride(false);
            }}
          >
            <ArrowHead flip={true} /> close <ArrowHead flip={true} />
          </div>
        </>
      )}
      {!showOverride && (
        <div
          style={{
            cursor: 'pointer',
            textAlign: 'center',
            paddingTop: '1rem',
            marginBottom: '-2rem',
          }}
          onClick={() => {
            setShowOverride(true);
          }}
        >
          <CallToAction>
            <BaseIcon
              type={IconType.Add}
              marginLeft={SpacingSizes.XXS}
              marginRight={SpacingSizes.XXS}
              borderColor={BorderColor.Subdued}
            />
            <CallToActionText>set active company</CallToActionText>
          </CallToAction>
        </div>
      )}
    </div>
  );
};

export default CompanyOverrideBar;
