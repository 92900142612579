import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import ContentColumn2Template from 'pages/templates/ContentColumn2Template';
import Typography from '@mui/material/Typography';
import MenuOptionItemExpanded from 'components/molecules/MenuOptionItemExpanded';
import useCompanyContext from 'helpers/UseCompanyContext';
import NotificationsService from 'services/NotificationsService';
import { NotificationType, SubNotificationType } from 'types/types';
import NoDataPlaceholder from 'components/atoms/NoDataPlaceholder';
import ToggleOptionsMenu from 'components/organisms/ToggleOptionsMenu';
const Wrapper = styled.div`
  display: flex;
  padding: ${props => props.theme.customSpacing.px.base * 8}px ${props => props.theme.customSpacing.px.m}px;
  height: 100%;
`;

const Notifications: NotificationType[] = [
  {
    title: 'Progress Notifications',
    key: 'progress-notifications',
    subNotifications: [
      {
        title: 'File Processing Complete',
        key: 'file-processing-complete',
        enabled: true,
        enabledDescription:
          'A notification mail will be sent to the file uploader and to all the company admins when the file processing is completed.',
        disabledDescription:
          'Enabling this will send a mail to the file uploader and to all the company admins when file processing is completed.',
        notificationConfig: {
          area: 'notifications',
          subArea: 'processingComplete',
          notification: 'email',
          fallback: true,
        },
      },
    ],
  },
];

// TODO: Use the Notifications from below once the notification options to display on the page are finalized.
// const Notifications: NotificationType[] = [
//   {
//     title: 'Data Notifications',
//     key: 'data-notifications',
//     subNotifications: [
//       {
//         title: 'Notification Type',
//         key: 'notification-type',
//         enabled: true,
//         enabledDescription: 'Notification Type - {To be filled later}',
//         disabledDescription: 'Notification Type - Enabling this will - {To be filled later}',
//         notificationConfig: { area: 'notifications', subArea: 'notificationType', notification: 'email', fallback: true }
//       },
//       {
//         title: 'Skills Update',
//         key: 'skills-updates',
//         enabled: true,
//         enabledDescription: 'Skills Update - {To be filled later}',
//         disabledDescription: 'Skills Update - Enabling this will - {To be filled later}',
//         notificationConfig: { area: 'notifications', subArea: 'skillsUpdate', notification: 'email', fallback: true }
//       }
//     ]
//   }, {
//     title: 'Progress Notifications',
//     key: 'progress-notifications',
//     subNotifications: [
//       {
//         title: 'File Processing Complete',
//         key: 'file-processing-complete',
//         enabled: true,
//         enabledDescription: 'A notification mail will be sent to the file uploader and to all the company admins when the file processing is completed.',
//         disabledDescription: 'Enabling this will send a mail to the file uploader and to all the company admins when file processing is completed.',
//         notificationConfig: { area: 'notifications', subArea: 'processingComplete', notification: 'email', fallback: true }
//       },
//       {
//         title: 'Role Mapping',
//         key: 'role-mapping',
//         enabled: true,
//         enabledDescription: 'Role Mapping - {To be filled later}',
//         disabledDescription: 'Role Mapping - Enabling this will - {To be filled later}',
//         notificationConfig: { area: 'notifications', subArea: 'roleMapping', notification: 'email', fallback: true }
//       },
//       {
//         title: 'Unreviewed Roles',
//         key: 'unreviewed-roles',
//         enabled: true,
//         enabledDescription: 'Unreviewed Roles - {To be filled later}',
//         disabledDescription: 'Unreviewed Roles - Enabling this will - {To be filled later}',
//         notificationConfig: { area: 'notifications', subArea: 'unreviewedRoles', notification: 'email', fallback: true }
//       }
//     ]
//   }, {
//     title: 'Teammate Notifications',
//     key: 'teammate-notifications',
//     subNotifications: [
//       {
//         title: 'Manual Requests',
//         key: 'manual-requests',
//         enabled: true,
//         enabledDescription: 'Manual Requests - {To be filled later}',
//         disabledDescription: 'Manual Requests - Enabling this will - {To be filled later}',
//         notificationConfig: { area: 'notifications', subArea: 'manualRequests', notification: 'email', fallback: true }
//       },
//       {
//         title: 'Changes',
//         key: 'changes',
//         enabled: true,
//         enabledDescription: 'Changes - {To be filled later}',
//         disabledDescription: 'Changes - Enabling this will - {To be filled later}',
//         notificationConfig: { area: 'notifications', subArea: 'changes', notification: 'email', fallback: true }
//       }
//     ]
//   }
// ];

const updateNotificationSettingToggle = (
  notification: NotificationType[],
  notificationKey: string,
  subNotificationKey: string,
  toggled: boolean
) => {
  const updatedState = notification;
  const notificationObjectIndex = updatedState.findIndex(notification => notification.key === notificationKey);
  const subNotificationObjectIndex = updatedState[notificationObjectIndex].subNotifications.findIndex(
    subNotification => subNotification.key === subNotificationKey
  );
  updatedState[notificationObjectIndex].subNotifications[subNotificationObjectIndex].enabled = toggled;
  return updatedState;
};

const getNotificationConfig = (notificationKey: string, subNotificationKey: string) => {
  const notificationObjectIndex = Notifications.findIndex(notification => notification.key === notificationKey);
  const subNotificationObjectIndex = Notifications[notificationObjectIndex].subNotifications.findIndex(
    subNotification => subNotification.key === subNotificationKey
  );
  return Notifications[notificationObjectIndex].subNotifications[subNotificationObjectIndex].notificationConfig;
};

const NotificationSettingsPage = (): ReactElement => {
  const { companyId } = useCompanyContext();
  const [notifications, setNotifications] = useState(Notifications);
  const [activeNotificationExpanded, setActiveNotificationExpanded] = useState<SubNotificationType | undefined>();

  useEffect(() => {
    setNotifications(prevState => {
      let updatedNotifications = prevState;
      updatedNotifications.forEach(notification => {
        notification.subNotifications.forEach(async subNotification => {
          const notificationConfig = getNotificationConfig(notification.key, subNotification.key);
          const getToggleSetting = await NotificationsService.getNotificationSetting(
            companyId,
            notificationConfig.fallback,
            notificationConfig.area,
            notificationConfig.subArea,
            notificationConfig.notification
          );
          updatedNotifications = [
            ...updateNotificationSettingToggle(notifications, notification.key, subNotification.key, getToggleSetting),
          ];
        });
      });
      return [...updatedNotifications];
    });
  }, []);

  const handleSubNotificationItemClick = (notificationKey: string, subNotificationKey: string) => {
    const notificationObjectIndex = notifications.findIndex(notification => notification.key === notificationKey);
    const subNotificationObjectIndex = notifications[notificationObjectIndex].subNotifications.findIndex(
      subNotification => subNotification.key === subNotificationKey
    );
    setActiveNotificationExpanded(notifications[notificationObjectIndex].subNotifications[subNotificationObjectIndex]);
  };

  const toggleNotificationSetting = async (notificationKey: string, subNotificationKey: string, toggled: boolean) => {
    const notificationConfig = getNotificationConfig(notificationKey, subNotificationKey);
    setNotifications(prevState => [
      ...updateNotificationSettingToggle(prevState, notificationKey, subNotificationKey, toggled),
    ]);
    const toggleSetting = await NotificationsService.setNotificationSetting(
      companyId,
      notificationConfig.area,
      notificationConfig.subArea,
      notificationConfig.notification,
      toggled
    );
    if (!toggleSetting) {
      window.alert('Unable to updating the setting. Try again later.');
      setNotifications(prevState =>
        updateNotificationSettingToggle(prevState, notificationKey, subNotificationKey, !toggled)
      );
    }
  };

  return (
    <MainContentTemplate pageTitle="Notifications" isFullWidth={false}>
      <ContentHeaderTemplate contentTitle="Notifications" displayDivider={true} fullWidthDivider={false} />
      <Wrapper>
        <ContentColumn2Template
          sideComponent={
            <ToggleOptionsMenu
              activeOptionExpanded={activeNotificationExpanded}
              menuOptions={notifications.map(notification => {
                return {
                  title: notification.title,
                  key: notification.key,
                  subOption: notification.subNotifications.map(subNotification => {
                    return {
                      title: subNotification.title,
                      key: subNotification.key,
                      enabled: subNotification.enabled,
                    };
                  }),
                };
              })}
              handleSubOptionClick={handleSubNotificationItemClick}
              handleToggleSetting={toggleNotificationSetting}
              dataCy="notification"
            />
          }
          mainComponent={
            activeNotificationExpanded ? (
              <MenuOptionItemExpanded
                itemHeader={activeNotificationExpanded.title}
                itemDescription={
                  activeNotificationExpanded.enabled ? (
                    <Typography
                      data-cy={`notification-item-${activeNotificationExpanded.title.replace(/\s+/g, '-').toLowerCase()}-description`}
                    >
                      {activeNotificationExpanded.enabledDescription}
                    </Typography>
                  ) : (
                    <Typography
                      data-cy={`notification-item-${activeNotificationExpanded.title.replace(/\s+/g, '-').toLowerCase()}-description`}
                    >
                      {activeNotificationExpanded.disabledDescription}
                    </Typography>
                  )
                }
              />
            ) : (
              <NoDataPlaceholder dataCy={'notification-setting-click-notification-message-container'}>
                <Typography data-cy={'notification-setting-click-notification-message'}>
                  {'Click a notification to see expanded notification options'}
                </Typography>
              </NoDataPlaceholder>
            )
          }
          mainComponentPosition="right"
        />
      </Wrapper>
    </MainContentTemplate>
  );
};

export default NotificationSettingsPage;
