import React, { ReactElement, ReactNode } from 'react';
import SelectIcon from 'components/atoms/icons/Select';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styled from 'styled-components';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s linear 0ms,
    opacity 0ms;

  .MuiDataGrid-row:hover & {
    visibility: visible;
    opacity: 1;
    transition:
      visibility 0s linear 0s,
      opacity 0ms;
  }
`;

const IconComponent = props => {
  return (
    <SvgIcon {...props}>
      <SelectIcon />
    </SvgIcon>
  );
};

type TableSelectProps = {
  field: { id: string; value: string };
  options: { id: string; value: string; restricted?: boolean }[];
  label?: string;
  updateHandler: (e: SelectChangeEvent<unknown>) => void;
  isUpdating: boolean;
  iconsComponent?: ReactNode;
  autoWidth?: boolean;
  selectMaxWidth?: string;
};

const StyledSelect = styled(Select)(() => ({
  width: '100%',

  '& .MuiOutlinedInput-input': {
    padding: '8px 8px 8px 12px',
  },
}));

const TableSelect = ({
  field,
  options,
  label,
  updateHandler,
  isUpdating,
  iconsComponent,
  autoWidth = true,
  selectMaxWidth = '100%',
}: TableSelectProps): ReactElement => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      {isUpdating ? (
        <p style={{ textAlign: 'center' }}>updating...</p>
      ) : (
        <StyledSelect
          label={label}
          value={field.value}
          autoWidth={autoWidth}
          onChange={updateHandler}
          IconComponent={IconComponent}
          sx={{ maxWidth: selectMaxWidth }}
        >
          {options.map(item => {
            if (item?.restricted) return null;
            return (
              <MenuItem key={item.id} value={item.id}>
                {item.value}
              </MenuItem>
            );
          })}
        </StyledSelect>
      )}
      {iconsComponent && <IconContainer>{iconsComponent}</IconContainer>}
    </div>
  );
};

export default TableSelect;
