import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Role from 'classes/role';
import RoleMetadata from 'components/molecules/RoleMetadata';
import RoleStatusInfo from 'components/molecules/RoleStatusInfo';
import RoleMappingMappedFields from 'components/molecules/RoleMappingMappedFields';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.customSpacing.px.s}px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .MuiChip-root {
    margin: 0;
  }
`;

type RoleMatchDetailColumnProps = {
  role: Role;
  recordModification: (newData: Role) => void;
  loading?: boolean;
};

const RoleMatchDetailColumn = ({
  role,
  recordModification,
  loading = false,
}: RoleMatchDetailColumnProps): ReactElement => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <Typography variant="displayMedium">{'Role Mapping'}</Typography>
      </HeaderWrapper>
      <RoleMappingMappedFields
        role={role}
        loading={loading}
        reviewed={role?.reviewed}
        mappingConfidenceLevel={role?.mappingConfidenceLevel}
        showHeader={false}
      />
      <Divider />
      <RoleStatusInfo
        role={role}
        loading={loading}
        recordModification={recordModification}
        sourceComponent={'role mapping'}
      />
      <Divider />
      <RoleMetadata role={role} loading={loading} />
      <Divider />
    </Wrapper>
  );
};

export default RoleMatchDetailColumn;
