import React, { useState, useEffect, ReactElement, ReactNode } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import styled, { useTheme } from 'styled-components';
import DialogModal from 'components/molecules/DialogModal';
import CollapsibleAutocompleteRadioList from 'components/organisms/CollapsibleAutocompleteRadioList';
import { PostingsCount } from 'types/types';
import Typography from '@mui/material/Typography';
import RolesService from 'services/RolesService';
import UseCompanyContext from 'helpers/UseCompanyContext';
import RadioListItemComponentForMappings from 'components/atoms/RadioListItemComponentForMappings';
import {
  sortMappings,
  Title,
  Occupation,
  SpecializedOccupation,
  RoleLightcastTitle,
  RoleOccupation,
  RoleSpecializedOccupation,
  RoleUpdateMappingsModalProps,
  mapAndSortMappingValues,
  autocompleteItems,
  getMappingItemsPostingCounts,
  getTitleOccLabelAndValue,
} from 'helpers/RoleUpdateMappingsModalHelper';

const Wrapper = styled.div<{ $wrapVertical: boolean }>`
  display: flex;
  flex-direction: ${props => (props.$wrapVertical ? 'column' : 'row')};
  gap: ${props => props.theme.customSpacing.px.xxs}px;
  color: ${props => props.theme.colors.text.default};
  min-height: 400px;
`;

const MappingSelectionWrapper = styled.div<{ $wrapVertical: boolean; $hide: boolean }>`
  display: ${props => (props.$hide ? 'none' : 'block')};
  width: ${props => (props.$wrapVertical ? '100%' : '33%')};
`;

const RoleUpdateMappingsModal = ({
  isOpen,
  closeHandler,
  roles,
  sourceComponent,
}: RoleUpdateMappingsModalProps): ReactElement => {
  const theme = useTheme();
  const { companyId, companyPostingsId } = UseCompanyContext();

  const [titleItems, setTitleItems] = useState<Title[]>([]);
  const [loadingTitleMessage, setLoadingTitleMessage] = useState('');
  const [selectedTitle, setSelelectedTitle] = useState<RoleLightcastTitle>();

  const [occupationItems, setOccupationItems] = useState<Occupation[]>([]);
  const [loadingOccupationMessage, setLoadingOccupationMessage] = useState('');
  const [selectedOccupation, setSelectedOccupation] = useState<RoleOccupation>();

  const [specializedOccupationItems, setSpecializedOccupationItems] = useState<SpecializedOccupation[]>([]);
  const [loadingSpecializedOccupationMessage, setLoadingSpecializedOccupationMessage] = useState('');
  const [selectedSpecializedOccupation, setSelectedSpecializedOccupation] = useState<RoleSpecializedOccupation>();

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isAllMappingSelected, setIsAllMappingSelected] = useState(false);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);
  const [showConfimationModal, setShowConfirmationModal] = useState(false);
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [minimizeModal, setMinimizeModal] = useState(false);

  const setPostingsCount = (
    mappingItems: Title[] | Occupation[] | SpecializedOccupation[],
    mappingItemKey: 'value' | 'label',
    ourPostings: PostingsCount[],
    nationwidePostings: PostingsCount[]
  ) => {
    return mappingItems.map(mappingItem => {
      return {
        ...mappingItem,
        ourPostings: ourPostings.find(postings => postings.name === mappingItem[`${mappingItemKey}`])?.uniquePostings,
        nationwidePostings: nationwidePostings.find(postings => postings.name === mappingItem[`${mappingItemKey}`])
          ?.uniquePostings,
      };
    });
  };

  const getAllPostingCountsForAllMappings = async (
    titles: Title[],
    occupations: Occupation[],
    specializedOccupations: SpecializedOccupation[]
  ) => {
    const promises: Promise<any>[] = [];
    if (titles.length > 0) {
      setLoadingTitleMessage('loading posting counts...');
      promises.push(
        getMappingItemsPostingCounts({
          facet: 'title',
          facetFilters: Array.from(titles, item => item.value),
          companyPostingsId,
        }).then(({ ourPostingsCount, nationwidePostingsCount }) => {
          titles = setPostingsCount(titles, 'value', ourPostingsCount, nationwidePostingsCount);
          setLoadingTitleMessage('');
          setTitleItems(sortMappings(titles, 'label'));
        })
      );
    }
    if (occupations.length > 0) {
      setLoadingOccupationMessage('loading posting counts...');
      promises.push(
        getMappingItemsPostingCounts({
          facet: 'soc5_name',
          facetFilters: Array.from(occupations, item => item.label),
          companyPostingsId,
        }).then(({ ourPostingsCount, nationwidePostingsCount }) => {
          occupations = setPostingsCount(occupations, 'label', ourPostingsCount, nationwidePostingsCount);
          setLoadingOccupationMessage('');
          setOccupationItems(occupations);
        })
      );
    }
    if (specializedOccupations.length > 0) {
      setLoadingSpecializedOccupationMessage('loading posting counts...');
      promises.push(
        getMappingItemsPostingCounts({
          facet: 'lot_specialized_occupation_name',
          facetFilters: Array.from(specializedOccupations, item => item.label),
          companyPostingsId,
        }).then(({ ourPostingsCount, nationwidePostingsCount }) => {
          specializedOccupations = setPostingsCount(
            specializedOccupations,
            'label',
            ourPostingsCount,
            nationwidePostingsCount
          );
          setLoadingSpecializedOccupationMessage('');
          setSpecializedOccupationItems(specializedOccupations);
        })
      );
    }
    await Promise.all(promises);
  };

  useEffect(() => {
    if (!isOpen) return;
    const { titles, occupations, specializedOccupations } = mapAndSortMappingValues(roles);
    setTitleItems(sortMappings(titles, 'label'));
    setOccupationItems(sortMappings(occupations, 'id'));
    setSpecializedOccupationItems(sortMappings(specializedOccupations, 'label'));

    getAllPostingCountsForAllMappings(titles, occupations, specializedOccupations);
  }, [isOpen]);

  useEffect(() => {
    setIsFormDirty(!!(selectedTitle || selectedOccupation || selectedSpecializedOccupation));
    setIsAllMappingSelected(!!(selectedTitle && selectedOccupation && selectedSpecializedOccupation));
  }, [selectedTitle, selectedOccupation, selectedSpecializedOccupation]);

  useEffect(() => {
    setMinimizeModal(isAllMappingSelected || isUpdateInProgress);
  }, [isAllMappingSelected, isUpdateInProgress]);

  const resetSelectedMappings = () => {
    setSelelectedTitle(undefined);
    setSelectedOccupation(undefined);
    setSelectedSpecializedOccupation(undefined);
  };

  const handleRoleUpdate = async (
    roleId: string,
    updateData: Partial<RoleLightcastTitle & RoleOccupation & RoleSpecializedOccupation>
  ) => {
    const success = await RolesService.updateRole(companyId, roleId, updateData);
    return success;
  };

  const handleApply = async () => {
    setShowConfirmationModal(false);
    const promises: Promise<boolean>[] = [];

    roles.forEach(role => {
      promises.push(
        handleRoleUpdate(role.roleId, { ...selectedTitle, ...selectedOccupation, ...selectedSpecializedOccupation })
      );
    });

    try {
      setIsUpdateInProgress(true);
      await Promise.all(promises);
      resetSelectedMappings();
      closeHandler(true);
    } catch (e) {
      console.error(e);
      alert('Update mappings failed. Try again later.');
    } finally {
      setIsUpdateInProgress(false);
    }
  };

  const handleCancel = () => {
    setShowCancellationModal(false);
    resetSelectedMappings();
    closeHandler(false);
  };

  const handleTitleSelect = async (title: any) => {
    const { label, value } = getTitleOccLabelAndValue(title, 'titles');

    setSelelectedTitle({ emsiTitleId: value, emsiTitleName: label });
    if (titleItems.findIndex(t => t.value === value) === -1) {
      setLoadingTitleMessage('loading title...');
      const { ourPostingsCount, nationwidePostingsCount } = await getMappingItemsPostingCounts({
        facet: 'title',
        facetFilters: [value],
        companyPostingsId,
      });
      titleItems.unshift({
        id: value,
        label,
        value,
        message: 'Manually Added',
        ourPostings: ourPostingsCount.find((postings: PostingsCount) => postings.name === value)?.uniquePostings,
        nationwidePostings: nationwidePostingsCount.find((postings: PostingsCount) => postings.name === value)
          ?.uniquePostings,
      });
      setLoadingTitleMessage('');
      setTitleItems([...titleItems]);
    }
  };

  const handleOccupationSelect = async (occupation: any) => {
    const { label, value } = getTitleOccLabelAndValue(occupation, 'socs');

    setSelectedOccupation({ soc: value, socName: label });
    if (occupationItems.findIndex(o => o.label === label) === -1) {
      setLoadingOccupationMessage('loading occupation...');
      const occupationItem: Occupation = {
        id: value,
        label,
        value,
        message: 'Manually Added',
      };
      const { ourPostingsCount, nationwidePostingsCount } = await getMappingItemsPostingCounts({
        facet: 'soc5_name',
        facetFilters: [label],
        companyPostingsId,
      });
      occupationItem.ourPostings = ourPostingsCount.find(
        (postings: PostingsCount) => postings.name === label
      )?.uniquePostings;
      occupationItem.nationwidePostings = nationwidePostingsCount.find(
        (postings: PostingsCount) => postings.name === label
      )?.uniquePostings;
      occupationItems.unshift(occupationItem);
      setOccupationItems([...occupationItems]);
      setLoadingOccupationMessage('');
    }
  };

  const handleSpecializedOccupationSelect = async (specializedOccupation: any) => {
    const { label, value } = getTitleOccLabelAndValue(specializedOccupation, 'specialized_occupations');

    setSelectedSpecializedOccupation({ specializedOccupationId: value, specializedOccupationName: label });
    if (specializedOccupationItems.findIndex(so => so.label === label) === -1) {
      setLoadingSpecializedOccupationMessage('loading specialized occupation...');
      const specializedOccupationItem: Occupation = {
        id: value,
        label,
        value,
        message: 'Manually Added',
      };
      const { ourPostingsCount, nationwidePostingsCount } = await getMappingItemsPostingCounts({
        facet: 'lot_specialized_occupation_name',
        facetFilters: [label],
        companyPostingsId,
      });
      specializedOccupationItem.ourPostings = ourPostingsCount.find(
        (postings: PostingsCount) => postings.name === label
      )?.uniquePostings;
      specializedOccupationItem.nationwidePostings = nationwidePostingsCount.find(
        (postings: PostingsCount) => postings.name === label
      )?.uniquePostings;
      specializedOccupationItems.unshift(specializedOccupationItem);
      setSpecializedOccupationItems([...specializedOccupationItems]);
      setLoadingSpecializedOccupationMessage('');
    }
  };

  const postingsHeaderComponent = (dataCy: string): ReactNode => {
    return (
      <>
        <div style={{ display: 'flex', width: '50%', float: 'right' }}>
          <Typography
            variant="caption"
            component={'div'}
            sx={{ width: '50%', textAlign: 'right' }}
            color={theme.colors.text.subdued}
            data-cy={`role-update-mappings-modal-${dataCy}-textbox-our-postings-header`}
          >
            Our Postings
          </Typography>
          <Typography
            variant="caption"
            component={'div'}
            sx={{ width: '50%', textAlign: 'right' }}
            color={theme.colors.text.subdued}
            data-cy={`role-update-mappings-modal-${dataCy}-textbox-nationwide-postings-header`}
          >
            Nationwide
          </Typography>
        </div>
      </>
    );
  };

  const selectedMappingItem = (mappingHeader: string, mappingValue: string): ReactNode => {
    return (
      <div style={{ display: 'flex', gap: `${theme.customSpacing.px.base}px` }}>
        <Typography color={theme.colors.text.default}>{mappingHeader}</Typography>
        <Typography color={theme.colors.text.subdued}>{mappingValue}</Typography>
      </div>
    );
  };

  return (
    <DialogModal
      dialogOpen={isOpen}
      title={'Update Mappings'}
      titleSizeVariant={'displayLarge'}
      fullWidth={!minimizeModal}
      maxWidth={minimizeModal ? 'lg' : 'xl'}
      content={
        <>
          {isUpdateInProgress && <LinearProgress />}
          <Wrapper $wrapVertical={minimizeModal}>
            <MappingSelectionWrapper $wrapVertical={minimizeModal} $hide={!selectedTitle && isUpdateInProgress}>
              <CollapsibleAutocompleteRadioList
                title="Job Title"
                placeholder="Search for a Job Title"
                required={false}
                collapse={!!selectedTitle}
                collapseHandler={() => setSelelectedTitle(undefined)}
                collapseActionLabel={isUpdateInProgress ? '' : 'Revert'}
                autocompleteFunction={q => autocompleteItems('titles', q)}
                autocompleteOnChange={handleTitleSelect}
                radioListItemsHeaderComponent={titleItems.length > 0 && postingsHeaderComponent('job-title')}
                radioListItemsComponent={RadioListItemComponentForMappings(titleItems)}
                radioListItemsLoadingMessage={loadingTitleMessage}
                radioItemSelected={selectedTitle?.emsiTitleName}
                radioListItems={titleItems.map(item => {
                  return { key: item.id, value: item.label };
                })}
                radioListItemOnClick={handleTitleSelect}
                dataCy={'role-update-mappings-modal-job-title-textbox'}
              />
            </MappingSelectionWrapper>

            <MappingSelectionWrapper $wrapVertical={minimizeModal} $hide={!selectedOccupation && isUpdateInProgress}>
              <CollapsibleAutocompleteRadioList
                title="Government Code (SOC)"
                placeholder="Search for a Job Occupation"
                required={false}
                collapse={!!selectedOccupation}
                collapseHandler={() => setSelectedOccupation(undefined)}
                collapseActionLabel={isUpdateInProgress ? '' : 'Revert'}
                autocompleteFunction={q => autocompleteItems('socs', q, true)}
                autocompleteOnChange={handleOccupationSelect}
                radioListItemsHeaderComponent={occupationItems.length > 0 && postingsHeaderComponent('soc-name')}
                radioListItemsComponent={RadioListItemComponentForMappings(occupationItems, true)}
                radioListItemsLoadingMessage={loadingOccupationMessage}
                radioItemSelected={selectedOccupation?.socName}
                radioItemSelectedLabel={`${selectedOccupation?.socName} (${selectedOccupation?.soc})`}
                radioListItems={occupationItems.map(item => {
                  return { key: item.id, value: item.label };
                })}
                radioListItemOnClick={handleOccupationSelect}
                dataCy={'role-update-mappings-modal-soc-name-textbox'}
              />
            </MappingSelectionWrapper>

            <MappingSelectionWrapper
              $wrapVertical={minimizeModal}
              $hide={!selectedSpecializedOccupation && isUpdateInProgress}
            >
              <CollapsibleAutocompleteRadioList
                title="Specialized Occupation"
                placeholder="Search for a Specialized Occupation"
                required={false}
                collapse={!!selectedSpecializedOccupation}
                collapseHandler={() => setSelectedSpecializedOccupation(undefined)}
                collapseActionLabel={isUpdateInProgress ? '' : 'Revert'}
                autocompleteFunction={q => autocompleteItems('specialized_occupations', q)}
                autocompleteOnChange={handleSpecializedOccupationSelect}
                radioListItemsHeaderComponent={
                  specializedOccupationItems.length > 0 && postingsHeaderComponent('specialized-occupation')
                }
                radioListItemsComponent={RadioListItemComponentForMappings(specializedOccupationItems)}
                radioListItemsLoadingMessage={loadingSpecializedOccupationMessage}
                radioItemSelected={selectedSpecializedOccupation?.specializedOccupationName}
                radioListItems={specializedOccupationItems.map(item => {
                  return { key: item.id, value: item.label };
                })}
                radioListItemOnClick={handleSpecializedOccupationSelect}
                dataCy={'role-update-mappings-modal-specialized-occupation-textbox'}
              />
            </MappingSelectionWrapper>
          </Wrapper>
          <DialogModal
            dialogOpen={showConfimationModal}
            title={'Confirm Update'}
            content={
              <>
                {`Are you sure to update mappings for ${roles.length} selected role${roles.length > 1 ? 's' : ''}?`}
                <div style={{ padding: `${theme.customSpacing.px.base}px ${theme.customSpacing.px.none}px` }}>
                  {selectedTitle && selectedMappingItem('Title:', selectedTitle.emsiTitleName)}
                  {selectedOccupation &&
                    selectedMappingItem('Occupation:', `${selectedOccupation.socName} (${selectedOccupation.soc})`)}
                  {selectedSpecializedOccupation &&
                    selectedMappingItem(
                      'Specialized Occupation:',
                      selectedSpecializedOccupation.specializedOccupationName
                    )}
                </div>
              </>
            }
            buttonGroup={[
              {
                text: 'Update',
                color: 'actionPrimary',
                variant: 'contained',
                onClickEvent: handleApply,
                dataCy: `${sourceComponent}-role-update-mappings-modal-apply-confirm-button`,
              },
              {
                text: 'Cancel',
                variant: 'outlined',
                onClickEvent: () => setShowConfirmationModal(false),
                dataCy: `${sourceComponent}-role-update-mappings-modal-apply-cancel-button`,
              },
            ]}
          />
          <DialogModal
            dialogOpen={showCancellationModal}
            title={'Unsaved Changes'}
            content={
              <>
                Are you sure to cancel updating mappings?
                <br />
                WARNING: Your selected changes will be lost.
              </>
            }
            buttonGroup={[
              {
                text: 'Continue',
                color: 'actionPrimary',
                variant: 'contained',
                onClickEvent: handleCancel,
                dataCy: `${sourceComponent}-role-update-mappings-modal-cancel-confirm-button`,
              },
              {
                text: 'Close',
                variant: 'outlined',
                onClickEvent: () => setShowCancellationModal(false),
                dataCy: `${sourceComponent}-role-update-mappings-modal-cancel-close-button`,
              },
            ]}
          />
        </>
      }
      closeModal={() => (isFormDirty ? setShowCancellationModal(true) : handleCancel())}
      buttonGroup={[
        {
          text: `Apply${roles.length > 1 ? ' to all' : ''}`,
          color: 'actionPrimary',
          variant: 'contained',
          disabled: !isFormDirty,
          isLoadingButton: true,
          loadingStatus: isUpdateInProgress,
          loadingMessage: 'Applying',
          onClickEvent: () => setShowConfirmationModal(true),
          dataCy: `${sourceComponent}-role-update-mappings-modal-apply-button`,
        },
        {
          text: 'Revert',
          variant: 'outlined',
          disabled: !isFormDirty || isUpdateInProgress,
          onClickEvent: resetSelectedMappings,
          dataCy: `${sourceComponent}-role-update-mappings-modal-revert-button`,
        },
        {
          text: 'Cancel',
          variant: 'outlined',
          disabled: isUpdateInProgress,
          onClickEvent: () => (isFormDirty ? setShowCancellationModal(true) : handleCancel()),
          dataCy: `${sourceComponent}-role-update-mappings-modal-cancel-button`,
        },
      ]}
      btnGroupPosition={'top'}
      showCloseIcon={false}
      dataCy={`${sourceComponent}-role-update-mappings-modal`}
    />
  );
};

export default RoleUpdateMappingsModal;
