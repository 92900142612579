import { useEffect, useState } from 'react';
import CustomSkillsService from 'services/customSkillsService';
import { CustomSkill } from 'types/types';
import useCompanyContext from 'helpers/UseCompanyContext';

type useCustomSkillsHelperReturnObject = {
  customSkills: CustomSkill[];
  addCustomSkill: (customSkill: Omit<CustomSkill, 'customSkillId'>) => Promise<void>;
  editCustomSkill: (customSkillId: string, customSkill: Partial<CustomSkill>) => Promise<void>;
  deleteCustomSkill: (customSkillId: string) => Promise<void>;
  deleteCustomSkills: (customSkillIds: string[]) => Promise<void>;
  loadingCustomSkills: boolean;
  totalCustomSkills: number;
};

const useCustomSkillsHelper = (): useCustomSkillsHelperReturnObject => {
  const { companyId } = useCompanyContext();
  const [customSkills, setCustomSkills] = useState<CustomSkill[]>([]);
  const [loadingCustomSkills, setLoadingCustomSkills] = useState(false);
  const [totalCustomSkills, setTotalCustomSkills] = useState(0);

  const addCustomSkill = async (customSkill: Omit<CustomSkill, 'customSkillId'>): Promise<void> => {
    const newCustomSkill = await CustomSkillsService.createCustomSkill(companyId, customSkill);

    const newCustomSkills = [...customSkills, newCustomSkill].sort((a, b) => a.skillName.localeCompare(b.skillName));

    setCustomSkills(newCustomSkills);
  };

  const editCustomSkill = async (customSkillId: string, customSkill: Partial<CustomSkill>): Promise<void> => {
    const { skillName, skillSource } = customSkill;
    const updatedCustomSkill = await CustomSkillsService.updateCustomSkill(companyId, customSkillId, {
      skillName,
      skillSource,
    });
    const newCustomSkills = customSkills.map(cs =>
      cs.customSkillId === updatedCustomSkill.customSkillId ? updatedCustomSkill : cs
    );
    setCustomSkills(newCustomSkills);
  };

  const deleteCustomSkill = async (customSkillId: string): Promise<void> => {
    await CustomSkillsService.deleteCustomSkill(companyId, customSkillId);
    const newCustomSkills = customSkills.filter(cs => cs.customSkillId !== customSkillId);
    setCustomSkills(newCustomSkills);
  };

  const deleteCustomSkills = async (customSkillIds: string[]): Promise<void> => {
    const promises = customSkillIds.map(customSkillId =>
      CustomSkillsService.deleteCustomSkill(companyId, customSkillId)
    );
    const results = await Promise.allSettled(promises);
    const successfulCustomSkillIds = results.reduce((acc, r, i) => {
      if (r.status === 'fulfilled') acc.push(customSkillIds[i]);
      return acc;
    }, [] as string[]);
    const newCustomSkills = customSkills.filter(cs => !successfulCustomSkillIds.includes(cs.customSkillId));
    setCustomSkills(newCustomSkills);
    if (successfulCustomSkillIds.length < customSkillIds.length) {
      throw new Error('There was an error deleting one or more custom skills.');
    }
  };

  const loadCustomSkills = async () => {
    setLoadingCustomSkills(true);
    const cs = await CustomSkillsService.getAllCustomSkills(companyId);
    setCustomSkills(cs);
    setTotalCustomSkills(cs.length);
    setLoadingCustomSkills(false);
  };

  useEffect(() => {
    loadCustomSkills();
  }, [companyId]);

  return {
    customSkills,
    addCustomSkill,
    editCustomSkill,
    deleteCustomSkill,
    deleteCustomSkills,
    loadingCustomSkills,
    totalCustomSkills,
  };
};

export default useCustomSkillsHelper;
