import type {} from '@mui/lab/themeAugmentation';
import { DefaultTheme } from 'styled-components';

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    iconSuccess: true;
    iconCritical: true;

    primary: false;
    secondary: false;
    error: false;
    info: false;
    success: false;
    warning: false;
  }
}

export const muiCheckboxOverride = (gs: DefaultTheme): Record<'defaultProps' | 'styleOverrides', any> => ({
  defaultProps: {
    color: 'iconSuccess',
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      height: '20px',
      width: '19px',
      padding: '0px',
      margin: '0px 9px',
      borderRadius: `${gs.borderRadius.small}`,
      '&:not(.Mui-checked)': {
        color: `${gs.colors.border.default}`,
      },
      '&:focus-visible': {
        boxShadow: `${gs.boxShadow.buttonFocus}`,
      },
      '&:focus-within': {
        boxShadow: `${gs.boxShadow.buttonFocus}`,
      },
      '&:hover:not(.Mui-checked)': {
        color: `${gs.colors.border.hovered}`,
      },
      '&.Mui-disabled': {
        '&:not(.Mui-checked)': {
          color: `${gs.colors.border.disabled}`,
          backgroundColor: `${gs.colors.surface.disabled}`,
        },
        '&.Mui-checked': {
          color: `${gs.colors.surface.disabled}`,
          backgroundColor: `${gs.colors.icon.disabled.hex}`,
        },
      },
    },
    checked: {},
    disabled: {},
    indeterminate: {},
    colorPrimary: {
      color: 'iconSuccess',
    },
    colorSecondary: {},
  },
});
