import React, { ReactElement, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import styled, { useTheme } from 'styled-components';
import useSkillProfileContext from 'helpers/useSkillProfileContext';
import SkillsExtractor from 'components/organisms/SkillsExtractor';
import { ExtractedSkill } from 'types/types';

const SkillExtractorWrapper = styled.div`
  margin-bottom: ${props => props.theme.customSpacing.px.base * 13}px;
  padding: ${props => props.theme.customSpacing.px.base * 4}px 0px;
`;

const LinkText = styled(Link)`
  padding: 0px ${props => props.theme.customSpacing.px.xxs}px;
`;

const SkillProfileEditDescriptionContent = (): ReactElement => {
  const theme = useTheme();
  const {
    loadingSkillProfile,
    isSkillsExtracting,
    role,
    setIsSkillsExtracting,
    setSkillsFromDescription,
    setDescriptionOverride,
  } = useSkillProfileContext();
  const [extractorInitialText, setExtractorInitialText] = useState(role?.details.description || '');
  const [currentJobDescription, setCurrentJobDescription] = useState(role?.details.description || '');
  const [isDescriptionOverride, setIsDescriptionOverride] = useState(false);

  useEffect(() => setIsSkillsExtracting(true), []);

  const extractedSkillsHandler = (skills: ExtractedSkill[]) => {
    setSkillsFromDescription(skills);
  };

  useEffect(() => {
    if (!loadingSkillProfile && !isSkillsExtracting) {
      setDescriptionOverride({
        isOverride: isDescriptionOverride,
        description: currentJobDescription,
      });
    }
  }, [isDescriptionOverride, currentJobDescription]);

  const resetDescription = () => {
    const originalText = role?.details.description || '';
    setExtractorInitialText(currentJobDescription); // Required to trigger useEffect in SkillsExtractor
    setTimeout(() => {
      setExtractorInitialText(originalText);
      setCurrentJobDescription(originalText);
    }, 200);
  };

  return (
    <>
      <Grid container justifyContent="space-between" direction="row" sx={{ display: 'flex', flexWrap: 'nowrap' }}>
        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            component="div"
            variant="strong"
            sx={{ height: '100%', alignItems: 'center', display: 'inline-flex' }}
          >
            {`1. ${role?.details?.description ? '' : 'Paste '}Job Description`}
          </Typography>
          {!loadingSkillProfile && currentJobDescription !== role?.details?.description && (
            <LinkText onClick={() => resetDescription()} data-cy="job-description-revert-button">
              Revert
            </LinkText>
          )}
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={isDescriptionOverride}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsDescriptionOverride(e.target.checked)}
                disabled={loadingSkillProfile}
                data-cy={'job-description-override-switch'}
              />
            }
            sx={{ textAlign: 'right' }}
            label="Override description when I save"
            labelPlacement="start"
          />
        </Grid>
      </Grid>
      <SkillsExtractor
        Wrapper={SkillExtractorWrapper}
        content={extractorInitialText}
        onContentChange={text => {
          setCurrentJobDescription(text);
        }}
        dataCy="skills-extractor-job-description"
        extractedSkillsHandler={extractedSkillsHandler}
        isExtractingHandler={(e: boolean) => setIsSkillsExtracting(e)}
        placeholder="Paste your job description here to analyze for skills."
      />
      <div style={{ marginTop: `${theme.customSpacing.px.base * 6}px` }}>
        <Typography variant="strong" data-cy="job-description-add-skills-title">
          {'2. Add skills to skill profile'}
        </Typography>
      </div>
    </>
  );
};

export default SkillProfileEditDescriptionContent;
