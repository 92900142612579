import React, { ReactElement, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Column, Section, SectionSticky } from 'pages/admin/uiLibrary/UILibraryComponents';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

const ColumnCustom = styled(Column)`
  align-items: left;
  display: block;
`;

const MUILinkDemo = (): ReactElement<any> => {
  const theme = useTheme();
  const defaultLinkHref = '/admin/ui-library';

  const [customLinkText, setCustomLinkText] = useState('Custom Link');
  const [customLinkColor, setCustomLinkColor] = useState('interactive.default');
  const [customLinkSize, setCustomLinkSize] = useState<any>('body');
  const [customLinkType, setCustomLinkType] = useState('link');
  const [customLinkHref, setCustomLinkHref] = useState(`${defaultLinkHref}#muiLink`);

  const linkClickHandler = (name: string): void => {
    alert(`'${name}' link has been clicked`);
  };

  const styleBrandOptions = { backgroundColor: `${theme.colors.surface.selected}` };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <SectionSticky
          defaultComponent={
            <>
              <Typography variant="subHeading">Default</Typography>
              <Link href="/admin/ui-library#muiLink">Link</Link>
              <br />
              <Typography variant="subHeading">Customizable</Typography>
              {customLinkType === 'link' && (
                <>
                  <Link color={customLinkColor} variant={customLinkSize} href={customLinkHref}>
                    {customLinkText}
                  </Link>
                  <Typography variant="caption">(default link)</Typography>
                </>
              )}
              {customLinkType === 'button' && (
                <>
                  <Link
                    color={customLinkColor}
                    variant={customLinkSize}
                    onClick={() => linkClickHandler('Custom Link Button')}
                  >
                    {customLinkText}
                  </Link>
                  <Typography variant="caption">(link as button)</Typography>
                </>
              )}
            </>
          }
          componentOptions={
            <>
              <TextField
                value={customLinkText}
                label="Custom Link Text"
                variant="outlined"
                onChange={e => {
                  setCustomLinkText(e.target.value);
                }}
              />
              <br />
              <FormControl fullWidth>
                <InputLabel id="color-select-label">Custom Link Color</InputLabel>
                <Select
                  labelId="color-select-label"
                  id="color-select"
                  value={customLinkColor}
                  label="Color"
                  onChange={e => {
                    setCustomLinkColor(e.target.value);
                  }}
                >
                  <MenuItem key="color-option-default" value="interactive.default" sx={styleBrandOptions}>
                    <Typography variant="strong" color="interactive.default">
                      Default *&nbsp;
                    </Typography>
                    ( color=interactive.default )
                  </MenuItem>
                  <MenuItem key="color-option-critical" value="interactive.critical" sx={styleBrandOptions}>
                    <Typography variant="strong" color="interactive.critical">
                      Critical *&nbsp;
                    </Typography>
                    ( color=interactive.critical )
                  </MenuItem>
                  <MenuItem key="color-option-disabled" value="text.disabled" sx={styleBrandOptions} divider={true}>
                    <Typography variant="strong" color="text.disabled">
                      Disabled *&nbsp;
                    </Typography>
                    ( color=text.disabled )
                  </MenuItem>
                  <MenuItem key="color-option-information" value="text.information">
                    <Typography variant="strong" color="text.information">
                      Information&nbsp;
                    </Typography>
                    ( color=text.information )
                  </MenuItem>
                  <MenuItem key="color-option-success" value="text.success">
                    <Typography variant="strong" color="text.success">
                      Success&nbsp;
                    </Typography>
                    ( color=text.success )
                  </MenuItem>
                  <MenuItem key="color-option-warning" value="text.warning">
                    <Typography variant="strong" color="text.warning">
                      Warning&nbsp;
                    </Typography>
                    ( color=text.warning )
                  </MenuItem>
                </Select>
                <Typography variant="caption" paragraph={true} sx={{ maxWidth: '300px' }}>
                  * These options are branded Link colors. Any Typography colors will be accepted.
                </Typography>
              </FormControl>
              <br />
              <FormControl fullWidth>
                <InputLabel id="size-select-label">Custom Link Size</InputLabel>
                <Select
                  labelId="size-select-label"
                  id="size-select"
                  value={customLinkSize}
                  label="Color"
                  onChange={e => {
                    setCustomLinkSize(e.target.value);
                  }}
                >
                  <MenuItem key="size-option-default" value="body" sx={styleBrandOptions}>
                    <Typography variant="strong">Body * &nbsp;</Typography>( default )
                  </MenuItem>
                  <MenuItem key="size-option-caption" value="caption" sx={styleBrandOptions} divider={true}>
                    <Typography variant="strong">Caption * &nbsp;</Typography>( variant=caption )
                  </MenuItem>
                  <MenuItem key="size-option-displayXLarge" value="displayXLarge">
                    <Typography variant="strong">DisplayXLarge &nbsp;</Typography>( variant=displayXLarge )
                  </MenuItem>
                  <MenuItem key="size-option-displayLarge" value="displayLarge">
                    <Typography variant="strong">DisplayLarge &nbsp;</Typography>( variant=displayLarge )
                  </MenuItem>
                  <MenuItem key="size-option-displayMedium" value="displayMedium">
                    <Typography variant="strong">DisplayMedium &nbsp;</Typography>( variant=displayMedium )
                  </MenuItem>
                  <MenuItem key="size-option-displaySmall" value="displaySmall">
                    <Typography variant="strong">DisplaySmall &nbsp;</Typography>( variant=displaySmall )
                  </MenuItem>
                  <MenuItem key="size-option-heading" value="heading">
                    <Typography variant="strong">Heading &nbsp;</Typography>( variant=heading )
                  </MenuItem>
                  <MenuItem key="size-option-subHeading" value="subHeading">
                    <Typography variant="strong">SubHeading &nbsp;</Typography>( variant=subHeading )
                  </MenuItem>
                  <MenuItem key="size-option-strong" value="strong">
                    <Typography variant="strong">Strong &nbsp;</Typography>( variant=strong )
                  </MenuItem>
                </Select>
                <Typography variant="caption" paragraph={true} sx={{ maxWidth: '300px' }}>
                  * These options are branded Link sizes. Any Typography variants will be accepted.
                </Typography>
              </FormControl>
              <br />
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <ToggleButtonGroup
                  color="primary"
                  orientation="vertical"
                  value={customLinkType}
                  exclusive
                  onChange={(e, type) => setCustomLinkType(type)}
                  sx={{ marginRight: `${theme.customSpacing.px.base}px` }}
                >
                  <ToggleButton disableRipple value="link">
                    Link
                  </ToggleButton>
                  <ToggleButton disableRipple value="button" title="include props: component='button'">
                    Button
                  </ToggleButton>
                </ToggleButtonGroup>
                {customLinkType === 'link' && (
                  <div style={{ display: 'block', width: '100%' }}>
                    <TextField
                      fullWidth
                      value={customLinkHref}
                      label="Custom Link href"
                      variant="outlined"
                      onChange={e => {
                        setCustomLinkHref(e.target.value);
                      }}
                    />
                    <Typography variant="caption" paragraph={true}>
                      Removing href will disable link.
                    </Typography>
                  </div>
                )}
                {customLinkType === 'button' && (
                  <div style={{ display: 'block', width: '100%' }}>
                    <TextField
                      fullWidth
                      value={"() => alert('Link Button clicked')"}
                      label="Custom Link onClick Event"
                      variant="outlined"
                      disabled
                    />
                    <Typography variant="caption" paragraph={true}>
                      Removing onClick event will disable link.
                    </Typography>
                  </div>
                )}
              </div>
            </>
          }
        ></SectionSticky>
        <Section>
          <Typography variant="strong">Brand Sizes</Typography>
          <ColumnCustom>
            <Link href={defaultLinkHref}>Body (default)</Link>
            <br />
            <Link variant="caption" href={defaultLinkHref}>
              Caption
            </Link>
          </ColumnCustom>
        </Section>
        <Section>
          <Typography variant="strong">Brand Colors</Typography>
          <ColumnCustom>
            <Link href={defaultLinkHref}>Interactive (default)</Link>
            <br />
            <Link>Interactive Disabled</Link>
            <br />
            <Link href={defaultLinkHref} color="interactive.critical">
              Critical
            </Link>
            <br />
            <Link color="interactive.critical">Critical Disabled</Link>
          </ColumnCustom>
        </Section>
        <Section>
          <Typography variant="strong">Type</Typography>
          <ColumnCustom style={{ maxWidth: '225px' }}>
            <Link href={defaultLinkHref}>Link</Link> (default)
            <br />
            <Link component="button" onClick={() => linkClickHandler('Custom Link Button')}>
              Button
            </Link>{' '}
            (component=button)
            <br />
            <br />
            <Typography variant="caption">
              Does not impact display, only the behavior using href vs onClick event
            </Typography>
          </ColumnCustom>
        </Section>
        <Section>
          <Typography variant="strong">Underline Display</Typography>
          <ColumnCustom>
            <Link href={defaultLinkHref}>Default</Link>
            <br />
            <Link href={defaultLinkHref} underline="none">
              Underline: none
            </Link>
            <br />
            <Link href={defaultLinkHref} underline="hover">
              Underline: hover
            </Link>
            <br />
            <Link href={defaultLinkHref} underline="always">
              Underline: always
            </Link>
          </ColumnCustom>
        </Section>
      </div>
    </>
  );
};

export default MUILinkDemo;
