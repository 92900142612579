import { DefaultTheme } from 'styled-components';
export type ProcessingState = 'created' | 'updated' | 'skipped' | 'processing' | 'error' | 'deleted';

export function areaColor(area: ProcessingState, theme: DefaultTheme): string {
  let color = theme.colors.progressBar.default;
  switch (area) {
    case 'created':
      color = theme.colors.progressBar.segment1;
      break;
    case 'updated':
      color = theme.colors.progressBar.segment2;
      break;
    case 'skipped':
      color = theme.colors.progressBar.segment3;
      break;
    case 'error':
      color = theme.colors.progressBar.segment4;
      break;
    case 'processing':
      color = theme.colors.progressBar.segment5;
      break;
    case 'deleted':
      color = theme.colors.progressBar.segment6;
      break;
  }
  return color;
}

export function areaTitle(area: ProcessingState, count: number, progressPercent: number): string {
  let message = '';
  const displayPercentage = Math.round(progressPercent);
  switch (area) {
    case 'created':
      message = `${count} rows (${displayPercentage}%) have been processed and saved.`;
      break;
    case 'updated':
      message = `${count} rows (${displayPercentage}%) have been updated.`;
      break;
    case 'skipped':
      message = `${count} rows (${displayPercentage}%) have been skipped because they already exist or have no changes.`;
      break;
    case 'error':
      message = `${count} rows (${displayPercentage}%) have failed to process. Check the row logs for more details.`;
      break;
    case 'processing':
      message = `${count} rows (${displayPercentage}%) are currently processing.`;
      break;
    case 'deleted':
      message = `${count} rows (${displayPercentage}%) have been deleted.`;
      break;
  }
  return message;
}
