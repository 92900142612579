import { useContext } from 'react';
import { ColorModeContext, ColorModeContextState } from 'contexts/ColorModeContext';

interface useColorModeContentProps extends ColorModeContextState {
  toggleColorMode: () => void;
}

const useColorModeContext = (): useColorModeContentProps => {
  const [state, setState] = useContext(ColorModeContext);

  const toggleColorMode = () => {
    const newColorMode = state.colorMode === 'light' ? 'dark' : 'light';
    setState({ ...state, colorMode: newColorMode });
  };

  return {
    toggleColorMode,
    colorMode: state.colorMode,
  };
};

export default useColorModeContext;
