import React, { ReactElement, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Button from '@mui/material/Button';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { TopSkill } from 'types/types';

const FooterContainer = styled.div`
  padding-left: ${props => props.theme.customSpacing.px.m}px;
`;

type TopSkillsTableProps = {
  topSkills: TopSkill[];
  sourceComponent: string;
};

const TopSkillsTable = ({ topSkills, sourceComponent }: TopSkillsTableProps): ReactElement => {
  const theme = useTheme();
  const [isTopSkillsExpanded, setIsTopSkillsExpanded] = useState(false);
  const columns: GridColDef<TopSkill>[] = [
    {
      field: 'topSkillOrder',
      headerName: '',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'skillName',
      headerName: 'Top Overall Skills',
      flex: 4,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'categoryName',
      headerName: 'Category',
      flex: 4,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'count',
      headerName: '# of Role Appearances',
      flex: 3,
      headerAlign: 'right',
      align: 'right',
      renderCell: param => {
        return param.value.toLocaleString();
      },
    },
  ];

  return (
    <>
      <DataGrid
        columns={columns}
        rows={topSkills.slice(0, isTopSkillsExpanded ? 10 : 5)}
        getRowId={(topSkill: TopSkill) => topSkill.skillId}
        components={{
          Footer: () => (
            <FooterContainer>
              <Button
                onClick={() => setIsTopSkillsExpanded(!isTopSkillsExpanded)}
                sx={{ margin: `${theme.customSpacing.px.xxs}px 0px` }}
                data-cy={`${sourceComponent}-top-skills-table-expand-button`}
              >
                {isTopSkillsExpanded ? 'See Less' : 'See More'}
              </Button>
            </FooterContainer>
          ),
        }}
        autoHeight
        disableSelectionOnClick
        className={`${sourceComponent}-top-skills-table`}
      />
    </>
  );
};

export default TopSkillsTable;
