import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import UseAuthContext from 'helpers/UseAuthContext';
import Typography from '@mui/material/Typography';
import { UILayers, CustomThemeName } from 'types/types';
import Switch from '@mui/material/Switch';
import useColorModeContext from 'helpers/useColorModeContext';

const Wrapper = styled.div`
  position: absolute;
  top: 73px;
  box-shadow:
    0 0 1px 0 rgba(0, 0, 0, 0.2),
    0 2px 8px -2px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.brand.headerBarColor1};
  right: 0px;
  z-index: ${UILayers.SiteMenu};
  color:
    ${props => props.theme.colors.text.onHeaderBar},
    @media (max-width: 768px) {
    top: 50px;
  }
`;

const MenuButton = styled(Typography)(({ theme }) => ({
  padding: `${theme.customSpacing.px.base * 4}px ${theme.customSpacing.px.s}px`,
  width: '320px',
  letterSpacing: '0',
  textTransform: 'none',
  cursor: 'pointer',

  '&:hover': {
    fontWeight: `${theme.customTypography.desktop.strong.weight}`,
    backgroundColor: `${theme.colors.surface.neutralSubdued}`,
    color: `${theme.colors.text.onHeaderBarHover}`,
  },
}));

const MyAccountDropDown = (): ReactElement => {
  const { logOut, isEmsiAdmin } = UseAuthContext();
  const theme = useTheme();
  const navigate = useNavigate();
  const { colorMode, toggleColorMode } = useColorModeContext();

  const handleLogoutClick = () => {
    logOut().then(() => {
      navigate('/sign-in');
    });
  };

  return (
    <Wrapper data-cy="wrapper-account-dropdown">
      {process.env.REACT_APP_STAGE !== 'prod' && (
        <Typography
          variant="heading"
          sx={{
            display: 'block',
            width: '100%',
            textAlign: 'left',
            padding: `${theme.customSpacing.px.base * 4}px ${theme.customSpacing.px.s}px`,
            marginBottom: '0',
            borderBottom:
              `${theme.customThemeName}` === CustomThemeName.LightCast
                ? `1px solid ${theme.colors.border.subdued}`
                : `1px solid ${theme.colors.text.onPrimary}`,
          }}
        >
          Environment - {process.env.REACT_APP_STAGE}
        </Typography>
      )}
      <MenuButton
        onClick={() => {
          navigate('/my-profile');
        }}
      >
        My Profile
      </MenuButton>
      {isEmsiAdmin() && (
        <>
          <MenuButton
            onClick={() => {
              navigate('/admin/admin-tools');
            }}
          >
            Admin Tools
          </MenuButton>
          <MenuButton
            onClick={() => {
              navigate('/admin/ui-library');
            }}
          >
            UI Library
          </MenuButton>
          <MenuButton sx={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex' }}>Legacy theme</div>
            <Switch checked={colorMode === 'dark'} onChange={toggleColorMode} />
          </MenuButton>
        </>
      )}
      <MenuButton onClick={handleLogoutClick} data-cy="link-logout">
        Logout
      </MenuButton>
    </Wrapper>
  );
};

export default MyAccountDropDown;
