import React, { ReactElement, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import CheckboxList from 'components/molecules/CheckboxList';
import Typography from '@mui/material/Typography';
import { CheckboxListLineItemProps } from 'components/atoms/CheckboxListLineItem';
import NoDataPlaceholder from 'components/atoms/NoDataPlaceholder';
import AutocompleteInput from 'components/molecules/AutocompleteInput';
import Skeleton from '@mui/material/Skeleton';
import Checkbox from '@mui/material/Checkbox';

type CheckboxListWithAutoCompleteProps = {
  title?: string;
  placeholder: string;
  loading?: boolean;
  items: Array<CheckboxListLineItemProps>;
  isAutoCompleteDisabled?: boolean;
  isListDisabled?: boolean;
  isListCritical?: boolean;
  autocompleteClickHandler: (arg: { label: string; value: any; source: string }) => void;
  autocompleteFunction?: (q: string) => Promise<any>;
  handleCheckboxItemToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  listDataLoadingMessage?: string;
  listDataColumnsCount: 0 | 1 | 2;
  listDataColumn1Title?: string;
  listDataColumn2Title?: string;
  dataCy?: string;
  listHeight?: string;
  listMaxHeight?: string;
};

const Wrapper = styled.div<{ isDisabled: boolean }>`
  border: 1px solid ${props => props.theme.colors.border.default};
  border-radius: ${props => props.theme.borderRadius.default};
  background-color: ${props => (props.isDisabled ? '#FBFCFD' : props.theme.colors.surface.default)};
  & .checkbox-list-item {
    padding: 0px ${props => props.theme.customSpacing.px.base * 4}px;
  }
  & .no-data-message-container {
    margin: ${props => `${props.theme.customSpacing.px.xxs}px ${props.theme.customSpacing.px.base * 4}px`};
  }
`;

const AutoCompleteWrapper = styled.div`
  background-color: inherit;
  height: 70px;
  padding: ${props => props.theme.customSpacing.px.base * 4}px;
  box-shadow: ${props => props.theme.boxShadow.surface1};
`;

const ListWrapper = styled.div<{ height?: string; maxHeight?: string }>`
  height: ${props => props.height};
  max-height: ${props => props.maxHeight};
  overflow-y: auto;
`;

// the items should be in the format of {label:'', value:'', message:''}
const CheckboxListWithAutoComplete = ({
  title,
  placeholder,
  loading = false,
  items,
  isAutoCompleteDisabled = false,
  isListDisabled = false,
  isListCritical,
  autocompleteClickHandler,
  autocompleteFunction,
  handleCheckboxItemToggle,
  listDataLoadingMessage = '',
  listDataColumnsCount,
  listDataColumn1Title = '',
  listDataColumn2Title = '',
  dataCy,
  listHeight = 'auto',
  listMaxHeight = 'auto',
}: CheckboxListWithAutoCompleteProps): ReactElement => {
  const theme = useTheme();
  const [itemList, setItemList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnInputChange = async value => {
    setIsLoading(true);
    const response = await autocompleteFunction?.(value);
    setIsLoading(false);
    setItemList(response);
  };

  return (
    <>
      {title && (
        <Typography variant="strong" color={isAutoCompleteDisabled ? 'text.disabled' : 'text.default'}>
          {title}
        </Typography>
      )}
      <Wrapper isDisabled={isAutoCompleteDisabled}>
        <AutoCompleteWrapper>
          <AutocompleteInput
            isDisabled={isAutoCompleteDisabled}
            onInputChange={handleOnInputChange}
            onItemSelect={value =>
              autocompleteClickHandler({ label: value.value.name, value: value.value.id, source: 'autocomplete' })
            }
            list={itemList}
            isLoading={isLoading}
            placeholder={placeholder}
          />
        </AutoCompleteWrapper>
        <ListWrapper height={listHeight} maxHeight={listMaxHeight}>
          {loading ? (
            Array.from(Array(3).keys()).map(i => {
              return (
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    gap: '8px',
                    padding: `${theme.customSpacing.px.base}px ${theme.customSpacing.px.base * 4}px`,
                  }}
                >
                  <Checkbox disabled={true} sx={{ padding: `${theme.customSpacing.px.xxs}px 0px` }} />
                  <Skeleton width={'100%'}>
                    <Typography>{'.'}</Typography>
                  </Skeleton>
                </div>
              );
            })
          ) : items && items.length > 0 ? (
            <CheckboxList
              items={items}
              isListDisabled={isListDisabled}
              isListCritical={isListCritical}
              name={title}
              handleItemToggle={e => handleCheckboxItemToggle(e)}
              dataCy={(dataCy || 'checkbox-list-with-autocomplete') + '-checkbox-list'}
              dataColumnsCount={listDataColumnsCount}
              dataColumn1Title={listDataColumn1Title}
              dataColumn2Title={listDataColumn2Title}
            />
          ) : (
            <NoDataPlaceholder>
              <Typography>{listDataLoadingMessage}</Typography>
            </NoDataPlaceholder>
          )}
        </ListWrapper>
      </Wrapper>
    </>
  );
};

export default CheckboxListWithAutoComplete;
