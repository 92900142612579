import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const StyledGridToolbarContainer = styled(GridToolbarContainer)`
  padding-top: ${props => props.theme.customSpacing.px.xs}px;
  padding-left: ${props => props.theme.customSpacing.px.m}px;
  margin-bottom: ${props => props.theme.customSpacing.px.xs}px;
`;

const DataGridExport = (): ReactElement => {
  return (
    <StyledGridToolbarContainer>
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </StyledGridToolbarContainer>
  );
};

export default DataGridExport;
