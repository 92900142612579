import React, { ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  color: ${props => props.theme.colors.text.critical};
  display: inline-block;
  font-weight: bold;
  padding-left: 0.5rem;
`;

const RequiredFieldIndicator = (): ReactElement => {
  return <Wrapper title={'required'}>*</Wrapper>;
};

export default RequiredFieldIndicator;
