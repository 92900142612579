import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { Column, ColumnsWrapper, Section, SectionSticky } from 'pages/admin/uiLibrary/UILibraryComponents';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { IconColorFilter } from 'types/types';

const ColumnCentered = styled(Column)`
  align-items: center;
`;

const Description = styled(Typography)`
  white-space: nowrap;
  height: 36px;
  padding-top: 10px;
`;

const ButtonDemo = (): ReactElement<any> => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Loading...');
  const [buttonCount, setButtonCount] = useState(0);

  const loadingButtonHandler = () => {
    setLoadingStatus(!loadingStatus);
  };

  const loadingButtonMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoadingMessage(e.target.value);
  };

  const buttonCountMessage = `Button clicked ${buttonCount} times.`;

  const handleButtonCount = () => {
    setButtonCount(buttonCount + 1);
  };

  const resetButtonCount = () => {
    setButtonCount(0);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <SectionSticky
          defaultComponent={
            <>
              <Typography variant="subHeading"></Typography>
              <br />
              <br />
              <Button onClick={handleButtonCount} title="<Button onClick={handleButtonCount}>Default</Button>">
                Default
              </Button>
              <br />
              <div style={{ whiteSpace: 'nowrap' }}>
                <Typography variant="caption">{buttonCountMessage}</Typography>
                <BaseIcon
                  type={IconType.Refresh}
                  colorFilter={IconColorFilter.Interactive}
                  onIconClick={resetButtonCount}
                />
              </div>
              <br />
              <br />
              <LoadingButton
                loading={loadingStatus}
                title="<LoadingButton loading={loadingStatus}>Loading Button</LoadingButton>"
              >
                Loading Button
              </LoadingButton>
              <br />
              <LoadingButton
                loading={loadingStatus}
                loadingIndicator={loadingMessage}
                title="<LoadingButton loading={loadingStatus} loadingIndicator={loadingMessage}>Custom Loading Message</LoadingButton>"
              >
                Custom Loading Message
              </LoadingButton>
              <br />
              <LoadingButton
                variant="text"
                loading={loadingStatus}
                loadingIndicator={loadingMessage}
                title="<LoadingButton variant='text' loading={loadingStatus} loadingIndicator={loadingMessage}>Loading Text Button</LoadingButton>"
              >
                Loading Text Button
              </LoadingButton>
              <br />
            </>
          }
          componentOptions={
            <>
              <FormControlLabel
                control={<Switch onChange={loadingButtonHandler} />}
                labelPlacement="end"
                label="Loading"
              />
              <br />
              <TextField
                value={loadingMessage}
                label="Loading Message"
                variant="outlined"
                onChange={loadingButtonMessage}
              />
            </>
          }
        />
        <Section>
          <Typography variant="strong">Color and Variant Options</Typography>
          <ColumnsWrapper>
            <ColumnCentered>
              <Typography variant="subHeading">Button Name</Typography>
              <br />
              <Button>Default</Button>
              <br />
              <br />
              <Button variant="contained" color="actionPrimary">
                Primary
              </Button>
              <br />
              <Button variant="contained" color="actionPrimary" disabled>
                Primary (disabled)
              </Button>
              <br />
              <Button variant="outlined">Secondary</Button>
              <br />
              <Button variant="outlined" disabled>
                Secondary (disabled)
              </Button>
              <br />
              <Button variant="contained" color="actionCritical">
                Critical
              </Button>
              <br />
              <Button variant="contained" color="actionCritical" disabled>
                Critical (disabled)
              </Button>
              <br />
              <Button variant="text">Default</Button>
              <br />
              <Button variant="text" disabled>
                Default (disabled)
              </Button>
              <br />
              <Button variant="text" color="actionCritical">
                Critical
              </Button>
              <br />
              <Button variant="text" color="actionCritical" disabled>
                Critical (disabled)
              </Button>
              <br />
            </ColumnCentered>
            <Column>
              <Typography variant="subHeading">Attribute Customization</Typography>
              <br />
              <Description>no variant, no color</Description>
              <br />
              <br />
              <Description>variant=&apos;contained&apos; color=&apos;actionPrimary&apos;</Description>
              <br />
              <Description>variant=&apos;contained&apos; color=&apos;actionPrimary&apos; disabled</Description>
              <br />
              <Description>variant=&apos;outlined&apos;&nbsp;(all colors inputs default to secondary)</Description>
              <br />
              <Description>variant=&apos;outlined&apos; disabled</Description>
              <br />
              <Description>variant=&apos;contained&apos; color=&apos;actionCritical&apos;</Description>
              <br />
              <Description>variant=&apos;contained&apos; color=&apos;actionCritical&apos; disabled</Description>
              <br />
              <Typography>variant=&apos;text&apos;</Typography>
              <br />
              <Typography>variant=&apos;text&apos; disabled</Typography>
              <br />
              <Typography style={{ whiteSpace: 'nowrap' }}>
                variant=&apos;text&apos; color=&apos;actionCritical&apos;&nbsp;(all other colors revert to Default)
              </Typography>
              <br />
              <Typography>variant=&apos;text&apos; color=&apos;actionCritical&apos; disabled</Typography>
              <br />
            </Column>
          </ColumnsWrapper>
        </Section>
        <Divider />
        <Section>
          <Typography variant="strong">Size Options</Typography>
          <ColumnsWrapper>
            <ColumnCentered>
              <Typography variant="subHeading">Small</Typography>
              <br />
              <Button size="small">Default</Button>
              <br />
              <Button size="small" variant="outlined">
                Outlined
              </Button>
              <br />
              <Button size="small" variant="contained" color="actionPrimary">
                Contained Primary
              </Button>
              <br />
              <Button size="small" variant="contained" color="actionCritical">
                Contained Critical
              </Button>
              <br />
              <Button size="small" variant="text">
                Text (default)
              </Button>
              <br />
              <Button size="small" variant="text">
                Text Critical
              </Button>
            </ColumnCentered>
            <ColumnCentered>
              <Typography variant="subHeading">Medium (default)</Typography>
              <br />
              <Button size="medium">Default</Button>
              <br />
              <Button size="medium" variant="outlined">
                Outlined
              </Button>
              <br />
              <Button size="medium" variant="contained" color="actionPrimary">
                Contained Primary
              </Button>
              <br />
              <Button size="medium" variant="contained" color="actionCritical">
                Contained Critical
              </Button>
              <br />
              <Button size="medium" variant="text">
                Text (default)
              </Button>
              <br />
              <Button size="medium" variant="text">
                Text Critical
              </Button>
            </ColumnCentered>
            <ColumnCentered>
              <Typography variant="subHeading">Large</Typography>
              <br />
              <Button size="large">Default</Button>
              <br />
              <Button size="large" variant="outlined">
                Outlined
              </Button>
              <br />
              <Button size="large" variant="contained" color="actionPrimary">
                Contained Primary
              </Button>
              <br />
              <Button size="large" variant="contained" color="actionCritical">
                Contained Critical
              </Button>
              <br />
              <Button size="large" variant="text">
                Text (default)
              </Button>
              <br />
              <Button size="large" variant="text">
                Text Critical
              </Button>
            </ColumnCentered>
          </ColumnsWrapper>
        </Section>
      </div>
    </>
  );
};

export default ButtonDemo;
