import React, { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import styled, { useTheme } from 'styled-components';
import AsyncSelect from 'react-select/async';
import axios from 'helpers/api_helper';
import isEqual from 'lodash/isEqual';

import customStyles from 'helpers/asyncSelectStylesHelper';

import { getPostings } from 'helpers/LaborSearchHelper';
import { benchmarkToIds } from 'helpers/SkillAnalysisHelper';
import GenericPill from 'components/atoms/GenericPill';
import Benchmark from 'classes/benchmark';
import InputBar from 'components/molecules/InputBar';
import ButtonRow from 'components/atoms/ButtonRow';
import InputRow from 'components/atoms/InputRow';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';

const InputRowAlt = styled(InputRow)`
  margin-bottom: ${props => props.theme.customSpacing.px.xxs}px;
`;

const StyledInputBar = styled(InputBar)`
  border: 1px solid #c4c4c4;
  border-radius: 0.3125rem;
  color: #666666;
  font-size: 0.875rem;
  padding-left: 0.5em;
  width: 100%;
`;

const BubblesDiv = styled.div`
  padding-bottom: 0.25rem;
  display: flex;
  flex-wrap: wrap;
`;

const Container = styled.div`
  border-radius: ${props => props.theme.borderRadius.default};
  padding: 0.4rem;
`;

const PostingCountRow = styled.div`
  color: ${props => props.theme.colors.text.disabled};
`;

const customTheme = theme => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
  },
});

const locationAutoComplete = async q => {
  const encodedQuery = encodeURIComponent(q);
  const res = await axios.get(`${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/postings/taxonomies/msa?q=${encodedQuery}`);
  return res.data.results.map(i => ({ value: i, label: i.name }));
};

const companyAutoComplete = async q => {
  const encodedQuery = encodeURIComponent(q);
  const res = await axios.get(
    `${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/postings/taxonomies/company?q=${encodedQuery}`
  );
  return res.data.results.map(i => ({ value: i, label: i.name }));
};

const naicsAutoComplete = async q => {
  const encodedQuery = encodeURIComponent(q);
  const res = await axios.get(
    `${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/postings/taxonomies/naics6?q=${encodedQuery}`
  );
  return res.data.results.map(i => ({ value: i, label: i.name }));
};

const BenchmarkForm = ({ originalBenchmark, saveHandler, cancelHandler }) => {
  const theme = useTheme();
  const [saving, setSaving] = useState(false);
  const [benchmark, setBenchmark] = useState(Benchmark.fromJson(originalBenchmark));
  const [loadingPostingCount, setLoadingPostingCount] = useState(false);
  const [jobPostingsCount, setJobPostingsCount] = useState(0);
  const [lastCheckHash, setLastCheckHash] = useState('');
  const [currentValue, setCurrentValue] = useState();

  useEffect(() => {
    setLastCheckHash(JSON.stringify({ ...benchmark, name: '' }));
  }, [benchmark]);

  useEffect(() => {
    (async () => {
      try {
        if (lastCheckHash) {
          setLoadingPostingCount(true);
          const benchmarkIds = benchmarkToIds(JSON.parse(lastCheckHash));
          const searchContext = {
            company: benchmarkIds.companyIds,
            msa: benchmarkIds.locationMSAIds,
            naics6: benchmarkIds.industryIds,
          };
          const data = await getPostings({ facetName: 'skills', searchContext, limit: 1 });
          setJobPostingsCount(data.total);
          setLoadingPostingCount(false);
        }
      } catch (e) {
        console.error('Error', e);
      }
    })();
  }, [lastCheckHash]);

  const benchmarkModified = useMemo(() => {
    if (!originalBenchmark) {
      // role not loaded, so don't compare
      return false;
    }
    return !isEqual(benchmark, originalBenchmark);
  }, [originalBenchmark, benchmark]);

  const updateRole = async () => {
    setSaving(true);
    // call the patch api with the new data
    await saveHandler(benchmark);
    setSaving(false);
  };

  const removeLocation = item => {
    removeItem('locations', item.id);
  };

  const removeCompany = item => {
    removeItem('companies', item.id);
  };

  const removeIndustry = item => {
    removeItem('industries', item.id);
  };

  const removeItem = (type, itemId) => {
    const benchmarkCopy = { ...benchmark };
    const itemIndex = benchmarkCopy[type].findIndex(i => i.id === itemId);
    if (itemIndex >= 0) {
      benchmarkCopy[type].splice(itemIndex, 1);
      setBenchmark(benchmarkCopy);
    }
    ReactGA.event({
      category: 'benchmark',
      action: 'edit benchmark',
      label: `remove ${type}`,
    });
  };

  return (
    <div>
      <InputRow>
        Benchmark Name
        <StyledInputBar
          value={benchmark.name}
          onChange={e => {
            setBenchmark({ ...benchmark, name: e.target.value });
          }}
        />
      </InputRow>

      <InputRowAlt>
        Locations
        <Container>
          <BubblesDiv>
            {benchmark.locations.map(i => (
              <GenericPill item={i} key={i.id} removeHandler={removeLocation} />
            ))}
          </BubblesDiv>
          <AsyncSelect
            value={currentValue}
            onChange={x => {
              const benchmarkCopy = { ...benchmark };
              if (!benchmarkCopy.locations.find(i => i.id === x.value.id)) {
                ReactGA.event({
                  category: 'benchmark',
                  action: 'edit benchmark',
                  label: 'add locations',
                });

                benchmarkCopy.locations.push({
                  id: x.value.id,
                  name: x.value.name,
                });
              } else {
                ReactGA.event({
                  category: 'benchmark',
                  action: 'edit benchmark',
                  label: 'add locations - duplicate',
                });
              }
              setBenchmark(benchmarkCopy);
              setCurrentValue(null);
            }}
            styles={customStyles}
            noOptionsMessage={({ inputValue }) => {
              return inputValue ? 'No results' : 'Type to search';
            }}
            theme={customTheme}
            cacheOptions
            loadOptions={locationAutoComplete}
            placeholder={'Add a Location'}
          />
        </Container>
      </InputRowAlt>

      <InputRowAlt>
        Company (Optional)
        <Container>
          <BubblesDiv>
            {benchmark.companies.map(i => (
              <GenericPill item={i} key={i.id} removeHandler={removeCompany} />
            ))}
          </BubblesDiv>
          <AsyncSelect
            value={currentValue}
            onChange={x => {
              const benchmarkCopy = { ...benchmark };
              if (!benchmarkCopy.companies.find(i => i.id === x.value.id)) {
                ReactGA.event({
                  category: 'benchmark',
                  action: 'edit benchmark',
                  label: 'add companies',
                });

                benchmarkCopy.companies.push({
                  id: x.value.id,
                  name: x.value.name,
                });
              } else {
                ReactGA.event({
                  category: 'benchmark',
                  action: 'edit benchmark',
                  label: 'add companies - duplicate',
                });
              }
              setBenchmark(benchmarkCopy);
              setCurrentValue(null);
            }}
            styles={customStyles}
            noOptionsMessage={({ inputValue }) => {
              return inputValue ? 'No results' : 'Type to search';
            }}
            theme={customTheme}
            cacheOptions
            loadOptions={companyAutoComplete}
            placeholder={'Add a Company'}
          />
        </Container>
      </InputRowAlt>

      <InputRowAlt>
        Industry (Optional)
        <Container>
          <BubblesDiv>
            {benchmark.industries.map(i => (
              <GenericPill item={i} key={i.id} removeHandler={removeIndustry} />
            ))}
          </BubblesDiv>
          <AsyncSelect
            value={currentValue}
            onChange={x => {
              const benchmarkCopy = { ...benchmark };
              if (!benchmarkCopy.industries.find(i => i.id === x.value.id)) {
                ReactGA.event({
                  category: 'benchmark',
                  action: 'edit benchmark',
                  label: 'add industries',
                });

                benchmarkCopy.industries.push({
                  id: x.value.id,
                  name: x.value.name,
                });
              } else {
                ReactGA.event({
                  category: 'benchmark',
                  action: 'edit benchmark',
                  label: 'add industries - duplicate',
                });
              }
              setBenchmark(benchmarkCopy);
              setCurrentValue(null);
            }}
            styles={customStyles}
            noOptionsMessage={({ inputValue }) => {
              return inputValue ? 'No results' : 'Type to search';
            }}
            theme={customTheme}
            cacheOptions
            loadOptions={naicsAutoComplete}
            placeholder={'Add an Industry'}
          />
        </Container>
      </InputRowAlt>

      <PostingCountRow>
        <span style={{ paddingRight: '0.25rem' }}>Your benchmark represents </span>
        {loadingPostingCount ? (
          <span>loading...</span>
        ) : (
          <span
            style={{
              color: jobPostingsCount < 5000 ? theme.colors.text.critical : theme.colors.text.default,
              fontWeight: 600,
            }}
          >
            {Math.min(jobPostingsCount, 100000).toLocaleString()}
            {jobPostingsCount > 100000 ? '+' : null}
          </span>
        )}
        <span style={{ paddingLeft: '0.25rem' }}> job postings.</span>
      </PostingCountRow>

      <ButtonRow>
        {' '}
        {/* TODO: make this a ButtonGroup when we conver to typescript */}
        <LoadingButton
          onClick={() => {
            updateRole(null);
          }}
          loading={saving}
          loadingIndicator="saving"
          disabled={!benchmark.name || !benchmarkModified}
          data-cy="this-is-a-test"
          sx={{ marginRight: `${theme.customSpacing.px.xxs}px` }}
        >
          Save
        </LoadingButton>
        <Button onClick={cancelHandler} sx={{ marginRight: '8px' }}>
          Cancel
        </Button>
      </ButtonRow>
    </div>
  );
};

export default BenchmarkForm;
