import { useEffect, useState } from 'react';
import IntegrationService from 'services/IntegrationService';
import {
  SubOption,
  SetIntegrationBody,
  IntegrationCredentials,
  VendorCompanyConfiguration,
  IntegrationMappings,
  IntegrationConfigurationFieldId,
  GenericOptions,
  JobCodeMappingOption,
  JobTitleMappingOption,
  JobFamilyMappingOption,
  JobDescriptionMappingOption,
  IntegrationScheduleJobType,
  IntegrationScheduleType,
  WorkdayFieldMap,
  VendorCompanyId,
  VendorCompany,
} from 'types/types';
import useCompanyContext from 'helpers/UseCompanyContext';

let getIntegrationRequestController = new AbortController();

const defaultIntegrationMappings: IntegrationMappings = {
  jobCode: JobCodeMappingOption.JobCode,
  roleName: JobTitleMappingOption.JobTitle,
  jobTitle: JobTitleMappingOption.JobTitle,
  description: JobDescriptionMappingOption.JobDescription,
  jobFamily: JobFamilyMappingOption.JobFamily,
  jobFunction: GenericOptions.NoneSpecified,
  notes: GenericOptions.NoneSpecified,
};

const VENDORMAPS = {
  workday: WorkdayFieldMap,
  default: WorkdayFieldMap, // default to workday since is the only integration that we are using, we need to revisit this.
};

const useIntegrationHelper = (companyId: string) => {
  const [integrationSettings, setIntegrationSettings] = useState<SubOption[]>([]);
  const [loadingIntegrationSettings, setLoadingIntegrationSettings] = useState<boolean>(false);
  const {
    vendorCompaniesWithConfiguration,
    integrationSchedules,
    setIntegrationSchedules,
    integrationCredentials,
    setIntegrationCredentials,
    setIntegrationMappings,
    getMappingConfigItems,
    getIntegrationCriteria,
    setIntegrationCriteria,
    loadAdminSpecificSettings,
  } = useCompanyContext();

  const getIntegrationMap = (integrationVendorName: string) => {
    return VENDORMAPS[integrationVendorName.toLowerCase()] || VENDORMAPS.default;
  };

  // TODO: Let's embrace we're only using Workday :) When we're using more than one integration, this will need to change.
  const vendorCompanyIntegration = vendorCompaniesWithConfiguration?.find(vcc => vcc.vendor.vendorName.toLowerCase() === 'workday');

  useEffect(() => {
    (async () => {
      if (!vendorCompanyIntegration) {
        // TODO: Let's embrace we're only using Workday :) When we're using more than one integration, this will need to change.
        const workdayVendorId = (await IntegrationService.getVendors(companyId))
          .find(integration => { 
            return integration?.vendorName.toLowerCase() === 'workday' 
          })?.id as string;
        await setIntegration(workdayVendorId, false);
      } else {
        await getIntegrations();
      }
    })();
  }, [companyId, integrationSchedules, JSON.stringify(vendorCompanyIntegration)]);

  const getIntegrations = async () => {
    if (vendorCompanyIntegration) {
      const settings = await formatIntegrationSettings(vendorCompanyIntegration);
      setIntegrationSettings(settings);
    }
  };

  const mapCompanyVendorConfigToMappings = (configItems: Array<VendorCompanyConfiguration>): IntegrationMappings => {
    if (!(configItems?.length > 0)) {
      return defaultIntegrationMappings;
    } else {
      const configToMappings = {};
      Object.keys(defaultIntegrationMappings).forEach(key => {
        const configItem = configItems.filter(item => item.fieldName === key)?.[0];
        // If we don't have a field specified use "None Specified", except for jobCode, roleName and jobTitle which we
        // require in order to create a role. If we have multiple fields mapped, combine them for the dropdown value
        // Converted this to if statements instead of ternary for readability
        if (!configItem) {
          configToMappings[key] = ['jobCode', 'roleName', 'jobTitle'].includes(key)
            ? defaultIntegrationMappings[key]
            : GenericOptions.NoneSpecified;
        } else {
          configToMappings[key] =
            configItem?.fieldValue?.length > 1 ? configItem?.fieldValue?.join(' + ') : configItem?.fieldValue?.[0];
        }
      });
      return configToMappings as IntegrationMappings;
    }
  };

  const getCompanyVendorIntegrationMappings = (): IntegrationMappings => {
    if (vendorCompanyIntegration) {
      const mappingConfigItems = getMappingConfigItems();
      const mappings = mappingConfigItems
        ? mapCompanyVendorConfigToMappings(mappingConfigItems)
        : defaultIntegrationMappings;
      return mappings;
    } else return defaultIntegrationMappings;
  };

  // Massage the mappings form data into what will be stored in the DB
  const updateVendorCompanyMappings = (
    vendorCompanyId: VendorCompanyId | undefined,
    integrationMappings: IntegrationMappings
  ): VendorCompanyConfiguration[] => {
    if (!vendorCompanyId) return []; // No need to proceed if vendorCompanyId is not provided

    const specifiedMappings = Object.fromEntries(
      Object.entries(integrationMappings).filter(([, value]) => !value.includes(GenericOptions.NoneSpecified))
    );

    const defaultConfigItem = {
      vendorCompanyId,
      fieldId: IntegrationConfigurationFieldId.RoleImportMapping,
      fieldType: 'textField',
      protected: false,
      active: true,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    const vendorCompanyConfig = vendorCompanyIntegration?.vendorCompanyConfigurations || [];
    const updatedIntegrationMappings: Array<VendorCompanyConfiguration> = [];

    Object.entries(specifiedMappings).forEach(([k, v]) => {
      const existingMapping = vendorCompanyConfig.find(vcc => vcc.fieldName === k);
      if (existingMapping) {
        const fieldValue =
          v === JobDescriptionMappingOption.JobDescriptionAndSummary ||
          v === JobDescriptionMappingOption.JobSummaryAndDescription
            ? v.replaceAll(' ', '').split('+')
            : [v];
        updatedIntegrationMappings.push({ ...existingMapping, fieldValue });
      } else {
        updatedIntegrationMappings.push({
          ...defaultConfigItem,
          fieldName: k,
          fieldValue:
            v === JobDescriptionMappingOption.JobDescriptionAndSummary ||
            v === JobDescriptionMappingOption.JobSummaryAndDescription
              ? v.replaceAll(' ', '').split('+')
              : [v],
        });
      }
    });
    const newIntegrationSettings = updatedIntegrationMappings.concat(getIntegrationCriteria());
    return newIntegrationSettings;
  };

  // TODO: This is what is called from IntegrationSettingsPage
  const saveIntegrationMappings = async (
    vendorId: string,
    vendorActive: boolean,
    integrationMappings: IntegrationMappings
  ) => {
    // Check if we have a vendorCompany record
    const vendorCompanyId = vendorCompanyIntegration?.id;
    const updatedMappings: Array<VendorCompanyConfiguration> = updateVendorCompanyMappings(
      vendorCompanyId,
      integrationMappings
    );
    if (vendorCompanyId) {
      return await setIntegrationMappings(updatedMappings);
    } else {
      // We have to create a new vendorCompany record
      const response = await setIntegration(vendorId, vendorActive);
      if (response?.data?.created) {
        updatedMappings.forEach(mapping => {
          delete mapping.id;
          mapping.vendorCompanyId = response.data.vendorCompanyItem.id;
        });
        return await setIntegrationMappings(updatedMappings);
      }
    }
  };

  const setIntegration = async (key: string, status: boolean) => {
    const id = integrationSettings.find(integration => integration.key === key)?.id;
    setIntegrationSettings(previousState =>
      previousState.map(integration => {
        if (integration.key === key) {
          return {
            ...integration,
            enabled: status,
          };
        }
        return integration;
      })
    );
    const body: SetIntegrationBody = {
      companyId,
      status: status ? 'active' : 'inactive',
      ...(id ? { id } : {}),
    };
    getIntegrationRequestController?.abort();
    getIntegrationRequestController = new AbortController();
    const response = await IntegrationService.setIntegration(companyId, key, body, getIntegrationRequestController);
    await loadAdminSpecificSettings();
    return response;
  };

  const saveIntegrationCredentials = async (vendorId: string, integrationCredentials: IntegrationCredentials) => {
    await setIntegrationCredentials(vendorId, integrationCredentials);
  };

  const formatIntegrationSettings = async (vci: VendorCompany) => {
    setLoadingIntegrationSettings(true);
    const formattedIntegrationSettings: SubOption[] = [
      {
        title: vci?.vendor.vendorName,
        key: vci?.vendorId,
        id: vci?.id,
        enabled: vci?.status === 'active',
        description:
          vci?.vendor &&
          getVendorDescription(vci.vendor.vendorName),
        subOptions: [
          {
            title: 'Credentials',
            key: `${vci?.vendorId}-credentials`,
            optionConfiguration: integrationCredentials?.[vci.vendorId],
          },
          {
            title: 'Field Mapping',
            key: `${vci?.vendorId}-mappings`,
            optionConfiguration: {
              vendorCompanyConfig: vci?.vendorCompanyConfigurations,
              mappings: getCompanyVendorIntegrationMappings(),
            },
          },
          {
            title: 'Scheduling',
            key: `${vci?.vendorId}-scheduling`,
            optionConfiguration: {
              vendorCompanyConfig: vci?.vendorCompanyConfigurations,
              schedules: integrationSchedules,
              criteria: getIntegrationCriteria(),
            },
          },
        ],
      },
    ];
    setLoadingIntegrationSettings(false);
    return formattedIntegrationSettings;
  };

  const saveIntegrationSchedules = async (
    vendorId: string,
    schedules: { job: IntegrationScheduleJobType; schedule: IntegrationScheduleType }[]
  ) => {
    await setIntegrationSchedules(vendorId, schedules);
  };

  // TODO: This is what is called from the IntegrationSettingsPage
  const saveIntegrationCriteria = async (vendorId: string, criteria: { fieldName: string; fieldValue: string[] }[]) => {
    if (vendorId !== vendorCompanyIntegration?.vendorId) return; // sanity check

    // This is from companyContext state
    const vendorCompanyConfig = vendorCompanyIntegration?.vendorCompanyConfigurations || [];

    // Update existing mappings with new values
    const updatedVCC: VendorCompanyConfiguration[] = vendorCompanyConfig
      .map(existingConfig => {
        const configToUpdate = criteria.find(mapping => mapping.fieldName === existingConfig.fieldName);
        if (configToUpdate) {
          // remove any empty values from the array of values
          existingConfig.fieldValue = configToUpdate.fieldValue.filter(value => value !== '');
        }
        return existingConfig;
      })
      .filter(updatedVCC => updatedVCC.fieldValue.length > 0); // if the array is empty we remove it from the criteria
    // add new mappings that don't exist
    criteria.forEach(configToUpdate => {
      const mappingExists = vendorCompanyConfig.some(
        existingConfig => existingConfig.fieldName === configToUpdate.fieldName
      );
      if (!mappingExists) {
        updatedVCC.push({
          vendorCompanyId: vendorCompanyIntegration.id,
          fieldId: IntegrationConfigurationFieldId.RoleExportCriteria,
          fieldType: 'role',
          fieldName: configToUpdate.fieldName,
          fieldValue: configToUpdate.fieldValue,
          protected: false,
          active: true,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }
    });
    return await setIntegrationCriteria(updatedVCC);
  };

  return {
    integrationSettings,
    setIntegration,
    loadingIntegrationSettings,
    saveIntegrationCredentials,
    saveIntegrationMappings,
    getIntegrationMap, // check how can we improve this
    saveIntegrationSchedules,
    saveIntegrationCriteria,
    activeVendorCompanyIntegration: vendorCompanyIntegration,
  };
};

const getVendorDescription = (vendorName: string) => {
  switch (vendorName) {
    case 'Workday':
      return `Enable Workday as an Integration Vendor to allow direct import of Workday Job Profiles to create and update roles in Talent Transform. 
              Talent Transform Skills Profiles can then be exported directly to their corresponding Workday Job Profiles. In addition, Lightcast offers 
              the ability for Workday Skills Cloud enabled customers to manage Lightcast to Workday Skills Cloud Skill mappings as an External Skill Vendor. 
              Lightcast is a Certified Workday Integration.`;
    default:
      return '';
  }
};

export default useIntegrationHelper;
