import React, { ReactElement, ReactNode, useState } from 'react';
import useRoleEditContext from 'helpers/useRoleEditContext';
import { ExtractedSkill } from 'types/types';
import styled from 'styled-components';
import Role from 'classes/role';
import SkillsExtractor from 'components/organisms/SkillsExtractor';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

type RoleMetadataProps = {
  role: Role;
  loading?: boolean;
};

const MetaContainer = styled.div<{ $showMetadata?: boolean }>`
  display: ${props => (props.$showMetadata ? 'flex' : 'none')};
  padding-top: ${props => props.theme.customSpacing.px.xs}px;
  flex-direction: column;
  row-gap: ${props => props.theme.customSpacing.px.xxs}px;
`;

const MetaLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const MetaHeader = styled(Typography)`
  color: ${props => props.theme.colors.text.default};
  display: block;
  width: 110px;
  text-transform: capitalize;
`;

const MetaBody = styled.div<{ $multiline?: boolean }>`
  font-size: ${props => props.theme.customTypography.desktop.body.size};
  overflow-y: auto;
  padding-left: ${props => props.theme.customSpacing.px.base}px;
  width: 100%;
  color: ${props => props.theme.colors.text.disabled};

  ${props =>
    props.$multiline &&
    `
      flex-basis: 100%;
      max-height: 200px;
    `}
`;

const SkillExtractorWrapper = styled.div`
  background-color: 'white';
  column-gap: ${props => props.theme.customSpacing.px.m}px;
  display: grid;
  @media (max-width: 750px) {
    display: block;
    & > div {
      min-height: 300px;
    }
  }
`;

const RoleMetadata = ({ role, loading = false }: RoleMetadataProps): ReactElement => {
  const [showMetadata, setShowMetadata] = useState(false);
  const { setSkillsFromDescription, setIsSkillsExtracting } = useRoleEditContext();

  const extractedSkillsHandler = (skills: ExtractedSkill[]) => {
    setIsSkillsExtracting(true);
    setSkillsFromDescription(skills);
    setIsSkillsExtracting(false);
  };

  const aliasList = role?.aliases.map(alias => alias.name) || [];
  const tagList = role?.tags.map(tag => tag.name) || [];
  const defaultSkelatonHeight = 14;

  const loadingSkeleton = (isMultiline = false): ReactElement => {
    return (
      <Skeleton width={'100%'} height={(isMultiline ? 5 : 1) * defaultSkelatonHeight} variant="rounded">
        <MetaBody>{'.'}</MetaBody>
      </Skeleton>
    );
  };

  const MetadataSection = (
    isVisible: boolean,
    title: string,
    isMultiline: boolean,
    metabodyContent: ReactNode
  ): ReactElement => {
    return (
      <>
        {isVisible && (
          <MetaLine>
            <MetaHeader variant="body">{title}</MetaHeader>
            {loading ? (
              loadingSkeleton(isMultiline)
            ) : (
              <MetaBody
                $multiline={isMultiline}
                data-cy={`role-mapping-metadata-job-${title}-text`}
                className={`metadata-${title}`}
              >
                {metabodyContent}
              </MetaBody>
            )}
          </MetaLine>
        )}
      </>
    );
  };

  return (
    <div>
      <div
        onClick={() => setShowMetadata(!showMetadata)}
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', cursor: 'pointer' }}
        data-cy="role-mapping-metadata-link-button"
      >
        <Typography variant="strong">Meta Data</Typography>
        <BaseIcon type={showMetadata ? IconType.ChevronUp : IconType.ChevronDown} size={'10px'} />
      </div>
      <MetaContainer data-cy="role-mapping-metadata-container" $showMetadata={showMetadata}>
        {MetadataSection(true, 'job title', false, `${role.jobTitle}`)}
        {MetadataSection(true, 'code', false, `${role.jobCode}`)}
        {MetadataSection(role?.jobFamily !== 'n/a', 'family', false, `${role.jobFamily}`)}
        {MetadataSection(role?.jobFunction !== 'n/a', 'function', false, `${role.jobFunction}`)}
        {MetadataSection(
          role?.description !== null,
          'description',
          true,
          <SkillsExtractor
            extractedSkillsHandler={extractedSkillsHandler}
            readOnly={true}
            noPadding={true}
            content={role?.description?.trim() || ''}
            loading={loading}
            showToolbar={false}
            Wrapper={SkillExtractorWrapper}
            dataCy="role-mapping-metadata-job-description-text-content"
          />
        )}
        {MetadataSection(role?.tags.length > 0, 'tags', false, `${tagList.join(', ')}`)}
        {MetadataSection(role?.aliases.length > 0, 'aliases', false, `${aliasList.join(', ')}`)}
        {MetadataSection(role?.notes !== null, 'notes', true, `${role.notes?.trim()}`)}
      </MetaContainer>
    </div>
  );
};

export default RoleMetadata;
