import React, { useState, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';

export interface AuthenticationContextState {
  isLoggedIn: boolean;
  isAttemptingLogin?: boolean;
  loaded: boolean;
  userId?: string | undefined;
  firstName?: string;
  lastName?: string;
  companyId: string;
  idToken?: string | undefined;
  groups?: string | Array<string>;
  refreshToken: string;
}

interface AuthenticationContextProviderProps {
  children?: React.ReactElement;
}

const defaultContext: AuthenticationContextState = {
  isLoggedIn: false,
  isAttemptingLogin: false,
  loaded: false,
  refreshToken: 'norefresh',
  idToken: undefined,
  userId: undefined,
  companyId: '',
};

const AuthenticationContext = React.createContext<[AuthenticationContextState, React.Dispatch<any>]>([
  defaultContext,
  () => undefined,
]);

const AuthenticationContextProvider: React.FC<AuthenticationContextProviderProps> = props => {
  const [state, setState] = useState<AuthenticationContextState>({
    ...defaultContext,
  });

  useEffect(() => {
    (async () => {
      try {
        const userDetails = await Auth.currentAuthenticatedUser();

        // the user has a session, so let's mark it as such
        setState(state => {
          return {
            ...state,
            isLoggedIn: true,
            isAttemptingLogin: false,
            loaded: true,
            idToken: userDetails.signInUserSession.getIdToken().getJwtToken(),
            groups: userDetails.signInUserSession.getIdToken().payload['cognito:groups'] || [],
            companyId: userDetails.signInUserSession.getIdToken().payload['custom:CompanyId'],
            refreshToken: state.refreshToken,
          };
        });
        sessionStorage.setItem('authIdToken', userDetails.signInUserSession.getIdToken().getJwtToken());

        if (userDetails && userDetails.attributes) {
          setState({
            isLoggedIn: true,
            isAttemptingLogin: false,
            loaded: true,
            userId: userDetails.username,
            // We don't seem to use these anywhere. The UserContext firstName+lastName which we use in the app
            // are set via a call to Dynamo (UserContext lines 62+63)
            // firstName: user.attributes.given_name,
            // lastName: user.attributes.family_name,
            companyId: userDetails.attributes['custom:CompanyId'],
            idToken: userDetails.signInUserSession.getIdToken().getJwtToken(),
            groups: userDetails.signInUserSession.getIdToken().payload['cognito:groups'] || [],
            refreshToken: state.refreshToken,
          });
        }
      } catch (e: any) {
        console.error('User Authentication error: ' + JSON.stringify(e));
        setState({
          companyId: '',
          isLoggedIn: false,
          loaded: true,
          refreshToken: state.refreshToken,
        });
      }
    })();
  }, [state.refreshToken]);

  return <AuthenticationContext.Provider value={[state, setState]}>{props.children}</AuthenticationContext.Provider>;
};

export { AuthenticationContext, AuthenticationContextProvider };
