import axios from 'helpers/api_helper';
import { GetPostingsCountRequestProps, PostingsCount } from 'types/types';
import { getPostings } from 'helpers/LaborSearchHelper';
import Role from 'classes/role';

export type Mapping = {
  id: string;
  label: string;
  value: string;
  message?: string;
  ourPostings?: string;
  nationwidePostings?: string;
};

export type Title = Mapping;
export type Occupation = Mapping;
export type SpecializedOccupation = Mapping;

export type RoleLightcastTitle = {
  emsiTitleId: string;
  emsiTitleName: string;
};

export type RoleOccupation = {
  soc: string;
  socName: string;
};

export type RoleSpecializedOccupation = {
  specializedOccupationId: string;
  specializedOccupationName: string;
};

export type RoleUpdateMappingsModalProps = {
  isOpen: boolean;
  closeHandler: (reload: boolean) => void;
  roles: Role[];
  sourceComponent: string;
};

type RoleClassification = 'titles' | 'socs' | 'specialized_occupations';

export const sortMappings = (mapping: Mapping[], sortBy: string, sortDirection: 'asc' | 'desc' = 'asc'): Mapping[] => {
  if (sortDirection === 'asc') {
    return mapping.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1));
  } else {
    return mapping.sort((a, b) => (b[sortBy] > a[sortBy] ? 1 : -1));
  }
};

export const mapAndSortMappingValues = (
  roles: Role[]
): { titles: Mapping[]; occupations: Mapping[]; specializedOccupations: Mapping[] } => {
  const titles: Title[] = [];
  const occupations: Occupation[] = [];
  const specializedOccupations: SpecializedOccupation[] = [];
  roles.forEach(role => {
    if (titles.findIndex(t => t.id === role.emsiTitle.id) === -1) {
      titles.push({
        id: role.emsiTitle?.id,
        label: role.emsiTitle?.name,
        value: role.emsiTitle?.id,
      });
    }

    if (occupations.findIndex(o => o.id === role.soc) === -1 && role.socName !== 'n/a') {
      occupations.push({
        id: role.soc,
        label: role.socName,
        value: role.socName,
      });
    }

    if (
      specializedOccupations.findIndex(s => s.id === role.specializedOccupationId) === -1 &&
      role.specializedOccupationName !== 'n/a'
    ) {
      specializedOccupations.push({
        id: role.specializedOccupationId,
        label: role.specializedOccupationName,
        value: role.specializedOccupationId,
      });
    }
  });

  return { titles, occupations, specializedOccupations };
};

export const autocompleteItems = async (
  autocompleteItem: RoleClassification,
  q: string,
  showId = false
): Promise<{ label: string; value: string; name?: string }> => {
  const results = await axios.get(
    `${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/${autocompleteItem}/autocomplete?q=${encodeURIComponent(q)}`
  );

  if (showId) {
    return results.data[`${autocompleteItem}`].map(i => ({ value: i, name: i.name, label: `${i.name} (${i.id})` }));
  }
  return results.data[`${autocompleteItem}`].map(i => ({ value: i, label: i.name }));
};

const getPostingsCount = async ({
  facet,
  facetFilters,
  filter,
}: GetPostingsCountRequestProps): Promise<PostingsCount[]> => {
  let postingsCount: any[] = [];
  try {
    const postings = await getPostings({
      facetName: facet,
      searchContext: { ...filter, allow_filter_facet: true },
      filterValues: facetFilters,
      limit: facetFilters.length,
    });
    postingsCount = postings.buckets;
  } catch (e) {
    console.error(e);
  }
  postingsCount = facetFilters.map(item => {
    return {
      name: item,
      uniquePostings: (postingsCount.find(postings => postings.name === item)?.unique_postings || 0).toLocaleString(),
    };
  });
  return postingsCount;
};

export const getMappingItemsPostingCounts = async ({
  facet,
  facetFilters,
  filter,
  companyPostingsId,
}: GetPostingsCountRequestProps): Promise<any> => {
  const ourPostingsCount = await getPostingsCount({
    facet,
    facetFilters,
    filter: {
      ...filter,
      company: [companyPostingsId],
    },
  });
  const nationwidePostingsCount = await getPostingsCount({
    facet,
    facetFilters,
    filter,
  });
  return { ourPostingsCount, nationwidePostingsCount };
};

export const getTitleOccLabelAndValue = (
  titleOrOcc: { source: string; id: string; name?: string; label?: string; value: any; key: string },
  roleClassification: RoleClassification
): { label: string; value: string } => {
  let label: string;
  let value: string;
  if (roleClassification === 'socs') {
    label = titleOrOcc.source === 'autocomplete' ? titleOrOcc.name : titleOrOcc.value;
    value = titleOrOcc.source === 'autocomplete' ? titleOrOcc.value.id : titleOrOcc.key;
  } else {
    label = titleOrOcc.source === 'autocomplete' ? titleOrOcc.label : titleOrOcc.value;
    value = titleOrOcc.source === 'autocomplete' ? titleOrOcc.value.id : titleOrOcc.key;
  }

  return { label, value };
};
