import React, { ReactElement, useState } from 'react';
import useCompanyContext from 'helpers/UseCompanyContext';
import useAuthContext from 'helpers/UseAuthContext';
import userRoles from 'helpers/userRolesHelper';
import ReactGA from 'react-ga';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import DialogModal from 'components/molecules/DialogModal';
import TableSelect from 'components/molecules/TableSelect';
import { EMSIBGButton } from 'components/atoms/ButtonGroup';
import userService from 'services/UserService';

type UserTableSelectProps = {
  participant: { id: string; name: string; value: string };
  handleParticipantUpdate: (arg: { userId: string; role: string; teamId: string | null }) => void;
  handleUserDelete: (userId: string) => void;
  selectMaxWidth?: string;
};

const UserTableSelect = ({
  participant,
  handleParticipantUpdate,
  handleUserDelete,
  selectMaxWidth = '100%',
}: UserTableSelectProps): ReactElement => {
  const { userId, isAdmin, isEmsiAdmin } = useAuthContext();
  const { companyId } = useCompanyContext();
  const [updatingAdmin, setUpdatingAdmin] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmResetPwdOpen, setConfirmResetPwdOpen] = useState(false);

  const updateUserRole = async (userId: string, role: string) => {
    setUpdatingAdmin(true);
    try {
      await userService.updateUserRole(companyId, userId, role);
      handleParticipantUpdate({ userId, role, teamId: null });
      ReactGA.event({
        category: 'user',
        action: 'permission',
        label: `change user role: ${role}`,
      });
    } catch (e) {
      alert("unable to change the user's role");
      ReactGA.event({
        category: 'user',
        action: 'permission',
        label: 'change user role - FAILED',
      });
    }
    setUpdatingAdmin(false);
  };

  const deleteHandler = () => {
    ReactGA.event({
      category: 'user',
      action: 'delete',
      label: 'button clicked',
    });
    setConfirmDeleteOpen(true);
  };

  const resetPwdHandler = () => {
    ReactGA.event({
      category: 'user',
      action: 'reset password',
      label: 'button clicked',
    });
    setConfirmResetPwdOpen(true);
  };

  const handleCancel = () => {
    setConfirmDeleteOpen(false);
    setConfirmResetPwdOpen(false);
  };

  const handleDelete = async () => {
    setConfirmDeleteOpen(false);
    try {
      await userService.deleteUser(companyId, participant.id);
      handleUserDelete(participant.id);
      ReactGA.event({
        category: 'user',
        action: 'delete',
        label: 'success',
      });
      alert(`${participant?.name} deleted successfully.`);
    } catch (e) {
      alert(`Unable to delete user ${participant.id}`);
      ReactGA.event({
        category: 'user',
        action: 'delete',
        label: 'fail',
      });
    }
  };

  const handleResetPwd = async () => {
    setConfirmResetPwdOpen(false);
    try {
      await userService.resetUserPassword(companyId, participant.id);
      alert('reset email has been sent');
      ReactGA.event({
        category: 'user',
        action: 'reset password',
        label: 'success',
      });
    } catch (e) {
      alert(
        `Unable to reset user password ${participant.id}. Contact your customer service representative for further assistance.`
      );
      ReactGA.event({
        category: 'user',
        action: 'reset password',
        label: 'fail',
      });
    }
  };

  const isLoggedInUserRow = () => {
    return userId === participant.id;
  };

  const deleteUserBtnGroup: EMSIBGButton[] = [
    {
      text: 'Cancel',
      onClickEvent: handleCancel,
      dataCy: 'button-dialog-cancel',
    },
    {
      text: 'Delete User',
      variant: 'contained',
      color: 'actionCritical',
      onClickEvent: handleDelete,
      dataCy: 'button-dialog-delete',
    },
  ];
  const resetPwdBtnGroup: EMSIBGButton[] = [
    {
      text: 'Cancel',
      onClickEvent: handleCancel,
      dataCy: 'button-dialog-cancel',
    },
    {
      text: 'Reset Password',
      variant: 'contained',
      color: 'actionCritical',
      onClickEvent: handleResetPwd,
      dataCy: 'button-dialog-reset',
    },
  ];

  return (
    <>
      <div style={{ width: '100%' }}>
        {isAdmin() && (
          <TableSelect
            selectMaxWidth={selectMaxWidth}
            field={participant}
            options={userRoles.map(item => {
              return {
                id: item.key,
                value: item.name,
                restricted: !((item.restricted && isEmsiAdmin()) || !item.restricted),
              };
            })}
            updateHandler={(e: any) => {
              updateUserRole(participant.id, e.target.value);
            }}
            isUpdating={updatingAdmin}
            iconsComponent={
              !isLoggedInUserRow() && (
                <>
                  <BaseIcon type={IconType.Lock} onIconClick={resetPwdHandler} dataCy="icon-reset" />
                  <BaseIcon type={IconType.Delete} onIconClick={deleteHandler} dataCy="icon-delete" />
                </>
              )
            }
          />
        )}
      </div>
      <DialogModal
        dialogOpen={confirmDeleteOpen}
        closeModal={handleCancel}
        title="Delete User"
        content={
          <>
            Are you sure you wish to delete user <b>{participant?.name}</b> ?
            <br />
            <br />
            WARNING: This operation cannot be undone.
          </>
        }
        buttonGroup={deleteUserBtnGroup}
        dataCy="delete-user-dialog"
      />
      <DialogModal
        dialogOpen={confirmResetPwdOpen}
        closeModal={handleCancel}
        title="Reset User Password"
        content={
          <>
            Would you like to send a password reset email to <b>{participant?.id}</b> ?
            <br />
            <br />
          </>
        }
        buttonGroup={resetPwdBtnGroup}
        dataCy="reset-pwd-user-dialog"
      />
    </>
  );
};

export default UserTableSelect;
