import React, { useState, ReactElement } from 'react';
import { reviewStatusColor, reviewStatusLabel } from 'helpers/SkillProfilePageHelper';
import useSkillProfileNavigation from 'helpers/useSkillProfileNavigation';
import ComplexInputBar from 'components/molecules/ComplexInputBar';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import EmsiUIBadge from 'components/atoms/EmsiUIBadge';
import styled, { useTheme } from 'styled-components';
import FormControl from '@mui/material/FormControl';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import MenuItem from '@mui/material/MenuItem';
import { SpacingSizes } from 'types/types';
import Select from '@mui/material/Select';
import debounce from 'lodash/debounce';
import Menu from '@mui/material/Menu';
import RolesPageHelper from 'helpers/RolesPageHelper';
type NavigationSwitcherProps = {
  roleId: string;
  onSelectionChange: (id: string) => void;
  backClickHandler: () => void;
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly';
  dataCy: string;
};

const Wrapper = styled.div<{ $justifyContent: string }>`
  display: flex;
  justify-content: ${props => props.$justifyContent};
`;

const NavigationSwitcher = ({
  roleId,
  onSelectionChange,
  backClickHandler,
  dataCy = 'table-pagination',
  justifyContent = 'space-between',
}: NavigationSwitcherProps): ReactElement => {
  const theme = useTheme();
  const {
    isLoadingItems,
    list,
    listSort,
    updateSortBy,
    activeItemIndex,
    activeItemId,
    setItemFilter,
    prevPage,
    total,
    nextPage,
    currentPage,
    rowsPerPage,
    getNextItemId,
    getPrevItemId,
  } = useSkillProfileNavigation(roleId);
  const [listMenuItemsAnchor, setListMenuItemsAnchor] = useState<null | HTMLElement>(null);
  const listMenuOpen = Boolean(listMenuItemsAnchor);

  const handleItemSelectId = id => {
    onSelectionChange(id);
    setListMenuItemsAnchor(null);
  };

  return (
    <Wrapper $justifyContent={justifyContent} data-cy={dataCy}>
      <LoadingButton
        data-cy="navigation-switcher-back-button"
        variant="outlined"
        disabled={isLoadingItems}
        onClick={backClickHandler}
        startIcon={
          <BaseIcon
            size="16"
            marginLeft={SpacingSizes.None}
            marginRight={SpacingSizes.None}
            type={IconType.ArrowLeft}
          />
        }
        size="medium"
      >
        Back
      </LoadingButton>
      <div style={{ display: 'flex', gap: `${theme.customSpacing.px.base}px` }}>
        <LoadingButton
          disabled={isLoadingItems}
          variant="text"
          sx={{ p: '0px', minWidth: 'auto' }}
          startIcon={
            <BaseIcon type={IconType.ArrowLeftLine} size="16" onIconClick={() => onSelectionChange(getPrevItemId())} />
          }
        />
        <LoadingButton
          variant="outlined"
          disabled={listMenuOpen}
          loading={isLoadingItems}
          onClick={(event: React.MouseEvent<HTMLElement>) => setListMenuItemsAnchor(event.currentTarget)}
          loadingIndicator={
            <>
              <Skeleton variant="text" width={'20px'} />
              <Typography>{'/'}</Typography>
              <Skeleton variant="text" width={'20px'} />
            </>
          }
          endIcon={
            !isLoadingItems && (
              <BaseIcon
                size="16"
                marginLeft={SpacingSizes.None}
                marginRight={SpacingSizes.None}
                type={IconType.ChevronDown}
              />
            )
          }
          size="medium"
        >
          {activeItemIndex + 1} / {total}
        </LoadingButton>
        <LoadingButton
          disabled={isLoadingItems}
          variant="text"
          sx={{ p: '0px', minWidth: 'auto' }}
          endIcon={
            <BaseIcon type={IconType.ArrowRightLine} size="16" onIconClick={() => onSelectionChange(getNextItemId())} />
          }
        />
        <Menu
          anchorEl={listMenuItemsAnchor}
          open={listMenuOpen}
          onClose={() => setListMenuItemsAnchor(null)}
          PaperProps={{ style: { width: 500 } }}
        >
          <Grid container p={1}>
            <ComplexInputBar
              placeholder="Search"
              preItem={<BaseIcon type={IconType.Search} />}
              onChange={debounce(e => setItemFilter(e.target.value), 300)}
            />
          </Grid>
          <Grid container p={1}>
            <FormControl size="small" fullWidth>
              <Select
                labelId="sort-select-label"
                id="sort-select"
                value={listSort.sortBy}
                onChange={e => {
                  updateSortBy(e.target.value);
                }}
              >
                <MenuItem value={'roleName'}>
                  <Typography>
                    Sort By: <b> Role Name</b>
                  </Typography>
                </MenuItem>
                <MenuItem value={'mappingStatus'}>
                  <Typography>
                    Sort By: <b> Review Status</b>
                  </Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {list.map((item, index) => {
            return (
              <MenuItem
                selected={activeItemId === item.roleId}
                autoFocus={false}
                key={`${item.roleId}-${index}`}
                onClick={() => handleItemSelectId(item.roleId)}
                sx={{ justifyContent: 'space-between' }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 'bold' }}>{item.roleName}</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ display: 'inline-flex' }}>
                    <Divider
                      orientation="vertical"
                      sx={{
                        width: `${4}px`,
                        backgroundColor: `${RolesPageHelper.RoleConfidenceLevelColor(item.mappingConfidenceLevel)}`,
                        borderRadius: '10px',
                        p: '1',
                        height: '50%',
                        alignSelf: 'center',
                      }}
                    />
                    <Typography p={1}>{item.emsiTitleName}</Typography>
                    <Typography p={1}>{}</Typography>
                  </Grid>
                </Grid>
                <EmsiUIBadge
                  label={reviewStatusLabel(item.reviewStatus)}
                  color={reviewStatusColor(item.reviewStatus)}
                  size={'small'}
                  data-cy={'skillprofile-skills-review-status'}
                />
              </MenuItem>
            );
          })}
          <div style={{ display: 'flex', justifyContent: 'center', gap: `${theme.customSpacing.px.base}px` }}>
            <LoadingButton
              disabled={isLoadingItems}
              variant="text"
              sx={{ p: '0px', minWidth: 'auto' }}
              startIcon={<BaseIcon type={IconType.ArrowLeftLine} size="16" onIconClick={prevPage} />}
            />
            <Typography>
              {Math.ceil(total / rowsPerPage) === currentPage ? total : currentPage * rowsPerPage} / {total}
            </Typography>
            <LoadingButton
              disabled={isLoadingItems}
              variant="text"
              sx={{ p: '0px', minWidth: 'auto' }}
              endIcon={<BaseIcon type={IconType.ArrowRightLine} size="16" onIconClick={nextPage} />}
            />
          </div>
        </Menu>
      </div>
    </Wrapper>
  );
};

export default NavigationSwitcher;
