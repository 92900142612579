import React from 'react';
import PropTypes from 'prop-types';

const RequiredIcon = ({ color = '#A9B0B8' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
        fill={color}
      />
      <path
        d="M10.2365 4.61816C11.4538 4.61816 12.3902 4.95829 13.0231 5.56704C13.6567 6.17639 13.9613 7.0311 13.9613 8.00993C13.9613 8.72358 13.799 9.36431 13.4633 9.89104C13.1638 10.3608 12.7323 10.7306 12.1751 10.9791L14.4867 15.2H12.2795L10.2113 11.347H8.34345V15.2H6.40723V4.61816H10.2365Z"
        fill={color}
      />
      <path
        d="M8.34375 6.33569V9.62461H10.0926C10.8154 9.62461 11.289 9.4632 11.5812 9.19948C11.8695 8.93939 12.0155 8.54858 12.0155 8.00998C12.0155 7.46844 11.8691 7.06097 11.576 6.78624C11.2805 6.50933 10.8024 6.33569 10.0727 6.33569H8.34375Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

RequiredIcon.propTypes = {
  color: PropTypes.string,
};

export default RequiredIcon;
