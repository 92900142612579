import React, { ReactElement } from 'react';
import styled from 'styled-components';

export enum PipIconType {
  full = 'full',
  partial = 'partial',
  empty = 'empty',
}

type PipIconProps = {
  type: PipIconType;
  width?: string;
  height?: string;
  fillColor?: string;
  margins?: string;
};

const Wrapper = styled.div<{ $margins?: string }>`
  margin: ${props => (props.$margins ? props.$margins : '0.15rem 0.15rem 0rem 0rem')};
  width: auto;
`;

const FullPip = ({ width, height, fillColor }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="5" cy="5" r="5" fill={fillColor} />
    </svg>
  );
};
const PartialPip = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5ZM8 5H2C2 3.34315 3.34315 2 5 2C6.65685 2 8 3.34315 8 5Z"
        fill="currentColor"
      />
    </svg>
  );
};
const EmptyPip = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10ZM5 8C6.65685 8 8 6.65685 8 5C8 3.34315 6.65685 2 5 2C3.34315 2 2 3.34315 2 5C2 6.65685 3.34315 8 5 8Z"
        fill="currentColor"
      />
    </svg>
  );
};

const PipIcon = ({
  type,
  width = '10',
  height = '10',
  fillColor = 'currentColor',
  margins,
}: PipIconProps): ReactElement => {
  return (
    <Wrapper $margins={margins} className="pip-icon-container">
      {type === 'full' && <FullPip width={width} height={height} fillColor={fillColor} />}
      {type === 'partial' && <PartialPip width={width} height={height} />}
      {type === 'empty' && <EmptyPip width={width} height={height} />}
    </Wrapper>
  );
};

export default PipIcon;
