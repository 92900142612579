import type {} from '@mui/lab/themeAugmentation';
import { UILayers } from 'types/types';

export const muiDrawerOverride = {
  styleOverrides: {
    root: {
      width: 428,
      flexShrink: 0,
      zIndex: UILayers.SidePanel,
      '& .MuiDrawer-paper': {
        width: 428,
        boxSizing: 'border-box',
        zIndex: UILayers.SidePanel,
      },
    },
  },
};
