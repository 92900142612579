import { ThemeProvider as MuiThemeProvider } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import themeDecider from 'theme/theme';
import globalStylesOriginal from 'globalStylesOriginal';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import globalStylesMango from 'globalStylesMango';
import globalStylesLightCast from 'globalStylesLightCast';
import Cookies from 'js-cookie';
import { PaletteMode } from '@mui/material';

const COLORMODE_PREFERENCE_COOKIE_KEY = 'colorModePreference';
export interface ColorModeContextState {
  colorMode: PaletteMode;
}

interface ColorModeContextProviderProps {
  children?: React.ReactElement;
}

const defaultContext: ColorModeContextState = {
  colorMode: 'light',
};

const ColorModeContext = React.createContext<[ColorModeContextState, React.Dispatch<any>]>([
  defaultContext,
  () => undefined,
]);

const ColorModeContextProvider: React.FC<ColorModeContextProviderProps> = props => {
  const colorModePreference: PaletteMode = (Cookies.get(COLORMODE_PREFERENCE_COOKIE_KEY) as PaletteMode) || 'light';

  const [state, setState] = useState<ColorModeContextState>({ ...defaultContext, colorMode: colorModePreference });

  useEffect(() => {
    Cookies.set(COLORMODE_PREFERENCE_COOKIE_KEY, state.colorMode);
  }, [state.colorMode]);

  const globalStyles = useMemo(() => {
    return state.colorMode === 'light' ? globalStylesLightCast : globalStylesOriginal;
  }, [state.colorMode]);

  const theme = useMemo(() => {
    return themeDecider(globalStyles);
  }, [state.colorMode]);

  return (
    <ColorModeContext.Provider value={[state, setState]}>
      <MuiThemeProvider theme={theme}>
        <StyledThemeProvider theme={globalStyles}>{props.children}</StyledThemeProvider>
      </MuiThemeProvider>
    </ColorModeContext.Provider>
  );
};

export { ColorModeContext, ColorModeContextProvider };
