import styled, { DefaultTheme, keyframes } from 'styled-components';

interface PlaceholderBarProps {
  width?: string;
  theme: DefaultTheme;
}

const PlaceholderShimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;

const PlaceholderBar = styled.div<PlaceholderBarProps>`
  animation-duration: 1.3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${PlaceholderShimmer};
  animation-timing-function: linear;
  background: ${props => props.theme.colors.surface.neutral};
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 1em;
  position: relative;
  line-height: 2em;
  margin: auto;
  width: ${params => (params.width ? params.width : '100%')};
  margin-bottom: 1em;
`;

export default PlaceholderBar;
