import axios from 'helpers/api_helper';
import EventLog from 'classes/eventLog';

const getEventLog = async (companyId, eventLogId) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/eventLogs/${eventLogId}`;
  const resp = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const eventLog = new EventLog(resp.data);

  return eventLog;
};

const getEventLogs = async (companyId, limit, offset) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/eventLogs?limit=${limit}&offset=${offset}`;
  const resp = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const eventLogs = resp.data?.eventLogs.map(el => new EventLog(el)) || [];
  const eventLogsTotal = resp.data?.eventLogsTotal;

  return { eventLogs, eventLogsTotal };
};

const getUserEventLogs = async (companyId, userId) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/eventLogs/user/${userId}`;
  const resp = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const eventLogs = resp.data?.map(el => new EventLog(el)) || [];

  return eventLogs;
};

const getObjectEventLogs = async (companyId, eventObjectId) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/eventLogs/object/${eventObjectId}`;
  const resp = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const eventLogs = resp.data?.map(el => new EventLog(el)) || [];

  return eventLogs;
};

const getUserObjectEventLogs = async (companyId, userId, eventObjectId) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/eventLogs/user/${userId}/object/${eventObjectId}`;
  const resp = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const eventLogs = resp.data?.map(el => new EventLog(el)) || [];

  return eventLogs;
};

export default {
  getEventLog,
  getEventLogs,
  getUserEventLogs,
  getObjectEventLogs,
  getUserObjectEventLogs,
};
