import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

const Search = ({ color, size = 24 }) => {
  const theme = useTheme();
  color = color || theme.colors.icon.default.hex;
  return (
    <div style={{ width: `${size}px` }}>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 11.8487 17.3729 13.551 16.3199 14.9056L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L14.9056 16.3199C13.551 17.3729 11.8487 18 10 18C5.58172 18 2 14.4183 2 10Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

Search.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default Search;
