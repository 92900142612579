import React, { ReactElement, ReactNode, useEffect, useMemo } from 'react';
import SkillProfileEditSuggestedSkillsContent from 'components/organisms/SkillProfileEditSuggestedSkillsContent';
import SkillProfileEditDescriptionContent from 'components/organisms/SkillProfileEditDescriptionContent';
import SkillProfileEditRecommendedContent from 'components/organisms/SkillProfileEditRecommendedContent';
import SkillProfileEditBenchmarkContent from 'components/organisms/SkillProfileEditBenchmarkContent';
import SkillProfileSuggestedSkillsTable from 'components/organisms/SkillProfileSuggestedSkillsTable';
import SelectableCardWithPipContainer from 'components/molecules/SelectableCardWithPipContainer';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import useSkillProfileContext from 'helpers/useSkillProfileContext';
import { CircularProgress, Typography } from '@mui/material';
import ComparisonChip from 'components/atoms/ComparisonChip';
import styled, { useTheme } from 'styled-components';
import { SkillSuggestionBy } from 'types/types';
import uniq from 'lodash/uniq';

const AddSkillsComponentsContainer = styled.div`
  padding: ${props => `${props.theme.customSpacing.px.base * 8}px ${props.theme.customSpacing.px.base * 10}px 0px`};
`;

const AddSkillsCardContainer = styled.div`
  width: 100%;
  margin: 1rem;
`;

const MetadataPopoverContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;
  gap: 1.125rem;
`;

const MetadataField = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: ${props => props.theme.customTypography.desktop.subHeading.lineHeight};
`;

const EditSkillProfileContent = (): ReactElement => {
  const theme = useTheme();
  const {
    role,
    skillsBy,
    suggestedSkills,
    setAddSkillsMode,
    descriptionOverride,
    loadJobDescriptionSkills,
    loadRecommendedSkills,
    loadBenchmarkSkills,
    skillTypes,
    skillTypeFilter,
    setSkillTypeFilter,
    sortBy,
    activeSortBy,
    setActiveSortBy,
  } = useSkillProfileContext();

  const addSkillsCardCounts =
    useMemo(() => {
      return suggestedSkills?.reduce(
        (acc, curr) => {
          if (curr.suggestedBy) {
            acc[curr.suggestedBy] = acc[curr.suggestedBy] ? acc[curr.suggestedBy] + 1 : 1;
          }
          return acc;
        },
        {} as Record<SkillSuggestionBy, number>
      );
    }, [suggestedSkills]) || ({} as Record<SkillSuggestionBy, number>);

  useEffect(() => {
    (async () => {
      await loadBenchmarkSkills();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await loadJobDescriptionSkills();
    })();
  }, [JSON.stringify(role?.details.description), descriptionOverride]);

  useEffect(() => {
    loadRecommendedSkills();
  }, [role?.specializedOccupationId, role?.soc]);

  const getUniqueSkillIds = skills => uniq(skills?.map(skill => skill.skillId));

  const cards = [
    {
      title: 'All Skills',
      key: SkillSuggestionBy.All,
      value: getUniqueSkillIds(suggestedSkills).length,
      children:
        addSkillsCardCounts[SkillSuggestionBy.JobDescription] &&
        addSkillsCardCounts[SkillSuggestionBy.Benchmark] &&
        addSkillsCardCounts[SkillSuggestionBy.Recommended] ? undefined : (
          <CircularProgress size={20} />
        ),
      isActive: skillsBy === SkillSuggestionBy.All,
    },
    {
      title: 'Job Description',
      titleIcon: <ComparisonChip chipType="Job Description" />,
      key: SkillSuggestionBy.JobDescription,
      value: addSkillsCardCounts[SkillSuggestionBy.JobDescription],
      children: addSkillsCardCounts[SkillSuggestionBy.JobDescription] ? undefined : <CircularProgress size={20} />,
      isActive: skillsBy === SkillSuggestionBy.JobDescription,
    },
    {
      title: 'Benchmark',
      titleIcon: <ComparisonChip chipType="Benchmark" />,
      key: SkillSuggestionBy.Benchmark,
      value: addSkillsCardCounts[SkillSuggestionBy.Benchmark],
      children: addSkillsCardCounts[SkillSuggestionBy.Benchmark] ? undefined : <CircularProgress size={20} />,
      isActive: skillsBy === SkillSuggestionBy.Benchmark,
    },
    {
      title: 'Occupation',
      titleIcon: <ComparisonChip chipType="Occupation" />,
      key: SkillSuggestionBy.Recommended,
      value: addSkillsCardCounts[SkillSuggestionBy.Recommended],
      children: addSkillsCardCounts[SkillSuggestionBy.Recommended] ? undefined : <CircularProgress size={20} />,
      isActive: skillsBy === SkillSuggestionBy.Recommended,
    },
  ];

  const SubNavComponent = (): ReactNode => {
    return (
      <AddSkillsCardContainer>
        <SelectableCardWithPipContainer items={cards} onClickHandler={index => setAddSkillsMode(cards[index].key)} />
      </AddSkillsCardContainer>
    );
  };

  const MetadataPopover = () => (
    <MetadataPopoverContainer>
      <MetadataField>
        <Typography variant="strong">{role?.jobCode || 'N/A'}</Typography>
        <Typography variant="caption">Code</Typography>
      </MetadataField>
      <MetadataField>
        <Typography variant="strong">{role?.jobFamily || 'N/A'}</Typography>
        <Typography variant="caption">Family</Typography>
      </MetadataField>
      <MetadataField>
        <Typography variant="strong">{role?.jobFunction || 'N/A'}</Typography>
        <Typography variant="caption">Function</Typography>
      </MetadataField>
      <MetadataField>
        <Typography variant="strong">{role?.emsiTitleName || 'Unclassified'}</Typography>
        <Typography variant="caption">Lightcast Title</Typography>
      </MetadataField>
      <MetadataField>
        <Typography variant="strong">{role ? `${role.socName} (${role.soc})` : 'Unclassified'}</Typography>
        <Typography variant="caption">SOC</Typography>
      </MetadataField>
      <MetadataField>
        <Typography variant="strong">{role?.specializedOccupationName || 'N/A'}</Typography>
        <Typography variant="caption">Occupation</Typography>
      </MetadataField>
    </MetadataPopoverContainer>
  );

  return (
    <>
      <div data-cy="edit-skill-profile-content">
        <ContentHeaderTemplate
          contentTitle={role?.roleName || ''}
          contentSubTitle={'Skill Profile'}
          displayDivider={true}
          fullWidthDivider={true}
          subNavComponent={SubNavComponent()}
          titleInformationPopoverComponent={MetadataPopover()}
        />
        <>
          <AddSkillsComponentsContainer data-cy={`edit-skill-profile-content-${skillsBy}-section`}>
            {skillsBy === SkillSuggestionBy.All && (
              <SkillProfileEditSuggestedSkillsContent
                filters={skillTypes}
                activeFilter={skillTypeFilter}
                setFilter={setSkillTypeFilter}
                sortBy={sortBy}
                activeSortBy={activeSortBy}
                setActiveSortBy={setActiveSortBy}
              />
            )}
            {skillsBy === SkillSuggestionBy.JobDescription && <SkillProfileEditDescriptionContent />}
            {skillsBy === SkillSuggestionBy.Benchmark && <SkillProfileEditBenchmarkContent />}
            {skillsBy === SkillSuggestionBy.Recommended && <SkillProfileEditRecommendedContent />}
          </AddSkillsComponentsContainer>
          <div style={{ padding: `${theme.customSpacing.px.base * 4}px 0px` }}>
            <SkillProfileSuggestedSkillsTable />
          </div>
        </>
      </div>
    </>
  );
};

export default EditSkillProfileContent;
