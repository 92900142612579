import React, { ReactElement } from 'react';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import styled, { useTheme } from 'styled-components';
import ChevronRight from 'components/atoms/icons/ChevronRight';
import ChevronLeft from 'components/atoms/icons/ChevronLeft';
import Select from 'components/atoms/icons/Select';
import MuiTablePagination from '@mui/material/TablePagination';

const PaginationWrapper = styled.div<{ $isLateralPaddingZero: boolean; $hasBorder: boolean; $justifyContent: string }>`
  width: 100%;

  .MuiTablePagination-selectLabel {
    display: none;
  }

  .MuiTablePagination-spacer {
    display: none;
  }

  .MuiTablePagination-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.$justifyContent};
    font-size: ${props => props.theme.customTypography.desktop.body.size};
    box-shadow: ${props => (props.$hasBorder ? props.theme.boxShadow.surface2 : 'none')};
    padding: 0px;

    .MuiInputBase-root {
      margin: 0px;
    }

    .MuiTablePagination-select {
      font-family: ${props => props.theme.customTypography.fontFamily};
      width: fit-content;
      border: 1px solid ${props => props.theme.colors.border.default};
      box-shadow: ${props => props.theme.boxShadow.surfaceSelected};
      border-radius: ${props => props.theme.borderRadius.default};
    }

    .MuiTablePagination-displayedRows {
      margin: 0px ${props => props.theme.customSpacing.px.xxs}px;
    }
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PagingButton = styled(IconButton)`
  padding-left: ${props => props.theme.customSpacing.px.base}px;
  padding-right: ${props => props.theme.customSpacing.px.base}px;
  border-radius: ${props => props.theme.borderRadius.default};
  border: 1px solid
    ${props =>
      props.disabled ? props => props.theme.colors.border.disabled : props => props.theme.colors.border.default};
  background-color: ${props =>
    props.disabled
      ? props => props.theme.colors.surface.disabled
      : props => props.theme.colors.surface.default} !important;
  box-shadow: ${props => props.theme.boxShadow.default};
  width: 32px;
  height: 32px;
`;

type TablePaginationProps = {
  total: number;
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  customRowsPerPageOptions?: Array<number>;
  setRowsPerPage: (rows: number) => void;
  pageChangeHandler?: () => void;
  isLateralPaddingZero?: boolean;
  hasBorder?: boolean;
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly';
  dataCy: string;
};

const TablePagination = ({
  total,
  page,
  setPage,
  rowsPerPage = 10,
  customRowsPerPageOptions = [10, 25, 50, 100],
  setRowsPerPage,
  pageChangeHandler,
  isLateralPaddingZero = false,
  hasBorder = true,
  dataCy = 'table-pagination',
  justifyContent = 'space-between',
}: TablePaginationProps): ReactElement => {
  const theme = useTheme();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    pageChangeHandler?.();
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowsPerPageOptions = customRowsPerPageOptions.map(rowsPerPage => ({
    label: `Rows ${rowsPerPage}`,
    value: rowsPerPage,
  }));

  const CustomSelectIcon = props => {
    return (
      <SvgIcon {...props}>
        <Select />
      </SvgIcon>
    );
  };

  const TablePaginationActions = props => {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleBackButtonClick = event => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = event => {
      onPageChange(event, page + 1);
    };

    return (
      <div style={{ display: 'flex', gap: `${theme.customSpacing.px.base}px` }}>
        <PagingButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
          disableRipple={true}
          data-cy="pagination-component-previous"
        >
          <ArrowWrapper>
            <ChevronLeft size={16} />
          </ArrowWrapper>
        </PagingButton>
        <PagingButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
          disableRipple={true}
          data-cy="pagination-component-next"
        >
          <ArrowWrapper>
            <ChevronRight size={16} />
          </ArrowWrapper>
        </PagingButton>
      </div>
    );
  };

  return (
    <PaginationWrapper
      className="pagination-wrapper"
      $isLateralPaddingZero={isLateralPaddingZero}
      $hasBorder={hasBorder}
      $justifyContent={justifyContent}
      data-cy={dataCy}
    >
      <MuiTablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        defaultValue={10}
        component="div"
        count={total}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
        SelectProps={{
          IconComponent: CustomSelectIcon,
        }}
      />
    </PaginationWrapper>
  );
};

export default TablePagination;
