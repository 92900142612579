import React, { ReactElement } from 'react';
import ReactGA from 'react-ga';
import styled, { useTheme } from 'styled-components';
import dayjs from 'dayjs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: ${props => props.theme.customSpacing.px.base * 16}px;
  padding: ${props => props.theme.customSpacing.px.s}px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterLink = styled(Link)`
  margin: 0px ${props => props.theme.customSpacing.px.xs}px ${props => props.theme.customSpacing.px.base}px;
`;

const Footer = (): ReactElement => {
  const theme = useTheme();
  const recordAnalytics = (label: string) => {
    ReactGA.event({
      category: 'footer',
      action: 'footer links',
      label,
    });
  };

  return (
    <Wrapper>
      <Typography
        style={{ margin: `0px ${theme.customSpacing.px.xs}px ${theme.customSpacing.px.base}px` }}
        variant="caption"
      >
        Copyright &copy; {dayjs().format('YYYY')}
      </Typography>
      <FooterLink
        variant="caption"
        tabIndex={0}
        href={'/terms-of-service'}
        target="_blank"
        rel="noreferrer"
        onClick={() => recordAnalytics('terms of service viewed')}
      >
        Terms of Service
      </FooterLink>
      <FooterLink
        variant="caption"
        tabIndex={0}
        href="https://www.economicmodeling.com/privacy-policy/"
        target="_blank"
        rel="noreferrer"
        onClick={() => recordAnalytics('privacy poclicy viewed')}
      >
        Privacy Policy
      </FooterLink>
    </Wrapper>
  );
};

export default Footer;
