import RolesService, { GetRolesResponse } from 'services/RolesService';
import { bucketizeTime, bucketizeTimeLong } from 'helpers/analyticsHelper';
import ReactGA from 'react-ga';
import Role from 'classes/role';
import { RoleFilters } from 'types/types';

const fields = ['roleName', 'roleId', 'emsiData', 'emsiTitleId', 'reviewed', 'needsApproval'];

const preliminaryRoleQuery = async (companyId: string, roleFilter?: string, appliedFilters?: RoleFilters) => {
  console.time('prefetch');
  const preFetch = await RolesService.getRoles({
    companyId,
    limit: 300,
    fields,
    filters: appliedFilters,
    searchTerms: roleFilter,
  });
  console.timeEnd('prefetch');
  return preFetch;
};

const trackLoadInGA = (totalTime, batchSize, numBatches) => {
  ReactGA.event({
    category: 'role mapping bulk load',
    action: `loading roles (${batchSize})`,
    label: `numPages ${numBatches}`,
  });

  ReactGA.event({
    category: 'role mapping bulk load',
    action: 'loading roles timing',
    label: `total time: ${bucketizeTimeLong(totalTime)}`,
  });

  ReactGA.event({
    category: 'role mapping bulk load',
    action: 'loading roles timing',
    label: `average time: ${bucketizeTime(totalTime / numBatches)}`,
  });
};

const appliedFiltersForQuery = appliedFilters => {
  const filters = { ...appliedFilters };
  Object.keys(filters).forEach(k => {
    filters[k] = filters[k].map(i => i.value);
  });
  return filters;
};

const loadRoles = async (companyId: string, roleFilter?: string, appliedFilters?: RoleFilters): Promise<Role[]> => {
  appliedFilters = appliedFiltersForQuery(appliedFilters);
  const { roles, totalCount } = await preliminaryRoleQuery(companyId, roleFilter, appliedFilters);

  const batchSize = 700;
  const queries = new Array<Promise<GetRolesResponse>>();
  const startTime = performance.now();

  // if we need more roles we'll get them here
  for (let i = roles.length; i < totalCount; i += batchSize) {
    queries.push(
      RolesService.getRoles({
        companyId,
        limit: batchSize,
        offset: i,
        fields,
        filters: appliedFilters,
        searchTerms: roleFilter,
      })
    );
  }

  const results = await Promise.all<GetRolesResponse>(queries);
  trackLoadInGA(performance.now() - startTime, batchSize, queries.length);

  const rolesData = results
    .map(r => r.roles)
    .reduce((p, v) => {
      return [...p, ...v];
    }, roles);

  return rolesData;
};

export default loadRoles;
