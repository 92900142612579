import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 75vh;
  margin: 10px;

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

const Icon404 = styled.div`
  display: flex;
  font-size: 25vw;
  line-height: 25vw;
  color: #999;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 3rem;
  color: #666;

  @media (max-width: 640px) {
    margin-left: 0rem;
  }
`;

const Message404 = styled.div`
  font-size: 6rem;
  line-height: 8rem;
  font-weight: bold;
  margin: 2rem 0;
  text-align: center;

  @media (max-width: 1100px) {
    font-size: 8vw;
    line-height: 10vw;
  }

  @media (max-width: 768px) {
    font-size: 7vw;
  }
`;

const PageNotFoundText = styled.div`
  font-size: 2rem;
  line-height: 3rem;
  margin: 0 0 2rem 0;
  text-align: center;

  @media (max-width: 1100px) {
    font-size: 3vw;
    line-height: 4.5vw;
  }

  @media (max-width: 768px) {
    font-size: 2.5vw;
  }

  @media (max-width: 640px) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

const NotFoundPage = () => {
  const navigate = useNavigate();

  ReactGA.event({
    category: 'error',
    action: '404',
    label: window.location.pathname,
  });

  const navigateHome = () => {
    ReactGA.event({
      category: 'error',
      action: '404',
      label: 'back to home click',
    });
    navigate('/home');
  };

  return (
    <>
      <Container>
        <Icon404 role="img" aria-label="sadface">
          &#x2639;
        </Icon404>
        <TextContainer>
          <Message404>Oops! Page not found</Message404>
          <PageNotFoundText>
            The page you are looking for may have been removed or is temporarily unavailable
          </PageNotFoundText>
          <Button onClick={navigateHome}>Back to Home</Button>
        </TextContainer>
      </Container>
    </>
  );
};

export default NotFoundPage;
