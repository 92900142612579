import React, { ReactElement, useEffect, useState } from 'react';
import DialogModal from 'components/molecules/DialogModal';
import styled, { useTheme } from 'styled-components';
import EventLogRow from 'components/molecules/EventLogRow';
import useCompanyContext from 'helpers/UseCompanyContext';
import EventLogsService from 'services/EventLogsService';
import EventLog from 'classes/eventLog';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

const EventLogs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.theme.customSpacing.px.xs}px;
`;

type RoleViewLogModalProps = {
  isOpen: boolean;
  closeHandler: () => void;
  roleId: string;
  sourceComponent: string;
  modalTitle?: string;
};

const RoleViewLogModal = ({
  isOpen,
  closeHandler,
  roleId,
  sourceComponent,
  modalTitle = 'Role Change Logs',
}: RoleViewLogModalProps): ReactElement => {
  const theme = useTheme();
  const { companyId } = useCompanyContext();
  const [events, setEvents] = useState<EventLog[][]>([[]]);
  const [loadingEvents, setLoadingEvents] = useState(true);

  useEffect(() => {
    if (!isOpen) return;
    (async () => {
      try {
        setLoadingEvents(true);
        const companyEventLogs = await EventLogsService.getObjectEventLogs(companyId, roleId);
        const groupedEvents = companyEventLogs.reduce((prev: EventLog, curr: EventLog) => {
          const userTime = curr.eventTime + curr.eventBy;
          if (prev[userTime]) prev[userTime].push(curr);
          else prev[userTime] = [curr];
          return prev;
        }, {});
        setEvents(groupedEvents);
      } catch (e) {
        console.error('Error loading events', e);
      } finally {
        setLoadingEvents(false);
      }
    })();
  }, [isOpen]);

  return (
    <DialogModal
      dialogOpen={isOpen}
      title={modalTitle}
      fullWidth={true}
      maxWidth={'lg'}
      content={
        <EventLogs data-cy={`${sourceComponent}-role-viewlog-modal-event-log-section`}>
          {loadingEvents && <Skeleton height={32} width={'100%'} />}
          {!loadingEvents && Object.keys(events).length === 0 && (
            <Typography color={theme.colors.text.disabled}>{'No event logs for this role'}</Typography>
          )}
          {!loadingEvents &&
            Object.keys(events).length > 0 &&
            Object.values(events).map((event, idx) => <EventLogRow key={idx} events={event} />)}
        </EventLogs>
      }
      closeModal={closeHandler}
      buttonGroup={[
        {
          text: 'Close',
          variant: 'outlined',
          onClickEvent: closeHandler,
          dataCy: `${sourceComponent}-role-viewlog-modal-close-button`,
        },
      ]}
      dataCy={`${sourceComponent}-role-viewlog-modal`}
    />
  );
};

export default RoleViewLogModal;
