import styled from 'styled-components';

const MainContentPage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 400px;
`;

export default MainContentPage;
