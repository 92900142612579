import React, { ReactElement, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import type {
  IntegrationCredentials,
  Options,
  SubOption,
  IntegrationMappings,
  IntegrationSchedule,
  VendorCompanyConfiguration,
  IntegrationScheduleType,
  IntegrationScheduleJobType,
  IntegrationSettingType,
} from 'types/types';
import IntegrationCredentialsForm from 'components/organisms/IntegrationCredentialsForm';
import IntegrationSchedulingForm from 'components/organisms/IntegrationSchedulingForm';
import MenuOptionItemExpanded from 'components/molecules/MenuOptionItemExpanded';
import ContentColumn2Template from 'pages/templates/ContentColumn2Template';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import ToggleOptionsMenu from 'components/organisms/ToggleOptionsMenu';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import NoDataPlaceholder from 'components/atoms/NoDataPlaceholder';
import useIntegrationHelper from 'helpers/useIntegrationHelper';
import useCompanyContext from 'helpers/UseCompanyContext';
import IntegrationMappingsForm from 'components/organisms/IntegrationMappingsForm';
import { toast } from 'react-toastify';

type AlertText = `${IntegrationSettingType} successfully saved!` | `Error saving ${Lowercase<IntegrationSettingType>}!`;

const Wrapper = styled.div`
  display: flex;
  padding: ${props => props.theme.customSpacing.px.base * 8}px ${props => props.theme.customSpacing.px.m}px;
  height: 100%;
  flex-direction: column;
`;

const IntegrationsSettingsPage = (): ReactElement => {
  const { companyId } = useCompanyContext();
  const {
    integrationSettings: settings,
    setIntegration,
    loadingIntegrationSettings,
    saveIntegrationCredentials,
    saveIntegrationMappings,
    saveIntegrationSchedules,
    saveIntegrationCriteria,
    getIntegrationMap,
  } = useIntegrationHelper(companyId);
  const [activeSettingExpanded, setActiveSettingExpanded] = useState<SubOption | undefined>();
  const [activeSettingParent, setActiveSettingParent] = useState<SubOption | undefined>();
  const [activeParentKey, setActiveParentKey] = useState<string>('');
  const [activeExpandedKey, setActiveExpandedKey] = useState<string[]>([]);
  const integrationSettings: Options[] = [
    {
      title: 'Integration Vendors',
      key: 'integrationVendors',
      subOption: settings,
      loadingSubOptions: loadingIntegrationSettings,
    },
  ];
  console.log(activeSettingParent);
  useEffect(() => {
    updateActiveOptions(activeParentKey, activeExpandedKey);
  }, [JSON.stringify(integrationSettings)]);

  const updateActiveOptions = (optionKey: string, subOptionKeys: string[]) => {
    const integrationOption = integrationSettings.find(integration => integration.key === optionKey);
    const vendorOption = integrationOption?.subOption.find(option => option.key === subOptionKeys[0]);
    const vendorSubOption = vendorOption?.subOptions?.find(option => option.key === subOptionKeys[1]);
    if (vendorSubOption) {
      setActiveSettingParent(vendorOption);
      setActiveSettingExpanded(vendorSubOption);
    } else if (vendorOption) {
      setActiveSettingParent(undefined);
      setActiveSettingExpanded(vendorOption);
    }
  };

  const alertToast = (alertText: AlertText) => (
    <div>
      <span data-cy={`integration-page-save-result-toast`}>
        {alertText}
      </span>
    </div>
  );

  const showAlertHandler = (settingType: IntegrationSettingType, success: boolean) => {
    let alertText;
    if (success) {
      alertText = `${settingType} successfully saved!`
      toast.success(alertToast(alertText))
    } else {
      alertText = `Error saving ${settingType.toLocaleLowerCase()}!`
      toast.error(alertToast(alertText))
    }
  }

  const DisplayElement = (): ReactElement => {
    if (activeSettingParent && activeSettingExpanded) {
      if (activeSettingExpanded.title === 'Credentials') {
        const credentials = activeSettingExpanded.optionConfiguration as IntegrationCredentials;
        return (
          <IntegrationCredentialsForm
            integrationCredentials={credentials}
            handleSave={saveIntegrationCredentialHandler}
            vendorName={activeSettingParent.title}
            vendorCompanyId={activeSettingParent?.id || ''}
            vendorId={activeSettingParent.key}
            savingIntegrationCredentials={false}
            active={activeSettingParent?.enabled || false}
            onAfterSave={showAlertHandler}
          />
        );
      }
      if (activeSettingExpanded.title === 'Field Mapping') {
        const mappings = activeSettingExpanded?.optionConfiguration.mappings as IntegrationMappings;
        return (
          <IntegrationMappingsForm
            vendorId={activeSettingParent.key}
            vendorName={activeSettingParent.title}
            integrationMappings={mappings}
            handleSave={saveIntegrationMappingHandler}
            handleCancel={cancelIntegrationMappingHandler}
            fieldMapper={getIntegrationMap(activeSettingParent.title)}
            onAfterSave={showAlertHandler}
          />
        );
      }
      if (activeSettingExpanded.title === 'Scheduling') {
        const { schedules, criteria } = activeSettingExpanded.optionConfiguration as {
          schedules: IntegrationSchedule[];
          criteria: VendorCompanyConfiguration[];
        };
        return (
          <IntegrationSchedulingForm
            vendorId={activeSettingParent.key}
            vendorName={activeSettingParent.title}
            integrationSchedules={{ schedules, criteria }}
            handleSave={saveIntegrationScheduleHandler}
            onAfterSave={showAlertHandler}
          />
        );
      }
      return <Typography>{activeSettingExpanded?.description}</Typography>;
    } else if (activeSettingExpanded) {
      return <Typography>{activeSettingExpanded.description}</Typography>;
    }
    return <></>;
  };
  const handleSubOptionClick = (optionKey: string, ...subOptionKeys: string[]) => {
    setActiveParentKey(optionKey);
    setActiveExpandedKey(subOptionKeys);
    updateActiveOptions(optionKey, subOptionKeys);
  };

  const toggleOptionSetting = (optionKey: string, subOptionKey: string, toggled: boolean) => {
    setIntegration(subOptionKey, toggled);
  };

  const saveIntegrationCredentialHandler = async (
    vendorId: string,
    integrationConfiguration: IntegrationCredentials
  ) => {
    await saveIntegrationCredentials(vendorId, integrationConfiguration);
    const setting = activeSettingExpanded || {} as SubOption;
    setting.optionConfiguration = integrationConfiguration;
    setActiveSettingExpanded(setting);
  };

  const saveIntegrationMappingHandler = async (
    vendorId: string,
    integrationMappingConfiguration: IntegrationMappings
  ) => {
    const savedIntegrationMappings = await saveIntegrationMappings(vendorId, activeSettingParent?.enabled === true, integrationMappingConfiguration);
    const setting = activeSettingExpanded || {} as SubOption;
    setting.optionConfiguration.vendorCompanyConfig = savedIntegrationMappings;
    setActiveSettingExpanded(setting);
  };

  const saveIntegrationScheduleHandler = async (
    vendorId: string,
    integrationSchedules: { job: IntegrationScheduleJobType; schedule: IntegrationScheduleType }[],
    integrationCriteria: Record<string, string>
  ) => {
    const criteria: { fieldName: string; fieldValue: string[] }[] = Object.entries(integrationCriteria)
    .map(
      ([fieldName, fieldValue]) => ({ fieldName, fieldValue: [fieldValue] })
    );
    await saveIntegrationSchedules(vendorId, integrationSchedules);
    await saveIntegrationCriteria(vendorId, criteria);
  };

  const cancelIntegrationMappingHandler = async () => {
    alert('Cancelled!');
  };

  return (
    <MainContentTemplate pageTitle="Integrations" isFullWidth={false}>
      <ContentHeaderTemplate contentTitle="Integrations" displayDivider={true} fullWidthDivider={false} />
        <Wrapper>
          { (settings.length < 1) && ('loading...')}
          { (settings.length > 0) && (
            <ContentColumn2Template
              sideComponent={
                <ToggleOptionsMenu
                  activeOptionExpanded={activeSettingExpanded}
                  menuOptions={integrationSettings}
                  handleSubOptionClick={handleSubOptionClick}
                  handleToggleSetting={toggleOptionSetting}
                  dataCy="integration"
                />
              }
              mainComponent={
                activeSettingExpanded ? (
                  <MenuOptionItemExpanded
                    itemHeader={activeSettingParent ? '' : activeSettingExpanded.title}
                    itemDescription={<DisplayElement />}
                  />
                ) : (
                  <NoDataPlaceholder dataCy={'integration-setting-click-integration-message-container'}>
                    <Typography data-cy={'integration-setting-click-integration-message'}>
                      {'Click a option to see expanded integration options'}
                    </Typography>
                  </NoDataPlaceholder>
                )
              }
              mainComponentPosition="right"
            />
          )}
        </Wrapper>
    </MainContentTemplate>
  );
};

export default IntegrationsSettingsPage;
