import React, { ReactElement, useState } from 'react';
import SelectIcon from 'components/atoms/icons/Select';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';

type SkillProfileEditSuggestedSkillsContentProps = {
  filters: { id: string; name: string }[];
  activeFilter: { id: string; name: string };
  setFilter: (filterId: string) => void;
  sortBy: { id: string; name: string }[];
  activeSortBy: { id: string; name: string };
  setActiveSortBy: (id: string) => void;
};
const SkillProfileEditSuggestedSkillsContent = ({
  filters,
  activeFilter,
  setFilter,
  sortBy,
  setActiveSortBy,
}: SkillProfileEditSuggestedSkillsContentProps): ReactElement => {
  const [activeFilterIndex, setActiveFilterIndex] = useState(
    filters.findIndex(filter => filter.id === activeFilter.id)
  );
  const [activeSkillsByFilterIndex, setActiveSkillsByFilterIndex] = useState(0);

  const IconComponent = props => {
    return (
      <SvgIcon {...props}>
        <SelectIcon />
      </SvgIcon>
    );
  };

  const updateFilter = (index: number) => {
    setFilter(filters[index].id);
    setActiveFilterIndex(index);
  };
  const updateSortBy = (index: number) => {
    setActiveSortBy(sortBy[index].id);
    setActiveSkillsByFilterIndex(index);
  };
  return (
    <Grid container direction="row" display="flex" style={{ width: '100%' }} spacing={1}>
      <Grid item display="flex" alignItems="center" maxWidth="400px">
        <Select
          value={activeFilterIndex}
          autoWidth
          size="small"
          IconComponent={IconComponent}
          onChange={e => {
            updateFilter(e.target.value as number);
          }}
          sx={{ maxWidth: '100%' }}
        >
          {filters.map((item, index) => {
            return (
              <MenuItem key={item.id} value={index}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item display="flex" alignItems="center" maxWidth="400px">
        <Select
          value={activeSkillsByFilterIndex}
          autoWidth
          size="small"
          IconComponent={IconComponent}
          onChange={e => {
            updateSortBy(e.target.value as number);
          }}
          sx={{ maxWidth: '100%' }}
        >
          {sortBy.map((item, index) => {
            return (
              <MenuItem key={item.id} value={index}>
                Sort By {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
    </Grid>
  );
};

export default SkillProfileEditSuggestedSkillsContent;
