class EventLog {
  constructor(eventLogJson = {}) {
    this.data = eventLogJson;
  }

  get logId() {
    return this.data.logId;
  }

  get customerId() {
    return this.data.customerId;
  }

  get eventObjectId() {
    return this.data.eventObjectId;
  }

  get eventObjectType() {
    return this.data.eventObjectType;
  }

  get eventType() {
    return this.data.eventType;
  }

  get eventField() {
    return this.data.eventField;
  }

  get beforeChange() {
    return this.data.beforeChange;
  }

  get afterChange() {
    return this.data.afterChange;
  }

  get userId() {
    return this.data.userId;
  }

  get eventBy() {
    return this.data.eventBy;
  }

  get eventTime() {
    return this.data.eventTime;
  }

  get createdAt() {
    return this.data.createdAt || 'n/a';
  }

  get updatedAt() {
    return this.data.updatedAt || 'n/a';
  }

  getReadableEventType() {
    const eventTypePastTense =
      this.eventType[this.eventType.length - 1] === 'e' ? `${this.eventType}d` : `${this.eventType}ed`;
    return `${this.splitWord(this.eventField).toLowerCase()} ${eventTypePastTense}`;
  }

  splitWord(word) {
    const words = word?.match(/[A-Za-z][a-z]*/g) || [];
    return words?.join(' ');
  }

  isCreated() {
    return this.eventType.toUpperCase() === 'CREATE';
  }

  isDeleted() {
    return this.eventType.toUpperCase() === 'DELETE';
  }

  isRemoveEventType() {
    return this.eventType.toUpperCase() === 'REMOVE';
  }

  isAddEventType() {
    return this.eventType.toUpperCase() === 'ADD';
  }

  isCreateEventType() {
    return this.eventType.toUpperCase() === 'CREATE';
  }

  isRequireSkillEvent() {
    return this.isSkillEvent() && this.eventType.toUpperCase() === 'REQUIRE';
  }

  isUnrequireSkillEvent() {
    return this.isSkillEvent() && this.eventType.toUpperCase() === 'UNREQUIRE';
  }

  isSkillEvent() {
    return this.eventField?.length > 0 && this.eventField.toUpperCase() === 'SKILL';
  }

  getEventText(change) {
    if (this.eventField === 'skill') {
      return change[0].skillName;
    }
    if (this.eventField === 'title') {
      return change[0].emsiTitleName;
    }
    if (this.eventField.includes('benchmark')) {
      return change[0].name;
    }
    if (Array.isArray(change)) {
      return change.join(', ');
    }
    if (typeof change === 'object') {
      return change[this.eventField]?.toString() === null ? 'false' : change[this.eventField]?.toString();
    }
    return change;
  }

  getReadableTo() {
    return this.afterChangeNull() || this.isDeleted()
      ? 'n/a'
      : this.isCreated()
        ? this.afterChange.roleName
        : this.getEventText(this.afterChange);
  }

  getReadableFrom() {
    return this.beforeChangeNull() || this.isCreated()
      ? 'n/a'
      : this.isDeleted()
        ? this.beforeChange.roleName
        : this.getEventText(this.beforeChange);
  }

  getReadableSkillChanges(rawChangeJson) {
    const skillChanges = rawChangeJson.reduce((skillRes, skill) => {
      return `${skillRes} ${skill.skillName} with required: ${skill.required},`;
    }, '');

    return skillChanges.slice(0, -1); // remove trailing comma
  }

  beforeChangeNull() {
    return !this.beforeChange;
  }

  afterChangeNull() {
    return !this.afterChange;
  }

  isRole() {
    return this.eventObjectType.toUpperCase() === 'ROLE';
  }
}

export default EventLog;
