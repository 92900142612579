import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import Typography from '@mui/material/Typography';
import Role from 'classes/role';
import RoleConfidenceLevelLabel from 'components/molecules/RoleConfidenceLevelLabel';
import Skeleton from '@mui/material/Skeleton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.customSpacing.px.base * 4}px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MappingDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.customSpacing.px.base}px;
`;

const TitleHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.customSpacing.px.xxs}px;
  height: ${props => props.theme.customSpacing.px.base * 9}px;
`;

type RoleMappingMappedFieldsProps = {
  role?: Role;
  loading?: boolean;
  reviewed?: boolean;
  mappingConfidenceLevel?: string;
  showHeader?: boolean;
};

const RoleMappingMappedFields = ({
  role = new Role({}),
  loading = false,
  reviewed = false,
  mappingConfidenceLevel = 'User Selected',
  showHeader = true,
}: RoleMappingMappedFieldsProps): ReactElement => {
  const theme = useTheme();
  const title = role.emsiTitleName;
  const occupation = `${role.socName} (${role.soc})`;
  const specializedOccupation = role.specializedOccupationName;

  const loadingSkeleton = (
    <Skeleton width={'100%'}>
      <Typography>{'.'}</Typography>
    </Skeleton>
  );

  const mappedDataItem = (itemName?: string, itemValue?: string) => {
    return (
      <>
        <MappingDataContainer>
          <Typography
            color={theme.colors.text.default}
            data-cy={`role-mapping-${itemName?.replace(/\s+/g, '-').toLowerCase()}-header`}
          >
            {itemName}
          </Typography>
          {loading ? (
            loadingSkeleton
          ) : itemValue && !itemValue.includes('n/a') ? (
            <Typography
              color={theme.colors.text.disabled}
              data-cy={`role-mapping-${itemName?.replace(/\s+/g, '-').toLowerCase()}-value`}
            >
              {itemValue}
            </Typography>
          ) : (
            <Typography
              color={theme.colors.text.warning}
              data-cy={`role-mapping-${itemName?.replace(/\s+/g, '-').toLowerCase()}-value`}
            >
              {`${itemName} not mapped`}
            </Typography>
          )}
        </MappingDataContainer>
      </>
    );
  };

  return (
    <>
      <Wrapper data-cy={'role-mapping-mapped-fields-section'}>
        {showHeader && (
          <HeaderWrapper>
            <Typography variant={'strong'} data-cy={'role-mapping-mapped-fields-header'}>
              {'Role Mapping'}
            </Typography>
          </HeaderWrapper>
        )}
        <MappingDataContainer>
          <TitleHeaderContainer>
            <Typography color={theme.colors.text.default} data-cy={'role-mapping-title-header'}>
              {'Title'}
            </Typography>
            {!loading && !reviewed && mappingConfidenceLevel !== 'User Selected' && (
              <RoleConfidenceLevelLabel
                level={mappingConfidenceLevel}
                value={role.mappingConfidence}
                prefix={'Confidence: '}
              />
            )}
          </TitleHeaderContainer>
          {loading ? (
            loadingSkeleton
          ) : title && !title.includes('n/a') ? (
            <Typography color={theme.colors.text.disabled} data-cy={'role-mapping-title-value'}>
              {title}
            </Typography>
          ) : (
            <Typography color={theme.colors.text.warning} data-cy={'role-mapping-title-value'}>
              {'Title not mapped'}
            </Typography>
          )}
        </MappingDataContainer>
        {mappedDataItem('SOC', occupation)}
        {mappedDataItem('Specialized Occupation', specializedOccupation)}
      </Wrapper>
    </>
  );
};

export default RoleMappingMappedFields;
