import { useContext } from 'react';
import { AuthenticationContext, AuthenticationContextState } from 'contexts/AuthenticationContext';
import { Auth } from '@aws-amplify/auth';

// eslint-disable-next-line
const useAuthContext = () => {
  const [state, setState] = useContext(AuthenticationContext);
  const userGroups = state.groups || [];

  const logOut = () => {
    return new Promise<void>(resolve => {
      Auth.signOut().then(() => {
        setState({ isLoggedIn: false, loaded: true });
        window.sessionStorage.clear();
        window.localStorage.clear();
        resolve();
      });
    });
  };

  const setIsLoggingIn = (loggingIn: boolean) => {
    setState((state: AuthenticationContextState) => ({ ...state, isAttemptingLogin: loggingIn }));
  };

  const isEmsiAdmin = () => {
    return userGroups.includes('emsi_super_users');
  };

  const isCustomerSupport = () => {
    return userGroups.includes('emsi_customer_support') || isEmsiAdmin();
  };

  const isAdmin = () => {
    return userGroups.includes('company_administrators') || isCustomerSupport();
  };

  const isAnalystReadOnly = () => {
    return userGroups.includes('analysts_read_only');
  };

  const isAnalyst = () => {
    return userGroups.includes('analysts') || isAnalystReadOnly() || isAdmin();
  };

  const triggerReload = () => {
    setState(state => ({ ...state, refreshToken: Math.random() }));
  };

  return {
    loggedIn: state.isLoggedIn || false,
    loggingIn: state.isAttemptingLogin || false,
    authLoaded: state.loaded || false,
    idToken: state.idToken,
    userId: state.userId,
    setIsLoggingIn,
    logOut,
    isAdmin,
    isCustomerSupport,
    isEmsiAdmin,
    isAnalyst,
    isAnalystReadOnly,
    triggerReload,
  };
};

export default useAuthContext;
