import { TypographyOptions } from '@mui/material/styles/createTypography';
import { DefaultTheme } from 'styled-components';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    displayXLarge: true;
    displayLarge: true;
    displayMedium: true;
    displaySmall: true;
    heading: true;
    subHeading: true;
    body: true;
    strong: true;
    button: true;
    caption: true;
    body1: false;
    body2: false;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  displayXLarge: React.CSSProperties;
  displayLarge: React.CSSProperties;
  displayMedium: React.CSSProperties;
  displaySmall: React.CSSProperties;
  heading: React.CSSProperties;
  subHeading: React.CSSProperties;
  body: React.CSSProperties;
  strong: React.CSSProperties;
}

const typographyOptionsOverride = (gs: DefaultTheme): any =>
  ({
    fontFamily: gs.customTypography.fontFamily,
    fontSize: 16,
    // Following are default variant names but styles align to indicated Figma Typography
    h1: {
      // displayXLarge
      fontFamily: gs.customTypography.fontFamily,
      fontSize: gs.customTypography.desktop.displayXLarge.size,
      fontWeight: gs.customTypography.desktop.displayXLarge.weight,
      lineHeight: gs.customTypography.desktop.displayXLarge.lineHeight,
      letterSpacing: gs.customTypography.desktop.displayXLarge.letterSpacing,
      '@media (max-width:768px)': {
        fontSize: gs.customTypography.mobile.displayXLarge.size,
        lineHeight: gs.customTypography.mobile.displayXLarge.lineHeight,
      },
    },
    h2: {
      // displayLarge
      fontFamily: gs.customTypography.fontFamily,
      fontSize: gs.customTypography.desktop.displayLarge.size,
      fontWeight: gs.customTypography.desktop.displayLarge.weight,
      lineHeight: gs.customTypography.desktop.displayLarge.lineHeight,
      letterSpacing: gs.customTypography.desktop.displayLarge.letterSpacing,
      '@media (max-width:768px)': {
        fontSize: gs.customTypography.mobile.displayLarge.size,
        lineHeight: gs.customTypography.mobile.displayLarge.lineHeight,
      },
    },
    h3: {
      // displayMedium
      fontFamily: gs.customTypography.fontFamily,
      fontSize: gs.customTypography.desktop.displayMedium.size,
      fontWeight: gs.customTypography.desktop.displayMedium.weight,
      lineHeight: gs.customTypography.desktop.displayMedium.lineHeight,
      '@media (max-width:768px)': {
        fontSize: gs.customTypography.mobile.displayMedium.size,
        lineHeight: gs.customTypography.mobile.displayMedium.lineHeight,
      },
    },
    h4: {
      // displaySmall
      fontFamily: gs.customTypography.fontFamily,
      fontSize: gs.customTypography.desktop.displaySmall.size,
      fontWeight: gs.customTypography.desktop.displaySmall.weight,
      lineHeight: gs.customTypography.desktop.displaySmall.lineHeight,
    },
    h5: {
      // heading
      fontFamily: gs.customTypography.fontFamily,
      fontSize: gs.customTypography.desktop.heading.size,
      fontWeight: gs.customTypography.desktop.heading.weight,
      lineHeight: gs.customTypography.desktop.heading.lineHeight,
    },
    h6: undefined,
    subtitle1: {
      // subheading
      fontFamily: gs.customTypography.fontFamily,
      fontSize: gs.customTypography.desktop.subHeading.size,
      fontWeight: gs.customTypography.desktop.subHeading.weight,
      lineHeight: gs.customTypography.desktop.subHeading.lineHeight,
      letterSpacing: gs.customTypography.desktop.subHeading.letterSpacing,
      textTransform: gs.customTypography.desktop.subHeading.textTransform,
      '@media (max-width:768px)': {
        fontWeight: gs.customTypography.mobile.subHeading.weight,
        fontSize: gs.customTypography.mobile.subHeading.size,
        lineHeight: gs.customTypography.mobile.subHeading.lineHeight,
      },
    },
    subtitle2: undefined,
    body1: {
      // body
      fontFamily: gs.customTypography.fontFamily,
      fontSize: gs.customTypography.desktop.body.size,
      fontWeight: gs.customTypography.desktop.body.weight,
      lineHeight: gs.customTypography.desktop.body.lineHeight,
      '@media (max-width:768px)': {
        fontSize: gs.customTypography.mobile.body.size,
      },
    },
    body2: {
      // strong
      fontFamily: gs.customTypography.fontFamily,
      fontSize: gs.customTypography.desktop.strong.size,
      fontWeight: gs.customTypography.desktop.strong.weight,
      lineHeight: gs.customTypography.desktop.strong.lineHeight,
      '@media (max-width:768px)': {
        fontSize: gs.customTypography.mobile.strong.size,
      },
    },
    button: {
      fontSize: gs.customTypography.desktop.button.size,
      fontWeight: gs.customTypography.desktop.button.weight,
      lineHeight: gs.customTypography.desktop.button.lineHeight,
      textTransform: 'capitalize',
      '@media (max-width:768px)': {
        fontSize: gs.customTypography.mobile.button.size,
      },
    },
    // Below are custom Typography variants, but can only be used with @mui/material
    displayXLarge: {
      fontFamily: gs.customTypography.fontFamily,
      fontSize: gs.customTypography.desktop.displayXLarge.size,
      fontWeight: gs.customTypography.desktop.displayXLarge.weight,
      lineHeight: gs.customTypography.desktop.displayXLarge.lineHeight,
      letterSpacing: gs.customTypography.desktop.displayXLarge.letterSpacing,
      '@media (max-width:768px)': {
        fontSize: gs.customTypography.mobile.displayXLarge.size,
        lineHeight: gs.customTypography.mobile.displayXLarge.lineHeight,
      },
    },
    displayLarge: {
      fontFamily: gs.customTypography.fontFamily,
      fontSize: gs.customTypography.desktop.displayLarge.size,
      fontWeight: gs.customTypography.desktop.displayLarge.weight,
      lineHeight: gs.customTypography.desktop.displayLarge.lineHeight,
      letterSpacing: gs.customTypography.desktop.displayLarge.letterSpacing,
      '@media (max-width:768px)': {
        fontSize: gs.customTypography.mobile.displayLarge.size,
        lineHeight: gs.customTypography.mobile.displayLarge.lineHeight,
      },
    },
    displayMedium: {
      fontFamily: gs.customTypography.fontFamily,
      fontSize: gs.customTypography.desktop.displayMedium.size,
      fontWeight: gs.customTypography.desktop.displayMedium.weight,
      lineHeight: gs.customTypography.desktop.displayMedium.lineHeight,
      '@media (max-width:768px)': {
        fontSize: gs.customTypography.mobile.displayMedium.size,
        lineHeight: gs.customTypography.mobile.displayMedium.lineHeight,
      },
    },
    displaySmall: {
      fontFamily: gs.customTypography.fontFamily,
      fontSize: gs.customTypography.desktop.displaySmall.size,
      fontWeight: gs.customTypography.desktop.displaySmall.weight,
      lineHeight: gs.customTypography.desktop.displaySmall.lineHeight,
    },
    heading: {
      fontFamily: gs.customTypography.fontFamily,
      fontSize: gs.customTypography.desktop.heading.size,
      fontWeight: gs.customTypography.desktop.heading.weight,
      lineHeight: gs.customTypography.desktop.heading.lineHeight,
    },
    subHeading: {
      fontFamily: gs.customTypography.fontFamily,
      fontSize: gs.customTypography.desktop.subHeading.size,
      fontWeight: gs.customTypography.desktop.subHeading.weight,
      lineHeight: gs.customTypography.desktop.subHeading.lineHeight,
      letterSpacing: gs.customTypography.desktop.subHeading.letterSpacing,
      textTransform: gs.customTypography.desktop.subHeading.textTransform,
      '@media (max-width:768px)': {
        fontWeight: gs.customTypography.mobile.subHeading.weight,
        fontSize: gs.customTypography.mobile.subHeading.size,
        lineHeight: gs.customTypography.mobile.subHeading.lineHeight,
      },
    },
    body: {
      fontFamily: gs.customTypography.fontFamily,
      fontSize: gs.customTypography.desktop.body.size,
      fontWeight: gs.customTypography.desktop.body.weight,
      lineHeight: gs.customTypography.desktop.body.lineHeight,
      '@media (max-width:768px)': {
        fontSize: gs.customTypography.mobile.body.size,
      },
    },
    strong: {
      fontFamily: gs.customTypography.fontFamily,
      fontSize: gs.customTypography.desktop.strong.size,
      fontWeight: gs.customTypography.desktop.strong.weight,
      lineHeight: gs.customTypography.desktop.strong.lineHeight,
      '@media (max-width:768px)': {
        fontSize: gs.customTypography.mobile.strong.size,
      },
    },
    caption: {
      fontFamily: gs.customTypography.fontFamily,
      fontSize: gs.customTypography.desktop.caption.size,
      fontWeight: gs.customTypography.desktop.caption.weight,
      lineHeight: gs.customTypography.desktop.caption.lineHeight,
      '@media (max-width:768px)': {
        fontSize: gs.customTypography.mobile.caption.size,
        fontWeight: gs.customTypography.mobile.caption.weight,
        lineHeight: gs.customTypography.mobile.caption.lineHeight,
      },
    },
    colorTransformations: {
      interactiveDefault: 'interactive.default',
    },
  }) as ExtendedTypographyOptions;

export default typographyOptionsOverride;
