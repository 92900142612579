import React, { useState, ReactElement, MouseEventHandler } from 'react';
import { useField, useFormikContext } from 'formik';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import styled, { useTheme } from 'styled-components';
import InputRow from 'components/atoms/InputRow';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { IconColorFilter, SpacingSizes } from 'types/types';
import Popover from '@mui/material/Popover';

interface TextFieldProps {
  name: string;
  value?: string;
  id?: string;
  label?: string;
  labelActionElement?: ReactElement;
  placeholder?: string;
  loading?: boolean;
  required?: boolean;
  readOnly?: boolean;
  type?: 'text' | 'number' | 'password';
  displayAsTextArea?: boolean;
  textAreaProps?: TextAreaFieldProps;
  informationText?: string;
  tabIndex?: number;
  handleInputTextUpdate?: any;
  autoFocus?: boolean;
  onFocus?: any;
  removeBottomMargin?: boolean;
  dataCy?: string;
  startElement?: ReactElement;
  endElement?: ReactElement;
  tooltipText?: string;
  tooltipIconColor?: 'Default' | 'Information' | 'Warning' | 'Critical';
}

interface TextAreaFieldProps {
  minRows?: number;
  maxRows?: number;
}

const CustomInputLabel = styled(InputLabel)`
  display: flex;

  .MuiInputLabel-asterisk {
    color: ${props => props.theme.colors.text.critical};
  }
`;

const CustomOutlinedInput = styled(OutlinedInput)`
  background-color: ${props => props.theme.colors.surface.default};
  padding: ${props => props.theme.customSpacing.px.none}px;

  .MuiInputBase-input {
    padding: ${props => props.theme.customSpacing.px.xxs}px ${props => props.theme.customSpacing.px.xs}px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${props => props.theme.colors.border.default};
  }

  &.Mui-focused {
    background-color: ${props => props.theme.colors.surface.default};

    .MuiOutlinedInput-notchedOutline {
      border: 2px solid ${props => props.theme.colors.decorative.focus};
    }
  }

  &.Mui-error {
    background-color: ${props => props.theme.colors.surface.criticalSubdued};

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${props => props.theme.colors.border.critical};
    }
  }

  &.Mui-disabled {
    background-color: ${props => props.theme.colors.surface.disabled};

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${props => props.theme.colors.border.disabled};
    }
  }

  .MuiInputAdornment-positionStart {
    padding-left: ${props => props.theme.customSpacing.px.xs}px;
  }

  .MuiInputBase-inputAdornedStart {
    padding-left: ${props => props.theme.customSpacing.px.none}px;
  }

  .MuiInputAdornment-positionEnd {
    padding-right: ${props => props.theme.customSpacing.px.xs}px;
  }

  .MuiInputBase-inputAdornedEnd {
    padding-right: ${props => props.theme.customSpacing.px.none}px;
  }
`;

const CustomFormHelperText = styled(FormHelperText)`
  margin: 0px;
  color: ${props => props.theme.colors.text.subdued};

  .Mui-error {
    color: ${props => props.theme.colors.text.critical};
  }
`;

const PopoverIconContainer = styled.div`
  display: flex;
  div {
    cursor: pointer;
  }
`;

type InputFieldLabelProps = {
  label?: string;
  id?: string;
  required?: boolean;
  labelActionElement?: ReactElement;
  displayTooltip?: boolean;
  handlePopoverOpen?: MouseEventHandler;
  handlePopoverClose?: MouseEventHandler;
  tooltipIconColor: 'Default' | 'Information' | 'Warning' | 'Critical';
};

const InputFieldLabelSection = ({
  label,
  id,
  required,
  labelActionElement,
  displayTooltip = false,
  handlePopoverOpen,
  handlePopoverClose,
  tooltipIconColor,
}: InputFieldLabelProps) => {
  const theme = useTheme();
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <CustomInputLabel htmlFor={id} required={required}>
        <Typography variant="body" color={theme.colors.text.default}>
          {label}
        </Typography>
        {displayTooltip && (
          <PopoverIconContainer
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            data-cy={`tooltip-icon-${label}`}
          >
            <BaseIcon
              type={IconType.InformationFill}
              marginLeft={SpacingSizes.Base}
              marginRight={SpacingSizes.None}
              colorFilter={IconColorFilter[`${tooltipIconColor}`]}
            />
          </PopoverIconContainer>
        )}
      </CustomInputLabel>
      {labelActionElement}
    </div>
  );
};

type FormHelperTextProps = {
  dataCy: string;
  helperText?: string;
  error?: boolean;
};

const FormHelperTextSection = ({ dataCy, helperText, error = false }: FormHelperTextProps) => {
  const theme = useTheme();
  return (
    <div style={{ display: 'flex', marginTop: `${theme.customSpacing.px.base}px` }}>
      {
        <div style={{ display: `${error ? 'block' : 'none'}` }}>
          <BaseIcon
            type={IconType.ErrorWarningFill}
            colorFilter={IconColorFilter.Critical}
            marginLeft={SpacingSizes.None}
          />
        </div>
      }
      {
        <CustomFormHelperText error={error} data-cy={dataCy}>
          {helperText}
        </CustomFormHelperText>
      }
    </div>
  );
};

const FormikTextField = ({
  name,
  value = '',
  id = name,
  label = '',
  labelActionElement,
  placeholder = '',
  loading = false,
  required = false,
  readOnly = false,
  type = 'text',
  displayAsTextArea = false,
  textAreaProps = { minRows: 4, maxRows: 8 },
  informationText = '',
  tabIndex,
  handleInputTextUpdate,
  autoFocus = false,
  onFocus,
  removeBottomMargin = false,
  dataCy = name,
  startElement,
  endElement,
  tooltipText,
  tooltipIconColor = 'Default',
}: TextFieldProps): ReactElement => {
  const theme = useTheme();
  const [field, meta, helpers] = useField({ name, value });
  const [tooltipIconAnchor, setTooltipIconAnchor] = useState<HTMLElement | null>(null);

  const form = useFormikContext();
  const { setTouched } = helpers;
  const inputTextUpdated = () => {
    handleInputTextUpdate && handleInputTextUpdate(name, meta, field, form);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setTooltipIconAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setTooltipIconAnchor(null);
  };

  return (
    <>
      {name && (
        <>
          <InputRow className="input-row" style={{ marginBottom: removeBottomMargin ? '0px' : undefined }}>
            <InputFieldLabelSection
              label={label}
              id={id}
              required={required}
              labelActionElement={labelActionElement}
              displayTooltip={!!tooltipText}
              handlePopoverOpen={handlePopoverOpen}
              handlePopoverClose={handlePopoverClose}
              tooltipIconColor={tooltipIconColor}
            />
            {loading ? (
              <Skeleton width={'100%'}>
                <CustomOutlinedInput />
              </Skeleton>
            ) : (
              <CustomOutlinedInput
                placeholder={placeholder}
                required={required}
                disabled={readOnly}
                type={type}
                fullWidth={true}
                tabIndex={tabIndex}
                {...field}
                onBlur={() => {
                  setTouched(true);
                  inputTextUpdated();
                }}
                multiline={displayAsTextArea}
                minRows={textAreaProps.minRows}
                maxRows={textAreaProps.maxRows}
                onFocus={onFocus}
                autoFocus={autoFocus}
                error={meta.touched && meta.error !== undefined}
                startAdornment={startElement && <InputAdornment position="start">{startElement}</InputAdornment>}
                endAdornment={endElement && <InputAdornment position="end">{endElement}</InputAdornment>}
                inputProps={{
                  'data-cy': dataCy,
                }}
              />
            )}
            {meta.touched && meta.error && meta.error !== '*' && (
              <FormHelperTextSection dataCy={`${dataCy}-validation-text`} helperText={meta.error} error={true} />
            )}
            {informationText && (
              <FormHelperTextSection dataCy={`${dataCy}-information-text`} helperText={informationText} />
            )}
          </InputRow>
          <Popover
            anchorEl={tooltipIconAnchor}
            open={!!tooltipIconAnchor}
            onClose={handlePopoverClose}
            sx={{ pointerEvents: 'none' }}
            disableScrollLock={true}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Typography
              variant={'caption'}
              sx={{ maxWidth: '220px', padding: `${theme.customSpacing.px.xs}px` }}
              component={'div'}
              data-cy={`tooltip-message-${label}`}
            >
              {tooltipText}
            </Typography>
          </Popover>
        </>
      )}
    </>
  );
};

export default FormikTextField;
