import React, { useEffect, useState } from 'react';
import useCompanyContext from 'helpers/UseCompanyContext';
import Role from 'classes/role';
import { Skill } from 'classes/skillProfile';
import RolesService from 'services/RolesService';
import { Benchmark, ExtractedSkill, SuggestedSkill, SkillSuggestionBy } from 'types/types';
import ReactGA from 'react-ga';

const SKILL_TYPES = [
  { id: 'ST0', name: 'All' },
  { id: 'ST1', name: 'Specialized Skill' },
  { id: 'ST2', name: 'Common Skill' },
  { id: 'ST3', name: 'Certification' },
];

const SORT_BY = [
  { id: 'All', name: 'All' },
  { id: 'Job Description', name: 'Job Description' },
  { id: 'Benchmark', name: 'Benchmark' },
  { id: 'Recommended', name: 'Occupation' },
];

export interface SkillProfileContextState {
  previousState: any;
  role?: Role;
  isLoadingSkillProfile?: boolean;
  isSavingSkillProfile?: boolean;
  roleSkills?: Skill[];
  skillsBy?: SkillSuggestionBy;
  updatedDescription?: string;
  skillsFromDescription?: ExtractedSkill[];
  skillsFromDescriptionNormalized?: SuggestedSkill[];
  isSkillsExtracting?: boolean;
  suggestedSkills?: SuggestedSkill[];
  isLoadingSuggestedSkills?: {
    [SkillSuggestionBy.JobDescription]?: boolean;
    [SkillSuggestionBy.Benchmark]?: boolean;
    [SkillSuggestionBy.Recommended]?: boolean;
  };
  descriptionOverride?: {
    isOverride: boolean;
    description: string;
  };
  tempRoleSkills?: Array<Skill | SuggestedSkill>;
  benchmark?: Benchmark;
  totalCompanyPostings?: number;
  companySkills?: any[];
  isUsingBenchmarkNationwide: boolean;
  SkillTypes: { id: string; name: string }[];
  skillTypeFilter: { id: string; name: string };
  sortBy: { id: string; name: string }[];
  activeSortBy: { id: string; name: string };
}

const defaultSkillProfileState: SkillProfileContextState = {
  previousState: undefined,
  role: undefined,
  isLoadingSkillProfile: false,
  isSavingSkillProfile: false,
  roleSkills: undefined,
  skillsBy: SkillSuggestionBy.JobDescription,
  updatedDescription: undefined,
  skillsFromDescription: [],
  skillsFromDescriptionNormalized: [],
  isSkillsExtracting: false,
  suggestedSkills: undefined,
  isLoadingSuggestedSkills: undefined,
  tempRoleSkills: undefined,
  benchmark: undefined,
  isUsingBenchmarkNationwide: false,
  SkillTypes: SKILL_TYPES,
  skillTypeFilter: SKILL_TYPES[0],
  sortBy: SORT_BY,
  activeSortBy: SORT_BY[0],
  totalCompanyPostings: 0,
  companySkills: [],
};

interface SkillProfileContextProviderProps {
  roleId: string;
  skillsBy?: SkillSuggestionBy;
  children?: React.ReactElement;
}

const SkillProfileContext = React.createContext<[SkillProfileContextState, React.Dispatch<any>]>([
  defaultSkillProfileState,
  () => undefined,
]);

const SkillProfileContextProvider: React.FC<SkillProfileContextProviderProps> = props => {
  const { companyId } = useCompanyContext();
  const [state, setState] = useState<SkillProfileContextState>(defaultSkillProfileState);

  useEffect(() => {
    (async () => {
      setState(previousState => ({
        ...previousState,
        isLoadingSkillProfile: true,
        skillsBy: props.skillsBy,
      }));
      try {
        const role = await RolesService.getRole(companyId, props.roleId);
        setState(previousState => ({
          ...previousState,
          role,
          isLoadingSkillProfile: false,
          roleSkills: role.skills,
          tempRoleSkills: role.skills,
          totalCompanyPostings: 0,
          companySkills: [],
        }));
      } catch (e) {
        ReactGA.event({
          category: 'skill-profile-context',
          action: 'load role',
          label: 'failed',
        });
      } finally {
        setState(previousState => ({
          ...previousState,
          isLoadingSkillProfile: false,
        }));
      }
    })();
  }, [props.roleId]);

  return <SkillProfileContext.Provider value={[state, setState]}>{props.children}</SkillProfileContext.Provider>;
};

export { SkillProfileContext, SkillProfileContextProvider };
