import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

type TitleTagTooltipProps = {
  content: ReactNode;
  hoveroverTitle: string;
  className?: string;
};

const TooltipContainer = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} data-cy="title-tag" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.text.default,
    color: theme.colors.text.onPrimary,
    boxShadow: theme.boxShadow.surface1,
  },
}));

const TitleTagTooltip = ({ content, hoveroverTitle, className }: TitleTagTooltipProps): ReactElement => {
  return (
    <TooltipContainer
      title={hoveroverTitle}
      placement="right-start"
      disableHoverListener={!hoveroverTitle}
      disableFocusListener
      disableTouchListener
      className={className}
    >
      <div>{content}</div>
    </TooltipContainer>
  );
};

export default TitleTagTooltip;
