import axios from 'helpers/api_helper';
import { getPostings } from 'helpers/LaborSearchHelper';
import { Skill, SuggestedSkill, SkillDDNType } from 'types/types';
import Role from 'classes/role';

type benchmarkArea = {
  id: string;
};

type benchmarkToIdsObj = {
  locations: benchmarkArea[];
  companies: benchmarkArea[];
  industries: benchmarkArea[];
};

type benchmarkToIdsResponse = {
  locationMSAIds: string[];
  companyIds: string[];
  industryIds: string[];
};

export function benchmarkToIds(benchmark: benchmarkToIdsObj): benchmarkToIdsResponse {
  let locationMSAIds: string[] = [];
  if (benchmark.locations) {
    locationMSAIds = benchmark.locations.map(l => l.id);
  }

  let companyIds: string[] = [];
  if (benchmark.companies) {
    companyIds = benchmark.companies.map(c => c.id);
  }

  let industryIds: string[] = [];
  if (benchmark.industries) {
    industryIds = benchmark.industries.map(i => i.id);
  }

  return {
    locationMSAIds,
    companyIds,
    industryIds,
  };
}

export async function getRelatedSkills(skillId: string, limit = 5): Promise<any[]> {
  const { data } = await axios.post<{ skills: any[] }>(`${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/skills/related`, {
    skills: [skillId],
    limit,
  });

  return data.skills;
}

export async function getBenchmarkSkills(
  searchContext: any,
  limit = 50
): Promise<{ totalBenchmarkPostings: number; skills: any[] }> {
  // get the benchmark skills from JPA
  const data = await getPostings({ facetName: 'skills', searchContext, limit });
  const totalBenchmarkPostings = data.total;
  const skillHash = {};
  const skillIds: string[] = [];

  data.buckets.forEach((b: any) => {
    skillIds.push(b.name);
    skillHash[b.name] = b;
  });

  if (skillIds.length === 0) {
    return { totalBenchmarkPostings: 0, skills: [] };
  }

  // load the skill details for the JPA skills found
  const skillDetails = await getDetailsBySkillIds(skillIds);

  // extract skill details and apply data to our skills set
  let skills: any[] = skillDetails.map(s => ({
    ...s,
    name: s.name,
    id: s.id,
    rank: skillHash[s.id].unique_postings,
    relatedSkills: [],
    type: s.type,
  }));

  // sort the skills by rank
  skills = skills.sort((a, b) => b.rank - a.rank);

  return { totalBenchmarkPostings, skills };
}

// eslint-disable-next-line
export async function getCompanySkills(
  companyId: string,
  // eslint-disable-next-line
  searchContext: any
): Promise<{ totalCompanyPostings: number; skills: any }> {
  const { total: totalCompanyPostings, buckets: skills } = await getPostings({
    companyId,
    facetName: 'skills',
    searchContext,
    limit: 500,
  });
  return { totalCompanyPostings, skills };
}

const DDNSkillTypeMap = (skills, skillType): any[] => {
  const mappedDDNSkillTypes = skills.map(skill => {
    return { skillId: skill.id, skillName: skill.name, skillType };
  });
  return mappedDDNSkillTypes;
};

const formatDDNSkills = skills => {
  const formattedDDNSkills: any[] = [];

  formattedDDNSkills.push(...DDNSkillTypeMap(skills[SkillDDNType.defining], 'Defining'));
  formattedDDNSkills.push(...DDNSkillTypeMap(skills[SkillDDNType.distinguishing], 'Distinguishing'));
  formattedDDNSkills.push(...DDNSkillTypeMap(skills[SkillDDNType.necessary], 'Necessary'));

  return formattedDDNSkills;
};

export async function getRecommendedSkills(dimension: string, id: string): Promise<SuggestedSkill[]> {
  const { data } = await axios.post<{ data: any }>(`${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/ddn/${dimension}`, {
    id,
  });
  const recommendedSkills = formatDDNSkills(data);

  return recommendedSkills;
}

export function getDDNDimensionName(role?: Role): string | null {
  if (role?.specializedOccupationId) return 'gotspecocc';
  if (role?.soc) return 'soc';
  return null;
}

export function getDDNDimensionId(role?: Role): string | null {
  if (role?.specializedOccupationId) return role?.specializedOccupationId;
  if (role?.soc) return role?.soc;
  return null;
}

const getDetailsBySkillIds = async (skillIds: string[]): Promise<any[]> => {
  try {
    const skillDetailsResp = await axios.post(
      `${process.env.REACT_APP_API_ROOT}/skills/details`,
      {
        skillIds,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return skillDetailsResp.data.skills;
  } catch (error) {
    console.error('Error fetching skill details in getDetailsBySkillIds:', error);
    return [];
  }
};

export const skillDetailsEnrichment = async (skills: Skill[]): Promise<any[]> => {
  if (!skills.length) return skills;

  const skillIds = skills.map(s => s.skillId);

  try {
    const skillDetails = await getDetailsBySkillIds(skillIds);
    const enrichedSkills = skills.map(skill => {
      const skillDetail = skillDetails.find(s => s.id === skill.skillId) || {};
      const { type = null, category = null, subcategory = null, isSoftware = null } = skillDetail;

      return {
        ...skill,
        type,
        category,
        subcategory,
        isSoftware,
      };
    });

    return enrichedSkills;
  } catch (error) {
    console.error('Error fetching skill details in skillDetailsEnrichment:', error);
    return skills;
  }
};
