import styled from 'styled-components';

const InputTextArea = styled.textarea`
  width: 100%;
  padding: ${props => props.theme.customSpacing.px.base * 4}px;
  font-weight: 500;
  color: ${props => props.theme.colors.text.default};
  border-radius: ${props => props.theme.borderRadius.default};
  border: 1px solid ${props => props.theme.colors.border.default};
  height: 140px;
  &::placeholder {
    color: ${props => props.theme.colors.text.disabled};
  }
  &:focus-visible {
    outline: none;
    box-shadow: ${props => props.theme.boxShadow.textFieldFocus};
  }
`;

export default InputTextArea;
