import React, { ReactElement } from 'react';
import ReactGA from 'react-ga';
import styled, { css } from 'styled-components';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export type CheckboxListLineItemProps = {
  label: string;
  value: any;
  name?: string;
  tabIndex?: number;
  isChecked: boolean;
  isDisabled?: boolean;
  isCritical?: boolean;
  dataCy?: string;
  dataColumn1?: number | string;
  dataColumn2?: number | string;
  dataColumnsCount: 0 | 1 | 2;
  handleItemToggle?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  [x: string]: any;
};

const Wrapper = styled(Grid)`
  flex-grow: 1;
  background-color: transparent;
  transition: 0.5s;

  &.highlight {
    background-color: ${props => props.theme.colors.surface.selected};
  }
`;

const DataColumnWrapper = styled(Grid)`
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-end;
  margin-top: 0px;
`;

const DataColumn = styled(Grid)<{ $loading: boolean; $datacolumnscount?: number }>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  text-align: right;

  &.MuiGrid-item {
    padding-top: 0px;
  }

  ${props =>
    props.$datacolumnscount === 2 &&
    css`
      justify-content: center;
      text-align: center;
    `};

  ${props => props.$loading && 'opacity: 0.7;'};
`;

const CheckboxListLineItem = ({
  label,
  value,
  name = 'checkboxListItem',
  tabIndex = 0,
  isChecked = false,
  isDisabled = false,
  isCritical = true,
  dataColumn1,
  dataColumn2,
  dataColumnsCount = 2,
  dataCy,
  handleItemToggle,
}: CheckboxListLineItemProps): ReactElement => {
  const skillNameFlexWidth = (dataColumnsCount: number) => {
    switch (dataColumnsCount) {
      case 0:
        return 12;
      case 1:
        return 10;
      case 2:
        return 8;
    }
  };

  const handleItemClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    ReactGA.event({
      category: `${name}`,
      action: 'skill toggle',
      label: `${label}`,
    });

    handleItemToggle?.(event);
  };

  return (
    <>
      <Wrapper
        container
        spacing={0}
        className="checkbox-list-item"
        id={`checkbox-list-item-${value}`}
        data-cy={dataCy || `checkbox-list-item-${value}`}
      >
        <Grid container item sx={{ flexGrow: 2, flexShrink: 1 }} xs={skillNameFlexWidth(dataColumnsCount)}>
          <FormControlLabel
            label={
              <Typography
                color={isDisabled ? 'text.disabled' : 'text.default'}
                component="div"
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {label}
              </Typography>
            }
            value={value}
            checked={isChecked}
            control={
              <Checkbox
                color={isCritical ? 'iconCritical' : 'iconSuccess'}
                onChange={e => handleItemClick(e)}
                value={value}
                checked={isChecked}
                disabled={isDisabled}
                data-cy={(dataCy + '-' || '') + `checkbox-${value}`}
                tabIndex={tabIndex}
                name={name}
              />
            }
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', height: '20px' }}
          />
        </Grid>
        {dataColumnsCount > 0 && (
          <DataColumnWrapper container item xs={dataColumnsCount === 2 ? 4 : 2} spacing={0.5}>
            {dataColumn1 && (
              <DataColumn
                item
                $loading={!dataColumn1}
                $datacolumnscount={dataColumnsCount}
                className="data-column"
                data-cy={`data-column-1-${value}`}
                xs={dataColumnsCount === 2 ? 6 : 12}
              >
                <Typography variant="caption" color="text.disabled">
                  {dataColumn1}
                </Typography>
              </DataColumn>
            )}
            {dataColumn2 && dataColumnsCount === 2 && (
              <DataColumn
                item
                $loading={!dataColumn2}
                // datacolumnscount={dataColumnsCount}
                className="data-column"
                data-cy={`data-column-2-${value}`}
                xs={dataColumnsCount === 2 ? 6 : 12}
              >
                <Typography variant="caption" color="text.disabled">
                  {dataColumn2}
                </Typography>
              </DataColumn>
            )}
          </DataColumnWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default CheckboxListLineItem;
