import { DefaultTheme } from 'styled-components';

declare module '@mui/material/Select' {}

export const MuiSelectOverride = (gs: DefaultTheme): Record<'styleOverrides', any> => ({
  styleOverrides: {
    root: {},
    outlined: {
      padding: `${gs.customSpacing.px.xxs}px ${gs.customSpacing.px.xxs}px ${gs.customSpacing.px.xxs}px ${gs.customSpacing.px.xs}px`,
    },
  },
});
