import React, { ReactElement, ReactNode } from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';

const CategoryHeader = styled(Typography)`
  padding-bottom: ${props => props.theme.customSpacing.px.base * 4}px;
`;

const CategoryHeaderWrapper = styled.div`
  padding-top: ${props => props.theme.customSpacing.px.base * 4}px;
  padding-right: ${props => props.theme.customSpacing.px.xs}px;
  padding-bottom: ${props => props.theme.customSpacing.px.base * 4}px;
  padding-left: ${props => props.theme.customSpacing.px.xs}px;
`;

export type SummaryHeaderProps = {
  children?: ReactNode;
  headerTitle?: string;
};

const SummaryHeader = ({ children, headerTitle }: SummaryHeaderProps): ReactElement => {
  return (
    <CategoryHeaderWrapper>
      <CategoryHeader variant="h4">{headerTitle}</CategoryHeader>
      {children}
    </CategoryHeaderWrapper>
  );
};

export default SummaryHeader;
