import { useState } from 'react';
import useCompanyContext from 'helpers/UseCompanyContext';
import SkillProfileService from 'services/SkillProfileService';
import RolesService from 'services/RolesService';
import { RoleSkillUpdateType } from 'components/organisms/QuickUpdateSkills';
import ReactGA from 'react-ga';
import { AutocompleteSkill } from './useGetSkills';

interface RoleSkillsUpdateHook {
  roleSkillsUpdated: boolean;
  updateType: RoleSkillUpdateType | undefined;
  setUpdateType: (updateType: RoleSkillUpdateType) => void;
  setAppArea: (appArea: string) => void;
  roleSkillsUpdateInProgress: boolean;
  setRoleSkillsUpdateInProgress: (updateInProgress: boolean) => void;
  saveManuallyUpdatedSkills: (roleIds: Array<string>, skills: Array<AutocompleteSkill>) => void;
}

const useRoleSkillsUpdate = (): RoleSkillsUpdateHook => {
  const [roleSkillsUpdated, setRoleSkillsUpdated] = useState<boolean>(true);
  const [updateType, setUpdateType] = useState<RoleSkillUpdateType>();
  const [appArea, setAppArea] = useState<string>('');
  const [roleSkillsUpdateInProgress, setRoleSkillsUpdateInProgress] = useState<boolean>(false);
  const { companyId } = useCompanyContext();

  const saveManuallyUpdatedSkills = async (roleIds, skills) => {
    setRoleSkillsUpdated(false);
    const roleSkillUpdateCalls = new Array<Promise<any>>();
    const failed = new Array<string>();

    if (updateType === RoleSkillUpdateType.Add) {
      const newSkill = skills.map(skill => {
        return {
          skillId: skill?.id,
          skillName: skill?.name,
          source: skill?.source,
          customSkillId: skill.customSkillId,
        };
      });
      roleIds.forEach(roleId => {
        roleSkillUpdateCalls.push(SkillProfileService.addSkills(companyId, roleId, newSkill));
      });
    } else {
      const skillIds = skills.map(skill => {
        return skill.id;
      });
      roleIds.forEach(roleId => {
        roleSkillUpdateCalls.push(RolesService.deleteRoleSkills(companyId, roleId, skillIds));
      });
    }

    const results = await Promise.allSettled(roleSkillUpdateCalls);
    results.forEach((result, index) => {
      if (result.status === 'rejected' || result.value === false) {
        failed.push(roleIds[index]);
      }
    });

    setRoleSkillsUpdated(true);
    setRoleSkillsUpdateInProgress(false);

    const isAddType = updateType === RoleSkillUpdateType.Add;
    const successes = roleIds.filter(roleId => {
      return !failed.includes(roleId);
    });
    const skillText = skills.length > 1 ? 'skills' : 'skill';
    // Successes
    const successText = `${successes?.length > 0 ? `Successfully ${isAddType ? 'added' : 'removed'} ${skillText} for` : ''}`;
    const successRoleText = `${successes?.length > 0 && roleIds.length > 1 ? `${successes?.length} of ${roleIds.length} roles` : 'role'}`;
    const successInformationText = successes.length > 0 ? `${successText} ${successRoleText}` : '';
    // Failures
    const failureText = `${failed?.length > 0 ? `Failed to ${isAddType ? 'add' : 'remove'} ${skillText} for` : ''}`;
    const failureRoleText = `${failed?.length > 0 && roleIds.length > 1 ? `${failed?.length} of ${roleIds.length} roles` : 'role'}`;
    const failureInformationText = failed.length > 0 ? `${failureText} ${failureRoleText}` : '';
    alert(`${successInformationText}
      ${failureInformationText}`);
    ReactGA.event({
      category: appArea,
      action: isAddType ? 'addskill-manually' : 'removeskill-manually',
      label: `Failures: ${failed}, Successes: ${successes}`,
    });
  };

  return {
    roleSkillsUpdated,
    updateType,
    setUpdateType,
    setAppArea,
    roleSkillsUpdateInProgress,
    setRoleSkillsUpdateInProgress,
    saveManuallyUpdatedSkills,
  };
};

export default useRoleSkillsUpdate;
