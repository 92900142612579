import React, { ReactElement } from 'react';
import Divider from '@mui/material/Divider';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

type TopCountStatsProps = {
  items: { title: string; value: string }[];
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px;
  margin: 1px;
`;

const Container = styled(Box)`
  margin: 4px 8px 4px 8px;
  width: 180px;
  padding: 1px;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContainerDivider = styled(Divider)`
  border: ${props => `1px solid ${props.theme.colors.border.subdued}`};
  margin-right: '8px';
  margin-left: '8px';
  @media (max-width: 768px) {
    display: none;
  }
`;

function TopCountStats({ items }: TopCountStatsProps): ReactElement {
  return (
    <Wrapper>
      {items.map((item, index) => {
        return (
          <React.Fragment key={item.title + index}>
            {index % items.length !== 0 && <ContainerDivider orientation="vertical" variant="middle" flexItem />}
            <Container>
              <Typography variant={'strong'}>{item.title}</Typography>
              {item.title !== 'Total Unique Skills' ? (
                <Typography variant={'strong'}>{item.value}</Typography>
              ) : (
                <Typography variant={'strong'}>
                  <Link href="unique-skills">{item.value}</Link>
                </Typography>
              )}
            </Container>
          </React.Fragment>
        );
      })}
    </Wrapper>
  );
}

export default TopCountStats;
