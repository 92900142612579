import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import SearchBarEmbeddedPill from 'components/atoms/SearchBarEmbeddedPill';
import ComplexInputBar from 'components/molecules/ComplexInputBar';
import FacetOptionsBox from 'components/molecules/FacetOptionsBox';
import { UILayers } from 'types/types';
import styled, { useTheme, css } from 'styled-components';
import Filter from 'components/atoms/icons/Filter';
import Typography from '@mui/material/Typography';
import Chevron from 'components/atoms/Chevron';

type FacetType = { key: string | number; value: string; count: number };

type AdvancedSearchBarProps = {
  searchBarName: string;
  placeholder: string;
  searchText: string;
  facetsFilter: { [key: string]: { key: string; value: string }[] };
  totalFacets: { [key: string]: { key: string; name: string; displayValue: string; values: FacetType[] } };
  handleSearchTextChange: (filter: any) => void;
  handleClearAllFacets: (key: string) => void;
  handleSelectAllFacets: (key: string) => void;
  handleFacetsFilterToggle: (area: any, value: any, displayValue?: any) => void;
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: ${UILayers.PageElement};
`;

const SearchBox = styled(ComplexInputBar)`
  padding: 0px ${props => props.theme.customSpacing.px.xxs}px;
`;

const FacetCategories = styled.div<{ $expanded?: boolean }>`
  background-color: white;
  border-radius: ${props => props.theme.borderRadius.default};
  box-shadow: ${props => props.theme.boxShadow.surface1};
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: 10px;
  min-width: 225px;
  padding: ${props => props.theme.customSpacing.px.base}px;
  position: absolute;
  box-shadow: ${props => props.theme.boxShadow.surface2};

  ${props => props.$expanded && 'height: fit-content;'}

  @media (max-width: 768px) {
    min-width: 150px;
  }
`;

const FacetCategoryRow = styled.div<{ $selected: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: ${props =>
    `${props.theme.customSpacing.px.xxs}px ${props.theme.customSpacing.px.base}px ${
      props.theme.customSpacing.px.xxs
    }px ${props.theme.customSpacing.px.base * 4}px`};
  border-radius: ${props => props.theme.borderRadius.default};
  ${params =>
    params.$selected &&
    `
  background-color: ${props => props.theme.colors.interactive.default};
  color: ${props => props.theme.colors.text.onInteractive};`}

  &:hover {
    ${params => !params.$selected && `background-color: ${props => props.theme.colors.action.secondaryHovered};`}
  }

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }

  transition: all 200ms;
`;

const FacetWrapper = styled.div<{ $active: boolean }>`
  position: absolute;
  top: 3rem;
  left: 235px;
  width: 250px;
  display: ${params => (params.$active ? 'inherit' : 'none')};

  @media (max-width: 768px) {
    font-size: 0.75rem;
    left: 155px;
    width: 200px;
  }
`;

const PillsWrapper = styled.div<{ $hasPills?: boolean }>`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  transition: all 3s;

  ${params =>
    params.$hasPills &&
    css`
      max-width: calc(100vw - 180px);
    `};
`;

const ShowFacetsWrapper = styled(PillsWrapper)`
  cursor: pointer;
  margin-right: 1rem;
  min-width: 30px;
  overflow: hidden;
`;

const Cover = styled.div<{ $blockInput: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: ${UILayers.Cover};
  background-color: white;
  opacity: 0.4;
  display: ${props => (props.$blockInput ? 'initial' : 'none')};
`;

const StyledFacetOptionsBox = styled(FacetOptionsBox)`
  position: absolute;
  left: 250px;
`;

const StyledSearchBarEmbeddedPill = styled(SearchBarEmbeddedPill)`
  color: yellow;
`;

const FacetRow = ({ facetName, setActiveFacet, activeFacet }) => {
  const theme = useTheme();
  const selected = facetName === activeFacet;

  return (
    <FacetCategoryRow
      $selected={selected}
      onClick={() => (selected ? setActiveFacet(undefined) : setActiveFacet(facetName))}
    >
      <Typography variant="strong">{facetName}</Typography>
      <Chevron
        flip={false}
        horizontal={true}
        widthInPixels={14}
        color={selected ? theme.colors.text.onInteractive : undefined}
        dataCy="search-bar-facet-list-chevron"
      />
    </FacetCategoryRow>
  );
};

const AdvancedSearchBar = ({
  placeholder,
  searchText,
  totalFacets,
  facetsFilter,
  searchBarName,
  handleClearAllFacets,
  handleSelectAllFacets,
  handleSearchTextChange,
  handleFacetsFilterToggle,
}: AdvancedSearchBarProps): ReactElement => {
  const theme = useTheme();
  const [showFacets, setShowFacets] = useState(false);
  const [activeFacet, setActiveFacet] = useState();
  // const [localSearchText, setLocalSearchText] = useState(searchText);

  // const debouncedSearchTextChange = debounce(handleSearchTextChange, 800);

  // useEffect(() => {
  //   debouncedSearchTextChange(localSearchText);
  // }, [localSearchText]);

  useEffect(() => {
    if (!showFacets) {
      setActiveFacet(undefined);
    }
  }, [showFacets]);

  const hasPills = useMemo(() => {
    let count = 0;
    Object.keys(facetsFilter).forEach(area => {
      count += facetsFilter[area].length;
    });
    return count > 0;
  }, [facetsFilter]);

  const memoizedUI = useMemo(() => {
    const filterKeys = Object.keys(totalFacets);
    return filterKeys.map(s => {
      return (
        <FacetWrapper $active={activeFacet === totalFacets[s].name} key={`filter-wrapper-${totalFacets[s].name}`}>
          {totalFacets[s].key !== '' && (
            <StyledFacetOptionsBox
              enabledSelectAll={true}
              showTitle={false}
              showSearch={true}
              title={totalFacets[s].key}
              key={totalFacets[s].key}
              facetList={(totalFacets[s].values || [])
                .sort((a, b) => (b.count >= a.count ? 1 : -1))
                .filter(v => {
                  return v.key !== null;
                })
                .map(v => {
                  let displayValue = v.key;
                  const value = v?.value?.toString();
                  if (['temporary', 'needsApproval', 'reviewed'].includes(s)) {
                    v.key === 'true' ? (displayValue = 'yes') : (displayValue = 'no');
                  }
                  return {
                    name: `${displayValue} (${v.count})`,
                    value,
                    displayValue,
                  };
                })}
              selectedFacets={facetsFilter[s] || []}
              handleItemToggle={e => {
                handleFacetsFilterToggle(s, e.target.value, e.target.getAttribute('data-displayvalue'));
              }}
              handleClear={() => {
                handleClearAllFacets(s);
              }}
              handleSelectAll={() => {
                handleSelectAllFacets(s);
              }}
            />
          )}
        </FacetWrapper>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalFacets, facetsFilter, activeFacet]);

  return (
    <>
      <Cover $blockInput={showFacets} onClick={() => setShowFacets(false)} />
      <Wrapper>
        <SearchBox
          placeholder={placeholder}
          value={searchText}
          name={searchBarName}
          onChange={e => handleSearchTextChange(e.target.value)}
          preItem={
            <div>
              <PillsWrapper $hasPills={hasPills} style={{ overflow: 'hidden' }}>
                <ShowFacetsWrapper
                  onClick={() => {
                    setActiveFacet(undefined);
                    setShowFacets(!showFacets);
                  }}
                >
                  <Filter color={theme.colors.icon.subdued.hex} dataCy="facet-filter-icon" />
                </ShowFacetsWrapper>
                <PillsWrapper $hasPills={hasPills}>
                  {Object.keys(facetsFilter).map(filterType => {
                    return facetsFilter[filterType].map((filter: { key: string; value: string }) => {
                      return (
                        <StyledSearchBarEmbeddedPill
                          item={{
                            name: `${filterType}: ${filter.key}`,
                          }}
                          key={`${filterType}-${filter.value}`}
                          removeHandler={() => {
                            handleFacetsFilterToggle(filterType, filter.value);
                          }}
                        />
                      );
                    });
                  })}
                </PillsWrapper>
              </PillsWrapper>
            </div>
          }
        />
        {showFacets && (
          <FacetCategories>
            {Object.keys(totalFacets).map(s => {
              return (
                <FacetRow
                  key={s}
                  facetName={totalFacets[s].name}
                  activeFacet={activeFacet}
                  setActiveFacet={setActiveFacet}
                ></FacetRow>
              );
            })}
          </FacetCategories>
        )}

        {memoizedUI}
      </Wrapper>
    </>
  );
};

export default AdvancedSearchBar;
