import React, { ReactElement, useState, useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components';
import Button from '@mui/material/Button';
import CheckBox from 'components/atoms/CheckBox';
import ReactGA from 'react-ga';
import Chevron from 'components/atoms/Chevron';
import ComplexInputBar from 'components/molecules/ComplexInputBar';
import Search from 'components/atoms/icons/Search';

type FacetOptionsBoxProps = {
  title: string;
  facetList: any[];
  selectedFacets?: any[];
  handleItemToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClear: () => void;
  handleSelectAll: () => void;
  startCollapsed?: boolean;
  showTitle?: boolean;
  showSearch?: boolean;
  enabledSelectAll: boolean;
};

const FilterSection = styled.div`
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.25rem;
  border-radius: ${props => props.theme.borderRadius.default};
  font-weight: 600;
  background-color: white;
  box-shadow: ${props => props.theme.boxShadow.surface1};
  margin-left: auto;
  margin-right: auto;
`;

const FilterExpandCollapse = styled.span`
  font-weight: 100;
  font-size: 2.25em;
  line-height: 0em;
  color: ${props => props.theme.colors.icon.default.hex};
  cursor: pointer;
  width: 23px;
  display: inline-block;
  margin-left: auto;
`;

const FilterItemsList = styled.div<{ $collapsed?: boolean }>`
  margin-top: 1rem;
  max-height: 300px;
  overflow-y: scroll;
  transition: all 0.5s ease-in-out;
  opacity: 1;

  ${props =>
    props.$collapsed &&
    css`
      margin-top: 0px;
      max-height: 0px;
      opacity: 0;
    `};
`;

const FilterItem = styled.div`
  color: ${props => props.theme.colors.text.subdued};
  min-height: 1.75rem;
  overflow: none;
  whitespace: nowrap;
  display: flex;
  padding-left: ${props => props.theme.customSpacing.px.base}px;
  align-items: center;
`;

const FilterTitle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  padding-left: ${props => props.theme.customSpacing.px.base}px;
`;

const LinksRow = styled.div`
  padding-left: ${props => props.theme.customSpacing.px.base}px;
  margin-bottom: ${props => props.theme.customSpacing.px.base}px;
`;

const StyledCheckBox = styled(CheckBox)`
  padding-left: 0px;
`;

const FacetOptionsBox = ({
  title,
  facetList,
  selectedFacets = [],
  handleItemToggle,
  handleClear,
  handleSelectAll,
  startCollapsed = false,
  showTitle = true,
  showSearch = false,
  enabledSelectAll,
}: FacetOptionsBoxProps): ReactElement => {
  const theme = useTheme();
  const [collapsed, setCollapsed] = useState(startCollapsed);
  const [filterTerms, setFilterTerms] = useState<string>();

  const toggleCollapsed = () => {
    ReactGA.event({
      category: 'facet',
      action: 'toggle collapse',
      label: `${title}`,
    });
    setCollapsed(!collapsed);
  };

  const handleItemClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    ReactGA.event({
      category: 'facet',
      action: 'item toggle',
      label: `${title}`,
    });
    handleItemToggle(e);
  };

  const handleClearClick = () => {
    ReactGA.event({
      category: 'facet',
      action: 'clear all',
      label: `${title}`,
    });
    handleClear();
  };

  const handleSelectAllClick = () => {
    ReactGA.event({
      category: 'facet',
      action: 'select all',
      label: `${title}`,
    });
    handleSelectAll();
  };

  const filteredFacetList = useMemo(() => {
    if (filterTerms && filterTerms.length > 0) {
      return facetList.filter(f => {
        const lowerName = f.name.toLowerCase();
        const lowerFilter = filterTerms.toLowerCase();
        return lowerName.indexOf(lowerFilter) >= 0;
      });
    } else {
      return facetList;
    }
  }, [filterTerms, facetList]);

  const hasFilterTerms = filterTerms !== '' && filterTerms !== undefined;

  return (
    <FilterSection>
      {showTitle && (
        <FilterTitle>
          {title}
          <FilterExpandCollapse onClick={toggleCollapsed}>
            <Chevron flip={!collapsed} dataCy="facet-options-box-expand-filters-chevron" />
          </FilterExpandCollapse>
        </FilterTitle>
      )}
      {showSearch && (
        <>
          <ComplexInputBar
            placeholder={'filter values'}
            onChange={e => {
              setFilterTerms(e.target.value);
            }}
            value={filterTerms}
            preItem={<Search color={theme.colors.icon.subdued.hex} size={14} />}
          />
        </>
      )}
      <FilterItemsList $collapsed={collapsed}>
        {filteredFacetList.slice(0, 100).map(f => {
          return (
            <FilterItem key={f.displayValue + '-' + f.value}>
              <StyledCheckBox
                checked={selectedFacets.map(i => i.value).indexOf(f.value) >= 0}
                value={f.value}
                displayValue={f.displayValue}
                onChange={handleItemClick}
                dataCy="facet-options-box-checkbox"
              >
                {f.name}
              </StyledCheckBox>
            </FilterItem>
          );
        })}
        {facetList && (
          <LinksRow>
            {!!selectedFacets.length && (
              <Button variant="text" onClick={handleClearClick} disabled={hasFilterTerms}>
                clear
              </Button>
            )}
            {!!selectedFacets.length && enabledSelectAll && <span>&nbsp;|&nbsp;</span>}
            {enabledSelectAll && (
              <Button variant="text" onClick={handleSelectAllClick} disabled={hasFilterTerms}>
                all
              </Button>
            )}
          </LinksRow>
        )}
      </FilterItemsList>
    </FilterSection>
  );
};

export default FacetOptionsBox;
