import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

interface HeaderLinkProps {
  url: string;
  text: string;
  active: boolean;
  mobileOnly?: boolean;
  onClickHandler?: () => void;
  dataCy: string;
}

const LinkBox = styled.a<{ $active: boolean; $mobileOnly: boolean }>`
  position: static;
  font-style: normal;
  opacity: 0.7;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: ${props => props.theme.colors.text.onHeaderBar};
  flex: none;
  align-self: center;
  margin: 0px 2px;
  padding: 8px 16px;
  border-radius: ${props => props.theme.borderRadius.default};
  cursor: pointer;
  &:hover {
    opacity: 1;
  }

  ${props =>
    props.$active &&
    css`
      opacity: 1;
    `};

  ${props =>
    props.$mobileOnly &&
    css`
      display: none;
    `};

  ${props =>
    props.$mobileOnly &&
    css`
      @media (max-width: 768px) {
        display: block;
      }
    `};

  @media (max-width: 768px) {
    margin: 2px;
    width: 100%;
  }
`;

const HeaderLink = ({
  url,
  text,
  active,
  mobileOnly = false,
  onClickHandler,
  dataCy,
}: HeaderLinkProps): ReactElement => {
  const navigate = useNavigate();

  return (
    <>
      <LinkBox
        $active={active}
        onClick={() => {
          if (onClickHandler) {
            onClickHandler();
          } else {
            navigate(url);
          }
        }}
        $mobileOnly={mobileOnly}
        data-cy={dataCy || `header-link-${text}`}
      >
        <Typography variant={active ? 'strong' : 'body'}>{text}</Typography>
      </LinkBox>
    </>
  );
};

export default HeaderLink;
