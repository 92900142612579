import { useState, useEffect } from 'react';
import { CategoryCount } from 'types/types';
import { ProcessingBarData } from 'components/atoms/ProcessingProgressBar';
import { useTheme } from 'styled-components';
import { ProcessingProgressBarKeyDataElement } from '../components/molecules/ProcessingProgressBarKey';

type UseProgressBarCategoryDataProps = {
  categoryCountData: Array<CategoryCount>;
};

type ProgressBarCategoryData = {
  categoryProgressBarDataList: Array<ProcessingBarData>;
  categoryProgressBarKeyData: Array<ProcessingProgressBarKeyDataElement>;
  total: number;
};

const useProgressBarCategoryData = ({
  categoryCountData,
}: UseProgressBarCategoryDataProps): ProgressBarCategoryData => {
  const [progressBarCategoryData, setProgressBarCategoryData] = useState<Array<ProcessingBarData>>(
    new Array<ProcessingBarData>()
  );
  const [progressBarKeyData, setprogressBarKeyData] = useState<Array<ProcessingProgressBarKeyDataElement>>(
    new Array<ProcessingProgressBarKeyDataElement>()
  );
  const [total, setTotal] = useState<number>(1);
  const theme = useTheme();

  const categoryColourMapper = (index: number) => {
    let color = theme.colors.progressBar.default;
    switch (index) {
      case 0:
        color = theme.colors.progressBar.segment1;
        break;
      case 1:
        color = theme.colors.progressBar.segment2;
        break;
      case 2:
        color = theme.colors.progressBar.segment3;
        break;
      case 3:
        color = theme.colors.progressBar.segment4;
        break;
      case 4:
        color = theme.colors.progressBar.segment5;
        break;
    }
    return color;
  };

  useEffect(() => {
    const total = categoryCountData.reduce((runningTotal, categoryCount) => runningTotal + categoryCount.count, 0);

    const topFiveCategories = categoryCountData.slice(0, 5);
    const otherCategoryCount = categoryCountData
      .slice(5, categoryCountData.length - 1)
      .reduce((otherCategoryTotal, categoryCount) => otherCategoryTotal + categoryCount.count, 0);
    const otherCategory: CategoryCount | null =
      otherCategoryCount && otherCategoryCount > 0
        ? {
            // Do we want to change this? We should if we plan to make these clickable as we'll need to pass a valid id.
            categoryId: 0,
            categoryName: 'Other',
            count: otherCategoryCount,
          }
        : null;
    const allCategories = otherCategory ? topFiveCategories.concat([otherCategory]) : topFiveCategories;

    const categoryDataPoints = new Array<ProcessingBarData>();
    allCategories.forEach((category, barIndex) => {
      categoryDataPoints.push({
        title: `${category.categoryName}: ${category.count.toLocaleString()}`,
        count: category.count,
        color: categoryColourMapper(barIndex),
        segmentId: category.categoryId.toString(),
      });
    });

    const categoryProgressBarKeyData = new Array<ProcessingProgressBarKeyDataElement>();
    allCategories.forEach((category, keyIndex) => {
      categoryProgressBarKeyData.push({
        label: category.categoryName,
        color: categoryColourMapper(keyIndex),
        count: category.count,
      });
    });

    setTotal(total);
    setProgressBarCategoryData(categoryDataPoints);
    setprogressBarKeyData(categoryProgressBarKeyData);
  }, [categoryCountData]);

  return {
    categoryProgressBarDataList: progressBarCategoryData,
    categoryProgressBarKeyData: progressBarKeyData,
    total,
  };
};

export default useProgressBarCategoryData;
