import React, { useState, useEffect, ReactElement } from 'react';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import UserTableSelect from 'components/molecules/UserTableSelect';
import useCompanyContext from 'helpers/UseCompanyContext';
import userRoles from 'helpers/userRolesHelper';
import styled from 'styled-components';
import axios from 'helpers/api_helper';
import { DataGrid, GridColDef, GridFooter, GridFooterContainer } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import dayjs from 'dayjs';
import User from 'classes/User';
import DataGridExport from 'components/organisms/DataGridExport';

const UserEmail = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${props => props.theme.customTypography.desktop.body.weight};
`;

const AddUserComponent = ({ handleUserAdd }) => {
  return (
    <Stack direction="row" spacing={1}>
      <Button variant="contained" color="actionPrimary" onClick={handleUserAdd} data-cy="button-add-user">
        Add User
      </Button>
    </Stack>
  );
};

const rolesComparator = (roleOne, roleTwo) => {
  roleOne = userRoles.find(r => r.key === roleOne);
  roleTwo = userRoles.find(r => r.key === roleTwo);
  return roleOne.name > roleTwo.name ? 1 : -1;
};

// eslint-disable-next-line space-before-function-paren
function UserManagementPage(): ReactElement {
  const navigate = useNavigate();
  const { companyId } = useCompanyContext();
  const [loading, setLoading] = useState(true);
  const [participants, setParticipants] = useState<User[]>([]);

  const DATE_MM_DD_YY = 'M/D/YY';
  const DATE_M_D_YYYY_H_M_S = 'M/D/YYYY h:mm:ss a';

  const loadParticipants = async () => {
    const userData = await axios.get(`${process.env.REACT_APP_API_ROOT}/companies/${companyId}/users`);

    setParticipants(
      userData.data.users.map(participant => {
        return {
          id: participant.user_id,
          name: participant.details.first_name + ' ' + participant.details.last_name,
          email: participant.user_id,
          dateRegistered: participant.created,
          lastLogin: participant.details.last_login,
          role: participant.role ? participant.role : 'employee',
        };
      })
    );
  };

  useEffect(() => {
    const getParticipants = async () => {
      setLoading(true);
      await loadParticipants();
      setLoading(false);
    };
    getParticipants();
  }, [companyId]);

  const handleUserAdd = () => {
    navigate('/admin/user/');

    ReactGA.event({
      category: 'user',
      action: 'add',
      label: 'initiated',
    });
  };

  const editParticipantDetails = userId => {
    navigate(`/admin/user/${userId}`);
    ReactGA.event({
      category: 'user',
      action: 'edit',
      label: 'initiated',
    });
  };

  const handleParticipantUpdate = ({ userId, role, teamId }) => {
    const newParticipants = [...participants];

    newParticipants.forEach(p => {
      if (p.id === userId) {
        if (role) {
          p.role = role;
        }
        if (teamId) {
          p.teamId = teamId;
        }
      }
    });

    setParticipants(newParticipants);
  };

  const handleUserDelete = userId => {
    const newParticipants = participants.filter(item => item.id !== userId);
    setParticipants(newParticipants);
  };

  const columns: GridColDef<User>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2.5,
      headerAlign: 'left',
      align: 'left',
      renderCell: param => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant="text"
              className="full-name"
              data-cy="user-full-name"
              sx={{ paddingBottom: '0px' }}
              onClick={() => {
                editParticipantDetails(param.row.id);
              }}
            >
              {param.value}
            </Button>
          </div>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 3,
      headerAlign: 'left',
      align: 'left',
      renderCell: param => {
        return <UserEmail>{param.value}</UserEmail>;
      },
    },
    {
      field: 'dateRegistered',
      type: 'date',
      headerName: 'Date Registered',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      valueFormatter: param => {
        return dayjs(param.value).format(DATE_MM_DD_YY);
      },
    },
    {
      field: 'lastLogin',
      type: 'date',
      headerName: 'Last Login',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      renderCell: param => {
        return (
          <span title={param.value ? dayjs(param.value).format(DATE_M_D_YYYY_H_M_S) : 'never'}>
            {param.value ? dayjs(param.value).format(DATE_MM_DD_YY) : 'never'}
          </span>
        );
      },
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 2.5,
      headerAlign: 'left',
      align: 'left',
      sortComparator: rolesComparator,
      renderCell: param => {
        return (
          <UserTableSelect
            participant={{ id: param.row.id, name: param.row.name, value: param.value }}
            handleParticipantUpdate={handleParticipantUpdate}
            handleUserDelete={handleUserDelete}
            selectMaxWidth="217px"
          />
        );
      },
    },
  ];

  return (
    <MainContentTemplate pageTitle="User Management">
      <ContentHeaderTemplate
        contentTitle="Users"
        actionsComponent={<AddUserComponent handleUserAdd={handleUserAdd} />}
      />
      <Box sx={{ flexGrow: '1' }}>
        <DataGrid
          components={{
            Footer: () => (
              <GridFooterContainer>
                <DataGridExport />
                <GridFooter sx={{ border: 'none' }} />
              </GridFooterContainer>
            ),
          }}
          rows={participants}
          columns={columns}
          loading={loading}
          hideFooter={false}
          disableSelectionOnClick={true}
          sx={{
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
          autoHeight
          className="users-table"
        />
      </Box>
    </MainContentTemplate>
  );
}

export default UserManagementPage;
