import realAxios from 'axios';

const axios = realAxios.create();
axios.interceptors.request.use(config => {
  const idToken = sessionStorage.getItem('authIdToken');
  if (idToken && config.headers) {
    config.headers.Authorization = `Bearer ${idToken}`;
  }
  return config;
});

axios.interceptors.response.use(
  res => res,
  err => {
    if (err.response && err.response.data) {
      return Promise.reject(err);
    }
    return Promise.reject(err.message);
  }
);

export default axios;
