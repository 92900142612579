import React, { ReactElement, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import DialogModal from 'components/molecules/DialogModal';
import ReactGA from 'react-ga';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import useCompanyContext from 'helpers/UseCompanyContext';
import axios from 'helpers/api_helper';
import Select from 'components/atoms/Select';
import RequiredFieldIndicator from 'components/atoms/RequiredFieldIndicator';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { IconColorFilter } from 'types/types';
import CSVToJSON from 'csvtojson';
import { EMSIBGButton } from 'components/atoms/ButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import RoleUploadFileDetails from 'components/organisms/RoleUploadFileDetails';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { toast } from 'react-toastify';
import RoleProcessingProgressBar from 'components/molecules/RoleProcessingProgressBar';

const Wrapper = styled.div`
  padding: ${props => props.theme.customSpacing.px.base * 4}px;
  padding-top: 0px;
`;

const UploadSection = styled.div`
  height: 30%;
  width: 100%;
`;

const RolesUploader = styled.input`
  margin-bottom: ${props => props.theme.customSpacing.px.base * 4}px;
  height: 100%;
  width: 100%;
  display: none;
`;

const MessageContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const MapHead = styled.div`
  font-weight: 700;
`;

const MappingRow = styled.div<{ noHover?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.25rem;

  :hover {
    ${params => !params.noHover && `background-color: ${params.theme.colors.surface.neutralSubdued};`}
  }
`;

const MapDesc = styled.div`
  font-weight: 400;
  color: ${props => props.theme.colors.text.default};
  font-size: 0.85rem;
`;

const UploadArea = styled.div<{ $isDragging: boolean }>`
  border: ${params =>
    params.$isDragging
      ? `1px solid ${params.theme.colors.border.default}`
      : `1px solid ${params.theme.colors.border.default}`};
  border-radius: ${props => props.theme.borderRadius.default};
  background-color: ${params => (params.$isDragging ? params.theme.colors.surface.neutral : 'inherit')};
  text-align: center;
  color: ${params => params.theme.colors.text.default};
  padding: ${props => props.theme.customSpacing.px.m}px;
  ${params => params.$isDragging && 'font-weight: 600;'}

  transition: all 300ms;
`;

type RoleUploadFileModalProps = {
  isOpen: boolean;
  closeHandler: (openAddModal: boolean) => void;
};

const RoleUploadFileModal = ({ isOpen = true, closeHandler }: RoleUploadFileModalProps): ReactElement => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [file, setFile] = useState<File>();
  const [roles, setRoles] = useState<any[]>();
  const [uploading, setUploading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [titleColumn, setTitleColumn] = useState();
  const [codeColumn, setCodeColumn] = useState();
  const [familyColumn, setFamilyColumn] = useState();
  const [functionColumn, setFunctionColumn] = useState();
  const [descriptionColumn, setDescriptionColumn] = useState();
  const [notesColumn, setNotesColumn] = useState();
  const [roleNameColumn, setRoleNameColumn] = useState();
  const [aliasesColumn, setAliasesColumn] = useState();
  const [tagsColumn, setTagsColumn] = useState();
  const [performUpdates, setPerformUpdates] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [headerLines, setHeaderLines] = useState<string[]>([]);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isManualAddAfterClose, setIsManualAddAfterClose] = useState(false);
  const [cancelUploadMessage, setCancelUploadMessage] = useState('');
  const [selectNewFile, setSelectNewFile] = useState(false);
  const [jobCodes, setJobCodes] = useState<string[]>([]);
  const [uploadBatchId, setUploadBatchId] = useState<string | undefined>(undefined);
  const [uploadFail, setUploadFail] = useState(false);

  const { companyId } = useCompanyContext();
  // Role upload file constraints
  const ALLOWED_FILE_TYPES = ['text/csv'];
  const MAXIMUM_ALLOWED_FILE_SIZE = 3000000;
  const MAXIMUM_ALLOWED_ROLES_PER_FILE = 10000;

  const logAnalytics = (event: string) => {
    ReactGA.event({
      category: 'roles',
      action: 'upload',
      label: event,
    });
  };

  const bucketizeFileSize = (fileSize: number) => {
    let bucket = '10MB+';
    if (fileSize < 10000000) {
      bucket = '1MB-10MB';
    }
    if (fileSize < 1000000) {
      bucket = '100k-1MB';
    }
    if (fileSize < 100000) {
      bucket = '50k-100k';
    }
    if (fileSize < 50000) {
      bucket = '5k-50k';
    }
    if (fileSize < 5000) {
      bucket = '1-5k';
    }
    return bucket;
  };

  useEffect(() => {
    if (!codeColumn || codeColumn === 'null') setJobCodes([]);
    else {
      const jobCodes = roles?.map(role => role[codeColumn]) || [];
      setJobCodes(jobCodes);
    }
  }, [codeColumn]);

  const onUploadProgress = event => {
    setProgress(Math.round((100 * event.loaded) / event.total));
  };

  const SuccessfulFileProcessedToast = batchId => (
    <div>
      <span data-cy="file-successfully-processed-toast">
        Your file has finished processing{' '}
        <Button variant="text" onClick={() => navigate(`/admin/processing-statuses/${batchId}?p=0&n=10`)}>
          Click here to view upload details
        </Button>
      </span>
    </div>
  );

  const FailFileProcessedToast = () => (
    <div>
      <span data-cy="file-fail-processed-toast">There was a problem with your file upload, please try again.</span>
    </div>
  );

  const uploadCSV = async () => {
    setUploading(true);

    try {
      axios
        .post(
          `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/roles/bulkupload`,
          {
            roles,
            fileName: file?.name,
            performUpdates,
            titleColumn,
            familyColumn,
            functionColumn,
            descriptionColumn,
            notesColumn,
            externalIdColumn: codeColumn,
            roleNameColumn,
            aliasesColumn,
            tagsColumn,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            onUploadProgress,
          }
        )
        .then(response => {
          setUploadBatchId(response.data.id);
          toast.success(SuccessfulFileProcessedToast(response.data.id));
        })
        .catch(error => {
          setUploadFail(true);
          toast.error(FailFileProcessedToast());
          console.error(error);
        });
      setFileUploaded(true);
      logAnalytics('File upload complete');
    } catch (error) {
      console.error('ERROR SENDING TO LAMBDA TO SAVE', error);
      logAnalytics('File upload error');
    } finally {
      setUploading(false);
      setProgress(0);
      const bucketizedFileSize = file ? bucketizeFileSize(file.size) : '0K';
      logAnalytics(`Size bucket: ${bucketizedFileSize}`);
    }
  };

  const fileSelectHandler = async (file: File | undefined, source: string) => {
    if (!file) return;

    logAnalytics(source);
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      logAnalytics(`${source}: invalid file type - ${file.type}`);
      alert('Only CSV files are supported at this time.\n' + 'Please try a different file.');
    } else if (file.size === 0) {
      logAnalytics(`${source}: empty file`);
      alert('The file you have provided is empty.\n' + 'Please try a different file.');
    } else if (file.size > MAXIMUM_ALLOWED_FILE_SIZE) {
      logAnalytics(`${source}: file size exceeded - ${file.size}B`);
      alert(
        'The file you have provided is larger than the maximum file size allowed.\n' +
          'Please reduce the size by removing unneeded columns or by splitting the file.'
      );
    } else {
      const reader = new FileReader();
      reader.onload = async e => {
        const fileContent = e?.target?.result;
        const lines = fileContent?.toString() || '';
        const rows = await CSVToJSON().fromString(lines);
        let totalRows = 0;
        rows.forEach(role => {
          if (!(role?.length === 1 && role?.[0] === '')) totalRows++; // Exclude empty rows
        });
        const totalRoles = totalRows - 1; // Exclude header row count
        if (totalRoles === -1) {
          logAnalytics(`${source}: no roles`);
          alert('The file you have provided has no roles.\n' + 'Please try a different file.');
        } else if (totalRoles > MAXIMUM_ALLOWED_ROLES_PER_FILE) {
          logAnalytics(`${source}: roles limit exceeded - ${totalRoles}`);
          alert(
            `The file you have provided has more than ${MAXIMUM_ALLOWED_ROLES_PER_FILE} roles.\n` +
              'Please reduce the number of roles in this file and upload again.'
          );
        } else {
          setFile(file);
          setRoles(rows);
          setHeaderLines(Object.keys(rows?.[0]));
        }
      };
      reader.onerror = e => alert(e?.target?.error?.name);
      reader.readAsText(file);
    }
  };

  const resetFields = () => {
    setFile(undefined);
    setUploading(false);
    setFileUploaded(false);
    setProgress(0);
    setTitleColumn(undefined);
    setCodeColumn(undefined);
    setFamilyColumn(undefined);
    setFunctionColumn(undefined);
    setDescriptionColumn(undefined);
    setNotesColumn(undefined);
    setRoleNameColumn(undefined);
    setAliasesColumn(undefined);
    setTagsColumn(undefined);
    setPerformUpdates(false);
    setUploadBatchId(undefined);
    setUploadFail(false);
  };

  const cancelHandler = (isManualAddAfterClose = false, setNewFile = false) => {
    setIsManualAddAfterClose(isManualAddAfterClose);
    setSelectNewFile(setNewFile);
    if (file) {
      const messageCancelAction = isManualAddAfterClose
        ? 'Manually adding roles'
        : setNewFile
          ? 'Choosing new file'
          : 'Cancelling';
      setCancelUploadMessage(
        `${messageCancelAction} will remove your selected file to upload. Do you want to continue?`
      );
      setIsCancelModalOpen(true);
    } else {
      closeHandler(isManualAddAfterClose);
    }
  };

  const cancelUploadModalHandler = (continueAction: boolean) => {
    setIsCancelModalOpen(false);

    if (continueAction) {
      resetFields();
      if (!selectNewFile) {
        closeHandler(isManualAddAfterClose);
      }
    }
  };

  const fields = [
    {
      name: 'Job Code Column',
      description: 'The job code that uniquely identifies the role in your system.',
      fieldValue: codeColumn,
      changeFunction: e => {
        setCodeColumn(e.target.value);
      },
      required: true,
    },
    {
      name: 'Job Title Column',
      description: 'The title to be normalized.',
      fieldValue: titleColumn,
      changeFunction: e => {
        setTitleColumn(e.target.value);
      },
      required: true,
    },
    {
      name: 'Role Name Column',
      description: 'The name of the role.  If missing the job title will be used.',
      fieldValue: roleNameColumn,
      changeFunction: e => {
        setRoleNameColumn(e.target.value);
      },
    },
    {
      name: 'Job Family Column',
      description: 'The name/code of the job family the role belongs to.',
      fieldValue: familyColumn,
      changeFunction: e => {
        setFamilyColumn(e.target.value);
      },
    },
    {
      name: 'Job Function Column',
      description: 'The job function that the role fills.',
      fieldValue: functionColumn,
      changeFunction: e => {
        setFunctionColumn(e.target.value);
      },
    },
    {
      name: 'Job Description Column',
      description: 'The job description text for the role.',
      fieldValue: descriptionColumn,
      changeFunction: e => {
        setDescriptionColumn(e.target.value);
      },
    },
    {
      name: 'Job Notes Column',
      description: 'Additional notes to store with the role.',
      fieldValue: notesColumn,
      changeFunction: e => {
        setNotesColumn(e.target.value);
      },
    },
    {
      name: 'Aliases Column',
      description: 'Aliases for the role (pipe "|" separated). Ex: "alias 1|alias 2|alias 3"',
      fieldValue: aliasesColumn,
      changeFunction: e => {
        setAliasesColumn(e.target.value);
      },
    },
    {
      name: 'Additional Tags Column',
      description: 'Tags for the role (pipe "|" separated). Ex: "tag 1|tag 2|tag 3"',
      fieldValue: tagsColumn,
      changeFunction: e => {
        setTagsColumn(e.target.value);
      },
    },
  ];

  const canSubmit = () => {
    return file && titleColumn && codeColumn;
  };

  const defaultDragEventHandler = ({ e, isOver = false }) => {
    e.preventDefault();
    e.stopPropagation();
    if (isOver !== isDragging) {
      setIsDragging(isOver);
    }
  };

  const handleDrop = async e => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.items && e.dataTransfer.items.length === 1 && e.dataTransfer.items[0].kind === 'file') {
      const file = e.dataTransfer.items[0].getAsFile();
      await fileSelectHandler(file, 'Drag-Drop');
    }
    setIsDragging(false);
  };

  const uploadBtnGroup: EMSIBGButton[] = [
    {
      text: 'Cancel',
      variant: 'contained',
      onClickEvent: () => cancelHandler(),
      dataCy: 'add-roles-selector-modal-cancel-button',
    },
    {
      text: 'Add Manually',
      variant: 'contained',
      onClickEvent: () => cancelHandler(true),
      dataCy: 'add-roles-selector-modal-add-manually-button',
    },
    {
      text: 'Upload',
      variant: 'contained',
      color: 'actionPrimary',
      isLoadingButton: true,
      loadingStatus: uploading,
      loadingMessage: 'Uploading file...',
      disabled: !canSubmit(),
      onClickEvent: async () => await uploadCSV(),
      dataCy: 'add-roles-selector-modal-upload-button',
      width: '120px',
    },
  ];

  const uploadedBtnGroup: EMSIBGButton[] = [
    {
      text: 'Close',
      variant: 'contained',
      onClickEvent: () => cancelUploadModalHandler(true),
      dataCy: 'file-upload-completed-modal-close-button',
    },
  ];

  const cancelUploadBtnGroup: EMSIBGButton[] = [
    {
      text: 'Cancel',
      onClickEvent: () => cancelUploadModalHandler(false),
      dataCy: 'cancel-file-upload-modal-cancel-button',
    },
    {
      text: 'Continue',
      variant: 'contained',
      color: 'actionPrimary',
      onClickEvent: () => cancelUploadModalHandler(true),
      dataCy: 'cancel-file-upload-modal-continue-button',
    },
  ];

  const Content = (
    <Wrapper data-cy="add-roles-selector-modal">
      {file && !uploading && !fileUploaded && (
        <>
          <RoleUploadFileDetails
            fileName={file.name}
            lastModified={`${new Date(file.lastModified).toLocaleString()}`}
            jobCodes={jobCodes}
            cancelHandler={() => cancelHandler(false, true)}
            performUpdates={performUpdates}
          />
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={performUpdates} onChange={event => setPerformUpdates(event.target.checked)} />}
              label="Update any existing roles with the content of this file: role name, description, notes, family, function."
            />
          </FormGroup>
        </>
      )}
      {((!fileUploaded && !uploading) || !file) && (
        <>
          <UploadSection>
            {!file && (
              <>
                <RolesUploader
                  type="file"
                  accept=".csv"
                  id="fileInput"
                  name="fileInput"
                  onClick={() => logAnalytics('File dialog opened')}
                  onChange={e => fileSelectHandler(e?.target?.files?.[0], 'File selected')}
                />
                <UploadArea
                  onDrop={handleDrop}
                  onDragOver={e => defaultDragEventHandler({ e, isOver: true })}
                  onDragEnter={e => defaultDragEventHandler({ e, isOver: true })}
                  onDragLeave={e => defaultDragEventHandler({ e, isOver: false })}
                  $isDragging={isDragging}
                  data-cy="add-roles-selector-modal-upload-file-drag-n-drop-area"
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <BaseIcon type={IconType.Upload} size="16px" alt="upload icon" />
                    <Typography>Drag and drop or</Typography>
                    <Button
                      variant="text"
                      onClick={() => {
                        document.getElementById('fileInput')?.click();
                      }}
                    >
                      <Typography variant="strong">browse file</Typography>
                    </Button>
                  </div>
                  <Typography variant="caption" color="text.subdued" sx={{ whiteSpace: 'pre-wrap' }}>
                    {`
Acceptable file types: csv.
Maximum file size limit: 3 MB.
Maximum number of roles allowed per file: ${MAXIMUM_ALLOWED_ROLES_PER_FILE}.
                    `}
                  </Typography>
                </UploadArea>
              </>
            )}

            {file && headerLines && (
              <div>
                <MappingRow noHover={true} style={{ borderBottom: `1px solid ${theme.colors.border.default}` }}>
                  <div>Mapped Field</div>
                  <div>Mapped Column</div>
                </MappingRow>
                {fields.map(f => (
                  <MappingRow key={`mapping field - ${f.name}`}>
                    <div>
                      <MapHead data-cy={`role-upload-${f.name.replace(/\s+/g, '-').toLowerCase()}-header`}>
                        {f.name} {f.required && <RequiredFieldIndicator />}
                      </MapHead>
                      <MapDesc data-cy={`role-upload-${f.name.replace(/\s+/g, '-').toLowerCase()}-description`}>
                        {f.description}
                      </MapDesc>
                    </div>
                    <div>
                      <Select
                        onChange={f.changeFunction}
                        value={f.fieldValue}
                        data-cy={`role-upload-${f.name.replace(/\s+/g, '-').toLowerCase()}-select`}
                      >
                        {['- not mapped -', ...headerLines].map(hl => (
                          <option
                            key={`${hl}`}
                            value={`${hl !== '- not mapped -' ? hl : null}`}
                            data-cy={`role-upload-${hl}-option`}
                          >
                            {hl}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </MappingRow>
                ))}
              </div>
            )}
          </UploadSection>
        </>
      )}
      {file && uploading && (
        <>
          <Typography variant="subHeading">Uploading...</Typography>
          <LinearProgress variant="determinate" value={progress} />
        </>
      )}
      {fileUploaded && (
        <MessageContainer>
          {!uploadBatchId ? (
            uploadFail ? (
              <>
                <Typography variant="displayMedium">
                  there was a problem with your file upload, please &nbsp;
                  <Link onClick={() => resetFields()}>try again</Link>.
                </Typography>
              </>
            ) : (
              <>
                <div>
                  <Typography variant="displayMedium">The file is being processed.</Typography>
                </div>
                <div>
                  <Link
                    data-cy="file-successfully-processed-link"
                    onClick={() => navigate('/admin/processing-statuses/')}
                  >
                    <Typography variant="body">click here to go to the processing page</Typography>
                  </Link>
                </div>
              </>
            )
          ) : (
            <>
              <div>
                <BaseIcon
                  type={IconType.Check}
                  size="28px"
                  colorFilter={IconColorFilter.Success}
                  aria-label="success"
                />
                <Typography variant="displayMedium">Your file has finished processing</Typography>
                <div style={{ padding: '15px 0px 10px 0px' }}>
                  <RoleProcessingProgressBar requestId={uploadBatchId} width={'100%'} />
                </div>
              </div>
              <div>
                <Link
                  data-cy="file-successfully-processed-link"
                  onClick={() => navigate(`/admin/processing-statuses/${uploadBatchId}?p=0&n=10`)}
                >
                  <Typography variant="body">click here to view upload details</Typography>
                </Link>
              </div>
            </>
          )}
        </MessageContainer>
      )}

      <DialogModal
        dialogOpen={isCancelModalOpen}
        disableEscapeKeyDown={true}
        title="Cancel File Upload"
        content={cancelUploadMessage}
        buttonGroup={cancelUploadBtnGroup}
        dataCy={'role-upload-cancel-file-upload-modal'}
      />
    </Wrapper>
  );

  return (
    <>
      <DialogModal
        dialogOpen={isOpen}
        title="Upload CSV"
        titleSizeVariant="displayLarge"
        content={Content}
        buttonGroup={fileUploaded ? uploadedBtnGroup : uploadBtnGroup}
        btnGroupPosition="top"
        maxWidth="md"
        fullWidth={true}
      />
    </>
  );
};

export default RoleUploadFileModal;
