import React, { ReactElement, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ReactGA from 'react-ga';
import DialogModal from 'components/molecules/DialogModal';
import FormPartLabel from 'components/atoms/formElements/FormPartLabel';
import FormikTextField from 'components/atoms/formElements/FormikTextField';
import ButtonGroup from 'components/atoms/ButtonGroup';
import { CustomSkill } from 'types/types';
import { Typography } from '@mui/material';

type FormikCustomSkill = Omit<CustomSkill, 'customSkillId'>;

type AddEditCustomSkillModalProps = {
  dialogOpen: boolean;
  closeModal: () => void;
  addCustomSkill: (customSkill: Omit<CustomSkill, 'customSkillId'>) => Promise<string | void>;
  editCustomSkill: (customSkillId: string, customSkill: Partial<CustomSkill>) => Promise<string | void>;
  customSkill?: CustomSkill;
};

const logAnalytics = (action: string, event: string) => {
  ReactGA.event({
    category: 'custom-skills',
    action,
    label: event,
  });
};

const AddEditCustomSkillModal = ({
  dialogOpen,
  closeModal,
  addCustomSkill,
  editCustomSkill,
  customSkill,
}: AddEditCustomSkillModalProps): ReactElement => {
  const [saving, setSaving] = useState(false);
  const verb = customSkill ? 'Update' : 'Add';

  const submitForm = async (submittedCustomSkill: FormikCustomSkill) => {
    logAnalytics('custom skill', verb);
    setSaving(true);
    try {
      if (customSkill) {
        await editCustomSkill(customSkill.customSkillId, submittedCustomSkill);
      } else {
        await addCustomSkill(submittedCustomSkill);
      }
      closeModal();
    } catch (error: any) {
      return error.message;
    } finally {
      setSaving(false);
    }
  };

  const customSkillForm = () => {
    return (
      <Formik<FormikCustomSkill>
        onSubmit={async (values, formikHelpers) => {
          formikHelpers.setStatus(null);
          const result = await submitForm(values);
          formikHelpers.setValues(values);
          formikHelpers.resetForm();
          formikHelpers.setStatus(result);
        }}
        initialValues={
          customSkill || {
            skillId: '',
            skillName: '',
            skillSource: '',
          }
        }
        validationSchema={Yup.object({
          skillId: Yup.string()
            .min(1, 'Skill ID must be at least 1 character.')
            .max(255, 'Skill ID must be less than 255 characters.')
            .required('You must provide a skill ID.'),
          skillName: Yup.string()
            .min(1, 'Skill name must be at least 1 character.')
            .max(255, 'Skill name must be less than 255 characters.')
            .required('You must provide a skill name.'),
          skillSource: Yup.string()
            .min(1, 'Skill source must be at least 1 character.')
            .max(255, 'Skill source must be less than 255 characters.')
            .required('You must provide a skill source.'),
        })}
      >
        {props => {
          return (
            <>
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  props.handleSubmit();
                }}
              >
                <FormPartLabel>Skill Name</FormPartLabel>
                <FormikTextField name="skillName"></FormikTextField>

                <FormPartLabel>Skill ID</FormPartLabel>
                <FormikTextField name="skillId" readOnly={verb === 'Update'}></FormikTextField>

                <FormPartLabel>Source</FormPartLabel>
                <FormikTextField name="skillSource"></FormikTextField>

                <ButtonGroup
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  spacing={2}
                  buttons={[
                    {
                      text: 'Save',
                      disabled: saving,
                      onClickEvent: props.handleSubmit,
                      variant: 'contained',
                    },
                    {
                      text: props.dirty ? 'Reset' : 'Cancel',
                      disabled: saving,
                      onClickEvent: () => {
                        if (props.dirty) {
                          props.resetForm();
                        } else {
                          closeModal();
                        }
                      },
                    },
                  ]}
                />
                <div style={{ marginTop: '1rem' }}>
                  {props.status && (
                    <Typography variant="caption" color="text.critical">
                      Error: {props.status}
                    </Typography>
                  )}
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    );
  };

  return (
    <DialogModal
      title={`${verb} Skill`}
      titleSizeVariant="displayLarge"
      dialogOpen={dialogOpen}
      showDialogTitle={true}
      content={customSkillForm()}
      buttonGroup={[]}
      maxWidth="md"
      fullWidth={true}
      closeModal={closeModal}
    />
  );
};

export default AddEditCustomSkillModal;
