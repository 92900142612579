import React, { ReactElement } from 'react';
import styled from 'styled-components';
import FacetOptionsBox from 'components/molecules/FacetOptionsBox';

type SearchBarFacetProps = {
  facets: { name: string; key: string }[];
  activeFacet: string | undefined;
  totalFacets: any;
  appliedFilters: string;
  handleClearAllFacets: (key: string) => void;
  handleSelectAllFacets: (key: string) => void;
  handleToggle: (area: any, value: any, displayValue?: any) => void;
};
const FacetWrapper = styled.div<{ $active: boolean }>`
  position: absolute;
  top: 3rem;
  left: 235px;
  width: 250px;
  display: ${params => (params.$active ? 'inherit' : 'none')};

  @media (max-width: 768px) {
    font-size: 0.75rem;
    left: 155px;
    width: 200px;
  }
`;

const StyledFacetOptionsBox = styled(FacetOptionsBox)`
  position: absolute;
  left: 250px;
`;

const SearchBarFacet = ({
  facets,
  activeFacet,
  totalFacets,
  appliedFilters,
  handleClearAllFacets,
  handleSelectAllFacets,
  handleToggle,
}: SearchBarFacetProps): ReactElement => {
  return (
    <>
      {facets.map(facet => {
        return (
          <FacetWrapper $active={activeFacet === facet.name} key={`filter-wrapper-${facet.name}`}>
            {facet.key !== '' && (
              <StyledFacetOptionsBox
                enabledSelectAll={true}
                showTitle={false}
                showSearch={true}
                title={facet.key}
                key={facet.key}
                facetList={(totalFacets[facet.key] || [])
                  .sort((a, b) => (b.count >= a.count ? 1 : -1))
                  .filter(v => {
                    return v.key !== null;
                  })
                  .map(v => {
                    let displayValue = v.key;
                    const value = v?.value?.toString();
                    if (['temporary', 'needsApproval', 'reviewed'].includes(facet.key)) {
                      v.key === 'true' ? (displayValue = 'yes') : (displayValue = 'no');
                    }
                    return {
                      name: `${displayValue} (${v.count})`,
                      value,
                      displayValue,
                    };
                  })}
                selectedFacets={appliedFilters[facet.key] || []}
                handleItemToggle={e => {
                  handleToggle(facet.key, e.target.value, e.target.getAttribute('data-displayvalue'));
                }}
                handleClear={() => {
                  handleClearAllFacets(facet.key);
                }}
                handleSelectAll={() => {
                  handleSelectAllFacets(facet.key);
                }}
              />
            )}
          </FacetWrapper>
        );
      })}
    </>
  );
};
export default SearchBarFacet;
