import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import ReactGA from 'react-ga';
import Role from 'classes/role';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { SpacingSizes, IconColorFilter } from 'types/types';
import Skeleton from '@mui/material/Skeleton';

const StatusInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.customSpacing.px.base * 4}px;
`;

const StatusInfoItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type RoleStatusInfoProps = {
  role?: Role;
  loading?: boolean;
  recordModification: (newData: Role) => void;
  sourceComponent: string;
};

const RoleStatusInfo = ({
  role,
  loading = false,
  recordModification,
  sourceComponent,
}: RoleStatusInfoProps): ReactElement => {
  const reviewStatus = role?.reviewed;
  const needsApproval = role?.needsApproval;

  const toggleNeedsApproval = () => {
    ReactGA.event({
      category: sourceComponent,
      action: 'needs approval toggle',
      label: !needsApproval ? 'on' : 'off',
    });
    recordModification?.(new Role({ needsApproval: !needsApproval }));
  };

  const toggleReviewStatus = (reviewed?: boolean) => {
    ReactGA.event({
      category: sourceComponent,
      action: 'reviewed toggle',
      label: reviewed ? 'on' : 'off',
    });
    recordModification?.(new Role({ reviewed }));
  };

  const reviewStatusSwitch = (): ReactElement => {
    return loading ? (
      <Skeleton>
        <Switch size="labelled" />
      </Skeleton>
    ) : (
      <Switch
        size="labelled"
        uncheckedlabel="Unreviewed"
        checkedlabel="Reviewed"
        data-cy={`${sourceComponent}-role-status-info-review-status-switch`.replaceAll(' ', '-').toLowerCase()}
        checked={!!reviewStatus}
        onChange={event => toggleReviewStatus(event.target.checked)}
      />
    );
  };

  const needsApprovalFlag = (): ReactElement => {
    return (
      <BaseIcon
        type={needsApproval ? IconType.FlagFilled : IconType.Flag}
        marginLeft={SpacingSizes.None}
        marginRight={SpacingSizes.None}
        size={'24px'}
        colorFilter={needsApproval ? IconColorFilter.Warning : IconColorFilter.Default}
        disabled={loading}
        onIconClick={toggleNeedsApproval}
        dataCy={`${sourceComponent}-role-status-info-needs-approval-flag`.replaceAll(' ', '-').toLowerCase()}
      />
    );
  };

  const statusInfoItem = (itemName?: string, itemValue?: ReactElement) => {
    const theme = useTheme();
    return (
      <>
        <StatusInfoItemContainer>
          <div>
            <Typography
              color={theme.colors.text.disabled}
              data-cy={`role-status-info-${itemName}-label`.replaceAll(' ', '-').toLowerCase()}
            >
              {itemName}
            </Typography>
          </div>
          <div>{itemValue || <></>}</div>
        </StatusInfoItemContainer>
      </>
    );
  };

  return (
    <>
      <StatusInfoContainer data-cy={'role-status-info-container'}>
        {statusInfoItem('Status', reviewStatusSwitch())}
        {statusInfoItem('Flag for Approval', needsApprovalFlag())}
      </StatusInfoContainer>
    </>
  );
};

export default RoleStatusInfo;
