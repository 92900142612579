import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import useCompanyContext from 'helpers/UseCompanyContext';
import Typography from '@mui/material/Typography';
import { Benchmark, BenchmarkCategory } from 'types/types';
import EmsiUIChip from 'components/atoms/EmsiUIChip';
import { Formik } from 'formik';
import useSkillProfileContext from 'helpers/useSkillProfileContext';
import AsyncSelect from 'react-select/async';
import asyncSelectStyles from 'helpers/asyncSelectStylesHelper';
import axios from 'helpers/api_helper';
import ReactGA from 'react-ga';
import useAuthContext from 'helpers/UseAuthContext';
import ContentColumn2Template from 'pages/templates/ContentColumn2Template';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import ButtonGroup, { EMSIBGButton } from 'components/atoms/ButtonGroup';
import RolesService from 'services/RolesService';
import isEqual from 'lodash/isEqual';
import Role from 'classes/role';

const resizeThreshold = 1000;

const LinkText = styled(Link)`
  padding: 0px ${props => props.theme.customSpacing.px.xxs}px;
`;

const BenchmarksWrapper = styled(TableContainer)`
  @media (max-width: ${resizeThreshold}px) {
    max-height: 100px;
    overflow-y: scrolling;
    border: 1px solid ${props => props.theme.colors.border.subdued};
    border-radius: ${props => props.theme.borderRadius.default};
  }
`;
const BenchmarksList = styled(Table)``;

const BenchmarkRow = styled(TableRow)`
  &:hover {
    background-color: ${props => props.theme.colors.surface.neutralSubdued};
    cursor: pointer;
  }

  &.activeRow {
    background-color: ${props => props.theme.colors.surface.selected};
  }

  & .MuiTableCell-root {
    padding: ${props => props.theme.customSpacing.px.xs}px;

    @media (max-width: ${resizeThreshold}px) {
      padding: ${props => props.theme.customSpacing.px.base}px;
    }
  }
`;

const Chips = styled.div`
  margin-bottom: ${props => props.theme.customSpacing.px.xs}px;
`;

const InputSection = styled.div`
  margin-top: ${props => props.theme.customSpacing.px.xs}px;
`;

type BenchmarkCategoryItem = 'locations' | 'companies' | 'industries';

let abortController = new AbortController();

const SkillProfileEditBenchmarkContent = (): ReactElement => {
  const theme = useTheme();
  const { isAdmin } = useAuthContext();
  const {
    companyId,
    benchmarks: companySharedBenchmarks,
    defaultBenchmark: companyDefaultBenchmark,
    benchmarksLoading,
  } = useCompanyContext();
  const { role, setRole, loadBenchmarkSkills, loadingSuggestedSkills } = useSkillProfileContext();

  const isBenchmarkDataPresent = (benchmark: Benchmark): boolean => {
    return (benchmark?.locations?.length || benchmark?.companies?.length || benchmark?.industries?.length) >= 0;
  };

  const customBenchmark = { benchmarkId: 'customBenchmark', locations: [], companies: [], industries: [] };
  const roleCustomBenchmark = { ...role?.benchmark, benchmarkId: 'customBenchmark' };
  const roleSharedBenchmark = role?.benchmarkId
    ? companySharedBenchmarks.find((benchmark: Benchmark) => benchmark.benchmarkId === role?.benchmarkId)
    : undefined;
  const benchmark = useMemo(() => {
    return isBenchmarkDataPresent(roleCustomBenchmark)
      ? { ...roleCustomBenchmark, benchmarkId: 'customBenchmark' }
      : isBenchmarkDataPresent(roleSharedBenchmark)
        ? roleSharedBenchmark
        : isBenchmarkDataPresent(companyDefaultBenchmark)
          ? companyDefaultBenchmark
          : customBenchmark;
  }, [role]);

  const [comparisonBenchmark, setComparisonBenchmark] = useState<Benchmark>(benchmark);
  const [originalBenchmark, setOriginalBenchmark] = useState<Benchmark>(benchmark);
  const [autocompleteValue] = useState('');
  const [isSavingBenchmark, setIsSavingBenchmark] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isWindowResized, setIsWindowResized] = useState(windowWidth <= resizeThreshold);

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    setIsWindowResized(windowWidth <= resizeThreshold);
  }, [windowWidth]);

  useEffect(() => {
    (async () => {
      if (!loadingSuggestedSkills?.Benchmark && comparisonBenchmark) {
        await loadBenchmarkSkills(comparisonBenchmark);
      }
    })();
  }, [comparisonBenchmark]);

  const changeBenchmark = (benchmarkId: string) => {
    if (benchmarkId === comparisonBenchmark.benchmarkId) return;
    let newBenchmark: Benchmark;
    if (benchmarkId === 'customBenchmark') {
      newBenchmark = isBenchmarkDataPresent(roleCustomBenchmark) ? roleCustomBenchmark : customBenchmark;
    } else {
      newBenchmark = companySharedBenchmarks.find(b => b.benchmarkId === benchmarkId);
    }

    ReactGA.event({
      category: 'skill-profile',
      action: 'add skill by benchmark',
      label: 'new benchmark selected',
    });

    setComparisonBenchmark(newBenchmark);
    setOriginalBenchmark(newBenchmark);
  };

  const sortedBenchmarkOptions = useMemo(() => {
    if (companySharedBenchmarks.length === 0) {
      return [{ id: 'customBenchmark', value: 'Custom Benchmark' }];
    }

    const benchmarkOptions = companySharedBenchmarks
      .filter(benchmark => !benchmark.companyDefault)
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      .map(c => {
        return { id: c.benchmarkId, value: c.name };
      });

    benchmarkOptions.unshift(
      { id: 'customBenchmark', value: 'Custom Benchmark' },
      { id: companyDefaultBenchmark.benchmarkId, value: `${companyDefaultBenchmark.name} (default)` }
    );
    return benchmarkOptions;
  }, [companySharedBenchmarks]);

  const autocompleteItems = async (autocompleteItem: 'company' | 'msa' | 'naics6', q: string) => {
    abortController?.abort();
    abortController = new AbortController();
    const res = await axios.get(
      `${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/postings/taxonomies/${autocompleteItem}?q=${q}`,
      { signal: abortController.signal }
    );
    return res.data.results.map(i => ({ value: i, label: i.name }));
  };

  const cloneBenchmarkAsCustomBenchmark = (benchmark: Benchmark): Benchmark => {
    return {
      benchmarkId: 'customBenchmark',
      locations: benchmark.locations,
      companies: benchmark.companies,
      industries: benchmark.industries,
    };
  };

  const addItemToBenchmark = (item: { id: string; value: string }, benchmarkCategory: BenchmarkCategoryItem) => {
    if ([...comparisonBenchmark[benchmarkCategory]].find(benchmarkItem => benchmarkItem.id === item.id)) return;

    const updatedCategory = [...comparisonBenchmark[benchmarkCategory], item];
    setComparisonBenchmark({
      ...cloneBenchmarkAsCustomBenchmark(comparisonBenchmark),
      [benchmarkCategory]: updatedCategory,
    });
  };

  const removeItemFromBenchmark = (index: number, benchmarkCategory: BenchmarkCategoryItem) => {
    const updatedCategory = [...comparisonBenchmark[benchmarkCategory]];
    updatedCategory.splice(index, 1);
    setComparisonBenchmark({
      ...cloneBenchmarkAsCustomBenchmark(comparisonBenchmark),
      [benchmarkCategory]: updatedCategory,
    });
  };

  const clearBenchmarkCategory = (benchmarkCategory: BenchmarkCategoryItem) => {
    setComparisonBenchmark({
      ...cloneBenchmarkAsCustomBenchmark(comparisonBenchmark),
      [benchmarkCategory]: [],
    });
  };

  const clearAllCategories = () => {
    setComparisonBenchmark(customBenchmark);
  };

  const revertBenchmark = () => {
    setComparisonBenchmark(originalBenchmark);
  };

  const benchmarkHasChanged = useMemo(() => {
    return !isEqual(comparisonBenchmark, originalBenchmark) && !isEqual(comparisonBenchmark, benchmark);
  }, [comparisonBenchmark, originalBenchmark]);

  const benchmarkCategoryItem = (
    categoryTitle: string,
    categoryList: BenchmarkCategory[],
    categoryType: BenchmarkCategoryItem,
    categoryAutocompleteItem: 'company' | 'msa' | 'naics6',
    categoryAutocompletePlaceholder: string
  ): ReactElement => {
    return (
      <InputSection data-cy={`benchmark-category-input-section-${categoryType}`}>
        <Typography variant="body" data-cy={`benchmark-category-title-${categoryType}`}>
          {categoryTitle}
        </Typography>
        {categoryList.length !== 0 && (
          <LinkText
            data-cy={`clear-benchmark-category-${categoryType}`}
            onClick={() => clearBenchmarkCategory(categoryType)}
          >
            Clear
          </LinkText>
        )}
        {categoryList && (
          <Chips data-cy={`benchmark-category-${categoryType}-items`}>
            {categoryList?.map((b, idx) => {
              return (
                <div key={b.id} data-cy={`benchmark-category-${categoryType}-item-${b.id}`}>
                  <EmsiUIChip
                    id={b.id}
                    label={b.name}
                    deleteHandler={() => removeItemFromBenchmark(idx, categoryType)}
                    style={{ margin: '6px 3px 0 3px' }}
                  />
                </div>
              );
            })}
          </Chips>
        )}
        <AsyncSelect
          value={autocompleteValue}
          onChange={(x: any) => {
            addItemToBenchmark(x?.value, categoryType);
          }}
          styles={asyncSelectStyles}
          noOptionsMessage={({ inputValue }) => {
            return inputValue ? 'No results' : 'Type to search';
          }}
          cacheOptions
          loadOptions={(q: string) => autocompleteItems(categoryAutocompleteItem, q)}
          placeholder={categoryAutocompletePlaceholder}
          classNamePrefix={`benchmark-custom-${categoryType}-autocomplete`}
        />
      </InputSection>
    );
  };

  const saveRoleBenchmark = async () => {
    let roleBenchmarkData = {};
    if (
      companySharedBenchmarks.find((benchmark: Benchmark) => benchmark.benchmarkId === comparisonBenchmark.benchmarkId)
    ) {
      roleBenchmarkData = {
        benchmark: null,
        benchmarkId: comparisonBenchmark.benchmarkId,
      };
    } else {
      roleBenchmarkData = {
        benchmark: {
          ...comparisonBenchmark,
          benchmarkId: null,
        },
        benchmarkId: null,
      };
    }
    try {
      setIsSavingBenchmark(true);
      if (role?.roleId) {
        await RolesService.updateRole(companyId, role.roleId, roleBenchmarkData);
      }
      setRole(new Role({ ...role?.data, ...roleBenchmarkData }));
      setComparisonBenchmark(comparisonBenchmark);
      setOriginalBenchmark(comparisonBenchmark);
    } catch (e) {
      alert('Unable to save benchmark. Try again later.');
      console.error(e);
    } finally {
      setIsSavingBenchmark(false);
    }
  };

  const bechmarkCanSave = (): boolean => {
    if (comparisonBenchmark.benchmarkId === 'customBenchmark') {
      if (isBenchmarkDataPresent(roleCustomBenchmark)) {
        return benchmarkHasChanged;
      } else {
        return true;
      }
    } else if (role?.benchmarkId !== comparisonBenchmark.benchmarkId) {
      return true;
    }

    return false;
  };

  const isFallbackBenchmarksAvailable = (): boolean => {
    return !isEqual(benchmark, customBenchmark) || role?.benchmark;
  };

  const isSaveDisabled = () => {
    return isFallbackBenchmarksAvailable() ? !bechmarkCanSave() : false;
  };

  const benchmarkFormActions: EMSIBGButton[] = [
    {
      text: 'Run',
      onClickEvent: () => console.log('Run updated benchmarks'),
      dataCy: 'button-run-benchmark',
      isLoadingButton: true,
      disabled: !benchmarkHasChanged,
      visible: false,
    },
    {
      text: 'Save To Role',
      onClickEvent: async () => await saveRoleBenchmark(),
      dataCy: 'button-save-benchmark-to-role',
      isLoadingButton: true,
      color: 'actionSecondary',
      disabled: isSaveDisabled(),
      loadingStatus: isSavingBenchmark,
      visible: isAdmin(),
    },
    {
      text: 'Clear All',
      onClickEvent: () => clearAllCategories(),
      dataCy: 'button-clear-all-benchmark',
      disabled:
        comparisonBenchmark?.locations.length === 0 &&
        comparisonBenchmark?.companies.length === 0 &&
        comparisonBenchmark?.industries.length === 0,
    },
  ];

  const benchmarkEditForm = (): ReactNode => {
    return (
      <>
        {benchmarkCategoryItem('Locations', comparisonBenchmark?.locations, 'locations', 'msa', 'Add a Location')}
        {benchmarkCategoryItem('Companies', comparisonBenchmark?.companies, 'companies', 'company', 'Add a Company')}
        {benchmarkCategoryItem('Industries', comparisonBenchmark?.industries, 'industries', 'naics6', 'Add a Industry')}
      </>
    );
  };

  const benchmarksTable = (): ReactNode => {
    return (
      <>
        <BenchmarksWrapper>
          {benchmarksLoading && <Typography variant="caption">Loading roles...</Typography>}
          {!benchmarksLoading && (
            <BenchmarksList className={'benchmark-selection-table'}>
              <TableBody>
                {sortedBenchmarkOptions &&
                  sortedBenchmarkOptions.map(b => (
                    <BenchmarkRow
                      key={`benchmark-list-item-${b.id}`}
                      id={`benchmark-list-item-${b.id}`}
                      data-cy={`benchmark-list-item-${b.id}`}
                      className={
                        b.id === comparisonBenchmark.benchmarkId ? 'benchmark-item activeRow' : 'benchmark-item'
                      }
                      onClick={() => changeBenchmark(b.id)}
                    >
                      <TableCell>
                        <Typography>{b.value}</Typography>
                      </TableCell>
                    </BenchmarkRow>
                  ))}
              </TableBody>
            </BenchmarksList>
          )}
        </BenchmarksWrapper>
      </>
    );
  };

  return (
    <>
      <Grid container justifyContent="space-between" direction="row" sx={{ display: 'flex', flexWrap: 'nowrap' }}>
        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            component="div"
            variant="strong"
            sx={{ height: '100%', alignItems: 'center', display: 'inline-flex' }}
          >
            1. Select Benchmark
          </Typography>
          {benchmarkHasChanged && (
            <LinkText data-cy="button-revert-benchmark" onClick={() => revertBenchmark()}>
              Revert
            </LinkText>
          )}
        </Grid>
      </Grid>
      <div style={{ marginTop: `${theme.customSpacing.px.xs}px` }}>
        {sortedBenchmarkOptions && (
          <Formik initialValues={{}} onSubmit={() => undefined}>
            <ContentColumn2Template
              breakpoint={resizeThreshold}
              paddingVariant="narrow-sideNone"
              mainComponentPosition="right"
              mainComponent={benchmarkEditForm()}
              sideComponent={benchmarksTable()}
              flexDirection={isWindowResized ? 'column' : 'row'}
            />
          </Formik>
        )}
      </div>
      <div style={{ marginTop: `${theme.customSpacing.px.base * 6}px` }}>
        <ButtonGroup buttons={benchmarkFormActions} justifyContent="flex-end" wrap="nowrap" />
      </div>
      <div style={{ marginTop: `${theme.customSpacing.px.base * 6}px` }}>
        <Typography variant="strong" data-cy="benchmark-add-skills-title">
          {'2. Add skills to skill profile'}
        </Typography>
      </div>
    </>
  );
};

export default SkillProfileEditBenchmarkContent;
