import React, { useState, useEffect, ReactElement, useMemo } from 'react';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import UseCompanyContext from 'helpers/UseCompanyContext';
import batchLogService from 'services/batchLogService';
import BatchLog from 'classes/batchLog';
import queryString from 'query-string';
import dayjs from 'dayjs';

import ButtonGroup, { EMSIBGButton } from 'components/atoms/ButtonGroup';
import TablePagination from 'components/molecules/TablePagination';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import styled, { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import FileProcessingProgressBar from 'components/molecules/FileProcessingProgressBar';
import ReactGA from 'react-ga';

const Wrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;
const Pagination = styled(Box)`
  box-shadow: ${props => props.theme.boxShadow.surface1};
  padding: 0px ${props => props.theme.customSpacing.px.base * 10}px;
`;

export const BatchLogPage = (): ReactElement => {
  const theme = useTheme();
  const navigate = useNavigate();
  const qs: any = queryString.parse(window.location.search, { parseBooleans: true, parseNumbers: true });
  const { companyId } = UseCompanyContext();
  const [batchLogs, setBatchLogs] = useState<BatchLog[]>([]);
  const [totalBatchLogCount, setTotalBatchLogCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(qs.p || 0);
  const [rowsPerPage, setRowsPerPage] = useState(qs.n || 10);
  const [selectedRows, setSelectedRows] = useState(0);
  const [loading, setLoading] = useState(false);

  const loadProcessBatchLogs = async () => {
    const { batchLogs, totalCount } = await batchLogService.getBatchLogs({
      companyId,
      limit: rowsPerPage,
      offset: currentPage * rowsPerPage,
    });
    setBatchLogs(batchLogs);
    setTotalBatchLogCount(totalCount);
  };

  const selectionRowChange = (selectedRowIds: any[]) => {
    setSelectedRows(selectedRowIds.length);
  };

  const pageChangeHandler = page => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const getProcessLogs = async () => {
      setLoading(true);
      await loadProcessBatchLogs();
      setLoading(false);
    };
    getProcessLogs();
  }, [companyId, currentPage, rowsPerPage]);

  const buttonGroups: EMSIBGButton[] = [
    {
      text: `${selectedRows} Selected`,
      color: 'actionSecondary',
      onClickEvent: () => true,
      variant: 'outlined',
      endIcon: <BaseIcon type={IconType.ChevronDown} size="8px" />,
      disabled: true,
      dataCy: 'batchlogs-menu-button',
    },
    {
      text: 'Export All Data',
      color: 'actionSecondary',
      onClickEvent: () => true,
      variant: 'outlined',
      disabled: true,
      loadingMessage: 'exporting',
      loadingStatus: false,
      dataCy: 'batchlogs-export-button',
    },
  ];
  const ActionsComponent = (): ReactElement => {
    return (
      <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap-reverse', justifyContent: 'right' }}>
        <ButtonGroup buttons={buttonGroups} wrap="nowrap" justifyContent="flex-end" />
      </Box>
    );
  };

  const skillProfileCriteriaSearchParams = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set('p', currentPage);
    searchParams.set('n', rowsPerPage);
    const searchParamsString = searchParams.toString();

    if (searchParamsString.length > window.location.search.length) {
      ReactGA.event({
        category: 'skillProfiles',
        action: 'skill profiles criteria length',
        label: searchParamsString.length.toString(),
      });
    }

    const newRelativePathQuery = `${window.location.pathname}?${searchParamsString}`;
    window.history.pushState(null, '', newRelativePathQuery);
    return searchParams;
  }, [currentPage, rowsPerPage]);

  const columns: GridColDef<BatchLog>[] = [
    {
      field: 'type',
      headerName: 'Type',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'fileName',
      headerName: 'Name',
      flex: 0.3,
      headerAlign: 'left',
      align: 'left',
      renderCell: param => {
        return (
          <>
            <Button
              variant="text"
              onClick={() =>
                navigate(`/admin/processing-statuses/${param.id}?${skillProfileCriteriaSearchParams.toString()}`)
              }
            >
              {param.value}
            </Button>
          </>
        );
      },
    },
    {
      field: 'firstActionDate',
      headerName: 'Created At',
      flex: 0.2,
      headerAlign: 'left',
      align: 'left',
      valueFormatter: param => {
        return dayjs(param.value).format('HH:mm:ss - MM-DD-YYYY');
      },
    },
    {
      field: 'lastActionDate',
      headerName: 'Updated At',
      flex: 0.2,
      headerAlign: 'left',
      align: 'left',
      valueFormatter: param => {
        return dayjs(param.value).format('HH:mm:ss - MM-DD-YYYY');
      },
    },
    {
      field: 'source',
      headerName: 'Source',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'lastStatus',
      headerName: 'Status',
      flex: 0.2,
      headerAlign: 'left',
      align: 'left',
      renderCell: param => {
        return (
          <>
            <FileProcessingProgressBar
              showLabel={false}
              requestId={param.id.toString()}
              width={'120px'}
              autoRefresh={true}
            />
          </>
        );
      },
    },
    {
      field: 'lastStatusMessage',
      headerName: 'Details',
      flex: 0.2,
      headerAlign: 'left',
      align: 'left',
      renderCell: param => {
        return <span title={param.value}>{param.value}</span>;
      },
    },
  ];

  return (
    <MainContentTemplate pageTitle="Processing Statuses">
      <ContentHeaderTemplate contentTitle="Processing Statuses" actionsComponent={<ActionsComponent />} />
      <Wrapper>
        <Pagination>
          <TablePagination
            dataCy="processbatchlog-list-table-pagination"
            total={totalBatchLogCount}
            page={currentPage}
            setPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Pagination>
        <DataGrid
          columns={columns}
          rows={batchLogs}
          loading={loading}
          getRowId={(r: BatchLog) => r.requestId}
          pagination
          paginationMode="server"
          rowCount={totalBatchLogCount}
          onPageChange={pageChangeHandler}
          pageSize={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          autoHeight
          checkboxSelection
          hideFooterPagination
          hideFooter
          onSelectionModelChange={selectionRowChange}
        />
        <Pagination style={{ boxShadow: theme.boxShadow.surface1 }}>
          <TablePagination
            dataCy="processbatchlog-list-table-pagination"
            total={totalBatchLogCount}
            page={currentPage}
            setPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Pagination>
      </Wrapper>
    </MainContentTemplate>
  );
};

export default BatchLogPage;
