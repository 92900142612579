import { PaletteOptions } from '@mui/material/styles';
import { DefaultTheme } from 'styled-components';

export default (gs: DefaultTheme): PaletteOptions => {
  return {
    text: {
      default: gs.colors.text.default,
      subdued: gs.colors.text.subdued,
      disabled: gs.colors.text.disabled,
      information: gs.colors.text.information,
      warning: gs.colors.text.warning,
      success: gs.colors.text.success,
      critical: gs.colors.text.critical,
      onPrimary: gs.colors.text.onPrimary,
      onCritical: gs.colors.text.onCritical,
    },
    border: {
      default: gs.colors.border.default,
      subdued: gs.colors.border.subdued,
      hovered: gs.colors.border.hovered,
      information: gs.colors.border.information,
      success: gs.colors.border.success,
      critical: gs.colors.border.critical,
    },
    interactive: {
      default: gs.colors.interactive.default,
      defaultDisabled: gs.colors.interactive.defaultDisabled,
      critical: gs.colors.interactive.critical,
      criticalDisabled: gs.colors.interactive.criticalDisabled,
    },
    actionPrimary: {
      main: gs.colors.action.primary,
      hovered: gs.colors.action.primaryHovered,
      pressed: gs.colors.action.primaryPressed,
      depressed: gs.colors.action.primaryDepressed,
      disabled: gs.colors.action.primaryDisabled,
      contrastText: gs.colors.text.onPrimary,
    },
    actionSecondary: {
      main: gs.colors.action.secondary,
      hovered: gs.colors.action.secondaryHovered,
      pressed: gs.colors.action.secondaryPressed,
      depressed: gs.colors.action.secondaryDepressed,
      disabled: gs.colors.action.secondaryDisabled,
      contrastText: gs.colors.text.default,
    },
    actionCritical: {
      main: gs.colors.action.critical,
      hovered: gs.colors.action.criticalHovered,
      pressed: gs.colors.action.criticalPressed,
      depressed: gs.colors.action.criticalDepressed,
      disabled: gs.colors.action.criticalDisabled,
      contrastText: gs.colors.text.onCritical,
    },
    surfaceDefault: { main: gs.colors.surface.default, contrastText: gs.colors.text.default },
    surfaceDisabled: { main: gs.colors.surface.disabled, contrastText: gs.colors.text.disabled },
    surfaceSelected: { main: gs.colors.surface.selected, contrastText: gs.colors.interactive.default },
    surfaceInformation: { main: gs.colors.surface.information, contrastText: gs.colors.text.information },
    surfaceNeutral: { main: gs.colors.surface.neutral, contrastText: gs.colors.text.default },
    surfaceCritical: { main: gs.colors.surface.critical, contrastText: gs.colors.text.critical },
    surfaceCriticalSubdued: { main: gs.colors.surface.criticalSubdued, contrastText: gs.colors.text.critical },
    surfaceSuccess: { main: gs.colors.surface.success, contrastText: gs.colors.text.success },
    surfaceWarning: { main: gs.colors.surface.warning, contrastText: gs.colors.text.warning },
    surfaceProcessing: { main: gs.colors.progressBar.segment5, contrastText: '#6f11a6' },
    iconSuccess: { main: gs.colors.icon.success.hex },
    iconInformation: { main: gs.colors.icon.information.hex },
    iconWarning: { main: gs.colors.icon.warning.hex },
    iconCritical: { main: gs.colors.icon.critical.hex },
  } as PaletteOptions;
};
