import styled from 'styled-components';

const BodyPageFullScreen = styled.div`
  width: 100%;
  min-height: calc(100vh - 140px);

  @media (max-width: 768px) {
    padding: 8px;
  }
`;

export default BodyPageFullScreen;
