import { DefaultTheme } from 'styled-components';

const customStyles: any = (gs: DefaultTheme) => ({
  container: (provided: any, state: any): any => ({
    ...provided,
    width: '100%',
    marginBottom: '16px',
    marginTop: '4px',
    color: `${gs.colors.text.default}`,
    borderRadius: `${gs.borderRadius.default}`,
    border: `1px solid ${gs.colors.border.default}`,
    paddingLeft: state.selectProps.hideIcon ? '0px' : '12px',
    outline: 'none',
    backgroundColor: state.selectProps.isDisabled ? `${gs.colors.surface.disabled}` : 'transparent',
  }),
  control: (provided: any, state: any): any => ({
    ...provided,
    fontSize: `${gs.customTypography.desktop.body.size}`,
    cursor: 'text',
    fontWeight: `${gs.customTypography.desktop.body.weight}`,
    border: 'none',
    padding: `${gs.customSpacing.px.xxs}px ${gs.customSpacing.px.xs}px`,
    width: '100%',
    minHeight: `${gs.customSpacing.px.base * 9}px`,
    backgroundSize: 'contain',
    background: state.selectProps.hideIcon ? 'none' : 'url(/icons/system/search.svg) no-repeat left',
    paddingLeft: state.selectProps.hideIcon ? '8px' : '28px',
    boxShadow: 'none',
  }),
  menu: (provided: any, state: any): any => ({
    ...provided,
    borderRadius: `${gs.borderRadius.default}`,
    marginLeft: state.selectProps.hideIcon ? '0px' : '-12px',
  }),
  placeholder: (provided: any): any => ({
    ...provided,
    color: `${gs.colors.text.disabled}`,
  }),
  input: (provided: any): any => ({
    ...provided,
    padding: '0px',
    color: `${gs.colors.text.default}`,
  }),
  valueContainer: (provided: any): any => ({
    ...provided,
    padding: 0,
    height: `${gs.customSpacing.px.base * 5}px`,
    // paddingTop: 1,
  }),
  indicatorSeparator: (): any => ({
    display: 'none',
  }),
  indicatorsContainer: (): any => ({
    display: 'none',
  }),
});

export default customStyles;
