import React, { ReactElement } from 'react';
import { EmsiUIBadgeColorPalette, IconColorFilter, SpacingSizes } from 'types/types';
import { IconType } from 'components/atoms/BaseIcon';
import EmsiUIBadge from 'components/atoms/EmsiUIBadge';
import { GrowthCategory } from 'types/api_responses';
import styled from 'styled-components';

const StyledEmsiUIBadge = styled(EmsiUIBadge)`
  margin: 0.5rem 0;
`;

let colorString: EmsiUIBadgeColorPalette;
let labelString = '';

const badgeBuilder = (percentage: number): ReactElement => {
  if (!isFinite(percentage)) {
    colorString = EmsiUIBadgeColorPalette.SurfaceCritical;
  } else {
    if (percentage >= 0.8) {
      colorString = EmsiUIBadgeColorPalette.SurfaceSuccess;
    } else if (percentage >= 0.3) {
      colorString = EmsiUIBadgeColorPalette.SurfaceWarning;
    } else {
      colorString = EmsiUIBadgeColorPalette.SurfaceCritical;
    }
  }
  labelString = !isFinite(percentage) ? '0%' : Math.round(percentage * 100).toString() + '%';
  return <StyledEmsiUIBadge color={colorString} label={labelString} />;
};

const reviewStatusColor = (reviewStatus: string): EmsiUIBadgeColorPalette => {
  let reviewStatusColor = EmsiUIBadgeColorPalette.SurfaceCritical;
  if (reviewStatus === 'Reviewed') {
    reviewStatusColor = EmsiUIBadgeColorPalette.SurfaceSuccess;
  } else if (reviewStatus === 'Needs Approval') {
    reviewStatusColor = EmsiUIBadgeColorPalette.SurfaceWarning;
  }
  return reviewStatusColor;
};

const reviewStatusLabel = (reviewStatus: string): string => {
  // Dont show High | Medium | Low, instead show 'Unreviewed' as per new designs
  return ['Reviewed', 'Needs Approval'].includes(reviewStatus) ? reviewStatus : 'Unreviewed';
};

const lightcastSkillLink = (skillId: string): string => {
  return `https://lightcast.io/open-skills/skills/${skillId}`;
};

const skillProficiency = [
  { key: 'required', name: 'Required', standard: 'true' },
  { key: 'preferred', name: 'Preferred', standard: 'true' },
];

const getSkillGrowthStatus = (growthCategory?: GrowthCategory) => {
  switch (growthCategory) {
    case 'Lagging':
      return {
        icon: IconType.DiagonalArrowDown,
        iconColor: IconColorFilter.Critical,
        iconSize: 10,
        marginBottom: SpacingSizes.None,
        chipColor: EmsiUIBadgeColorPalette.SurfaceCritical,
      };
    case 'Growing':
    case 'Rapidly Growing':
      return {
        icon: IconType.DiagonalArrowUp,
        iconColor: IconColorFilter.Success,
        iconSize: 10,
        marginBottom: SpacingSizes.None,
        chipColor: EmsiUIBadgeColorPalette.SurfaceSuccess,
      };
    case 'Stable':
    default:
      return {
        icon: IconType.ArrowRightLine,
        iconColor: IconColorFilter.Default,
        iconSize: 16,
        marginBottom: SpacingSizes.None,
        chipColor: EmsiUIBadgeColorPalette.SurfaceNeutral,
      };
  }
};
export {
  badgeBuilder,
  reviewStatusColor,
  reviewStatusLabel,
  skillProficiency,
  lightcastSkillLink,
  getSkillGrowthStatus,
};
