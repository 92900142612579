import axios from 'helpers/api_helper';

export interface JPAFilter {
  title?: string[];
  when?: {
    start: string;
    end: string;
  };
  skills?: string[];
}

export type JPAMetric =
  | 'unique_postings'
  | 'duplicate_postings'
  | 'total_postings'
  | 'posting_intensity'
  | 'unique_companies'
  | 'median_posting_duration'
  | 'min_salary'
  | 'median_salary'
  | 'max_salary'
  | 'median_max_salary'
  | 'average_salary';

export interface JPATotalsResponse {
  median_salary: number;
  unique_postings: number;
}

// TODO: specify taxonomny versions we're using
// TODO: can we type response based on metrics?
export const getTotals = async (filter: JPAFilter, metrics: JPAMetric[]) => {
  return (
    await axios.post<{ data: { totals: JPATotalsResponse } }>(
      `${process.env.REACT_APP_APIGATEWAY_URL}/emsiservices/jpa/totals`,
      {
        filter,
        metrics,
      }
    )
  ).data.data.totals;
};
