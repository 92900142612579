import { ButtonProps } from '@mui/material';
import type {} from '@mui/lab/themeAugmentation';
import { DefaultTheme } from 'styled-components';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    actionPrimary: true;
    actionSecondary: true;
    actionCritical: true;

    primary: false;
    secondary: false;
    info: false;
    success: false;
    warning: false;
    error: false;
    inherit: false;
  }
}

export const muiButtonOverride = (gs: DefaultTheme): any => ({
  defaultProps: {
    variant: 'outlined',
    color: 'actionSecondary',
    disableElevation: true,
    disableFocusRipple: true,
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      fontSize: gs.customTypography.desktop.button.size,
      fontWeight: gs.customTypography.desktop.button.weight,
      lineHeight: gs.customTypography.desktop.button.lineHeight,
      whiteSpace: 'nowrap',
      minWidth: 'unset',
      '@media (max-width:768px)': {
        fontSize: gs.customTypography.mobile.button.size,
      },
      '&:focus-visible': {
        boxShadow:
          '0px 0px 0px 1px #FFFFFF, 0px 0px 0px 3px #4E91F5, 0px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
      },
      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
        cursor: 'default',
      },
      ...((ownerState.variant !== 'text' || !ownerState.variant) && {
        color: theme.palette[`${ownerState.color}`].contrastText,
        marginRight: `${gs.customSpacing.px.none}px`,
        boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
        textTransform: 'capitalize',
        '&.MuiButton-sizeSmall': {
          padding: '6px 12px',
          height: '32px',
        },
        '&.MuiButton-sizeMedium': {
          padding: '8px 16px',
          height: '36px',
        },
        '&.MuiButton-sizeLarge': {
          padding: '12px 24px',
          height: '44px',
        },
        '&:hover': {
          backgroundColor: theme.palette[`${ownerState.color}`].hovered,
          boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)',
          color: `${theme.palette[`${ownerState.color}`].contrastText} !important`,
        },
        '&:active': {
          backgroundColor: theme.palette[`${ownerState.color}`].pressed,
          boxShadow: 'inset 0px 2px 0px rgba(0, 0, 0, 0.25)',
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette[`${ownerState.color}`].disabled,
          boxShadow: 'none',
        },
        '&.MuiButton-containedActionSecondary': {
          border: `1px solid ${gs.colors.border.default}`,
        },
      }),
      ...(ownerState.variant === 'outlined' && {
        backgroundColor: theme.palette.actionSecondary.main,
        border: `1px solid ${gs.colors.border.default}`,
        color: theme.palette.actionSecondary.contrastText,
        '&:hover': {
          border: `1px solid ${gs.colors.border.default}`,
          backgroundColor: theme.palette.actionSecondary.hovered,
        },
        '&:active': {
          backgroundColor: theme.palette.actionSecondary.pressed,
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.actionSecondary.disabled,
        },
      }),
      ...(ownerState.variant === 'text' && {
        color: ownerState.color === 'actionCritical' ? gs.colors.interactive.critical : gs.colors.interactive.default,
        padding: '0px 4px 2px 4px',
        backgroundColor: 'transparent',
        textTransform: 'unset',
        '&.MuiButton-textSizeSmall': {
          fontSize: gs.customTypography.desktop.caption.size,
          fontWeight: gs.customTypography.desktop.caption.weight,
          lineHeight: gs.customTypography.desktop.caption.lineHeight,
          '@media (max-width:768px)': {
            fontSize: gs.customTypography.mobile.caption.size,
          },
        },
        '&.MuiButton-textSizeMedium': {
          fontSize: gs.customTypography.desktop.body.size,
          fontWeight: gs.customTypography.desktop.body.weight,
          lineHeight: gs.customTypography.desktop.body.lineHeight,
          '@media (max-width:768px)': {
            fontSize: gs.customTypography.mobile.body.size,
          },
        },
        '&.MuiButton-textSizeLarge': {
          fontSize: gs.customTypography.desktop.body.size,
          fontWeight: gs.customTypography.desktop.body.weight,
          lineHeight: gs.customTypography.desktop.body.lineHeight,
          '@media (max-width:768px)': {
            fontSize: gs.customTypography.mobile.body.size,
          },
        },
        '&:hover': {
          backgroundColor: 'transparent',
          textDecorationLine: 'underline',
        },
        '&:active': {
          backgroundColor:
            ownerState.color === 'actionCritical'
              ? theme.palette.surfaceCriticalSubdued.main
              : theme.palette.surfaceSelected.main,
        },
        '& + .Mui-disabled': {
          color:
            ownerState.color === 'actionCritical'
              ? gs.colors.interactive.criticalDisabled
              : gs.colors.interactive.defaultDisabled,
        },
        '&.MuiButton-containedActionSecondary': {
          border: `1px solid ${gs.colors.border.default}`,
        },
      }),
    }),
    sizeSmall: {},
    sizeMedium: {},
    sizeLarge: {},
  } as ButtonProps,
});
