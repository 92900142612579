import React, { ReactElement } from 'react';
import styled from 'styled-components';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';

const LogoText = styled.div`
  max-width: 240px;
`;

const AppLogo = (): ReactElement => {
  return (
    <>
      <div style={{ display: 'block' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <BaseIcon type={IconType.LogoTT} size="unset" alt="logo icon" />
          <LogoText>
            <BaseIcon type={IconType.LogoTTText} size="unset" alt="Talent Transform" />
          </LogoText>
        </div>
      </div>
    </>
  );
};

export default AppLogo;
