import React, { ReactElement } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Chevron from 'components/atoms/Chevron';
import RolesAnalytics from 'components/organisms/RolesAnalytics';
import useCompanyContext from 'helpers/UseCompanyContext';
import useUserContext from 'helpers/UseUserContext';
import useAuthContext from 'helpers/UseAuthContext';
import ContainerPage from 'components/atoms/ContainerPage';
import ContentPlaceHolder from 'pages/templates/ContentPlaceHolder';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { productModule } from 'helpers/product_modules';

const Title = styled(Typography)`
  color: ${props => props.theme.colors.text.onBackground};
  @media (max-width: 768px) {
    width: 90%;
    margin-top: ${props => `${props.theme.customSpacing.px.base * 4}px`};
  }
`;

const BodyPage = styled.div<{ $backgroundImageUrl: string }>`
  width: 100%;
  min-height: calc(100vh - 140px);

  @media (max-width: 768px) {
    padding: ${props => `${props.theme.customSpacing.px.xxs}px`};
  }

  ${props =>
    props.$backgroundImageUrl &&
    css`
      background-image: url(${props.$backgroundImageUrl});
      background-position: right 63px top -31px;
      background-repeat: no-repeat;
      background-size: 370px;
    `};
`;

const PageContainerCustom = styled(ContainerPage)`
  flex-direction: row;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: unset;
  }
`;

const HomeMainContent = styled.div`
  width: 100%;
  margin-right: ${props => `${props.theme.customSpacing.px.base * 8}px`};
`;

const SideColumn = styled.div`
  width: 80%;
  min-width: 300px;
  max-width: 750px;

  @media (max-width: 1024px) {
    width: 100%;
    min-width: unset;
    max-width: unset;
  }
`;

const MainCard = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.surface.default};
  border-radius: ${props => props.theme.borderRadius.default};
  box-shadow: ${props => props.theme.boxShadow.surface2};
  font-weight: 600;
  height: fit-content;
  margin-bottom: ${props => `${props.theme.customSpacing.px.m}px`};
  padding: ${props => `${props.theme.customSpacing.px.base * 8}px ${props.theme.customSpacing.px.base * 4}px`};

  @media (max-width: 1024px) {
    margin-bottom: ${props => `${props.theme.customSpacing.px.s}px`};
  }
`;

const Card = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.surface.default};
  border-radius: ${props => props.theme.borderRadius.default};
  box-shadow: ${props => props.theme.boxShadow.surface2};
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  margin-bottom: ${props => `${props.theme.customSpacing.px.m}px`};
  padding: ${props => `${props.theme.customSpacing.px.base * 8}px ${props.theme.customSpacing.px.base * 4}px`};

  @media (max-width: 1024px) {
    margin-bottom: ${props => `${props.theme.customSpacing.px.s}px`};
  }
`;

const SectionCard = styled(Card)`
  cursor: pointer;
  padding: ${props => `${props.theme.customSpacing.px.base * 7}px ${props.theme.customSpacing.px.base * 4}px`};

  .card-text {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
  }

  .side-panel-title {
    display: inline-block;
    width: 80px;
    white-space: nowrap;
  }

  .innerDesc {
    @media (max-width: 1024px) {
      text-align: center;
      width: 100%;
    }

    @media (max-width: 600px) {
      text-align: left;
    }
  }
`;

const RowIconWrapper = styled.div`
  display: flex;
  justify-content: right;
  padding-right: ${props => `${props.theme.customSpacing.px.xs}px`};
  transition: padding-right ease-in-out;
`;

function HomePage(): ReactElement {
  const theme = useTheme();
  const { hasProductModule } = useCompanyContext();
  const { userDetails } = useUserContext();
  const { isAdmin } = useAuthContext();
  const navigate = useNavigate();
  const SideColumnSection = (onClickPath: string, title: string, description: string, dataCy: string): ReactElement => {
    return (
      <SectionCard onClick={() => navigate(onClickPath)} data-cy={dataCy}>
        <Grid container>
          <Grid item xs={10}>
            <Grid container>
              <Grid item sm={4} className="side-panel-title">
                <Typography variant="strong">{title}</Typography>
              </Grid>
              <Grid item sm={8} className="innerDesc">
                <Typography variant="body">{description}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
            <RowIconWrapper>
              <Chevron horizontal={true} />
            </RowIconWrapper>
          </Grid>
        </Grid>
      </SectionCard>
    );
  };

  return (
    <ContentPlaceHolder pageTitle="Welcome to Talent Transform">
      <BodyPage $backgroundImageUrl={theme.brand.pngHomeBackground}>
        <div style={{ padding: `${theme.customSpacing.px.m}px` }}>
          <Title variant="displayLarge" id="welcomeMessage" data-cy="welcome-message">
            Welcome, {userDetails.first_name}
          </Title>
          <PageContainerCustom>
            <HomeMainContent>
              <MainCard data-cy={'roles-analytics-section'}>
                <RolesAnalytics />
              </MainCard>
            </HomeMainContent>
            <SideColumn>
              {SideColumnSection('/roles', 'Roles', 'Add and map your roles to data', 'navigate-to-roles')}
              {hasProductModule(productModule.SkillId) &&
                SideColumnSection(
                  '/skill-profiles',
                  'Skill Profiles',
                  'Manage the skill profile for your role',
                  'navigate-to-skill-profiles'
                )}
              {isAdmin() &&
                SideColumnSection(
                  '/admin/company-settings',
                  'Settings',
                  'Configure your company settings',
                  'navigate-to-settings'
                )}
            </SideColumn>
          </PageContainerCustom>
        </div>
      </BodyPage>
    </ContentPlaceHolder>
  );
}

export default HomePage;
