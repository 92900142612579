import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import ButtonGroup, { EMSIBGButton } from 'components/atoms/ButtonGroup';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import TablePagination from 'components/molecules/TablePagination';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import styled from 'styled-components';
import queryString from 'query-string';
import Box from '@mui/material/Box';
import AddEditCustomSkillModal from 'components/organisms/modals/AddEditCustomSkillModal';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { CustomSkill } from 'types/types';
import { Alert, Menu, MenuItem } from '@mui/material';
import useCustomSkillsHelper from 'helpers/useCustomSkillsHelper';

const Wrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

const Pagination = styled(Box)`
  box-shadow: ${props => props.theme.boxShadow.surface1};
  padding: 0px ${props => props.theme.customSpacing.px.base * 10}px;
`;

const ActionComponentsContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: right;

  .button-group {
    justify-content: flex-end;

    @media (max-width: 768px) {
      justify-content: flex-start;
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: ${props => props.theme.customSpacing.px.xs}px;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s linear 0ms,
    opacity 0ms;

  .MuiDataGrid-row:hover & {
    visibility: visible;
    opacity: 1;
    transition:
      visibility 0s linear 0s,
      opacity 0ms;
  }
`;

const CompanyCustomSkillsPage = () => {
  const {
    customSkills,
    deleteCustomSkill,
    deleteCustomSkills,
    addCustomSkill,
    editCustomSkill,
    loadingCustomSkills,
    totalCustomSkills,
  } = useCustomSkillsHelper();
  const qs: any = queryString.parse(window.location.search, { parseBooleans: true, parseNumbers: true });
  const [currentPage, setCurrentPage] = useState(qs.p || 0);
  const [rowsPerPage, setRowsPerPage] = useState(qs.n || 10);
  const [filteredCustomSkills, setFilteredCustomSkills] = useState(customSkills);
  const [selectedCustomSkillsIds, setSelectedCustomSkillsIds] = useState<string[]>([]);
  const [selectedRowsCount, setSelectedRowsCount] = useState<number>(0);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedCustomSkillsMenuItemsAnchor, setSelectedCustomSkillsMenuItemsAnchor] = useState<HTMLElement>();
  const selectedSkillsMenuOpen = Boolean(selectedCustomSkillsMenuItemsAnchor);

  const [showCustomSkillModal, setShowCustomSkillModal] = useState(false);
  const [addEditCustomSkill, setAddEditCustomSkill] = useState<CustomSkill>();

  const columns: GridColDef<CustomSkill>[] = [
    {
      field: 'skillName',
      headerName: 'Skill Name',
      flex: 0.2,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'skillId',
      headerName: 'Skill ID',
      flex: 0.2,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'skillSource',
      headerName: 'Source',
      flex: 0.2,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
      renderCell: params => {
        return (
          <IconContainer>
            <BaseIcon onIconClick={() => handleCustomSkillEdit(params.row)} type={IconType.Edit} size="16px" />
            <BaseIcon
              onIconClick={async () => {
                try {
                  await deleteCustomSkill(params.row.customSkillId);
                } catch (error: any) {
                  alert(error.message);
                }
              }}
              type={IconType.Delete}
              size="16px"
            />
          </IconContainer>
        );
      },
    },
  ];

  const handleCustomSkillEdit = (customSkill: CustomSkill) => {
    setAddEditCustomSkill(customSkill);
    setShowCustomSkillModal(true);
  };

  useEffect(() => {
    const start = currentPage * rowsPerPage;
    const end = start + rowsPerPage;
    setFilteredCustomSkills(customSkills.slice(start, end));
    updateQueryStringsParams();
  }, [JSON.stringify(customSkills), rowsPerPage, currentPage]);

  const updateQueryStringsParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('p', currentPage);
    searchParams.set('n', rowsPerPage);
    const searchParamsString = searchParams;
    window.history.replaceState(null, '', `${window.location.pathname}?${searchParamsString}`);
  };

  const buttonGroups: EMSIBGButton[] = [
    {
      text: `${selectedRowsCount} Selected`,
      color: 'actionSecondary',
      onClickEvent: (event: React.MouseEvent<HTMLElement>) => {
        setSelectedCustomSkillsMenuItemsAnchor(event.currentTarget);
      },
      variant: 'outlined',
      endIcon: <BaseIcon type={IconType.ChevronDown} size="8px" />,
      disabled: loadingCustomSkills || selectedRowsCount === 0,
      dataCy: 'custom-skills-menu-button',
    },
    {
      text: 'Add Skill',
      color: 'actionPrimary',
      onClickEvent: () => {
        setAddEditCustomSkill(undefined);
        setShowCustomSkillModal(true);
      },
      variant: 'contained',
      loadingMessage: 'exporting',
      loadingStatus: false,
      dataCy: 'custom-skills-add-button',
    },
  ];

  const ActionsComponent = useMemo((): ReactElement => {
    return (
      <ActionComponentsContainer>
        <Box sx={{ display: 'flex' }}>
          <Menu
            anchorEl={selectedCustomSkillsMenuItemsAnchor}
            open={selectedSkillsMenuOpen}
            onClose={() => handleMenuClose(selectedCustomSkillsMenuItemsAnchor)}
            data-cy="custom-skills-selected-skills-menu-items"
          >
            <MenuItem
              data-cy="custom-skills-menu-skills-delete-button"
              onClick={async () => {
                try {
                  await deleteCustomSkills(selectedCustomSkillsIds);
                } catch (error: any) {
                  setShowAlert(true);
                } finally {
                  handleMenuClose(selectedCustomSkillsMenuItemsAnchor);
                }
              }}
            >
              Delete {selectedRowsCount} Custom Skill(s)
            </MenuItem>
          </Menu>
        </Box>
        <ButtonGroup buttons={buttonGroups} wrap="nowrap" justifyContent="flex-end" />
      </ActionComponentsContainer>
    );
  }, [buttonGroups, customSkills]);

  const selectionRowChange = (selectedRowIds: string[]) => {
    setSelectedCustomSkillsIds(selectedRowIds);
    setSelectedRowsCount(selectedRowIds.length);
  };

  const handleMenuClose = (menuItem: HTMLElement | undefined) => {
    switch (menuItem) {
      case selectedCustomSkillsMenuItemsAnchor:
        setSelectedCustomSkillsMenuItemsAnchor(undefined);
        break;
    }
  };

  return (
    <MainContentTemplate pageTitle="Custom Skills" isFullWidth={false}>
      {showAlert && (
        <Alert severity="error" onClose={() => setShowAlert(false)}>
          There was an error deleting one or more custom skills.
        </Alert>
      )}
      <ContentHeaderTemplate contentTitle="Custom Skills" actionsComponent={ActionsComponent} />
      <Wrapper>
        <Pagination>
          <TablePagination
            dataCy="custom-skills-list-table-pagination"
            total={totalCustomSkills}
            page={!totalCustomSkills || totalCustomSkills <= 0 ? 0 : currentPage}
            setPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Pagination>
        <DataGrid
          columns={columns}
          rows={filteredCustomSkills}
          loading={loadingCustomSkills}
          getRowId={row => row.customSkillId}
          pagination
          paginationMode="client"
          rowCount={totalCustomSkills}
          onPageChange={setCurrentPage}
          pageSize={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          autoHeight
          checkboxSelection
          disableSelectionOnClick
          hideFooterPagination
          hideFooter
          onSelectionModelChange={rowId => selectionRowChange(rowId as string[])}
        />
        <Pagination>
          <TablePagination
            dataCy="custom-skills-list-table-pagination"
            total={totalCustomSkills}
            page={!totalCustomSkills || totalCustomSkills <= 0 ? 0 : currentPage}
            setPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Pagination>
      </Wrapper>
      <AddEditCustomSkillModal
        dialogOpen={showCustomSkillModal}
        customSkill={addEditCustomSkill}
        addCustomSkill={addCustomSkill}
        editCustomSkill={editCustomSkill}
        closeModal={() => setShowCustomSkillModal(false)}
      />
    </MainContentTemplate>
  );
};

export default CompanyCustomSkillsPage;
