import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { EditorState, RichUtils } from 'draft-js';
import clearFormatting from 'draft-js-clear-formatting';

type ToolbarProps = {
  editorState: EditorState;
  handleEditorChange: (richUtils: any) => any;
};
const ToolbarWrapper = styled.div`
  background: inherit;
  padding: ${props => props.theme.customSpacing.px.xxs}px 0;
  display: flex;
  justify-content: space-between;

  & > * {
    align-self: center;
  }
`;

const ToolbarButton = styled.span<{ $icon: string }>`
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-size: 26px;
  height: 1em;
  margin-right: 0.1em;
  position: relative;
  width: 1em;

  &:hover {
    background-color: #f0f1f3;
  }

  &::before {
    background-image: url(${props => `/icons/${props.$icon}.svg`});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: inline-block;
    font-size: inherit;
    height: 0.5em;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 1em;
  }
`;

const ToolbarSeparator = styled.span`
  border-right: solid ${props => props.theme.colors.border.default} 1px;
  display: inline-block;
  height: 0.9em;
  margin: 0 0.5em;
  position: relative;
  top: -0.3em;
`;

const Toolbar = ({ editorState, handleEditorChange }: ToolbarProps): ReactElement => {
  const getProps = icon => ({
    $icon: icon,
    title: icon,
    onMouseDown: evt => {
      evt.preventDefault();
      switch (icon) {
        case 'H1':
          handleEditorChange(RichUtils.toggleBlockType(editorState, 'header-one'));
          break;
        case 'H2':
          handleEditorChange(RichUtils.toggleBlockType(editorState, 'header-two'));
          break;
        case 'List':
          handleEditorChange(RichUtils.toggleBlockType(editorState, 'unordered-list-item'));
          break;
        case 'Bold':
        case 'Italic':
          handleEditorChange(RichUtils.toggleInlineStyle(editorState, icon.toUpperCase()));
          break;
        case 'Unformat':
          handleEditorChange(RichUtils.toggleBlockType(clearFormatting(editorState), 'unstyled'));
          break;
        default:
          break;
      }
    },
  });

  return (
    <ToolbarWrapper>
      <div style={{ height: '26px' }}>
        <ToolbarButton {...getProps('H1')} />
        <ToolbarButton {...getProps('H2')} />
        <ToolbarSeparator />
        <ToolbarButton {...getProps('Bold')} />
        <ToolbarButton {...getProps('Italic')} />
        <ToolbarSeparator />
        <ToolbarButton {...getProps('List')} />
        <ToolbarButton {...getProps('Unformat')} />
      </div>
    </ToolbarWrapper>
  );
};

export default Toolbar;
