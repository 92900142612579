import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import ReactGA from 'react-ga';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 75vh;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

const ErrorIcon = styled.span`
  font-size: 24rem;
  color: #da6f6f;

  @media (max-width: 768px) {
    font-size: 18rem;
    line-height: 18rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  margin-right: 1rem;
  color: #666;

  @media (max-width: 768px) {
    max-width: 90%;
    margin-left: 0rem;
  }
`;

const Message500 = styled.div`
  font-size: 6rem;
  font-weight: bold;
  line-height: 6rem;
  margin: 2rem 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 4rem;
    line-height: 4rem;
  }
`;

const ErrorText = styled.div`
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0 0 2rem 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      errorInfo: '',
      hasError: false,
    };
  }

  navigateHome = () => {
    ReactGA.event({
      category: 'error',
      action: '500',
      label: 'back to home click',
    });

    this.setState({ hasError: false, error: null });
    this.props.navigate('/home');
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <>
          <Container>
            <ErrorIcon role="img" aria-label="warning">
              &#x26A0;
            </ErrorIcon>
            <TextContainer>
              <Message500>This is awkward</Message500>
              <ErrorText>
                An unexpected error has occured. Our engineers have been notified and will be working on this ASAP.
              </ErrorText>
              <Button onClick={this.navigateHome}>Back to Home</Button>
            </TextContainer>
          </Container>
        </>
      );
    }
    return this.props.children;
  }
}
// eslint-disable-next-line react/display-name
export default props => {
  const navigate = useNavigate();
  return <ErrorBoundary {...props} navigate={navigate} />;
};

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
