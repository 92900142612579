import { useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';

const levenstein = (a: string, b: string) => {
  const m: any[] = [];
  let i;
  let j;
  const min = Math.min;

  if (!(a && b)) return (b || a).length;

  for (i = 0; i <= b.length; m[i] = [i++]);
  for (j = 0; j <= a.length; m[0][j] = j++);

  for (i = 1; i <= b.length; i++) {
    for (j = 1; j <= a.length; j++) {
      m[i][j] =
        b.charAt(i - 1) === a.charAt(j - 1)
          ? m[i - 1][j - 1]
          : (m[i][j] = min(m[i - 1][j - 1] + 1, min(m[i][j - 1] + 1, m[i - 1][j] + 1)));
    }
  }

  return m[b.length][a.length];
};
const LEVENSTEIN_THRESHOLD = 250;
const LEVENSTEIN_EXPIRATION = 60;
const LEVENSTEIN_THRESHOLD_TOTAL = 5000;

const useLevensteinDetector = (rawText: string): void => {
  const [leaven, setLeaven] = useState(''); // debounced rawText value
  const [oldLeaven, setOldLeaven] = useState(''); // previous value of `leaven` for comparison

  const setLeavenFunction = value => {
    setLeaven(value);
  };

  useEffect(() => {
    debounce(() => setLeavenFunction(rawText), 500);
  }, [rawText]);

  // within the last minute, tracks how many values exceeded LEVENSTEIN_THRESHOLD and
  // what their values are
  const [leavenValues, setLeavenValues] = useState<any[]>([]);

  // list of the timeouts that we can reference and clear later if we need to
  const timeoutRefs = useRef<any>();

  useEffect(() => {
    const newLumpValue = levenstein(leaven, oldLeaven);

    // if the user copy/pastes text, and neither value were an empty string, check that
    // the levenstein difference doesn't exceed LEVENSTEIN_THRESHOLD, otherwise log that
    // amount for LEVENSTEIN_EXPIRATION seconds.
    if (leaven && oldLeaven && newLumpValue > LEVENSTEIN_THRESHOLD) {
      setLeavenValues((values: any) => [...values, newLumpValue]);

      const clearLeavenTimeout = setTimeout(() => {
        setLeavenValues(values => {
          values.shift(); // remove oldest value
          return values;
        });
      }, LEVENSTEIN_EXPIRATION * 1000);

      timeoutRefs.current?.push(clearLeavenTimeout);
    }

    setOldLeaven(leaven);
  }, [leaven, oldLeaven]);

  useEffect(() => {
    const totalLeaven = leavenValues.reduce((total, current) => total + current, 0);

    // ************ Trying to curb front end bot scrapers ************
    // if the total of current logged "differences" (`totalLeaven`) exceeds
    // LEVENSTEIN_THRESHOLD_TOTAL, they're likely using the tool in a way
    // that violates the "free" nature, or they're scraping
    if (totalLeaven > LEVENSTEIN_THRESHOLD_TOTAL) {
      setLeavenValues([]); // just in case they're human, clear out extra leaven

      // clear timeouts so that past timeouts (intended to remove from `leavenValues`)
      // don't accidentally remove unintended future values from `leavenValues`
      timeoutRefs.current.forEach(ref => clearTimeout(ref));
      timeoutRefs.current = [];
    }
  }, [leavenValues]);
};

export default useLevensteinDetector;
