import React, { createRef, ReactElement, useEffect, useMemo, useState } from 'react';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import EditRoleContent from 'components/organisms/EditRoleContent';
import { BulkRoleEditContextProvider } from 'contexts/BulkRoleEditContext';
import useBulkRoleEditContext from 'helpers/useBulkRoleEditContext';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EmsiUIBadge from 'components/atoms/EmsiUIBadge';
import { reviewStatusColor, reviewStatusLabel } from 'helpers/SkillProfilePageHelper';
import { RoleEditContextProvider } from 'contexts/RoleEditContext';
import { useNavigate, useParams } from 'react-router-dom';
import useRoleEditContext from 'helpers/useRoleEditContext';
import Skeleton from '@mui/material/Skeleton';
import TableContainer from '@mui/material/TableContainer';
import styled, { css, useTheme } from 'styled-components';
import queryString from 'query-string';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import PipIcon, { PipIconType } from 'components/atoms/PipIcon';
import { RoleListSort } from 'types/types';
import { FixedSizeList as List } from 'react-window';
import DialogModal from 'components/molecules/DialogModal';
import { EMSIBGButton } from 'components/atoms/ButtonGroup';
import rolesPageHelper from 'helpers/RolesPageHelper';

const sidebarRowSize = 60;
const sidePanelWidth = 428;

const RowContainer = styled.div<{ $selected?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${props => props.theme.customSpacing.px.xxs}px ${props => props.theme.customSpacing.px.base * 5}px;
  cursor: pointer;
  box-shadow: ${props => props.theme.boxShadow.default};
  ${params =>
    params.$selected &&
    css`
      background-color: ${props => props.theme.colors.surface.neutralSubdued};
    `}

  :hover {
    background-color: ${props => props.theme.colors.surface.neutralSubdued};
  }
`;

const RowContainerHeader = styled(RowContainer)`
  background-color: ${props => props.theme.colors.surface.default};
  border-bottom: 1px solid ${props => props.theme.colors.border.subdued};
  padding: ${props => props.theme.customSpacing.px.base * 5}px;
  position: sticky;
  top: 0px;
  z-index: 100;
  :hover {
    background-color: ${props => props.theme.colors.surface.default};
  }
`;

const EditRolePage = (): ReactElement => {
  const { roleId } = useParams();

  const theme = useTheme();
  const navigate = useNavigate();
  const qs: any = queryString.parse(window.location.search, { parseBooleans: true, parseNumbers: true }) || {};

  const getPipIconTypeFromReviewStatus = (status: string): PipIconType => {
    switch (status) {
      case 'Reviewed':
        return PipIconType.full;
      case 'Needs Approval':
        return PipIconType.partial;
      default:
        return PipIconType.empty;
    }
  };

  const SidePanelContent = (): ReactElement => {
    const {
      isAllRolesLoading,
      roleList,
      setNextRole,
      activeRoleId,
      activeRoleIndex,
      roleListSort,
      setRoleListSort,
      updateRoleByRoleId,
    } = useBulkRoleEditContext();
    const { role, saveRole, isSaving, isModified } = useRoleEditContext();
    const [isDialogModalOpen, setIsDialogModalOpen] = useState(false);
    const [modalCallback, setModalCallback] = useState<any>();
    const roleListRef = createRef<any>();
    const roleListHeight = window.innerHeight - 228; // 228 = App Header + Side Panel Header + Role List Header
    const noOfRowsToDisplay = Math.round(roleListHeight / sidebarRowSize);

    const closeDialogModal = () => {
      setIsDialogModalOpen(false);
    };
    const openDialogModal = () => {
      setIsDialogModalOpen(true);
    };

    const validateMoveWithChanges = callback => {
      if (isModified) {
        setModalCallback(() => callback);
        openDialogModal();
      } else {
        callback();
      }
    };
    const handleRowClick = (roleId: string) => {
      roleId !== activeRoleId && validateMoveWithChanges(() => setNextRole(roleId));
    };

    const modalSaveChanges = async () => {
      await saveRole();
      role && updateRoleByRoleId(role);
      modalCallback?.();
      closeDialogModal();
    };

    const modalResetChanges = () => {
      modalCallback?.();
      closeDialogModal();
    };

    const handleSort = (columnId: string) => {
      const updatedRoleListSort = {
        sortBy: columnId,
        sortDirection: columnId === roleListSort.sortBy && roleListSort.sortDirection === 'asc' ? 'desc' : 'asc',
      } as RoleListSort;
      setRoleListSort(updatedRoleListSort);
    };

    const rows = useMemo(() => {
      return roleList.map(role => {
        return {
          roleId: role.roleId,
          roleName: role.roleName,
          reviewStatus: role.reviewStatus,
          mappingConfidence: role.mappingConfidence,
        };
      });
    }, [roleList]);

    useEffect(() => {
      roleListRef.current?.scrollToItem(activeRoleIndex, 'smart');
    }, [activeRoleIndex]);

    const Row = ({ index, id, style }) => {
      const row = rows[index];
      const selected = row.roleId === activeRoleId;
      return (
        <div
          style={{ ...style, width: '100%' }}
          key={id}
          id={`bulk-edit-role-list-row-id-${row.roleId}`}
          onClick={() => handleRowClick(row.roleId)}
        >
          <RowContainer $selected={selected}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '65%', justifyContent: 'left' }}>
              <Typography sx={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                {row.roleName}
              </Typography>
              <div
                title={
                  rolesPageHelper.mappingConfidenceIndicator(row.reviewStatus, row.mappingConfidence)
                    .mappingConfidenceMessage
                }
                style={{ width: 'fit-content' }}
                data-cy={'bulk-edit-role-list-role-mapping-confidence'}
              >
                <Typography variant="caption" color={theme.colors.text.subdued}>
                  {'Confidence: '}
                </Typography>
                <Typography
                  variant="caption"
                  color={rolesPageHelper.mappingConfidenceIndicator(row.reviewStatus).mappingConfidenceColor}
                >
                  {rolesPageHelper.mappingConfidenceIndicator(row.reviewStatus).mappingConfidenceText}
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                width: '35%',
                justifyContent: 'right',
                paddingRight: `${theme.customSpacing.px.xs}px`,
              }}
            >
              <EmsiUIBadge
                color={reviewStatusColor(row.reviewStatus)}
                label={reviewStatusLabel(row.reviewStatus)}
                title={reviewStatusLabel(row.reviewStatus)}
                icon={<PipIcon type={getPipIconTypeFromReviewStatus(reviewStatusLabel(row.reviewStatus))} />}
              />
            </div>
          </RowContainer>
        </div>
      );
    };

    const SkeletonPlaceholder = () => {
      return (
        <div
          style={{
            display: 'flex',
            gap: '10%',
            alignItems: 'center',
            height: `${sidebarRowSize}px`,
            padding: `${theme.customSpacing.px.xxs}px ${theme.customSpacing.px.base * 5}px`,
          }}
        >
          <div style={{ width: '60%' }}>
            <Skeleton width={'100%'}>
              <Typography>&nbsp;</Typography>
            </Skeleton>
            <Skeleton width={'100%'}>
              <Typography variant="caption">&nbsp;</Typography>
            </Skeleton>
          </div>
          <div style={{ width: '30%' }}>
            <Skeleton width={'100%'}>
              <EmsiUIBadge />
            </Skeleton>
          </div>
        </div>
      );
    };

    const modalBtnGroup: EMSIBGButton[] = [
      {
        text: 'Save',
        variant: 'contained',
        color: 'actionPrimary',
        isLoadingButton: true,
        loadingStatus: isSaving,
        onClickEvent: modalSaveChanges,
        dataCy: 'button-dialog-save',
      },
      {
        text: "Don't Save",
        onClickEvent: modalResetChanges,
        dataCy: 'button-dialog-not-save',
      },
      {
        text: 'Cancel',
        onClickEvent: closeDialogModal,
        dataCy: 'button-dialog-cancel',
      },
    ];

    const RoleListHeader = (headerName: string, headerId: string, widthPercent: number, justify: string) => {
      return (
        <div
          onClick={() => handleSort(headerId)}
          style={{ display: 'flex', width: `${widthPercent}%`, justifyContent: justify }}
          data-cy={`bulk-edit-role-list-${headerId}-header`}
        >
          <Typography variant={'strong'}>{headerName}</Typography>
          <div style={{ visibility: roleListSort.sortBy === headerId ? 'visible' : 'hidden' }}>
            <BaseIcon
              type={roleListSort.sortDirection === 'asc' ? IconType.ChevronDown : IconType.ChevronUp}
              size="8px"
            />
          </div>
        </div>
      );
    };

    return (
      <>
        <TableContainer component={Box} sx={{ overflowX: 'unset' }}>
          <RowContainerHeader data-cy={'role-selection-table'}>
            {RoleListHeader('Role Name', 'roleName', 65, 'left')}
            {RoleListHeader('Mapping Status', 'mappingStatus', 35, 'right')}
          </RowContainerHeader>
          {isAllRolesLoading ? (
            Array.from(Array(noOfRowsToDisplay).keys()).map(i => (
              <SkeletonPlaceholder key={`role-list-skeleton-${i}`} />
            ))
          ) : (
            <List
              width={sidePanelWidth}
              height={noOfRowsToDisplay * sidebarRowSize}
              itemCount={rows.length}
              itemSize={sidebarRowSize}
              ref={roleListRef}
            >
              {Row}
            </List>
          )}
        </TableContainer>
        <DialogModal
          dialogOpen={isDialogModalOpen}
          closeModal={() => closeDialogModal()}
          title="Unsaved Changes"
          content={'Are you sure you want to exit without saving your changes?'}
          buttonGroup={modalBtnGroup}
        />
      </>
    );
  };

  const MainContentWithEditContent: React.FC = () => {
    const { isLoading, role } = useRoleEditContext();
    return (
      <MainContentTemplate
        pageTitle={`Role Details${!isLoading && role ? ` - ${role.roleName}` : ''}`}
        isFullWidth={false}
        sidePanelTitle={'Role Selection'}
        sidePanelContent={SidePanelContent()}
        sidePanelWidth={sidePanelWidth}
      >
        <EditRoleContent backClickHandler={() => navigate(`/roles${window.location.search}`)} />
      </MainContentTemplate>
    );
  };

  const EditContextWithMainContent: React.FC = () => {
    const { activeRoleId } = useBulkRoleEditContext();
    return (
      <RoleEditContextProvider roleId={activeRoleId}>
        <MainContentWithEditContent />
      </RoleEditContextProvider>
    );
  };

  return (
    <BulkRoleEditContextProvider
      initialRoleId={roleId}
      roleFilter={qs.rf?.toString()}
      appliedFilters={qs.af ? JSON.parse(qs?.af) : {}}
    >
      <EditContextWithMainContent />
    </BulkRoleEditContextProvider>
  );
};

export default EditRolePage;
