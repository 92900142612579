import React, { ReactElement } from 'react';
import styled, { useTheme, css } from 'styled-components';
import CircleDot from 'components/atoms/CircleDot2';
import RequiredIcon from 'components/atoms/icons/RequiredIcon';
import { Skill } from 'types/types';

type RoleComparisonColumnSkillRowProps = {
  skill: Skill;
  hitMatrix: boolean[];
  highlighted: boolean;
  showDots?: boolean;
  onClick: () => void;
  dataCy: string;
};
const Wrapper = styled.div<{ $highlighted: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  border-bottom: 0.5px solid ${props => props.theme.colors.surface.neutral};
  padding: 0.25rem 1rem;
  ${props =>
    props.$highlighted &&
    css`
      background-color: ${props => props.theme.colors.surface.neutralSubdued};
    `}
`;

const SkillName = styled.div<{ $highlighted: boolean }>`
  flex-grow: 1;
  font-weight: ${props => (props.$highlighted ? '600' : '400')};
`;

const IconCell = styled.div`
  padding: ${props => `0px ${props.theme.customSpacing.px.base}px`};
`;

const RoleComparisonColumnSkillRow = ({
  skill,
  hitMatrix = [false, false, false],
  highlighted = false,
  showDots = true,
  onClick,
  dataCy = 'role-comparison-skills',
}: RoleComparisonColumnSkillRowProps): ReactElement => {
  const theme = useTheme();
  const bgColor = highlighted ? theme.colors.border.default : theme.colors.surface.default;

  return (
    <Wrapper $highlighted={highlighted} onClick={onClick} data-cy={dataCy}>
      <IconCell>
        <RequiredIcon color={skill.required ? theme.colors.icon.critical.hex : bgColor} />
      </IconCell>
      <SkillName $highlighted={highlighted} data-cy={dataCy + '-' + skill.skillName}>
        {skill.skillName}
      </SkillName>
      {showDots && (
        <>
          <IconCell>
            {hitMatrix[0] ? (
              <CircleDot
                size={theme.customSpacing.px.base * 2.5}
                fill={theme.colors.icon.success.hex}
                stroke={theme.colors.icon.success.hex}
              />
            ) : (
              <CircleDot
                size={theme.customSpacing.px.base * 2.5}
                fill={bgColor}
                stroke={theme.colors.icon.success.hex}
              />
            )}
          </IconCell>
          <IconCell>
            {hitMatrix[1] ? (
              <CircleDot
                size={theme.customSpacing.px.base * 2.5}
                fill={theme.colors.icon.warning.hex}
                stroke={theme.colors.icon.warning.hex}
              />
            ) : (
              <CircleDot
                size={theme.customSpacing.px.base * 2.5}
                fill={bgColor}
                stroke={theme.colors.icon.warning.hex}
              />
            )}
          </IconCell>
          <IconCell>
            {hitMatrix[2] ? (
              <CircleDot
                size={theme.customSpacing.px.base * 2.5}
                fill={theme.colors.icon.information.hex}
                stroke={theme.colors.icon.information.hex}
              />
            ) : (
              <CircleDot
                size={theme.customSpacing.px.base * 2.5}
                fill={bgColor}
                stroke={theme.colors.icon.information.hex}
              />
            )}
          </IconCell>
        </>
      )}
    </Wrapper>
  );
};

export default RoleComparisonColumnSkillRow;
