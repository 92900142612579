type authProps = {
  isAdmin?: () => boolean;
  isEmsiAdmin?: () => boolean;
  isCustomerSupport?: () => boolean;
};

// all the possible sub routes for each module
const moduleRoutes = {
  DEI: [{ displayName: 'Diversity', path: '/dei/diversity', startString: '/dei' }],
  settings: [
    { displayName: 'Users', path: '/admin/user-management', startString: '/admin/user' },
    { displayName: 'Activity Log', path: '/event-logs', startString: '/event-logs' },
    { displayName: 'Benchmarks', path: '/admin/benchmark-management', startString: '/admin/benchmark' },
    { displayName: 'Custom Skills', path: '/admin/custom-skills', startString: '/admin/custom-skills' },
    { displayName: 'Processing Statuses', path: '/admin/processing-statuses', startString: '/admin/processing-status' },
  ],
};

// get the specific routes for the active module
// make any updates that might be needed
const getSubNavRoutes = (activeModule: string, auth: authProps, pathName: string): any[] => {
  let routes = moduleRoutes[activeModule];
  if (auth.isAdmin?.()) {
    if (activeModule === 'settings') {
      routes = [
        { displayName: 'Overview', path: '/admin/company-settings', startString: '/admin/company-settings' },
        ...routes,
        ...(auth.isEmsiAdmin?.()
          ? [
              {
                displayName: 'Notifications',
                path: '/admin/notifications-settings',
                startString: '/admin/notifications',
              },
            ]
          : []),
        ...(auth.isAdmin?.()
          ? [
              {
                displayName: 'Integrations',
                path: '/admin/integration-settings',
                startString: '/admin/integration',
              },
            ]
          : []),
        ...(auth.isCustomerSupport?.()
          ? [
              {
                displayName: 'Manage Companies',
                path: '/admin/company-management',
                startString: '/admin/company-management',
              },
            ]
          : []),
      ];
    }
  }
  const activeRoute = routes?.filter(route => pathName.startsWith(route.startString));
  const activeRouteName = activeRoute && activeRoute.length > 0 ? activeRoute[0].displayName : '';
  return [routes, activeRouteName];
};

export default {
  getSubNavRoutes,
};
