import React, { ReactElement } from 'react';

type ComparisonChipProps = {
  chipType: 'Job Description' | 'Benchmark' | 'Occupation';
  size?: number;
};

const types = {
  'Job Description': {
    color: '#EAC0FF',
    letter: (
      <path
        d="M10.7839 6.72727H12.0907V11.8409C12.0884 12.3097 11.9889 12.7133 11.7924 13.0518C11.5959 13.388 11.3213 13.6473 10.9686 13.8295C10.6182 14.0095 10.2098 14.0994 9.74343 14.0994C9.31729 14.0994 8.93377 14.0237 8.59286 13.8722C8.25432 13.7183 7.98562 13.491 7.78675 13.1903C7.58789 12.8897 7.48846 12.5156 7.48846 12.0682H8.79883C8.8012 12.2647 8.84381 12.4339 8.92667 12.576C9.0119 12.718 9.12908 12.8269 9.27823 12.9027C9.42738 12.9785 9.59902 13.0163 9.79315 13.0163C10.0038 13.0163 10.1826 12.9725 10.3294 12.8849C10.4761 12.795 10.5874 12.6624 10.6632 12.4872C10.7413 12.312 10.7815 12.0966 10.7839 11.8409V6.72727Z"
        fill="#512766"
      />
    ),
  },
  Benchmark: {
    color: '#B8CFFF',
    letter: (
      <path
        d="M7.4312 14V6.72727H10.2153C10.7409 6.72727 11.1776 6.81013 11.5257 6.97585C11.876 7.1392 12.1376 7.36293 12.3105 7.64702C12.4856 7.93111 12.5732 8.25308 12.5732 8.61293C12.5732 8.90885 12.5164 9.16217 12.4028 9.37287C12.2892 9.5812 12.1365 9.75047 11.9447 9.88068C11.7529 10.0109 11.5387 10.1044 11.3019 10.1612V10.2322C11.56 10.2464 11.8074 10.3258 12.0441 10.4702C12.2832 10.6122 12.4785 10.8134 12.6301 11.0739C12.7816 11.3343 12.8573 11.6491 12.8573 12.0185C12.8573 12.3949 12.7662 12.7334 12.5839 13.0341C12.4016 13.3324 12.127 13.5679 11.76 13.7408C11.3931 13.9136 10.9314 14 10.3751 14H7.4312ZM8.74867 12.8991H10.1656C10.6438 12.8991 10.9883 12.808 11.199 12.6257C11.412 12.4411 11.5186 12.2043 11.5186 11.9155C11.5186 11.7 11.4653 11.5059 11.3588 11.3331C11.2522 11.1579 11.1007 11.0206 10.9042 10.9212C10.7077 10.8194 10.4733 10.7685 10.2011 10.7685H8.74867V12.8991ZM8.74867 9.82031H10.0519C10.2792 9.82031 10.484 9.77888 10.6663 9.69602C10.8486 9.6108 10.9918 9.49124 11.096 9.33736C11.2025 9.18111 11.2558 8.99645 11.2558 8.78338C11.2558 8.50166 11.1563 8.26965 10.9575 8.08736C10.761 7.90507 10.4686 7.81392 10.0803 7.81392H8.74867V9.82031Z"
        fill="#1F3666"
      />
    ),
  },
  Occupation: {
    color: '#FFC9A5',
    letter: (
      <path
        d="M13.3438 10.3636C13.3438 11.1473 13.1971 11.8184 12.9035 12.3771C12.6123 12.9335 12.2146 13.3596 11.7103 13.6555C11.2084 13.9515 10.6391 14.0994 10.0022 14.0994C9.36538 14.0994 8.79483 13.9515 8.29057 13.6555C7.78868 13.3572 7.39095 12.9299 7.09739 12.3736C6.8062 11.8149 6.6606 11.1449 6.6606 10.3636C6.6606 9.58002 6.8062 8.91004 7.09739 8.35369C7.39095 7.79498 7.78868 7.36766 8.29057 7.07173C8.79483 6.7758 9.36538 6.62784 10.0022 6.62784C10.6391 6.62784 11.2084 6.7758 11.7103 7.07173C12.2146 7.36766 12.6123 7.79498 12.9035 8.35369C13.1971 8.91004 13.3438 9.58002 13.3438 10.3636ZM12.0193 10.3636C12.0193 9.81203 11.9329 9.34683 11.76 8.96804C11.5896 8.58688 11.3528 8.29924 11.0498 8.10511C10.7468 7.90862 10.3976 7.81037 10.0022 7.81037C9.60686 7.81037 9.25766 7.90862 8.95463 8.10511C8.6516 8.29924 8.41368 8.58688 8.24086 8.96804C8.0704 9.34683 7.98517 9.81203 7.98517 10.3636C7.98517 10.9152 8.0704 11.3816 8.24086 11.7628C8.41368 12.1416 8.6516 12.4292 8.95463 12.6257C9.25766 12.8198 9.60686 12.9169 10.0022 12.9169C10.3976 12.9169 10.7468 12.8198 11.0498 12.6257C11.3528 12.4292 11.5896 12.1416 11.76 11.7628C11.9329 11.3816 12.0193 10.9152 12.0193 10.3636Z"
        fill="#66300C"
      />
    ),
  },
};

function IconJobDescription({ chipType, size = 20 }: ComparisonChipProps): ReactElement {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={size} height={size} rx="10" fill={types[chipType].color} />
      <g style={{ mixBlendMode: 'darken' }}>{types[chipType].letter}</g>
    </svg>
  );
}

export default IconJobDescription;
