import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import DialogModal from 'components/molecules/DialogModal';
import ReactGA from 'react-ga';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import useCompanyContext from 'helpers/UseCompanyContext';
import * as FileUploadService from 'services/FileUploadService';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { IconColorFilter, DUOmit } from 'types/types';
import CSVToJSON from 'csvtojson';
import { EMSIBGButton } from 'components/atoms/ButtonGroup';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
  padding: ${props => props.theme.customSpacing.px.base * 4}px;
  padding-top: 0px;
`;

const UploadSection = styled.div`
  height: 30%;
  width: 100%;
`;

const Uploader = styled.input`
  margin-bottom: ${props => props.theme.customSpacing.px.base * 4}px;
  height: 100%;
  width: 100%;
  display: none;
`;

const MessageContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const UploadArea = styled.div<{ $isDragging: boolean }>`
  border: ${params =>
    params.$isDragging
      ? `1px solid ${params.theme.colors.border.default}`
      : `1px solid ${params.theme.colors.border.default}`};
  border-radius: ${props => props.theme.borderRadius.default};
  background-color: ${params => (params.$isDragging ? params.theme.colors.surface.neutral : 'inherit')};
  color: ${params => params.theme.colors.text.default};
  padding: ${props => props.theme.customSpacing.px.m}px;
  ${params => params.$isDragging && 'font-weight: 600;'}

  transition: all 300ms;
`;

const UploadInstructions = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const UploadFieldsMessage = styled.div`
  display: flex;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

const UploadFields = styled.div`
  display: flex;
`;

const OtherInstructions = styled.div`
  display: flex;
  margin-bottom: 1rem;
  font-weight: 600;
  margin-top: 1rem;
`;

const UploadDescription = styled(UploadFieldsMessage)`
  margin-bottom: 1rem;
`;

const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const UPLOAD_TYPE_DISPLAY_NAMES: {
  [K in FileUploadService.FileUploadDetails['uploadType']]: string;
} = {
  skills: 'skill profiles',
  // roles: "roles",
  // tagsAliases: "tags and aliases",
} as const;

export const UPLOAD_ACTION_DISPLAY_NAMES: {
  [K in FileUploadService.FileUploadDetails['uploadAction']]: string;
} = {
  add: 'add',
  delete: 'remove',
  update: 'update',
} as const;

type UploadFileModalProps = {
  isOpen: boolean;
  closeHandler: (openAddModal: boolean) => void;
  showAddManually?: boolean;
  uploadDetails: DUOmit<FileUploadService.FileUploadDetails, 'fileName'>;
  ignoredFields: readonly string[];
  allowedFields: readonly string[];
  requiredFields: readonly string[];
};

const UploadFileModal = ({
  isOpen = true,
  showAddManually = true,
  closeHandler,
  ignoredFields,
  allowedFields,
  uploadDetails,
  requiredFields,
}: UploadFileModalProps): ReactElement => {
  const navigate = useNavigate();
  const [file, setFile] = useState<File>();
  const [uploading, setUploading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [headerLines, setHeaderLines] = useState<string[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isManualAddAfterClose, setIsManualAddAfterClose] = useState(false);
  const [cancelUploadMessage, setCancelUploadMessage] = useState('');
  const [selectNewFile, setSelectNewFile] = useState(false);
  const [uploadBatchId, setUploadBatchId] = useState<string | undefined>(undefined);
  const [uploadFail, setUploadFail] = useState(false);
  const updateableItems = allowedFields.filter(allowedField => !ignoredFields.includes(allowedField)).filter(allowedField => !requiredFields.includes(allowedField));

  const displayUploadType = UPLOAD_TYPE_DISPLAY_NAMES[uploadDetails.uploadType];
  const displayUploadAction = UPLOAD_ACTION_DISPLAY_NAMES[uploadDetails.uploadAction];
  console.log('UTDN: ', UPLOAD_TYPE_DISPLAY_NAMES);
  console.log('uploadDetails: ', uploadDetails);

  const SKILLS_ADD_DESCRIPTION = 'Provide a .csv file to bulk ADD skills to roles. Any skills that already exist on a role will be ignored.';
  const SKILLS_UPDATE_DESCRIPTION = 'Provide a .csv file to bulk UPDATE the skill details for skills related to each role. Skills that do not exist on the role will be ignored.';
  const SKILLS_DELETE_DESCRIPTION = 'Provide a .csv file to bulk DELETE skills from roles. Any skills that do not already exist on a role will be ignored.';

  const { companyId } = useCompanyContext();
  const ALLOWED_FILE_TYPES = ['text/csv'];

  const logAnalytics = (event: string) => {
    ReactGA.event({
      category: uploadDetails.uploadType,
      action: 'upload',
      label: event,
    });
  };

  const bucketizeFileSize = (fileSize: number) => {
    let bucket = '10MB+';
    if (fileSize < 10000000) {
      bucket = '1MB-10MB';
    }
    if (fileSize < 1000000) {
      bucket = '100k-1MB';
    }
    if (fileSize < 100000) {
      bucket = '50k-100k';
    }
    if (fileSize < 50000) {
      bucket = '5k-50k';
    }
    if (fileSize < 5000) {
      bucket = '1-5k';
    }
    return bucket;
  };

  const onUploadProgress = event => {
    setProgress(Math.round((100 * event.loaded) / event.total));
  };

  const SuccessfulFileProcessedToast = (batchId: string) => (
    <div>
      <span data-cy="file-successfully-processed-toast">
        Your file has started processing{' '}
        <Button variant="text" onClick={() => navigate(`/admin/processing-statuses/${batchId}?p=0&n=10`)}>
          Click here to view upload details
        </Button>
      </span>
    </div>
  );

  const FailFileProcessedToast = () => (
    <div>
      <span data-cy="file-fail-processed-toast">There was a problem with your file upload, please try again.</span>
    </div>
  );

  const uploadCSV = async () => {
    setUploading(true);

    try {
      if (!file) alert('no file selected');
      const fileName = file?.name;
      if (!fileName) {
        alert('no filename provided');
        return;
      }

      try {
        const { url, batchId } = await FileUploadService.getPresignedUploadUrl(companyId, {
          ...uploadDetails,
          fileName,
        });
        const fileUploadResponse = await FileUploadService.uploadFileToS3(url, file, onUploadProgress);
        if (fileUploadResponse) {
          setUploadBatchId(batchId);
          toast.success(SuccessfulFileProcessedToast(batchId));
        }
      } catch (error) {
        setUploadFail(true);
        toast.error(FailFileProcessedToast());
        console.error(error);
      }
      setFileUploaded(true);
      logAnalytics('File upload complete');
    } catch (error) {
      console.error('ERROR SENDING TO LAMBDA TO SAVE', error);
      logAnalytics('File upload error');
    } finally {
      setUploading(false);
      setProgress(0);
      const bucketizedFileSize = file ? bucketizeFileSize(file.size) : '0K';
      logAnalytics(`Size bucket: ${bucketizedFileSize}`);
    }
  };

  const fileSelectHandler = async (file: File | undefined, source: string) => {
    if (!file) return;

    logAnalytics(source);
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      logAnalytics(`${source}: invalid file type - ${file.type}`);
      alert('Only CSV files are supported at this time.\n' + 'Please try a different file.');
    } else if (file.size === 0) {
      logAnalytics(`${source}: empty file`);
      alert('The file you have provided is empty.\n' + 'Please try a different file.');
    } else {
      const reader = new FileReader();
      reader.onload = async e => {
        const fileContent = e?.target?.result;
        const lines = fileContent?.toString() || '';
        const rows = await CSVToJSON().fromString(lines);
        let totalRows = 0;
        rows.forEach(row => {
          if (!(row?.length === 1 && row?.[0] === '')) totalRows++; // Exclude empty rows
        });
        const totalItems = totalRows - 1; // Exclude header row count
        if (totalItems === -1) {
          logAnalytics(`${source}: no ${uploadDetails.uploadType}`);
          alert(`The file you have provided has no ${uploadDetails.uploadType}.\n` + 'Please try a different file.');
        } else {
          setFile(file);
          setHeaderLines(Object.keys(rows?.[0]));
        }
      };
      reader.onerror = e => alert(e?.target?.error?.name);
      reader.readAsText(file);
    }
  };

  const resetFields = () => {
    setFile(undefined);
    setUploading(false);
    setFileUploaded(false);
    setProgress(0);
    setUploadBatchId(undefined);
    setUploadFail(false);
  };

  const cancelHandler = (isManualAddAfterClose = false, setNewFile = false) => {
    setIsManualAddAfterClose(isManualAddAfterClose);
    setSelectNewFile(setNewFile);
    if (file) {
      const messageCancelAction = isManualAddAfterClose
        ? `Manually adding ${uploadDetails.uploadType}`
        : setNewFile
          ? 'Choosing new file'
          : 'Cancelling';
      setCancelUploadMessage(
        `${messageCancelAction} will remove your selected file to upload. Do you want to continue?`
      );
      setIsCancelModalOpen(true);
    } else {
      closeHandler(isManualAddAfterClose);
    }
  };

  const cancelUploadModalHandler = (continueAction: boolean) => {
    setIsCancelModalOpen(false);

    if (continueAction) {
      resetFields();
      if (!selectNewFile) {
        closeHandler(isManualAddAfterClose);
      }
    }
  };

  const defaultDragEventHandler = ({ e, isOver = false }) => {
    e.preventDefault();
    e.stopPropagation();
    if (isOver !== isDragging) {
      setIsDragging(isOver);
    }
  };

  const handleDrop = async e => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.items && e.dataTransfer.items.length === 1 && e.dataTransfer.items[0].kind === 'file') {
      const file = e.dataTransfer.items[0].getAsFile();
      await fileSelectHandler(file, 'Drag-Drop');
    }
    setIsDragging(false);
  };

  const uploadBtnGroup: EMSIBGButton[] = [
    {
      text: 'Cancel',
      variant: 'contained',
      onClickEvent: () => cancelHandler(),
      dataCy: `add-${uploadDetails.uploadType}-selector-modal-cancel-button`,
    },
    {
      text: 'Add Manually',
      variant: 'contained',
      onClickEvent: () => cancelHandler(true),
      visible: showAddManually,
      dataCy: `add-${uploadDetails.uploadType}-selector-modal-add-manually-button`,
    },
    {
      text: 'Upload',
      variant: 'contained',
      color: 'actionPrimary',
      isLoadingButton: true,
      loadingStatus: uploading,
      loadingMessage: 'Uploading file...',
      disabled: !file,
      onClickEvent: async () => await uploadCSV(),
      dataCy: `add-${uploadDetails.uploadType}-selector-modal-upload-button`,
      width: '120px',
    },
  ];

  const uploadedBtnGroup: EMSIBGButton[] = [
    {
      text: 'Close',
      variant: 'contained',
      onClickEvent: () => cancelUploadModalHandler(true),
      dataCy: 'file-upload-completed-modal-close-button',
    },
  ];

  const cancelUploadBtnGroup: EMSIBGButton[] = [
    {
      text: 'Cancel',
      onClickEvent: () => cancelUploadModalHandler(false),
      dataCy: 'cancel-file-upload-modal-cancel-button',
    },
    {
      text: 'Continue',
      variant: 'contained',
      color: 'actionPrimary',
      onClickEvent: () => cancelUploadModalHandler(true),
      dataCy: 'cancel-file-upload-modal-continue-button',
    },
  ];

  const generateFieldsList = (fieldArray, fieldType) => {
    const chunked: Array<Array<string>> = [];
    for (let i = 0; i < fieldArray.length; i += 5) {
      chunked.push(fieldArray.slice(i, i + 5));
    }
    return chunked.map((chunk, index) => (
      <ul key={index}>
        {chunk.map((item, index) => (
          <li key={index}>
            <ListItemWrapper>
              {item} 
              {fieldType === 'allowed' && requiredFields.includes(item) && <sup><Typography sx={{ fontSize: '10px' }} color='text.critical'>R</Typography></sup>}
              {fieldType === 'allowed' && updateableItems.includes(item) && <sup><Typography sx={{ fontSize: '10px' }} color='text.information'>U</Typography></sup>}
            </ListItemWrapper>
          </li>
        ))}
      </ul>
    ));
  }

  const FileInstructions = () => (
    <>
      {allowedFields?.length > 0 && (
        <>
          <UploadFieldsMessage>
            We accept the following columns in this .csv file. Any additional columns included in the file will cause
            the bulk action to fail.
          </UploadFieldsMessage>
          <UploadFields>{generateFieldsList(allowedFields, 'allowed')}</UploadFields>
        </>
      )}
      {requiredFields?.length > 0 && (
        <>
          <UploadFieldsMessage>
            <sup><Typography sx={{ fontSize: '10px' }} color='text.critical'>R</Typography></sup> &nbsp; Signifies a required column. Any row that does not have these specified will be skipped.
          </UploadFieldsMessage>
        </>
      )}
      {updateableItems?.length > 0 && (
        <>
          <UploadFieldsMessage>
             <sup><Typography sx={{ fontSize: '10px' }} color='text.information'>U</Typography></sup> &nbsp; Signifies a column is updatable.
          </UploadFieldsMessage>
        </>
      )}

      {uploadDetails.uploadType === 'skills' && (
        <OtherInstructions>Custom Skills must already exist in the system in order to be used here.</OtherInstructions>
      )}

      <OtherInstructions>
        We recommend that you start by exporting your current {displayUploadType}, and only including the rows you want
        to affect.
      </OtherInstructions>
    </>
  );

  const Content = (
    <Wrapper data-cy="add-file-selector-modal">
      {((!fileUploaded && !uploading) || !file) && (
        <>
          <UploadSection>
            {!file && (
              <>
                <Uploader
                  type="file"
                  accept=".csv"
                  id="fileInput"
                  name="fileInput"
                  onClick={() => logAnalytics('File dialog opened')}
                  onChange={e => fileSelectHandler(e?.target?.files?.[0], 'File selected')}
                />
                <UploadArea
                  onDrop={handleDrop}
                  onDragOver={e => defaultDragEventHandler({ e, isOver: true })}
                  onDragEnter={e => defaultDragEventHandler({ e, isOver: true })}
                  onDragLeave={e => defaultDragEventHandler({ e, isOver: false })}
                  $isDragging={isDragging}
                  data-cy="add-file-selector-modal-upload-file-drag-n-drop-area"
                >
                  <UploadDescription>
                    { uploadDetails.uploadAction === 'add' ? SKILLS_ADD_DESCRIPTION :
                      uploadDetails.uploadAction === 'update' ? SKILLS_UPDATE_DESCRIPTION :
                      SKILLS_DELETE_DESCRIPTION
                    }
                  </UploadDescription>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <BaseIcon type={IconType.Upload} size="16px" alt="upload icon" />
                    <Typography>Drag and drop or</Typography>
                    <Button
                      variant="text"
                      onClick={() => {
                        document.getElementById('fileInput')?.click();
                      }}
                    >
                      <Typography variant="strong">browse file</Typography>
                    </Button>
                  </div>
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <Typography
                      variant="caption"
                      color="text.subdued"
                      sx={{ whiteSpace: 'pre-wrap', lineHeight: '4rem' }}
                    >
                      Accepted file types: .csv
                    </Typography>
                  </div>
                  <FileInstructions />
                </UploadArea>
              </>
            )}

            {file && headerLines && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}
              >
                <UploadInstructions>
                  <FileInstructions />
                </UploadInstructions>
              </div>
            )}
          </UploadSection>
        </>
      )}
      {file && uploading && (
        <>
          <Typography variant="subHeading">Uploading...</Typography>
          <LinearProgress variant="determinate" value={progress} />
        </>
      )}
      {fileUploaded && (
        <MessageContainer>
          {!uploadBatchId ? (
            uploadFail ? (
              <>
                <Typography variant="displayMedium">
                  there was a problem with your file upload, please &nbsp;
                  <Link onClick={() => resetFields()}>try again</Link>.
                </Typography>
              </>
            ) : (
              <>
                <div>
                  <Typography variant="displayMedium">The file is being processed.</Typography>
                </div>
                <div>
                  <Link
                    data-cy="file-successfully-processed-link"
                    onClick={() => navigate('/admin/processing-statuses/')}
                  >
                    <Typography variant="body">click here to go to the processing page</Typography>
                  </Link>
                </div>
              </>
            )
          ) : (
            <>
              <div>
                <BaseIcon
                  type={IconType.Check}
                  size="28px"
                  colorFilter={IconColorFilter.Success}
                  aria-label="success"
                />
                <Typography variant="displayMedium">Your file has finished processing</Typography>
              </div>
              <div>
                <Link
                  data-cy="file-successfully-processed-link"
                  onClick={() => navigate(`/admin/processing-statuses/${uploadBatchId}?p=0&n=10`)}
                >
                  <Typography variant="body">click here to view upload details</Typography>
                </Link>
              </div>
            </>
          )}
        </MessageContainer>
      )}

      <DialogModal
        dialogOpen={isCancelModalOpen}
        disableEscapeKeyDown={true}
        title="Cancel File Upload"
        content={cancelUploadMessage}
        buttonGroup={cancelUploadBtnGroup}
        dataCy={'upload-cancel-file-upload-modal'}
      />
    </Wrapper>
  );

  return (
    <>
      <DialogModal
        dialogOpen={isOpen}
        title={`Upload ${displayUploadAction} ${displayUploadType} CSV`}
        titleSizeVariant="displayLarge"
        content={Content}
        buttonGroup={fileUploaded ? uploadedBtnGroup : uploadBtnGroup}
        btnGroupPosition="top"
        maxWidth="md"
        fullWidth={true}
      />
    </>
  );
};

export default UploadFileModal;
