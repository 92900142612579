import { useState, useEffect } from 'react';
import useCompanyContext from 'helpers/UseCompanyContext';
import RolesPageHelper from 'helpers/RolesPageHelper';
import roleService from 'services/RolesService';
import Role from 'classes/role';
export type SkillProfileListSort = {
  sortBy: SkillProfileListSortBy;
  sortDirection: SkillProfileListSortDirection;
};

export type SkillProfileListSortBy = 'roleName' | 'mappingStatus';

export type paginationAction = null | 'next' | 'prev';

export type SkillProfileListSortDirection = 'asc' | 'desc';

export interface useNavigationSwitchReturnObject {
  list: Role[];
  listUnsorted: Role[];
  listSort: SkillProfileListSort;
  updateSortBy: (sortBy: string) => void;
  activeItemIndex: number;
  activeItemId?: string;
  setItemFilter: (text: string) => void;
  total: number;
  isLoadingItems: boolean;
  getNextItemId: () => string;
  getPrevItemId: () => string;
  nextPage: () => void;
  prevPage: () => void;
  currentPage: number;
  rowsPerPage: number;
}

export interface NavigationState {
  listUnsorted: Role[];
  list: Role[];
  updateList: boolean;
  total: number;
  listSort: SkillProfileListSort;
  activeItemIndex: number;
  activeItemId?: string;
  isLoadingItems: boolean;
  isSaving?: boolean;
  itemFilter: string;
  appliedFilters?: any[];
  rowsPerPage: number;
  currentPage: number;
}

const defaultState: NavigationState = {
  listUnsorted: new Array<any>(),
  list: new Array<any>(),
  updateList: false,
  total: 0,
  listSort: {
    sortBy: 'roleName',
    sortDirection: 'asc',
  },
  activeItemIndex: -1,
  activeItemId: '',
  isLoadingItems: false,
  isSaving: false,
  itemFilter: '',
  appliedFilters: new Array<any>(),
  rowsPerPage: 10,
  currentPage: 1,
};

const useSkillProfileNavigation = (roleId: string): useNavigationSwitchReturnObject => {
  const [state, setState] = useState(defaultState);
  const { companyId } = useCompanyContext();

  state.activeItemId = roleId;

  const loadSkillProfiles = async () => {
    const { roles } = await roleService.getRoles({
      companyId,
      fields: ['roleId', 'roleName', 'emsiTitleName', 'emsiTitleId', 'needsApproval', 'reviewed', 'titleConfidence'],
      limit: 10000,
      excludes: ['skills', 'tags', 'aliases'],
    });
    return { list: roles, total: roles.length };
  };

  const updateFilteredList = (pagination: paginationAction) => {
    // default to initial state
    let listSorted = state.listUnsorted;
    let currentPage = state.currentPage;
    let rowsPerPage = defaultState.rowsPerPage;
    let total = state.listUnsorted.length;

    if (state.itemFilter) {
      listSorted = listSorted.filter(elemento => elemento.roleName.toLowerCase().includes(state.itemFilter));
      total = listSorted.length;
      rowsPerPage = rowsPerPage <= total ? rowsPerPage : total;
    }

    if (pagination === 'next') {
      currentPage = currentPage + 1 > Math.ceil(listSorted.length / rowsPerPage) ? 1 : currentPage + 1;
    } else if (pagination === 'prev') {
      currentPage = currentPage - 1 <= 0 ? Math.ceil(listSorted.length / rowsPerPage) : currentPage - 1;
    }

    if (state.listSort.sortBy === 'roleName') {
      listSorted = RolesPageHelper.sortByRoleName(listSorted, state.listSort.sortDirection);
    } else if (state.listSort.sortBy === 'mappingStatus') {
      listSorted = RolesPageHelper.sortByReviewStatus(listSorted, state.listSort.sortDirection);
    }

    const startIndex = (currentPage - 1) * rowsPerPage;
    let endIndex: number;

    if (currentPage === Math.ceil(listSorted.length / rowsPerPage)) {
      endIndex = listSorted.length;
    } else {
      endIndex = startIndex + rowsPerPage;
    }

    listSorted = listSorted.slice(startIndex, endIndex);

    setState(prevState => ({ ...prevState, rowsPerPage, currentPage, list: listSorted, total, updateList: false }));
  };

  const getNextItemId = () => {
    const newItemIndex = state.activeItemIndex + 1 > state.listUnsorted.length - 1 ? 0 : state.activeItemIndex + 1;
    const activeItemId = state.listUnsorted[newItemIndex].roleId;
    return activeItemId;
  };

  const getPrevItemId = () => {
    const newItemIndex = state.activeItemIndex - 1 < 0 ? state.listUnsorted.length - 1 : state.activeItemIndex - 1;
    const activeItemId = state.listUnsorted[newItemIndex].roleId;
    return activeItemId;
  };

  const nextPage = () => {
    updateFilteredList('next');
  };

  const prevPage = () => {
    updateFilteredList('prev');
  };

  const setItemFilter = text => {
    setState(prevState => ({ ...prevState, itemFilter: text, currentPage: 1, updateList: true }));
  };

  const updateSortBy = (sortBy = 'roleName') => {
    const listSort = { sortBy, sortDirection: state.listSort.sortDirection } as SkillProfileListSort;
    setState(prevState => ({ ...prevState, listSort, updateList: true }));
  };

  // Load skill profiles
  useEffect(() => {
    (async () => {
      setState(prevState => ({ ...prevState, isLoadingItems: true }));
      try {
        const { list, total } = (await loadSkillProfiles()) || [];
        setState(prevState => ({
          ...prevState,
          listUnsorted: list,
          total,
          isLoadingItems: false,
        }));
      } catch (e) {
        setState(prevState => ({ ...prevState, isLoadingItems: false }));
      }
    })();
  }, [companyId]);

  useEffect(() => {
    if (!state.updateList) return;
    updateFilteredList(null);
  }, [state.updateList]);

  useEffect(() => {
    if (state.isLoadingItems || !state.activeItemId) return;
    const newActiveItemId = state.activeItemId;
    const activeItemIndex = state.listUnsorted.findIndex(item => item.roleId === newActiveItemId);
    const currentPage = Math.ceil((activeItemIndex + 1) / state.rowsPerPage);
    setState(prevState => ({ ...prevState, activeItemIndex, currentPage, itemFilter: '', updateList: true }));
  }, [state.activeItemId, state.isLoadingItems]);

  return {
    list: state.list,
    listUnsorted: state.listUnsorted,
    listSort: state.listSort,
    updateSortBy,
    activeItemIndex: state.activeItemIndex,
    activeItemId: state.activeItemId,
    setItemFilter,
    total: state.total,
    isLoadingItems: state.isLoadingItems,
    getNextItemId,
    getPrevItemId,
    nextPage,
    prevPage,
    currentPage: state.currentPage,
    rowsPerPage: state.rowsPerPage,
  };
};

export default useSkillProfileNavigation;
