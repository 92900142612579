import React, { ReactElement, useEffect, useState } from 'react';
import IntegrationService from 'services/IntegrationService';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import useCompanyContext from 'helpers/UseCompanyContext';
import {
  ExternalSkillMappingResponse,
  CreateUpdateIntegrationSkillVendorResponse,
  DeleteIntegrationSkillVendorResponse,
  ErrorResponse,
} from 'types/api_responses';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useGetCompanyVendorIntegrations, { IntegrationOptionType } from 'helpers/useGetCompanyVendorIntegrationOptions';
import IntegrationOption from 'components/molecules/IntegrationOption';
import { IntegrationSkillVendor, SpacingSizes } from 'types/types';
import DialogModal from 'components/molecules/DialogModal';
import { EMSIBGButton } from 'components/atoms/ButtonGroup';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { toast } from 'react-toastify';

const ExternalSkillMappingsSection = styled.div`
  height: 100%;
  margin: ${props => props.theme.customSpacing.px.m}px;
`;

const IntegrationOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${props => props.theme.customSpacing.px.m}px;
`;

const ExternalSkillMappingsHeader = styled.h1``;

const IntOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MainContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const MainContentPanel = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 6;
`;

const MainContentSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const SkillVendorDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SkillVendorDialogItemWrapper = styled.div`
  display: flex;
  padding-bottom: 5px;
`;

const SkillVendorDialogLabel = styled.label`
  flex-grow: 1;
  flex-basis: 0;
`;

const SkillVendorDialogInput = styled.input`
  flex-grow: 1;
  flex-basis: 50%;
  align-self: flex-start;
`;

const emptyIntegrationSkillVendor: IntegrationSkillVendor = {
  skillVendorId: '',
  id: '',
  skillVendorName: '',
  inUse: null,
};

const IntegrationSettingsPage = (): ReactElement => {
  const { companyId } = useCompanyContext();
  const [loadingExternalSkillMappingSummary, setLoadingExternalSkillMappingSummary] = useState(true);
  const [externalSkillMappingSummary, setExternalSkillMappingSummary] = useState<ExternalSkillMappingResponse>({
    'Synonym Match': 0,
    'Exact Match - Mapped to Maintained Skill': 0,
    'Exact Match - Mapped to Skills Cloud Skill': 0,
    Unmatched: 0,
  });
  const [integrationOptions, setIntegrationOptions] = useState<IntegrationOptionType[]>([]);
  const { getCompanyIntegrations } = useGetCompanyVendorIntegrations();
  const [skillVendors, setSkillVendors] = useState<IntegrationSkillVendor[]>([]);
  const [isSkillVendorDialogModalOpen, setIsSkillVendorDialogModalOpen] = useState<boolean>(false);
  const [isSVDeleteConfirmationModalOpen, setIsSVDeleteConfirmationModalOpen] = useState<boolean>(false);
  const [skillVendorId, setSkillVendorId] = useState<string>('');
  const [skillVendorName, setSkillVendorName] = useState<string>('');
  const [deletingSkillVendor, setDeletingSkillVendor] = useState<IntegrationSkillVendor>(emptyIntegrationSkillVendor);
  const [isEditingSkillVendor, setIsEditingSkillVendor] = useState<boolean>(false);
  const [isLoadingSkillVendors, setIsLoadingSkillVendors] = useState<boolean>(false);
  const [isSavingSkillVendor, setIsSavingSkillVendor] = useState<boolean>(false);
  const [isDeletingSkillVendor, setIsDeletingSkillVendor] = useState<boolean>(false);
  const [isCreatingSkillVendor, setIsCreatingSkillVendor] = useState<boolean>(false);
  const [isSkillMappingsCreating, setIsSkillMappingsCreating] = useState(false);

  const fetchExternalSkills = async () => {
    if (integrationOptions[0]?.vendorId) {
      setLoadingExternalSkillMappingSummary(true);
      const esms = await IntegrationService.fetchExternalSkills(companyId, integrationOptions[0]?.vendorId); // this should be replace by the id of the selected option
      setExternalSkillMappingSummary(esms);
      setLoadingExternalSkillMappingSummary(false);
    }
  };

  useEffect(() => {
    fetchExternalSkills();
  }, [companyId, integrationOptions]);

  useEffect(() => {
    (async () => {
      const intOptions = await getCompanyIntegrations();
      setIntegrationOptions(intOptions);
    })();
  }, [companyId]);

  useEffect(() => {
    (async () => {
      // If we have integration options AND we're not performing an action on a skill vendor (action is finished)
      if (integrationOptions?.length > 0 && !isSavingSkillVendor && !isCreatingSkillVendor && !isDeletingSkillVendor) {
        setIsLoadingSkillVendors(true);
        // We'll need to change this as we add integrations so we're not referencing integrationOptions[0]
        const companyIntSkillVendors = await IntegrationService.getSkillVendors(
          companyId,
          integrationOptions[0].vendorId
        );
        setSkillVendors(companyIntSkillVendors.data);
        setIsLoadingSkillVendors(false);
      }
    })();
  }, [integrationOptions, companyId, isSavingSkillVendor, isDeletingSkillVendor, isCreatingSkillVendor]);

  const createSkillMappings = async (companyId: string, vendorId: string) => {
    try {
      setIsSkillMappingsCreating(true);
      const results = await IntegrationService.createSkills(companyId, vendorId);
      const { success, failed } = results.result;
      toast.success(`${success.length} maintained skills created. Error creating ${failed.length} maintained skills`);
      fetchExternalSkills();
    } catch (err) {
      toast.error('Could not create skill mappings');
    } finally {
      setIsSkillMappingsCreating(false);
    }
  };

  const exportSkillsHandler = async (vendorId: string) => {
    setIntegrationOptions(prevState => {
      const newState = prevState.map(option => {
        if (option.vendorId === vendorId) {
          return {
            ...option,
            loading: true,
          };
        }
        return option;
      });
      return newState;
    });

    const resp = await IntegrationService.exportSkills(companyId, vendorId);
    alert(resp.data.message);

    setIntegrationOptions(prevState => {
      return prevState.map(option => {
        return {
          ...option,
          loading: false,
        };
      });
    });
  };

  const exportSkillsMappingsHandler = async (vendorId: string) => {
    setIntegrationOptions(prevState => {
      const newState = prevState.map(option => {
        if (option.vendorId === vendorId) {
          return {
            ...option,
            loading: true,
          };
        }
        return option;
      });
      return newState;
    });

    const resp = IntegrationService.exportSkillsMapping(companyId);

    alert(resp.data.message);

    setIntegrationOptions(prevState => {
      return prevState.map(option => {
        return {
          ...option,
          loading: false,
        };
      });
    });
  };

  const closeSkillVendorDialogModal = () => {
    setIsEditingSkillVendor(false);
    setIsCreatingSkillVendor(false);
    setIsSkillVendorDialogModalOpen(false);
  };

  const openSkillVendorDialogModal = (skillVendorId: string, editMode = false) => {
    setIsEditingSkillVendor(editMode);
    setIsCreatingSkillVendor(!editMode);
    if (skillVendorId) {
      const esv = skillVendors.find(io => io.skillVendorId === skillVendorId);
      const tempSV = esv || emptyIntegrationSkillVendor;
      setSkillVendorId(tempSV.skillVendorId);
      setSkillVendorName(tempSV.skillVendorName);
    }
    setIsSkillVendorDialogModalOpen(true);
  };

  const skillVendorCrudOpHandler = async (
    skillVendorId: string,
    skillVendorName: string,
    deleteSkillVendor = false
  ): Promise<CreateUpdateIntegrationSkillVendorResponse | DeleteIntegrationSkillVendorResponse | ErrorResponse> => {
    if (!isEditingSkillVendor) {
      // We'll need to change this when we have multiple integration vendors available so we're not referencing integrationOptions[0]
      const createOrDeleteSkillVendorResp = await IntegrationService.createOrDeleteSkillVendor(
        companyId,
        integrationOptions[0].vendorId,
        skillVendorId,
        skillVendorName,
        deleteSkillVendor
      );
      return createOrDeleteSkillVendorResp;
    } else {
      const updateSkillVendorResp = await IntegrationService.updateSkillVendor(
        companyId,
        integrationOptions[0].vendorId,
        skillVendorId,
        skillVendorName
      );
      return updateSkillVendorResp;
    }
  };

  const confirmSkillVendorDelete = async () => {
    setIsDeletingSkillVendor(true);
    const deleteResp = await skillVendorCrudOpHandler(
      deletingSkillVendor.skillVendorId,
      deletingSkillVendor.skillVendorName,
      true
    );
    const errorResp = deleteResp as ErrorResponse;
    if (errorResp.errorMessage) {
      toast.error(informationToast(`Unable to delete Skill Vendor: ${errorResp.errorMessage}`));
    } else {
      const delResp = deleteResp as DeleteIntegrationSkillVendorResponse;
      console.info(`Deleted Skill Vendor with integrationExternalId: ${delResp.data.removedSkillVendorIIID}`);
      toast.success(informationToast(`Successfully deleted Skill Vendor "${deletingSkillVendor.skillVendorName}"`));
    }
    closeSVDeleteConfirmationModal();
    setDeletingSkillVendor(emptyIntegrationSkillVendor);
    setIsDeletingSkillVendor(false);
  };

  const createOrUpdateSkillVendorHandler = async (skillVendorId: string, skillVendorName: string) => {
    setIsSavingSkillVendor(true);
    const createOrUpdateResp = await skillVendorCrudOpHandler(skillVendorId, skillVendorName);
    const errorResp = createOrUpdateResp as ErrorResponse;
    if (errorResp.errorMessage) {
      toast.error(
        informationToast(
          `Unable to ${isEditingSkillVendor ? 'update' : 'create'} Skill Vendor: ${errorResp.errorMessage}`
        )
      );
    } else {
      const resp = createOrUpdateResp as CreateUpdateIntegrationSkillVendorResponse;
      toast.success(
        informationToast(
          `Successfully ${isEditingSkillVendor ? 'updated' : 'created'} Skill Vendor "${
            resp.data.skillVendor.skillVendorName
          }"`
        )
      );
    }
    setIsSavingSkillVendor(false);
  };

  const createOrUpdateSkillVendorModalContent = () => {
    return (
      <form>
        <SkillVendorDialogContainer>
          <SkillVendorDialogItemWrapper>
            <SkillVendorDialogLabel htmlFor="skillVendorId">ID:</SkillVendorDialogLabel>
            <SkillVendorDialogInput
              id="skillVendorId"
              name="skillVendorId"
              type="text"
              value={skillVendorId}
              onChange={e => setSkillVendorId(e.target.value)}
              disabled={isEditingSkillVendor}
            />
          </SkillVendorDialogItemWrapper>
          <SkillVendorDialogItemWrapper>
            <SkillVendorDialogLabel htmlFor="skillVendorName">Name:</SkillVendorDialogLabel>
            <SkillVendorDialogInput
              id="skillVendorName"
              name="skillVendorName"
              type="text"
              value={skillVendorName}
              onChange={e => setSkillVendorName(e.target.value)}
            />
          </SkillVendorDialogItemWrapper>
        </SkillVendorDialogContainer>
      </form>
    );
  };

  const informationToast = (message: string) => (
    <div>
      <span data-cy={`integration-skills-info-toast`}>{message}</span>
    </div>
  );

  const closeSVDeleteConfirmationModal = () => {
    setIsSVDeleteConfirmationModalOpen(false);
  };

  const openSVDeleteConfirmationModal = (integrationInternalId, skillVendorId, skillVendorName, inUse) => {
    setDeletingSkillVendor({ id: integrationInternalId, skillVendorId, skillVendorName, inUse });
    setIsSVDeleteConfirmationModalOpen(true);
  };

  const skillVendorModalBtnGroup: EMSIBGButton[] = [
    {
      text: isEditingSkillVendor ? 'Save' : 'Create',
      variant: 'contained',
      color: 'actionPrimary',
      isLoadingButton: true,
      loadingStatus: isSavingSkillVendor,
      loadingMessage: isEditingSkillVendor ? 'Saving...' : 'Creating...',
      onClickEvent: async () => {
        await createOrUpdateSkillVendorHandler(skillVendorId, skillVendorName);
        setIsEditingSkillVendor(false);
        setIsCreatingSkillVendor(false);
        closeSkillVendorDialogModal();
      },
      dataCy: 'skill-vendor-dialog-save-button',
      disabled: isSavingSkillVendor || skillVendorId.trim().length === 0 || skillVendorName.trim().length === 0,
    },
    {
      text: 'Cancel',
      onClickEvent: () => {
        setSkillVendorId(emptyIntegrationSkillVendor.skillVendorId);
        setSkillVendorName(emptyIntegrationSkillVendor.skillVendorName);
        closeSkillVendorDialogModal();
      },
      dataCy: 'skill-vendor-dialog-cancel-button',
    },
  ];

  const delConfirmationModalButtonGroup: EMSIBGButton[] = [
    {
      text: 'Cancel',
      onClickEvent: closeSVDeleteConfirmationModal,
      dataCy: 'sv-del-confirmation-dialog-cancel',
    },
    {
      text: 'Delete',
      variant: 'contained',
      color: 'actionCritical',
      onClickEvent: confirmSkillVendorDelete,
      dataCy: 'sv-del-confirmation-dialog-delete',
      loadingStatus: isDeletingSkillVendor,
      loadingMessage: 'Deleting...',
      disabled: isDeletingSkillVendor,
    },
  ];

  return (
    <>
      <MainContentTemplate pageTitle="Integration Settings" isFullWidth={true}>
        <ContentHeaderTemplate contentTitle="Skills Integration" fullWidthDivider={false} displayDivider={true} />
        <ExternalSkillMappingsSection>
          <IntegrationOptionsWrapper>
            {integrationOptions.length <= 0 && <div>Loading integration options...</div>}
            {integrationOptions.length > 0 && (
              <IntOptionsContainer>
                {integrationOptions.map((option, index) => {
                  return (
                    <>
                      <IntegrationOption
                        key={`${index}-export-skills`}
                        id={`${option.vendorId}-export-skills`}
                        title={option.vendorName}
                        loading={option.loading}
                        loadingIndicator="exporting..."
                        description={`Export skills to ${option.vendorName} integration`}
                        onClickHandler={() => exportSkillsHandler(option.vendorId)}
                        dataCy={`integration-${option.id}-export-skills`}
                        buttonText="Export"
                      />
                      <IntegrationOption
                        key={`${index}-export-skills-mappings`}
                        id={`${option.vendorId}-export-skills-mappings`}
                        title={option.vendorName}
                        loading={option.loading}
                        loadingIndicator="exporting..."
                        description={`Export skills mappings to ${option.vendorName} integration`}
                        onClickHandler={() => exportSkillsMappingsHandler(option.vendorId)}
                        dataCy={`integration-${option.id}-export-skills-mappings`}
                        buttonText="Export Skills Mappings"
                      />
                      <IntegrationOption
                        key={`${index}-create-skill-vendor`}
                        id={`${option.vendorId}-create-skill-vendor`}
                        title={option.vendorName}
                        loading={option.loading}
                        loadingIndicator="creating..."
                        description={`Create Skill Vendor for ${option.vendorName} integration`}
                        onClickHandler={() => openSkillVendorDialogModal(option.vendorId, false)}
                        dataCy={`integration-${option.id}-create-skill-vendor`}
                        buttonText="Create Skill Vendor"
                      />
                      <IntegrationOption
                        key={`${index}-create-skill-mappings`}
                        id={`${option.vendorId}-create-skill-mappings`}
                        title={option.vendorName}
                        loading={isSkillMappingsCreating}
                        loadingIndicator="mapping..."
                        description={`Create maintained skills for unmatched external skill mappings`}
                        onClickHandler={() => createSkillMappings(companyId, option.vendorId)}
                        buttonText="Create Maintained Skills"
                      />
                    </>
                  );
                })}
              </IntOptionsContainer>
            )}
          </IntegrationOptionsWrapper>
          <MainContentContainer>
            <MainContentPanel>
              <MainContentSection>
                <ExternalSkillMappingsHeader>Summary</ExternalSkillMappingsHeader>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Match type</TableCell>
                        <TableCell>Count</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(externalSkillMappingSummary).map(esms => (
                        <TableRow key={esms}>
                          <TableCell component="th" scope="row">
                            {esms}
                          </TableCell>
                          <TableCell>
                            {loadingExternalSkillMappingSummary ? (
                              <CircularProgress />
                            ) : (
                              externalSkillMappingSummary[esms]
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </MainContentSection>
            </MainContentPanel>
            <MainContentPanel>
              <MainContentSection>
                <ExternalSkillMappingsHeader>Skill Vendors</ExternalSkillMappingsHeader>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>In Use</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoadingSkillVendors && (
                        <>
                          <TableRow></TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                              <CircularProgress />
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </>
                      )}
                      {!isLoadingSkillVendors &&
                        skillVendors &&
                        skillVendors.map(sv => (
                          <TableRow key={sv.id}>
                            <TableCell component="th" scope="row">
                              {sv.skillVendorId}
                            </TableCell>
                            <TableCell>{sv.skillVendorName}</TableCell>
                            <TableCell>{sv.inUse?.toString()}</TableCell>
                            <TableCell>
                              <BaseIcon
                                type={IconType.Edit}
                                onIconClick={() => openSkillVendorDialogModal(sv.skillVendorId, true)}
                                dataCy={`skill-vendor-edit-icon-${sv.id}`}
                                marginLeft={SpacingSizes.None}
                                marginRight={SpacingSizes.None}
                              />
                            </TableCell>
                            <TableCell>
                              <BaseIcon
                                disabled={sv.inUse === true}
                                type={IconType.Delete}
                                onIconClick={() =>
                                  openSVDeleteConfirmationModal(sv.id, sv.skillVendorId, sv.skillVendorName, sv.inUse)
                                }
                                dataCy={`skill-vendor-delete-icon-${sv.id}`}
                                marginLeft={SpacingSizes.None}
                                marginRight={SpacingSizes.None}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </MainContentSection>
            </MainContentPanel>
          </MainContentContainer>
        </ExternalSkillMappingsSection>
      </MainContentTemplate>
      <DialogModal
        dialogOpen={isSkillVendorDialogModalOpen}
        closeModal={() => closeSkillVendorDialogModal()}
        title="Enter Skill Vendor details"
        content={createOrUpdateSkillVendorModalContent()}
        buttonGroup={skillVendorModalBtnGroup}
      />
      <DialogModal
        dialogOpen={isSVDeleteConfirmationModalOpen}
        closeModal={() => closeSVDeleteConfirmationModal()}
        title="Delete Skill Vendor"
        content={
          <>
            {`Are you sure you want to delete "${deletingSkillVendor.skillVendorName}"?`}
            <br />
            <br />
          </>
        }
        buttonGroup={delConfirmationModalButtonGroup}
      />
    </>
  );
};

export default IntegrationSettingsPage;
