import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';

type ChevronProps = {
  flip?: boolean;
  horizontal?: boolean;
  color?: string;
  clickHandler?: () => void;
  style?: React.CSSProperties;
  widthInPixels?: number;
  dataCy?: string;
};

const Container = styled.span<{ rotation: string }>`
  display: inline-block;
  cursor: pointer;
  margin-left: auto;

  svg {
    transform: rotate(${({ rotation }) => rotation});
    transition: all 200ms;
  }
`;

const Chevron = ({
  flip = true,
  horizontal = false,
  color,
  clickHandler,
  style,
  widthInPixels = 15,
  dataCy = 'chevron-icon',
}: ChevronProps): ReactElement => {
  const theme = useTheme();

  const chevronColor = color || `${theme.colors.icon.default.hex}`;

  let rotation = flip ? '180deg' : '0deg';
  horizontal && (rotation = '-90deg');
  return (
    <Container style={{ ...style }} rotation={rotation} onClick={clickHandler} data-cy={dataCy}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={widthInPixels}
        height={widthInPixels}
        viewBox={`0 0 ${widthInPixels} ${0.6 * widthInPixels}`}
        fill="none"
      >
        <path
          d={`M${0.94 * widthInPixels} ${0.06 * widthInPixels}L${0.5 * widthInPixels} ${0.4 * widthInPixels}L${
            0.06 * widthInPixels
          } ${0.06 * widthInPixels}`}
          stroke={chevronColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Container>
  );
};

export default Chevron;
