import React, { ReactElement, useState } from 'react';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { Formik } from 'formik';
import FormikTextField from 'components/atoms/formElements/FormikTextField';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { BorderColor, IconColorFilter, SpacingSizes } from 'types/types';
import { Divider, TextField, FormControl, MenuItem, InputLabel, Select, Typography } from '@mui/material';

const FormikTextFieldsDemo = (): ReactElement<any> => {
  const [textFieldTooltip, setTextFieldTooltip] = useState<string | undefined>(undefined);
  const [tooltipIconColor, settooltipIconColor] = useState();

  const handleTooltipChange = (event: any) => {
    setTextFieldTooltip(event.target.value);
  };

  const handleTooltipColorChange = (event: any) => {
    settooltipIconColor(event.target.value);
  };

  return (
    <>
      <Formik
        initialValues={{
          simpleTextField: '',
          simpleTextFieldWithPlaceholder: '',
          textFieldWithLabel: '',
          textFieldRequiredWithValidation: '',
          textFieldReadOnly: 'Some read only text',
          textFieldWithHelperMessage: '',
          textFieldNumberType: '',
          textFieldPasswordType: '',
          textFieldWithLabelActionElement: '',
          textFieldAsTextArea: '',
          textFieldWithStartElement: '',
          textFieldWithEndElement: '',
          textFieldTooltip: '',
        }}
        validationSchema={Yup.object().shape({
          textFieldRequiredWithValidation: Yup.string()
            .min(2, 'Must be at least 2 characters long')
            .max(10, 'Must be less than 10 characters long')
            .required('*'),
        })}
        onSubmit={() => undefined}
      >
        <div>
          <div style={{ display: 'flex', gap: '16px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
              <br />
              <FormikTextField
                name="simpleTextField"
                dataCy="simpleTextField"
                tooltipText={textFieldTooltip}
                tooltipIconColor={tooltipIconColor}
              />
              <br />
              <FormikTextField
                name="simpleTextFieldWithPlaceholder"
                placeholder="Placeholder Text"
                dataCy="simpleTextFieldWithPlaceholder"
                tooltipText={textFieldTooltip}
                tooltipIconColor={tooltipIconColor}
              />
              <FormikTextField
                name="textFieldWithLabel"
                label="Text Field with label"
                dataCy="textFieldWithLabel"
                tooltipText={textFieldTooltip}
                tooltipIconColor={tooltipIconColor}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
              <FormikTextField
                name="textFieldRequiredWithValidation"
                label="Text Field with required and validation"
                required={true}
                dataCy="textFieldRequiredWithValidation"
                tooltipText={textFieldTooltip}
                tooltipIconColor={tooltipIconColor}
              />
              <FormikTextField
                name="textFieldReadOnly"
                label="Text Field with read only"
                readOnly={true}
                dataCy="textFieldReadOnly"
                tooltipText={textFieldTooltip}
                tooltipIconColor={tooltipIconColor}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
              <FormikTextField
                name="textFieldWithHelperMessage"
                label="Text Field with helper message"
                informationText="Some information about the text field"
                dataCy="textFieldWithHelperMessage"
                tooltipText={textFieldTooltip}
                tooltipIconColor={tooltipIconColor}
              />
              <FormikTextField
                name="textFieldNumberType"
                label="Text Field with number type"
                type="number"
                dataCy="textFieldNumberType"
                tooltipText={textFieldTooltip}
                tooltipIconColor={tooltipIconColor}
              />
              <FormikTextField
                name="textFieldPasswordType"
                label="Text Field with password type"
                type="password"
                dataCy="textFieldPasswordType"
                tooltipText={textFieldTooltip}
                tooltipIconColor={tooltipIconColor}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
              <FormikTextField
                name="textFieldWithLabelActionElement"
                label="Text Field with label action element"
                labelActionElement={
                  <Button variant="text" onClick={() => alert('Action button clicked')}>
                    Action
                  </Button>
                }
                dataCy="textFieldWithLabelActionElement"
                tooltipText={textFieldTooltip}
                tooltipIconColor={tooltipIconColor}
              />
              <FormikTextField
                name="textFieldAsTextArea"
                label="Text Field as text area"
                displayAsTextArea={true}
                dataCy="textFieldAsTextArea"
                tooltipText={textFieldTooltip}
                tooltipIconColor={tooltipIconColor}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
              <FormikTextField
                name="textFieldWithStartElement"
                label="Text Field with start element"
                startElement={
                  <BaseIcon type={IconType.User} marginLeft={SpacingSizes.None} marginRight={SpacingSizes.None} />
                }
                dataCy="textFieldWithStartElement"
                tooltipText={textFieldTooltip}
                tooltipIconColor={tooltipIconColor}
              />
              <FormikTextField
                name="textFieldWithEndElement"
                label="Text Field with end element"
                endElement={
                  <BaseIcon
                    type={IconType.Add}
                    marginLeft={SpacingSizes.None}
                    marginRight={SpacingSizes.None}
                    colorFilter={IconColorFilter.Information}
                    borderColor={BorderColor.InformationSubdued}
                  />
                }
                dataCy="textFieldWithEndElement"
                tooltipText={textFieldTooltip}
                tooltipIconColor={tooltipIconColor}
              />
            </div>
          </div>
          <Divider style={{ margin: '16px 0px' }} />
          <div style={{ display: 'flex', gap: '16px' }}>
            <TextField
              id="text-field-tooltip"
              value={textFieldTooltip}
              label="Optional TextField Tooltip Text"
              placeholder="Enter text to display tooltip"
              onChange={handleTooltipChange}
            />
            <FormControl>
              <InputLabel id="color-select-label">Tooltip Icon Color</InputLabel>
              <Select
                labelId="color-select-label"
                id="color-select"
                value={tooltipIconColor}
                label="Color"
                onChange={handleTooltipColorChange}
              >
                <MenuItem key="color-option-default" value="Default">
                  <Typography variant="strong" color="text.default">
                    Default&nbsp;
                  </Typography>
                  ( tooltipIconColor=Default )
                </MenuItem>
                <MenuItem key="color-option-information" value="Information">
                  <Typography variant="strong" color="text.information">
                    Information&nbsp;
                  </Typography>
                  ( tooltipIconColor=Information )
                </MenuItem>
                <MenuItem key="color-option-warning" value="Warning">
                  <Typography variant="strong" color="text.warning">
                    Warning&nbsp;
                  </Typography>
                  ( tooltipIconColor=Warning )
                </MenuItem>
                <MenuItem key="color-option-critical" value="Critical">
                  <Typography variant="strong" color="text.critical">
                    Critical&nbsp;
                  </Typography>
                  ( tooltipIconColor=Critical )
                </MenuItem>
              </Select>
              <Typography variant="caption" paragraph={true} sx={{ maxWidth: '300px' }}>
                * This color will only be applied if tooltipText is provided
              </Typography>
            </FormControl>
          </div>
        </div>
      </Formik>
    </>
  );
};

export default FormikTextFieldsDemo;
