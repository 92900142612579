import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import Typography from '@mui/material/Typography';
import ProgressBarSegment from 'components/atoms/ProcessingProgressBarSegment';

export type ProcessingProgressBarKeyDataElement = {
  label: string;
  color: string;
  count?: number;
};

type ProcessingProgressBarKeyProps = {
  data: ProcessingProgressBarKeyDataElement[];
  showCount?: boolean;
};

const KeyWrapper = styled.div`
  display: flex;
  font-size: 9px;
  margin-bottom: ${props => props.theme.customSpacing.px.xs}px;
`;

const Label = ({ label, color, count, showCount }) => {
  const theme = useTheme();

  return (
    <>
      <div style={{ width: '8px' }}>
        <ProgressBarSegment count={1} total={1} color={color} title={''} />
      </div>
      <Typography
        variant="caption"
        style={{ padding: '0px 6px 0px 6px', marginTop: 'auto', marginBottom: 'auto' }}
        color={theme.colors.text.default}
      >
        {label.charAt(0).toUpperCase() + label.slice(1)}
      </Typography>
      {showCount && count && (
        <Typography
          variant="strong"
          style={{ padding: '0px 12px 0px 0px', marginTop: 'auto', marginBottom: 'auto' }}
          color={theme.colors.text.default}
        >
          {count.toLocaleString()}
        </Typography>
      )}
    </>
  );
};

const ProcessingProgressBarKey = ({ data, showCount = false }: ProcessingProgressBarKeyProps): ReactElement => {
  return (
    <KeyWrapper>
      {data.map(d => {
        return (
          <Label
            key={`data-label-${d.label.replace(/\s+/g, '')}`}
            label={d.label}
            color={d.color}
            count={d.count}
            showCount={showCount}
          />
        );
      })}
    </KeyWrapper>
  );
};

export default ProcessingProgressBarKey;
