import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { To, useNavigate } from 'react-router-dom';
import ChevronRight from 'components/atoms/icons/ChevronRight';
import useAuthContext from 'helpers/UseAuthContext';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${props => props.theme.customSpacing.px.m}px;
`;

const RowItem = styled.div`
  cursor: pointer;
  padding: ${props => `${props.theme.customSpacing.px.base * 4}px`} 0px;
  margin: ${props => `0px ${props.theme.customSpacing.px.m}px`};
  border-bottom: 1px solid ${props => props.theme.colors.border.subdued};

  &:last-child {
    border-bottom: none;
  }

  &:hover svg path {
    fill: ${props => props.theme.colors.interactive.default};
  }

  @media (max-width: 768px) {
    margin: 0px;
  }
`;

const RowItemTitle = styled(Typography)`
  ${RowItem}:hover & {
    color: ${props => props.theme.colors.interactive.default};
  }
`;

const RowIconWrapper = styled.div`
  display: flex;
  justify-content: right;
  padding-right: ${props => `${props.theme.customSpacing.px.xs}px`};
  transition: padding-right ease-in-out;

  ${RowItem}:hover & {
    padding-right: 0px;
  }
`;

const SettingsOption = (title: string, description: string, navigateTo: To, dataCy: string): ReactElement => {
  const navigate = useNavigate();

  return (
    <>
      <RowItem onClick={() => navigate(navigateTo)} data-cy={dataCy}>
        <Grid container>
          <Grid item xs={10}>
            <Grid container>
              <Grid item xs={12} sm={7}>
                <RowItemTitle variant="heading">{title}</RowItemTitle>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Typography color="text.subdued">{description}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
            <RowIconWrapper>
              <ChevronRight />
            </RowIconWrapper>
          </Grid>
        </Grid>
      </RowItem>
    </>
  );
};

const CompanySettingsPage = (): ReactElement => {
  const { isCustomerSupport, isEmsiAdmin } = useAuthContext();
  return (
    <>
      <MainContentTemplate pageTitle="Settings, Overview" isFullWidth={false}>
        <ContentHeaderTemplate contentTitle="Overview" fullWidthDivider={false} displayDivider={true} />
        <Wrapper>
          {SettingsOption('Users', 'Manage Users', '/admin/user-management', 'link-users')}
          {SettingsOption('Activity Log', 'View changes made to roles', '/event-logs', 'link-activity-log')}
          {SettingsOption('Benchmarks', 'Manage Benchmarks', '/admin/benchmark-management', 'link-manage-benchmarks')}
          {SettingsOption('Custom Skills', 'Manage Custom Skills', '/admin/custom-skills', 'link-custom-skills')}
          {SettingsOption(
            'Processing Statuses',
            'View file processing statuses',
            '/admin/processing-statuses',
            'link-view-processing-statuses'
          )}
          {isEmsiAdmin() &&
            SettingsOption(
              'Notifications',
              'Manage Notifications settings',
              '/admin/notifications-settings',
              'link-notifications-settings'
            )}
          {isEmsiAdmin() &&
            SettingsOption(
              'Integrations',
              'Manage integration credentials and configuration',
              '/admin/integration-settings',
              'link-integration-settings'
            )}
          {isCustomerSupport() &&
            SettingsOption(
              'Manage Companies',
              'Manage Companies',
              '/admin/company-management',
              'link-manage-companies'
            )}
        </Wrapper>
      </MainContentTemplate>
    </>
  );
};

export default CompanySettingsPage;
