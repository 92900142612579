import React, { ReactElement } from 'react';
import styled, { css, useTheme } from 'styled-components';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckboxListLineItem, { CheckboxListLineItemProps } from 'components/atoms/CheckboxListLineItem';

interface CheckboxListProps {
  items: Array<CheckboxListLineItemProps>;
  isListDisabled?: boolean;
  isListCritical?: boolean;
  tabIndex?: number;
  name?: string;
  handleItemToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  dataCy?: string;
  dataColumnsCount: 0 | 1 | 2;
  dataColumn1Title?: string;
  dataColumn2Title?: string;
  [x: string]: any;
}

const ColumnHeaderWrapper = styled(Grid)`
  flex-grow: 1;
  justify-content: flex-end;
`;

const DataHeaderWrapper = styled(Grid)`
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-end;
  margin-top: 0px;
`;

const DataHeader = styled(Grid)<{ datacolumnscount?: number }>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  text-align: right;

  ${props =>
    props.datacolumnscount === 2 &&
    css`
      justify-content: center;
      text-align: center;
    `};
`;

const CheckboxList = ({
  items = [],
  isListDisabled,
  isListCritical,
  tabIndex = 0,
  name,
  handleItemToggle,
  dataCy,
  dataColumnsCount,
  dataColumn1Title = '',
  dataColumn2Title = '',
  ...props
}: CheckboxListProps): ReactElement => {
  const theme = useTheme();

  return (
    <>
      {items && items.length > 0 && (
        <>
          <ColumnHeaderWrapper container spacing={0}>
            {dataColumnsCount > 0 && (
              <DataHeaderWrapper
                container
                item
                xs={dataColumnsCount === 2 ? 4 : 2}
                spacing={0.5}
                className="checkbox-list-header"
              >
                <DataHeader
                  item
                  className="data-column-header"
                  data-cy="data-column-header-1"
                  xs={dataColumnsCount === 2 ? 6 : 12}
                  datacolumnscount={dataColumnsCount}
                >
                  <Typography variant="caption" color="text.disabled">
                    {dataColumn1Title}
                  </Typography>
                </DataHeader>
                {dataColumnsCount === 2 && (
                  <DataHeader
                    item
                    className="data-column-header"
                    data-cy="data-column-header-2"
                    xs={dataColumnsCount === 2 ? 6 : 12}
                  >
                    <Typography variant="caption" color="text.disabled">
                      {dataColumn2Title}
                    </Typography>
                  </DataHeader>
                )}
              </DataHeaderWrapper>
            )}
          </ColumnHeaderWrapper>
          <FormGroup
            tabIndex={tabIndex}
            aria-label="list of suggested skills"
            data-cy={'checkbox-list' + (`-${dataCy}` || '')}
            style={{ rowGap: `${theme.customSpacing.px.xxs}px`, margin: `${theme.customSpacing.px.xs}px 0px` }}
          >
            {items.map((i, index) => {
              return (
                <CheckboxListLineItem
                  tabIndex={tabIndex}
                  {...props}
                  key={i.value}
                  label={i.label}
                  value={i.value}
                  isChecked={i.isChecked}
                  isDisabled={isListDisabled || i.isDisabled || false}
                  isCritical={isListCritical || i.isCritical || false}
                  dataCy={(dataCy || 'checkbox') + `-list-item-${i.value}`}
                  dataColumn1={i.dataColumn1}
                  dataColumn2={i.dataColumn2}
                  dataColumnsCount={dataColumnsCount}
                  name={name}
                  handleItemToggle={e => handleItemToggle(e)}
                  aria-label={`skill ${index} - ${i.label}`}
                />
              );
            })}
          </FormGroup>
        </>
      )}
    </>
  );
};

export default CheckboxList;
