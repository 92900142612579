import React, { ReactElement, ReactNode } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import { DrawerProps } from '@mui/material';
import { SxProps } from '@mui/system';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import styled, { css } from 'styled-components';

const MainContent = styled(Box)<{ $hasBorder?: boolean }>`
  overflow-x: clip;
  overflow-y: auto;
  border: 1px 0px 0px solid ${props => props.theme.colors.border.default};

  &::-webkit-scrollbar-thumb {
    border: 1px solid #fff;
  }

  .MuiDataGrid-columnHeader:first-child {
    padding-left: ${props => props.theme.customSpacing.px.base * 7}px;
  }

  .MuiDataGrid-cell:first-child {
    padding-left: ${props => props.theme.customSpacing.px.base * 7}px;
  }

  .MuiDataGrid-columnHeader:last-child {
    padding-right: ${props => props.theme.customSpacing.px.base * 7}px;
  }

  .MuiDataGrid-cell:last-child {
    padding-right: ${props => props.theme.customSpacing.px.base * 7}px;
  }

  ${props =>
    !props.$hasBorder &&
    css`
      border: none;
    `}
`;

export type SidePanelTemplateProps = {
  children?: ReactNode;
  drawerProps?: DrawerProps;
  width?: number;
  headerTitle?: string | ReactNode;
  headerActions?: ReactElement;
  displayHeaderDivider?: boolean;
};

const SidePanelTemplate = ({
  children,
  drawerProps = { variant: 'permanent', anchor: 'right' },
  width,
  headerTitle = '',
  headerActions,
  displayHeaderDivider = true,
}: SidePanelTemplateProps): ReactElement => {
  const drawerSx: SxProps = () => {
    return {
      width,
      '& .MuiDrawer-paper': { width },
    };
  };

  const wrapperDataCy =
    typeof headerTitle === 'string'
      ? 'side-panel-' + headerTitle.trim().replaceAll(' ', '-').toLowerCase()
      : 'side-panel-drawer-wrapper';

  return (
    <Drawer {...drawerProps} sx={drawerSx} className="side-panel-drawer" data-cy={wrapperDataCy}>
      <Toolbar />
      <ContentHeaderTemplate
        contentTitle={headerTitle}
        titleSizeVariant="heading"
        actionsComponent={headerActions}
        displayDivider={displayHeaderDivider}
        fullWidthDivider={displayHeaderDivider}
      />
      <MainContent className="side-panel-content" data-cy="side-panel-content" $hasBorder={displayHeaderDivider}>
        {children}
      </MainContent>
    </Drawer>
  );
};

export default SidePanelTemplate;
