import React, { useEffect, useState, ReactElement } from 'react';
import Role from 'classes/role';
import isEqual from 'lodash/isEqual';
import RoleMapForm from 'components/molecules/RoleMapForm';
import RoleMatchDetailColumn from 'components/molecules/RoleMatchDetailColumn';
import ContentColumn2Template from 'pages/templates/ContentColumn2Template';
import Divider from '@mui/material/Divider';
import RoleSkillKeywordSearch from 'components/molecules/RoleSkillKeywordSearch';
import { useTheme } from 'styled-components';

const resizeThreshold = 1100;

type SingleRoleMappingContentProps = {
  role: Role;
  isModifiedHandler: (isModified: boolean) => void;
  updateMappingHandler: ({ editableRole, updateElements }: { editableRole: Role; updateElements: any }) => void;
  loading?: boolean;
  resetForm?: boolean;
  updateResetForm?: () => void;
};

const SingleRoleMappingContent = ({
  role,
  isModifiedHandler,
  updateMappingHandler,
  loading = false,
  resetForm = false,
  updateResetForm,
}: SingleRoleMappingContentProps): ReactElement => {
  const theme = useTheme();
  const [editableRole, setEditableRole] = useState<Role>(role);
  const [updateElements, setUpdateElements] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isWindowResized, setIsWindowResized] = useState(windowWidth <= resizeThreshold);

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    if (resetForm) {
      setEditableRole(role);
      setUpdateElements({});
      updateResetForm?.();
    }
  }, [resetForm]);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    setIsWindowResized(windowWidth <= resizeThreshold);
  }, [windowWidth]);

  useEffect(() => {
    setEditableRole(role);
  }, [role]);

  useEffect(() => {
    if (editableRole) {
      updateMappingHandler({ editableRole, updateElements });
    }
  }, [editableRole, updateElements]);

  useEffect(() => {
    let modified = false;
    if (role.roleId === editableRole.roleId) {
      modified = !isEqual(role.data, editableRole.data);
    }
    isModifiedHandler(modified);
  }, [role, editableRole]);

  const updateRoleModification = (modifiedRoleData: any) => {
    const clone = Object.assign(Object.create(Object.getPrototypeOf(editableRole)), editableRole);
    clone.data = { ...clone.data, ...modifiedRoleData };
    setEditableRole(clone);
    setUpdateElements({ ...updateElements, ...modifiedRoleData });
  };

  const modifyEditableRole = (newData: Role) => {
    updateRoleModification(newData.data);
  };

  const modifyRoleStatus = (newData: Role) => {
    // Get the exact value for "reviewed" | "needsApproval" nodes to check the role modification
    const getModifiedNode = (node: string) => {
      return { [node]: newData.data[node] ? true : typeof role.data[node] === 'boolean' ? false : role.data[node] };
    };

    updateRoleModification(getModifiedNode(Object.keys(newData.data)[0]));
  };

  return (
    <>
      <ContentColumn2Template
        mainComponent={<RoleMapForm role={editableRole} recordModification={modifyEditableRole} loading={loading} />}
        sideComponent={
          <>
            <RoleMatchDetailColumn role={editableRole} recordModification={modifyRoleStatus} loading={loading} />
            <div style={{ display: 'flex', flexDirection: 'column', gap: `${theme.customSpacing.px.base * 8}px` }}>
              <Divider />
              <RoleSkillKeywordSearch resultsLimit={5} resultsAllowUnclassified={false} loading={loading} />
            </div>
          </>
        }
        mainComponentPosition="right"
        flexDirection={isWindowResized ? 'column' : 'row'}
      />
    </>
  );
};

export default SingleRoleMappingContent;
