import React, { ReactNode, ReactElement } from 'react';
import styled from 'styled-components';
import { CssFlexDirection } from 'types/types';
import PageBaseTemplate from 'pages/templates/PageBaseTemplate';

type ContentPlaceHolderProps = {
  children: ReactNode;
  pageTitle: string;
  flexDirection?: CssFlexDirection;
};

const Wrapper = styled.div<{ $flexDirection: string }>`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 68px;
  flex-direction: ${props => props.$flexDirection};
`;

const ContentPlaceHolder = ({
  children,
  pageTitle,
  flexDirection = CssFlexDirection.Unset,
}: ContentPlaceHolderProps): ReactElement => {
  return (
    <PageBaseTemplate pageTitle={pageTitle}>
      <Wrapper className="content-placeholder-wrapper" $flexDirection={flexDirection}>
        {children}
      </Wrapper>
    </PageBaseTemplate>
  );
};

export default ContentPlaceHolder;
