import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import HeaderBar from 'components/organisms/HeaderBar';
import axios from 'helpers/api_helper';
import { Helmet } from 'react-helmet-async';
import Typography from '@mui/material/Typography';
import { useLocation, useParams } from 'react-router-dom';

const Title = styled(Typography)`
  width: 547px;
  margin: auto;
  text-align: center;
  margin-top: ${props => props.theme.customSpacing.px.m}px;
  display: block;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const SkillDetailsContent = styled.div`
  border-width: 0px 1px 0px 1px;
  border-style: solid;
  border-color: #ccc;
  flex: 1 1 auto;
  padding: 0rem 5rem;
  @media (max-width: 768px) {
    width: 90%;
    padding-left: 0.5em;
    border-width: 0px;
  }
`;

const SkillDetailsPage = () => {
  const location = useLocation();
  const { skillId } = useParams();
  const { skill: incomingSkill } = location.state || {};
  const [skill, setSkill] = useState(incomingSkill);

  useEffect(() => {
    if (!skill) {
      const url = `${process.env.REACT_APP_API_ROOT}/skills/details`;
      axios.post(url, { skillIds: [skillId] }).then(res => {
        if (res.data.skills && res.data.skills.length > 0) {
          setSkill(res.data.skills[0]);
        }
      });
    }
  }, [skill, skillId]);

  return (
    <>
      <Helmet>Skill - {skill ? skill.title : ''}</Helmet>
      <HeaderBar />
      <SkillDetailsContent>
        {skill && (
          <>
            <Title variant="displayLarge">{skill.name}</Title>
          </>
        )}
      </SkillDetailsContent>
    </>
  );
};

export default SkillDetailsPage;
