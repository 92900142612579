import React, { useEffect, useState, ReactElement } from 'react';
import styled from 'styled-components';
import CompanyOverrideBar from 'components/molecules/CompanyOverrideBar';
import RoleComparisonColumn from 'components/organisms/RoleComparisonColumn';
import RoleComparisonSummaryColumn from 'components/organisms/RoleComparisonSummaryColumn';
import PlaceholderBar from 'components/atoms/PlaceholderBar';
import queryString from 'query-string';
import RolesService from 'services/RolesService';
import useCompanyContext from 'helpers/UseCompanyContext';
import ContainerPage from 'components/atoms/ContainerPage';
import MainContentPage from 'components/atoms/MainContentPage';
import ContentPlaceHolder from 'pages/templates/ContentPlaceHolder';
import { Role } from 'types/types';
import { useLocation } from 'react-router-dom';

const PageMainContentCustom = styled(MainContentPage)`
  @media (max-width: 825px) {
    flex-wrap: wrap;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Title = styled.div`
  font-size: 42px;
  font-weight: 700;
`;

const LoadingBar = styled.div`
  padding: 0px 10px 0px 10px;
`;

const RoleComparisonPage = (): ReactElement => {
  const { companyId } = useCompanyContext();
  const location = useLocation();
  const [highlightedSkills, setHighlightedSkills] = useState<string[]>([]);
  const [comparedRoles, setComparedRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState(false);

  const qsParams = queryString.parse(location.search);

  const replaceRole = (role, position) => {
    const newComparedRoles: Role[] = [...comparedRoles];
    newComparedRoles[position] = role;
    setComparedRoles(newComparedRoles);
  };

  const setHighlightedSkillsHandler = skillId => {
    setHighlightedSkills([skillId]);
  };

  useEffect(() => {
    (async () => {
      const incomingRoleIds = comparedRoles.map(cr => cr.roleId);
      const qsRoleIds = (qsParams.roleIds as string)?.split(',') || [];
      const roleIds = [...qsRoleIds, ...incomingRoleIds];
      if (roleIds.length === 0) return;

      setLoading(true);
      const { roles } = await RolesService.getRoles({
        companyId,
        filters: { roleId: roleIds },
        limit: 3,
      });
      setComparedRoles(roles);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, qsParams.roleIds]);

  return (
    <ContentPlaceHolder pageTitle="Compare Roles">
      <div style={{ width: '100%' }}>
        <CompanyOverrideBar />

        <ContainerPage>
          <Header>
            <Title data-cy="compare-roles-title">Compare Roles</Title>
          </Header>
          {loading && (
            <LoadingBar>
              loading roles...
              <PlaceholderBar />
            </LoadingBar>
          )}
          <PageMainContentCustom>
            <RoleComparisonColumn
              position={0}
              comparedRoles={comparedRoles}
              highlightedSkills={highlightedSkills}
              replaceRoleHandler={replaceRole}
              setHighlightedSkillsHandler={setHighlightedSkillsHandler}
            />
            <RoleComparisonColumn
              position={1}
              comparedRoles={comparedRoles}
              highlightedSkills={highlightedSkills}
              replaceRoleHandler={replaceRole}
              setHighlightedSkillsHandler={setHighlightedSkillsHandler}
            />
            <RoleComparisonColumn
              position={2}
              comparedRoles={comparedRoles}
              highlightedSkills={highlightedSkills}
              replaceRoleHandler={replaceRole}
              setHighlightedSkillsHandler={setHighlightedSkillsHandler}
            />
            <RoleComparisonSummaryColumn comparedRoles={comparedRoles} />
          </PageMainContentCustom>
        </ContainerPage>
      </div>
    </ContentPlaceHolder>
  );
};

export default RoleComparisonPage;
