import React from 'react';
import styled, { css } from 'styled-components';

export type RadioListLineItemProps = {
  label: string;
  value: string;
  message?: string;
  name?: string;
  tabIndex?: number;
  selected?: boolean;
  clickHandler?: (props: { label: string; value: string }) => void;
  dataCy?: string;
  ourPostings?: number;
  nationwidePostings?: number;
  hasPostingDataColumns?: boolean;
  onFocus?: () => void;
  disabled?: boolean;
};

const RadioListLineItem: React.FC<RadioListLineItemProps> = ({
  label,
  value,
  message,
  name,
  tabIndex,
  selected,
  clickHandler,
  ourPostings,
  nationwidePostings,
  hasPostingDataColumns = false,
  dataCy,
  disabled,
}) => {
  return (
    <StyledWrapper
      $disabled={disabled}
      $selected={selected}
      onClick={() => (!disabled && clickHandler ? clickHandler({ label, value }) : undefined)}
      className="radio-list-item"
    >
      <div>
        <input
          type="radio"
          data-cy={dataCy}
          checked={selected}
          disabled={disabled}
          readOnly={true}
          name={name || 'radioListItem'}
          tabIndex={tabIndex || 0}
        />
      </div>
      <StyledLabelWrapper hasPostingDataColumns className="radio-label">
        {label}
      </StyledLabelWrapper>
      <MessageWrapper $hasPostingDataColumns={hasPostingDataColumns}>
        <Message className="radio-message">{message}</Message>
        {hasPostingDataColumns && (
          <>
            <SubMessage $loading={!ourPostings}>{ourPostings || '-'}</SubMessage>
            <SubMessage $loading={!nationwidePostings}>{nationwidePostings || '-'}</SubMessage>
          </>
        )}
      </MessageWrapper>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div<{ $selected: boolean | undefined; $disabled: boolean | undefined }>`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.text.default};
  cursor: pointer;
  font-weight: 500;
  padding: 0.25rem;
  border-radius: ${props => props.theme.borderRadius.default};

  background-color: ${props =>
    props.$selected ? props.theme.colors.surface.neutralSubdued : props.theme.colors.surface.default};

  :hover {
    background-color: ${props =>
      props.$disabled ? props.theme.colors.surface.default : props.theme.colors.surface.neutralSubdued};
  }
`;

const Message = styled.div`
  color: ${props => props.theme.colors.text.disabled};
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  line-height: ${props => props.theme.customTypography.desktop.body.lineHeight};
`;

const MessageWrapper = styled.div<{ $hasPostingDataColumns: boolean }>`
  display: flex;
  padding: 0.25rem;
  ${props =>
    props.$hasPostingDataColumns &&
    css`
      width: 50%;
      justify-content: flex-end;
    `};
`;
const StyledLabelWrapper = styled.div<{ hasPostingDataColumns: boolean }>`
  white-space: nowrap;
  padding: 0.25rem;
  ${props =>
    props.hasPostingDataColumns &&
    css`
      width: '60%';
    `};
`;

const SubMessage = styled.div<{ $loading: boolean }>`
  width: 70px;
  text-align: center;
  font-size: ${props => props.theme.customTypography.desktop.caption.size};
  font-weight: ${props => props.theme.customTypography.desktop.caption.weight};
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.colors.text.subdued};

  ${props => props.$loading && 'opacity: 0.7;'}
`;

export default RadioListLineItem;
