import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from 'styled-components';
import { Mapping } from 'helpers/RoleUpdateMappingsModalHelper';

const RadioListItemComponentForMappings = (radioListItems: Mapping[], showId = false): ReactNode[] => {
  const theme = useTheme();
  return radioListItems.map(radioListItem => {
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Typography component={'div'} sx={{ width: '50%' }}>
            {`${radioListItem.label} ${showId ? `(${radioListItem.id})` : ''}`}
          </Typography>
          <div style={{ display: 'flex', width: '50%', float: 'right' }}>
            <Typography
              variant="caption"
              component={'div'}
              sx={{ width: '50%', textAlign: 'right' }}
              color={theme.colors.text.subdued}
            >
              {radioListItem.ourPostings || '-'}
            </Typography>
            <Typography
              variant="caption"
              component={'div'}
              sx={{ width: '50%', textAlign: 'right' }}
              color={theme.colors.text.subdued}
            >
              {radioListItem.nationwidePostings || '-'}
            </Typography>
          </div>
        </div>
        {radioListItem.message && (
          <Typography
            variant="caption"
            component={'div'}
            color={theme.colors.text.information}
          >{`(${radioListItem.message})`}</Typography>
        )}
      </>
    );
  });
};

export default RadioListItemComponentForMappings;
