import React, { ReactElement, useEffect, useMemo, useState, useCallback } from 'react';
import styled, { useTheme, css } from 'styled-components';
import ComplexInputBar from 'components/molecules/ComplexInputBar';
import Filter from 'components/atoms/icons/Filter';
import Chevron from 'components/atoms/Chevron';
import FacetOptionsBox from 'components/molecules/FacetOptionsBox';
import SearchBarEmbeddedPill from 'components/atoms/SearchBarEmbeddedPill';
import debounce from 'lodash/debounce';
import Typography from '@mui/material/Typography';
import { UILayers, AppliedFacetFilters } from 'types/types';

type RolesSearchBarProps = {
  roleFilter: string;
  setRoleFilter: (roleFilter: any) => void;
  handleClearAllFacets: (key: string) => void;
  handleSelectAllFacets: (key: string) => void;
  totalFacets: any;
  handleToggle: (area: any, value: any, displayValue?: any) => void;
  appliedFilters: AppliedFacetFilters;
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: ${UILayers.PageElement};
`;

const RolesSearchBox = styled(ComplexInputBar)`
  padding: 0px ${props => props.theme.customSpacing.px.xxs}px;
`;

const FacetCategories = styled.div<{ $expanded?: boolean }>`
  background-color: white;
  border-radius: ${props => props.theme.borderRadius.default};
  box-shadow: ${props => props.theme.boxShadow.surface1};
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: 10px;
  min-width: 225px;
  padding: ${props => props.theme.customSpacing.px.base}px;
  position: absolute;
  box-shadow: ${props => props.theme.boxShadow.surface2};

  ${props => props.$expanded && 'height: fit-content;'}

  @media (max-width: 768px) {
    min-width: 150px;
  }
`;

const FacetCategoryRow = styled.div<{ $selected: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: ${props =>
    `${props.theme.customSpacing.px.xxs}px ${props.theme.customSpacing.px.base}px ${
      props.theme.customSpacing.px.xxs
    }px ${props.theme.customSpacing.px.base * 4}px`};
  border-radius: ${props => props.theme.borderRadius.default};
  ${params =>
    params.$selected &&
    `
  background-color: ${props => props.theme.colors.interactive.default};
  color: ${props => props.theme.colors.text.onInteractive};`}

  &:hover {
    ${params => !params.$selected && `background-color: ${props => props.theme.colors.action.secondaryHovered};`}
  }

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }

  transition: all 200ms;
`;

const FacetWrapper = styled.div<{ $active: boolean }>`
  position: absolute;
  top: 3rem;
  left: 235px;
  width: 250px;
  display: ${params => (params.$active ? 'inherit' : 'none')};

  @media (max-width: 768px) {
    font-size: 0.75rem;
    left: 155px;
    width: 200px;
  }
`;

const PillsWrapper = styled.div<{ $hasPills?: boolean }>`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  transition: all 3s;

  ${params =>
    params.$hasPills &&
    css`
      min-width: 220px;
      max-width: calc(100vw - 180px);
    `};
`;

const ShowFacetsWrapper = styled(PillsWrapper)`
  cursor: pointer;
  margin-right: 1rem;
  min-width: 30px;
  overflow: hidden;
`;

const Cover = styled.div<{ $blockInput: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: ${UILayers.Cover};
  background-color: white;
  opacity: 0.4;
  display: ${props => (props.$blockInput ? 'initial' : 'none')};
`;

const StyledFacetOptionsBox = styled(FacetOptionsBox)`
  position: absolute;
  left: 250px;
`;

const StyledSearchBarEmbeddedPill = styled(SearchBarEmbeddedPill)`
  color: yellow;
`;

const FacetRow = ({ facetName, setActiveFacet, activeFacet }) => {
  const theme = useTheme();
  const selected = facetName === activeFacet;

  return (
    <FacetCategoryRow
      $selected={selected}
      onClick={() => (selected ? setActiveFacet(undefined) : setActiveFacet(facetName))}
    >
      <Typography variant="strong">{facetName}</Typography>
      <Chevron
        flip={false}
        horizontal={true}
        widthInPixels={14}
        color={selected ? theme.colors.text.onInteractive : undefined}
        dataCy="roles-search-bar-facet-list-chevron"
      />
    </FacetCategoryRow>
  );
};

const RolesSearchBar = ({
  roleFilter,
  setRoleFilter,
  handleClearAllFacets,
  handleSelectAllFacets,
  totalFacets,
  handleToggle,
  appliedFilters,
}: RolesSearchBarProps): ReactElement => {
  const theme = useTheme();
  const [showFacets, setShowFacets] = useState(false);
  const [activeFacet, setActiveFacet] = useState();
  const [localRoleFilter, setLocalRoleFilter] = useState(roleFilter);

  const debouncedSetRoleFilter = useCallback(debounce(setRoleFilter, 400), []);

  useEffect(() => {
    debouncedSetRoleFilter(localRoleFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localRoleFilter]);

  // Added to ensure population of filter on first load when filter term is included in qs params via deeplink
  useEffect(() => {
    setLocalRoleFilter(roleFilter);
  }, [roleFilter]);

  useEffect(() => {
    if (!showFacets) {
      setActiveFacet(undefined);
    }
  }, [showFacets]);

  const hasPills = useMemo(() => {
    let count = 0;
    Object.keys(appliedFilters).forEach(area => {
      count += appliedFilters[area].length;
    });
    return count > 0;
  }, [appliedFilters]);

  const filterTypeName = (filterType: string) => {
    switch (filterType) {
      case 'jobTitle':
        return 'Job Title';
      case 'jobCode':
        return 'Job Code';
      case 'jobFunction':
        return 'Job Function';
      case 'jobFamily':
        return 'Job Family';
      case 'tags':
        return 'Tags';
      case 'matchStatus':
        return 'Role Match Status';
      case 'emsiTitleId':
        return 'Lightcast Title';
      case 'soc':
        return 'Occupation';
      case 'specializedOccupationId':
        return 'Specialized Occupation';
      case 'skills':
        return 'Skill';
      case 'required':
        return 'Required';
      case 'temporary':
        return 'Temporary';
      case 'skillProfileStatus':
        return 'Skill Profile Status';
      default:
        return filterType;
    }
  };

  const memoizedUI = useMemo(() => {
    return [
      { name: 'Mapping Status', key: 'matchStatus' },
      { name: 'Job Title', key: 'jobTitle' },
      { name: 'Job Code', key: 'jobCode' },
      { name: 'Job Family', key: 'jobFamily' },
      { name: 'Job Function', key: 'jobFunction' },
      { name: 'Job Level', key: '', filterKey: '' },
      { name: 'Lightcast Title', key: 'emsiTitleId' },
      { name: 'Occupation', key: 'soc' },
      { name: 'Specialized Occupation', key: 'specializedOccupationId' },
      { name: 'Benchmark Status', key: '' },
      { name: 'Tags', key: 'tags' },
      { name: 'Aliases', key: 'aliases' },
      { name: 'Skills', key: 'skills' },
      { name: 'Temporary', key: 'temporary' },
      { name: 'Skill Profile Status', key: 'skillProfileStatus' },
      { name: 'Last Updated Date', key: '' },
    ].map(s => {
      return (
        <FacetWrapper $active={activeFacet === s.name} key={`filter-wrapper-${s.name}`}>
          {s.key !== '' && (
            <StyledFacetOptionsBox
              enabledSelectAll={true}
              showTitle={false}
              showSearch={true}
              title={s.key}
              key={s.key}
              facetList={(totalFacets[s.key] || [])
                .sort((a, b) => (b.count >= a.count ? 1 : -1))
                .filter(v => {
                  return v.key !== null;
                })
                .map(v => {
                  let displayValue = v.key;
                  const value = v?.value?.toString();
                  if (['temporary', 'needsApproval', 'reviewed'].includes(s.key)) {
                    v.key === 'true' ? (displayValue = 'yes') : (displayValue = 'no');
                  }
                  return {
                    name: `${displayValue} (${v.count})`,
                    value,
                    displayValue,
                  };
                })}
              selectedFacets={appliedFilters[s.key] || []}
              handleItemToggle={e => {
                handleToggle(s.key, e.target.value, e.target.getAttribute('data-displayvalue'));
              }}
              handleClear={() => {
                handleClearAllFacets(s.key);
              }}
              handleSelectAll={() => {
                handleSelectAllFacets(s.key);
              }}
            />
          )}
        </FacetWrapper>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalFacets, appliedFilters, activeFacet]);

  return (
    <>
      <Cover $blockInput={showFacets} onClick={() => setShowFacets(false)} />
      <Wrapper>
        <RolesSearchBox
          placeholder={'Search Roles'}
          value={localRoleFilter}
          name="roles-table-search-textbox"
          onChange={e => setLocalRoleFilter(e.target.value)}
          preItem={
            <div>
              <PillsWrapper $hasPills={hasPills} style={{ overflow: 'hidden' }}>
                <ShowFacetsWrapper
                  onClick={() => {
                    setActiveFacet(undefined);
                    setShowFacets(!showFacets);
                  }}
                >
                  <Filter color={theme.colors.icon.subdued.hex} dataCy="roles-facet-filter-icon" />
                </ShowFacetsWrapper>
                <PillsWrapper $hasPills={hasPills}>
                  {Object.keys(appliedFilters).map(filterType => {
                    return appliedFilters[filterType].map((filter: { key: string; value: string }) => {
                      return (
                        <StyledSearchBarEmbeddedPill
                          item={{
                            name: `${filterTypeName(filterType)}: ${filter.key}`,
                          }}
                          key={`${filterType}-${filter.value}`}
                          removeHandler={() => {
                            handleToggle(filterType, filter.value);
                          }}
                        />
                      );
                    });
                  })}
                </PillsWrapper>
              </PillsWrapper>
            </div>
          }
        />
        {showFacets && (
          <FacetCategories>
            <FacetRow facetName={'Mapping Status'} activeFacet={activeFacet} setActiveFacet={setActiveFacet}></FacetRow>
            <FacetRow
              facetName={'Skill Profile Status'}
              activeFacet={activeFacet}
              setActiveFacet={setActiveFacet}
            ></FacetRow>
            <FacetRow facetName={'Job Title'} activeFacet={activeFacet} setActiveFacet={setActiveFacet}></FacetRow>
            <FacetRow facetName={'Job Code'} activeFacet={activeFacet} setActiveFacet={setActiveFacet}></FacetRow>
            <FacetRow facetName={'Job Family'} activeFacet={activeFacet} setActiveFacet={setActiveFacet}></FacetRow>
            <FacetRow facetName={'Job Function'} activeFacet={activeFacet} setActiveFacet={setActiveFacet}></FacetRow>
            <FacetRow
              facetName={'Lightcast Title'}
              activeFacet={activeFacet}
              setActiveFacet={setActiveFacet}
            ></FacetRow>
            <FacetRow facetName={'Occupation'} activeFacet={activeFacet} setActiveFacet={setActiveFacet}></FacetRow>
            <FacetRow
              facetName={'Specialized Occupation'}
              activeFacet={activeFacet}
              setActiveFacet={setActiveFacet}
            ></FacetRow>
            <FacetRow facetName={'Tags'} activeFacet={activeFacet} setActiveFacet={setActiveFacet}></FacetRow>
            <FacetRow facetName={'Aliases'} activeFacet={activeFacet} setActiveFacet={setActiveFacet}></FacetRow>
            <FacetRow facetName={'Skills'} activeFacet={activeFacet} setActiveFacet={setActiveFacet}></FacetRow>
            <FacetRow facetName={'Temporary'} activeFacet={activeFacet} setActiveFacet={setActiveFacet}></FacetRow>
          </FacetCategories>
        )}

        {memoizedUI}
      </Wrapper>
    </>
  );
};

export default RolesSearchBar;
