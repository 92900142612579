import React, { ReactElement } from 'react';
import Chip from '@mui/material/Chip';
import { SpacingSizes } from 'types/types';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import styled, { CSSProperties } from 'styled-components';

type EmsiUIChipProps = {
  id: string;
  label: string;
  deleteHandler?: (idToDelete: string) => void;
  style?: CSSProperties;
};

const StyledChip = styled(Chip)(({ theme }) => ({
  '& .MuiChip-label': {
    fontFamily: theme.customTypography.fontFamily,
    fontSize: theme.customTypography.desktop.body.size,
    fontWeight: theme.customTypography.desktop.body.weight,
    lineHeight: theme.customTypography.desktop.body.lineHeight,
    '@media (max-width:768px)': {
      fontSize: theme.customTypography.mobile.body.size,
    },
  },
  '& .MuiChip-deleteIcon': {
    marginLeft: '0px',
  },
  margin: `0px ${theme.customSpacing.px.base}px`,
  borderRadius: theme.borderRadius.medium,
  padding: '4px 5px 4px 0px',
  backgroundColor: theme.colors.surface.neutralSubdued,
  height: `${theme.customSpacing.px.base * 7}px`,
}));

const EmsiUIChip = ({ id, label, deleteHandler, style }: EmsiUIChipProps): ReactElement => {
  return (
    <StyledChip
      style={style}
      key={id}
      label={label}
      onDelete={deleteHandler}
      deleteIcon={
        <BaseIcon
          type={IconType.Close}
          size="10px"
          marginLeft={SpacingSizes.XL}
          onIconClick={() => deleteHandler?.(id)}
        />
      }
    />
  );
};

export default EmsiUIChip;
