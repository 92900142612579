import React, { ReactElement } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import EditRoleContent, { ViewModes } from 'components/organisms/EditRoleContent';

type SingleRoleMappingModalProps = {
  isOpen: boolean;
  closeHandler(): void;
  refreshRoleHandler(): void;
};

const SingleRoleMappingModal = ({
  isOpen = true,
  closeHandler,
  refreshRoleHandler,
}: SingleRoleMappingModalProps): ReactElement => {
  return (
    <Dialog
      open={isOpen}
      onClose={closeHandler}
      fullWidth
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-cy="single-role-mapping-modal-window"
    >
      <DialogContent sx={{ padding: '0px' }}>
        <EditRoleContent
          isUsedInModal={true}
          initialViewMode={ViewModes.RoleMapping}
          closeModalHandler={closeHandler}
          backClickHandler={closeHandler}
          refreshRoleHandler={refreshRoleHandler}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SingleRoleMappingModal;
