import React from 'react';
import Typography from '@mui/material/Typography';
import { Options, SubOption } from 'types/types';
import styled, { css } from 'styled-components';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';

type ToggleOptionsMenuProps = {
  activeOptionExpanded: SubOption | undefined;
  menuOptions: Options[];
  handleSubOptionClick: (optionKey: string, ...subOptionKeys: string[]) => void;
  handleToggleSetting: (optionKey: string, subOptionKey: string, toggled: boolean) => void;
  dataCy?: string;
};
const MainItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.customSpacing.px.base * 4}px;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.customSpacing.px.none}px ${props => props.theme.customSpacing.px.xxs}px;
  cursor: pointer;
`;

const ItemContainer = styled.div<{ $isActive: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.customSpacing.px.xs}px ${props => props.theme.customSpacing.px.xxs}px;

  ${props =>
    props.$isActive &&
    css`
      background-color: ${props => props.theme.colors.surface.selected};
      border-radius: ${props => props.theme.borderRadius.large};
    `};
`;

const SubItemContainer = styled(ItemContainer)`
  margin-left: 1rem;
`;

const ItemTitleTypography = styled(Typography)<{ $isActive: boolean }>`
  color: ${props =>
    props.$isActive ? `${props.theme.colors.text.interactive}` : `${props.theme.colors.text.default}`};
`;

const ToggleOptionsMenu = ({
  activeOptionExpanded,
  menuOptions,
  handleSubOptionClick,
  handleToggleSetting,
  dataCy = 'menu',
}: ToggleOptionsMenuProps) => {
  return (
    <MainItemsContainer>
      {menuOptions.map((option, id1) => {
        return (
          <React.Fragment key={`${option.key}-${id1}`}>
            <Typography
              key={`${option.key}-${option.title}`}
              variant="strong"
              data-cy={`${dataCy}-setting-${option.key}-header`}
            >
              {option.title + '  '}
              {option.loadingSubOptions && (
                <CircularProgress key={`circular-progress-${option.key}-${id1}`} size={14} />
              )}
            </Typography>
            <ItemsContainer key={`${option.key}-${option.title}-${option.key}`} data-cy={`${dataCy}-container`}>
              {!option.loadingSubOptions &&
                option.subOption.map((subOption, id2) => {
                  return (
                    <React.Fragment key={`${subOption.key}-${id2}`}>
                      <ItemContainer
                        key={`${option.key}-${subOption.key}-${id1}`}
                        $isActive={activeOptionExpanded?.key === subOption.key}
                        onClick={() => handleSubOptionClick(option.key, subOption.key)}
                        data-cy={`${dataCy}-setting-${option.key}-${subOption.key}-container`}
                      >
                        <ItemTitleTypography
                          key={`${dataCy}-setting-${subOption.key}-header`}
                          $isActive={activeOptionExpanded?.key === subOption.key}
                          data-cy={`${dataCy}-setting-${subOption.key}-header`}
                        >
                          {subOption.title}
                        </ItemTitleTypography>
                        <Switch
                          onClick={e => e.stopPropagation()}
                          key={`${option.key}-${subOption.key}-${id1}-${id2}`}
                          checked={!!subOption.enabled}
                          onChange={event => handleToggleSetting(option.key, subOption.key, event.target.checked)}
                          data-cy={`${dataCy}-setting-${subOption.key}-switch`}
                        />
                      </ItemContainer>
                      {subOption.subOptions?.map(subSubOption => (
                        <SubItemContainer
                          key={`${subOption.key}-${subSubOption.key}`}
                          $isActive={activeOptionExpanded?.key === subSubOption.key}
                          onClick={() => handleSubOptionClick(option.key, subOption.key, subSubOption.key)}
                        >
                          {subSubOption.title}
                        </SubItemContainer>
                      ))}
                    </React.Fragment>
                  );
                })}
            </ItemsContainer>
          </React.Fragment>
        );
      })}
    </MainItemsContainer>
  );
};

export default ToggleOptionsMenu;
