import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

import { getSkillGrowthStatus } from 'helpers/SkillProfilePageHelper';
import EmsiUIBadge from 'components/atoms/EmsiUIBadge';
import BaseIcon from 'components/atoms/BaseIcon';

import { SuggestedSkill } from 'types/types';

const SkillGrowthChip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;
type SkillInfoProjectedGrowthProps = {
  skill: SuggestedSkill;
};

const SkillInfoProjectedGrowth = ({ skill }: SkillInfoProjectedGrowthProps) => {
  const { icon, iconColor, iconSize, chipColor } = getSkillGrowthStatus(skill?.growthCategory);
  const growthPercent = (skill?.growthPercent || 0) * 100;
  const sign = growthPercent > 0 ? '+' : '';
  const formattedGrowthPercent = `${sign}${growthPercent.toFixed(2)} %`;

  return (
    <Grid container my={3}>
      <Grid container item xs={12} spacing={1}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
            Projected Skill Growth
          </Typography>
        </Grid>
        <Grid item>
          <EmsiUIBadge
            size="medium"
            color={chipColor}
            label={
              <SkillGrowthChip>
                <div>
                  <BaseIcon type={icon} colorFilter={iconColor} size={`${iconSize}px`} />
                </div>
                <div>{skill?.growthCategory}</div>
              </SkillGrowthChip>
            }
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} mt={2} alignItems="center">
        <Grid item>
          <Typography variant="h2">{formattedGrowthPercent}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Typography variant="body">
          {`${skill?.skillName} projection is ${formattedGrowthPercent} in the next 2 years. Its popularity is ${skill?.growthCategory?.toLocaleLowerCase()} relative
          to the market.`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SkillInfoProjectedGrowth;
