import React, { ReactElement, useState } from 'react';
import { skillProficiency } from 'helpers/SkillProfilePageHelper';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import TableSelect from 'components/molecules/TableSelect';
import { Skill } from 'types/types';

type SkillTableSelectProps = {
  skill: Skill;
  updateHandler: (...args: any) => void;
  deleteHandler: (skillId: string) => void;
};

function SkillTableSelect({ skill, updateHandler, deleteHandler }: SkillTableSelectProps): ReactElement {
  const [isUpdating, setIsUpdating] = useState(false);

  const handleProficiencyUpdate = async value => {
    setIsUpdating(true);
    const required = skillProficiency[0].key === value;
    await updateHandler({ ...skill, required });
    setIsUpdating(false);
  };
  const handleDeleteSkill = () => {
    deleteHandler(skill.skillId);
  };

  return (
    <TableSelect
      field={{ id: skill.skillId, value: skill.required ? skillProficiency[0].key : skillProficiency[1].key }}
      options={skillProficiency.map(item => {
        return { id: item.key, value: item.name };
      })}
      updateHandler={(e: any) => {
        handleProficiencyUpdate(e.target.value);
      }}
      isUpdating={isUpdating}
      iconsComponent={
        <>
          <BaseIcon type={IconType.Delete} onIconClick={handleDeleteSkill} dataCy="icon-delete" />
        </>
      }
    />
  );
}

export default SkillTableSelect;
