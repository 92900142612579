import React, { ReactElement, useState, useEffect, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import RadialLollipop, {
  CenterCircleDataProps,
  RadialLollipopDataProps,
  RadialLollipopItemProps,
} from 'components/charts/RadialLollipop';
import { ColumnsWrapper, Column, Section, SectionSticky } from 'pages/admin/uiLibrary/UILibraryComponents';

type DemoDataItem = {
  id: string;
  name: string;
  value: number;
  count?: number;
};

const FormControlLabelCustom = styled(FormControlLabel)`
  white-space: nowrap;
`;

const GraphData = styled(Typography)`
  font-family: monospace;
  display: inline-block;
  padding: ${props => `${props.theme.customSpacing.px.xxs}px ${props.theme.customSpacing.px.base * 4}px`};
  border-radius: ${props => props.theme.borderRadius.default};
  background-color: ${props => props.theme.colors.surface.informationSubdued};
  max-height: 250px;
  overflow-y: scroll;
`;

const RadialLollipopDemo = (): ReactElement => {
  const [isSkillsStatsLoading, setIsSkillsStatsLoading] = useState(false);
  const theme = useTheme();
  const [labelType, setLabelType] = useState<'tooltip' | 'none'>('tooltip');
  const [valueType, setValueType] = useState<'value' | 'percent'>('value');
  const [colorRange, setColorRange] = useState<'colorRange3' | 'colorRange7' | 'colorRange10'>('colorRange10');
  const [isOnClickHandlerProvided, setIsOnClickHandlerProvided] = useState(true);
  const [chartSize, setChartSize] = useState(240);
  const [customColor, setCustomColor] = useState('none');

  const [dataItemCount, setDataItemCount] = useState(10);
  const [chartRangeSectionsCount, setChartRangeSectionsCount] = useState(3);
  const [isDescendingDistance, setIsDescendingDistance] = useState(true);
  const [isCirclesVisible, setIsCirclesVisible] = useState(true);
  const [isNodeCircleDynamic, setIsNodeCircleDynamic] = useState(false);
  const [isCustomTooltip, setIsCustomTooltip] = useState(false);
  const [workingChartData, setWorkingChartData] = useState<any[]>([]);
  const [updatedFormattedData, setUpdatedFormattedData] = useState<RadialLollipopDataProps | undefined>();
  const [clickedSectionData, setClickedSectionData] = useState<RadialLollipopItemProps | undefined>();

  const selectedOccupation: CenterCircleDataProps = {
    id: '1234',
    name: 'Occupation Name',
    tooltip: 'Occupation Name (id: 1234)',
  };

  const demoDataValue = [
    { id: '0', name: 'item0', value: 82.33529128329813, count: 296 },
    { id: '1', name: 'item1', value: 203.9051685288591, count: 147 },
    { id: '2', name: 'item2', value: 336.11543388969744, count: 85 },
    { id: '3', name: 'item3', value: 29.87318875146112, count: 62 },
    { id: '4', name: 'item4', value: 281.6562175617842, count: 14 },
    { id: '5', name: 'item5', value: 281.6900599841108, count: 237 },
    { id: '6', name: 'item6', value: 191.22124155013822, count: 174 },
    { id: '7', name: 'item7', value: 150.6670279086283415, count: 186 },
    { id: '8', name: 'item8', value: 176.20396189741382, count: 340 },
    { id: '9', name: 'item9', value: 157.29847273465901, count: 37 },
    { id: '10', name: 'item10', value: 177.79365438420245, count: 241 },
    { id: '11', name: 'item35', value: 445.64194268981316, count: 323 },
    { id: '12', name: 'item12', value: 449.7684375326078, count: 312 },
    { id: '13', name: 'item13', value: 223.40927458828793, count: 130 },
    { id: '14', name: 'item14', value: 108.28414701502223, count: 334 },
    { id: '15', name: 'item15', value: 112.95658771248583, count: 288 },
    { id: '16', name: 'item16', value: 422.0009361804134, count: 240 },
    { id: '17', name: 'item17', value: 166.9284162276831, count: 171 },
    { id: '18', name: 'item18', value: 464.52560298008933, count: 174 },
    { id: '19', name: 'item19', value: 374.61830588852126, count: 254 },
    { id: '20', name: 'item20', value: 377.6931938023711, count: 223 },
    { id: '21', name: 'item21', value: 325.3676397666334, count: 146 },
    { id: '22', name: 'item22', value: 176.98541042547023, count: 326 },
    { id: '23', name: 'item23', value: 217.2935771944588, count: 206 },
    { id: '24', name: 'item24', value: 22.390603079765725, count: 258 },
  ];

  const demoDataPercent = [
    { id: '0', name: 'item0', value: 0.942, count: 176 },
    { id: '1', name: 'item1', value: 0.163, count: 264 },
    { id: '2', name: 'item2', value: 0.798, count: 171 },
    { id: '3', name: 'item3', value: 0.4174, count: 246 },
    { id: '4', name: 'item4', value: 0.811, count: 145 },
    { id: '5', name: 'item5', value: 0.41, count: 288 },
    { id: '6', name: 'item6', value: 0.1152, count: 308 },
    { id: '7', name: 'item7', value: 0.5038, count: 51 },
    { id: '8', name: 'item8', value: 0.353, count: 140 },
    { id: '9', name: 'item9', value: 0.7006, count: 7 },
    { id: '10', name: 'item10', value: 0.7781, count: 61 },
    { id: '11', name: 'item35', value: 0.13, count: 343 },
    { id: '12', name: 'item12', value: 0.36, count: 343 },
    { id: '13', name: 'item13', value: 0.5, count: 270 },
    { id: '14', name: 'item14', value: 0.64, count: 66 },
    { id: '15', name: 'item15', value: 0.82, count: 201 },
    { id: '16', name: 'item16', value: 0.64, count: 1 },
    { id: '17', name: 'item17', value: 0.88, count: 254 },
    { id: '18', name: 'item18', value: 0.23, count: 86 },
    { id: '19', name: 'item19', value: 0.75, count: 46 },
    { id: '20', name: 'item20', value: 0.33, count: 295 },
    { id: '21', name: 'item21', value: 0.67, count: 311 },
    { id: '22', name: 'item22', value: 0.85, count: 130 },
    { id: '23', name: 'item23', value: 0.59, count: 268 },
    { id: '24', name: 'item24', value: 0.31, count: 196 },
  ];

  const workingData = useMemo(() => {
    const selectedData = () => {
      switch (valueType) {
        case 'percent':
          return demoDataPercent;
        case 'value':
          return demoDataValue;
      }
    };

    let data: Array<DemoDataItem> = [];
    for (let i = 0; i < dataItemCount; i++) {
      data = data.concat(selectedData()[i]);
    }

    return data;
  }, [valueType, dataItemCount]) as DemoDataItem[];

  const formatData = async () => {
    const formattedItems = await workingData.map((item, i) => {
      return {
        id: item.id,
        label: item.name,
        value: item.value,
        count: item.count || undefined,
        isActive: clickedSectionData ? clickedSectionData.id === item.id : true,
        color: customColor === 'none' || i !== 0 ? undefined : customColor,
        tooltipText: isCustomTooltip
          ? `(CUSTOM) name: ${item.name}, value: ${item.value}, count: ${item.count}`
          : undefined,
      };
    });

    setUpdatedFormattedData({
      centerItem: selectedOccupation,
      dataItems: formattedItems,
    });
  };

  const loadChartData = async () => {
    setIsSkillsStatsLoading(true);
    setWorkingChartData(workingData);
    await formatData();
    setIsSkillsStatsLoading(false);
  };

  useEffect(() => {
    loadChartData();
  }, [
    workingData,
    chartSize,
    chartRangeSectionsCount,
    isDescendingDistance,
    isCirclesVisible,
    isNodeCircleDynamic,
    isCustomTooltip,
    labelType,
    customColor,
    isOnClickHandlerProvided,
    clickedSectionData,
  ]);

  const sectionClickHandler = (data: RadialLollipopItemProps) => {
    if (clickedSectionData?.id === data.id) {
      setClickedSectionData(undefined);
    } else {
      setClickedSectionData(data);
    }
    formatData();
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <SectionSticky
          defaultComponent={
            <>
              <LoadingButton
                onClick={() => {
                  loadChartData();
                  setClickedSectionData(undefined);
                }}
                loading={isSkillsStatsLoading}
                loadingIndicator="Loading..."
              >
                Refresh Data & Chart
              </LoadingButton>
            </>
          }
          componentOptions={
            <>
              <FormControlLabelCustom
                control={
                  <Slider
                    value={chartSize}
                    onChange={(e: any) => setChartSize(e.target.value)}
                    getAriaValueText={(value: number) => `${value}px`}
                    valueLabelDisplay="auto"
                    step={50}
                    marks
                    min={200}
                    max={400}
                  />
                }
                labelPlacement="top"
                label="ChartSize"
              />
              <FormControlLabelCustom
                control={
                  <Slider
                    value={dataItemCount}
                    onChange={(e: any) => setDataItemCount(e.target.value)}
                    getAriaValueText={(value: number) => `${value}px`}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={1}
                    max={demoDataValue.length}
                  />
                }
                labelPlacement="top"
                label="DataItemCount"
              />
              <FormControlLabelCustom
                control={
                  <Slider
                    value={chartRangeSectionsCount}
                    onChange={(e: any) => setChartRangeSectionsCount(e.target.value)}
                    getAriaValueText={(value: number) => `${value}px`}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={1}
                    max={10}
                  />
                }
                labelPlacement="top"
                label="ChartRangeSectionsCount"
              />
              <FormControlLabelCustom
                control={
                  <Switch
                    color="iconSuccess"
                    checked={isCirclesVisible}
                    onChange={() => setIsCirclesVisible(!isCirclesVisible)}
                  />
                }
                labelPlacement="top"
                label="isCirclesVisible"
              />
              <FormControlLabelCustom
                control={
                  <Switch
                    color="iconSuccess"
                    checked={isDescendingDistance}
                    onChange={() => setIsDescendingDistance(!isDescendingDistance)}
                  />
                }
                labelPlacement="top"
                label="isDescendingDistance (Sort Order from Center Circle)"
              />
              <FormControlLabelCustom
                control={
                  <Switch
                    color="iconSuccess"
                    checked={isNodeCircleDynamic}
                    onChange={() => setIsNodeCircleDynamic(!isNodeCircleDynamic)}
                  />
                }
                labelPlacement="top"
                label="isNodeCircleDynamic"
              />
              <FormControlLabelCustom
                control={
                  <Switch
                    color="iconSuccess"
                    checked={isCustomTooltip}
                    onChange={() => setIsCustomTooltip(!isCustomTooltip)}
                  />
                }
                labelPlacement="top"
                label="isCustomTooltip"
              />
              <br />
              <FormControlLabelCustom
                control={
                  <ToggleButtonGroup
                    size="small"
                    value={valueType}
                    exclusive
                    onChange={(e: any) => setValueType(e.target.value)}
                    aria-label="value type"
                    color="success"
                  >
                    <ToggleButton value="value">Value</ToggleButton>
                    <ToggleButton value="percent">Percent</ToggleButton>
                  </ToggleButtonGroup>
                }
                labelPlacement="top"
                label="Value Type (then click Refresh)"
              />
              <br />
              <FormControlLabelCustom
                control={
                  <ToggleButtonGroup
                    size="small"
                    value={labelType}
                    exclusive
                    onChange={(e: any) => setLabelType(e.target.value)}
                    aria-label="label type"
                    color="success"
                  >
                    <ToggleButton value="tooltip">Tooltip (default)</ToggleButton>
                    <ToggleButton value="none">None</ToggleButton>
                  </ToggleButtonGroup>
                }
                labelPlacement="top"
                label="Label Type"
              />
              <br />
              <FormControlLabelCustom
                control={
                  <ToggleButtonGroup
                    size="small"
                    value={colorRange}
                    exclusive
                    onChange={(e: any) => setColorRange(e.target.value)}
                    aria-label="color range"
                    color="success"
                  >
                    <ToggleButton value={'colorRange3'}>3 Colors</ToggleButton>
                    <ToggleButton value={'colorRange7'}>7 Colors</ToggleButton>
                    <ToggleButton value={'colorRange10'}>10 Colors (default)</ToggleButton>
                  </ToggleButtonGroup>
                }
                labelPlacement="top"
                label="Color Range"
              />
              <br />
              <FormControlLabelCustom
                control={
                  <ToggleButtonGroup
                    size="small"
                    value={customColor}
                    exclusive
                    onChange={(e: any) => setCustomColor(e.target.value)}
                    aria-label="customColor"
                    color="success"
                  >
                    <ToggleButton value={'none'}>Reset</ToggleButton>
                    <ToggleButton value={theme.colors.icon.success.hex}>Success</ToggleButton>
                    <ToggleButton value={theme.colors.icon.warning.hex}>Warning</ToggleButton>
                    <ToggleButton value={theme.colors.icon.critical.hex}>Critical</ToggleButton>
                  </ToggleButtonGroup>
                }
                labelPlacement="top"
                label="Color Override by Item"
              />
              <br />
              <FormControlLabelCustom
                control={
                  <Switch
                    color="iconSuccess"
                    checked={isOnClickHandlerProvided}
                    onChange={() => setIsOnClickHandlerProvided(!isOnClickHandlerProvided)}
                  />
                }
                labelPlacement="top"
                label="onClickHandler"
              />
              <Divider />
            </>
          }
        />
        <Section style={{ width: '50%' }}>
          <Typography variant="strong">Chart Example</Typography>
          <ColumnsWrapper>
            <Column>
              {isSkillsStatsLoading && (
                <Typography sx={{ display: 'flex', justifyContent: 'center' }}>loading...</Typography>
              )}
              {!isSkillsStatsLoading && updatedFormattedData && (
                <div style={{ display: 'inline-flex' }}>
                  <RadialLollipop
                    data={updatedFormattedData}
                    valueType={valueType}
                    chartName="related-roles"
                    chartSize={chartSize}
                    chartRangeSectionsCount={chartRangeSectionsCount}
                    isDescendingDistance={isDescendingDistance}
                    isCirclesVisible={isCirclesVisible}
                    isNodeCircleDynamic={isNodeCircleDynamic}
                    labelType={labelType}
                    colorRange={theme.colors.chart[`${colorRange}`]}
                    sectionClickHandler={isOnClickHandlerProvided ? sectionClickHandler : undefined}
                  />
                </div>
              )}
              {isOnClickHandlerProvided && clickedSectionData && (
                <>
                  <br />
                  <Typography variant="strong">{`Chart section clicked: ${clickedSectionData.label}`}</Typography>
                  <Typography variant="caption">
                    <pre style={{ maxHeight: '250px', overflowY: 'scroll' }}>
                      {JSON.stringify(clickedSectionData, null, 2)}
                    </pre>
                  </Typography>
                </>
              )}
            </Column>
          </ColumnsWrapper>
        </Section>
        <Section>
          <Typography variant="strong">Mock Data</Typography>
          <ColumnsWrapper>
            <Column>
              {isSkillsStatsLoading && (
                <Typography sx={{ display: 'flex', justifyContent: 'center' }}>loading...</Typography>
              )}
              {!isSkillsStatsLoading && (
                <>
                  <Typography variant="body">Raw Data</Typography>
                  <GraphData variant="caption">
                    <pre>{JSON.stringify(workingChartData, null, 2)}</pre>
                  </GraphData>
                  <br />
                  <Typography variant="body">Formatted Data (RadialLollipopItemProps)</Typography>
                  <GraphData variant="caption">
                    <pre style={{ maxHeight: '250px', overflowY: 'scroll' }}>
                      {JSON.stringify(updatedFormattedData, null, 2)}
                    </pre>
                  </GraphData>
                </>
              )}
            </Column>
          </ColumnsWrapper>
        </Section>
      </div>
    </>
  );
};

export default RadialLollipopDemo;
