import React, { ReactElement, ReactNode } from 'react';
import { BorderColor, IconColorFilter } from 'types/types';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import ButtonGroup, { EMSIBGButton } from 'components/atoms/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { Breakpoint } from '@mui/system';

type DialogModalProps = {
  dialogOpen: boolean;
  title?: string;
  showDialogTitle?: boolean;
  titleSizeVariant?: 'heading' | 'displayLarge';
  content?: ReactNode;
  fullWidth?: boolean;
  maxWidth?: Breakpoint;
  closeModal?: () => void;
  disableEscapeKeyDown?: boolean;
  buttonGroup?: EMSIBGButton[];
  btnGroupPosition?: 'top' | 'down' | 'top-down';
  showCloseIcon?: boolean;
  dataCy?: string;
};

const DialogModal = ({
  dialogOpen,
  title,
  titleSizeVariant = 'heading',
  showDialogTitle = true,
  content,
  fullWidth = false,
  maxWidth = 'sm',
  closeModal = undefined,
  disableEscapeKeyDown = false,
  buttonGroup,
  btnGroupPosition = 'down',
  showCloseIcon = false,
  dataCy = 'alert-dialog-container',
}: DialogModalProps): ReactElement => {
  return (
    <Dialog
      open={dialogOpen}
      onClose={closeModal}
      disableEscapeKeyDown={disableEscapeKeyDown}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-cy={dataCy}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      hideBackdrop={true}
    >
      {showDialogTitle && (
        <DialogTitle id="alert-dialog-title">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            data-cy="alert-dialog-title"
            paddingY={1}
          >
            {title && (
              <Typography mr={10} variant={titleSizeVariant}>
                {title}
              </Typography>
            )}
            {buttonGroup && (btnGroupPosition === 'top' || btnGroupPosition === 'top-down') && (
              <DialogActions>
                <ButtonGroup buttons={buttonGroup} justifyContent="flex-end" />
              </DialogActions>
            )}
            {closeModal && showCloseIcon && (
              <BaseIcon
                type={IconType.Close}
                size="10px"
                colorFilter={IconColorFilter.Information}
                borderColor={BorderColor.Default}
                onIconClick={closeModal}
                alt="close popup"
              />
            )}
          </Grid>
        </DialogTitle>
      )}
      {content && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description" data-cy="alert-dialog-description" component={'div'}>
            {content}
          </DialogContentText>
        </DialogContent>
      )}
      {buttonGroup && (btnGroupPosition === 'down' || btnGroupPosition === 'top-down') && (
        <DialogActions>
          <ButtonGroup buttons={buttonGroup} justifyContent="flex-end" />
        </DialogActions>
      )}
    </Dialog>
  );
};
export default DialogModal;
