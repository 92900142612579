const userRoles = [
  {
    key: 'employee',
    name: 'Employee',
    restricted: false,
  },
  {
    key: 'analysts',
    name: 'Analyst',
    restricted: false,
  },
  {
    key: 'analysts_read_only',
    name: 'Viewer',
    restricted: false,
  },
  {
    key: 'company_administrators',
    name: 'Company Admin',
    restricted: false,
  },
  {
    key: 'emsi_customer_support',
    name: 'Lightcast Customer Support',
    restricted: true,
  },
  {
    key: 'emsi_super_users',
    name: 'Lightcast Admin',
    restricted: true,
  },
];

export default userRoles;
