import React, { FC, useEffect, useMemo, useState, useCallback } from 'react';
import dayjs from 'dayjs';
import { useTheme } from 'styled-components';

import Typography from '@mui/material/Typography';
import { DrawerProps } from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import SidePanelTemplate from 'pages/templates/SidePanelTemplate';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { JPATotalsResponse, JPAMetric, getTotals } from 'services/jobPostingsService';
import SkillInfoProjectedGrowth from 'components/molecules/SkillInfoProjectedGrowth';
import SkillInfoRelatedSkills from 'components/molecules/SkillInfoRelatedSkills';

import Role from 'classes/role';
import { SpacingSizes, SuggestedSkill } from 'types/types';

const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
const moneyFormatWithSign = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  signDisplay: 'always',
});

const SELECTABLE_RANGES = {
  '3M': dayjs().subtract(3, 'month').format('YYYY-MM-DD'),
  '6M': dayjs().subtract(6, 'month').format('YYYY-MM-DD'),
  '1Y': dayjs().subtract(1, 'year').format('YYYY-MM-01'),
  '2Y': dayjs().subtract(2, 'year').format('YYYY-MM-01'),
} as const;

const TitleComponent = () => {
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <BaseIcon size="32px" marginRight={SpacingSizes.XS} type={IconType.SearchGiantInverted} />
        <Typography variant="h5">Skill Detail</Typography>
      </div>
    </div>
  );
};

interface SalaryCardProps {
  title: string;
  salaryInfo?: JPATotalsResponse;
  skill?: SuggestedSkill;
}

const SalaryCard: FC<SalaryCardProps> = ({ title, salaryInfo, skill }) => {
  const theme = useTheme();
  return (
    <div style={{ flexGrow: 1, background: theme.colors.surface.selected, borderRadius: '8px', padding: '16px' }}>
      <Typography sx={{ fontWeight: '700' }} variant="h4">
        {title}
      </Typography>
      <Typography sx={{ lineHeight: '16px', fontSize: '12px' }}>
        {skill ? (
          <>
            With <b>{skill.skillName}</b>
          </>
        ) : (
          'No Skills Specified'
        )}
      </Typography>

      <Typography sx={{ fontWeight: '700', fontSize: '28px', margin: '14px 0' }}>
        {moneyFormat.format(salaryInfo?.median_salary || 0)}
      </Typography>
      <Typography>
        Based on data from <b>{salaryInfo?.unique_postings || 0} unique postings</b>
      </Typography>
    </div>
  );
};

interface Props {
  role?: Role;
  skill?: SuggestedSkill;
  baseSalary?: JPATotalsResponse;
  withSkillSalary?: JPATotalsResponse;
  postingRange: keyof typeof SELECTABLE_RANGES;
  handlePostingRangeUpdate: (val: string) => void;
  closeDrawer: () => void;
  drawerIsOpen: boolean;
}

export const RoleSkillInfoDrawer: FC<Props> = ({
  role,
  skill,
  baseSalary,
  postingRange,
  handlePostingRangeUpdate,
  withSkillSalary,
  drawerIsOpen,
  closeDrawer,
}) => {
  const drawerProps: DrawerProps = {
    variant: 'temporary',
    anchor: 'right',
    open: drawerIsOpen,
    onClose: closeDrawer,
  };

  const salaryNotApplicable = (withSkillSalary?.unique_postings || 0) < 1;

  const title = role?.emsiTitleName || '';
  const medianSalary = moneyFormat.format(baseSalary?.median_salary || 0);
  const medianSalaryWithSkill = moneyFormat.format(withSkillSalary?.median_salary || 0);
  const salaryDifference = (withSkillSalary?.median_salary || 0) - (baseSalary?.median_salary || 0);

  return (
    <SidePanelTemplate
      headerTitle={<TitleComponent />}
      headerActions={
        <Button onClick={closeDrawer} variant="outlined">
          Close
        </Button>
      }
      displayHeaderDivider={true}
      width={792}
      drawerProps={drawerProps}
    >
      <div style={{ padding: '8px 24px' }}>
        <Typography padding="24px 0" variant="h2">
          {skill?.skillName}
        </Typography>
        <Divider />
        {skill && <SkillInfoProjectedGrowth skill={skill} />}
        <Divider />
        <div
          style={{
            margin: '24px 0px',
            transition: 'opacity 0.1s',
            opacity: baseSalary && withSkillSalary ? 1 : 0.5,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" style={{ flexGrow: 1 }}>
              National Median Salary
            </Typography>
            <ToggleButtonGroup
              value={postingRange}
              exclusive={true}
              onChange={(_, value: string) => handlePostingRangeUpdate(value)}
              size="small"
            >
              {Object.keys(SELECTABLE_RANGES).map(range => {
                return (
                  <ToggleButton sx={{ padding: '4px 8px' }} key={range} value={range}>
                    {range}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </div>
          {typeof salaryDifference !== 'undefined' && (
            <Typography sx={{ fontWeight: '700', fontSize: '28px', margin: '14px 0' }}>
              {salaryNotApplicable ? 'N/A' : moneyFormatWithSign.format(salaryDifference)}
            </Typography>
          )}
          <div>
            <Typography sx={{ minHeight: '32px', fontSize: '13px', lineHeight: '16px', margin: '15px 0 25px' }}>
              {salaryNotApplicable
                ? `No postings were found for ${title} including ${skill?.skillName} as a skill.`
                : `The national median salary for ${title} is ${medianSalary}. For ${title}
                    postings including ${skill?.skillName} as a skill, the national median salary
                    is ${medianSalaryWithSkill}, giving this skill a premium of
                    ${moneyFormat.format(salaryDifference)}.`}
            </Typography>
            <div style={{ display: 'flex', columnGap: '10px' }}>
              <SalaryCard title={title} salaryInfo={baseSalary} />
              <SalaryCard title={title} salaryInfo={withSkillSalary} skill={skill} />
            </div>
          </div>
        </div>
        <Divider />
        {skill && <SkillInfoRelatedSkills emsiTitleId={role?.emsiTitleId} skill={skill} />}
        <Divider />
      </div>
    </SidePanelTemplate>
  );
};

const useRoleSkillInfoDrawer = (role: Role | undefined) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
  const [skill, setSkill] = useState<SuggestedSkill | undefined>();
  const [baseSalary, setBaseSalary] = useState<JPATotalsResponse | undefined>();
  const [withSkillSalary, setWithSkillSalary] = useState<JPATotalsResponse | undefined>();
  const [postingRange, setPostingRange] = useState<keyof typeof SELECTABLE_RANGES>('1Y');

  const jpaMetrics: JPAMetric[] = ['median_salary', 'unique_postings'];
  const jpaFilter = useMemo(
    () =>
      role && {
        title: [role.emsiTitleId],
        when: {
          start: SELECTABLE_RANGES[postingRange],
          end: dayjs().format('YYYY-MM-DD'),
        },
      },
    [role?.emsiTitleId, postingRange]
  );

  useEffect(() => {
    (async () => {
      if (!jpaFilter) return;
      const baseSalaryResponse = await getTotals(jpaFilter, jpaMetrics);
      if (!baseSalaryResponse) {
        console.error(new Error('Error retrieving base role salary'));
        return;
      }
      setBaseSalary(baseSalaryResponse);
    })();
  }, [jpaFilter]);

  useEffect(() => {
    (async () => {
      if (!skill || !jpaFilter) return;
      const withSkillSalaryResponse = await getTotals({ ...jpaFilter, skills: [skill.skillId] }, jpaMetrics);
      if (!withSkillSalaryResponse) {
        console.error(new Error('Error retrieving salary with skill'));
        return;
      }
      setWithSkillSalary(withSkillSalaryResponse);
    })();
  }, [role?.emsiTitleId, skill?.skillId, jpaFilter]);

  const closeDrawer = useCallback(() => setDrawerIsOpen(false), [setDrawerIsOpen]);

  const openDrawer = useCallback(
    (newSkill: SuggestedSkill) => {
      if (!skill || skill.skillId !== newSkill.skillId) {
        setWithSkillSalary(undefined);
        setSkill(newSkill);
      }
      setDrawerIsOpen(true);
    },
    [skill?.skillId]
  );

  const handlePostingRangeUpdate = newRange => {
    setBaseSalary(undefined);
    setWithSkillSalary(undefined);
    setPostingRange(newRange);
  };

  const drawerProps: Props = {
    role,
    skill,
    baseSalary,
    withSkillSalary,
    postingRange,
    handlePostingRangeUpdate,
    closeDrawer,
    drawerIsOpen,
  };

  return [drawerProps, openDrawer, RoleSkillInfoDrawer] as const;
};

export default useRoleSkillInfoDrawer;
