import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import styled, { useTheme } from 'styled-components';
import dayjs from 'dayjs';
import BenchmarksService from 'services/BenchmarksService';
import useCompanyContext from 'helpers/UseCompanyContext';
import Button from '@mui/material/Button';
import BenchmarkEditModal from 'components/organisms/modals/BenchmarkEditModal';
import Benchmark from 'classes/benchmark';
import Favorite from 'components/atoms/icons/Favorite';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import DialogModal from 'components/molecules/DialogModal';
import { EMSIBGButton } from 'components/atoms/ButtonGroup';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: ${props => props.theme.customSpacing.px.xs}px;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s linear 0ms,
    opacity 0ms;

  .MuiDataGrid-row:hover & {
    visibility: visible;
    opacity: 1;
    transition:
      visibility 0s linear 0s,
      opacity 0ms;
  }
`;

const LastUpdatedColumnContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.customSpacing.px.xxs}px;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) or (max-width: 1024px) {
    flex-direction: column;
    gap: ${props => props.theme.customSpacing.px.base}px;

    ${IconContainer} {
      gap: ${props => props.theme.customSpacing.px.xxs}px;
    }
  }
`;

function BenchmarksManagementPage(): ReactElement {
  const theme = useTheme();
  const { companyId, benchmarks, benchmarksLoading, loadBenchmarks } = useCompanyContext();
  const [refreshValue, setRefreshValue] = useState(0);
  const [editingBenchmark, setEditingBenchmark] = useState({});
  const [deleteBenchmark, setDeleteBenchmark] = useState<any>({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isBenchmarkUpdateInProgress, setIsBenchmarkUpdateInProgress] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const DATE_MM_DD_YY = 'M/D/YY';

  const logAnalytics = (action: string, event: string): void => {
    ReactGA.event({
      category: 'benchmarks',
      action,
      label: event,
    });
  };

  useEffect(() => {
    (async () => {
      await loadBenchmarks();
      setIsBenchmarkUpdateInProgress(false);
    })();
  }, [refreshValue]);

  const sortedBenchmarks = useMemo(() => {
    return [...benchmarks].sort((a, b) => (b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1));
  }, [benchmarks]);

  const showCreateModal = () => {
    ReactGA.modalview('Benchmark Create Modal');
    logAnalytics('benchmark create', 'show modal');
    setEditingBenchmark(new Benchmark({ companyId, name: '' }));
    setModalIsOpen(true);
  };

  const closeModal = (refresh = false) => {
    setEditingBenchmark({});
    setModalIsOpen(false);
    if (refresh) {
      setIsBenchmarkUpdateInProgress(true);
      setRefreshValue(Math.random());
    }
  };

  const handleDeleteClick = (benchmarkId: string) => {
    setDeleteBenchmark(benchmarks.find(benchmark => benchmark.benchmarkId === benchmarkId));
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setDeleteBenchmark({});
  };

  const handleDeleteDialogConfirm = async () => {
    setDeleteDialogOpen(false);
    setIsBenchmarkUpdateInProgress(true);
    try {
      await BenchmarksService.deleteBenchmark({ companyId, benchmarkId: deleteBenchmark.benchmarkId });
      setRefreshValue(Math.random());
      logAnalytics('benchmark delete', 'success');
    } catch (e) {
      setIsBenchmarkUpdateInProgress(false);
      logAnalytics('benchmark delete', 'failed');
      console.error(e);
      window.alert('Unable to delete benchmark. Try again later.');
    } finally {
      setDeleteBenchmark({});
    }
  };

  const handleEditClick = (benchmark: Benchmark) => {
    ReactGA.modalview('Benchamrk Edit Modal');
    logAnalytics('benchmark edit', 'show modal');
    setEditingBenchmark(benchmark);
    setModalIsOpen(true);
  };

  const makeDefault = async (benchmarkId: string) => {
    setIsBenchmarkUpdateInProgress(true);
    // make this default
    await BenchmarksService.updateBenchmark({
      companyId,
      benchmarkId,
      updateData: { companyDefault: true },
    });
    // make others not default
    for (let i = 0; i < benchmarks.length; i++) {
      if (benchmarks[i].companyDefault) {
        await BenchmarksService.updateBenchmark({
          companyId,
          benchmarkId: benchmarks[i].benchmarkId,
          updateData: { companyDefault: false },
        });
      }
    }
    // force reload
    setRefreshValue(Math.random());
  };

  const columns: GridColDef<any>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 5,
      renderCell: param => {
        return (
          <div style={{ display: 'flex', gap: `${theme.customSpacing.px.xxs}px` }}>
            <div onClick={() => makeDefault(param.row.benchmarkId)} style={{ cursor: 'pointer' }}>
              <Favorite
                color={theme.colors.icon.default.hex}
                size={20}
                filledIn={param.row.companyDefault}
                fillColor={theme.colors.decorative.focus}
              />
            </div>
            <Button variant="text" onClick={() => handleEditClick(param.row)}>
              {param.value}
            </Button>
          </div>
        );
      },
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 5,
    },
    {
      field: 'modified',
      type: 'date',
      headerName: 'Last Updated',
      flex: 2,
      renderCell: param => {
        return (
          <LastUpdatedColumnContainer>
            <Typography color="text.subdued" component="div" title={`Updated by: ${param.row.lastModifiedBy}`}>
              {dayjs(param.row.modified).format(DATE_MM_DD_YY)}
            </Typography>
            <IconContainer>
              <BaseIcon type={IconType.Delete} onIconClick={() => handleDeleteClick(param.row.benchmarkId)} />
            </IconContainer>
          </LastUpdatedColumnContainer>
        );
      },
    },
  ];

  const deleteDialogButtons: EMSIBGButton[] = [
    {
      text: 'Cancel',
      onClickEvent: handleDeleteDialogClose,
      dataCy: 'button-dialog-cancel',
    },
    {
      text: 'Delete Benchmark',
      variant: 'contained',
      color: 'actionCritical',
      onClickEvent: handleDeleteDialogConfirm,
      dataCy: 'button-dialog-delete',
    },
  ];

  return (
    <MainContentTemplate pageTitle="Benchmarks" isFullWidth={false}>
      <ContentHeaderTemplate
        contentTitle="Benchmarks"
        actionsComponent={
          <Button onClick={showCreateModal} variant="contained" color="actionPrimary">
            Add Benchmark
          </Button>
        }
        displayDivider={true}
        fullWidthDivider={false}
      />
      <DataGrid
        columns={columns}
        rows={benchmarksLoading && !isBenchmarkUpdateInProgress ? [] : sortedBenchmarks}
        components={{
          LoadingOverlay: isBenchmarkUpdateInProgress ? LinearProgress : undefined,
        }}
        getRowId={(b: any) => b.benchmarkId}
        loading={benchmarksLoading || isBenchmarkUpdateInProgress}
        hideFooterPagination={true}
        hideFooter={true}
        autoHeight
        disableSelectionOnClick
        className="benchmarks-table"
      />
      <BenchmarkEditModal isOpen={modalIsOpen} benchmark={editingBenchmark} closeHandler={closeModal} />
      <DialogModal
        dialogOpen={deleteDialogOpen}
        closeModal={handleDeleteDialogClose}
        title="Delete Benchmark"
        content={
          <>
            Are you sure you wish to delete benchmark <b>{deleteBenchmark?.name}</b> ?
            <br />
            <br />
            WARNING: This operation cannot be undone.
          </>
        }
        buttonGroup={deleteDialogButtons}
        dataCy="delete-benchmark-dialog"
      />
    </MainContentTemplate>
  );
}

export default BenchmarksManagementPage;
