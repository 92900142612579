import { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import SkillsService from 'services/SkillsService';

const useSurfaceForms = (initialRawText = '', shouldParse = true): any => {
  const [rawText, setRawText] = useState(initialRawText);
  const [debouncedText] = useDebounce(rawText, 300);
  const [surfaceForms, setSurfaceForms] = useState([]);

  // fetch new skills
  useEffect(() => {
    const updateSkills = async () => {
      if (!debouncedText) {
        setSurfaceForms([]);
        return;
      }
      const text = shouldParse ? debouncedText : '';
      let surfaceForms = await SkillsService.extractSkillsFromRawText(text);
      // surfaceForms = surfaceForms.data.data.trace;
      surfaceForms.forEach(surfaceForm => {
        surfaceForm.classificationData.skills = surfaceForm.classificationData.skills.filter(
          skill => skill.confidence >= 0.5
        );
      });

      surfaceForms = surfaceForms.filter(form => !!form.classificationData.skills.length);

      setSurfaceForms(surfaceForms);
    };
    updateSkills();
  }, [debouncedText, shouldParse]);

  return [surfaceForms, rawText, debouncedText, setRawText] as const;
};

export default useSurfaceForms;
