import styled from 'styled-components';

const ContentCredentialDetails = styled.div`
  background-color: ${props => props.theme.colors.surface.default};
  padding: ${props => props.theme.customSpacing.px.base * 18}px ${props => props.theme.customSpacing.px.base * 16}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  max-width: ${props => props.theme.customSpacing.px.base * 120}px;
  min-width: ${props => props.theme.customSpacing.px.base * 87}px;
  margin: auto;

  @media (max-width: 768px) {
    padding: ${props => props.theme.customSpacing.px.base * 12}px ${props => props.theme.customSpacing.px.base * 9}px
      ${props => props.theme.customSpacing.px.base * 10}px;
  }
`;

export default ContentCredentialDetails;
