/* eslint-disable camelcase */

export type CompanyConstructorProps = {
  customerId?: string;
  name?: string;
  safeName?: string;
  companyPostingsId?: string;
  companyProfilesId?: string;
  status?: string;
  product_modules?: string[];
  company_modifiers?: string[];
  identityProviderName?: string;
};

class Company {
  customerId?: string;
  name: string;
  safeName?: string;
  companyPostingsId?: string;
  companyProfilesId?: string;
  status?: string;
  product_modules?: string[];
  company_modifiers?: string[];
  identityProviderName?: string;

  constructor({
    customerId,
    name,
    safeName,
    companyPostingsId,
    companyProfilesId,
    status,
    product_modules,
    company_modifiers,
    identityProviderName,
  }: CompanyConstructorProps) {
    this.customerId = customerId;
    this.name = name || '';
    this.safeName = safeName;
    this.companyPostingsId = companyPostingsId;
    this.companyProfilesId = companyProfilesId;
    this.status = status;
    this.product_modules = product_modules;
    this.company_modifiers = company_modifiers;
    this.identityProviderName = identityProviderName;
  }

  get companyId(): string {
    return this.customerId || '';
  }
}

export default Company;
