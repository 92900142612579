import React, { useEffect, ReactElement, useState } from 'react';
import SimilarRoleSkills from 'components/organisms/SimilarRoleSkills';
import SkillProfileService from 'services/SkillProfileService';
import UseCompanyContext from 'helpers/UseCompanyContext';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import SkillProfile from 'classes/skillProfile';
import { Button } from '@mui/material';
import styled from 'styled-components';
import Role from 'classes/role';

type RelatedRolesListProps = {
  role: Role;
  filter?: { type: string; id: string };
};

class SkillComparison {
  count!: number;
  percent!: number;
}

class RelatedRole {
  role!: SkillProfile;
  skillComparison!: SkillComparison;
}

const SkillCountGraph = styled.div<{ $width: number }>`
  background-color: rgb(47, 86, 192);
  border-radius: 5px;
  display: inline-block;
  height: 10px;
  margin-left: 10px;
  min-width: ${props => (props.$width ? '10px' : '0px')};
  width: ${props => props.$width * 1.5}px;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const RelatedRolesList = ({ role, filter }: RelatedRolesListProps): ReactElement => {
  const { companyId } = UseCompanyContext();
  const [relatedRoles, setRelatedRoles] = useState<RelatedRole[]>([]);
  const [loadingRoles, setLoadingRoles] = useState<boolean>(false);
  const [compareRole, setCompareRole] = useState<SkillProfile | null>(null);
  const [showSimilarRoleSKills, setShowSimilarRoleSKills] = useState<boolean>(false);

  const getSkillOverlapData = (comparisonRole): SkillComparison => {
    let count = 0;
    let percent = -1;
    const roleSkills = role?.skills?.length || 0;

    if (comparisonRole?.skills) {
      const comparisonSkillIds = comparisonRole.skills.map(s => s.skillId);
      count = role.skills.reduce((currentCount, skill) => {
        if (comparisonSkillIds.includes(skill.skillId)) {
          return currentCount + 1;
        }
        return currentCount;
      }, 0);
      if (roleSkills > 0) {
        percent = count / roleSkills;
      }
    }
    return { count, percent };
  };

  useEffect(() => {
    const loadRoles = async () => {
      setLoadingRoles(true);
      const rolesResp = await SkillProfileService.getSkillProfiles({
        companyId,
        limit: 200,
        offset: 0,
        filters: filter ? { [filter.type]: filter.id } : {},
      });
      const onlyOtherRoles = rolesResp.skillProfiles.filter(aRole => aRole.roleId !== role.roleId);
      const processedRoles = onlyOtherRoles.map((role: SkillProfile) => {
        const returnRole = new RelatedRole();
        returnRole.role = role;
        returnRole.skillComparison = getSkillOverlapData(role);
        return returnRole;
      });
      setRelatedRoles(processedRoles);
      setLoadingRoles(false);
    };
    filter && loadRoles();
  }, [filter]);

  const compareRoleHandler = compareRole => {
    setCompareRole(compareRole);
    setShowSimilarRoleSKills(true);
  };

  const columns: GridColDef<RelatedRole>[] = [
    {
      field: 'label',
      headerName: 'Your Roles',
      flex: 3,
      valueGetter: params => {
        return params?.row?.role?.roleName || '';
      },
    },
    {
      field: 'skillCount',
      headerName: 'Skill Overlap',
      flex: 2,
      valueGetter: params => {
        return params?.row?.skillComparison?.count || 0;
      },
      renderCell: param => {
        const comparisonData = param.row.skillComparison;
        const comparisonPercent = Math.round(comparisonData.percent * 10000) / 100;
        return (
          <>
            {comparisonData.count}
            <SkillCountGraph $width={comparisonPercent} title={`There is a ${comparisonPercent}% skill ovelap.`} />
          </>
        );
      },
    },
    {
      field: 'roleId',
      headerName: '',
      flex: 1,
      align: 'center',
      renderCell: param => {
        return (
          <Button variant="outlined" onClick={() => compareRoleHandler(param.row.role)}>
            Compare
          </Button>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <DataGrid
        columns={columns}
        rows={relatedRoles}
        getRowId={(r: RelatedRole) => r.role.roleId}
        loading={loadingRoles}
        paginationMode="client"
        rowCount={relatedRoles.length}
        pageSize={10}
        rowsPerPageOptions={[10]}
        autoHeight
        className="related-roles-table"
        sortModel={[{ field: 'skillCount', sort: 'desc' }]}
      />
      {compareRole && (
        <SimilarRoleSkills
          role={role}
          comparedRole={compareRole}
          showSimilarRoleSkills={showSimilarRoleSKills}
          setShowSimilarRoleSkills={setShowSimilarRoleSKills}
        />
      )}
    </Wrapper>
  );
};

export default RelatedRolesList;
