import defaultAxios from 'axios';
import axios from 'helpers/api_helper';

const SKILL_PROFILES_UPLOAD_FIELDS_ALLOWED = [
  'jobCode',
  'skillId',
  'required',
  'skillLevel',
  'isLanguage',
  'isSoftware',
  'isDeprecated',
  'description',
  'infoUrl',
  'categoryName',
  'categoryId',
  'subCategoryName',
  'subCategoryId',
  'typeName',
  'typeId',
  'source',
  'skillName',
  'isAutomated',
  'customSkillId',
  'roleName',
] as const;
const SKILL_PROFILES_UPLOAD_FIELDS_IGNORED = [
  'isLanguage',
  'isSoftware',
  'isDeprecated',
  'description',
  'infoUrl',
  'categoryName',
  'categoryId',
  'subCategoryName',
  'subCategoryId',
  'typeName',
  'typeId',
  'source',
  'skillName',
  'isAutomated',
  'customSkillId',
  'roleName',
] as const;
const SKILL_PROFILES_UPLOAD_FIELDS_REQUIRED = ['jobCode', 'skillId'] as const;

// const ACCEPTED_TAGS_ALIASES_UPLOAD_FIELDS = ['jobCode', 'type', 'name'];
// const IGNORED_ROLE_UPLOAD_FIELDS = [];
// const ACCEPTED_ROLE_UPLOAD_FIELDS = [
//   'jobCode',
//   'jobTitle',
//   'roleName',
//   'jobFamily',
//   'jobFunction',
//   'emsiTitleId',
//   'emsiTitleName',
//   'soc',
//   'socName',
//   'benchmarkId',
//   'benchmark',
//   'approved',
//   'needsApproval',
//   'reviewed',
//   'temporary',
//   'specializedOccupationName',
//   'specializedOccupationId',
//   'jobDescription',
//   'notes',
//   'updatedAt',
// ];

type FileUploadDetailsBase = {
  fileName: string;
};

export interface RoleFileUploadDetails extends FileUploadDetailsBase {
  uploadType: 'roles';
  uploadAction: 'add' | 'update' | 'delete';
}

export interface SkillsFileUploadDetails extends FileUploadDetailsBase {
  uploadType: 'skills';
  uploadAction: 'add' | 'update' | 'delete';
}

export interface TagsAliasesFileUploadDetails extends FileUploadDetailsBase {
  uploadType: 'tagsAliases';
  uploadAction: 'add' | 'delete';
}

export type FileUploadDetails = SkillsFileUploadDetails; // | RoleFileUploadDetails | TagsAliasesFileUploadDetails;

export type UploadType = FileUploadDetails['uploadType'];

type UploadFieldInfo = {
  allowed: readonly string[];
  required: readonly string[];
  ignored: readonly string[];
};

export const UPLOAD_FIELDS_INFO: {
  [K in FileUploadDetails['uploadType']]: {
    [J in Extract<FileUploadDetails, { uploadType: K }>['uploadAction']]: UploadFieldInfo;
  };
} = {
  skills: {
    add: {
      required: SKILL_PROFILES_UPLOAD_FIELDS_REQUIRED,
      ignored: SKILL_PROFILES_UPLOAD_FIELDS_IGNORED,
      allowed: SKILL_PROFILES_UPLOAD_FIELDS_ALLOWED,
    },
    update: {
      required: SKILL_PROFILES_UPLOAD_FIELDS_REQUIRED,
      ignored: SKILL_PROFILES_UPLOAD_FIELDS_IGNORED,
      allowed: SKILL_PROFILES_UPLOAD_FIELDS_ALLOWED,
    },
    delete: {
      required: SKILL_PROFILES_UPLOAD_FIELDS_REQUIRED,
      ignored: [
        'isLanguage',
        'isSoftware',
        'isDeprecated',
        'description',
        'infoUrl',
        'categoryName',
        'categoryId',
        'subCategoryName',
        'subCategoryId',
        'typeName',
        'typeId',
        'source',
        'skillName',
        'isAutomated',
        'customSkillId',
        'roleName',
        'required',
        'skillLevel',
      ],
      allowed: SKILL_PROFILES_UPLOAD_FIELDS_ALLOWED,
    },
  },
} as const;

type AxiosConfig = {
  headers: Record<string, string>;
  scope: string;
  params?: Record<string, string | number | boolean>;
};

const axiosConfig: AxiosConfig = {
  headers: {
    'Content-Type': 'application/json',
    AllowedOrigin: '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    AllowedMethod: 'PUT',
  },
  scope: 'company_datastore_roles',
};

export const getPresignedUploadUrl = async (companyId: string, details: FileUploadDetails) => {
  const presignedUrlResponse = await axios.get<{ url: string; batchId: string }>(
    `${process.env.REACT_APP_API_COMPANY_DATASTORE_ROOT}/import/get-presigned-url`,
    {
      ...axiosConfig,
      params: {
        ...details,
        companyId,
        // These 2 lines can be removed once the API is updated
        uploadAction: undefined,
        importType: details.uploadAction,
      },
      headers: {
        ...axiosConfig.headers,
        'x-ecds-customerId': companyId,
        'Content-Type': 'csv',
      },
    }
  );
  console.log(presignedUrlResponse.data.url);
  return presignedUrlResponse.data;
};

export const uploadFileToS3 = async (url: string, file: File, onUploadProgress: (progressEvent) => void) => {
  const fileUploadResponse = await defaultAxios.put(url, file, { ...axiosConfig, onUploadProgress });
  return fileUploadResponse.status === 200;
};
