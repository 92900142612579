import axios from 'helpers/api_helper';

const apiBaseUrl = process.env.REACT_APP_API_ROOT;

const updateUserRole = async (companyId: string, userId: string, role: string): Promise<void> => {
  const url = `${apiBaseUrl}/companies/${companyId}/user/${userId}/role`;
  await axios.put(url, { role });
};

const deleteUser = async (companyId: string, userId: string): Promise<void> => {
  const url = `${apiBaseUrl}/companies/${companyId}/user/${userId}`;
  await axios.delete(url);
};

const resetUserPassword = async (companyId: string, userId: string): Promise<void> => {
  const url = `${apiBaseUrl}/companies/${companyId}/user/${userId}/password/reset`;
  await axios.post(url);
};

const getIdentityProviderName = async (email: string): Promise<string | null> => {
  const url = `${apiBaseUrl}/sso/${email}`;
  try {
    const response = await axios.get<{ identityProviderName: string | null }>(url);
    return response.data.identityProviderName;
  } catch (e: unknown) {
    console.log('Something went wrong getting the identity provider name', e);
    return '';
  }
};

export default {
  updateUserRole,
  deleteUser,
  resetUserPassword,
  getIdentityProviderName,
};
