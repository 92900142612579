import { Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

type MenuOptionItemExpandedProps = {
  itemHeader: string;
  itemDescription: ReactElement;
  dataCy?: string;
};

const MenuOptionItemExpanded = ({
  itemHeader,
  itemDescription,
  dataCy = 'option',
}: MenuOptionItemExpandedProps): ReactElement => {
  return (
    <Wrapper data-cy={`${dataCy}-item-expanded-container`}>
      <Typography variant="heading" data-cy={`${dataCy}-item-${itemHeader.replace(/\s+/g, '-').toLowerCase()}-header`}>
        {itemHeader}
      </Typography>
      {itemDescription}
    </Wrapper>
  );
};

export default MenuOptionItemExpanded;
