import { useContext } from 'react';
import { BulkRoleEditContext } from 'contexts/BulkRoleEditContext';
import Role from 'classes/role';
import ReactGA from 'react-ga';
import { RoleListSort } from 'types/types';

const logAnalytics = (action, event) => {
  ReactGA.event({
    category: 'bulk roles',
    action,
    label: event,
  });
};

export interface UseBulkEditReturnObject {
  isAllRolesLoading: boolean | undefined;
  isSaving: boolean | undefined;
  activeRoleId: string | undefined;
  roleList: Role[];
  roleListSort: RoleListSort;
  activeRoleIndex: number;
  roleFilter: string | undefined;
  appliedFilters: any;
  setRoleListSort: (roleListSort: RoleListSort) => void;
  setShowMetaData: (showMetaData: boolean) => void;
  setNextRole: (roleId?: string) => void;
  updateRoleByRoleId: (role: Role) => void;
  setRoleFilter: (roleFilter: string) => void;
  setAppliedFilters: (setAppliedFilters: any) => void;
}

const useBulkRoleEditContext = (): UseBulkEditReturnObject => {
  const [state, setState] = useContext(BulkRoleEditContext);

  const setShowMetaData = (showMetaData: boolean) => {
    setState(state => ({ ...state, showMetaData }));
  };

  const getURLSearchParams = () => {
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set('rf', state.roleFilter || '');
    searchParams.set('af', JSON.stringify(state.appliedFilters));
    return searchParams.toString();
  };

  const setRoleListSort = (roleListSort: RoleListSort) => {
    setState(prevState => ({ ...prevState, roleListSort }));
  };

  const setNextRole = (roleId?: string) => {
    if (roleId) {
      logAnalytics('navigate', 'direct to role');
      setState(prevState => ({ ...prevState, activeRoleId: roleId }));
    } else {
      logAnalytics('navigate', 'next role in list');
      // No roleId se we get the next roleId (useEffect will set the activeRoleIndex on the context)
      if (state.activeRoleIndex < state.roleList.length - 1) {
        const nextRoleId = state.roleList[state.activeRoleIndex + 1].roleId;
        setState(prevState => ({ ...prevState, activeRoleId: nextRoleId }));
      } else {
        console.log('Unable to move to next role, end of role list.');
      }
    }
    setState(prevState => {
      window.history.replaceState(null, '', `/role-edit/${prevState.activeRoleId}?${getURLSearchParams()}`);
      const index = state.roleList?.findIndex(role => role.roleId === prevState.activeRoleId);
      return {
        ...prevState,
        activeRoleIndex: index,
      };
    });
  };

  const updateRoleByRoleId = (role: Role) => {
    const updatedRoleListUnsorted = state.roleListUnsorted.map(r => (r.roleId === role.roleId ? role : r));
    setState(prevState => ({ ...prevState, roleListUnsorted: updatedRoleListUnsorted }));
  };

  const setRoleFilter = (roleFilter: string) => {
    setState(prevState => ({ ...prevState, roleFilter }));
  };

  const setAppliedFilters = (appliedFilters: any) => {
    setState(prevState => ({ ...prevState, appliedFilters }));
  };

  return {
    isAllRolesLoading: state.isAllRolesLoading,
    isSaving: state.isSaving,
    activeRoleId: state.activeRoleId,
    roleList: state.roleList,
    roleListSort: state.roleListSort,
    activeRoleIndex: state.activeRoleIndex,
    roleFilter: state.roleFilter,
    appliedFilters: state.appliedFilters,
    setRoleListSort,
    setShowMetaData,
    setNextRole,
    updateRoleByRoleId,
    setRoleFilter,
    setAppliedFilters,
  };
};

export default useBulkRoleEditContext;
