import React, { ReactElement } from 'react';
import BreadCrumbs from 'components/organisms/BreadCrumbs';

const BreadCrumbsDemo = (): ReactElement<any> => {
  return (
    <BreadCrumbs
      currentPageName="Current Page"
      previousPageName="PreviousPage"
      previousPagePath="/admin/admin-tools"
      width="100%"
    />
  );
};

export default BreadCrumbsDemo;
