import React from 'react';
import { useField, useFormikContext } from 'formik';
import RadioList from 'components/molecules/RadioList';
import FormFieldErrorMessage from 'components/atoms/formElements/FormFieldErrorMessage';
import InputRow from 'components/atoms/InputRow';
import FormPartLabel from 'components/atoms/formElements/FormPartLabel';
import { RadioListLineItemProps } from 'components/atoms/RadioListLineItem';

interface FormikRadioListProps {
  label: string;
  items: RadioListLineItemProps[];
  disabled: boolean;
  name: string;
  selectedValue: any;
  onChange: any;
  onFocus: any;
  id?: string;
  tabIndex?: number;
  dataCy?: string;
  hasPostingDataColumns?: boolean;
}

const FormikRadioList: React.FC<FormikRadioListProps> = ({ ...props }) => {
  const [field, meta] = useField(props);
  const form = useFormikContext();

  // manually sets meta.touched, because auto setTouched is not detected
  const handleFieldTouched = () => {
    form.setFieldTouched(field.name, true, true);
    form.touched[`${field.name}`] = meta.touched;
  };

  const onChangeLocal: (props: Record<string, string>) => void = selection => {
    form.setFieldValue(field.name, selection.value);
    handleFieldTouched();
    props.onChange(selection, { form, meta, field });
  };

  return (
    <>
      <InputRow className="input-row">
        <FormPartLabel htmlFor={props.id || props.name}>{props.label}</FormPartLabel>
        <RadioList clickHandler={onChangeLocal} {...field} {...props} />
        {meta.touched && meta.error ? <FormFieldErrorMessage errorMessage={meta.error} /> : null}
      </InputRow>
    </>
  );
};

export default FormikRadioList;
