import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ErrorMessageContainer = styled.div`
  display: inline-flex;
  color: ${props => props.theme.colors.text.critical};
  font-size: 0.75rem;
  font-weight: bold;
`;

const FormFieldErrorMessage = ({ errorMessage, className, dataCy }) => {
  return (
    <ErrorMessageContainer className={className || 'error-message'} dataCy={dataCy || 'error-message'}>
      {errorMessage}
    </ErrorMessageContainer>
  );
};

FormFieldErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  dataCy: PropTypes.string,
};

export default FormFieldErrorMessage;
