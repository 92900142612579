import React, { ReactElement, useState } from 'react';
import {
  IntegrationSchedule,
  IntegrationScheduleJobType,
  IntegrationScheduleType,
  VendorCompanyConfiguration,
  IntegrationScheduleFields,
  IntegrationSettingType,
} from 'types/types';
import FormikTextField from 'components/atoms/formElements/FormikTextField';
import FormikSelect from 'components/atoms/formElements/FormikSelect';
import { Typography } from '@mui/material';
import ButtonGroup from 'components/atoms/ButtonGroup';
import { Form, Formik, FormikHelpers } from 'formik';
import CheckBox from 'components/atoms/CheckBox';
import styled from 'styled-components';
import * as Yup from 'yup';

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  flex-grow: 1;
  margin-bottom: 1rem;
`;

const schedulingOptions = [
  { value: 'none', label: 'None' },
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
];

const statusTypeOptions = [
  { value: 'Approved', label: 'Approved' },
  { value: 'needsApproval', label: 'Needs Approval' },
  { value: 'Reviewed', label: 'Reviewed' },
];

type ScheduleInput = {
  name: IntegrationScheduleJobType;
  value: string;
  input: 'select' | 'checkbox';
  options?: { value: string; label: string }[];
  required: boolean;
};

const scheduleInputs: ScheduleInput[] = [
  {
    name: 'importExternalJobProfiles',
    value: 'Job Profile Imports from Workday',
    input: 'select',
    options: schedulingOptions,
    required: false,
  },
  {
    name: 'exportInternalJobProfiles',
    value: 'Job Profile Updates to Workday',
    input: 'select',
    options: schedulingOptions,
    required: false,
  },
  {
    name: 'importExternalSkills',
    value: 'Skills Imports to Workday',
    input: 'select',
    options: schedulingOptions,
    required: false,
  },
  {
    name: 'exportSkillsWithMappings',
    value: 'Include Lightcast mappings',
    input: 'checkbox',
    required: false,
  },
];

const criteriaInputs = [
  {
    name: 'matchStatus',
    value: 'Status Type',
    input: 'select',
    options: statusTypeOptions,
    required: true,
  },
  {
    name: 'tags',
    value: 'Tag',
    placeholder: 'Enter tag value',
    input: 'text',
    options: [],
    required: false,
  },
];

type IntegrationSchedulingFormProps = {
  vendorId: string;
  vendorName: string;
  integrationSchedules: { schedules: IntegrationSchedule[]; criteria: VendorCompanyConfiguration[] };
  handleSave: (
    vendorId: string,
    integrationShedules: { job: IntegrationScheduleJobType; schedule: IntegrationScheduleType }[],
    criteria: Record<string, string>
  ) => Promise<void>;
  onAfterSave: (integrationSettingType: IntegrationSettingType, success: boolean) => void;
};

const IntegrationSchedulingForm = ({
  vendorId,
  vendorName,
  integrationSchedules,
  handleSave,
  onAfterSave,
}: IntegrationSchedulingFormProps): ReactElement => {
  const [saving, setSaving] = useState(false);

  const submitSchedules = async (
    values: IntegrationScheduleFields,
    formikHelpers: FormikHelpers<IntegrationScheduleFields>
  ) => {
    formikHelpers.setStatus(null);
    setSaving(true);
    try {
      const {
        importExternalJobProfiles,
        exportInternalJobProfiles,
        importExternalSkills,
        exportSkillsWithMappings,
        matchStatus,
        tags,
      } = values as IntegrationScheduleFields;
      let ies: IntegrationScheduleType, ieswm: IntegrationScheduleType;
      if (exportSkillsWithMappings && importExternalSkills !== 'none') {
        ies = 'none';
        ieswm = importExternalSkills;
      } else {
        ies = importExternalSkills;
        ieswm = 'none';
      }
      await handleSave(
        vendorId,
        [
          {
            job: 'importExternalJobProfiles',
            schedule: importExternalJobProfiles,
          },
          {
            job: 'exportInternalJobProfiles',
            schedule: exportInternalJobProfiles,
          },
          {
            job: 'importExternalSkills',
            schedule: ies,
          },
          {
            job: 'exportSkillsWithMappings',
            schedule: ieswm,
          },
        ],
        { matchStatus, tags }
      );
      onAfterSave('Schedules', true);
      formikHelpers.resetForm({ values });
    } catch (e) {
      onAfterSave('Schedules', false);
    } finally {
      setSaving(false);
    }
  };

  const schedulesInitialValues = scheduleInputs.reduce<
    Record<IntegrationScheduleJobType, IntegrationScheduleType | boolean>
  >(
    (acc, input) => {
      const schedule =
        integrationSchedules?.schedules?.find(schedule => schedule.job === input.name)?.schedule || 'none';
      if (input.name === 'exportSkillsWithMappings') {
        if (schedule === 'none') {
          acc.exportSkillsWithMappings = false;
        } else {
          acc.exportSkillsWithMappings = true;
          acc.importExternalSkills = schedule;
        }
      } else {
        acc[input.name] = schedule;
      }
      return acc;
    },
    {} as Record<IntegrationScheduleJobType, IntegrationScheduleType | boolean>
  );

  const criteriaInitialValues: IntegrationScheduleFields = criteriaInputs.reduce((acc, input) => {
    const criteria =
      integrationSchedules?.criteria?.find(criteria => criteria.fieldName === input.name)?.fieldValue.toString() || '';
    acc[input.name] = criteria;
    return acc;
  }, {} as IntegrationScheduleFields);

  return (
    <>
      <Formik<IntegrationScheduleFields>
        onSubmit={submitSchedules}
        initialValues={
          Object.keys(schedulesInitialValues).length > 0 || Object.keys(criteriaInitialValues).length > 0 ? 
          { ...schedulesInitialValues, ...criteriaInitialValues } : 
          {
            importExternalJobProfiles: 'none',
            exportInternalJobProfiles: 'none',
            importExternalSkills: 'none',
            exportSkillsWithMappings: false,
            matchStatus: '',
            tags: '',
          }
        }
        validationSchema={Yup.object({
          importExternalJobProfiles: Yup.string(),
          exportInternalJobProfiles: Yup.string(),
          importExternalSkills: Yup.string(),
          exportSkillsWithMappings: Yup.boolean(),
          // matchStatus is a select field, that can be empty but is required
          matchStatus: Yup.string().required('Status Type is required'),
          tags: Yup.string(),
        })}
      >
        {props => {
          return (
            <>
              <Form
                onSubmit={e => {
                  console.log('submitting form');
                  e.preventDefault();
                  props.handleSubmit();
                }}
              >
                <FormRow style={{ marginBottom: '2rem' }}>
                  <Typography variant="displayMedium">{vendorName} Scheduling</Typography>
                  <ButtonGroup
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    spacing={2}
                    buttons={[
                      {
                        text: 'Cancel',
                        disabled: !props.dirty || !props.isValid || saving,
                        onClickEvent: () => {
                          if (props.dirty) {
                            props.resetForm();
                          }
                        },
                      },
                      {
                        text: 'Save',
                        disabled: !props.dirty || !props.isValid || saving,
                        onClickEvent: props.handleSubmit,
                        variant: 'contained',
                        color: 'actionPrimary',
                        isLoadingButton: true,
                        loadingStatus: saving,
                        loadingMessage: 'Saving...',
                      },
                    ]}
                  />
                </FormRow>
                {scheduleInputs.map((input, index) => {
                  return (
                    <FormRow key={index}>
                      {input.input === 'select' && (
                        <FormikSelect
                          formPropertyName={input.name}
                          options={input.options || []}
                          label={input.value}
                          required={input.required}
                        />
                      )}
                      {input.input === 'checkbox' && (
                        <CheckBox
                          name={input.name}
                          value={input.value}
                          checked={props.values.exportSkillsWithMappings}
                          onChange={e => props.setFieldValue(input.name, e.target.checked)}
                          dataCy={input.name}
                        >
                          {input.value}
                        </CheckBox>
                      )}
                    </FormRow>
                  );
                })}
                <FormRow>
                  <Typography variant="body">Profiles Ready For Import</Typography>
                </FormRow>
                {criteriaInputs.map((input, index) => {
                  return (
                    <FormRow key={index}>
                      {input.input === 'select' && (
                        <FormikSelect
                          formPropertyName={input.name}
                          options={input.options}
                          label={input.value}
                          required={input.required}
                        />
                      )}
                      {input.input === 'text' && (
                        <FormikTextField
                          name={input.name}
                          label={input.value}
                          placeholder={input?.placeholder}
                          required={input.required}
                        />
                      )}
                    </FormRow>
                  );
                })}
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default IntegrationSchedulingForm;
