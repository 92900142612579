import React, { ReactElement } from 'react';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { useTheme } from 'styled-components';
import { Skill } from 'classes/skillProfile';

type UniqueSkillsTableProps = {
  skillsA: Skill[];
  skillsB: Skill[];
};

const UniqueSkillsTable = ({ skillsA, skillsB }: UniqueSkillsTableProps): ReactElement => {
  const theme = useTheme();

  const getUniqueSkillsTableColumns = headerName => {
    const uniqueSkillsColumns: GridColDef<Skill>[] = [
      {
        field: 'skillName',
        headerName,
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        renderCell(params) {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>{params.row.skillName}</div>
              <div>
                <span style={{ color: theme.colors.text.critical }}>{params.row.required ? 'Required' : ''}</span>{' '}
                <span style={{ color: theme.colors.text.disabled }}>{params.row.typeName}</span>
              </div>
            </div>
          );
        },
      },
    ];
    return uniqueSkillsColumns;
  };
  return (
    <div style={{ display: 'flex' }}>
      <DataGrid
        columns={getUniqueSkillsTableColumns('Role A Unique Skills')}
        rows={skillsA}
        getRowId={(skill: Skill) => skill.skillId}
        autoHeight
        disableSelectionOnClick
        className={'skills-table'}
        hideFooterPagination
        hideFooter
      />
      <DataGrid
        columns={getUniqueSkillsTableColumns('Role B Unique Skills')}
        rows={skillsB}
        getRowId={(skill: Skill) => skill.skillId}
        autoHeight
        disableSelectionOnClick
        className={'skills-table'}
        hideFooterPagination
        hideFooter
      />
    </div>
  );
};

export default UniqueSkillsTable;
