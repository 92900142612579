import { LinkProps } from '@mui/material';
import type {} from '@mui/lab/themeAugmentation';

export const muiLinkOverride = {
  defaultProps: {
    underline: 'hover',
    variant: 'body',
    color: 'interactive.default',
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      padding: '0px 4px 2px 4px',
      backgroundColor: 'transparent',
      borderRadius: '4px',
      cursor: 'pointer',
      '&:focus-visible': {
        boxShadow:
          '0px 0px 0px 1px #FFFFFF, 0px 0px 0px 3px #4E91F5, 0px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
        outline: 'none',
      },
      '&:hover': {
        backgroundColor: 'transparent',
        cursor: 'pointer',
      },
      '&:active': {
        backgroundColor:
          (ownerState.color === 'interactive.critical' || ownerState.color === 'text.critical')
            ? theme.palette.surfaceCriticalSubdued.main
            : theme.palette.surfaceSelected.main,
      },
      ...((ownerState.href === undefined || ownerState.href === '') &&
        ownerState.onClick === undefined && {
          color: theme.palette.text.disabled,
          cursor: 'default',
          '&:hover': {
            textDecoration: 'none',
          },
        }),
    }),
  } as LinkProps,
};
