import React, { useCallback, useMemo, ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import Typography from '@mui/material/Typography';
import BodyColumn from 'components/atoms/BodyColumn';
import { Role } from 'types/types';

const ComparisonSummaryWrapper = styled.div`
  background-color: white;
  border-radius: ${props => props.theme.borderRadius.default};
  box-shadow: ${props => props.theme.boxShadow.surface1};
  display: flex;
  flex-direction: column;
  font-weight: 600;
  height: fit-content;
  min-height: 18rem;
  justify-content: flex-start;
  margin-bottom: 10px;
  overflow: hidden;
  width: 100%;
  margin: 10px;
`;

const ComparisonSummaryHeaderBar = styled.div`
  background-color: ${props => props.theme.colors.surface.neutral};
  height: 1rem;
  width: 100%;
`;

const ComparisonSummaryHeader = styled.div`
  padding: ${props => props.theme.customSpacing.px.base * 4}px;
`;

const SectionHead = styled(Typography)`
  display: block;
  border-bottom: 2px solid ${props => props.theme.colors.border.subdued};
  padding: ${props => props.theme.customSpacing.px.xxs}px;
  padding-top: ${props => props.theme.customSpacing.px.base * 9}px;
`;

const SkillItem = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.surface.neutral};
  font-size: 0.85rem;
  color: ${props => props.theme.colors.text.subdued};
  padding: ${props => `${props.theme.customSpacing.px.base * 1}px ${props.theme.customSpacing.px.xxs}px`};
`;

type roleComparisonColumnProps = {
  comparedRoles: Role[];
};

const RoleComparisonSummaryColumn = ({ comparedRoles = [{}, {}, {}] }: roleComparisonColumnProps): ReactElement => {
  const theme = useTheme();
  const skillsHashArray = useMemo(() => {
    return comparedRoles.map(r => {
      const skillsHash = {};
      if (r?.skills) {
        r.skills.forEach(s => {
          skillsHash[s.skillId] = true;
        });
      }
      return skillsHash;
    });
  }, [comparedRoles]);

  const getHitMatrix = useCallback(
    skillId => {
      return [skillsHashArray[0]?.[skillId], skillsHashArray[1]?.[skillId], skillsHashArray[2]?.[skillId]];
    },
    [skillsHashArray]
  );

  const skillsWithHitMaps: any = useMemo(() => {
    const _skillsHash = {};

    comparedRoles.forEach(r => {
      if (r?.skills) {
        r.skills.forEach(s => {
          if (!_skillsHash[s.skillId]) {
            const hitMatrix = getHitMatrix(s.skillId);
            _skillsHash[s.skillId] = {
              skill: s,
              hitMatrix,
            };
          }
        });
      }
    });
    return Object.values(_skillsHash);
  }, [getHitMatrix, comparedRoles]);

  const skillsList123 = useMemo(() => {
    return [...skillsWithHitMaps]
      .filter(s => {
        return s.hitMatrix[0] && s.hitMatrix[1] && s.hitMatrix[2];
      })
      .map(s => s.skill);
  }, [skillsWithHitMaps]);

  const skillsList12 = useMemo(() => {
    return [...skillsWithHitMaps]
      .filter(s => {
        return s.hitMatrix[0] && s.hitMatrix[1] && !s.hitMatrix[2];
      })
      .map(s => s.skill);
  }, [skillsWithHitMaps]);

  const skillsList13 = useMemo(() => {
    return [...skillsWithHitMaps]
      .filter(s => {
        return s.hitMatrix[0] && !s.hitMatrix[1] && s.hitMatrix[2];
      })
      .map(s => s.skill);
  }, [skillsWithHitMaps]);

  const skillsList23 = useMemo(() => {
    return [...skillsWithHitMaps]
      .filter(s => {
        return !s.hitMatrix[0] && s.hitMatrix[1] && s.hitMatrix[2];
      })
      .map(s => s.skill);
  }, [skillsWithHitMaps]);

  const renderSkills = skillArray => {
    if (skillArray.length === 0) {
      return <SkillItem style={{ color: theme.colors.text.disabled }}>None</SkillItem>;
    }
    return (
      <>
        {skillArray.map(s => {
          return <SkillItem key={`12_${s.skillId}`}>{s.skillName}</SkillItem>;
        })}
      </>
    );
  };

  return (
    <ComparisonSummaryWrapper data-cy="compare-roles-skills-summary-section">
      <ComparisonSummaryHeaderBar />
      <BodyColumn>
        <ComparisonSummaryHeader data-cy="compare-roles-skills-summary-section-comparisonsummaryheader">
          <div>Summary</div>
        </ComparisonSummaryHeader>

        <div>
          <SectionHead
            variant="subHeading"
            data-cy="compare-roles-skills-summary-shared-between-all-section"
            sx={{ marginTop: `${theme.customSpacing.px.base * 8}px` }}
          >
            Shared Between All Roles
          </SectionHead>
          {renderSkills(skillsList123)}
        </div>
        <div>
          <SectionHead variant="subHeading" data-cy="compare-roles-skills-summary-shared-between-role-12-section">
            Shared With Role 1 + 2
          </SectionHead>
          {renderSkills(skillsList12)}
        </div>
        <div>
          <SectionHead variant="subHeading" data-cy="compare-roles-skills-summary-shared-between-role-13-section">
            Shared With Role 1 + 3
          </SectionHead>
          {renderSkills(skillsList13)}
        </div>
        <div>
          <SectionHead variant="subHeading" data-cy="compare-roles-skills-summary-shared-between-role-23-section">
            Shared With Role 2 + 3
          </SectionHead>
          {renderSkills(skillsList23)}
        </div>
      </BodyColumn>
    </ComparisonSummaryWrapper>
  );
};

export default RoleComparisonSummaryColumn;
