import React, { ReactElement } from 'react';
import { Typography, DrawerProps, Button } from '@mui/material';
import styled, { useTheme } from 'styled-components';
import SidePanelTemplate from 'pages/templates/SidePanelTemplate';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import TextWithSubText from 'components/atoms/TextWithSubText';
import PipIcon, { PipIconType } from 'components/atoms/PipIcon';
import { RadialLollipopItemProps } from 'components/charts/RadialLollipop';
import Role from 'classes/role';
import RelatedRolesList from 'components/molecules/RelatedRolesList';

type SimilarRoleListProps = {
  setShowRolesMappedToOcc: React.Dispatch<React.SetStateAction<boolean>>;
  role: Role;
  filterType: string;
  filterId: string;
  selectedSimilarRole?: RadialLollipopItemProps;
  showRolesMappedToOcc?: boolean;
  occPipFillColor?: string;
};

const SubHeaderTextContainer = styled.div`
  display: flex;
`;

const SubHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: ${props => props.theme.customSpacing.px.s}px;
`;

const SimilarRoleList = ({
  setShowRolesMappedToOcc,
  role,
  filterType,
  filterId,
  selectedSimilarRole,
  showRolesMappedToOcc = false,
  occPipFillColor,
}: SimilarRoleListProps): ReactElement => {
  const theme = useTheme();

  const TitleComponent = () => {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <BaseIcon type={IconType.ArrowLeftRightDarkBg} />
          <Typography variant="h5">Role Comparison</Typography>
        </div>
      </div>
    );
  };

  const ActionComponent = (): ReactElement => {
    return (
      <>
        <Button variant="outlined" onClick={() => setShowRolesMappedToOcc(false)}>
          Cancel
        </Button>
      </>
    );
  };

  const drawerProps: DrawerProps = { variant: 'temporary', anchor: 'right', open: showRolesMappedToOcc };

  return (
    <SidePanelTemplate
      headerTitle={<TitleComponent />}
      headerActions={<ActionComponent />}
      displayHeaderDivider={true}
      width={792}
      drawerProps={drawerProps}
    >
      <SubHeaderContainer>
        <SubHeaderTextContainer style={{ flexBasis: '35%' }}>
          <TextWithSubText subText="Role A" text={role.roleName} isSubTextOnTop={true} />
        </SubHeaderTextContainer>
        <SubHeaderTextContainer style={{ flexBasis: '10%' }}>
          <BaseIcon type={IconType.ArrowLeftRight} />
        </SubHeaderTextContainer>
        <SubHeaderTextContainer style={{ flexBasis: '35%' }}>
          <TextWithSubText subText="Role B" text={selectedSimilarRole?.label} isSubTextOnTop={true} />
        </SubHeaderTextContainer>
        <SubHeaderTextContainer style={{ flexBasis: '20%' }}>
          <PipIcon
            type={PipIconType.full}
            fillColor={occPipFillColor}
            width="16"
            height="16"
            margins={`0px ${theme.customSpacing.px.base * 2}px 0px 0px`}
          />
          <Typography component="span">{selectedSimilarRole?.label}</Typography>
        </SubHeaderTextContainer>
      </SubHeaderContainer>
      <RelatedRolesList role={role} filter={{ type: filterType, id: filterId }} />
    </SidePanelTemplate>
  );
};

export default SimilarRoleList;
