import axios from 'axios';
import cryptoJS from 'crypto-js';

const checkIfPasswordIsCompromised = async (password: string): Promise<void> => {
  const hashedPassword = cryptoJS.SHA1(password).toString(cryptoJS.enc.Hex);
  const prefix = hashedPassword.substring(0, 5).toUpperCase();
  const suffix = hashedPassword.substring(5).toUpperCase();

  const res = await axios.get(`https://api.pwnedpasswords.com/range/${prefix}`);

  if (res.status === 200 && res.data.toUpperCase().includes(suffix)) {
    const error = new Error(
      'The password you are trying to use has been compromised before and is not secure to be used. Please try a different password and try again.'
    );
    error.name = 'CompromisedPasswordException';
    throw error;
  }
};

export default { checkIfPasswordIsCompromised };
