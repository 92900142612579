import React, { ReactElement, useState } from 'react';
import ButtonGroup, { EMSIBGButton } from 'components/atoms/ButtonGroup';
import AutocompleteInput from 'components/molecules/AutocompleteInput';
import EmsiUIChip from 'components/atoms/EmsiUIChip';
import useGetSkills, { AutocompleteSkill } from 'helpers/useGetSkills';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

export enum RoleSkillUpdateType {
  Add = 'Add',
  Remove = 'Remove',
}

interface QuickUpdateSkillsProps {
  roleIds: Array<string>;
  onCancel?: () => void;
  onSave?: (roleIds: Array<string>, skills: AutocompleteSkill[]) => void;
  actionButtonText: string;
  skillSource?: 'custom' | 'lightcast';
}

const QuickUpdateSkills = ({
  roleIds,
  onCancel,
  onSave,
  actionButtonText,
  skillSource,
}: QuickUpdateSkillsProps): ReactElement => {
  const [skills, setSkills] = useState<AutocompleteSkill[]>([]);
  const { skillsList, updateSearch, isLoading: isLoadingSearch } = useGetSkills(skillSource);
  const [isLoading, setIsLoading] = useState(false);
  const handleDeleteChip = id => {
    setSkills(skills.filter(skill => skill?.id !== id));
  };

  const handleInputValueChange = value => {
    updateSearch(value);
  };

  const handleAddSkill = value => {
    if (value.id && value.name && !skills.some(skill => skill?.id === value?.id)) {
      const updatedSkill = { ...value, source: 'quick' };
      setSkills(prevState => [...prevState, updatedSkill]);
    }
    updateSearch();
  };

  const handleCancel = () => {
    onCancel?.();
  };

  const handleSave = async () => {
    setIsLoading(true);
    await onSave?.(roleIds, skills);
    setIsLoading(false);
  };

  const buttonGroup: EMSIBGButton[] = [
    {
      text: actionButtonText,
      color: 'actionPrimary',
      variant: 'contained',
      isLoadingButton: true,
      loadingStatus: isLoading,
      loadingMessage: 'Saving...',
      onClickEvent: () => handleSave(),
      dataCy: 'add-quick-skills-button',
    },
    {
      text: 'Cancel',
      variant: 'contained',
      onClickEvent: () => handleCancel(),
      dataCy: 'cancel-quick-skills-button',
    },
  ];
  return (
    <Grid container p={1}>
      <AutocompleteInput
        onInputChange={handleInputValueChange}
        list={skillsList.map(skill => {
          return { ...skill, id: skill.id, label: skill.name };
        })}
        onItemSelect={handleAddSkill}
        isLoading={isLoadingSearch}
      />
      <Paper
        sx={{
          width: '100%',
          minHeight: '60px',
          display: 'flex',
          flexWrap: 'wrap',
          listStyle: 'none',
          p: 0.5,
          mt: 1,
        }}
        variant="outlined"
      >
        {skills.map(skill => (
          <EmsiUIChip
            style={{ margin: '1px' }}
            id={skill?.id?.toString()}
            key={skill?.id}
            label={skill?.name}
            deleteHandler={handleDeleteChip}
          />
        ))}
      </Paper>
      <Grid item py={1}>
        <ButtonGroup buttons={buttonGroup} />
      </Grid>
    </Grid>
  );
};

export default QuickUpdateSkills;
