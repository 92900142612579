import React, { ReactElement, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { HelmetProvider } from 'react-helmet-async';

import HomePage from 'pages/HomePage';
import ConfirmAccountPage from 'pages/ConfirmAccountPage';
import SignInPage from 'pages/SignInPage';
import SsoSignInPage from 'pages/SsoSignInPage';
import SsoSignOutPage from 'pages/SsoSignOutPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import PasswordResetRequiredPage from 'pages/PasswordResetRequiredPage';
import ProfilePage from 'pages/ProfilePage';
import HealthCheckPage from 'pages/HealthCheckPage';
import TermsOfServicePage from 'pages/TermsOfServicePage';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';
import SkillDetailsPage from 'pages/SkillDetailsPage';
import RolesPage from 'pages/RolesPage';
import EditRolePage from 'pages/EditRolePage';
import SkillProfilesPage from 'pages/SkillProfilesPage';
import RolesARToolsPage from 'pages/RolesARToolsPage';
import RoleComparisonPage from 'pages/RoleComparisonPage';
import EventLogPage from 'pages/EventLogPage.js';
import BatchLogPage from 'pages/admin/BatchLogPage';
import UniqueSkillsPage from 'pages/UniqueSkillsPage';
import BatchProcessingDetailsPage from 'pages/admin/BatchProcessingDetailsPage';

import InsightsHomePage from 'insights/HomePage';

import CompanySettingsPage from 'pages/admin/CompanySettingsPage';
import CompanyCustomSkillsPage from 'pages/admin/CompanyCustomSkillsPage';
import BenchmarksManagementPage from 'pages/admin/BenchmarksManagementPage';
import CompaniesManagementPage from 'pages/admin/CompaniesManagementPage';
import NotificationsSettingsPage from 'pages/admin/NotificationsSettingsPage';
import IntegrationsSettingsPage from 'pages/admin/integrations/IntegrationsSettingsPage';
import IntegrationPage from 'pages/admin/integrations/IntegrationPage';
import IntegrationImportRolesPage from 'pages/admin/integrations/IntegrationImportRolesPage';
import IntegrationExportRolesPage from 'pages/admin/integrations/IntegrationExportRolesPage';
import IntegrationExternalSkillMappingPage from 'pages/admin/integrations/IntegrationExternalSkillMappingPage';
import UserManagementPage from 'pages/admin/UserManagementPage';
import UserDetailsPage from 'pages/admin/UserDetailsPage';
import SkillProfileDetailsPage from 'pages/SkillProfileDetailsPage';
import SkillProfileEditPage from 'pages/SkillProfileEditPage';
import SkillsSummaryPage from 'pages/SkillsSummary';

import AdminToolsPage from 'pages/admin/AdminToolsPage';
import UILibraryPage from 'pages/admin/uiLibrary/UILibraryPage';
import D3ChartDemoPage from 'pages/admin/uiLibrary/D3ChartDemoPage';
import APIInformationPage from 'pages/admin/ApiInformationPage';

import NotFoundPage from 'pages/NotFoundPage';
import ErrorBoundary from 'pages/ErrorBoundary';

import { PublicRoute, PrivateRoute, AnalystUserRoute, AdminUserRoute, LightcastAdminUserRoute } from 'RouteWrappers';

import { AuthenticationContextProvider } from 'contexts/AuthenticationContext';
import { UserContextProvider } from 'contexts/UserContext';
import { CompanyContextProvider } from 'contexts/CompanyContext';
import { ColorModeContextProvider } from 'contexts/ColorModeContext';
import { SearchContextProvider } from 'contexts/SearchContext';

hotjar.initialize(parseInt(process.env.REACT_APP_HOTJAR_ID || '6'), 6);

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || 'GA_ID_NOT_FOUND');

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN_FRONTEND,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_NODE_ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const App = (): ReactElement => {
  const location = useLocation();
  ReactGA.pageview(location.pathname);

  let gaPageName;

  useEffect(() => {
    switch (location.pathname) {
      case '/unique-skills':
        gaPageName = 'Unique Skills';
        break;
      case '/sign-up':
        gaPageName = 'Sign-up';
        break;
      case '/sign-in':
        gaPageName = 'Sign-in';
        break;
      case '/confirm':
        gaPageName = 'Confirm';
        break;
      case '/terms-of-service':
        gaPageName = 'Terms of Service';
        break;
      case '/privacy-policy':
        gaPageName = 'Privacy Policy';
        break;
      case '/explore':
        gaPageName = 'Explore';
        break;
      case '/my-profile':
        gaPageName = 'Profile';
        break;
      case '/insights/home':
        gaPageName = 'Insights Home';
        break;
      case '/forgot-password':
        gaPageName = 'Forgot Password Page';
        break;
      case '/password-reset-required':
        gaPageName = 'Password Reset Page';
        break;
      case '/roles':
        gaPageName = 'Roles Page';
        break;
      case '/roles/compare':
        gaPageName = 'Role Comparison Page';
        break;
      case '/roles/applied-research-tools':
        gaPageName = 'Applied Research Tools';
        break;
      case '/admin/company-settings':
        gaPageName = 'Company Settings';
        break;
      case '/admin/custom-skills':
        gaPageName = 'Custom Skills';
        break;
      case '/admin/benchmark-management':
        gaPageName = 'Benchmark Management';
        break;
      case '/admin/company-management':
        gaPageName = 'Company Management';
        break;
      case '/admin/notifications-settings':
        gaPageName = 'Notifications Settings';
        break;
      case '/admin/integration-settings':
        gaPageName = 'Integation Settings';
        break;
      case '/admin/user-management':
        gaPageName = 'User Management';
        break;
      case '/admin/admin-tools':
        gaPageName = 'Admin Tools';
        break;
      case '/admin/user':
        gaPageName = 'Edit User';
        break;
      case '/event-logs':
        gaPageName = 'Company Event Logs';
        break;
      case 'admin/processing-statuses':
        gaPageName = 'Processing Statuses Logs';
        break;
      case 'admin/api-info':
        gaPageName = 'API Information Page';
        break;
      default:
        gaPageName = '- undefined -';
        break;
    }

    if (location.pathname.match(/\/roles-mapping\/.*/)) {
      gaPageName = 'Single Role Mapping';
    }

    if (location.pathname.match(/\/skills\/.*/)) {
      gaPageName = 'Skill Details';
    }

    if (location.pathname.match(/\/profile\/.*/)) {
      gaPageName = 'Profile Details';
    }

    if (location.pathname.match(/\/admin\/user\/.+/)) {
      gaPageName = 'Edit User';
    }

    if (location.pathname.match(/\/skill-profiles\/.+/)) {
      gaPageName = 'Skill Profile';
    }

    if (location.pathname.match(/\/skill-profiles\/.+\/edit/)) {
      gaPageName = 'Edit Skill Profile';
    }

    if (location.pathname === '/skills-summary') {
      gaPageName = 'Skills Summary';
    }

    ReactGA.pageview(location.pathname + location.search, [], gaPageName);
  }, [location]);

  return (
    <HelmetProvider>
      <AuthenticationContextProvider>
        <UserContextProvider>
          <CompanyContextProvider>
            <SearchContextProvider>
              <ColorModeContextProvider>
                <ErrorBoundary>
                  <Routes>
                    <Route element={<PublicRoute />}>
                      <Route path="/" element={<Navigate to="/sign-in" replace />} />
                      <Route element={<SignInPage />} path="/sign-in" />
                      <Route element={<SsoSignInPage />} path="/sso/sign-in" />
                      <Route element={<SsoSignOutPage />} path="/sso/sign-out" />
                      <Route element={<ForgotPasswordPage />} path="/forgot-password" />
                      <Route element={<PasswordResetRequiredPage />} path="/password-reset-required" />
                      <Route element={<ConfirmAccountPage />} path="/confirm" />
                      <Route element={<TermsOfServicePage />} path="/terms-of-service" />
                      <Route element={<PrivacyPolicyPage />} path="/privacy-policy" />
                      <Route element={<HealthCheckPage />} path="/health" />
                      <Route element={<UILibraryPage />} path="/admin/ui-library" />
                      <Route element={<NotFoundPage />} path="*" />
                    </Route>
                    <Route element={<PrivateRoute />}>
                      <Route element={<ProfilePage />} path="/my-profile" />
                      <Route element={<HomePage />} path="/home" />
                    </Route>
                    <Route element={<AnalystUserRoute />}>
                      <Route element={<InsightsHomePage />} path="/insights/home" />
                      <Route element={<EventLogPage />} path="/event-logs" />
                      <Route element={<EditRolePage />} path="/role-edit/:roleId" />
                      <Route element={<SkillDetailsPage />} path="/skills/:skillId" />
                      <Route element={<RolesPage />} path="/roles" />
                      <Route element={<RolesARToolsPage />} path="/roles/applied-research-tools" />
                      <Route element={<SkillProfilesPage />} path="/skill-profiles" />
                      <Route element={<SkillProfileDetailsPage />} path="/skill-profiles/:roleId" />
                      <Route element={<SkillProfileEditPage />} path="/skill-profiles/:roleId/edit" />
                      <Route element={<RoleComparisonPage />} path="/roles/compare" />
                      <Route element={<AdminToolsPage />} path="/admin/admin-tools" />
                      <Route element={<SkillsSummaryPage />} path="/skills-summary" />
                      <Route element={<D3ChartDemoPage />} path="/admin/d3-demo" />
                    </Route>
                    <Route element={<AdminUserRoute />}>
                      <Route element={<UserDetailsPage />} path="/admin/user/" />
                      <Route element={<UserDetailsPage />} path="/admin/user/:profileId" />
                      <Route element={<CompanySettingsPage />} path="/admin/company-settings" />
                      <Route element={<IntegrationPage />} path="/admin/integration" />
                      <Route element={<IntegrationImportRolesPage />} path="/admin/integration/roles/import" />
                      <Route element={<IntegrationExportRolesPage />} path="/admin/integration/roles/export" />
                      <Route
                        element={<IntegrationExternalSkillMappingPage />}
                        path="/admin/integration/external-skills"
                      />

                      <Route element={<CompanyCustomSkillsPage />} path="/admin/custom-skills" />
                      <Route element={<BenchmarksManagementPage />} path="/admin/benchmark-management" />
                      <Route element={<CompaniesManagementPage />} path="/admin/company-management" />
                      <Route element={<UserManagementPage />} path="/admin/user-management" />
                      <Route element={<BatchLogPage />} path="admin/processing-statuses" />
                      <Route element={<BatchProcessingDetailsPage />} path="/admin/processing-statuses/:batchId" />
                      <Route element={<APIInformationPage />} path="/admin/api-info" />
                      <Route element={<UniqueSkillsPage />} path="/unique-skills" />
                      <Route element={<IntegrationsSettingsPage />} path="/admin/integration-settings" />
                    </Route>
                    <Route element={<LightcastAdminUserRoute />}>
                      <Route element={<NotificationsSettingsPage />} path="/admin/notifications-settings" />
                    </Route>
                  </Routes>
                </ErrorBoundary>
              </ColorModeContextProvider>
            </SearchContextProvider>
          </CompanyContextProvider>
        </UserContextProvider>
      </AuthenticationContextProvider>
    </HelmetProvider>
  );
};

export default App;
