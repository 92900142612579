import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

type SubNavLinkProps = {
  url: string;
  text: string;
  active: boolean;
  clickEvent?: () => void;
  mobileOnly?: boolean;
  dataCy: string;
  disabled?: boolean;
};

const LinkBox = styled(Link)<{ $active: boolean; $mobileOnly?: boolean; $disabled?: boolean }>`
  text: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.text.onHeaderBar};
  font-size: ${props => props.theme.customTypography.desktop.body.size};
  align-self: center;
  margin: 0px 18px;
  padding: 14px 0px;
  cursor: pointer;

  ${props => {
    if (props.$active) {
      if (!props.$mobileOnly) {
        return css`
          box-shadow: inset 0px -2px 0px ${props => props.theme.colors.interactive.default};
        `;
      } else {
        return css`
          box-shadow: inset 4px 0px 0px ${props => props.theme.colors.interactive.default};
        `;
      }
    }
  }}

  ${props =>
    props.$mobileOnly &&
    css`
      display: none;
    `};

  ${props =>
    props.$mobileOnly &&
    css`
      @media (max-width: 768px) {
        display: block;
        background-color: ${props.theme.colors.surface.neutralSubdued};
        opacity: 0.7;
      }
    `};

  ${props =>
    props.$disabled &&
    css`
      color: ${props => props.theme.colors.text.disabled};
      background-color: ${props => props.theme.colors.surface.disabled};
      box-shadow: none;
      cursor: default;
    `};

  @media (max-width: 768px) {
    margin: ${props => `0px ${props.theme.customSpacing.px.base * 0.5}px`};
    padding: ${props => `${props.theme.customSpacing.px.xxs}px ${props.theme.customSpacing.px.base * 4}px`};
    width: 100%;
  }
`;

const SubNavLink = ({ url, text, active, mobileOnly, dataCy, disabled, clickEvent }: SubNavLinkProps): ReactElement => {
  return (
    <>
      <LinkBox
        $active={!!active}
        to={url}
        $mobileOnly={mobileOnly}
        data-cy={dataCy || `subnav-link-${text}`}
        $disabled={disabled}
        onClick={event => {
          if (clickEvent) {
            event.preventDefault();
            clickEvent();
          }
        }}
      >
        {text}
      </LinkBox>
    </>
  );
};

export default SubNavLink;
