import React, { ReactElement } from 'react';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import { useTheme } from 'styled-components';
import { Skill } from 'classes/skillProfile';

type SharedSkillsTableProps = {
  sharedSkills: Skill[];
  comparedSkills: Skill[];
};

const SharedSkillsTable = ({ sharedSkills, comparedSkills }: SharedSkillsTableProps): ReactElement => {
  const theme = useTheme();

  const sharedSkillsColumns: GridColDef<Skill>[] = [
    {
      field: 'skillName',
      headerName: 'Skill Name',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      renderCell(params) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{params.value}</div>
            <div style={{ color: theme.colors.text.disabled }}>{params.row.typeName}</div>
          </div>
        );
      },
    },
    {
      field: 'categoryName',
      headerName: '',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{params.value}</div>
            <div style={{ color: theme.colors.text.disabled }}>{params.row.typeName}</div>
          </div>
        );
      },
    },
    {
      field: 'required',
      headerName: 'Required',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell(param) {
        const requiredA = param.value;
        const requiredB = comparedSkills.find(skill => skill.skillId === param.id)?.required;
        const value = requiredA && requiredB ? 'Both' : requiredA ? 'Role A' : requiredB ? 'Role B' : '';
        return <Typography color={theme.colors.text.success}>{value}</Typography>;
      },
    },
  ];
  return (
    <DataGrid
      columns={sharedSkillsColumns}
      rows={sharedSkills}
      getRowId={(skill: Skill) => skill.skillId}
      autoHeight
      disableSelectionOnClick
      className={'skills-table'}
      hideFooterPagination
      hideFooter
    />
  );
};

export default SharedSkillsTable;
