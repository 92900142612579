import React, { useState, useMemo } from 'react';
import NavBar from 'components/molecules/NavBar';
import styled, { useTheme } from 'styled-components';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import MyAccountDropDown from 'components/organisms/MyAccountDropDown';
import UseAuthContext from 'helpers/UseAuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SubNav from 'components/molecules/SubNav';
import RoutesHelper from 'helpers/RoutesHelper';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { IconColorFilter, SpacingSizes, CustomThemeName, UILayers } from 'types/types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useUserContext from 'helpers/UseUserContext';
import Avatar from '@mui/material/Avatar';
import useCompanyContext from 'helpers/UseCompanyContext';

const MobileWrapper = styled.div`
  box-shadow: ${props => props.theme.boxShadow.surface1};
  display: none;
  flex-direction: row;

  @media (max-width: 768px) {
    display: flex;
    top: 0px;
    background: ${props => props.theme.brand.headerBarGradiant};
    z-index: ${UILayers.SiteMenu};
    width: 100%;
    max-height: 52px;
    position: sticky;
  }
`;

const Wrapper = styled.div`
  background: ${props => props.theme.brand.headerBarGradiant};
  position: relative;
  height: 72px;
  display: flex;
  flex-direction: row;
  box-shadow: ${props => props.theme.boxShadow.surface1};
  z-index: ${UILayers.SiteMenu};

  @media (max-width: 768px) {
    display: none;
  }
`;

const AppIcon = styled.div`
  display: flex;
  margin-left: ${props => (props.theme.customThemeName === CustomThemeName.LightCast ? '20px' : '24px')};
  margin-right: ${props => props.theme.customSpacing.px.s}px;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  img {
    height: ${props => (props.theme.customThemeName === CustomThemeName.LightCast ? '32px' : '20px')};
  }

  @media (max-width: 768px) {
    margin-left: 5px;
    margin-right: auto;
    height: 48px;
  }
`;

const AccountLink = styled.div<{ $isDisabled? }>`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 8px 16px;
  letter-spacing: 0.02em;
  position: relative;
  color: ${props => props.theme.colors.text.onHeaderBar};

  &:hover {
    cursor: ${props => (props.$isDisabled ? 'default' : 'pointer')};
  }

  & .menu-subtitle {
    color: ${props => props.theme.colors.text.onHeaderBarAlt};
  }
`;

const AppLink = styled(Link)`
  display: flex;
  background: none !important;
  border: none;
  padding: 0 !important;
  align-items: center;
  color: ${props => props.theme.colors.text.onHeaderBar};
  cursor: pointer;
`;

const Cover = styled.div<{ $enableModal: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: ${UILayers.Cover};
  display: ${props => (props.$enableModal ? 'initial' : 'none')};
`;

const HeaderNavMenu = styled.div`
  color: ${props => props.theme.colors.text.onHeaderBar};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
`;

const SubNavWrapper = styled.div`
  background-color: ${props => props.theme.colors.surface.default};

  @media (max-width: 768px) {
    display: none;
  }
`;

const HeaderBar = (): React.ReactElement => {
  const theme = useTheme();
  const navigate = useNavigate();
  const gradientMobileBackgroundColor = `${theme.brand.headerBarGradiant}`;
  const singleMobileBackgroundColor = `${theme.brand.headerBarColor1}`;

  const { loggedIn, loggingIn, isAnalyst, isAdmin, isEmsiAdmin, isCustomerSupport } = UseAuthContext();
  const { companyName } = useCompanyContext();
  const { userDetails } = useUserContext();
  const [showMyAccountMenu, setShowMyAccountMenu] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState('');

  const handleLogin = () => {
    navigate('/sign-in');
  };

  const homeLink = () => {
    if (!loggedIn) {
      return '/';
    } else if (isAnalyst()) {
      return '/insights/home';
    } else {
      return '/home';
    }
  };

  // Check path for route with wildcard so we can turn the path into the module name
  const activeModule = useMemo(() => {
    if (location.pathname.match(/^\/admin\/ui-library/i)) {
      return '';
    } else if (location.pathname.match(/^\/admin\/admin-tools/i)) {
      return '';
    } else if (location.pathname.match(/^\/admin\/*/i) || location.pathname.match('/event-logs')) {
      return 'settings';
    } else if (location.pathname.match(/^\/dei\/*/i)) {
      return 'DEI';
    } else {
      return '';
    }
  }, [location.pathname]);

  // Get the routes and active route for the current path
  const currentModuleRoutes = useMemo(() => {
    let routes = [] as any[];
    let currentRoute = '';
    [routes, currentRoute] = RoutesHelper.getSubNavRoutes(
      activeModule,
      { isAdmin, isEmsiAdmin, isCustomerSupport },
      location.pathname
    );
    if (!currentRoute || currentRoute.length === 0) {
      if (routes && routes[0].displayName) {
        setActiveRoute(routes[0].displayName);
      } else {
        setActiveRoute('');
      }
    } else {
      setActiveRoute(currentRoute);
    }
    return routes;
  }, [activeModule]);

  return (
    <>
      <Cover $enableModal={showMyAccountMenu} onClick={() => setShowMyAccountMenu(false)} />
      <ToastContainer position="top-right" autoClose={10000} hideProgressBar={false} pauseOnHover />
      <MobileWrapper
        id="mobile-nav"
        style={{ background: showMobileNav ? singleMobileBackgroundColor : gradientMobileBackgroundColor }}
      >
        <AppIcon>
          <AppLink to={homeLink()} style={{ fontSize: '1rem' }}>
            <img
              src={theme.brand.svgLogo}
              alt={'App Logo'}
              onError={e => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = '/favicon.png';
                e.currentTarget.style.borderRadius = '30px';
              }}
            />
          </AppLink>
        </AppIcon>
        {
          <NavBar
            showing={showMobileNav}
            setShowing={setShowMobileNav}
            moduleRoutes={currentModuleRoutes}
            activeModule={activeModule}
            route={activeRoute}
          />
        }
        {loggedIn &&
          (!showMobileNav ? (
            <HeaderNavMenu onClick={() => setShowMobileNav(true)}>
              <BaseIcon
                type={IconType.Menu}
                colorFilter={IconColorFilter.OnHeaderBar}
                size="13px"
                marginRight={SpacingSizes.XXS}
              />
              <Typography>Menu</Typography>
            </HeaderNavMenu>
          ) : (
            <HeaderNavMenu onClick={() => setShowMobileNav(false)}>
              <BaseIcon
                type={IconType.Close}
                colorFilter={IconColorFilter.OnHeaderBar}
                size="13px"
                marginRight={SpacingSizes.XXS}
              />
              <Typography>Close</Typography>
            </HeaderNavMenu>
          ))}
      </MobileWrapper>
      <Wrapper>
        <AppIcon>
          <img
            onClick={() => navigate(homeLink())}
            src={theme.brand.svgLogo}
            alt={'App Logo'}
            onError={e => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = '/favicon.png';
              e.currentTarget.style.borderRadius = '30px';
            }}
          />
        </AppIcon>
        {!showMobileNav && (
          <div id="desktop-nav" style={{ display: 'flex' }}>
            <NavBar
              activeModule={activeModule}
              showing={false}
              setShowing={(): void => {
                throw new Error('Function not implemented.');
              }}
              moduleRoutes={[]}
              route={''}
            />
          </div>
        )}
        {loggedIn && (
          <AccountLink data-cy="wrapper-account-link">
            <div
              style={{ display: 'flex' }}
              onClick={() => {
                setShowMyAccountMenu(!showMyAccountMenu);
              }}
            >
              <Avatar alt={companyName} src={companyName} />
              <div
                data-cy="menu-my-account"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  marginLeft: `${theme.customSpacing.px.s}px`,
                  width: 'fit-content',
                }}
              >
                <Typography
                  data-cy="user-name"
                  variant="body"
                  sx={{ height: '20px' }}
                >{`${userDetails.first_name} ${userDetails.last_name}`}</Typography>
                <Typography data-cy="app-name" variant="caption" className="menu-subtitle">
                  Talent Transform
                </Typography>
              </div>
              <BaseIcon
                type={IconType.ChevronDown}
                colorFilter={IconColorFilter.OnHeaderBar}
                size="8px"
                marginLeft={SpacingSizes.S}
                onIconClick={() => {
                  setShowMyAccountMenu(!showMyAccountMenu);
                }}
              />
            </div>
            {showMyAccountMenu && <MyAccountDropDown />}
          </AccountLink>
        )}
        {!loggedIn && (
          <AccountLink $isDisabled={loggingIn}>
            <Button
              className="login-button"
              variant="text"
              disabled={loggingIn}
              onClick={handleLogin}
              data-cy="login-button"
            >
              Login
            </Button>
          </AccountLink>
        )}
      </Wrapper>
      {!showMobileNav && (
        <SubNavWrapper>
          <SubNav
            moduleRoutes={currentModuleRoutes}
            moduleTitle={activeModule}
            activeRoute={activeRoute}
            showing={!showMobileNav}
            dataCy="mobile-sub-nav"
          ></SubNav>
        </SubNavWrapper>
      )}
    </>
  );
};

export default HeaderBar;
