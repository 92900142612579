import React, { ReactElement, useEffect, useState } from 'react';
import IntegrationService from 'services/IntegrationService';
import { JobProfile } from 'types/api_responses';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import useCompanyContext from 'helpers/UseCompanyContext';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import IntegrationOption from 'components/molecules/IntegrationOption';
import useGetCompanyVendorIntegrations, { IntegrationOptionType } from 'helpers/useGetCompanyVendorIntegrationOptions';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { CircularProgress } from '@mui/material';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${props => props.theme.customSpacing.px.m}px;
`;

const JobProfilesSection = styled.div`
  height: 100%;
  margin: ${props => props.theme.customSpacing.px.m}px;
`;

const IntegrationSettingsPage = (): ReactElement => {
  const { companyId } = useCompanyContext();
  const [options, setOptions] = useState<IntegrationOptionType[]>([]);
  const [loadingJobProfiles, setLoadingJobProfiles] = useState<boolean>();
  const [jobProfiles, setJobProfiles] = useState<JobProfile[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { getCompanyIntegrations } = useGetCompanyVendorIntegrations();

  useEffect(() => {
    (async () => {
      if (options.length > 0) {
        setLoadingJobProfiles(true);
        const jps = await IntegrationService.fetchRolesToImport(companyId, options[0].vendorId);
        setJobProfiles(jps);
        setLoadingJobProfiles(false);
      }
    })();
  }, [companyId, options]);

  useEffect(() => {
    (async () => {
      const integrationOptions = await getCompanyIntegrations();
      setOptions(integrationOptions);
    })();
  }, [companyId]);

  const importRolesHandler = async vendorId => {
    let vendorName = '';
    setOptions(prevState => {
      const newState = prevState.map(option => {
        if (option.vendorId === vendorId) {
          vendorName = option.vendorName;
          return {
            ...option,
            loading: true,
          };
        }
        return option;
      });
      return newState;
    });
    const response = await IntegrationService.importRoles(companyId, vendorId);
    if (response.status === 'error') {
      alert(response.errorMessage);
    } else {
      alert(`${response.count} roles imported from ${vendorName} integration`);
    }

    setOptions(prevState => {
      return prevState.map(option => {
        return {
          ...option,
          loading: false,
        };
      });
    });
  };

  const getColumnNames = (jobProfile?: JobProfile): GridColDef<JobProfile>[] => {
    const camelCaseToHumanReadable = str => str.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());

    return Object.keys(jobProfile || {}).map((k: string) => ({
      field: k,
      headerName: camelCaseToHumanReadable(k),
      flex: 1,
      valueGetter: param => {
        if (typeof param.value === 'object') return JSON.stringify(param.value);
        return param.value;
      },
    }));
  };

  return (
    <>
      <MainContentTemplate pageTitle="Integration Settings" isFullWidth={true}>
        <ContentHeaderTemplate contentTitle="Roles Integration" fullWidthDivider={false} displayDivider={true} />
        <Wrapper>
          {options.length <= 0 && <div>Loading integration options...</div>}
          {options.length > 0 &&
            options.map((option, index) => {
              return (
                <IntegrationOption
                  key={index}
                  id={option.vendorId}
                  title={option.vendorName}
                  loading={option.loading}
                  loadingIndicator="importing..."
                  description={`Import Roles from ${option.vendorName} integration`}
                  onClickHandler={importRolesHandler}
                  dataCy={`integration-${option.id}-roles`}
                  buttonText="Import"
                />
              );
            })}
        </Wrapper>
        <JobProfilesSection>
          <Typography variant="h3">Job Profiles to be imported as Roles</Typography>
          {!loadingJobProfiles && jobProfiles && jobProfiles?.length > 0 && (
            <>
              <DataGrid
                rows={jobProfiles || []}
                columns={getColumnNames(jobProfiles[0])}
                getRowId={jobProfile => `${jobProfile.jobCode}-${Math.floor(Math.random() * jobProfiles.length)}`}
                loading={loadingJobProfiles}
                page={currentPage}
                rowsPerPageOptions={[10, 50, 100]}
                pageSize={rowsPerPage}
                paginationMode="server"
                onPageChange={page => setCurrentPage(page)}
                onPageSizeChange={pageSize => setRowsPerPage(pageSize)}
                rowCount={jobProfiles?.length || 0}
                autoHeight={true}
                disableSelectionOnClick
              />
            </>
          )}
          {loadingJobProfiles && <CircularProgress />}
          {jobProfiles && jobProfiles?.length === 0 && (
            <>
              <br />
              <Typography variant="caption">No roles to import</Typography>
            </>
          )}
        </JobProfilesSection>
      </MainContentTemplate>
    </>
  );
};

export default IntegrationSettingsPage;
