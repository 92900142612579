import React, { useState, useContext, ReactElement } from 'react';
import { Auth } from '@aws-amplify/auth';
import styled from 'styled-components';
import InputBar from 'components/molecules/InputBar';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from 'contexts/AuthenticationContext';
import PasswordRequirements from 'components/atoms/PasswordRequirements';
import PasswordHelper from 'helpers/passwordHelper';
import ReactGA from 'react-ga';
import FormPartLabel from 'components/atoms/formElements/FormPartLabel';
import InputRow from 'components/atoms/InputRow';
import LoadingButton from '@mui/lab/LoadingButton';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { IconColorFilter } from 'types/types';
import TermsOfServiceStatement from 'components/organisms/TermsOfServiceStatement';
import Typography from '@mui/material/Typography';
import ContentPlaceHolder from 'pages/templates/ContentPlaceHolder';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${props => props.theme.customSpacing.px.s}px;
`;

const PasswordResetBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;
  max-width: 600px;

  @media (max-width: 768px) {
    width: 75%;
    max-width: 375px;
  }
`;

const Title = styled.h2`
  margin: 3em auto 2.5em;
  font-weight: 700;
  color: #222;
`;

const PasswordResetRequiredPage = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const qsParams = queryString.parse(location.search);
  const [email, setEmail] = useState(qsParams.email as string);
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showTempPassword, setShowTempPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [tempPassword, setTempPassword] = useState(qsParams.tempPwd as string);
  const [isPasswordsMatch, setIsPasswordsMatch] = useState(true);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isTosAccepted, setIsTosAccepted] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [authContext, setAuthContext] = useContext(AuthenticationContext);

  const isFormValid = !!email && isPasswordValid && isPasswordsMatch && isTosAccepted && tempPassword;

  const recordAnalytics = (label: string) => {
    ReactGA.event({
      category: 'password reset required',
      action: 'required reset attempted',
      label,
    });
  };

  const handleForceReset = async (event: any) => {
    event.preventDefault();

    // Check for page validations
    if (newPassword !== newPasswordConfirm) {
      alert('Your passwords must match.');
      return;
    }

    setIsConfirming(true);

    try {
      // Check "have I been pwned"
      await PasswordHelper.checkIfPasswordIsCompromised(newPassword);

      const user = await Auth.signIn(email, tempPassword);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(user, newPassword);

        navigate('/insights/home', { replace: true });

        recordAnalytics('success - logged in');
        recordAnalytics('terms of service accepted');
      } else {
        // User not in expected state error
        recordAnalytics('user not in expected state');
        alert('Password Reset Failed.');
        return;
      }
    } catch (error: any) {
      const cause = error.code || error.name;
      if (cause === 'UserNotFoundException') {
        recordAnalytics('unknown user');
        alert('Password Reset Failed: unknown user');
      } else if (cause === 'CompromisedPasswordException') {
        recordAnalytics('compromised password');
        alert(`Password Reset Failed: ${error.message}`);
      } else if (cause === 'NotAuthorizedException') {
        // This could also mean the user's access has been disabled or the temp pw has expired
        recordAnalytics('incorrect temp password');
        alert('Password Reset Failed: incorrect temporary password');
      }
    }
    setIsConfirming(false);
  };

  const validatePasswordMatch = () => {
    setIsPasswordsMatch(newPassword === newPasswordConfirm);
  };

  return (
    <>
      <ContentPlaceHolder pageTitle={'Password Reset Required'}>
        <Wrapper className="content-wrapper">
          <PasswordResetBody>
            <Title>Password Reset</Title>
            <>
              <form onSubmit={handleForceReset}>
                {!qsParams.email && (
                  <InputRow>
                    <FormPartLabel>Your Email Address</FormPartLabel>
                    <InputBar
                      key="emailInput"
                      required
                      name="email"
                      id="email"
                      onChange={e => setEmail(e.target.value)}
                      placeholder="Email Address"
                      type="email"
                      value={email}
                      autoFocus={true}
                      tabIndex={1}
                    />
                  </InputRow>
                )}
                {!qsParams.tempPwd && (
                  <InputRow>
                    <FormPartLabel>Temporary Password</FormPartLabel>
                    <div style={{ marginLeft: '100%' }}>
                      <span style={{ position: 'absolute', margin: '12px 5px 0px 5px' }}>
                        {showTempPassword ? (
                          <BaseIcon
                            type={IconType.EyeOff}
                            size="20px"
                            colorFilter={IconColorFilter.Subdued}
                            onIconClick={() => setShowTempPassword(!showTempPassword)}
                            tabIndex={-1}
                          />
                        ) : (
                          <BaseIcon
                            type={IconType.Eye}
                            size="20px"
                            colorFilter={IconColorFilter.Subdued}
                            onIconClick={() => setShowTempPassword(!showTempPassword)}
                            tabIndex={-1}
                          />
                        )}
                      </span>
                    </div>
                    <InputBar
                      key="tempPasswordInput"
                      required
                      type={showTempPassword ? 'text' : 'password'}
                      placeholder="Temporary Password"
                      onChange={e => setTempPassword(e.target.value)}
                      value={tempPassword}
                      tabIndex={2}
                    />
                  </InputRow>
                )}
                <InputRow>
                  <FormPartLabel>New Password</FormPartLabel>
                  <div style={{ marginLeft: '100%' }}>
                    <span style={{ position: 'absolute', margin: '12px 5px 0px 5px' }}>
                      {showPassword ? (
                        <BaseIcon
                          type={IconType.EyeOff}
                          size="20px"
                          colorFilter={IconColorFilter.Subdued}
                          onIconClick={() => setShowPassword(!showPassword)}
                          tabIndex={-1}
                        />
                      ) : (
                        <BaseIcon
                          type={IconType.Eye}
                          size="20px"
                          colorFilter={IconColorFilter.Subdued}
                          onIconClick={() => setShowPassword(!showPassword)}
                          tabIndex={-1}
                        />
                      )}
                    </span>
                  </div>
                  <InputBar
                    key="passwordInput1"
                    required
                    type={showPassword ? 'text' : 'password'}
                    placeholder="New Password"
                    onChange={e => setNewPassword(e.target.value)}
                    value={newPassword}
                    onFocus={() => setShowPasswordRequirements(true)}
                    tabIndex={3}
                  />
                  <PasswordRequirements
                    setValid={setIsPasswordValid}
                    password={newPassword}
                    show={showPasswordRequirements && newPassword.length > 0 && !isPasswordValid}
                  />
                </InputRow>
                <InputRow>
                  <FormPartLabel>Confirm New Password</FormPartLabel>
                  <InputBar
                    key="passwordInput2"
                    required
                    type="password"
                    placeholder="Confirm New Password"
                    onChange={e => {
                      setNewPasswordConfirm(e.target.value);
                    }}
                    onBlur={validatePasswordMatch}
                    value={newPasswordConfirm}
                    tabIndex={4}
                  />
                  {!isPasswordsMatch && (
                    <Typography paragraph={true} variant="strong" color="text.critical">
                      Passwords to not match
                    </Typography>
                  )}
                </InputRow>
                <InputRow>
                  <TermsOfServiceStatement
                    agreementStatusHandler={() => setIsTosAccepted(!isTosAccepted)}
                    submitButtonName="Change Password and Login"
                    agreementStatus={isTosAccepted}
                    tabIndex={5}
                  />
                </InputRow>
                <LoadingButton
                  loading={isConfirming}
                  disabled={!isFormValid}
                  type="submit"
                  sx={{ width: '100%' }}
                  tabIndex={0}
                >
                  Change Password and Login
                </LoadingButton>
              </form>
            </>
          </PasswordResetBody>
        </Wrapper>
      </ContentPlaceHolder>
    </>
  );
};

export default PasswordResetRequiredPage;
