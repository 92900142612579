import React, { ReactElement } from 'react';
import styled from 'styled-components';

type CheckBoxProps = {
  name?: string;
  value?: any;
  displayValue?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children?: any;
  checked: boolean;
  disabled?: boolean;
  dataCy: string;
  tabIndex?: number;
};

const CheckboxContainer = styled.label`
  cursor: pointer;
  display: block;
  font-weight: 500;
  min-height: 20px;
  min-width: 20px;
  line-height: 20px;
  position: relative;
  user-select: none;

  &:hover input ~ .checkmark {
    border-color: ${props => props.theme.colors.border.hovered};
  }

  &:focus-within .checkmark {
    border-radius: ${props => props.theme.borderRadius.default};
    box-shadow: ${props => props.theme.boxShadow.buttonFocus};
    outline: none;
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: ${props => props.theme.colors.interactive.default};
      border-color: ${props => props.theme.colors.interactive.default};
      box-shadow: none;

      &:after {
        display: block;
      }
    }
  }
`;

const CheckboxBox = styled.span<{ disabled: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: ${props => props.theme.borderRadius.default};
  background-color: ${props => props.theme.colors.surface.default};
  border: 2px solid ${props => props.theme.colors.border.default};
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);

  ${({ disabled }) => disabled && 'opacity: 0.33;'}

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const ChildrenContainer = styled.div`
  padding-left: 28px;
  line-height: 1.25rem;
`;

const CheckBox = ({
  name,
  value,
  displayValue,
  onChange,
  children,
  checked,
  disabled = false,
  dataCy = 'checkBox',
  tabIndex = 0,
}: CheckBoxProps): ReactElement => {
  const keyUpAction = e => {
    if (e.code.toLowerCase() === 'enter' || e.code.toLowerCase() === 'space') {
      onChange(e);
    }
  };

  return (
    <CheckboxContainer>
      <input
        name={name}
        type="checkbox"
        value={value}
        data-displayvalue={displayValue}
        onChange={onChange}
        onKeyUp={keyUpAction}
        checked={checked}
        disabled={disabled}
        data-cy={dataCy}
        tabIndex={tabIndex}
      />
      <CheckboxBox className="checkmark" disabled={disabled} />
      {children && <ChildrenContainer>{children}</ChildrenContainer>}
    </CheckboxContainer>
  );
};

export default CheckBox;
