import React, { ReactElement, useState } from 'react';
import { useField } from 'formik';
import styled, { useTheme } from 'styled-components';
import ReactTagInput from '@pathofdev/react-tag-input';
import RequiredFieldIndicator from 'components/atoms/RequiredFieldIndicator';
import cloneDeep from 'lodash/cloneDeep';
import last from 'lodash/last';
import uniq from 'lodash/uniq';
import xor from 'lodash/xor';
import PropTypes from 'prop-types';
import FormFieldErrorMessage from 'components/atoms/formElements/FormFieldErrorMessage';
import InputRow from 'components/atoms/InputRow';
import FormPartLabel from 'components/atoms/formElements/FormPartLabel';
import Skeleton from '@mui/material/Skeleton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { IconColorFilter, SpacingSizes } from 'types/types';

const PopoverIconContainer = styled.div`
  display: flex;
  div {
    cursor: pointer;
  }
`;

interface FormikBubbleInputFieldProps {
  tabIndex?: number;
  id?: string;
  label: string;
  name: string;
  initialValues: Array<any>;
  setFieldValue(fieldNameChanged: string, bubbleInputs: Array<any>): void;
  loading: boolean | undefined;
  dataCy: string;
  tooltipText?: string;
  tooltipIconColor?: string;
  required?: boolean;
}

const FormikBubbleInputField = ({
  tabIndex = 0,
  label,
  id,
  name,
  setFieldValue,
  initialValues = [],
  loading,
  dataCy,
  tooltipText,
  tooltipIconColor = 'Default',
  required = false,
}: FormikBubbleInputFieldProps): ReactElement => {
  const [tooltipIconAnchor, setTooltipIconAnchor] = useState(null);
  const [field, meta] = useField(name);

  const theme = useTheme();

  const tagChangeHandler = tags => {
    tags = uniq(tags);
    const updatedTags = cloneDeep(field.value || []);

    if (!updatedTags || tags.length > updatedTags.length) {
      const tagToAdd = last(tags);
      const tagIndex = initialValues.findIndex(x => x.name === tagToAdd);

      if (tagIndex !== -1) {
        updatedTags.splice(tagIndex, 0, initialValues.at(tagIndex));
      } else {
        updatedTags.splice(tags.length - 1, 0, { name: tagToAdd });
      }
    } else if (tags.length < updatedTags.length) {
      const tagToRemove = xor(
        tags,
        updatedTags.map(x => x.name)
      )[0];
      const tagIndex = updatedTags.findIndex(x => x.name === tagToRemove);

      if (tagIndex !== -1) {
        updatedTags.splice(tagIndex, 1);
      }
    }

    setFieldValue(name, updatedTags);
  };

  const handlePopoverOpen = event => {
    setTooltipIconAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setTooltipIconAnchor(null);
  };

  return (
    <>
      <InputRow className="input-row" data-cy={dataCy || 'bubble-input-field'}>
        <FormPartLabel htmlFor={id || name} tabIndex={tabIndex} style={{ display: 'flex', flexDirection: 'row' }}>
          {label}
          {tooltipText && (
            <PopoverIconContainer
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              data-cy={`tooltip-icon-${label}`}
            >
              <BaseIcon
                type={IconType.InformationFill}
                marginLeft={SpacingSizes.Base}
                marginRight={SpacingSizes.None}
                colorFilter={IconColorFilter[`${tooltipIconColor}`]}
              />
            </PopoverIconContainer>
          )}
          {required && <RequiredFieldIndicator />}
        </FormPartLabel>
        {loading ? (
          <Skeleton width={'100%'}>
            <ReactTagInput tags={[]} onChange={() => undefined} />
          </Skeleton>
        ) : (
          <ReactTagInput
            tags={(field.value && field.value.map(f => f.name)) || []}
            onChange={tagChangeHandler}
            removeOnBackspace={true}
          />
        )}
        {meta.touched && meta.error ? <FormFieldErrorMessage errorMessage={meta.error} /> : null}
      </InputRow>
      <Popover
        anchorEl={tooltipIconAnchor}
        open={!!tooltipIconAnchor}
        onClose={handlePopoverClose}
        sx={{ pointerEvents: 'none' }}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography
          variant={'caption'}
          sx={{ maxWidth: '220px', padding: `${theme.customSpacing.px.xs}px` }}
          component={'div'}
          data-cy={`tooltip-message-${label}`}
        >
          {tooltipText}
        </Typography>
      </Popover>
    </>
  );
};

FormikBubbleInputField.propTypes = {
  label: PropTypes.string,
  setFieldValue: PropTypes.func,
  id: PropTypes.any,
  name: PropTypes.any,
};

export default FormikBubbleInputField;
