import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';

type RectangleProps = {
  widthPercentage: number;
  heightInPx: number;
  color: string;
};

const Rectangle = ({ widthPercentage, heightInPx, color }: RectangleProps): ReactElement => {
  const theme = useTheme();

  return (
    <div
      style={{
        width: `${widthPercentage}%`,
        height: `${heightInPx}px`,
        backgroundColor: color,
        borderRadius: theme.borderRadius.large,
      }}
    />
  );
};

export default Rectangle;
