import React, { ReactElement, ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

type ContentColumn2TemplateProps = {
  mainComponent?: ReactNode;
  sideComponent?: ReactNode;
  actionComponent?: ReactNode;
  mainComponentPosition?: 'left' | 'right';
  paddingVariant?: 'narrow' | 'wide' | 'narrow-sideNone';
  breakpoint?: number;
  flexDirection?: 'row' | 'column';
};

const Wrapper = styled(Grid)`
  justify-content: space-between;
  align-items: stretch;
`;

const MainColumn = styled(Grid)<{ $breakpoint: number }>`
  @media (max-width: ${props => props.$breakpoint - 1}px) {
    padding: 0px 0px ${props => props.theme.customSpacing.px.s}px;
  }
`;

const SideColumn = styled(Grid)<{ $breakpoint: number }>`
  @media (max-width: ${props => props.$breakpoint - 1}px) {
    padding: 0px 0px ${props => props.theme.customSpacing.px.s}px 0px;
  }
`;

const DividerVerticalWrapper = styled.div<{ $breakpoint: number }>`
  display: flex;

  @media (max-width: ${props => props.$breakpoint - 1}px) {
    display: none;
  }
`;

const DividerHorizontalWrapper = styled.div<{ $breakpoint: number }>`
  padding-bottom: ${props => props.theme.customSpacing.px.s}px;

  @media (min-width: ${props => props.$breakpoint}px) {
    display: none;
  }
`;

const BottomActionComponent = styled(Grid)``;

function ContentColumn2Template({
  mainComponent,
  sideComponent = null,
  actionComponent = null,
  mainComponentPosition = 'left',
  paddingVariant = 'narrow',
  breakpoint = 900,
  flexDirection = 'row',
}: ContentColumn2TemplateProps): ReactElement {
  const theme = useTheme();

  let mainPadding = 0;
  let sidePadding = 0;

  switch (paddingVariant) {
    case 'narrow':
      if (flexDirection === 'row') {
        mainPadding = theme.customSpacing.px.base * 8;
        sidePadding = theme.customSpacing.px.base * 8;
      }
      break;
    case 'wide':
      if (flexDirection === 'row') {
        mainPadding = theme.customSpacing.px.base * 11;
        sidePadding = theme.customSpacing.px.base * 11;
      }
      break;
    case 'narrow-sideNone':
      if (flexDirection === 'row') {
        mainPadding = theme.customSpacing.px.base * 8;
        sidePadding = theme.customSpacing.px.base * 0;
      }
      break;
  }

  return (
    <Wrapper
      container
      direction={mainComponentPosition === 'left' ? `${flexDirection}` : `${flexDirection}-reverse`}
      columns={12.05}
    >
      <MainColumn
        className="main-content"
        item
        xs={12}
        md={7}
        sx={{
          padding: `0px
              ${mainComponentPosition === 'left' ? mainPadding : 0}px
              0px
              ${mainComponentPosition === 'left' ? 0 : mainPadding}px`,
        }}
        $breakpoint={breakpoint}
      >
        {mainComponent}
      </MainColumn>
      <DividerVerticalWrapper className="divider-wrapper-vertical" $breakpoint={breakpoint}>
        <Divider orientation="vertical" variant="middle" flexItem={true} />
      </DividerVerticalWrapper>
      <DividerHorizontalWrapper className="divider-wrapper-horizontal" $breakpoint={breakpoint}>
        <Divider />
      </DividerHorizontalWrapper>
      <SideColumn
        className="side-content"
        item
        xs={12}
        md={5}
        sx={{
          padding: `0px
              ${mainComponentPosition === 'left' ? 0 : sidePadding}px
              0px
              ${mainComponentPosition === 'left' ? sidePadding : 0}px`,
        }}
        $breakpoint={breakpoint}
      >
        {sideComponent}
      </SideColumn>
      {actionComponent && (
        <BottomActionComponent item xs={12} sx={{ padding: '0px 0px 0px 0px' }}>
          {actionComponent}
        </BottomActionComponent>
      )}
    </Wrapper>
  );
}

export default ContentColumn2Template;
