import React, { ReactElement } from 'react';
import styled from 'styled-components';
import SubNavLink from 'components/atoms/SubNavLink';

const SubNavMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: top 0.5s ease-in-out;
  background-color: ${props => props.theme.colors.surface.default};
  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.2),
    0px 2px 1px rgba(0, 0, 0, 0.05);
`;

type ModuleRoute = {
  displayName: string;
  path: string;
  disabled?: boolean;
};

type SubNavMobileProps = {
  moduleRoutes: ModuleRoute[];
  activeRoute: string;
};

const SubNavMobile = ({ moduleRoutes, activeRoute }: SubNavMobileProps): ReactElement => {
  // Dynamically render the UI
  return (
    <SubNavMobileContainer data-cy="subnav-mobile-container">
      {moduleRoutes?.map(route => {
        return (
          <SubNavLink
            key={route.displayName}
            text={route.displayName}
            url={route.path}
            active={route.displayName === activeRoute}
            disabled={route.disabled}
            dataCy={`subnav-mobile-link-${route.displayName}`}
            mobileOnly={true}
          />
        );
      })}
    </SubNavMobileContainer>
  );
};

export default SubNavMobile;
