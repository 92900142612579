import React, { ReactElement, useEffect, useState } from 'react';
import useGetCompanyVendorIntegrations, { IntegrationOptionType } from 'helpers/useGetCompanyVendorIntegrationOptions';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import IntegrationOption from 'components/molecules/IntegrationOption';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import IntegrationService from 'services/IntegrationService';
import useCompanyContext from 'helpers/UseCompanyContext';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Role from 'classes/role';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${props => props.theme.customSpacing.px.m}px;
`;

const JobProfilesSection = styled.div`
  height: 100%;
  margin: ${props => props.theme.customSpacing.px.m}px;
`;

const IntegrationSettingsPage = (): ReactElement => {
  const { companyId } = useCompanyContext();
  const navigate = useNavigate();
  const [options, setOptions] = useState<IntegrationOptionType[]>([]);
  const [loadingRoles, setloadingRoles] = useState<boolean>();
  const [roles, setRoles] = useState<Role[]>();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { getCompanyIntegrations } = useGetCompanyVendorIntegrations();

  useEffect(() => {
    (async () => {
      const integrationOptions = await getCompanyIntegrations();
      setOptions(integrationOptions);
    })();
  }, [companyId]);

  useEffect(() => {
    (async () => {
      if (options.length > 0) {
        setloadingRoles(true);
        const { roles } = await IntegrationService.fetchRolesToExport(companyId, options[0].vendorId); // add pagination here?
        setRoles(roles);
        setloadingRoles(false);
      }
    })();
  }, [companyId, options]);

  const exportRolesHandler = async vendorId => {
    setOptions(prevState => {
      const newState = prevState.map(option => {
        if (option.vendorId === vendorId) {
          return {
            ...option,
            loading: true,
          };
        }
        return option;
      });
      return newState;
    });
    await IntegrationService.exportRoles(companyId, vendorId)
      .then(message => {
        toast.success(SuccessfulFileProcessedToast(message));
      })
      .catch(error => {
        console.log('Error exporting roles', error);
        toast.error(FailFileProcessedToast('Error exporting roles'));
      });

    setOptions(prevState => {
      return prevState.map(option => {
        return {
          ...option,
          loading: false,
        };
      });
    });
  };

  const SuccessfulFileProcessedToast = message => (
    <div>
      <span data-cy="file-successfully-processed-toast">
        {message}
        <Button variant="text" onClick={() => navigate(`/admin/processing-statuses`)}>
          Click here to go to processing page
        </Button>
      </span>
    </div>
  );
  const FailFileProcessedToast = message => (
    <div>
      <span data-cy="file-fail-processed-toast">{message}</span>
      <Button variant="text" onClick={() => navigate(`/admin/processing-statuses`)}>
        Click here to go to processing page
      </Button>
    </div>
  );

  const getColumnNames = (): GridColDef<Role>[] => {
    const camelCaseToHumanReadable = str => str.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
    const filteredColumns = ['roleName', 'jobTitle', 'jobFamily', 'jobFunction', 'jobCode', 'skillsCount'];
    return filteredColumns.map((k: string) => ({
      field: k,
      headerName: camelCaseToHumanReadable(k),
      flex: 1,
      headerAlign: k === 'skillsCount' ? 'center' : 'left',
      align: k === 'skillsCount' ? 'center' : 'left',
      valueGetter: param => {
        if (typeof param.value === 'object') return JSON.stringify(param.value);
        if (k === 'skillsCount') return param.row.skills.length;
        return param.value;
      },
    }));
  };

  return (
    <>
      <MainContentTemplate pageTitle="Integration Settings" isFullWidth={true}>
        <ContentHeaderTemplate contentTitle="Roles Integration Export" fullWidthDivider={false} displayDivider={true} />
        <Wrapper>
          {options.length <= 0 && <div>Loading integration options...</div>}
          {options.length > 0 &&
            options.map((option, index) => {
              return (
                <IntegrationOption
                  key={index}
                  id={option.vendorId}
                  title={option.vendorName}
                  loading={option.loading}
                  loadingIndicator="exporting..."
                  description={`Export Roles to ${option.vendorName} integration`}
                  onClickHandler={exportRolesHandler}
                  dataCy={`integration-${option.id}-roles-export`}
                  buttonText="Export"
                />
              );
            })}
        </Wrapper>
        <JobProfilesSection>
          <Typography variant="h3">Roles to be exported</Typography>
          {!loadingRoles && roles && roles.length > 0 && (
            <>
              <DataGrid
                rows={roles || []}
                columns={getColumnNames()}
                getRowId={role => `${role.jobCode}-${Math.floor(Math.random() * roles.length)}`}
                loading={loadingRoles}
                page={currentPage}
                rowsPerPageOptions={[10, 50, 100]}
                pageSize={rowsPerPage}
                paginationMode="client"
                onPageChange={page => setCurrentPage(page)}
                onPageSizeChange={pageSize => setRowsPerPage(pageSize)}
                rowCount={roles?.length || 0}
                autoHeight={true}
                disableSelectionOnClick
              />
            </>
          )}
          {loadingRoles && <CircularProgress />}
        </JobProfilesSection>
      </MainContentTemplate>
    </>
  );
};

export default IntegrationSettingsPage;
