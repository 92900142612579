import React, { useState, useEffect } from 'react';
import Role from 'classes/role';
import { ExtractedSkill, SuggestedSkill } from 'types/types';
import useCompanyContext from 'helpers/UseCompanyContext';
import RolesService from 'services/RolesService';

export interface RoleEditContextState {
  previousState: any;
  role?: Role;
  isSkillsAndKeywordSearchEnabled: boolean;
  keywordSearchMatchedTitles?: any[];
  skillsFromDescription?: ExtractedSkill[];
  skillsFromDescriptionNormalized?: SuggestedSkill[];
  skillSearchSkillsList?: SuggestedSkill[];
  skillSearchManualSkills?: SuggestedSkill[];
  isSkillsExtracting?: boolean;
  keywordSearchText: string;
  isLoading?: boolean;
  isSaving?: boolean;
  isCopying?: boolean;
  isModified?: boolean;
  roleChanges?: Role;
  refreshKey?: number;
}

interface RoleEditContextProviderProps {
  roleId?: string | null;
  isCopying?: boolean;
  children?: React.ReactElement;
}

const defaultContext: RoleEditContextState = {
  previousState: undefined,
  role: undefined,
  roleChanges: undefined,
  isSkillsAndKeywordSearchEnabled: false,
  keywordSearchMatchedTitles: undefined,
  skillsFromDescription: undefined,
  skillsFromDescriptionNormalized: undefined,
  skillSearchSkillsList: undefined,
  skillSearchManualSkills: undefined,
  isSkillsExtracting: false,
  keywordSearchText: '',
  isLoading: false,
  isSaving: false,
  isCopying: false,
  isModified: false,
  refreshKey: Math.random(),
};

const RoleEditContext = React.createContext<[RoleEditContextState, React.Dispatch<any>]>([
  defaultContext,
  () => undefined,
]);

const RoleEditContextProvider: React.FC<RoleEditContextProviderProps> = props => {
  const { companyId } = useCompanyContext();

  const [state, setState] = useState<RoleEditContextState>({
    ...defaultContext,
    isCopying: props.isCopying,
  });

  useEffect(() => {
    (async () => {
      if (!props.roleId) {
        setState(defaultContext);
        return;
      }

      setState(state => ({ ...state, isLoading: true }));
      const role = await RolesService.getRole(companyId, props.roleId);

      const roleName = props.isCopying ? `${role.roleName} - copy` : `${role.roleName}`;
      role.roleName = roleName;

      // Handles updating common context props when used inside bulk role edit context
      const updateCommonContextProps = (prevCtxState: RoleEditContextState, currCtxStateRole: Role) => {
        const isSameJobDescription = prevCtxState.role?.description === currCtxStateRole?.description;
        const skillsFromDescription = isSameJobDescription ? prevCtxState.skillsFromDescription : undefined;
        const skillsFromDescriptionNormalized = isSameJobDescription
          ? prevCtxState.skillsFromDescriptionNormalized?.map(skill => {
              return {
                ...skill,
                isSelected: false,
              };
            })
          : undefined;
        return {
          keywordSearchText: '',
          keywordSearchMatchedTitles: undefined,
          skillsFromDescription,
          skillsFromDescriptionNormalized,
          skillSearchSkillsList: skillsFromDescriptionNormalized,
          skillSearchManualSkills: undefined,
        };
      };

      setState(state => {
        return {
          ...state,
          isCopying: props.isCopying,
          isLoading: false,
          isSaving: false,
          role,
          ...updateCommonContextProps(state, role),
        };
      });
    })();
  }, [props.roleId, state.refreshKey]);

  return <RoleEditContext.Provider value={[state, setState]}>{props.children}</RoleEditContext.Provider>;
};

export { RoleEditContext, RoleEditContextProvider };
