import React, { ReactElement } from 'react';
import EmsiUIBadge from 'components/atoms/EmsiUIBadge';
import PipIcon, { PipIconType } from 'components/atoms/PipIcon';
import styled from 'styled-components';
import { EmsiUIBadgeColorPalette } from 'types/types';

type RoleReviewStatusLabelProps = {
  status: string;
};

const StyledEmsiUIBadge = styled(EmsiUIBadge)`
  margin: 0.5rem 0;
`;

const RoleReviewStatusLabel = ({ status }: RoleReviewStatusLabelProps): ReactElement => {
  let statusColor: EmsiUIBadgeColorPalette = EmsiUIBadgeColorPalette.SurfaceNeutral;
  let iconType = PipIconType.empty;
  let label = status;
  switch (status) {
    case 'Low':
      statusColor = EmsiUIBadgeColorPalette.SurfaceCritical;
      iconType = PipIconType.empty;
      label = 'Unreviewed';
      break;
    case 'Medium':
      statusColor = EmsiUIBadgeColorPalette.SurfaceCritical;
      iconType = PipIconType.empty;
      label = 'Unreviewed';
      break;
    case 'High':
      statusColor = EmsiUIBadgeColorPalette.SurfaceCritical;
      iconType = PipIconType.empty;
      label = 'Unreviewed';
      break;
    case 'Reviewed':
      statusColor = EmsiUIBadgeColorPalette.SurfaceSuccess;
      iconType = PipIconType.full;
      break;
    case 'Needs Approval':
      statusColor = EmsiUIBadgeColorPalette.SurfaceWarning;
      iconType = PipIconType.partial;
      break;
    default:
      statusColor = EmsiUIBadgeColorPalette.SurfaceCritical;
      iconType = PipIconType.empty;
      label = 'Unreviewed';
  }

  return (
    <span>
      <StyledEmsiUIBadge color={statusColor} label={label} title={label} icon={<PipIcon type={iconType} />} />
    </span>
  );
};

export default RoleReviewStatusLabel;
