import { createTheme, ThemeOptions } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';
import { chipOverride } from 'theme/ChipOverride';
import { switchStyleOverride } from 'theme/SwitchOverride';
import { muiButtonOverride } from 'theme/MuiButtonOverride';
import { muiLoadingButtonOverride } from 'theme/MuiLoadingButtonOverride';
import { muiLinkOverride } from 'theme/MuiLinkOverride';
import { MuiSelectOverride } from 'theme/MuiSelectOverride';
import { muiDrawerOverride } from 'theme/MuiDrawerOverride';
import { muiCheckboxOverride } from 'theme/MuiCheckboxOverride';
import { muiDataGridOverride } from 'theme/MuiDataGridOverride';
import { muiSkeletonOverride } from 'theme/MuiSkeletonOverride';
import typographyOptionsOverride from 'theme/TypographyOverride';
import customPalette from 'theme/customPalette';
import { DefaultTheme } from 'styled-components';
import { Theme } from '@mui/system';

const theme = (globalStyles: DefaultTheme): Theme => {
  const paletteToUse = customPalette(globalStyles);

  return createTheme({
    typography: typographyOptionsOverride(globalStyles),
    palette: paletteToUse,
    overrides: {
      '@global': {
        '@font-face': globalStyles.customTypography.fontFamily,
      },
    },
    components: {
      MuiTablePagination: {
        styleOverrides: {
          displayedRows: {
            color: paletteToUse.text?.disabled,
          },
        },
      },
      MuiCheckbox: muiCheckboxOverride,
      MuiChip: chipOverride,
      MuiLink: muiLinkOverride,
      MuiLoadingButton: muiLoadingButtonOverride,
      MuiSelect: MuiSelectOverride,
      MuiDrawer: muiDrawerOverride,
      MuiButton: muiButtonOverride(globalStyles),
      MuiDataGrid: muiDataGridOverride(globalStyles),
      MuiSwitch: switchStyleOverride(globalStyles),
      MuiSkeleton: muiSkeletonOverride,
    },
    MuiCheckbox: muiCheckboxOverride,
    MuiChip: chipOverride,
    MuiLink: muiLinkOverride,
    MuiLoadingButton: muiLoadingButtonOverride,
    MuiSwitch: switchStyleOverride,
    MuiSelect: MuiSelectOverride,
    MuiDataGrid: muiDataGridOverride,
    MuiDrawer: muiDrawerOverride,
    MuiButton: muiButtonOverride(globalStyles),
  } as ThemeOptions);
};

export default theme;
