import React, { ReactElement, useState, useEffect } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import useCompanyContext from 'helpers/UseCompanyContext';
import SkillsService from 'services/SkillsService';
import TopCountStats from 'components/molecules/TopCountStats';
import TopSkillsTable from 'components/molecules/TopSkillsTable';
import { TopSkill, GetSkillsStatsResponse } from 'types/types';
import CategoryBreakdownProgressBar from 'components/molecules/CategoryBreakdownProgressBar';
import Divider from '@mui/material/Divider';
import SummaryHeader from 'components/molecules/SummaryHeader';
import TopSkillsTableChart from 'components/organisms/TopSkillsTableChart';

const Wrapper = styled.div`
  padding: ${props => props.theme.customSpacing.px.base * 4}px;
`;

const HorizontalDivider = styled(Divider)`
  border: ${props => `1px solid ${props.theme.colors.border.subdued}`};
  margin-top: ${props => props.theme.customSpacing.px.base * 5}px;
  margin-bottom: ${props => props.theme.customSpacing.px.base * 5}px;
`;

const defaultSkillsStats = {
  skillRoleCounts: {
    totalRolesWithSkills: 0,
    totalUniqueSkills: 0,
    averageSkillsPerRole: 0,
  },
  skillBreakdowns: {
    topSkills: [],
    topSpecializedSkills: [],
    topCertifications: [],
    topCommonSkills: [],
  },
  skillAggregations: {
    skillTypes: [],
    skillCategories: [],
  },
};

const SkillsSummaryPage = (): ReactElement => {
  const { companyId } = useCompanyContext();
  const [isSkillsStatsLoading, setIsSkillsStatsLoading] = useState(false);
  const [skillsStats, setSkillsStats] = useState<GetSkillsStatsResponse>(defaultSkillsStats);
  const [skillRoleCounts, setSkillRoleCounts] = useState<{ title: string; value: string }[]>([]);
  const [skillCategoryAggregates, setSkillCategoryAggregates] = useState<
    GetSkillsStatsResponse['skillAggregations']['skillCategories']
  >(defaultSkillsStats.skillAggregations.skillCategories);
  const [topSkillTypes, setTopSkillTypes] = useState<GetSkillsStatsResponse['skillBreakdowns']>(
    defaultSkillsStats.skillBreakdowns
  );

  useEffect(() => {
    (async () => {
      setIsSkillsStatsLoading(true);
      const skillsStats: GetSkillsStatsResponse = await SkillsService.getSkillStats(companyId);
      setSkillsStats(skillsStats);
      setIsSkillsStatsLoading(false);
    })();
  }, []);

  useEffect(() => {
    setSkillRoleCounts([
      { title: 'Roles with skills', value: skillsStats.skillRoleCounts.totalRolesWithSkills.toLocaleString() },
      { title: 'Total Unique Skills', value: skillsStats.skillRoleCounts.totalUniqueSkills.toLocaleString() },
      { title: 'Average Skills Per Role', value: skillsStats.skillRoleCounts.averageSkillsPerRole.toLocaleString() },
    ]);

    setSkillCategoryAggregates(skillsStats.skillAggregations.skillCategories);

    setTopSkillTypes({
      topSkills: skillsStats.skillBreakdowns.topSkills.map((topSkill: TopSkill, index: number) => {
        return {
          ...topSkill,
          topSkillOrder: index + 1,
        };
      }),
      topSpecializedSkills: skillsStats.skillBreakdowns.topSpecializedSkills,
      topCertifications: skillsStats.skillBreakdowns.topCertifications,
      topCommonSkills: skillsStats.skillBreakdowns.topCommonSkills,
    });
  }, [skillsStats]);

  return (
    <>
      <MainContentTemplate pageTitle="Skills Summary" isFullWidth={false}>
        <ContentHeaderTemplate contentTitle="Skills Summary" />
        <Wrapper>
          {isSkillsStatsLoading && (
            <Typography sx={{ display: 'flex', justifyContent: 'center' }}>loading...</Typography>
          )}
          {!isSkillsStatsLoading && (
            <>
              <TopCountStats items={skillRoleCounts} />
              <HorizontalDivider />
              <SummaryHeader headerTitle="Skill Categories">
                <CategoryBreakdownProgressBar
                  width={'100%'}
                  categoryCountList={skillCategoryAggregates}
                  showLabel={true}
                />
              </SummaryHeader>
              <TopSkillsTable topSkills={topSkillTypes.topSkills} sourceComponent={'skills-summary'} />
              <HorizontalDivider />
              <SummaryHeader headerTitle="Skill Types">
                <TopSkillsTableChart skills={topSkillTypes} />
              </SummaryHeader>
            </>
          )}
        </Wrapper>
      </MainContentTemplate>
    </>
  );
};

export default SkillsSummaryPage;
