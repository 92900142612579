import React, { ReactElement } from 'react';
import EmsiUIBadge from 'components/atoms/EmsiUIBadge';
import styled from 'styled-components';
import { EmsiUIBadgeColorPalette } from 'types/types';
// import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import TitleTagTooltip from 'components/atoms/TitleTagTooltip';
import RolesPageHelper from 'helpers/RolesPageHelper';

type RoleConfidenceLevelLabelProps = {
  level: string;
  value?: number;
  hoveroverTitle?: string;
  prefix?: string;
};

const StyledEmsiUIBadge = styled(EmsiUIBadge)`
  margin: 0.5rem 0;
`;

const RoleConfidenceLevelLabel = ({
  level,
  value,
  hoveroverTitle,
  prefix = '',
}: RoleConfidenceLevelLabelProps): ReactElement => {
  let label: string = level;
  let title: string;
  const statusColor: EmsiUIBadgeColorPalette = RolesPageHelper.RoleConfidenceLevelColor(label);
  switch (label) {
    case 'User Selected':
      label = 'n/a';
      title = 'Mapped title selection was not suggested by Lightcast';
      break;
    case 'No Match':
      title = '';
      break;
    case 'Low':
      title = `Low Confidence ${value ? `- ${Math.floor(value * 100)}%` : ''}`;
      break;
    case 'Medium':
      title = `Medium Confidence ${value ? `- ${Math.floor(value * 100)}%` : ''}`;
      break;
    case 'High':
      title = `High Confidence ${value ? `- ${Math.floor(value * 100)}%` : ''}`;
      break;
    default:
      title = label;
  }

  return (
    <span data-cy="title-confidence-score">
      <TitleTagTooltip
        hoveroverTitle={hoveroverTitle || title}
        content={<StyledEmsiUIBadge color={statusColor} label={prefix + label} />}
      />
    </span>
  );
};

export default RoleConfidenceLevelLabel;
