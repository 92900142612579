import styled, { keyframes, css } from 'styled-components';
import { HtmlHTMLAttributes } from 'react';

interface SurfaceFormProps extends HtmlHTMLAttributes<HTMLSpanElement> {
  'data-highlight-type'?: string;
}
const fadeColorTextToInteractive = props => keyframes`
  from {
    color: ${props.theme.colors.text.default};
  }
  to {
    color: ${props.theme.colors.text.interactive};
  }
`;

const wipeRight = keyframes`
  from {
    right: 100%;
  }
  to {
    right: 0%;
  }
`;

const wipeUp = keyframes`
  from {
    top: 100%;
    bottom: -2px;
  }
  to {
    top: 1px;
    bottom: 1px;
  }
`;

const fadeBgWhiteToGray = props => keyframes`
  from {
    background: ${props.theme.colors.surface.default};
  }
  to {
    background: ${props.theme.colors.surface.neutral};
  }
`;

const fadeColorInteractiveToWhite = props => keyframes`
  from {
    color: ${props.theme.colors.text.interactive};
  }
  to {
    color: ${props.theme.colors.text.default};
  }
`;

const fadeColorTextToWhite = props => keyframes`
  from {
    color: ${props.theme.colors.text.default};
  }
  to {
    color: ${props.theme.colors.text.onPrimary};
  }
`;

const SurfaceForm = styled.span.attrs<SurfaceFormProps>(() => ({
  'data-highlight-type': 'surface-form',
}))`
  ${() => {
    const rand = (Math.random() / 2).toFixed(2);
    return css`
      cursor: pointer;
      display: inline-block;
      position: relative;
      color: ${props => props.theme.colors.text.interactive};
      animation: ${fadeColorTextToInteractive} 0.2s ease forwards;
      animation-delay: ${rand}s;
      transition: background-color ease 0.2s;

      &:after {
        animation: ${wipeRight} 0.2s ease forwards;
        animation-delay: ${rand}s;
        background: ${props => props.theme.colors.text.interactive};
        bottom: 0px;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
      }
    `;
  }}
`;

const SelectedSurfaceForm = styled.span.attrs<SurfaceFormProps>(() => ({
  'data-highlight-type': 'surface-form',
}))`
  animation: ${fadeColorInteractiveToWhite} 0.1s ease forwards;
  display: inline-block;
  margin: 0 -2px;
  padding: 0 2px;
  position: relative;
  z-index: 1;

  &::before {
    animation: ${wipeUp} 0.1s ease forwards;
    background: ${props => props.theme.colors.surface.success};
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
  }
`;

const ContextSurfaceForm = styled(SelectedSurfaceForm).attrs(() => ({
  'data-highlight-type': 'context-surface-form',
}))`
  animation: ${fadeColorTextToWhite} 0.5s ease forwards;

  &::before {
    animation: ${fadeBgWhiteToGray} 0.5s ease forwards;
    background: ${props => props.theme.colors.surface.default};
    bottom: 1px;
    top: 1px;
  }
`;

export { SurfaceForm, SelectedSurfaceForm, ContextSurfaceForm };
