import React from 'react';
import HeaderBar from 'components/organisms/HeaderBar';
import { Helmet } from 'react-helmet-async';
import Footer from 'components/molecules/Footer';

const PrivacyPolicyPage = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <HeaderBar />
      <div style={{ padding: '2em' }}>
        Privacy Policy Here
        <br />
        <a href="https://www.economicmodeling.com/privacy-policy" rel="noopener noreferrer" target="_blank">
          see the Lightcast privacy policy here
        </a>
      </div>
      <div
        style={{
          margin: 'auto',
          textAlign: 'center',
          color: '#999999',
          bottom: '2em',
          position: 'absolute',
          left: '40%',
        }}
      >
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicyPage;
