import React, { useState, useEffect, ReactElement, useMemo } from 'react';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import UseCompanyContext from 'helpers/UseCompanyContext';
import queryString from 'query-string';
import dayjs from 'dayjs';
import TablePagination from 'components/molecules/TablePagination';
import FileProcessingProgressBar from 'components/molecules/FileProcessingProgressBar';
import EmsiUIBadge from 'components/atoms/EmsiUIBadge';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import styled, { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import batchLogService from 'services/batchLogService';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import BatchLogSearchBar from 'components/molecules/BatchLogSearchBar';
import useBatchLogs from 'helpers/useBatchLogs';
import cloneDeep from 'lodash/cloneDeep';
import { BatchDetails, BatchRowLog, EmsiUIBadgeColorPalette } from 'types/types';
import Button from '@mui/material/Button';

const Pagination = styled(Box)`
  box-shadow: ${props => props.theme.boxShadow.surface1};
  padding: 0px ${props => props.theme.customSpacing.px.base * 10}px;
`;

const HeaderSubnavContainer = styled(Box)`
  margin: 5px ${props => props.theme.customSpacing.px.m}px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .complex-input-bar-container {
    max-width: calc(100vw - ${props => props.theme.customSpacing.px.base * 110}px);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: ${props => props.theme.customSpacing.px.xxs}px;

    .MuiTablePagination-toolbar {
      justify-content: space-between;
      padding: 0px;
    }

    .complex-input-bar-container {
      max-width: revert;
    }
  }
`;
const normalizedTitleCell = param => {
  if (param?.value?.emsiTitleId === 'ET0000000000000000') {
    return (
      <EmsiUIBadge
        color="surfaceWarning"
        label="Unclassified"
        title="A valid Lightcast Title could not be found for the job title provided."
      />
    );
  } else {
    return <>{param?.value?.emsiTitleName}</>;
  }
};

const ProcessingDataContainer = styled.div`
  display: flex;
  width: 100%;
  padding: ${props => props.theme.customSpacing.px.xxs}px ${props => props.theme.customSpacing.px.m}px;
`;

const ProcessingDataColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const ProcessingDataItemContainer = styled.div`
  display: flex;
  column-gap: ${props => props.theme.customSpacing.px.xxs}px;
`;

interface PathParamsProps {
  batchId: string;
}

export const BatchProcessingDetailsPage = (): ReactElement => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { batchId } = useParams<keyof PathParamsProps>() as PathParamsProps;
  const qs: any = queryString.parse(window.location.search, { parseNumbers: true });
  const { companyId } = UseCompanyContext();
  const [batchRowLogs, setBatchRowLogs] = useState<BatchRowLog[]>([]);
  const [batchRowLogsCount, setBatchRowLogsCount] = useState(0);
  const [batchDetails, setBatchDetails] = useState<BatchDetails>();
  const [currentPage, setCurrentPage] = useState(qs.p || 0);
  const [rowsPerPage, setRowsPerPage] = useState(qs.n || 10);
  const [loadingBatchDetails, setLoadingBatchDetails] = useState(false);
  const [loadingBatchRowLogs, setLoadingBatchRowLogs] = useState(false);
  const [totalFacets, setTotalFacets] = useState({});
  const [appliedFilters, setAppliedFilters] = useState(JSON.parse(qs.af || '{}') || {});
  const [filter, setFilter] = useState(qs.f?.toString() || '');
  const [isPageChanged, setIsPageChanged] = useState(false);
  const { getBatchLogFacets } = useBatchLogs();

  const handleRowClick = (roleId: string) => {
    navigate(`/role-edit/${roleId}`);
  };

  const columns: GridColDef<BatchRowLog>[] = [
    {
      field: 'rowNumber',
      headerName: 'Line Number',
      flex: 1.5,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'externalId',
      headerName: 'Job Code',
      flex: 2,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: param => {
        return (
          <div style={{ display: 'block', flexDirection: 'row' }}>
            <Button
              variant="text"
              id="external-id"
              data-cy={`external-id-${param.value}`}
              onClick={() => handleRowClick(param.value as string)}
            >
              {param.value}
            </Button>
          </div>
        );
      },
    },
    {
      field: 'name',
      // Display role name for now until row level detail is avaialble
      // Update to display 'Job Title' once row level detail is available
      headerName: `Name`,
      flex: 2,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'processData',
      // Display job title for now until row level detail is avaialble
      // Update to display 'Normalized Title' once row level detail is available
      headerName: 'Normalized Title',
      flex: 2,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: normalizedTitleCell,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: param => {
        let color: EmsiUIBadgeColorPalette = EmsiUIBadgeColorPalette.SurfaceNeutral;
        let label = param.value;
        let title = '';
        switch (param.value) {
          case 'created':
            color = EmsiUIBadgeColorPalette.SurfaceSuccess;
            label = 'Created';
            title = 'This role has been created and saved.';
            break;
          case 'updated':
            color = EmsiUIBadgeColorPalette.SurfaceInformation;
            label = 'Updated';
            title = 'This role has been updated.';
            break;
          case 'error':
            color = EmsiUIBadgeColorPalette.SurfaceCritical;
            label = 'Error';
            title = 'An error occurred while processing this role.';
            break;
          case 'skipped':
            color = EmsiUIBadgeColorPalette.SurfaceWarning;
            label = 'Skipped';
            title = 'This role has been skipped.';
            break;
          case 'processing':
            color = EmsiUIBadgeColorPalette.SurfaceProcessing;
            label = 'Processing';
            title = 'This role is being processed.';
            break;
          case 'inserted':
            color = EmsiUIBadgeColorPalette.SurfaceNeutral;
            label = 'Inserted';
            title = 'This data has been inserted for processing.';
            break;
          case 'deleted':
            color = EmsiUIBadgeColorPalette.SurfaceNeutral;
            label = 'Deleted';
            title = 'This data has been deleted.';
            break;
        }
        return (
          <>
            <EmsiUIBadge color={color} label={label} title={title} />
          </>
        );
      },
    },
    {
      field: 'message',
      headerName: 'Message',
      flex: 2.5,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 2,
      headerAlign: 'left',
      align: 'left',
      valueFormatter: param => {
        return dayjs(param.value).format('HH:mm:ss - MM-DD-YYYY');
      },
      sortable: false,
    },
  ];

  const getBatchLogTypeDisplayText = (type?: string) => {
    switch (type) {
      case 'roles':
        return 'Roles';
      case 'skills':
        return 'Skills';
      case 'tagsAliases':
        return 'Tags & Aliases';
      default:
        return 'Roles';
    }
  };

  const appliedFiltersForQuery = () => {
    const filters = { ...appliedFilters };
    Object.keys(filters).forEach(k => {
      filters[k] = filters[k].map(i => i.value);
    });
    return filters;
  };

  const loadProcessBatchRowLogs = async () => {
    const filters = appliedFiltersForQuery();
    const { batchRowLogs, totalCount } = await batchLogService.getBatchRowLogs({
      companyId,
      batchId,
      limit: rowsPerPage,
      offset: currentPage * rowsPerPage,
      filters,
      searchTerms: filter,
    });
    setBatchRowLogs(batchRowLogs);
    setBatchRowLogsCount(totalCount);
  };

  const loadProcessBatchDetails = async () => {
    const processBatchDetails = await batchLogService.getBatchDetails({
      companyId,
      batchId,
    });
    setBatchDetails(processBatchDetails);
  };

  const pageChangeHandler = (page: number) => {
    setIsPageChanged(!isPageChanged);
    setCurrentPage(page);
  };

  useEffect(() => {
    const loadFacetData = async () => {
      const batchLogFacets = await getBatchLogFacets(batchId);
      setTotalFacets(batchLogFacets);
    };
    companyId && loadFacetData();
  }, [companyId]);

  const stringifiedAppliedFilters = useMemo(() => {
    return JSON.stringify(appliedFilters);
  }, [appliedFilters]);

  useEffect(() => {
    const getBatchRowLogs = async () => {
      setLoadingBatchRowLogs(true);
      await loadProcessBatchRowLogs();
      setLoadingBatchRowLogs(false);
    };
    getBatchRowLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, rowsPerPage, isPageChanged, filter, stringifiedAppliedFilters]);

  useEffect(() => {
    (async () => {
      setLoadingBatchDetails(true);
      await loadProcessBatchDetails();
      setLoadingBatchDetails(false);
    })();
  }, []);

  useEffect(() => {
    const getProcessingRowLogs = async () => {
      setLoadingBatchRowLogs(true);
      await loadProcessBatchRowLogs();
      setLoadingBatchRowLogs(false);
    };
    getProcessingRowLogs();

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('p', currentPage);
    searchParams.set('n', rowsPerPage);
    const searchParamsString = searchParams;
    window.history.replaceState(null, '', `${window.location.pathname}?${searchParamsString}`);
  }, [companyId, currentPage, rowsPerPage, filter, appliedFilters]);

  const handleToggle = (area, value, displayValue) => {
    const appliedFiltersCopy = { ...appliedFilters };

    if (!appliedFiltersCopy[area]) {
      appliedFiltersCopy[area] = [
        {
          key: displayValue || value,
          value,
        },
      ];
    } else {
      const i = appliedFiltersCopy[area].findIndex(f => f.value === value);
      if (i >= 0) {
        appliedFiltersCopy[area].splice(i, 1);
      } else {
        appliedFiltersCopy[area].push({
          key: displayValue || value,
          value,
        });
      }
    }

    // remove empty collections
    if (appliedFiltersCopy[area].length === 0) {
      delete appliedFiltersCopy[area];
    }
    setCurrentPage(0);
    setAppliedFilters(appliedFiltersCopy);
  };

  const handleFilterChange = (newFilter: string) => {
    setFilter((prevFilter: string) => {
      if (newFilter !== prevFilter) {
        setCurrentPage(0);
      }
      return newFilter;
    });
  };

  const handleClearAllFacets = area => {
    const appliedFiltersCopy = { ...appliedFilters };

    if (appliedFiltersCopy[area]) {
      delete appliedFiltersCopy[area];
      setCurrentPage(0);
      setAppliedFilters(appliedFiltersCopy);
    }
  };

  const handleSelectAllFacets = area => {
    const appliedFiltersCopy = { ...appliedFilters };
    const totalFacetsCopy = { ...cloneDeep(totalFacets) };
    appliedFiltersCopy[area] = totalFacetsCopy[area];
    setCurrentPage(0);
    setAppliedFilters(appliedFiltersCopy);
  };

  const subNavContent = (): ReactElement => {
    const ProcessingDataElement = ({ header, data }: { header: string; data?: string | number }): ReactElement => {
      return (
        <ProcessingDataItemContainer>
          <Typography
            variant="caption"
            color={theme.colors.text.disabled}
            data-cy={`processbatchlog-${batchDetails?.type}-${header.replace(/\s+/g, '-').toLowerCase()}-header`}
          >
            {`${header}:`}
          </Typography>
          {loadingBatchDetails ? (
            <Skeleton width={'100px'}>
              <Typography>{'.'}</Typography>
            </Skeleton>
          ) : (
            <Typography
              variant="caption"
              data-cy={`processbatchlog-${batchDetails?.type}-${header.replace(/\s+/g, '-').toLowerCase()}-data`}
            >
              {data}
            </Typography>
          )}
        </ProcessingDataItemContainer>
      );
    };

    return (
      <>
        <ProcessingDataContainer>
          <ProcessingDataColumn>
            <ProcessingDataElement header={'File Name'} data={batchDetails?.fileName} />
            <ProcessingDataElement header={'Uploaded By'} data={batchDetails?.userId} />
            <ProcessingDataElement
              header={'Uploaded On'}
              data={dayjs(batchDetails?.createdAt).format('MMM DD, YYYY')}
            />
            <ProcessingDataElement
              header={'Status: '}
              data={batchDetails?.status}
            />
            <ProcessingDataElement
              header={'Status Message: '}
              data={batchDetails?.message}
            />
          </ProcessingDataColumn>
          <ProcessingDataColumn>
            <FileProcessingProgressBar
              requestId={batchId}
              width={'100%'}
              onSegmentClick={(area: string) => handleToggle('status', area, area)}
              highlights={appliedFilters.status?.map(s => s.key)}
              autoRefresh={true}
            />
          </ProcessingDataColumn>
        </ProcessingDataContainer>
        <HeaderSubnavContainer>
          <BatchLogSearchBar
            filter={filter}
            setFilter={handleFilterChange}
            handleClearAllFacets={handleClearAllFacets}
            handleSelectAllFacets={handleSelectAllFacets}
            totalFacets={totalFacets}
            handleToggle={handleToggle}
            appliedFilters={appliedFilters}
          />
        </HeaderSubnavContainer>
      </>
    );
  };

  return (
    <MainContentTemplate pageTitle={`Processing ${getBatchLogTypeDisplayText(batchDetails?.type)} Statuses`}>
      <ContentHeaderTemplate
        contentTitle={`Processing ${getBatchLogTypeDisplayText(batchDetails?.type)} Statuses`}
        backClickHandler={() => navigate(`/admin/processing-statuses${window.location.search}`, { replace: true })}
        displayDivider={true}
        fullWidthDivider={true}
        subNavComponent={subNavContent()}
      />
      <DataGrid
        columns={columns}
        rows={batchRowLogs}
        loading={loadingBatchRowLogs}
        getRowId={(row: BatchRowLog) => `${row.rowNumber}-${row.externalId || ''}`}
        pagination
        paginationMode="server"
        rowCount={batchRowLogsCount}
        onPageChange={pageChangeHandler}
        pageSize={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
        autoHeight
        hideFooterPagination
        hideFooter
        className={`processbatchlog-${batchDetails?.type}-table`}
      />
      <Pagination>
        <TablePagination
          dataCy={`processbatchlog-${batchDetails?.type}-list-table-pagination`}
          total={batchRowLogsCount}
          page={currentPage}
          setPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Pagination>
    </MainContentTemplate>
  );
};

export default BatchProcessingDetailsPage;
