import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { SpacingSizes } from 'types/types';
import EmsiUIChip from 'components/atoms/EmsiUIChip';

const Section = styled.div`
  padding-right: ${props => props.theme.customSpacing.px.s}px;

  &:not(:last-child) {
    margin-right: ${props => props.theme.customSpacing.px.s}px;
    border-right: 1px solid ${props => props.theme.colors.border.default};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  max-height: 200px;
  margin-top: 1rem;
`;

const ChipDemo = (): ReactElement => {
  const [chips, setChips] = useState([
    { id: 1, label: 'Chip #1' },
    { id: 2, label: 'Chip #2' },
    { id: 3, label: 'Chip #3' },
    { id: 4, label: 'Chip #4' },
    { id: 5, label: 'Chip #5' },
  ]);
  const [newestChipId, setNewestChipId] = useState(chips?.length + 1 || 1);

  const handleDeleteChip = chipIdToDelete => {
    const newChips = chips.filter(chip => chip.id.toString() !== chipIdToDelete?.toString());
    setChips(newChips);
  };

  const addChip = () => {
    const newChips = [...chips, { id: newestChipId, label: `Chip #${newestChipId}` }];
    setChips(newChips);
    setNewestChipId(newestChipId + 1);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Section>
          <Typography variant="strong">
            Chips
            <BaseIcon type={IconType.Add} marginLeft={SpacingSizes.XS} size="10px" onIconClick={addChip} />
          </Typography>
          <Row>
            <EmsiUIChip id="undeletableChip1" label="Undeletable Chip #1" />
            {chips?.map(chip => {
              return (
                <EmsiUIChip id={chip.id.toString()} key={chip.id} label={chip.label} deleteHandler={handleDeleteChip} />
              );
            })}
            <EmsiUIChip id="undeletableChip2" label="Undeletable Chip #2" />
          </Row>
        </Section>
      </div>
    </>
  );
};

export default ChipDemo;
