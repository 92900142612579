import React, { ReactElement } from 'react';
import styled from 'styled-components';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import { IconColorFilter, SpacingSizes } from 'types/types';

type Item = {
  name: string;
};

type SearchBarEmbeddedPillProps = {
  item: Item;
  removeHandler?: (item: Item) => void;
};

const Wrapper = styled.div`
  border-radius: ${props => props.theme.borderRadius.default};
  background-color: ${props => props.theme.colors.surface.neutralSubdued};
  color: ${props => props.theme.colors.text.default};
  margin-right: 3px;
  padding: ${props => props.theme.customSpacing.px.xxs}px ${props => props.theme.customSpacing.px.xxs}px
    ${props => props.theme.customSpacing.px.base}px;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

const SearchBarEmbeddedPill = ({ item, removeHandler }: SearchBarEmbeddedPillProps): ReactElement => {
  return (
    <Wrapper>
      <div data-cy={`${item.name.replace(':', '').toLowerCase().replace(/\s/g, '-')}-pill`}>{item.name}</div>
      <div style={{ display: removeHandler ? 'inline-block' : 'none' }}>
        <BaseIcon
          type={IconType.Close}
          size="10px"
          onIconClick={() => removeHandler && removeHandler(item)}
          dataCy={`remove-item-${item.name}`}
          alt={`remove ${item.name}`}
          colorFilter={IconColorFilter.Information}
          marginLeft={SpacingSizes.XXS}
        />
      </div>
    </Wrapper>
  );
};

export default SearchBarEmbeddedPill;
