import React, { useState, useEffect } from 'react';
import useCompanyContext from 'helpers/UseCompanyContext';
import { GridSortItem } from '@mui/x-data-grid';
import { AppliedFacetFilters } from 'types/types';

interface PagingSortingOptions {
  currentPage: number;
  rowsPerPage: number;
  sortOptions: GridSortItem;
}

interface FilterSearchOptions {
  searchTermFilter: string;
  appliedFilters: AppliedFacetFilters;
}

export interface SearchOptions {
  pagingSortingOptions: PagingSortingOptions;
  filterOptions: FilterSearchOptions;
  pathQueryString: string;
}

export interface SearchContextState {
  rolesSearchOptions: SearchOptions;
  skillProfilesSearchOptions: SearchOptions;
}

interface SearchContextProviderProps {
  children?: React.ReactElement;
}

export type SortDirectionOptions = 'asc' | 'desc';

export const SKILL_PROFILES_DEFAULT_SORT_COLUMN = 'roleName';
export const DEFAULT_SORT_DIRECTION: SortDirectionOptions = 'asc';
export const DEFAULT_ROWS_PER_PAGE = 10;
export const DEFAULT_CURRENT_PAGE = 0;

const defaultRolesPagingSortingOptions: PagingSortingOptions = {
  currentPage: DEFAULT_CURRENT_PAGE,
  rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  sortOptions: {
    field: SKILL_PROFILES_DEFAULT_SORT_COLUMN,
    sort: DEFAULT_SORT_DIRECTION,
  },
};

const defaultSkillProfilesPagingSortingOptions: PagingSortingOptions = {
  currentPage: DEFAULT_CURRENT_PAGE,
  rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  sortOptions: {
    field: SKILL_PROFILES_DEFAULT_SORT_COLUMN,
    sort: DEFAULT_SORT_DIRECTION,
  },
};

const defaultRolesAppliedFilters: AppliedFacetFilters = [];
const defaultSkillProfilesAppliedFilters: AppliedFacetFilters = [];

const defaultContext: SearchContextState = {
  rolesSearchOptions: {
    filterOptions: {
      searchTermFilter: '',
      appliedFilters: defaultRolesAppliedFilters,
    },
    pagingSortingOptions: defaultRolesPagingSortingOptions,
    pathQueryString: '',
  },
  skillProfilesSearchOptions: {
    filterOptions: {
      searchTermFilter: '',
      appliedFilters: defaultSkillProfilesAppliedFilters,
    },
    pagingSortingOptions: defaultSkillProfilesPagingSortingOptions,
    pathQueryString: '',
  },
};

const SearchContext = React.createContext<[SearchContextState, React.Dispatch<any>]>([defaultContext, () => undefined]);

const SearchContextProvider: React.FC<SearchContextProviderProps> = props => {
  const { companyId } = useCompanyContext();
  const [state, setState] = useState<SearchContextState>({
    ...defaultContext,
  });

  useEffect(() => {
    (async () => {
      setState({ ...defaultContext });
    })();
  }, [companyId]);

  return <SearchContext.Provider value={[state, setState]}>{props.children}</SearchContext.Provider>;
};

export { SearchContext, SearchContextProvider };
