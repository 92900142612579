import React, { useState, useEffect } from 'react';

import { getRelatedSkills } from 'helpers/SkillAnalysisHelper';
import FormControlLabel from '@mui/material/FormControlLabel';
import EmsiUIBadge from 'components/atoms/EmsiUIBadge';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import styled from 'styled-components';

import BaseIcon from 'components/atoms/BaseIcon';
import { SuggestedSkill } from 'types/types';

import { lightcastSkillLink, getSkillGrowthStatus } from 'helpers/SkillProfilePageHelper';
import useSkillProfileContext from 'helpers/useSkillProfileContext';
import { getTotals, JPAMetric } from 'services/jobPostingsService';
import SkillsService from 'services/SkillsService';
import dayjs from 'dayjs';

const SkillGrowthChip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

type SkillInfoRelatedSkillsProps = {
  emsiTitleId?: string;
  skill: SuggestedSkill;
};

const SkillInfoRelatedSkills = ({ emsiTitleId, skill }: SkillInfoRelatedSkillsProps) => {
  const { tempRoleSkills, selectTempSuggestedSkill, deselectTempSuggestedSkill } = useSkillProfileContext();
  const [relatedSkills, setRelatedSkills] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const jpaMetrics: JPAMetric[] = ['median_salary'];

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (!skill || !emsiTitleId) return;

        // Get related skills
        let relatedSkills: SuggestedSkill[] = (await getRelatedSkills(skill.skillId || '', 5)).map(skill => ({
          skillId: skill.id,
          skillName: skill.name,
          isSelected: !!tempRoleSkills?.find(s => s.skillId === skill.id),
        }));

        const skillIds = relatedSkills.map(skill => skill.skillId);

        // get skill growth
        const skillsGrowthResponses = await SkillsService.getProjectedSkillsGrowth(skillIds);

        const jpaFilter = {
          title: [emsiTitleId],
          when: {
            start: dayjs().subtract(1, 'year').format('YYYY-MM-01'),
            end: dayjs().format('YYYY-MM-DD'),
          },
        };

        // get median salary for each skill
        const skillMedianSalaryPromises = relatedSkills.map(async skill => {
          return {
            id: skill.skillId,
            medianSalary:
              jpaFilter && (await getTotals({ ...jpaFilter, skills: [skill.skillId] }, jpaMetrics)).median_salary,
          };
        });
        const skillsMedianSalaryResponses = await Promise.all(skillMedianSalaryPromises);

        // merge the median salary and growth data with the related skills
        relatedSkills = relatedSkills.map(skill => ({
          ...skill,
          growthPercent: skillsGrowthResponses?.find(sgr => sgr.id === skill.skillId)?.growthPercent.mid || 0,
          growthCategory: skillsGrowthResponses?.find(sgr => sgr.id === skill.skillId)?.growthCategory || 'Stable',
          medianSalary: skillsMedianSalaryResponses?.find(s => s.id === skill.skillId)?.medianSalary || 0,
        }));

        setRelatedSkills(relatedSkills);
      } catch (error) {
        console.error('Error fetching related skills', error);
      } finally {
        setLoading(false);
      }
    })();
  }, [emsiTitleId, skill]);

  const updateReletedSkillSelection = (
    skills: SuggestedSkill[],
    skill: SuggestedSkill,
    checked: boolean
  ): SuggestedSkill[] => {
    return skills.map(s => (s.skillId === skill.skillId ? { ...s, isSelected: checked } : s));
  };

  const toggleState = (checked: boolean, skill: SuggestedSkill) => {
    setRelatedSkills(previousState => updateReletedSkillSelection(previousState, skill, checked));
    if (checked) {
      selectTempSuggestedSkill(skill);
    } else {
      deselectTempSuggestedSkill(skill);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'skillId',
      hide: true,
    },
    {
      field: 'skillName',
      renderCell: param => {
        return (
          <>
            <FormControlLabel
              control={
                <Switch
                  checked={!!param.row.isSelected}
                  data-cy={`related-skills-table-switch-${param.value.replace(/\s+/g, '-').toLowerCase()}`}
                  onChange={event => toggleState(event.target.checked, param.row)}
                />
              }
              label={
                <Link
                  href={lightcastSkillLink(param.row.skillId)}
                  onClick={e => e.stopPropagation()}
                  target="_blank"
                  data-cy={`suggested-skills-table-value-${param.value.replace(/\s+/g, '-').toLowerCase()}`}
                >
                  {param.value}
                </Link>
              }
            />
          </>
        );
      },
      headerName: 'Skill Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
    },
    {
      field: 'projectedGrowth',
      headerName: 'Projected Growth',
      headerAlign: 'center',
      align: 'center',
      renderCell: param => {
        const skillGrowthInfo = getSkillGrowthStatus(param.row.growthCategory);
        return (
          <EmsiUIBadge
            size="medium"
            color={skillGrowthInfo.chipColor}
            label={
              <SkillGrowthChip>
                <div>
                  <BaseIcon
                    marginBottom={skillGrowthInfo.marginBottom}
                    type={skillGrowthInfo.icon}
                    colorFilter={skillGrowthInfo.iconColor}
                    size={`${skillGrowthInfo.iconSize}px`}
                  />
                </div>
                <div>{Math.floor((param.row.growthPercent || 0) * 100)}%</div>
              </SkillGrowthChip>
            }
          />
        );
      },
      flex: 1,
    },
    {
      field: 'medianSalary',
      headerName: 'Salary With Skill',
      renderCell: param => {
        const salary = param.row.medianSalary ? `$${param.row.medianSalary.toLocaleString('en')}` : 'N/A';
        return <Typography variant="strong">{salary}</Typography>;
      },
      flex: 1,
    },
  ];
  return (
    <Grid container my={3} width="auto">
      <Grid container item xs={12} spacing={1}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
            Related Skills
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} mt={2} alignItems="center">
        <DataGrid
          rows={relatedSkills}
          getRowId={(s: SuggestedSkill) => s.skillId}
          columns={columns}
          loading={loading}
          hideFooter
          autoHeight
        />
      </Grid>
    </Grid>
  );
};

export default SkillInfoRelatedSkills;
