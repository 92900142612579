import React, { ReactElement } from 'react';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import BadgeDemo from 'pages/admin/uiLibrary/BadgeDemo';
import BaseIconDemo from 'pages/admin/uiLibrary/BaseIconDemo';
import BreadCrumbsDemo from 'pages/admin/uiLibrary/BreadCrumbsDemo';
import ButtonDemo from 'pages/admin/uiLibrary/ButtonDemo';
import ButtonGroupDemo from 'pages/admin/uiLibrary/ButtonGroupDemo';
import ChipDemo from 'pages/admin/uiLibrary/ChipDemo';
import FormikTextFieldsDemo from 'pages/admin/uiLibrary/FormikTextFieldsDemo';
import SwitchDemo from 'pages/admin/uiLibrary/SwitchDemo';
import TypographyMuiDemo from 'pages/admin/uiLibrary/TypographyMuiDemo';
import MuiLinkDemo from 'pages/admin/uiLibrary/MuiLinkDemo';
import PopoverDemo from 'pages/admin/uiLibrary/PopoverDemo';
import D3PieDonutDemo from 'pages/admin/uiLibrary/D3PieDonutDemo';
import D3RadialLollipopDemo from 'pages/admin/uiLibrary/D3RadialLollipopDemo';
import { UILibrarySection } from 'pages/admin/uiLibrary/UILibraryComponents';
import DrawerDemo from 'pages/admin/uiLibrary/DrawerDemo';

const UILibraryPage = (): ReactElement => {
  return (
    <MainContentTemplate pageTitle="UI Library">
      <ContentHeaderTemplate contentTitle="UI Library" />
      <div>
        <UILibrarySection title="BaseIcon" id="baseIcon">
          <BaseIconDemo />
        </UILibrarySection>
        <UILibrarySection title="BreadCrumb" id="breadCrumb">
          <BreadCrumbsDemo />
        </UILibrarySection>
        <UILibrarySection title="D3 Pie / Donut Chart" id="d3PieDonutChart">
          <D3PieDonutDemo />
        </UILibrarySection>
        <UILibrarySection title="D3 Radial Lollipop Chart" id="d3RadialLollipopChart">
          <D3RadialLollipopDemo />
        </UILibrarySection>
        <UILibrarySection title="EMSI UI Badge" id="emsiUibadge">
          <BadgeDemo />
        </UILibrarySection>
        <UILibrarySection title="MUI Button" id="muiButton">
          <ButtonDemo />
        </UILibrarySection>
        <UILibrarySection title="MUI Button Group" id="muiButtonGroup">
          <ButtonGroupDemo />
        </UILibrarySection>
        <UILibrarySection title="MUI Drawer" id="muiDrawer">
          <DrawerDemo />
        </UILibrarySection>
        <UILibrarySection title="MUI Link" id="muiLink">
          <MuiLinkDemo />
        </UILibrarySection>
        <UILibrarySection title="MUI Popover" id="muiPopover">
          <PopoverDemo />
        </UILibrarySection>
        <UILibrarySection title="MUI Switch" id="muiSwitch">
          <SwitchDemo />
        </UILibrarySection>
        <UILibrarySection title="MUI Typography" id="muiTypography">
          <TypographyMuiDemo />
        </UILibrarySection>
        <UILibrarySection title="Text Fields" id="formikTextFields">
          <FormikTextFieldsDemo />
        </UILibrarySection>
        <UILibrarySection title="Chips" id="muiChips">
          <ChipDemo />
        </UILibrarySection>
        <UILibrarySection title="TypographyCustom" id="typographyCustom">
          <div>
            {/* <TypographyCustomDemo /> */}
            Resolve errors in TypographyCustomDemo.tsx caused when transitioning to Typescript
          </div>
        </UILibrarySection>
      </div>
    </MainContentTemplate>
  );
};

export default UILibraryPage;
