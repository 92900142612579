import styled from 'styled-components';

const Divider = styled.hr`
  width: 100%;
  border-color: white;
  background-color: white;
  border-top: 1px solid ${props => props.theme.colors.border.subdued};
`;

export default Divider;
