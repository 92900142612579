import React, { ReactElement, useState } from 'react';
import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { IconColorFilter, IntegrationCredentials, IntegrationSettingType } from 'types/types';
import ButtonGroup from 'components/atoms/ButtonGroup';
import FormikTextField from 'components/atoms/formElements/FormikTextField';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import IntegrationService from 'services/IntegrationService';
import useCompanyContext from 'helpers/UseCompanyContext';
import DialogModal from 'components/molecules/DialogModal';

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-grow: 1;
`;

const ClientSecret = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

type VerificationStatus = 'Unverified' | 'Success' | 'Failure';

type IntegrationCredentialsFormProps = {
  vendorName: string;
  vendorId: string;
  vendorCompanyId: string;
  integrationCredentials: IntegrationCredentials;
  savingIntegrationCredentials: boolean;
  active?: boolean;
  handleSave: (vendorId: string, integrationCredentials: IntegrationCredentials) => Promise<void>;
  onAfterSave: (integrationSettingType: IntegrationSettingType, success: boolean) => void;
};

const IntegrationCredentialsForm = ({
  vendorName,
  vendorId,
  vendorCompanyId,
  integrationCredentials,
  active = true,
  handleSave,
  onAfterSave,
}: IntegrationCredentialsFormProps): ReactElement => {
  const { companyId } = useCompanyContext();
  const [showModal, setShowModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showClientSecret, setShowClientSecret] = useState(false);
  const [verifyingCredentials, setVerifyingCredentials] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState<VerificationStatus>('Unverified');

  return (
    <>
      <Formik<IntegrationCredentials>
        onSubmit={async (values, formikHelpers) => {
          formikHelpers.setStatus(null);
          setSaving(true);
          try {
            await handleSave(vendorId, values);
            onAfterSave('Credentials', true);
          } catch (e) {
            onAfterSave('Credentials', false);
          } finally {
            setSaving(false);
            setShowModal(false);
          }
        }}
        initialValues={
          integrationCredentials || {
            clientId: '',
            refreshToken: '',
            secret: '',
            skillVendorId: '',
            tenant: '',
            username: '',
            version: '',
            url: '',
          }
        }
        validationSchema={Yup.object({
          clientId: Yup.string()
            .min(1, 'Client ID must be at least 1 character.')
            .max(255, 'Client ID must be less than 255 characters.')
            .required('You must provide a client ID.'),
          secret: Yup.string()
            .min(1, 'Client secret must be at least 1 character.')
            .max(255, 'Client secret must be less than 255 characters.')
            .required('You must provide a client secret.'),
          refreshToken: Yup.string()
            .min(1, 'Refresh token must be at least 1 character.')
            .max(255, 'Refresh token must be less than 255 characters.')
            .required('You must provide a refresh token.'),
          skillVendorId: Yup.string()
            .min(1, 'Skill vendor ID must be at least 1 character.')
            .max(255, 'Skill vendor ID must be less than 255 characters.')
            .required('You must provide skill vendor ID.'),
          tenant: Yup.string()
            .min(1, 'Tenant Name must be at least 1 character.')
            .max(255, 'Tenant Name must be less than 255 characters.')
            .required('You must provide a tenant name.'),
          username: Yup.string()
            .min(1, 'Username must be at least 1 character.')
            .max(255, 'Username must be less than 255 characters.')
            .required('You must provide a username.'),
          url: Yup.string()
            .url('Web service URL must be a valid URL.')
            .min(1, 'Web service URL must be at least 1 character.')
            .max(255, 'Web service URL must be less than 255 characters.')
            .required('You must provide a web service URL.'),
          version: Yup.string()
            .min(1, 'Version must be at least 1 character.')
            .max(255, 'Version must be less than 255 characters.')
            .required('You must provide a version.')
            .matches(/^v\d+\.\d+$/, { message: 'Version must be similar to v40.1' }),
        })}
      >
        {props => {
          return (
            <>
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  props.handleSubmit();
                }}
              >
                <FormRow style={{ marginBottom: '2rem' }}>
                  <Typography variant="displayMedium">{vendorName} Credentials</Typography>
                  <ButtonGroup
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    spacing={2}
                    buttons={[
                      {
                        text: 'Cancel',
                        disabled: !active || !props.dirty || saving || showModal,
                        onClickEvent: () => {
                          if (props.dirty) {
                            props.resetForm();
                          }
                        },
                      },
                      {
                        text: 'Save',
                        disabled: !active || !props.dirty || saving || showModal,
                        onClickEvent: () => setShowModal(true),
                        variant: 'contained',
                        color: 'actionPrimary',
                      },
                    ]}
                  />
                </FormRow>
                <FormRow>
                  <FormikTextField readOnly={!active} name="tenant" label="Tenant Name" placeholder="tenant_name_123" />
                  <FormikTextField
                    readOnly={!active}
                    name="url"
                    label="Web Services URL"
                    placeholder="https://impl-sample.test.myworkday.com"
                  />
                </FormRow>

                <FormRow>
                  <FormikTextField
                    readOnly={!active}
                    name="clientId"
                    label="Client ID"
                    placeholder="clientIdgoeshere"
                  />
                  <ClientSecret>
                    <FormikTextField
                      readOnly={!active}
                      name="secret"
                      label="Client Secret"
                      placeholder="exampleClientSecret"
                      type={showClientSecret ? 'text' : 'password'}
                    />
                    <span>
                      {showClientSecret ? (
                        <BaseIcon
                          type={IconType.EyeOff}
                          size="20px"
                          colorFilter={IconColorFilter.Subdued}
                          onIconClick={() => setShowClientSecret(!showClientSecret)}
                        />
                      ) : (
                        <BaseIcon
                          type={IconType.Eye}
                          size="20px"
                          colorFilter={IconColorFilter.Subdued}
                          onIconClick={() => setShowClientSecret(!showClientSecret)}
                        />
                      )}
                    </span>
                  </ClientSecret>
                </FormRow>

                <FormRow>
                  <FormikTextField
                    readOnly={!active}
                    name="refreshToken"
                    label="Refresh Token"
                    placeholder="exampleRefreshToken"
                  />
                  <FormikTextField readOnly={!active} name="username" label="Username" placeholder="SAMPLE_Username" />
                </FormRow>

                <FormRow>
                  <FormikTextField
                    readOnly={!active}
                    name="skillVendorId"
                    label="Skill Vendor ID"
                    placeholder="SKILL_VENDOR_ID"
                  />
                  <FormikTextField readOnly={!active} name="version" label="Version" placeholder="v41.2" />
                </FormRow>
                <div style={{ marginTop: '1rem' }}>
                  {props.status && (
                    <Typography variant="caption" color="text.critical">
                      Error: {props.status}
                    </Typography>
                  )}
                </div>
                <FormRow style={{ marginTop: '1rem', justifyContent: 'flex-start' }}>
                  <LoadingButton
                    onClick={async () => {
                      setVerificationStatus('Unverified');
                      setVerifyingCredentials(true);
                      const success = await IntegrationService.verifyIntegrationCredentials(
                        companyId,
                        vendorId,
                        vendorCompanyId
                      );
                      setVerificationStatus(success ? 'Success' : 'Failure');
                      setVerifyingCredentials(false);
                    }}
                    color="actionPrimary"
                    variant="contained"
                    size="medium"
                    type="button"
                    disabled={!active || props.dirty}
                    loading={verifyingCredentials}
                    loadingIndicator="Verifying"
                  >
                    Verify
                  </LoadingButton>
                  {verificationStatus !== 'Unverified' && (
                    <Typography
                      variant="button"
                      color={verificationStatus === 'Success' ? 'text.success' : 'text.critical'}
                    >
                      Connection Status: {verificationStatus}
                    </Typography>
                  )}
                </FormRow>
              </Form>
              <DialogModal
                dialogOpen={showModal}
                closeModal={() => setShowModal(false)}
                title="Save Changes?"
                content={'Are you sure you want to save these credentials?'}
                buttonGroup={[
                  {
                    text: 'Cancel',
                    disabled: saving,
                    onClickEvent: () => setShowModal(false),
                  },
                  {
                    text: 'Save',
                    disabled: saving,
                    onClickEvent: props.handleSubmit,
                    variant: 'contained',
                    color: 'actionPrimary',
                  },
                ]}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default IntegrationCredentialsForm;
