import React, { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useSkillProfileContext from 'helpers/useSkillProfileContext';
import styled, { useTheme } from 'styled-components';
import { getDDNDimensionName } from 'helpers/SkillAnalysisHelper';

const ContentTitle = styled(Typography)`
  height: 100%;
  align-items: center;
  display: inline-flex;
`;

const SkillProfileEditRecommendedContent = (): ReactElement => {
  const theme = useTheme();
  const { role } = useSkillProfileContext();
  const dimensionName = getDDNDimensionName(role);

  return (
    <Grid container justifyContent="space-between" direction="column" sx={{ display: 'flex', flexWrap: 'nowrap' }}>
      <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
        <ContentTitle variant="strong" data-cy="skills-recommended-dimension-title">
          {dimensionName === 'gotspecocc' && (
            <>{'The recommended skills are based on your selected Lightcast Specialized Occupation'}</>
          )}
          {dimensionName === 'soc' && (
            <>
              {
                'The recommended skills are based on your selected Occupation Code. Select a Lightcast Specialized Occupation for the best results.'
              }
            </>
          )}
          {dimensionName === null && (
            <>
              {
                'We cannot recommend skills until you classify this Skill Profile. Select a Lightcast Specialized Occupation for the best results.'
              }
            </>
          )}
        </ContentTitle>
      </Grid>
      <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginTop: `${theme.customSpacing.px.base * 6}px` }}>
          <ContentTitle variant="strong" data-cy="skills-recommended-add-skills-title">
            Add skills to skill profile
          </ContentTitle>
        </div>
      </Grid>
    </Grid>
  );
};

export default SkillProfileEditRecommendedContent;
