import React, { ReactElement, useState } from 'react';
import AdminToolsService, { ApiToGet } from 'services/AdminToolsService';
import { UILibrarySection } from './uiLibrary/UILibraryComponents';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import CircularProgress from '@mui/material/CircularProgress';

const ApiInformationPage = (): ReactElement => {
  const [metadata, setMetadata] = useState<Record<ApiToGet, any>>();
  const [apiToGet, setApiToGet] = useState<ApiToGet>();

  const loadApiMetadata = async (api: ApiToGet) => {
    if (apiToGet !== api && metadata?.[api] === undefined) {
      const result = await AdminToolsService.getMetadata(api);
      setMetadata(metadata => ({ ...metadata, [api]: result }) as Record<ApiToGet, any>);
      setApiToGet(api);
    }
  };

  const ApiMetaSection = (sectionName: ApiToGet): ReactElement => {
    return (
      <div>
        <UILibrarySection
          title={sectionName}
          id={`meta-information-${sectionName}`}
          key={`meta-information-${sectionName}`}
          onChange={(_event, expanded) => expanded && loadApiMetadata(sectionName)}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {metadata?.[sectionName] && (
              <div style={{ width: '90vw', minHeight: 'min-content', maxHeight: '500px', overflow: 'scroll' }}>
                <pre>{JSON.stringify(metadata?.[sectionName], null, 2)}</pre>
              </div>
            )}
            {!metadata?.[sectionName] && <CircularProgress />}
          </div>
        </UILibrarySection>
      </div>
    );
  };

  return (
    <>
      <MainContentTemplate pageTitle="API Meta Information">
        <ContentHeaderTemplate contentTitle="API Meta Information" />
        {ApiMetaSection('roles')}
        {ApiMetaSection('skills')}
        {ApiMetaSection('titles')}
        {ApiMetaSection('classification')}
        {ApiMetaSection('ddn')}
        {ApiMetaSection('jpa')}
      </MainContentTemplate>
    </>
  );
};

export default ApiInformationPage;
