import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import axios from 'helpers/api_helper';
import { JpaResult, SkillGrowthResponse } from 'types/api_responses';
import { GetSkillsStatsResponse } from 'types/types';
import * as Sentry from '@sentry/react';

type SkillsExtractResponse = {
  skills: any[];
};

const extractSkillsFromRawText = async (text: string): Promise<any> => {
  const url = `${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/skills/extract`;
  const skills = await axios.post<SkillsExtractResponse>(url, { text });

  const surfaceForms = skills.data.skills;
  surfaceForms.forEach(surfaceForm => {
    surfaceForm.classificationData.skills = surfaceForm.classificationData.skills.filter(
      skill => skill.confidence >= 0.5
    );
  });

  const filteredSurfaceForms = surfaceForms.filter(form => !!form.classificationData.skills.length);

  return filteredSurfaceForms;
};

const getSkillsFromSurfaceForms = (surfaceForms: any[], postingCounts = []): any[] => {
  const skills = [] as any[];

  surfaceForms.forEach(form => {
    const formSkills = form.classificationData.skills;

    formSkills.forEach(skill => {
      if (!skills.some(({ id }) => id === skill.skill.id)) {
        const bucket: any = postingCounts.find(({ id }) => skill.skill.id === id);
        skills.push({
          ...skill.skill,
          confidence: skill.confidence,
          percentPostings: bucket ? bucket.percentPostings : null,
          surfaceForms: [],
        });
      }

      for (let i = 0; i < skills.length; i++) {
        if (skills[i].id === skill.skill.id) {
          skills[i].confidence = Math.max(skills[i].confidence, skill.confidence);
          skills[i].surfaceForms.push({
            confidence: skill.confidence,
            value: form.surfaceForm.value,
          });
        }
      }
    });
  });

  skills.sort((a, b) => a.name.localeCompare(b.name));
  return skills;
};

const getSkillFromAutocomplete = async (q: string, requestController?: AbortController): Promise<any> => {
  const encodedQuery = encodeURIComponent(q);
  const url = `${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/skills/autocomplete?q=${encodedQuery}`;
  const signal = requestController ? { signal: requestController.signal } : {};
  const res = await axios.get(url, signal);
  return res;
};

const getSkillStats = async (companyId: string): Promise<GetSkillsStatsResponse> => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/skill/stats`;
  const resp = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return resp.data.data;
};

const getProjectedSkillsGrowth = async (skillIds: string[]) => {
  const url = `${process.env.REACT_APP_APIGATEWAY_URL}/emsiservices/projected-skill-growth/dimensions/skill/bulk`;
  try {
    const response = await axios.post<{ data: SkillGrowthResponse[] }>(url, { ids: skillIds });
    return response.data.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    Sentry.captureException(axiosError);
  }
};

const getSkillsSalaries = async (emsiTitleId: string) => {
  const url = `${process.env.REACT_APP_APIGATEWAY_URL}/emsiservices/jpa/rankings/skills`;
  try {
    const today = dayjs().format('YYYY-MM');
    const oneYearAgo = dayjs().subtract(1, 'year').format('YYYY-MM');
    const response = await axios.post<{ data: JpaResult }>(url, {
      filter: {
        when: { start: oneYearAgo, end: today },
        title: [emsiTitleId],
      },
      rank: { limit: 1000, extra_metrics: ['median_salary'] },
    });
    return response.data.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    Sentry.captureException(axiosError);
  }
};

export default {
  extractSkillsFromRawText,
  getSkillsFromSurfaceForms,
  getSkillFromAutocomplete,
  getSkillStats,
  getProjectedSkillsGrowth,
  getSkillsSalaries,
};
