import { ReactNode } from 'react';
import { SurfaceForm } from 'components/atoms/draftHighlights';
import { CompositeDecorator, EditorState } from 'draft-js';
import { ExtractedSkill } from 'types/types';

type Sequence = {
  sourceStart: number;
  sourceEnd: number;
  value: string;
};

type ConfidenceSkills = {
  skill: ExtractedSkill;
  confidence: number;
  value: string;
};

// Scans `text` and builds a mapping from UTF-8 byte offsets to the
// UTF-16 based 'character' offsets (as used by `slice` and friends).
// Author: Justin
const getByteOffsetTable = text => {
  const table = {};
  let bytes = 0;
  for (let i = 0; i < text.length; i++) {
    table[bytes] = i;
    const char = text.codePointAt(i);
    if (char <= 0x007f) bytes += 1;
    else if (char <= 0x07ff) bytes += 2;
    else if (char <= 0xffff) bytes += 3;
    else bytes += 4;
  }
  return table;
};

// translate skill extraction source offsets into draft block offsets
const getBlockSources = (sources, editorText) => {
  const byteOffsetTable = getByteOffsetTable(editorText + ' ');
  const blockSources = {};

  sources.forEach(surfaceFormSource =>
    surfaceFormSource.sequence.forEach(({ sourceStart, sourceEnd }) => {
      sourceStart = byteOffsetTable[sourceStart];
      sourceEnd = byteOffsetTable[sourceEnd];
      const previousLines = editorText.substring(0, sourceStart).split('\n');
      const blockIndex = previousLines.length - 1;
      const lastLine = previousLines[blockIndex];

      if (!blockSources[blockIndex]) {
        blockSources[blockIndex] = [];
      }
      const blockCharOffset = sourceStart - lastLine.length;
      blockSources[blockIndex].push([sourceStart - blockCharOffset, sourceEnd - blockCharOffset]);
    })
  );

  return blockSources;
};

const getSkillDecorator = (
  sources: any[],
  editorState: EditorState,
  component: ReactNode,
  onClick: ((e?: any) => any) | undefined = undefined
): any => {
  const blockSources: any = getBlockSources(sources, editorState.getCurrentContent().getPlainText());

  return {
    strategy: (contentBlock, callback) => {
      const blockKey = contentBlock.getKey();
      const blockIndex = editorState
        .getCurrentContent()
        .getBlockMap()
        .keySeq()
        .findIndex(k => k === blockKey);
      const maxEnd = contentBlock.getText().length;
      (blockSources[blockIndex] || [])
        .filter(([start, end]) => {
          // filter out surface forms that may have been tagged on an old
          // version of the content -- prevents that race condition
          return start <= maxEnd && end <= maxEnd;
        })
        .forEach(([start, end]) => callback(start, end));
    },
    component,
    props: onClick ? { onClick } : undefined,
  };
};

const getSequence = source => {
  const words = source.value.split(/[\s-]/g);
  const sequence: Sequence[] = [];
  for (let i = 0, start = source.sourceStart; i < words.length; i++) {
    sequence.push({
      sourceStart: start,
      sourceEnd: start + words[i].length,
      value: words[i],
    });
    start += words[i].length + 1;
  }
  return sequence;
};

const getSurfaceFormSources = (surfaceForms: any[]): any[] => {
  return surfaceForms.map(form => ({
    skillId: form.classificationData.skills[0].skill.id,
    sequence: getSequence(form.surfaceForm),
  }));
};

const getSkillIdBySourceIndex = (surfaceForms: any[], sourceIndex: number): any => {
  const surfaceFormSources = getSurfaceFormSources(surfaceForms);
  for (let i = 0, index = -1; i < surfaceFormSources.length; i++) {
    index += surfaceFormSources[i].sequence.length;
    if (sourceIndex <= index) {
      return surfaceFormSources[i].skillId;
    }
  }
};

const getContextSources = (surfaceForms: any[]): any => {
  const sources: { sequence: Sequence[] }[] = [];
  surfaceForms.forEach(form =>
    form.classificationData.contextForms.forEach(context => sources.push({ sequence: getSequence(context) }))
  );
  return sources;
};

const getSkills = (surfaceForms: any[], postingCounts: any[] = []): ExtractedSkill[] => {
  const skills: ExtractedSkill[] = [];

  surfaceForms.forEach(form => {
    const formSkills: ConfidenceSkills[] = form.classificationData.skills;

    formSkills.forEach((skill: ConfidenceSkills) => {
      if (!skills.some(({ id }) => id === skill.skill.id)) {
        const bucket: { percentPostings?: any } = postingCounts.find(({ id }) => skill.skill.id === id);
        skills.push({
          ...skill.skill,
          confidence: skill.confidence,
          percentPostings: bucket ? bucket.percentPostings : null,
          surfaceForms: [],
        });
      }

      for (let i = 0; i < skills.length; i++) {
        if (skills[i].id === skill.skill.id) {
          skills[i].confidence = Math.max(skills[i].confidence, skill.confidence);
          skills[i].surfaceForms.push({
            confidence: skill.confidence,
            value: form.surfaceForm.value,
          });
        }
      }
    });
  });

  skills.sort((a, b) => a.name.localeCompare(b.name));
  return skills;
};

const getSelectedText = (editorState: EditorState): string => {
  const selectionState = editorState.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const start = selectionState.getStartOffset();
  const end = selectionState.getEndOffset();
  return currentContentBlock.getText().slice(start, end);
};

const getSkillsDecorator = (
  editorState: EditorState,
  surfaceForms: any[],
  selectedSkillId: string | null,
  setSelectedSkillId: (arg: any) => any,
  showContext?: boolean
): CompositeDecorator => {
  const decorators: any[] = [];

  const targetForm = surfaceForms.filter(form =>
    form.classificationData.skills.find(skill => skill.skill.id === selectedSkillId)
  );
  const nonTargetForms = surfaceForms.filter(form =>
    form.classificationData.skills.find(skill => skill.skill.id !== selectedSkillId)
  );

  if (selectedSkillId) {
    decorators.push(
      getSkillDecorator(
        getSurfaceFormSources(targetForm),
        editorState,
        SurfaceForm // SelectedSurfaceForm
      )
    );
  }

  if (selectedSkillId && showContext) {
    decorators.push(
      getSkillDecorator(
        getContextSources(targetForm),
        editorState,
        SurfaceForm // ContextSurfaceForm
      )
    );
  }

  if (!selectedSkillId || !showContext) {
    decorators.push(
      getSkillDecorator(getSurfaceFormSources(nonTargetForms), editorState, SurfaceForm, evt => {
        evt.persist();
        const parent = evt.target.closest('[data-highlight-type]');
        const highlightType = parent.dataset.highlightType;
        const highlights = [].slice.call(document.querySelectorAll(`[data-highlight-type="${highlightType}"]`));
        const sourceIndex = highlights.findIndex(el => el === parent);
        setSelectedSkillId(getSkillIdBySourceIndex(surfaceForms, sourceIndex));
      })
    );
  }

  return new CompositeDecorator(decorators);
};

export {
  getSkillDecorator,
  getSurfaceFormSources,
  getContextSources,
  getSelectedText,
  getSkillIdBySourceIndex,
  getSkills,
  getSkillsDecorator,
};
