import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useMemo, ReactElement } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import UseCompanyContext from 'helpers/UseCompanyContext';
import ReactGA from 'react-ga';
import RolesService from 'services/RolesService';
import { bucketizeTimeLong } from 'helpers/analyticsHelper';
import RolesSearchBar from 'components/molecules/RolesSearchBar';
import cloneDeep from 'lodash/cloneDeep';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import TablePagination from 'components/molecules/TablePagination';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import ButtonGroup, { EMSIBGButton } from 'components/atoms/ButtonGroup';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import dayjs from 'dayjs';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { badgeBuilder } from 'helpers/SkillProfilePageHelper';
import EmsiUIBadge from 'components/atoms/EmsiUIBadge';
import SkillProfileService from 'services/SkillProfileService';
import SkillProfile from 'classes/skillProfile';
import { BenchmarkSkillMatchPercentage } from 'types/types';
import useSkillProfilesSearchContext from 'helpers/useSkillProfilesSearchContext';
import useAuthContext from 'helpers/UseAuthContext';
import { SearchOptions } from 'contexts/SearchContext';
import SkillProfileSearchParams from 'classes/SkillProfileSearchParams';
import QuickUpdateSkills, { RoleSkillUpdateType } from 'components/organisms/QuickUpdateSkills';
import DialogModal from 'components/molecules/DialogModal';
import useRoleSkillsUpdate from 'helpers/useRoleSkillsUpdate';
import UploadFileModal from 'components/organisms/modals/UploadFileModal';
import * as ExportService from 'services/ExportService';
import { toast } from 'react-toastify';
import { UPLOAD_FIELDS_INFO, SkillsFileUploadDetails } from 'services/FileUploadService';

const DATE_FORMAT = 'M/D/YY';
const AVAILABLE_SORT_COLUMNS = ['roleName', 'skillCount', 'requiredSkillCount', 'updatedAt'];

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;

  &.pagination-top {
    padding-left: ${props => props.theme.customSpacing.px.s}px;

    @media (max-width: 768px) {
      padding: 0px;
    }
  }

  &.pagination-bottom {
    box-shadow: ${props => props.theme.boxShadow.surface1};
    padding: 0px ${props => props.theme.customSpacing.px.base * 10}px;
  }
`;

const SkillProfileContainer = styled.div``;

const HeaderSubnavContainer = styled(Box)`
  margin: 0px ${props => props.theme.customSpacing.px.m}px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .complex-input-bar-container {
    max-width: calc(100vw - ${props => props.theme.customSpacing.px.base * 110}px);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: ${props => props.theme.customSpacing.px.xxs}px;

    .MuiTablePagination-toolbar {
      justify-content: space-between;
      padding: 0px;
    }

    .complex-input-bar-container {
      max-width: revert;
    }
  }
`;

const StyledEmsiUIBadge = styled(EmsiUIBadge)`
  margin: 0.5rem 0;
`;

const SkillProfilesPage = (): ReactElement => {
  const { companyId } = UseCompanyContext();
  const navigate = useNavigate();
  const [skillProfiles, setSkillProfiles] = useState<SkillProfile[]>([]);
  const [skillProfilesBenchmarkMatch, setSkillProfilesBenchmarkMatch] = useState<BenchmarkSkillMatchPercentage[]>([]);
  const [isLoadingBenchmarkMatch, setIsLoadingBenchmarkMatch] = useState(true);
  const [totalSkillProfileCount, setTotalSkillProfileCount] = useState(0);
  const [loadingSkillProfiles, setLoadingSkillProfiles] = useState(true);
  const [exportingSkillProfiles, setExportingSkillProfiles] = useState(false);
  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
  const [totalFacets, setTotalFacets] = useState({});
  const [selectedRows, setSelectedRows] = useState(0);
  const [selectedSkillProfiles, setSelectedSkillProfiles] = useState<string[]>([]);
  const [menuActionItemsAnchor, setMenuActionItemsAnchor] = useState<null | HTMLElement>(null);
  const actionMenuOpen = Boolean(menuActionItemsAnchor);
  const [menuImportAnchor, setMenuImportAnchor] = useState<null | HTMLElement>(null);
  const importMenuOpen = Boolean(menuImportAnchor);
  const [modifySkillsAction, setModifySkillsAction] = useState<SkillsFileUploadDetails['uploadAction']>('add');

  const { isAnalystReadOnly } = useAuthContext();
  const {
    roleSkillsUpdated,
    updateType,
    setAppArea,
    setUpdateType,
    roleSkillsUpdateInProgress,
    setRoleSkillsUpdateInProgress,
    saveManuallyUpdatedSkills,
  } = useRoleSkillsUpdate();

  const {
    searchTermFilter,
    setSearchTermFilter,
    getAppliedFiltersArray,
    setAppliedFilters,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    sortOptions,
    setSortOptions,
    getSortColumn,
    getSortDirection,
    queryString,
    isLoaded,
  } = useSkillProfilesSearchContext();
  const appliedFilters = getAppliedFiltersArray();

  const triggerFileExport = async (roleIdsToExport?: string[]) => {
    setExportingSkillProfiles(true);
    const eventLabel = roleIdsToExport ? 'export selected clicked' : 'export clicked';
    ReactGA.event({
      category: 'skillProfiles',
      action: 'export',
      label: eventLabel,
    });

    try {
      const startTime = performance.now();

      const exportTriggerSuccess = await ExportService.triggerExportFile(companyId, 'skills', roleIdsToExport);

      const endTime = performance.now();

      ReactGA.event({
        category: 'skillProfiles',
        action: 'export',
        label: `load time: ${bucketizeTimeLong(endTime - startTime)}`,
      });

      if (!exportTriggerSuccess) throw new Error();

      exportTriggeredToast(exportTriggerSuccess);
    } catch (e) {
      exportTriggeredToast(false);
      ReactGA.event({
        category: 'skillProfiles',
        action: 'export',
        label: 'export failed',
      });
    } finally {
      setExportingSkillProfiles(false);
    }
  };

  const exportTriggeredToast = (success: boolean) => {
    const message = success
      ? 'Your skill profile file is exporting. You will receive an email with a link to the file when the export is complete.'
      : 'There was an error exporting your skill profiles. Please try again.';
    const toastContainer = (
      <div>
        <span data-cy="file-export-triggered-toast">{message}</span>
      </div>
    );

    if (success) {
      toast.success(toastContainer);
    } else {
      toast.error(toastContainer);
    }
  };

  useEffect(() => {
    setAppArea('Skill Profiles Page');
  }, []);

  useEffect(() => {
    const loadFacetData = async () => {
      const dbFacets = await RolesService.getRoleFacets(companyId);
      setTotalFacets(dbFacets);
    };
    companyId && roleSkillsUpdated && loadFacetData();
  }, [companyId, roleSkillsUpdated]);

  const appliedFiltersForQuery = () => {
    const filters = { ...appliedFilters };
    Object.keys(filters).forEach(k => {
      filters[k] = filters[k].map(i => i.value);
    });
    return filters;
  };

  const loadSkillProfiles = async () => {
    const filters = appliedFiltersForQuery();
    const { skillProfiles, totalCount } = await SkillProfileService.getSkillProfiles({
      companyId,
      limit: rowsPerPage,
      offset: currentPage * rowsPerPage,
      sortColumn: getSortColumn(),
      sortDirection: getSortDirection(),
      filters,
      searchTerms: searchTermFilter,
    });
    setSkillProfiles(skillProfiles);
    setTotalSkillProfileCount(totalCount);
  };

  const updateSortingOrder = (newSortModel: GridSortModel) => {
    // since we don't use Multi-sorting we are cheking the firt sort element only.
    if (newSortModel.length <= 0 || !AVAILABLE_SORT_COLUMNS.includes(newSortModel[0].field)) return;
    setCurrentPage(0, true);
    setSortOptions(newSortModel[0], true);
  };

  const stringifiedAppliedFilters = useMemo(() => {
    return JSON.stringify(appliedFilters);
  }, [appliedFilters]);

  const updateQueryString = () => {
    const searchOptions: SearchOptions = {
      filterOptions: {
        searchTermFilter,
        appliedFilters,
      },
      pagingSortingOptions: {
        currentPage,
        rowsPerPage,
        sortOptions,
      },
      pathQueryString: queryString,
    };
    const params = new SkillProfileSearchParams(searchOptions);
    const pathQueryString = params.searchParamsString;
    const newRelativePathQuery = `${window.location.pathname}${pathQueryString}`;
    window.history.pushState(null, '', newRelativePathQuery);
  };

  useEffect(() => {
    const getSkillProfiles = async () => {
      setLoadingSkillProfiles(true);
      roleSkillsUpdated && (await loadSkillProfiles());
      setLoadingSkillProfiles(false);
    };

    if (isLoaded) {
      getSkillProfiles();
      updateQueryString();
    }
  }, [
    companyId,
    currentPage,
    rowsPerPage,
    sortOptions,
    searchTermFilter,
    stringifiedAppliedFilters,
    isLoaded,
    roleSkillsUpdated,
  ]);

  useEffect(() => {
    const loadSkillProfilesBenchmarkMatch = async () => {
      setIsLoadingBenchmarkMatch(true);
      try {
        const skillProfilesBenchmarkMatch = await SkillProfileService.getSkillProfilesBenchmarkMatch(
          companyId,
          skillProfiles
        );
        setSkillProfilesBenchmarkMatch(skillProfilesBenchmarkMatch);
      } catch (e) {
        alert('Unable to load benchmark match. Try again later.');
        console.error(e);
      } finally {
        setIsLoadingBenchmarkMatch(false);
      }
    };

    skillProfiles.length > 0 && loadSkillProfilesBenchmarkMatch();
  }, [skillProfiles]);

  const handleSkillProfileFilterChange = (newSkillProfileFilter: string) => {
    setCurrentPage(0, true);
    setSearchTermFilter(newSkillProfileFilter, true);
  };

  const handleToggle = (area, value, displayValue) => {
    const appliedFiltersCopy = { ...appliedFilters };

    if (!appliedFiltersCopy[area]) {
      appliedFiltersCopy[area] = [
        {
          key: displayValue || value,
          value,
        },
      ];
    } else {
      const i = appliedFiltersCopy[area].findIndex(f => f.value === value);
      if (i >= 0) {
        appliedFiltersCopy[area].splice(i, 1);
      } else {
        appliedFiltersCopy[area].push({
          key: displayValue || value,
          value,
        });
      }
    }

    // remove empty collections
    if (appliedFiltersCopy[area].length === 0) {
      delete appliedFiltersCopy[area];
    }
    setCurrentPage(0, true);
    setAppliedFilters(appliedFiltersCopy, true);
  };

  useEffect(() => {
    updateQueryString();
  }, [queryString]);

  const handleSkillProfileClick = (skillProfileId: string) => {
    navigate(`/skill-profiles/${skillProfileId}`);
  };

  const handleClearAllFacets = area => {
    const appliedFiltersCopy = { ...appliedFilters };

    if (appliedFiltersCopy[area]) {
      delete appliedFiltersCopy[area];
      setCurrentPage(0, true);
      setAppliedFilters(appliedFiltersCopy);
    }
  };

  const handleSelectAllFacets = area => {
    const appliedFiltersCopy = { ...appliedFilters };
    const totalFacetsCopy = { ...cloneDeep(totalFacets) };
    appliedFiltersCopy[area] = totalFacetsCopy[area];
    setCurrentPage(0, true);
    setAppliedFilters(appliedFiltersCopy);
  };

  const selectionRowChange = (selectedRowIds: any[]) => {
    setSelectedSkillProfiles(selectedRowIds);
    setSelectedRows(selectedRowIds.length);
  };

  const compareSkillProfiles = () => {
    ReactGA.event({
      category: 'skillProfiles',
      action: 'skill profiles compare',
      label: `${selectedSkillProfiles.length}`,
    });
    navigate(`/roles/compare?roleIds=${selectedSkillProfiles.join(',')}`);
  };

  const handleActionMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuActionItemsAnchor(event.currentTarget);
  };

  const handleActionMenuClose = () => {
    setMenuActionItemsAnchor(null);
  };

  const handleImportMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuImportAnchor(event.currentTarget);
  };

  const handleImportMenuClose = () => {
    setMenuImportAnchor(null);
  };

  const requestReviewSkillProfiles = () => {
    alert('Feature not yet available');
    ReactGA.event({
      category: 'skillProfiles',
      action: 'skill profiles request review',
      label: `${selectedSkillProfiles.length}`,
    });
    handleActionMenuClose();
  };

  const duplicateSkillProfiles = () => {
    alert('Feature not yet available');
    ReactGA.event({
      category: 'skillProfiles',
      action: 'skill profiles create duplicate',
      label: `${selectedSkillProfiles.length}`,
    });
    handleActionMenuClose();
  };

  const deleteSkillProfiles = () => {
    alert('Feature not yet available');
    ReactGA.event({
      category: 'skillProfiles',
      action: 'skill profiles delete',
      label: `${selectedSkillProfiles.length}`,
    });
    handleActionMenuClose();
  };

  const handleExportSelected = () => {
    const idsToExport = Array.from(selectedSkillProfiles) as string[];
    triggerFileExport(idsToExport);
  };

  const handleAddSkills = () => {
    setUpdateType(RoleSkillUpdateType.Add);
    setRoleSkillsUpdateInProgress(true);
  };

  const handleRemoveSkills = () => {
    setUpdateType(RoleSkillUpdateType.Remove);
    setRoleSkillsUpdateInProgress(true);
  };

  const buttonGroups: EMSIBGButton[] = [
    {
      text: `${selectedRows} Selected`,
      color: 'actionSecondary',
      onClickEvent: handleActionMenuClick,
      variant: 'outlined',
      endIcon: <BaseIcon type={IconType.ChevronDown} size="8px" />,
      disabled: selectedRows === 0,
      dataCy: 'skillprofiles-menu-button',
    },
    {
      text: 'Export All Data',
      color: 'actionSecondary',
      onClickEvent: () => triggerFileExport(),
      variant: 'outlined',
      disabled: loadingSkillProfiles || totalSkillProfileCount === 0,
      isLoadingButton: true,
      loadingMessage: 'Exporting...',
      loadingStatus: exportingSkillProfiles,
      dataCy: 'skillprofiles-export-button',
    },
    {
      text: 'Bulk Modify Skills',
      color: 'actionPrimary',
      onClickEvent: handleImportMenuClick,
      variant: 'contained',
      visible: !isAnalystReadOnly(),
      endIcon: <BaseIcon type={IconType.ChevronDown} size="8px" />,
      dataCy: 'skillprofiles-modify-skills-button',
    },
  ];

  const columns: GridColDef<SkillProfile>[] = [
    {
      field: 'roleName',
      headerName: 'Role Name',
      flex: 0.3,
      headerAlign: 'left',
      align: 'left',
      renderCell: param => {
        return (
          <Button
            variant="text"
            data-cy={`skillprofiles-row-mapped-title-${param.row.roleId}`}
            sx={{ paddingBottom: '0px' }}
            onClick={() => {
              handleSkillProfileClick(param.row.roleId);
            }}
          >
            {param.value}
          </Button>
        );
      },
    },
    {
      field: 'skillCount',
      headerName: 'Skill Count',
      flex: 0.15,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'requiredSkillCount',
      headerName: 'Required Skills',
      flex: 0.15,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'roleId',
      headerName: '% Match to Benchmark',
      description: '% of skills matching with top 10 benchmark skills',
      flex: 0.2,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: param => {
        const benchmarkMatch =
          skillProfilesBenchmarkMatch.find(item => item.skillProfileId === param.value)?.benchmarkMatchPercentage || 0;
        return (
          <>
            {isLoadingBenchmarkMatch ? (
              <Skeleton variant="text" width={50} />
            ) : benchmarkMatch === -1 ? (
              <StyledEmsiUIBadge color={'surfaceDisabled'} label={'n/a'} title={'No benchmarks available to compare'} />
            ) : (
              badgeBuilder(benchmarkMatch / 100)
            )}
          </>
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Last Updated',
      flex: 0.15,
      headerAlign: 'left',
      align: 'left',
      valueFormatter: ({ value }) => dayjs(value).format(DATE_FORMAT),
    },
  ];

  const ImportMenuComponent = (
    <Box sx={{ display: 'flex' }}>
      <Menu
        anchorEl={menuImportAnchor}
        open={importMenuOpen}
        onClose={handleImportMenuClose}
        data-cy="skillprofiles-import-menu-items"
      >
        <MenuItem
          data-cy="skillprofiles-menu-export-selected-button"
          onClick={() => {
            setModifySkillsAction('add');
            setUploadModalIsOpen(true);
          }}
        >
          Add Skills
        </MenuItem>
        <MenuItem
          data-cy="skillprofiles-menu-add-skills-button"
          onClick={() => {
            setModifySkillsAction('update');
            setUploadModalIsOpen(true);
          }}
        >
          Update Skills
        </MenuItem>
        <MenuItem
          data-cy="skillprofiles-menu-remove-skills-button"
          onClick={() => {
            setModifySkillsAction('delete');
            setUploadModalIsOpen(true);
          }}
        >
          Remove Skills
        </MenuItem>
      </Menu>
    </Box>
  );

  const ActionsComponent = useMemo((): ReactElement => {
    return (
      <Box sx={{ display: 'flex' }}>
        <Menu
          anchorEl={menuActionItemsAnchor}
          open={actionMenuOpen}
          onClose={handleActionMenuClose}
          data-cy="skillprofiles-menu-items"
        >
          <MenuItem
            data-cy="skillprofiles-menu-compare-button"
            disabled={selectedSkillProfiles.length > 3}
            onClick={compareSkillProfiles}
            title={'Compare up to 3 skill profiles'}
            sx={{
              '&.Mui-disabled': {
                pointerEvents: 'auto',
                cursor: 'pointer',
              },
            }}
          >
            Compare
          </MenuItem>
          <MenuItem
            data-cy="skillprofiles-menu-export-selected-button"
            onClick={() => {
              handleExportSelected();
              handleActionMenuClose();
            }}
          >
            Export Selected
          </MenuItem>
          <MenuItem
            data-cy="skillprofiles-menu-add-skills-button"
            onClick={() => {
              handleAddSkills();
              handleActionMenuClose();
            }}
          >
            Add Skills
          </MenuItem>
          <MenuItem
            data-cy="skillprofiles-menu-remove-skills-button"
            onClick={() => {
              handleRemoveSkills();
              handleActionMenuClose();
            }}
          >
            Remove Skills
          </MenuItem>
          <div style={{ display: 'none' }}>
            <MenuItem
              disabled={isAnalystReadOnly()}
              data-cy="skillprofiles-menu-request-review-button"
              onClick={requestReviewSkillProfiles}
            >
              Request Review
            </MenuItem>
            <MenuItem
              data-cy="skillprofiles-menu-duplicate-button"
              disabled={isAnalystReadOnly() || !(selectedSkillProfiles.length === 1)}
              onClick={duplicateSkillProfiles}
            >
              Duplicate
            </MenuItem>
            <MenuItem
              disabled={isAnalystReadOnly()}
              data-cy="skillprofiles-menu-delete-button"
              onClick={deleteSkillProfiles}
            >
              Delete
            </MenuItem>
          </div>
        </Menu>
        <ButtonGroup buttons={buttonGroups} wrap="nowrap" justifyContent="flex-end" />
        {ImportMenuComponent}
      </Box>
    );
  }, [searchTermFilter, appliedFilters, buttonGroups]);

  return (
    <>
      <MainContentTemplate pageTitle="Skill ID">
        <ContentHeaderTemplate
          contentTitle="Skill Profiles"
          actionsComponent={ActionsComponent}
          subNavComponent={
            <HeaderSubnavContainer>
              <RolesSearchBar
                roleFilter={searchTermFilter}
                setRoleFilter={handleSkillProfileFilterChange}
                totalFacets={totalFacets}
                handleToggle={handleToggle}
                appliedFilters={appliedFilters}
                handleClearAllFacets={handleClearAllFacets}
                handleSelectAllFacets={handleSelectAllFacets}
              />
              <Pagination className="pagination-top">
                <TablePagination
                  dataCy="skillprofiles-list-table-pagination"
                  total={totalSkillProfileCount}
                  page={currentPage}
                  setPage={setCurrentPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  justifyContent="flex-end"
                />
              </Pagination>
            </HeaderSubnavContainer>
          }
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
          <div>
            <SkillProfileContainer>
              <Box sx={{ flexGrow: 1 }}>
                <DataGrid
                  columns={columns}
                  rows={skillProfiles}
                  getRowId={(r: SkillProfile) => r.roleId}
                  loading={loadingSkillProfiles}
                  pagination
                  paginationMode="server"
                  rowCount={totalSkillProfileCount}
                  pageSize={rowsPerPage}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  hideFooterPagination={true}
                  hideFooter={true}
                  autoHeight
                  checkboxSelection
                  onSelectionModelChange={selectionRowChange}
                  className="skillprofiles-table"
                  sortingMode="server"
                  onSortModelChange={updateSortingOrder}
                  sortModel={[sortOptions]}
                />
              </Box>
            </SkillProfileContainer>
          </div>
          <Pagination className="pagination-bottom">
            <TablePagination
              dataCy="skillprofiles-list-table-pagination-bottom"
              total={totalSkillProfileCount}
              page={currentPage}
              setPage={setCurrentPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          </Pagination>
        </div>
      </MainContentTemplate>
      <DialogModal
        title={`${updateType === RoleSkillUpdateType.Add ? 'Add' : 'Remove'} Skills`}
        dialogOpen={roleSkillsUpdateInProgress}
        content={
          <QuickUpdateSkills
            roleIds={Array.from(selectedSkillProfiles) as string[]}
            onCancel={() => setRoleSkillsUpdateInProgress(false)}
            onSave={saveManuallyUpdatedSkills}
            actionButtonText={`${updateType === RoleSkillUpdateType.Add ? 'Add' : 'Remove'} Skills`}
          />
        }
        closeModal={() => setRoleSkillsUpdateInProgress(false)}
        buttonGroup={[]}
        maxWidth="md"
        fullWidth={true}
        dataCy="skill-profiles-page-skill-update-modal"
      />
      <UploadFileModal
        isOpen={uploadModalIsOpen}
        closeHandler={() => {
          setUploadModalIsOpen(false);
        }}
        showAddManually={false}
        uploadDetails={{
          uploadType: 'skills',
          uploadAction: modifySkillsAction,
        }}
        ignoredFields={UPLOAD_FIELDS_INFO.skills[modifySkillsAction].ignored}
        allowedFields={UPLOAD_FIELDS_INFO.skills[modifySkillsAction].allowed}
        requiredFields={UPLOAD_FIELDS_INFO.skills[modifySkillsAction].required}
      />
    </>
  );
};

export default SkillProfilesPage;
