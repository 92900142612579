import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Divider from 'components/atoms/Divider';
import FormikTextField from 'components/atoms/formElements/FormikTextField';
import FormikTextAreaField from 'components/atoms/formElements/FormikTextAreaField';
import FormikBubbleInputField from 'components/atoms/formElements/FormikBubbleInputField';
import ButtonRow from 'components/atoms/ButtonRow';
import SkillsExtractor from 'components/organisms/SkillsExtractor';
import useCompanyContext from 'helpers/UseCompanyContext';
import ButtonGroup, { EMSIBGButton } from 'components/atoms/ButtonGroup';
import Role from 'classes/role';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import DialogModal from 'components/molecules/DialogModal';
import { RoleAttributeTooltip } from 'types/types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

interface RoleEditFormProps {
  role?: Role;
  saveRole?: (values: any) => Promise<any>;
  isLoading?: boolean;
  updateValues?: React.Dispatch<any>;
  closeHandler?: (reload?: boolean) => void;
  isCopyingRole?: boolean;
  isModal?: boolean;
  isModifiedHandler?: (isModified: boolean) => void;
  resetForm?: boolean;
  updateResetForm?: () => void;
}

const FormBody = styled.div``;

const RoleFormHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 2rem;
`;

const RoleFormTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 24px;
`;

const TemporaryRole = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  margin-left: 1rem;
`;

const SkillExtractorWrapper = styled.div`
  column-gap: 2.5rem;
  display: grid;
  margin-bottom: 24px;
  @media (max-width: 750px) {
    display: block;
    & > div {
      margin-bottom: 2rem;
      min-height: 300px;
    }
  }
`;

const RoleEditForm = ({
  role,
  saveRole,
  isLoading,
  updateValues,
  closeHandler,
  isCopyingRole = false,
  isModal = false,
  isModifiedHandler,
  resetForm = true,
  updateResetForm,
}: RoleEditFormProps): ReactElement => {
  const { companyId } = useCompanyContext();
  const [isModified, setIsModified] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  useEffect(() => {
    isModifiedHandler && isModifiedHandler(isModified);
  }, [isModified]);

  const getFormTitle = () => {
    return `${!role?.roleId ? 'Create' : role?.roleId && isCopyingRole ? 'Copy' : 'Edit'} Role`;
  };

  const successfullSavedRoleToast = (roleId, action: 'created' | 'copied') => (
    <div>
      <span data-cy={`role-successfully-${action}-toast`}>
        Role {action} successfully. Click{' '}
        <Link data-cy={`role-successfully-${action}-link`} to={`/role-edit/${roleId}`}>
          here
        </Link>{' '}
        to view.
      </span>
    </div>
  );

  const cancelUploadModalHandler = (continueAction: boolean) => {
    setIsCancelModalOpen(false);

    if (continueAction) {
      closeHandler?.(false);
    }
  };

  const cancelHandler = () => {
    if (isModified) {
      setIsCancelModalOpen(true);
    } else {
      closeHandler?.(false);
    }
  };

  const actionButtons: EMSIBGButton[] = [
    {
      text: 'Save',
      color: 'actionPrimary',
      type: 'submit',
      isLoadingButton: true,
      loadingStatus: isSubmitting,
      disabled: !isFormValid || isLoading,
      dataCy: 'role-modal-window-save-button',
    },
    {
      text: 'Cancel',
      color: 'actionSecondary',
      disabled: isLoading,
      onClickEvent: cancelHandler,
      dataCy: 'role-modal-window-cancel-button',
    },
  ];

  const cancelUploadBtnGroup: EMSIBGButton[] = [
    {
      text: 'Cancel',
      onClickEvent: () => cancelUploadModalHandler(false),
      dataCy: 'cancel-role-form-modal-cancel-button',
    },
    {
      text: 'Continue',
      variant: 'contained',
      color: 'actionPrimary',
      onClickEvent: () => cancelUploadModalHandler(true),
      dataCy: 'cancel-role-form-modal-continue-button',
    },
  ];

  return (
    <>
      <Formik
        enableReinitialize={!!role?.roleId}
        initialValues={{
          roleName: role?.roleName || '',
          jobCode: role?.jobCode || '',
          jobTitle: role?.jobTitle || '',
          jobFamily: role?.jobFamilyRaw || '',
          jobFunction: role?.jobFunctionRaw || '',
          details: {
            description: role?.details?.description || '',
            notes: role?.details?.notes || '',
          },
          tags: role?.tags || [],
          aliases: role?.aliases || [],
          temporary: role?.temporary || false,
          roleId: role?.roleId,
        }}
        validationSchema={Yup.object({
          roleName: Yup.string()
            .min(2, 'Name must be at least 2 characters long')
            .max(100, 'Name must be less than 100 characters long')
            .required('You must provide a role name.'),
          jobCode: Yup.string().required('You must provide a job code.'),
          jobTitle: Yup.string().required('You must provide a job title.'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          const result = await saveRole?.(values);
          if (result) {
            if (isCopyingRole) toast.success(successfullSavedRoleToast(result.roleId, 'copied'));
            else if (!role?.roleId) toast.success(successfullSavedRoleToast(result.roleId, 'created'));
          }
          setSubmitting(false);
          result && closeHandler?.(true);
        }}
      >
        {props => {
          useEffect(() => {
            setIsModified(props.dirty);
          }, [props.dirty]);

          useEffect(() => {
            setIsFormValid(props.dirty && props.isValid);
          }, [props.dirty, props.isValid]);

          useEffect(() => {
            updateValues?.(props.values);
          }, [props.values]);

          useEffect(() => {
            setIsSubmitting(props.isSubmitting);
          }, [props.isSubmitting]);

          useEffect(() => {
            if (resetForm) {
              props.resetForm();
              updateResetForm?.();
            }
          }, [resetForm]);

          return (
            <Form
              onSubmit={e => {
                e.preventDefault();
                props.handleSubmit(e);
              }}
            >
              {isModal && (
                <>
                  <RoleFormHeader className="role-modal-header">
                    <RoleFormTitle data-cy="role-modal-window-title">
                      {isLoading ? (
                        <Skeleton width={'100%'}>
                          <Typography variant="displayLarge">{'Role edit form title'}</Typography>
                        </Skeleton>
                      ) : (
                        getFormTitle()
                      )}
                    </RoleFormTitle>
                    <div>
                      <ButtonGroup buttons={actionButtons} />
                    </div>
                  </RoleFormHeader>
                  <Divider style={{ width: '90%' }} />
                </>
              )}
              <FormBody className="edit-role-form">
                <>
                  <FormikTextField
                    tabIndex={1}
                    label="Role Name"
                    name="roleName"
                    placeholder="enter a role name"
                    loading={isLoading}
                    dataCy="role-modal-window-role-name-textbox"
                    required={true}
                    tooltipText={RoleAttributeTooltip.RoleName}
                  />

                  <FormikTextField
                    tabIndex={2}
                    label="Job Code"
                    name="jobCode"
                    placeholder="enter a job code"
                    loading={isLoading}
                    dataCy="role-modal-window-job-code-textbox"
                    required={true}
                    informationText={isCopyingRole ? 'Job Code must be unique' : undefined}
                    tooltipText={RoleAttributeTooltip.JobCode}
                  />

                  <FormikTextField
                    tabIndex={3}
                    label="Job Title"
                    name="jobTitle"
                    placeholder="enter a job title"
                    loading={isLoading}
                    dataCy="role-modal-window-job-title-textbox"
                    required={true}
                    tooltipText={RoleAttributeTooltip.JobTitle}
                  />

                  <FormikTextField
                    tabIndex={4}
                    label="Job Family"
                    name="jobFamily"
                    placeholder="enter the job family for this role"
                    loading={isLoading}
                    dataCy="role-modal-window-job-family-textbox"
                    tooltipText={RoleAttributeTooltip.JobFamily}
                  />

                  <FormikTextField
                    tabIndex={5}
                    label="Job Function"
                    name="jobFunction"
                    placeholder="enter the job function for this role"
                    loading={isLoading}
                    dataCy="role-modal-window-job-function-textbox"
                    tooltipText={RoleAttributeTooltip.JobFunction}
                  />

                  <input type="hidden" name="details.description" value={props.values.details.description} />
                  <SkillsExtractor
                    Wrapper={SkillExtractorWrapper}
                    content={role?.details?.description || ''}
                    loading={isLoading}
                    dataCy="role-edit-modal-skills-extractor-description-editor"
                    title="Job Description"
                    onContentChange={text => props.setFieldValue('details.description', text, true)}
                    tooltipText={RoleAttributeTooltip.JobDescription}
                  />

                  <FormikBubbleInputField
                    tabIndex={6}
                    label="Tags"
                    name="tags"
                    loading={isLoading}
                    initialValues={role?.tags || []}
                    setFieldValue={(fieldNameChanged, bubbleInputs) => {
                      const tags = bubbleInputs.map(bi => ({
                        ...bi,
                        customerId: companyId,
                        roleId: role?.roleId,
                      }));
                      props.setFieldValue(fieldNameChanged, tags);
                    }}
                    dataCy="role-modal-window-tags-container"
                    tooltipText={RoleAttributeTooltip.Tags}
                    tooltipIconColor="Default"
                  />

                  <FormikBubbleInputField
                    tabIndex={7}
                    label="Aliases"
                    name="aliases"
                    loading={isLoading}
                    initialValues={role?.aliases || []}
                    setFieldValue={(fieldNameChanged, bubbleInputs) => {
                      const aliases = bubbleInputs.map(bi => ({
                        ...bi,
                        customerId: companyId,
                        roleId: role?.roleId,
                      }));
                      props.setFieldValue(fieldNameChanged, aliases);
                    }}
                    dataCy="role-modal-window-aliases-container"
                    tooltipText={RoleAttributeTooltip.Aliases}
                    tooltipIconColor="Default"
                  />

                  <FormikTextAreaField
                    tabIndex={8}
                    label="Notes"
                    name="details.notes"
                    loading={isLoading}
                    placeholder="record notes here"
                    dataCy="role-modal-window-notes-textbox"
                    tooltipText={RoleAttributeTooltip.Notes}
                    tooltipIconColor="Default"
                  />

                  {isModal && (
                    <ButtonRow>
                      <ButtonGroup buttons={actionButtons} />
                      <TemporaryRole>
                        <Field
                          name="temporary"
                          type="checkbox"
                          disabled={isLoading}
                          tabIndex={9}
                          data-cy="role-modal-temporary-role-checkbox"
                        />
                        Temporary Role
                      </TemporaryRole>
                    </ButtonRow>
                  )}
                </>
              </FormBody>
            </Form>
          );
        }}
      </Formik>
      <DialogModal
        dialogOpen={isCancelModalOpen}
        disableEscapeKeyDown={true}
        title={`Cancel ${getFormTitle()}`}
        content={'Cancelling will lose your changes. Do you want to continue?'}
        buttonGroup={cancelUploadBtnGroup}
        dataCy={'cancel-role-form-confirmation-modal'}
      />
    </>
  );
};

export default RoleEditForm;
