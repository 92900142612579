import React, { ReactElement, useEffect, useMemo, useState, useCallback } from 'react';
import styled, { useTheme, css } from 'styled-components';
import ComplexInputBar from 'components/molecules/ComplexInputBar';
import Filter from 'components/atoms/icons/Filter';
import Chevron from 'components/atoms/Chevron';
import SearchBarFacet from 'components/molecules/SearchBarFacet';
import SearchBarEmbeddedPill from 'components/atoms/SearchBarEmbeddedPill';
import debounce from 'lodash/debounce';
import Typography from '@mui/material/Typography';
import { UILayers } from 'types/types';

type BatchLogSearchBarProps = {
  filter: string;
  setFilter: (filter: any) => void;
  handleClearAllFacets: (key: string) => void;
  handleSelectAllFacets: (key: string) => void;
  totalFacets: any;
  handleToggle: (area: any, value: any, displayValue?: any) => void;
  appliedFilters: string;
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: ${UILayers.PageElement};
`;

const SearchBox = styled(ComplexInputBar)`
  padding: 0px ${props => props.theme.customSpacing.px.xxs}px;
`;

const FacetCategories = styled.div<{ $expanded?: boolean }>`
  background-color: white;
  border-radius: ${props => props.theme.borderRadius.default};
  box-shadow: ${props => props.theme.boxShadow.surface1};
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: 10px;
  min-width: 225px;
  padding: ${props => props.theme.customSpacing.px.base}px;
  position: absolute;
  box-shadow: ${props => props.theme.boxShadow.surface2};

  ${props => props.$expanded && 'height: fit-content;'}

  @media (max-width: 768px) {
    min-width: 150px;
  }
`;

const FacetCategoryRow = styled.div<{ $selected: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: ${props =>
    `${props.theme.customSpacing.px.xxs}px ${props.theme.customSpacing.px.base}px ${props.theme.customSpacing.px.xxs}px ${props.theme.customSpacing.px.base * 4}px`};
  border-radius: ${props => props.theme.borderRadius.default};
  ${params =>
    params.$selected &&
    `
      background-color: ${props => props.theme.colors.interactive.default};
      color: ${props => props.theme.colors.text.onInteractive};`}

  &:hover {
    ${params => !params.$selected && `background-color: ${props => props.theme.colors.action.secondaryHovered};`}
  }

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }

  transition: all 200ms;
`;

const PillsWrapper = styled.div<{ $hasPills?: boolean }>`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  transition: all 3s;

  ${params =>
    params.$hasPills &&
    css`
      min-width: 220px;
      max-width: calc(100vw - 180px);
    `};
`;

const ShowFacetsWrapper = styled(PillsWrapper)`
  cursor: pointer;
  margin-right: 1rem;
  min-width: 30px;
  overflow: hidden;
`;

const Cover = styled.div<{ $blockInput: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: ${UILayers.Cover};
  background-color: white;
  opacity: 0.4;
  display: ${props => (props.$blockInput ? 'initial' : 'none')};
`;

const StyledSearchBarEmbeddedPill = styled(SearchBarEmbeddedPill)`
  color: yellow;
`;

const FacetRow = ({ facetName, setActiveFacet, activeFacet }) => {
  const theme = useTheme();
  const selected = facetName === activeFacet;

  return (
    <FacetCategoryRow
      $selected={selected}
      onClick={() => (selected ? setActiveFacet(undefined) : setActiveFacet(facetName))}
    >
      <Typography variant="strong">{facetName}</Typography>
      <Chevron
        flip={false}
        horizontal={true}
        widthInPixels={14}
        color={selected ? theme.colors.text.onInteractive : undefined}
        dataCy="batchlog-search-bar-facet-list-chevron"
      />
    </FacetCategoryRow>
  );
};

const facets = [{ name: 'Processing Status', key: 'status' }];

const BatchLogSearchBar = ({
  filter,
  setFilter,
  handleClearAllFacets,
  handleSelectAllFacets,
  totalFacets,
  handleToggle,
  appliedFilters,
}: BatchLogSearchBarProps): ReactElement => {
  const theme = useTheme();
  const [showFacets, setShowFacets] = useState(false);
  const [activeFacet, setActiveFacet] = useState<string | undefined>();
  const [localFilter, setLocalFilter] = useState(filter);

  const debouncedSetFilter = useCallback(debounce(setFilter, 400), []);

  useEffect(() => {
    debouncedSetFilter(localFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localFilter]);

  useEffect(() => {
    if (!showFacets) {
      setActiveFacet(undefined);
    }
  }, [showFacets]);

  const hasPills = useMemo(() => {
    let count = 0;
    Object.keys(appliedFilters).forEach(area => {
      count += appliedFilters[area].length;
    });
    return count > 0;
  }, [appliedFilters]);

  const filterTypeName = (filterType: string) => {
    switch (filterType) {
      case 'status':
        return 'Processing Status';
      default:
        return filterType;
    }
  };

  return (
    <>
      <Cover $blockInput={showFacets} onClick={() => setShowFacets(false)} />
      <Wrapper>
        <SearchBox
          placeholder={'Search Name'}
          value={localFilter}
          name="batchrowlog-table-search-textbox"
          onChange={e => setLocalFilter(e.target.value)}
          preItem={
            <PillsWrapper $hasPills={hasPills} style={{ overflow: 'hidden' }}>
              <ShowFacetsWrapper
                onClick={() => {
                  setActiveFacet('Processing Status'); // active by default / set undefined when more filters added.
                  setShowFacets(!showFacets);
                }}
              >
                <Filter color={theme.colors.icon.subdued.hex} dataCy="batchlog-facet-filter-icon" />
              </ShowFacetsWrapper>
              <PillsWrapper $hasPills={hasPills}>
                {Object.keys(appliedFilters).map(filterType => {
                  return appliedFilters[filterType].map((filter: { key: string; value: string }) => {
                    return (
                      <StyledSearchBarEmbeddedPill
                        item={{
                          name: `${filterTypeName(filterType)}: ${filter.key}`,
                        }}
                        key={`${filterType}-${filter.value}`}
                        removeHandler={() => {
                          handleToggle(filterType, filter.value);
                        }}
                      />
                    );
                  });
                })}
              </PillsWrapper>
            </PillsWrapper>
          }
        />
        {showFacets && (
          <FacetCategories>
            <FacetRow
              facetName={'Processing Status'}
              activeFacet={activeFacet}
              setActiveFacet={setActiveFacet}
            ></FacetRow>
          </FacetCategories>
        )}
        <SearchBarFacet
          facets={facets}
          activeFacet={activeFacet}
          appliedFilters={appliedFilters}
          handleClearAllFacets={handleClearAllFacets}
          handleSelectAllFacets={handleSelectAllFacets}
          handleToggle={handleToggle}
          totalFacets={totalFacets}
        />
      </Wrapper>
    </>
  );
};

export default BatchLogSearchBar;
