import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import HeaderLink from 'components/atoms/HeaderLink';
import styled, { css } from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import UseAuthContext from 'helpers/UseAuthContext';
import useCompanyContext from 'helpers/UseCompanyContext';
import SubNavMobile from 'components/molecules/SubNavMobile';
import Typography from '@mui/material/Typography';
import { productModule } from 'helpers/product_modules';
import { UILayers } from 'types/types';

const Wrapper = styled.div<{ $showing: boolean }>`
  display: flex;
  flex-direction: row;
  margin: ${props => props.theme.customSpacing.px.base}px 0px;

  @media (max-width: 768px) {
    flex-direction: column;
    position: absolute;
    top: 48px;
    background-color: ${props => props.theme.brand.headerBarColor1};
    width: 100%;
    z-index: ${UILayers.SiteMenu};
    left: -100%;
    box-shadow: 0px 5px 5px 0px #777;
    transition: top 0.5s ease-in-out;

    ${props =>
      props.$showing &&
      css`
        left: 0;
      `};
  }
`;

const MenuButton = styled.div<{ $mobileOnly: boolean; $active?: boolean }>`
  padding: ${props => props.theme.customSpacing.px.base * 4}px;
  width: 320px;
  text-transform: none;
  cursor: pointer;
  color: ${props => props.theme.colors.text.onHeaderBarAlt};
  &:hover {
    color: ${props => props.theme.colors.text.onHeaderBar};
  }

  ${props =>
    props.$active &&
    css`
      color: ${props => props.theme.colors.text.onHeaderBar};
    `};

  ${props =>
    props.$mobileOnly &&
    css`
      display: none;

      @media (max-width: 768px) {
        display: block;
        width: 100%;
        padding: ${props.theme.customSpacing.px.xxs}px ${props.theme.customSpacing.px.base * 4}px;
      }
    `};
`;

const Divider = styled.div`
  padding: 16px;
  font-size: 12px;
  color: ${props => props.theme.colors.text.onHeaderBar};
  opacity: 0.6;
  text-align: left;
  display: none;
  overflow: hidden;

  &:after {
    display: inline-block;
    content: '';
    background-color: ${props => props.theme.colors.border.subdued};
    opacity: 0.2;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 80%;
    left: 1em;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

type ModuleRoute = {
  displayName: string;
  path: string;
  startString: string;
};

type NavBarProps = {
  showing?: boolean;
  setShowing?: Dispatch<SetStateAction<boolean>>;
  moduleRoutes: ModuleRoute[];
  activeModule: string;
  route?: string;
};

const NavBar = ({ showing = false, setShowing, moduleRoutes, activeModule, route }: NavBarProps): ReactElement => {
  const { logOut, isAnalyst, isEmsiAdmin, isAdmin } = UseAuthContext();
  const { hasProductModule, isAR } = useCompanyContext();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogoutClick = () => {
    logOut().then(() => {
      navigate('/sign-in');
    });
  };

  return (
    <Wrapper $showing={showing} id="nav-bar">
      {isAnalyst() && (
        <>
          {hasProductModule(productModule.Roles) && (
            <HeaderLink
              text={'Role Mapping'}
              url={'/roles'}
              dataCy="navbar-link-roles"
              active={
                location.pathname.match(/^\/roles/i) !== null &&
                location.pathname.match(/^\/roles\/applied-research-tools/i) === null
              }
            />
          )}
          {isAR() && (
            <HeaderLink
              text={'AR Tools'}
              url={'/roles/applied-research-tools'}
              dataCy="navbar-link-ar-tools"
              active={location.pathname.match(/^\/roles\/applied-research-tools/i) !== null}
            />
          )}
          {hasProductModule(productModule.SkillId) && (
            <HeaderLink
              text={'Skill ID'}
              url={'/skill-profiles'}
              dataCy="navbar-link-skillid"
              active={location.pathname.match(/^\/skill-profiles/i) !== null}
              onClickHandler={
                location.pathname.match(/^\/skill-profiles/i) !== null
                  ? () => {
                      console.log('tab already active');
                    }
                  : undefined
              }
            />
          )}
          {isAdmin() && (
            <>
              <HeaderLink
                text={'Settings'}
                url={'/admin/company-settings'}
                dataCy="navbar-link-settings"
                active={activeModule.toLowerCase() === 'settings'}
              />
              {activeModule && showing && activeModule.toLowerCase() === 'settings' && (
                <SubNavMobile moduleRoutes={moduleRoutes} activeRoute={route || ''} />
              )}
            </>
          )}
        </>
      )}

      <Divider>
        <Typography variant="caption" sx={{ opacity: '0.6' }}>
          My Account
        </Typography>
      </Divider>

      <MenuButton
        $mobileOnly={true}
        onClick={() => {
          navigate('/my-profile');
          setShowing?.(false);
        }}
        $active={location.pathname.match(/^\/my-profile/i) !== null}
      >
        <Typography>Profile</Typography>
      </MenuButton>
      {isEmsiAdmin() && (
        <MenuButton
          $mobileOnly={true}
          onClick={() => {
            navigate('/admin/admin-tools');
            setShowing?.(false);
          }}
          $active={location.pathname.match(/^\/admin\/admin-tools/i) !== null}
        >
          <Typography>Admin Tools</Typography>
        </MenuButton>
      )}
      {isEmsiAdmin() && (
        <MenuButton
          $mobileOnly={true}
          onClick={() => {
            navigate('/admin/ui-library');
            setShowing?.(false);
          }}
          $active={location.pathname.match(/^\/admin\/ui-library/i) !== null}
        >
          <Typography>UI Library</Typography>
        </MenuButton>
      )}
      <MenuButton $mobileOnly={true} onClick={handleLogoutClick}>
        <Typography>Log Out</Typography>
      </MenuButton>
    </Wrapper>
  );
};

export default NavBar;
