import React, { ReactElement } from 'react';
import * as d3 from 'd3';
import { useD3 } from 'helpers/useD3Helper';
import { useTheme } from 'styled-components';

export interface PieChartDataProps {
  id: string;
  label: string;
  value: number;
  isActive: boolean;
  color?: string;
  tooltipText?: string;
}

type PieChartProps = {
  chartName: string;
  data?: any;
  chartType?: 'pie' | 'donut';
  labelType?: 'text' | 'tooltip' | 'none';
  width?: number;
  height?: number;
  margin?: number;
  colorRange?: string[];
  sectionClickHandler?(sectionData: PieChartDataProps): void;
};

const PieChart = ({
  chartName,
  data,
  chartType = 'donut',
  labelType = 'tooltip',
  width = 240,
  height = 240,
  margin = 0,
  colorRange,
  sectionClickHandler,
}: PieChartProps): ReactElement => {
  const theme = useTheme();

  const colors = colorRange || theme.colors.chart.colorRange3;
  const outerRadius = Math.min(width, height) / 2 - margin;
  const innerRadius = (Math.min(width, height) - 60) / 2 - margin;

  const tooltip = d3
    .select('div')
    .append('div')
    .style('opacity', 0)
    .style('position', 'fixed')
    .style('display', 'inline-flex')
    .style('background-color', 'white')
    .style('border-radius', theme.borderRadius.default)
    .style('padding', `${theme.customSpacing.px.xs}px`)
    .style('font-size', theme.customTypography.desktop.caption.size)
    .style('box-shadow', theme.boxShadow.surface2);

  const mouseover = function (d: any) {
    tooltip.style('opacity', 1).style('left', `${d.clientX}px`).style('top', `${d.clientY}px`);
  };

  const mousemove = function (d: any) {
    tooltip
      .html(d.target.__data__.data.tooltipText || d.target.__data__.data.label)
      .style('left', `${d.clientX + 10}px`)
      .style('top', `${d.clientY + 10}px`);
  };

  const mouseleave = function () {
    tooltip.style('opacity', 0);
  };

  const handleOnClick = (sectionData: PieChartDataProps) => {
    sectionClickHandler?.(sectionData);
  };

  function renderChart(svg: any) {
    if (data[0]) {
      const color = d3
        .scaleOrdinal()
        .domain(data.map(x => x.value))
        .range(colors);

      const pie = d3.pie().value((d: any) => {
        return d.value;
      });

      const data_ready = pie(data);

      /** check if svg group has been created already */
      if (svg._groups[0][0].childElementCount === 0) {
        svg = d3
          .select(`#${chartName}-${chartType}-chart`)
          .attr('width', width)
          .attr('height', height)
          .append('g')
          .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

        svg
          .selectAll('g')
          .data(data_ready)
          .enter()
          .append('path')
          .attr(
            'd',
            d3
              .arc()
              .innerRadius(chartType === 'pie' ? 0 : innerRadius)
              .outerRadius(outerRadius)
          )
          .attr('cursor', sectionClickHandler ? 'pointer' : 'default')
          .attr('fill', (d: any) => d.data.color || color(d.data.value))
          .attr('stroke', 'white')
          .style('stroke-width', theme.customSpacing.px.base)
          .style('opacity', (d: any) => (d.data.isActive ? 1 : 0.56));

        if (sectionClickHandler) {
          svg.on('click', (d: any) => handleOnClick(d.target.__data__.data));
        }

        if (labelType === 'tooltip') {
          svg.on('mouseover', mouseover).on('mousemove', mousemove).on('mouseleave', mouseleave);
        }

        if (labelType === 'text') {
          const arcGenerator = d3.arc().innerRadius(30).outerRadius(outerRadius);

          svg
            .selectAll('g')
            .data(data_ready)
            .enter()
            .append('text')
            .text((d: any) => d.data.label)
            .attr('class', `${chartType}-chart-label`)
            .attr('transform', (d: any) => 'translate(' + arcGenerator.centroid(d) + ')')
            .style('text-anchor', 'middle')
            .style('font-size', theme.customTypography.desktop.caption.size);
        }
      }
    }
  }

  const svgRef = data[0] ? useD3(renderChart, [data]) : undefined;

  return (
    <div id={`${chartName}-${chartType}-chart-container`} data-cy={`${chartName}-${chartType}-chart-container`}>
      <svg id={`${chartName}-${chartType}-chart`} ref={svgRef} />
    </div>
  );
};

export default PieChart;
