import React, { useState, ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import ButtonGroup, { EMSIBGButton } from 'components/atoms/ButtonGroup';
import { Column, Section } from 'pages/admin/uiLibrary/UILibraryComponents';
import Box from '@mui/material/Box';

function ButtonGroupDemo(): ReactElement {
  const [loadingState, setLoadingState] = useState(false);

  const updateLoadingState = () => {
    setLoadingState(true);
    setTimeout(() => {
      setLoadingState(false);
    }, 2000);
  };

  const buttons: EMSIBGButton[] = [
    {
      text: 'Primary',
      onClickEvent: () => alert('action Primary'),
      color: 'actionPrimary' as const,
      variant: 'contained' as const,
    },
    {
      text: 'Secundary',
      onClickEvent: () => alert('action Secondary'),
      color: 'actionSecondary' as const,
      variant: 'outlined' as const,
    },
    {
      text: 'Critical Text',
      onClickEvent: () => alert('action Critical'),
      color: 'actionCritical' as const,
      variant: 'text' as const,
    },
    {
      text: 'Disable',
      color: 'actionSecondary' as const,
      variant: 'contained' as const,
      disabled: true,
    },
  ];
  const loadingButtons = [
    {
      text: 'Loading Button',
      onClickEvent: updateLoadingState,
      color: 'actionPrimary' as const,
      variant: 'contained' as const,
      LoadingMessage: 'Loading...',
      loadingStatus: loadingState,
      isLoadingButton: true,
    },
    {
      text: 'Loading Button Two',
      color: 'actionSecondary' as const,
      variant: 'contained' as const,
      LoadingMessage: 'Loading...',
      loadingStatus: true,
      isLoadingButton: true,
    },
  ];
  const sizeButtons = [
    {
      text: 'Small',
      onClickEvent: undefined,
      color: 'actionPrimary' as const,
      variant: 'contained' as const,
      size: 'small' as const,
    },
    {
      text: 'Medium',
      onClickEvent: undefined,
      color: 'actionSecondary' as const,
      variant: 'outlined' as const,
      size: 'medium' as const,
    },
    {
      text: 'Large',
      onClickEvent: undefined,
      color: 'actionCritical' as const,
      variant: 'contained' as const,
      size: 'large' as const,
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Section>
          <Typography variant="strong">Button Group Default</Typography>
          <Column>
            <ButtonGroup buttons={buttons} />
          </Column>
        </Section>
        <Section>
          <Typography variant="strong">Button Group Direction Column</Typography>
          <Column>
            <ButtonGroup buttons={buttons} direction="column" spacing={1} />
          </Column>
        </Section>
        <Section>
          <Typography variant="strong">Loading Button Group</Typography>
          <Column>
            <ButtonGroup buttons={loadingButtons} />
          </Column>
        </Section>
        <Section>
          <Typography variant="strong">Size Button Group</Typography>
          <Column>
            <ButtonGroup buttons={sizeButtons} />
          </Column>
        </Section>
        <Section>
          <Typography variant="strong">Button Group Justified</Typography>
          <Column>
            <Typography variant="subtitle1">flex-start</Typography>
            <ButtonGroup buttons={buttons} justifyContent="flex-start" spacing={1} />
          </Column>
          <br />
          <Column>
            <Typography variant="subtitle1">flex-end</Typography>
            <ButtonGroup buttons={buttons.slice(0, 2)} justifyContent="flex-end" spacing={1} />
          </Column>
          <br />
          <Column>
            <Typography variant="subtitle1">space-between</Typography>
            <ButtonGroup buttons={buttons.slice(0, 2)} justifyContent="space-between" spacing={1} />
          </Column>
        </Section>
        <Section>
          <Typography variant="strong">Button Group Wrap</Typography>
          <Column>
            <Typography variant="subtitle1">wrap</Typography>
            <Box sx={{ width: '250px' }} p={2}>
              <ButtonGroup buttons={buttons} wrap="wrap" alignItems="flex-start" spacing={1} />
            </Box>
          </Column>
          <br />
          <Column>
            <Typography variant="subtitle1">wrap-reverse</Typography>
            <Box sx={{ width: '250px' }} p={2}>
              <ButtonGroup buttons={buttons} wrap="wrap-reverse" alignItems="flex-end" spacing={1} />
            </Box>
          </Column>
          <br />
          <Column>
            <Typography variant="subtitle1">nowrap</Typography>
            <Box sx={{ width: '500px' }} p={2}>
              <ButtonGroup buttons={buttons} wrap="nowrap" spacing={1} />
            </Box>
          </Column>
        </Section>
      </div>
    </>
  );
}

export default ButtonGroupDemo;
