import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

type SalaryWithSkillInfoPopoverProps = {
  salaryWithSkillHeaderAnchor: HTMLElement | null;
  handleSalaryWithSkillInfoPopoverClose: () => void;
};

const SalaryWithSkillInfoPopover = ({
  salaryWithSkillHeaderAnchor,
  handleSalaryWithSkillInfoPopoverClose,
}: SalaryWithSkillInfoPopoverProps): ReactElement => {
  const theme = useTheme();

  return (
    <Popover
      anchorEl={salaryWithSkillHeaderAnchor}
      open={!!salaryWithSkillHeaderAnchor}
      onClose={handleSalaryWithSkillInfoPopoverClose}
      disableScrollLock={true}
      sx={{ pointerEvents: 'none' }}
      disableAutoFocus={true}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <div style={{ maxWidth: '320px', padding: `${theme.customSpacing.px.xs}px` }}>
        <Typography variant={'strong'} component={'div'}>
          Salary with Skill:{' '}
          <Typography variant={'caption'} component={'div'}>
            Salary with Skill reflects the Nationwide Median Advertised Salary for the past year based on the selected
            Lightcast Job Title and the Lightcast Skill
          </Typography>
        </Typography>
      </div>
    </Popover>
  );
};

export default SalaryWithSkillInfoPopover;
