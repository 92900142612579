import axios from 'helpers/api_helper';

export type ApiToGet = 'roles' | 'skills' | 'titles' | 'classification' | 'ddn' | 'jpa';

const getMetadata = async (api: ApiToGet): Promise<any> => {
  const url = `${process.env.REACT_APP_API_ROOT}/admin/tools/api`;
  const res = await axios.post(url, null, { params: { api } });
  return res.data.apiMetaInfo;
};

export default {
  getMetadata,
};
