import React, { useEffect, useMemo, useState, useRef, ReactElement, ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';
import Typography from '@mui/material/Typography';
import axios from 'helpers/api_helper';
import ReactGA from 'react-ga';
import useCompanyContext from 'helpers/UseCompanyContext';
import uniqBy from 'lodash/uniqBy';
import { getPostings } from 'helpers/LaborSearchHelper';
import Role from 'classes/role';
import { GetPostingsCountRequestProps, PostingsCount } from 'types/types';
import CollapsibleAutocompleteRadioList from 'components/organisms/CollapsibleAutocompleteRadioList';
import useRoleEditContext from 'helpers/useRoleEditContext';
import Skeleton from '@mui/material/Skeleton';
import TitlesService from 'services/TitlesService';

let titleNormalizeRequestController = new AbortController();

type RoleMapFormProps = {
  role?: Role;
  recordModification: (newData: Role) => void;
  loading?: boolean;
};

type Title = {
  id: string;
  label: string | undefined;
  value: string;
  message: string | undefined;
  confidence?: number;
  ourPostings?: string;
  nationwidePostings?: string;
};

type Occupation = {
  id: string;
  label: string | undefined;
  value: string;
  message: string | undefined;
  ourPostings?: string;
  nationwidePostings?: string;
};

type SpecializedOccupation = {
  id: string;
  label: string | undefined;
  value: string;
  message: string | undefined;
  ourPostings?: string;
  nationwidePostings?: string;
};

const Wrapper = styled.div``;

const RoleMapForm = ({ role, recordModification, loading = false }: RoleMapFormProps): ReactElement => {
  const theme = useTheme();
  const currentTitleId = role ? role.emsiTitle?.id : null;
  const currentTitleName = role ? role.emsiTitle?.name : null;
  const currentOccupationId = role ? role.socName : null;
  const currentOccupationName = role ? role.socName : null;
  const currentSpecializedOccupationId = role ? role.specializedOccupationId : null;
  const currentSpecializedOccupationName = role ? role.specializedOccupationName : null;
  const { companyPostingsId } = useCompanyContext();
  const { isSkillsAndKeywordSearchEnabled, keywordSearchMatchedTitles } = useRoleEditContext();

  const [titleItems, setTitleItems] = useState<Title[]>([]);
  const [loadingTitleMessage, setLoadingTitleMessage] = useState('');
  const [normalizedTitlesRealtime, setNormalizedTitlesRealtime] = useState<Title[]>([]);
  const [normalizingTitle, setNormalizingTitle] = useState(false);

  const [occupationItems, setOccupationItems] = useState<Occupation[]>([]);
  const [loadingOccupationMessage, setLoadingOccupationMessage] = useState('');

  const [specializedOccupationItems, setSpecializedOccupationItems] = useState<SpecializedOccupation[]>([]);
  const [loadingSpecializedOccupationMessage, setLoadingSpecializedOccupationMessage] = useState('');

  useEffect(() => {
    if (loading) {
      setNormalizingTitle(true);
      setTitleItems([]);
    }
  }, [loading]);

  const roleId = useMemo(() => role && role.roleId, [role]);
  const jobTitle = useMemo(() => role && role.jobTitle, [role?.jobTitle]);

  let isFormMounted = true;
  const isFirstRender = useRef(true);

  const getPostingsCount = async ({
    facet,
    facetFilters,
    filter,
  }: GetPostingsCountRequestProps): Promise<PostingsCount[]> => {
    let postingsCount: any[] = [];
    try {
      const postings = await getPostings({
        facetName: facet,
        searchContext: { ...filter, allow_filter_facet: true },
        filterValues: facetFilters,
        limit: facetFilters.length,
      });
      postingsCount = postings.buckets;
    } catch (e) {
      console.error(e);
    }
    postingsCount = facetFilters.map(item => {
      return {
        name: item,
        uniquePostings: (postingsCount.find(postings => postings.name === item)?.unique_postings || 0).toLocaleString(),
      };
    });
    return postingsCount;
  };

  const getMappingItemsPostingCounts = async ({
    facet,
    facetFilters,
    filter,
  }: GetPostingsCountRequestProps): Promise<any> => {
    const ourPostingsCount = await getPostingsCount({
      facet,
      facetFilters,
      filter: {
        ...filter,
        company: [companyPostingsId],
      },
    });
    const nationwidePostingsCount = await getPostingsCount({
      facet,
      facetFilters,
      filter,
    });
    return { ourPostingsCount, nationwidePostingsCount };
  };

  const setPostingsCount = (
    mappingItems: Title[] | Occupation[] | SpecializedOccupation[],
    mappingItemKey: 'value' | 'label',
    ourPostings: PostingsCount[],
    nationwidePostings: PostingsCount[]
  ) => {
    return mappingItems.map(mappingItem => {
      return {
        ...mappingItem,
        ourPostings: ourPostings.find(postings => postings.name === mappingItem[`${mappingItemKey}`])?.uniquePostings,
        nationwidePostings: nationwidePostings.find(postings => postings.name === mappingItem[`${mappingItemKey}`])
          ?.uniquePostings,
      };
    });
  };

  const getTitleMessage = (itemId): string => {
    let message = '';
    if (role?.emsiTitle?.id === itemId) {
      message = 'Current Value';
    }
    if (role?.matchedTitles[0]?.id === itemId && itemId !== 'ET0000000000000000') {
      message = 'Top Match';
    }
    return message;
  };

  const getNormalizedTitlesRealtime = async () => {
    setNormalizingTitle(true);
    titleNormalizeRequestController?.abort();
    titleNormalizeRequestController = new AbortController();
    let titles: any[] = [];
    try {
      titles = await TitlesService.normalizeTitle(jobTitle, 5, titleNormalizeRequestController);
    } catch (e: any) {
      console.error(e);
    }
    setNormalizingTitle(false);
    const normalizedTitles = titles.map((item): Title => {
      return {
        id: item.id,
        label: item.normalizedTitle,
        value: item.id,
        confidence: item.confidence,
        message: getTitleMessage(item.id),
      };
    });
    setNormalizedTitlesRealtime(normalizedTitles);
  };

  const updateTitleItems = async (titles: Title[]) => {
    if (isFormMounted) {
      setTitleItems(titles);
      titles.length > 0 && setLoadingTitleMessage('loading title options...');
    }
    const { ourPostingsCount, nationwidePostingsCount } = await getMappingItemsPostingCounts({
      facet: 'title',
      facetFilters: Array.from(titles, item => item.value),
    });

    titles = setPostingsCount(titles, 'value', ourPostingsCount, nationwidePostingsCount);
    if (isFormMounted) {
      setLoadingTitleMessage('');
      setTitleItems(titles);
    }
  };

  const socSuggestions = async (titleId, occs = occupationItems) => {
    if (!titleId) return;
    if (isFormMounted) {
      setLoadingOccupationMessage('loading related occupations...');
    }
    let occupations: any[] = [];
    const currentOccupationItem = occs.filter(item => item.message === 'Current Value');
    const currentSelectedOccupationItem = occs.filter(item => item.label === currentOccupationId);
    const manuallyAddedOccupationItems = occs.filter(item => item.message === 'Manually Added');
    try {
      const titleResp = await axios.get(`${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/titles/get/${titleId}`);
      const relatedSocs =
        titleResp?.data.name === 'Unclassified'
          ? null
          : await axios.get(`${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/socs?titleId=${titleId}`);
      let occSuggestions: any[] = [];
      if (titleResp?.status === 200 && titleResp.data.mapping) {
        occSuggestions = titleResp.data.mapping.socs || [];
      }
      if (relatedSocs?.status === 200) {
        occSuggestions = occSuggestions.concat(relatedSocs.data.socs || []);
      }
      occSuggestions = occSuggestions.map(item => ({
        label: item.name,
        value: item.name,
        id: item.id,
      }));
      occupations = new Array<any>().concat(
        currentOccupationItem,
        currentSelectedOccupationItem,
        occSuggestions,
        manuallyAddedOccupationItems
      );
      occupations = uniqBy(occupations, 'label');
      occupations = occupations.map(item => ({
        ...item,
        ourPostings: undefined,
        nationwidePostings: undefined,
      }));
      ReactGA.event({
        category: 'role mapping modal',
        action: 'get occupation suggestions',
        label: `suggestions: ${occSuggestions.length}`,
      });
    } catch (e) {
      console.error(e);
    }
    if (isFormMounted) {
      setOccupationItems(occupations);
      occupations.length > 0 && setLoadingOccupationMessage('loading occupation counts...');
    }
    const { ourPostingsCount, nationwidePostingsCount } = await getMappingItemsPostingCounts({
      facet: 'soc5_name',
      facetFilters: Array.from(occupations, item => item.label),
    });
    occupations = setPostingsCount(occupations, 'label', ourPostingsCount, nationwidePostingsCount);
    if (isFormMounted) {
      setLoadingOccupationMessage('');
      setOccupationItems(occupations);
    }
  };

  const specializedOccupationSuggestions = async (titleId: string | null, specOccs = specializedOccupationItems) => {
    if (!titleId) return;
    if (isFormMounted) {
      setLoadingSpecializedOccupationMessage('loading related specialized occupations...');
    }
    let specializedOccupations: any[] = [];
    const currentSpecializedOccupationItem = specOccs.filter(item => item.message === 'Current Value');
    const currentSelectedSpecializedOccupationItem = specOccs.filter(
      item => item.label === currentSpecializedOccupationName
    );
    const manuallyAddedSpecializedOccupationItems = specOccs.filter(item => item.message === 'Manually Added');
    try {
      const titleResp = await axios.get(`${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/titles/get/${titleId}`);
      const relatedSpecializedOccupations =
        titleResp?.data.name === 'Unclassified'
          ? null
          : await axios.get(`${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/specialized_occupations?titleId=${titleId}`);
      let specializedOccupationSuggestions: any[] = [];
      if (relatedSpecializedOccupations?.status === 200) {
        specializedOccupationSuggestions = specializedOccupationSuggestions.concat(
          relatedSpecializedOccupations.data.specialized_occupations || []
        );
      }
      specializedOccupationSuggestions = specializedOccupationSuggestions.map(item => ({
        label: item.name,
        value: item.name,
        id: item.id,
      }));
      specializedOccupations = new Array<any>().concat(
        currentSpecializedOccupationItem,
        currentSelectedSpecializedOccupationItem,
        specializedOccupationSuggestions,
        manuallyAddedSpecializedOccupationItems
      );
      specializedOccupations = uniqBy(specializedOccupations, 'label');
      specializedOccupations = specializedOccupations.map(item => ({
        ...item,
        ourPostings: undefined,
        nationwidePostings: undefined,
      }));
      ReactGA.event({
        category: 'role mapping modal',
        action: 'get specialized occupation suggestions',
        label: `suggestions: ${specializedOccupationSuggestions.length}`,
      });
    } catch (e) {
      console.error(e);
    }
    if (isFormMounted) {
      setSpecializedOccupationItems(specializedOccupations);
      specializedOccupations.length > 0 &&
        setLoadingSpecializedOccupationMessage('loading specialized occupation counts...');
    }
    const { ourPostingsCount, nationwidePostingsCount } = await getMappingItemsPostingCounts({
      facet: 'lot_specialized_occupation_name',
      facetFilters: Array.from(specializedOccupations, item => item.label),
    });
    specializedOccupations = setPostingsCount(
      specializedOccupations,
      'label',
      ourPostingsCount,
      nationwidePostingsCount
    );
    if (isFormMounted) {
      setLoadingSpecializedOccupationMessage('');
      setSpecializedOccupationItems(specializedOccupations);
    }
  };

  useEffect(() => {
    const loadNormalizedTitlesRealtime = async () => {
      await getNormalizedTitlesRealtime();
    };
    loadNormalizedTitlesRealtime();
  }, [jobTitle, roleId]);

  useEffect(() => {
    const loadTitleItems = () => {
      const titles =
        isSkillsAndKeywordSearchEnabled && keywordSearchMatchedTitles?.length
          ? keywordSearchMatchedTitles
          : normalizedTitlesRealtime;
      const currentIndex = titles.findIndex(item => item.value === currentTitleId);
      if (currentTitleId) {
        // remove the current value so it can go to the top
        if (currentIndex > -1) {
          titles.splice(currentIndex, 1);
        }
        const currentTitleItem = {
          id: role?.emsiTitle?.id || '',
          label: role?.emsiTitle?.name || role?.emsiTitle?.id,
          value: role?.emsiTitle?.id || '',
          message: getTitleMessage(role?.emsiTitle?.id),
        };
        titles.unshift(currentTitleItem);
      }
      updateTitleItems(titles);
    };
    loadTitleItems();

    const loadOccupationItems = () => {
      let soc: Occupation[] = [];
      if (role && currentOccupationId !== 'n/a') {
        soc = [
          {
            label: role.socName || role.soc,
            value: role.socName || '',
            id: role.soc || '',
            message: 'Current Value',
          },
        ];
      }
      if (isFormMounted) {
        setOccupationItems(soc);
        socSuggestions(currentTitleId, soc);
      }
    };
    loadOccupationItems();

    const loadSpecializedOccupationItems = () => {
      let specializedOccupation: SpecializedOccupation[] = [];
      if (role && currentSpecializedOccupationId) {
        specializedOccupation = [
          {
            label: role.specializedOccupationName,
            value: role.specializedOccupationId,
            id: role.specializedOccupationId,
            message: 'Current Value',
          },
        ];
      }
      if (isFormMounted) {
        setSpecializedOccupationItems(specializedOccupation);
        specializedOccupationSuggestions(currentTitleId, specializedOccupation);
      }
    };
    loadSpecializedOccupationItems();

    return () => {
      isFormMounted = false;
    };
  }, [roleId, normalizedTitlesRealtime]);

  useEffect(() => {
    if (keywordSearchMatchedTitles && keywordSearchMatchedTitles.length === 0) return;

    if (isFirstRender.current) return;

    const currentSelectedTitleItem = currentTitleId
      ? {
          id: currentTitleId,
          label: currentTitleName,
          value: currentTitleId,
          message: titleItems.find(titleItem => titleItem.id === currentTitleId)?.message,
        }
      : null;
    const manuallyAddedTitleItems = titleItems.filter(titleItem => titleItem.message === 'Manually Added');

    let titles: any[] = [];
    if (keywordSearchMatchedTitles) {
      titles = keywordSearchMatchedTitles;
    } else {
      titles = normalizedTitlesRealtime;
    }
    titles = new Array<any>().concat(titles, manuallyAddedTitleItems);

    if (currentSelectedTitleItem) {
      titles.unshift(currentSelectedTitleItem);
    }

    // remove duplicates
    // reverse is set inorder to persist manually added items, remove other title groups (normalized, keywords search titles)
    titles = uniqBy(titles.reverse(), 'id').reverse();
    updateTitleItems(titles);
  }, [keywordSearchMatchedTitles, normalizedTitlesRealtime]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  });

  // if there is no role, let's render and be done
  if (!role) {
    return <div style={{ padding: '12px' }}>no role selected</div>;
  }

  const handleTitleSelect = async (title: any) => {
    const titleSource =
      title.source === 'autocomplete'
        ? 'autocomplete'
        : Array.from(keywordSearchMatchedTitles || [], (item: any) => item?.id).includes(title.key)
          ? 'keywords search'
          : 'radio list';
    ReactGA.event({
      category: 'role mapping modal',
      action: 'mapped title changed',
      label: titleSource,
    });

    const label = titleSource === 'autocomplete' ? title.label : title.value;
    const value = titleSource === 'autocomplete' ? title.value.id : title.key;

    let titleConfidence: number | undefined = -1;
    if (titleSource === 'autocomplete' || titleSource === 'keywords search') {
      // autocomplete, keywords search => manual change
      titleConfidence = -1;
    } else if (titleSource === 'radio list') {
      // radio list => from normalized titles | previous manually set titles
      titleConfidence =
        normalizedTitlesRealtime.find(normalizedTitle => normalizedTitle.id === value)?.confidence || -1;
    }
    recordModification(new Role({ emsiTitleName: label, emsiTitleId: value, titleConfidence }));

    if (value && titleItems.findIndex(o => o.value === value) === -1) {
      setLoadingTitleMessage('loading title...');
      const { ourPostingsCount, nationwidePostingsCount } = await getMappingItemsPostingCounts({
        facet: 'title',
        facetFilters: [value],
      });
      titleItems.push({
        id: value,
        label,
        value,
        message: 'Manually Added',
        ourPostings: ourPostingsCount.find(postings => postings.name === value)?.uniquePostings,
        nationwidePostings: nationwidePostingsCount.find(postings => postings.name === value)?.uniquePostings,
      });
      setLoadingTitleMessage('');
    }
    // load soc suggestions for selected title
    socSuggestions(value);
    // load specialized occupation suggestions for selected title
    specializedOccupationSuggestions(value);
  };

  const handleOccupationSelect = async (occupation: any) => {
    ReactGA.event({
      category: 'role mapping modal',
      action: 'mapped occupation changed',
      label: occupation.source,
    });
    const label =
      occupation.source !== 'autocomplete' ? occupation.value : occupation.name ? occupation.name : occupation.label;
    const value = occupation.source === 'autocomplete' ? occupation.value.id : occupation.key;
    const soc = occupationItems.find(socItem => socItem.label === label)?.id || value;
    recordModification(new Role({ socName: label, soc }));
    if (label && occupationItems.findIndex(o => o.label === label) === -1) {
      setLoadingOccupationMessage('loading occupation...');
      const { ourPostingsCount, nationwidePostingsCount } = await getMappingItemsPostingCounts({
        facet: 'soc5_name',
        facetFilters: [label],
      });
      occupationItems.push({
        label,
        value: label,
        id: soc,
        message: 'Manually Added',
        ourPostings: ourPostingsCount.find(postings => postings.name === label)?.uniquePostings,
        nationwidePostings: nationwidePostingsCount.find(postings => postings.name === label)?.uniquePostings,
      });
      setLoadingOccupationMessage('');
    }
  };

  const handleSpecializedOccupationSelect = async (specializedOccupation: any) => {
    ReactGA.event({
      category: 'role mapping modal',
      action: 'mapped specialized occupation changed',
      label: specializedOccupation.source,
    });
    const label =
      specializedOccupation.source === 'autocomplete' ? specializedOccupation.label : specializedOccupation.value;
    const value =
      specializedOccupation.source === 'autocomplete' ? specializedOccupation.value.id : specializedOccupation.key;
    const specOcc =
      specializedOccupationItems.find(specializedOccupationItem => specializedOccupationItem.label === label)?.id ||
      value;
    recordModification(new Role({ specializedOccupationName: label, specializedOccupationId: specOcc }));
    if (label && specializedOccupationItems.findIndex(o => o.label === label) === -1) {
      setLoadingSpecializedOccupationMessage('loading specialized occupation...');
      const { ourPostingsCount, nationwidePostingsCount } = await getMappingItemsPostingCounts({
        facet: 'lot_specialized_occupation_name',
        facetFilters: [label],
      });
      specializedOccupationItems.push({
        label,
        value,
        id: specOcc,
        message: 'Manually Added',
        ourPostings: ourPostingsCount.find(postings => postings.name === label)?.uniquePostings,
        nationwidePostings: nationwidePostingsCount.find(postings => postings.name === label)?.uniquePostings,
      });
      setLoadingSpecializedOccupationMessage('');
    }
  };

  const autocompleteItems = async (
    autocompleteItem: 'titles' | 'socs' | 'specialized_occupations',
    q: string,
    showId = false
  ) => {
    const results = await axios.get(
      `${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/${autocompleteItem}/autocomplete?q=${encodeURIComponent(q)}`
    );

    if (showId)
      return results.data[`${autocompleteItem}`].map(i => ({ value: i, name: i.name, label: `${i.name} (${i.id})` }));
    return results.data[`${autocompleteItem}`].map(i => ({ value: i, label: i.name }));
  };

  const postingsHeaderComponent = (dataCy: string): ReactNode => {
    return (
      <>
        <div style={{ display: 'flex', width: '50%', float: 'right' }}>
          <Typography
            variant="caption"
            component={'div'}
            sx={{ width: '50%', textAlign: 'right' }}
            color={theme.colors.text.subdued}
            data-cy={`role-mapping-modal-${dataCy}-textbox-our-postings-header`}
          >
            Our Postings
          </Typography>
          <Typography
            variant="caption"
            component={'div'}
            sx={{ width: '50%', textAlign: 'right' }}
            color={theme.colors.text.subdued}
            data-cy={`role-mapping-modal-${dataCy}-textbox-nationwide-postings-header`}
          >
            Nationwide
          </Typography>
        </div>
      </>
    );
  };

  const radioListItemsComponent = (radioListItems: Title[] | Occupation[], showId = false): ReactNode[] => {
    return radioListItems.map(radioListItem => {
      return (
        <>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Typography component={'div'} sx={{ width: '50%' }}>
              {`${radioListItem.label} ${showId ? `(${radioListItem.id})` : ''}`}
            </Typography>
            <div style={{ display: 'flex', width: '50%', float: 'right' }}>
              <Typography
                variant="caption"
                component={'div'}
                sx={{ width: '50%', textAlign: 'right' }}
                color={theme.colors.text.subdued}
              >
                {radioListItem.ourPostings || '-'}
              </Typography>
              <Typography
                variant="caption"
                component={'div'}
                sx={{ width: '50%', textAlign: 'right' }}
                color={theme.colors.text.subdued}
              >
                {radioListItem.nationwidePostings || '-'}
              </Typography>
            </div>
          </div>
          {radioListItem.message && (
            <Typography
              variant="caption"
              component={'div'}
              color={theme.colors.text.information}
            >{`(${radioListItem.message})`}</Typography>
          )}
        </>
      );
    });
  };

  const loadingSkeletonList = Array.from(Array(3).keys()).map(i => {
    return {
      key: `${i}`,
      value: `${i}`,
    };
  });

  const loadingSkeletonComponent = Array.from(Array(3).keys()).map(i => {
    return (
      <>
        <div key={i} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Skeleton width={'55%'}>
            <Typography>{'.'}</Typography>
          </Skeleton>
          <div style={{ display: 'flex', width: '40%', justifyContent: 'space-between' }}>
            <Skeleton width={'40%'}>
              <Typography variant="caption">{'.'}</Typography>
            </Skeleton>
            <Skeleton width={'40%'}>
              <Typography variant="caption">{'.'}</Typography>
            </Skeleton>
          </div>
        </div>
      </>
    );
  });

  return (
    <Wrapper>
      <CollapsibleAutocompleteRadioList
        title="Select a Job Title"
        description="These are the job title suggestions for your role with the number of postings that are typically posted for this title in your company as well as nation-wide."
        placeholder="Search for a Job Title"
        collapse={false}
        autocompleteFunction={q => autocompleteItems('titles', q)}
        autocompleteOnChange={handleTitleSelect}
        radioListItemsHeaderComponent={titleItems.length > 0 && postingsHeaderComponent('job-title')}
        radioListItemsComponent={
          loading || normalizingTitle ? loadingSkeletonComponent : radioListItemsComponent(titleItems)
        }
        radioListItemsLoadingMessage={loadingTitleMessage}
        radioItemSelected={currentTitleName || undefined}
        radioListItems={
          loading || normalizingTitle
            ? loadingSkeletonList
            : titleItems.map(item => {
                return { key: item.id, value: item.label || '' };
              })
        }
        radioItemsSelector="Title"
        radioListItemOnClick={handleTitleSelect}
        dataCy={'role-mapping-modal-job-title-textbox'}
        keyId={role.roleId}
      />

      <CollapsibleAutocompleteRadioList
        title="Map to a Government Code (SOC)"
        description="These are the SOC code suggestions for your role with the number of postings that are typically posted for this title in your company as well as nation-wide."
        placeholder="Search for a Job Occupation"
        collapse={false}
        autocompleteFunction={q => autocompleteItems('socs', q, true)}
        autocompleteOnChange={handleOccupationSelect}
        radioListItemsHeaderComponent={occupationItems.length > 0 && postingsHeaderComponent('soc-name')}
        radioListItemsComponent={loading ? loadingSkeletonComponent : radioListItemsComponent(occupationItems, true)}
        radioListItemsLoadingMessage={loadingOccupationMessage}
        radioItemSelected={currentOccupationName || undefined}
        radioListItems={
          loading
            ? loadingSkeletonList
            : occupationItems.map(item => {
                return { key: item.id, value: item.label || '' };
              })
        }
        radioItemsSelector="Occupation"
        radioListItemOnClick={handleOccupationSelect}
        dataCy={'role-mapping-modal-soc-name-textbox'}
        keyId={role.roleId}
      />

      <CollapsibleAutocompleteRadioList
        title="Map to a Specialized Occupation"
        description="These are specialized occupations for your role with the number of postings that are typically posted for this title in your company as well as nation-wide."
        placeholder="Search for a Specialized Occupation"
        collapse={false}
        autocompleteFunction={q => autocompleteItems('specialized_occupations', q)}
        autocompleteOnChange={handleSpecializedOccupationSelect}
        radioListItemsHeaderComponent={
          specializedOccupationItems.length > 0 && postingsHeaderComponent('specialized-occupation')
        }
        radioListItemsComponent={
          loading ? loadingSkeletonComponent : radioListItemsComponent(specializedOccupationItems)
        }
        radioListItemsLoadingMessage={loadingSpecializedOccupationMessage}
        radioItemSelected={currentSpecializedOccupationName || undefined}
        radioListItems={
          loading
            ? loadingSkeletonList
            : specializedOccupationItems.map(item => {
                return { key: item.id, value: item.label || '' };
              })
        }
        radioItemsSelector="Specialized Occupation"
        radioListItemOnClick={handleSpecializedOccupationSelect}
        dataCy={'role-mapping-modal-specialized-occupation-textbox'}
        keyId={role.roleId}
      />
    </Wrapper>
  );
};

export default RoleMapForm;
