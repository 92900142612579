import React, { ReactElement } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';

export type ButtonColor = 'actionPrimary' | 'actionSecondary' | 'actionCritical';
type ButtonVariant = 'contained' | 'outlined' | 'text';
type ButtonSize = 'small' | 'medium' | 'large';
type Direction = 'row' | 'column';
type JustifyContent = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
type AlignItems = 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
type Wrap = 'nowrap' | 'wrap-reverse' | 'wrap';

export type EMSIBGButton = {
  text: string;
  onClickEvent?: (event: any) => void;
  color?: ButtonColor;
  variant?: ButtonVariant;
  size?: ButtonSize;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  loadingMessage?: string;
  loadingStatus?: boolean;
  isLoadingButton?: boolean;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  dataCy?: string;
  visible?: boolean;
  tabIndex?: number;
  autoFocus?: boolean;
  width?: string;
};

type ButtonGroupProps = {
  buttons: EMSIBGButton[];
  direction?: Direction;
  spacing?: number;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  wrap?: Wrap;
  width?: string;
};

const ButtonGroup = (props: ButtonGroupProps): ReactElement => {
  const {
    buttons,
    direction = 'row',
    spacing = 1,
    justifyContent = 'center',
    alignItems = 'center',
    wrap = 'wrap',
    width = 'auto',
  } = props;

  return (
    <Grid
      container
      className="button-group"
      justifyContent={justifyContent}
      alignItems={alignItems}
      spacing={spacing}
      direction={direction}
      wrap={wrap}
      width={width}
    >
      {buttons.map(button => {
        const {
          text,
          onClickEvent,
          color,
          variant,
          size,
          type,
          disabled,
          loadingMessage = undefined,
          loadingStatus = undefined,
          isLoadingButton = false,
          startIcon,
          endIcon,
          dataCy,
          visible = true,
          tabIndex = 0,
          autoFocus = false,
          width = 'auto',
        } = button;
        return (
          visible && (
            <Grid key={text} item>
              {isLoadingButton ? (
                <LoadingButton
                  onClick={onClickEvent}
                  color={color}
                  variant={variant}
                  size={size}
                  type={type}
                  disabled={disabled}
                  loading={loadingStatus}
                  loadingIndicator={loadingMessage}
                  startIcon={startIcon}
                  endIcon={endIcon}
                  data-cy={dataCy}
                  tabIndex={tabIndex}
                  autoFocus={autoFocus}
                  sx={{ width }}
                >
                  {text}
                </LoadingButton>
              ) : (
                <Button
                  onClick={onClickEvent}
                  color={color}
                  variant={variant}
                  size={size}
                  type={type}
                  disabled={disabled}
                  startIcon={startIcon}
                  endIcon={endIcon}
                  data-cy={dataCy}
                  tabIndex={tabIndex}
                  autoFocus={autoFocus}
                  sx={{ width }}
                >
                  {text}
                </Button>
              )}
            </Grid>
          )
        );
      })}
    </Grid>
  );
};

export default ButtonGroup;
