import axios from 'helpers/api_helper';
import Benchmark from 'classes/benchmark';

const getBenchmark = async (companyId, benchmarkId) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/benchmarks/${benchmarkId}`;
  const resp = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const benchmark = Benchmark.fromJson(resp.data);

  return benchmark;
};

const getBenchmarks = async (companyId, limit = 100) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/benchmarks`;
  const resp = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      limit,
    },
  });

  const rawBenchmarks = resp.data.benchmarks || [];
  const benchmarks = rawBenchmarks.map(r => Benchmark.fromJson(r));

  return {
    benchmarks,
    moreResults: resp.data.moreResults,
    nextPageOffsetBenchmarkId: resp.data.nextPageOffsetBenchmarkId,
  };
};

const createBenchmark = async ({ companyId, benchmark }) => {
  await axios.post(`${process.env.REACT_APP_API_ROOT}/companies/${companyId}/benchmarks`, {
    name: benchmark.name,
    locations: benchmark.locations,
    companies: benchmark.companies,
    industries: benchmark.industries,
    tags: benchmark.tags,
  });
};

const deleteBenchmark = async ({ companyId, benchmarkId }) => {
  await axios.delete(`${process.env.REACT_APP_API_ROOT}/companies/${companyId}/benchmarks/${benchmarkId}`);
};

const updateBenchmark = async ({ companyId, benchmarkId, updateData }) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/benchmarks/${benchmarkId}`;
  const headers = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const resp = await axios.patch(url, { updateData }, headers);

  return resp.data.success;
};

const updateBenchmarkAttribute = async (companyId, benchmarkId, attribute, updateData) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/benchmarks/${benchmarkId}/${attribute}`;
  const resp = await axios.put(
    url,
    { updateData },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return resp.data.success;
};

export default {
  getBenchmark,
  getBenchmarks,
  updateBenchmarkAttribute,
  updateBenchmark,
  createBenchmark,
  deleteBenchmark,
};
