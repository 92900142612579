import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import HeaderBar from 'components/organisms/HeaderBar';
import Footer from 'components/molecules/Footer';
import { Helmet } from 'react-helmet-async';

interface PageBaseTemplateProps {
  children: ReactNode;
  pageTitle: string;
  showHeader?: boolean;
  showFooter?: boolean;
}

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  font-family: ${props => props.theme.customTypography.fontFamily};
  background-color: ${props => props.theme.colors.background.default};
`;
const PageBaseTemplate = ({
  children,
  pageTitle,
  showHeader = true,
  showFooter = true,
}: PageBaseTemplateProps): ReactElement => {
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Wrapper>
        {showHeader && <HeaderBar />}
        {children}
        {showFooter && <Footer />}
      </Wrapper>
    </>
  );
};

export default PageBaseTemplate;
