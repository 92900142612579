import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import cloneDeep from 'lodash/cloneDeep';
import FormikTextField from 'components/atoms/formElements/FormikTextField';
import FormikRadioList from 'components/atoms/formElements/FormikRadioList';
import DialogModal from 'components/molecules/DialogModal';
import UseAuthContext from 'helpers/UseAuthContext';
import axios from 'helpers/api_helper';
import 'pages/ProfilePage.css';
import 'components/organisms/Form.css';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import FormikContentColumn2Template from 'pages/templates/ContentColumn2Template';
import { EMSIBGButton } from 'components/atoms/ButtonGroup';

type UserFormProps = {
  originalProfile: any;
  profileCompanyId: string;
  profileId: string;
  userDetailsChangedHandler: (detailsChanges: boolean) => void;
  reactEvent: (action: string, label: string) => void;
  handleSaveSuccess: (userId: string) => void;
  confirmCancelModalOpen: boolean;
  openConfirmCancelModal: (action: 'cancel' | 'back') => void;
  closeConfirmCancelModal: (action: 'cancel' | 'confirm') => void;
  popupPrimaryMessage: string;
  popupTitle: string;
};

const UserForm: React.FC<UserFormProps> = ({
  originalProfile,
  profileCompanyId,
  profileId,
  userDetailsChangedHandler,
  reactEvent,
  handleSaveSuccess,
  confirmCancelModalOpen,
  openConfirmCancelModal,
  closeConfirmCancelModal,
  popupPrimaryMessage,
  popupTitle,
}) => {
  const navigate = useNavigate();
  const { isEmsiAdmin } = UseAuthContext();

  const [currentUserProfileCopy, setCurrentUserProfileCopy] = useState<any>();
  const [formValues, setFormValues] = useState<any>();
  const [statusMessage, setStatusMessage] = useState<string>();
  const [isError, setIsError] = useState(false);
  const [userDetailsChanged, setUserDetailsChanged] = useState(false);
  const [newUserAdded, setNewUserAdded] = useState(false);
  const [hasFormBeenSubmitted, setHasFormBeenSubmitted] = useState(false);

  // Copy of selected user profile
  useEffect(() => {
    if (originalProfile) {
      const tempProfileCopy = { ...cloneDeep(originalProfile) };
      setCurrentUserProfileCopy(tempProfileCopy);
    }
  }, [originalProfile]);

  // Sets values for Formik fields
  useEffect(() => {
    if (currentUserProfileCopy) {
      const formFields = {
        firstName: `${currentUserProfileCopy.details.first_name}`,
        lastName: `${currentUserProfileCopy.details.last_name}`,
        email: `${currentUserProfileCopy.details.email}`,
        role: `${currentUserProfileCopy.role}`,
      };

      setFormValues(formFields);
    }
  }, [currentUserProfileCopy]);

  useEffect(() => {
    if (userDetailsChanged) {
      setStatusMessage('');
      setIsError(false);
    }
  }, [userDetailsChanged]);

  const cancelUserChanges = () => {
    if (userDetailsChanged) {
      openConfirmCancelModal('cancel');
    } else {
      navigate('/admin/user-management');
    }
  };

  // Update temp profile with selected user role
  const roleClickHandler = ({ value }, formData: any) => {
    const tmpCurrentUserProfileCopy = { ...currentUserProfileCopy };
    tmpCurrentUserProfileCopy.role = value;
    setCurrentUserProfileCopy(tmpCurrentUserProfileCopy);
    setUserDetailsChanged(formData.form.dirty || value !== formData.meta.initialValue);
    userDetailsChangedHandler(formData.form.dirty || value !== formData.meta.initialValue);
  };

  const saveUserDetails = async (values, { resetForm }) => {
    try {
      const userId = newUserAdded ? values.email : profileId;
      const url = `${process.env.REACT_APP_API_ROOT}/companies/${profileCompanyId}/user/${userId}`;
      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        role: values.role,
      };
      await axios.put(url, data);

      setStatusMessage('Changes saved successfully.');
      setIsError(false);
      setUserDetailsChanged(false);
      handleSaveSuccess(userId);
      resetForm({
        values: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          role: values.role,
        },
      });
      reactEvent('edit', 'success');
      setHasFormBeenSubmitted(true);
    } catch (e: any) {
      setStatusMessage('Unable to save changes.  Try again later.');
      setIsError(true);
      setUserDetailsChanged(false);

      reactEvent('edit', `failure-${e.code}`);
    }
  };

  const createNewUser = async (values, { resetForm }) => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT}/companies/${originalProfile.company_id}/user/`;
      const data = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        role: values.role,
      };
      await axios.post(url, data);

      setStatusMessage(`User ${values.email} has been added successfully.  Welcome email has been sent to the user.`);
      setIsError(false);
      setUserDetailsChanged(false);
      handleSaveSuccess(values.email);
      resetForm({
        values: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          role: values.role,
        },
      });
      reactEvent('add', 'success');
      setNewUserAdded(true);
      setHasFormBeenSubmitted(true);
    } catch (e: any) {
      if (e.response.status === 409) {
        setStatusMessage('Unable to add user: Email account already exists');
      } else {
        setStatusMessage('Unable to add user.  Try again later.');
      }
      setIsError(true);
      setUserDetailsChanged(false);

      reactEvent('add', `failure-${e.code}`);
    }
  };

  const saveUser = async (values, { resetForm }) => {
    if (values) {
      if (profileId || newUserAdded) {
        await saveUserDetails(values, { resetForm });
      } else {
        await createNewUser(values, { resetForm });
      }
    }
  };

  const resetStatusMessage = () => {
    setStatusMessage('');
    setIsError(false);
  };

  const handleInputTextUpdate = async (target, fieldMeta, field, form) => {
    const tmpCurrentProfileCopy = { ...currentUserProfileCopy };
    switch (target) {
      case 'firstName':
      case 'lastName':
      case 'email':
        tmpCurrentProfileCopy.details.email = field.value;
        setCurrentUserProfileCopy(tmpCurrentProfileCopy);
        setUserDetailsChanged(form.dirty);
        userDetailsChangedHandler(form.dirty);
        break;
      default:
        break;
    }
    switch (target) {
      case 'firstName':
        tmpCurrentProfileCopy.details.first_name = field.value;
        break;
      case 'lastName':
        tmpCurrentProfileCopy.details.last_name = field.value;
        break;
      case 'email':
        tmpCurrentProfileCopy.details.email = field.value;
        break;
      default:
        break;
    }
  };

  const rolesList = useMemo(() => {
    const roles = [
      {
        label: 'Viewer:',
        message: 'Can only view data',
        value: 'analysts_read_only',
      },
      {
        label: 'Analyst:',
        message: 'Can view all data and make changes',
        value: 'analysts',
      },
      {
        label: 'Admin:',
        message: 'Can view/edit data and manage company information',
        value: 'company_administrators',
      },
    ];
    if (isEmsiAdmin()) {
      roles.push({
        label: 'Lightcast Admin*:',
        message: 'Sees and view/edit everything across all organizations',
        value: 'emsi_super_users',
      });
    }
    return roles;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dialogBtnGroup = [
    {
      text: 'Cancel',
      onClickEvent: () => closeConfirmCancelModal('cancel'),
      dataCy: 'button-dialog-cancel',
    },
    {
      text: 'Continue',
      variant: 'contained',
      color: 'actionPrimary',
      onClickEvent: () => closeConfirmCancelModal('confirm'),
      dataCy: 'button-dialog-continue',
    },
  ] as EMSIBGButton[];
  return (
    <>
      {formValues !== undefined && !!Object.keys(formValues).length && (
        <Formik
          initialValues={formValues}
          validationSchema={Yup.object({
            firstName: Yup.string()
              .min(2, 'Must be at least 2 characters long')
              .max(100, 'Must be less than 100 characters long')
              .required('*'),
            lastName: Yup.string()
              .min(2, 'Must be at least 2 characters long')
              .max(100, 'Must be less than 100 characters long')
              .required('*'),
            email: Yup.string().email('Invalid email').required('*'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            await saveUser(values, { resetForm });
            setSubmitting(false);
          }}
        >
          {props => (
            <StyledForm data-cy="form-user-data">
              <FormikContentColumn2Template
                paddingVariant="wide"
                mainComponent={
                  <>
                    <InputUsernamesSection>
                      <FormikTextField
                        handleInputTextUpdate={handleInputTextUpdate}
                        name="firstName"
                        dataCy="input-first-name"
                        label="First Name"
                        autoFocus={true}
                        required={true}
                        readOnly={props.isSubmitting}
                      />
                      <FormikTextField
                        handleInputTextUpdate={handleInputTextUpdate}
                        dataCy="input-last-name"
                        label="Last Name"
                        name="lastName"
                        onFocus={resetStatusMessage}
                        required={true}
                        readOnly={props.isSubmitting}
                      />
                    </InputUsernamesSection>
                    <FormikTextField
                      handleInputTextUpdate={handleInputTextUpdate}
                      dataCy="input-email"
                      label="Email"
                      name="email"
                      onFocus={resetStatusMessage}
                      readOnly={!!(profileId || newUserAdded || props.isSubmitting)}
                      required={true}
                    />
                    {currentUserProfileCopy && (
                      <DialogModal
                        dialogOpen={confirmCancelModalOpen}
                        closeModal={() => closeConfirmCancelModal('cancel')}
                        title={popupTitle}
                        content={
                          <>
                            {popupPrimaryMessage}
                            <br />
                            WARNING: all changes will be lost
                          </>
                        }
                        buttonGroup={dialogBtnGroup}
                      />
                    )}
                  </>
                }
                sideComponent={
                  <FormikRadioList
                    label="Role"
                    items={rolesList}
                    disabled={props.isSubmitting}
                    selectedValue={formValues?.role}
                    name="role"
                    onChange={roleClickHandler}
                    onFocus={resetStatusMessage}
                  />
                }
                actionComponent={
                  <>
                    <Stack direction="row" spacing={1}>
                      <LoadingButton
                        color="actionPrimary"
                        data-cy="button-save-form"
                        loading={props?.isSubmitting}
                        disabled={!props?.dirty || !props?.isValid}
                        onBlur={resetStatusMessage}
                        type="submit"
                      >
                        Save
                      </LoadingButton>
                      {(!hasFormBeenSubmitted || props?.dirty) && (
                        <Button data-cy="button-cancel-form" onClick={cancelUserChanges}>
                          Cancel
                        </Button>
                      )}
                      {hasFormBeenSubmitted && !props?.dirty && (
                        <Button
                          color="actionPrimary"
                          data-cy="button-close-form"
                          variant="contained"
                          onClick={() => navigate('/admin/user-management')}
                        >
                          Finish
                        </Button>
                      )}
                    </Stack>
                    <StatusMessage data-cy="status-message" $error={isError} $statusMessage={statusMessage}>
                      {statusMessage}
                    </StatusMessage>
                  </>
                }
              />
            </StyledForm>
          )}
        </Formik>
      )}
    </>
  );
};

const StyledForm = styled(Form)`
  padding: ${props => props.theme.customSpacing.px.base * 22}px;
  padding-top: ${props => props.theme.customSpacing.px.base * 10}px;
`;

const InputUsernamesSection = styled.div`
  display: flex;
  gap: ${props => props.theme.customSpacing.px.xs}px;
  justify-content: space-between;

  @media (max-width: 768px) {
    gap: 0px;
    flex-direction: column;
  }
`;

const StatusMessage = styled.div<{ $error: any; $statusMessage: string | undefined }>`
  color: ${props => (props.$error ? props.theme.colors.text.critical : props.theme.colors.text.success)};
  margin-top: ${props => props.theme.customSpacing.px.xs}px;
  padding: 0px;
  font-size: 0.75rem;
  font-weight: bold;
  display: ${props => (props.$statusMessage !== '' ? 'block' : 'none')};
`;

export default UserForm;
