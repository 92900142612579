import React, { ReactElement, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { GetSkillsStatsResponse } from 'types/types';
import TopSkillTypesTable from 'components/molecules/TopSkillTypesTable';
import SelectableCardWithPipContainer from 'components/molecules/SelectableCardWithPipContainer';
import PieChart, { PieChartDataProps } from 'components/charts/PieChart';

type TopSkillsTableChartProps = {
  skills: GetSkillsStatsResponse['skillBreakdowns'];
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

// index for the tab and the color
const topSkillsIndex = {
  topCommonSkills: 0,
  topSpecializedSkills: 1,
  topCertifications: 2,
};

const TopSkillsTableChart = ({ skills }: TopSkillsTableChartProps): ReactElement => {
  const theme = useTheme();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const onTabClickHandler = index => {
    setActiveTabIndex(index);
  };

  const getItemColor = index => {
    if (index === topSkillsIndex.topCommonSkills) return theme.colors.chart.variant1;
    if (index === topSkillsIndex.topSpecializedSkills) return theme.colors.chart.variant2;
    if (index === topSkillsIndex.topCertifications) return theme.colors.chart.variant3;
    else return theme.colors.chart.variant1;
  };

  const getCurrentActiveTabName = () => {
    if (activeTabIndex === topSkillsIndex.topCommonSkills) return 'common';
    if (activeTabIndex === topSkillsIndex.topSpecializedSkills) return 'specialized';
    if (activeTabIndex === topSkillsIndex.topCertifications) return 'certifications';
    else return 'common';
  };

  const activeTopSkills = () => {
    if (activeTabIndex === topSkillsIndex.topCommonSkills) return skills.topCommonSkills;
    if (activeTabIndex === topSkillsIndex.topSpecializedSkills) return skills.topSpecializedSkills;
    if (activeTabIndex === topSkillsIndex.topCertifications) return skills.topCertifications;
    else return skills.topCommonSkills;
  };

  const getSkillTabItems = () => {
    return [
      {
        title: 'Common Skills',
        value: skills.topCommonSkills.reduce<number>((prev, curr) => prev + curr.count, 0),
        color: getItemColor(topSkillsIndex.topCommonSkills),
        isActive: activeTabIndex === 0,
      },
      {
        title: 'Specialized Skills',
        value: skills.topSpecializedSkills.reduce<number>((prev, curr) => prev + curr.count, 0),
        color: getItemColor(topSkillsIndex.topSpecializedSkills),
        isActive: activeTabIndex === 1,
      },
      {
        title: 'Certifications',
        value: skills.topCertifications.reduce<number>((prev, curr) => prev + curr.count, 0),
        color: getItemColor(topSkillsIndex.topCertifications),
        isActive: activeTabIndex === 2,
      },
    ];
  };

  const totalSkillCount = getSkillTabItems().reduce<number>((prev, curr) => prev + curr.value, 0);

  const chartClickHandler = (item: PieChartDataProps) => {
    const itemIndex = () => {
      switch (item.id) {
        case 'Common Skills':
          return topSkillsIndex.topCommonSkills;
        case 'Specialized Skills':
          return topSkillsIndex.topSpecializedSkills;
        case 'Certifications':
          return topSkillsIndex.topCertifications;
        default:
          return topSkillsIndex.topCommonSkills;
      }
    };

    setActiveTabIndex(itemIndex());
  };

  return (
    <Wrapper>
      <div style={{ marginRight: `${theme.customSpacing.px.base * 8}px` }}>
        {Object.values(skills).reduce<number>((prev, curr) => prev + curr.length, 0) > 0 && (
          <PieChart
            data={getSkillTabItems().map(category => {
              return {
                id: category.title,
                label: category.title,
                tooltipText: `${category.title} (${Math.round((category.value / totalSkillCount) * 100)}%)`,
                value: category.value,
                isActive: true,
                color: category.color,
              };
            })}
            chartName="top-skills-by-category"
            sectionClickHandler={chartClickHandler}
          />
        )}
      </div>
      <div style={{ width: '100%' }}>
        <SelectableCardWithPipContainer items={getSkillTabItems()} onClickHandler={onTabClickHandler} />
        <TopSkillTypesTable
          topSkillTypes={activeTopSkills()}
          color={getItemColor(activeTabIndex)}
          sourceComponent={`skills-summary-${getCurrentActiveTabName()}`}
        />
      </div>
    </Wrapper>
  );
};

export default TopSkillsTableChart;
