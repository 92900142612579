import React, { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const TypographyMuiDemo = (): ReactElement<any> => {
  return (
    <>
      <Typography variant="subHeading" style={{ marginBottom: '10px', textDecoration: 'underline' }}>
        AUTO RESPONSIVE
      </Typography>
      <div>
        <Typography variant="displayXLarge" color="text.information">
          Display XLarge
        </Typography>
      </div>
      <div>
        <Typography variant="displayLarge">Display Large</Typography>
      </div>
      <div>
        <Typography variant="displayMedium" color="text.warning">
          Display Medium
        </Typography>
      </div>
      <div>
        <Typography variant="displaySmall" color="text.success">
          Display Small
        </Typography>
      </div>
      <div>
        <Typography variant="heading" color="text.subdued">
          Heading
        </Typography>
      </div>
      <div>
        <Typography variant="subHeading">SubHeading</Typography>
      </div>
      <div>
        <Typography variant="body">Body</Typography>
      </div>
      <div>
        <Typography variant="strong">Strong</Typography>
      </div>
      <div>
        <Typography variant="caption">Caption</Typography>
      </div>
      <Button variant="contained" color="actionPrimary">
        Button
      </Button>
      <br />
      <Button variant="text">Text Button</Button>
      <br />
      <Button variant="text" size="small">
        Text Button (small)
      </Button>
    </>
  );
};

export default TypographyMuiDemo;
