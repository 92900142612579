import batchLogService from 'services/batchLogService';
import useCompanyContext from 'helpers/UseCompanyContext';

type useBatchLogsReturnObject = {
  getBatchLogsFacets: (batchIds: string[]) => Promise<any>;
  getBatchLogFacets: (batchId: string) => Promise<any>;
};

const useBatchLogs = (): useBatchLogsReturnObject => {
  const { companyId } = useCompanyContext();

  const getBatchLogsFacets = async (batchIds: string[] = []) => {
    const statusFacets = await batchLogService.getBatchLogStats({ companyId, batchIds });
    return statusFacets;
  };

  const getBatchLogFacets = async (batchId: string) => {
    // getting status facets
    const statusFacetsResponse = await batchLogService.getBatchLogStats({ companyId, batchIds: [batchId] });
    const statusFacets = statusFacetsResponse.batchLogStats[0].statuses
      .filter(status => status.name !== 'total')
      .map(status => {
        return { key: status.name, value: status.name, count: status.count };
      });
    return { status: statusFacets };
  };

  return { getBatchLogsFacets, getBatchLogFacets };
};

export default useBatchLogs;
