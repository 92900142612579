import React, { ReactElement, useState, useEffect } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import useCompanyContext from 'helpers/UseCompanyContext';
import RolesService, { RoleStats } from 'services/RolesService';
import PieChart, { PieChartDataProps } from 'components/charts/PieChart';

const Wrapper = styled.div`
  padding: ${props => props.theme.customSpacing.px.base * 4}px;
`;

// TODO: Update roles service, skills stats once api is available
const D3ChartDemoPage = (): ReactElement => {
  const { companyId } = useCompanyContext();
  const [isSkillsStatsLoading, setIsSkillsStatsLoading] = useState(false);
  const [skillsStats, setSkillsStats] = useState<RoleStats>({
    totalRoles: 0,
    numberReviewed: 0,
    numberWithSkillProfiles: 0,
    topSkills: [],
  });
  const [skillsStatsFormat, setSkillStatsFormat] = useState<PieChartDataProps[]>([]);

  const chartDataFormat = (stats: any, activeId?: string) => {
    const formatedSkills = stats.map(skill => {
      return {
        id: skill.skillId,
        label: skill.skillName,
        tooltipText: `${skill.skillName} (${skill.count})`,
        value: skill.count,
        isActive: activeId ? skill.skillId === activeId : true,
        color: undefined,
      };
    });
    setSkillStatsFormat(formatedSkills);
  };

  const sectionClickHandler = (data: PieChartDataProps) => {
    console.log(`Chart Demo: Section has been clicked - ${data.label} (${data.id})`);
    chartDataFormat(skillsStats.topSkills, data.id);
  };

  useEffect(() => {
    (async () => {
      setIsSkillsStatsLoading(true);
      const stats = await RolesService.getRoleStats(companyId);
      setIsSkillsStatsLoading(false);
      setSkillsStats(stats);
      chartDataFormat(stats.topSkills);
    })();
  }, []);

  return (
    <>
      <MainContentTemplate pageTitle="D3 Chart Demo Page" isFullWidth={false}>
        <ContentHeaderTemplate contentTitle="D3 Chart Demo Page" />
        <Wrapper>
          {isSkillsStatsLoading && (
            <Typography sx={{ display: 'flex', justifyContent: 'center' }}>loading...</Typography>
          )}
          {!isSkillsStatsLoading && <pre>{JSON.stringify(skillsStats, null, 2)}</pre>}
          {!isSkillsStatsLoading && (
            <div style={{ display: 'inline-flex' }}>
              <PieChart data={skillsStatsFormat} chartName="top-skills" sectionClickHandler={sectionClickHandler} />
            </div>
          )}
        </Wrapper>
      </MainContentTemplate>
    </>
  );
};

export default D3ChartDemoPage;
