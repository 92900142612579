import type {} from '@mui/lab/themeAugmentation';
import { DefaultTheme } from 'styled-components';
declare module '@mui/x-data-grid/DataGrid' {}

export const muiDataGridOverride = (gs: DefaultTheme): Record<'defaultProps' | 'styleOverrides', any> => ({
  defaultProps: {
    disableColumnMenu: true,
  },
  styleOverrides: {
    root: {
      columnSeparator: {
        display: 'none',
      },
      borderWidth: '1px 0px 0px 0px',
      borderRadius: '0px',
      '& .MuiDataGrid-cell': {
        fontFamily: `${gs.customTypography.fontFamily}`,
        '&:focus-within': {
          outline: 'none',
        },
      },
      '& .MuiDataGrid-row': {
        '&:hover': {
          boxShadow: `${gs.boxShadow.surfaceSelected}`,
          backgroundColor: `${gs.colors.surface.neutralSubdued}`,
        },
      },
      // Supports the table to have padding and row lines extend to edge
      //* START *//
      '& .MuiDataGrid-columnHeader:first-child': {
        paddingLeft: `${gs.customSpacing.px.m}px`,
      },
      '& .MuiDataGrid-cell:first-child': {
        paddingLeft: `${gs.customSpacing.px.m}px`,
      },
      '& .MuiDataGrid-columnHeader:last-child': {
        paddingRight: `${gs.customSpacing.px.m}px`,
      },
      '& .MuiDataGrid-cell:last-child': {
        paddingRight: `${gs.customSpacing.px.m}px`,
      },
      '& .MuiCheckbox-root': {
        padding: '0px',
        '& svg': {
          height: '24px',
          width: '24px',
        },
        '&:not(.Mui-checked) svg': {
          color: `${gs.colors.border.default}`,
        },
        '&.Mui-checked svg': {
          color: `${gs.colors.interactive.default}`,
        },
        '&.Mui-disabled svg': {
          color: `${gs.colors.border.disabled}`,
        },
      },
      '& .MuiDataGrid-cellCheckbox': {
        minWidth: '65px !important',
        maxWidth: '65px !important',
        justifyContent: 'start',
      },
      '& .MuiDataGrid-columnHeaderCheckbox': {
        minWidth: '65px !important',
        maxWidth: '65px !important',
        justifyContent: 'start',
      },
      //* END *//
    },
    columnSeparator: {
      display: 'none',
    },
    columnHeaderTitle: {
      fontWeight: gs.customTypography.desktop.strong.weight,
    },
    cell: {
      fontWeight: gs.customTypography.desktop.body.weight,
    },
    row: {
      '&:hover': {
        backgroundColor: gs.colors.surface.neutralSubdued,
      },
    },
    checkboxInput: {
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },
  },
});
