import React, { ReactElement } from 'react';
import styled from 'styled-components';

type ArrowHeadProps = {
  flip: boolean;
  horizontal?: boolean;
  clickHandler?: () => void;
  style?: React.CSSProperties;
  widthInPixels?: string;
};

const Container = styled.span<{ rotation: string }>`
  display: inline-block;
  cursor: pointer;

  svg {
    transform: rotate(${({ rotation }) => rotation});
    transition: all 200ms;
    transform-origin: center center;
  }
`;

const ArrowHead = ({
  flip = true,
  horizontal = false,
  clickHandler,
  style,
  widthInPixels = '24',
}: ArrowHeadProps): ReactElement => {
  let rotation = flip ? '180deg' : '0deg';
  horizontal && (rotation = '-90deg');
  return (
    <Container style={style} rotation={rotation} onClick={clickHandler}>
      <svg
        width={widthInPixels}
        height={widthInPixels}
        viewBox={`0 0 ${widthInPixels} ${widthInPixels}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16 10H8L12 15L16 10Z" fill="#8B8D91" />
      </svg>
    </Container>
  );
};

export default ArrowHead;
