import axios from 'helpers/api_helper';

const getTitlesFromAutocomplete = async (q: string): Promise<any> => {
  const encodedQuery = encodeURIComponent(q);
  const res = await axios.get(`${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/titles/autocomplete?q=${encodedQuery}`);
  return res;
};

const getTitlesBySearch = async (
  searchRequest: { skills?: string[]; keywords?: string },
  limit: number,
  requestController: AbortController
): Promise<any> => {
  const url = `${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/titles/search`;
  const res = await axios.post<any>(url, { ...searchRequest, limit }, { signal: requestController.signal });
  return res;
};

const normalizeTitle = async (term = '', limit = 10, requestController: AbortController): Promise<any> => {
  if (term === '') return [];

  const url = `${process.env.REACT_APP_API_MICRO_EMSI_ROOT}/titles/normalize`;
  const res = await axios.post<any>(url, { term, limit }, { signal: requestController.signal });
  return res.data;
};

export default {
  getTitlesFromAutocomplete,
  getTitlesBySearch,
  normalizeTitle,
};
