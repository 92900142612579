import React, { ReactElement, useState } from 'react';
import SidePanelTemplate from 'pages/templates/SidePanelTemplate';
import Button from '@mui/material/Button';
import { DrawerProps } from '@mui/material';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const DrawerDemo = (): ReactElement => {
  const [isEnabled, setIsEnabled] = useState(false);
  const drawerProps: DrawerProps = { variant: 'temporary', anchor: 'right', open: isEnabled };
  const ActionComponent = (): ReactElement => {
    return (
      <>
        <Button variant="outlined" onClick={() => setIsEnabled(false)}>
          Cancel
        </Button>
      </>
    );
  };
  const TitleComponent = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <BaseIcon type={IconType.ArrowLeftRightDarkBg} />
        <Typography variant="h4">Role Comparison</Typography>
      </div>
    );
  };

  const columns: GridColDef<any>[] = [
    { field: 'roleName', headerName: 'Role Name', flex: 1, headerAlign: 'center', align: 'center' },
    { field: 'skillOverlaps', headerName: 'Skill Overlaps', flex: 1, headerAlign: 'center', align: 'center' },
  ];

  const rows = [
    { id: 1, roleName: 'Developer', skillOverlaps: 5 },
    { id: 2, roleName: 'Designer', skillOverlaps: 3 },
    { id: 3, roleName: 'Project Manager', skillOverlaps: 2 },
    { id: 4, roleName: 'Data Analyst', skillOverlaps: 4 },
    { id: 5, roleName: 'Tester', skillOverlaps: 5 },
    { id: 6, roleName: 'Marketing Specialist', skillOverlaps: 1 },
    { id: 7, roleName: 'Sales Executive', skillOverlaps: 0 },
    { id: 8, roleName: 'HR Manager', skillOverlaps: 1 },
    { id: 9, roleName: 'Finance Analyst', skillOverlaps: 2 },
    { id: 10, roleName: 'Customer Support', skillOverlaps: 3 },
  ];

  const MyDataGrid = () => {
    return (
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid rows={rows} columns={columns} pageSize={5} />
      </div>
    );
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Button onClick={() => setIsEnabled(!isEnabled)}>{isEnabled ? 'Disable Drawer' : 'Enable Drawer'}</Button>
      <SidePanelTemplate
        headerTitle={<TitleComponent />}
        headerActions={<ActionComponent />}
        displayHeaderDivider={false}
        width={792}
        drawerProps={drawerProps}
      >
        <MyDataGrid />
      </SidePanelTemplate>
    </div>
  );
};

export default DrawerDemo;
