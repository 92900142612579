import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import Button from '@mui/material/Button';

const BreadCrumbsWrapper = styled.div`
  margin: ${props => props.margin};
  margin-top: 2rem;
  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
`;

const BreadCrumbs = ({
  currentPageName,
  previousPageName,
  previousPagePath,
  width = '90%',
  maxWidth = '1160px',
  margin = 'auto',
  dataCy = 'nav-previous-page',
}) => {
  const navigate = useNavigate();

  return (
    <>
      <BreadCrumbsWrapper maxWidth={maxWidth} width={width} margin={margin}>
        <Button variant="text" onClick={() => navigate(`${previousPagePath}`)} data-cy={dataCy}>
          {previousPageName}
        </Button>
        <BaseIcon type={IconType.ChevronRight} size="10px" />
        {currentPageName}
      </BreadCrumbsWrapper>
    </>
  );
};

BreadCrumbs.propTypes = {
  currentPageName: PropTypes.string.isRequired,
  previousPageName: PropTypes.string.isRequired,
  previousPagePath: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
};

export default BreadCrumbs;
