import { AxiosError } from 'axios';
import axios from 'helpers/api_helper';
import { ApiResponseData, CustomSkill, ApiErrorResponse } from 'types/types';
import * as Sentry from '@sentry/react';

type ApiCustomSkill = CustomSkill & ApiResponseData;

const createCustomSkill = async (
  companyId: string,
  customSkill: Omit<CustomSkill, 'customSkillId'>
): Promise<CustomSkill> => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/custom-skills`;
  try {
    const { data } = await axios.post<CustomSkill, { data: ApiCustomSkill }>(url, customSkill);
    return {
      customSkillId: data.customSkillId,
      skillName: data.skillName,
      skillSource: data.skillSource,
      skillId: data.skillId,
    };
  } catch (error: unknown) {
    const axiosError = error as AxiosError;
    Sentry.captureException(axiosError);
    const apiError = axiosError.response?.data as ApiErrorResponse;
    throw new Error(apiError?.errors?.[0].message || 'An error occurred. Please try again');
  }
};

const updateCustomSkill = async (
  companyId: string,
  customSkillId: string,
  customSkill: Partial<CustomSkill>
): Promise<CustomSkill> => {
  delete customSkill.customSkillId;
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/custom-skills/${customSkillId}`;
  try {
    const { data } = await axios.patch<Partial<CustomSkill>, { data: ApiCustomSkill }>(url, customSkill);
    return {
      customSkillId: data.customSkillId,
      skillName: data.skillName,
      skillSource: data.skillSource,
      skillId: data.skillId,
    };
  } catch (error: unknown) {
    const axiosError = error as AxiosError;
    Sentry.captureException(axiosError);
    const apiError = axiosError.response?.data as ApiErrorResponse;
    throw new Error(apiError?.errors?.[0].message || 'An error occurred. Please try again');
  }
};

const getAllCustomSkills = async (companyId: string) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/custom-skills`;
  try {
    const { data } = await axios.get<ApiCustomSkill[]>(url);
    return data.map(
      (apiCustomSkill): CustomSkill => ({
        customSkillId: apiCustomSkill.customSkillId,
        skillName: apiCustomSkill.skillName,
        skillSource: apiCustomSkill.skillSource,
        skillId: apiCustomSkill.skillId,
      })
    );
  } catch (error: unknown) {
    const axiosError = error as AxiosError;
    Sentry.captureException(axiosError);
    const apiError = axiosError.response?.data as ApiErrorResponse;
    throw new Error(apiError?.errors?.[0].message || 'An error occurred. Please try again');
  }
};

const deleteCustomSkill = async (companyId: string, customSkillId: string) => {
  const url = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/custom-skills/${customSkillId}`;
  try {
    await axios.delete(url);
    return true;
  } catch (error: unknown) {
    const axiosError = error as AxiosError;
    Sentry.captureException(axiosError);
    const apiError = axiosError.response?.data as ApiErrorResponse;
    throw new Error(apiError?.errors?.[0].message || 'An error occurred. Please try again');
  }
};

export default {
  createCustomSkill,
  updateCustomSkill,
  deleteCustomSkill,
  getAllCustomSkills,
};
