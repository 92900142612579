/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'helpers/api_helper';
import ReactGA from 'react-ga';
import { bucketizeTime, bucketizeTimeLong } from 'helpers/analyticsHelper';
import SkillProfile from 'classes/skillProfile';
import { BenchmarkSkillMatchPercentage, SkillPostingsPercentage } from 'types/types';
import RolesService from 'services/RolesService';

type GetSkillProfilesParameters = {
  companyId: string;
  fields?: any;
  filters?: any;
  limit?: number;
  offset?: number;
  sortColumn?: string;
  sortDirection?: string;
  searchTerms?: any;
};

type GetSkillProfileParameters = {
  companyId: string;
  roleId: string;
};

type GetSkillProfilesResponse = {
  skillProfiles: SkillProfile[];
  totalCount: number;
};

type GetSkillProfileResponse = {
  skillProfile: SkillProfile;
  totalCount: number;
};

type GetSkillProfilesBenchmarkMatchBySkillsResponse = {
  benchmarkSkillsMatchPercentage: SkillPostingsPercentage[];
  hasBenchmark: boolean;
};

const getSkillProfiles = async (params: GetSkillProfilesParameters): Promise<GetSkillProfilesResponse> => {
  const startTime = performance.now();

  params.filters &&
    Object.keys(params.filters).forEach(key => {
      if (params.filters[key].length === 0) {
        delete params.filters[key];
      }
    });

  const url = `${process.env.REACT_APP_API_ROOT}/companies/${params.companyId}/skills-profile`;
  const resp = await axios.post<GetSkillProfilesResponse>(url, params);

  const skillProfiles = resp.data?.skillProfiles || [];
  const totalCount = resp.data?.totalCount || 0;

  const endTime = performance.now();

  // track num roles
  ReactGA.event({
    category: 'roles',
    action: 'loading',
    label: `num roles: ${skillProfiles.length}`,
  });

  ReactGA.event({
    category: 'roles',
    action: 'loading',
    label: `page size: ${params.limit}`,
  });

  // track load time
  ReactGA.event({
    category: 'roles',
    action: 'loading',
    label: `load time (${params.limit}): ${bucketizeTime(endTime - startTime)}`,
  });

  return { skillProfiles, totalCount };
};

const getSkillProfile = async (params: GetSkillProfileParameters): Promise<GetSkillProfileResponse> => {
  const { companyId, roleId } = params;

  const { skillProfiles, totalCount } = await getSkillProfiles({ companyId, filters: { roleId } });
  const skillProfile = skillProfiles?.[0];

  return { skillProfile, totalCount };
};

const getSkillProfilesBenchmarkMatch = async (
  companyId: string,
  skillProfiles: SkillProfile[]
): Promise<BenchmarkSkillMatchPercentage[]> => {
  const startTime = performance.now();
  const requestUrl = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/skills-profile/benchmarkSkillsMatch`;
  const requestBody = {
    roleIds: Array.from(skillProfiles, skillProfile => skillProfile.roleId),
  };
  const benchmarkSkillsMatch = await axios.post(requestUrl, requestBody);
  const endTime = performance.now();

  ReactGA.event({
    category: 'skillProfiles',
    action: 'benchmark match',
    label: `load time (${skillProfiles.length}): ${bucketizeTimeLong(endTime - startTime)}`,
  });

  return benchmarkSkillsMatch.data;
};

const getSkillProfileBenchmarkMatchBySkills = async (
  companyId: string,
  roleId: string,
  limit = 50
): Promise<GetSkillProfilesBenchmarkMatchBySkillsResponse> => {
  const requestUrl = `${process.env.REACT_APP_API_ROOT}/companies/${companyId}/skills-profile/benchmarkSkillsMatch/${roleId}/skills?limit=${limit}`;
  const benchmarkSkillsMatch = await axios.get(requestUrl);
  return benchmarkSkillsMatch.data;
};

const addSkills = async (companyId, roleId, skills) => {
  const success = await RolesService.addRoleSkills(companyId, roleId, skills);

  return success;
};

export default {
  getSkillProfiles,
  getSkillProfile,
  getSkillProfilesBenchmarkMatch,
  getSkillProfileBenchmarkMatchBySkills,
  addSkills,
};
