import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import CategoryBreakdownProgressBar from 'components/molecules/CategoryBreakdownProgressBar';
import CircularProgress from '@mui/material/CircularProgress';
import SelectableCardWithPipContainer from 'components/molecules/SelectableCardWithPipContainer';
import SidePanelTemplate from 'pages/templates/SidePanelTemplate';
import PipIcon, { PipIconType } from 'components/atoms/PipIcon';
import { Typography, DrawerProps, Button } from '@mui/material';
import BaseIcon, { IconType } from 'components/atoms/BaseIcon';
import TextWithSubText from 'components/atoms/TextWithSubText';
import SummaryHeader from 'components/molecules/SummaryHeader';
import styled, { useTheme } from 'styled-components';
import skillProfile, { Skill } from 'classes/skillProfile';
import UniqueSkillsTable from './UniqueSkillsTable';
import SharedSkillsTable from './SharedSkillsTable';
import { CategoryCount } from 'types/types';
import Role from 'classes/role';
type SimilarRoleSkillsProps = {
  role: Role;
  comparedRole: skillProfile;
  showSimilarRoleSkills: boolean;
  setShowSimilarRoleSkills: React.Dispatch<React.SetStateAction<boolean>>;
  occPipFillColor?: string;
};

type SkillsStats = {
  sharedSkills: Skill[];
  uniqueSkillsA: Skill[];
  uniqueSkillsB: Skill[];
  postingDifference: number; // update when implemented
  sharedSkillsPercentage: number;
};

const SubHeaderTextContainer = styled.div`
  display: flex;
`;

const SubHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: ${props => props.theme.customSpacing.px.s}px;
`;

const SimilarRoleSkills = ({
  role,
  comparedRole,
  showSimilarRoleSkills,
  setShowSimilarRoleSkills,
  occPipFillColor,
}: SimilarRoleSkillsProps): ReactElement => {
  const theme = useTheme();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [skillCategoryAggregates, setSkillCategoryAggregates] = useState<CategoryCount[]>([]);

  const skillsStats: SkillsStats = useMemo(() => {
    const sharedSkills = role.skills.filter(skill =>
      comparedRole.skills.some(comparedSkill => skill.skillId === comparedSkill.skillId)
    );
    return {
      sharedSkills,
      uniqueSkillsA: role.skills.filter(
        skill => !comparedRole.skills.some(comparedSkill => skill.skillId === comparedSkill.skillId)
      ),
      uniqueSkillsB: comparedRole.skills.filter(
        skill => !role.skills.some(comparedSkill => skill.skillId === comparedSkill.skillId)
      ),
      postingDifference: 0,
      sharedSkillsPercentage: Math.round((sharedSkills.length * 100) / role.skills.length),
    };
  }, [role, comparedRole]);

  useEffect(() => {
    const skills =
      activeTabIndex === 0 ? skillsStats.sharedSkills : activeTabIndex === 1 ? skillsStats.uniqueSkillsA : [];
    const skillTypeHash = skills.reduce<Record<string, CategoryCount>>((accumulator, currentSkill) => {
      const categoryId: string | undefined = currentSkill.typeId;
      const categoryType: string | undefined = currentSkill.typeName;

      if (!categoryType || !categoryId) return accumulator;

      if (accumulator[categoryType]) {
        accumulator[categoryType].count++;
      } else {
        accumulator[categoryType] = {
          categoryId: Number(categoryType),
          categoryName: categoryType,
          count: 1,
        };
      }
      return accumulator;
    }, {});
    const skillCountByType: CategoryCount[] = Object.values(skillTypeHash);
    setSkillCategoryAggregates(skillCountByType);
  }, [skillsStats, activeTabIndex]);

  const TitleComponent = () => {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <BaseIcon type={IconType.ArrowLeftRightDarkBg} />
          <Typography variant="h5">Role Comparison</Typography>
        </div>
      </div>
    );
  };

  const ActionComponent = (): ReactElement => {
    return (
      <Button variant="outlined" onClick={() => setShowSimilarRoleSkills(false)}>
        Cancel
      </Button>
    );
  };

  const drawerProps: DrawerProps = { variant: 'temporary', anchor: 'right', open: showSimilarRoleSkills };

  const tabItems = [
    {
      title: 'Shared skills',
      value: skillsStats.sharedSkills.length,
      isActive: activeTabIndex === 0,
      children: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '12px' }}>
            <CircularProgress
              variant="determinate"
              size={24}
              thickness={6}
              value={skillsStats.sharedSkillsPercentage}
              color="success"
            />
          </div>
          <Typography variant="subtitle1">{skillsStats.sharedSkillsPercentage}%</Typography>
        </div>
      ),
    },
    {
      title: 'Unique Skills',
      value: skillsStats.uniqueSkillsA.length + skillsStats.uniqueSkillsB.length,
      isActive: activeTabIndex === 1,
    },
  ];

  const onTabClickHandler = index => {
    setActiveTabIndex(index);
  };

  return (
    <SidePanelTemplate
      headerTitle={<TitleComponent />}
      headerActions={<ActionComponent />}
      displayHeaderDivider={true}
      width={792}
      drawerProps={drawerProps}
    >
      <SubHeaderContainer>
        <SubHeaderTextContainer style={{ flexBasis: '35%' }}>
          <TextWithSubText subText="Role A" text={role?.roleName} isSubTextOnTop={true} />
        </SubHeaderTextContainer>
        <SubHeaderTextContainer style={{ flexBasis: '10%' }}>
          <BaseIcon type={IconType.ArrowLeftRight} />
        </SubHeaderTextContainer>
        <SubHeaderTextContainer style={{ flexBasis: '35%' }}>
          <TextWithSubText subText="Role B" text={comparedRole?.roleName} isSubTextOnTop={true} />
        </SubHeaderTextContainer>
        <SubHeaderTextContainer style={{ flexBasis: '20%' }}>
          <PipIcon
            type={PipIconType.full}
            fillColor={occPipFillColor}
            width="16"
            height="16"
            margins={`0px ${theme.customSpacing.px.base * 2}px 0px 0px`}
          />
          <Typography component="span">{comparedRole?.roleName}</Typography>
        </SubHeaderTextContainer>
      </SubHeaderContainer>
      <SelectableCardWithPipContainer items={tabItems} onClickHandler={onTabClickHandler} />
      <SummaryHeader headerTitle="Skill Categories">
        {activeTabIndex === 0 && (
          <CategoryBreakdownProgressBar width={'100%'} categoryCountList={skillCategoryAggregates} showLabel={true} />
        )}
      </SummaryHeader>
      {activeTabIndex === 0 && (
        <SharedSkillsTable sharedSkills={skillsStats.sharedSkills} comparedSkills={comparedRole.skills} />
      )}
      {activeTabIndex === 1 && (
        <UniqueSkillsTable skillsA={skillsStats.uniqueSkillsA} skillsB={skillsStats.uniqueSkillsB} />
      )}
    </SidePanelTemplate>
  );
};

export default SimilarRoleSkills;
