import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';

type IntegrationOptionProps = {
  id: string;
  title: string;
  loading: boolean;
  loadingIndicator: string;
  description: string;
  onClickHandler: (vendorId: string) => void;
  dataCy?: string;
  buttonText: string;
};

const RowItem = styled.div`
  padding: ${props => `${props.theme.customSpacing.px.base * 4}px`} 0px;
  margin: ${props => `0px ${props.theme.customSpacing.px.m}px`};
  border-bottom: 1px solid ${props => props.theme.colors.border.subdued};

  &:last-child {
    border-bottom: none;
  }

  &:hover svg path {
    fill: ${props => props.theme.colors.interactive.default};
  }

  @media (max-width: 768px) {
    margin: 0px;
  }
`;

const IntegrationOption = ({
  id,
  title,
  loading,
  loadingIndicator,
  description,
  onClickHandler,
  dataCy,
  buttonText,
}: IntegrationOptionProps): ReactElement => {
  const theme = useTheme();
  return (
    <RowItem theme={theme} key={`${id}-${title}`} data-cy={dataCy}>
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={12} sm={7}>
              <Typography variant="heading">{title}</Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography color="text.subdued">{description}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
          <LoadingButton
            loading={loading}
            loadingIndicator={loadingIndicator}
            onClick={async () => await onClickHandler(id)}
          >
            {buttonText}
          </LoadingButton>
        </Grid>
      </Grid>
    </RowItem>
  );
};

export default IntegrationOption;
