import cloneDeep from 'lodash/cloneDeep';
class Benchmark {
  constructor({ companyId, name, locations = [], industries = [], companies = [], tags = [] }) {
    this.companyId = companyId;
    this.name = name;
    this.locations = locations;
    this.companies = companies;
    this.industries = industries;
    this.tags = tags;
  }

  static fromJson(benchmarkJson) {
    const baseObj = new Benchmark({});
    return { ...baseObj, ...cloneDeep(benchmarkJson) };
  }
}

export default Benchmark;
