import React, { useState, useEffect } from 'react';
import ContentHeaderTemplate from 'pages/templates/ContentHeaderTemplate';
import MainContentTemplate from 'pages/templates/MainContentTemplate';
import UseCompanyContext from 'helpers/UseCompanyContext';
import EventLogsService from 'services/EventLogsService';
import { DataGrid } from '@mui/x-data-grid';
import EventLog from 'classes/eventLog';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import queryString from 'query-string';
import ReactGA from 'react-ga';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';
import TablePagination from 'components/molecules/TablePagination';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import * as ExportService from 'services/ExportService';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
  flex-grow: 1;
`;

const Pagination = styled(Box)`
  box-shadow: ${props => props.theme.boxShadow.surface1};
  padding: 0px ${props => props.theme.customSpacing.px.base * 10}px;
`;

const RoleLink = styled.span`
  color: ${props => props.theme.colors.interactive.default};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const EventLogPage = () => {
  const { companyId } = UseCompanyContext();
  const navigate = useNavigate();
  const qs = queryString.parse(window.location.search);
  const [currentPage, setCurrentPage] = useState((qs.p && parseInt(qs.p)) || 0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loadingLogs, setLoadingLogs] = useState(true);
  const [logs, setLogs] = useState([]);
  const [totalEventLogCount, setTotalEventLogCount] = useState(0);
  const [exportButtonLabel, setExportButtonLabel] = useState('Export');
  const [isExportInProgress, setIsExportInProgress] = useState(false);

  const eventDateFormat = 'M/D/YY';

  useEffect(() => {
    const getEventLogs = async () => {
      setLoadingLogs(true);
      await loadEventLogs();
      setLoadingLogs(false);
    };
    getEventLogs();
  }, [companyId, currentPage, rowsPerPage]);

  const loadEventLogs = async () => {
    const logData = await EventLogsService.getEventLogs(companyId, rowsPerPage, currentPage * rowsPerPage);
    setTotalEventLogCount(logData.eventLogsTotal);
    setLogs(
      logData.eventLogs.map(log => {
        const eventLog = new EventLog(log);
        return {
          id: eventLog.logId,
          date: dayjs(eventLog.eventTime).format(eventDateFormat),
          section: eventLog.eventObjectType,
          category: startCase(eventLog.getReadableEventType()),
          name:
            !eventLog.afterChangeNull() && eventLog.isCreated()
              ? { id: eventLog.eventObjectId, name: eventLog.getReadableTo() }
              : { id: null, name: eventLog.getReadableTo() },
          user: eventLog.eventBy,
        };
      })
    );
  };

  const handleRoleClick = roleId => {
    ReactGA.event({
      category: 'event-log',
      action: 'navigate',
      label: 'navigated to role detail',
    });

    navigate(`/role-edit/${roleId}?rf=&af={}`);
  };

  const pageChangeHandler = page => {
    setCurrentPage(page);
  };

  const pageSizeChange = newPageSize => {
    setRowsPerPage(newPageSize);
  };

  const exportEventLogs = async () => {
    setExportButtonLabel('Exporting...');
    setIsExportInProgress(true);
    try {
      const success = await ExportService.triggerExportEventLogs(companyId);
      
      exportTriggeredToast(success);
    } catch (e) {
      exportTriggeredToast(false);
    } finally {
      setExportButtonLabel('Export');
      setIsExportInProgress(false);
    }
  };

  const exportTriggeredToast = (success) => {
    const message = success ?
      `Your activity logs file is exporting. You will receive an email with a link to the file when the export is complete.` :
      `There was an error exporting your activity logs. Please try again.`;
    const toastContainer = <div><span data-cy="file-export-triggered-toast">{message}</span></div>;

    if (success) {
      toast.success(toastContainer)
    } else {
      toast.error(toastContainer);
    }
  }

  const columns = [
    { field: 'date', headerName: 'Date', flex: 0.2, headerAlign: 'center', align: 'center' },
    { field: 'section', headerName: 'Section', flex: 0.2 },
    { field: 'category', headerName: 'Category', flex: 0.4 },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: params => {
        return params.value.id === null ? (
          <span style={{ whiteSpace: 'pre-wrap' }}>{params.value.name}</span>
        ) : (
          <RoleLink style={{ whiteSpace: 'pre-wrap' }} onClick={() => handleRoleClick(params.value.id)}>
            {params.value.name}
          </RoleLink>
        );
      },
    },
    { field: 'user', headerName: 'User', flex: 1 },
  ];

  return (
    <MainContentTemplate pageTitle="Company Event Logs">
      <ContentHeaderTemplate
        contentTitle="Activity Log"
        actionsComponent={
          <Button
            variant="outlined"
            onClick={() => exportEventLogs()}
            disabled={totalEventLogCount === 0 || isExportInProgress}
            data-cy="eventlog-export-button"
          >
            {exportButtonLabel}
          </Button>
        }
      />
      <Wrapper>
        <DataGrid
          rows={logs}
          columns={columns}
          loading={loadingLogs}
          sx={{
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
          pagination
          paginationMode="server"
          onPageChange={pageChangeHandler}
          rowCount={totalEventLogCount}
          pageSize={rowsPerPage}
          onPageSizeChange={pageSizeChange}
          rowsPerPageOptions={[10, 50, 100]}
          hideFooterPagination={true}
          hideFooter={true}
          autoHeight
          className="event-log-table"
        />
        <Pagination>
          <TablePagination
            dataCy="event-log-table-pagination"
            total={totalEventLogCount}
            page={currentPage}
            setPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Pagination>
      </Wrapper>
    </MainContentTemplate>
  );
};

export default EventLogPage;
