const bucketize = (itemCount: number): string => {
  if (itemCount === 0) {
    return '0';
  } else if (itemCount >= 1 && itemCount <= 5) {
    return '1-5';
  } else if (itemCount >= 6 && itemCount <= 10) {
    return '6-10';
  } else if (itemCount >= 11 && itemCount <= 20) {
    return '11-20';
  } else if (itemCount >= 21 && itemCount <= 50) {
    return '21-50';
  } else if (itemCount >= 51) {
    return '51+';
  } else return '';
};

const bucketizeTime = (timeInMiliseconds: number): string => {
  if (timeInMiliseconds === 0) {
    return '0ms';
  } else if (timeInMiliseconds >= 1 && timeInMiliseconds <= 10) {
    return '1-10ms';
  } else if (timeInMiliseconds >= 11 && timeInMiliseconds <= 50) {
    return '11-50ms';
  } else if (timeInMiliseconds >= 51 && timeInMiliseconds <= 100) {
    return '51-100ms';
  } else if (timeInMiliseconds >= 101 && timeInMiliseconds <= 250) {
    return '101-250ms';
  } else if (timeInMiliseconds >= 251 && timeInMiliseconds <= 500) {
    return '251-500ms';
  } else if (timeInMiliseconds >= 501 && timeInMiliseconds <= 1000) {
    return '0.5-1s';
  } else if (timeInMiliseconds >= 1001 && timeInMiliseconds <= 2000) {
    return '1-2s';
  } else if (timeInMiliseconds >= 2001 && timeInMiliseconds <= 5000) {
    return '2-5s';
  } else if (timeInMiliseconds >= 5001) {
    return '5+s';
  } else return '';
};

const bucketizeTimeLong = (timeInMiliseconds: number): string => {
  if (timeInMiliseconds === 0) {
    return '0ms';
  } else if (timeInMiliseconds >= 0 && timeInMiliseconds <= 500) {
    return '< 500ms';
  } else if (timeInMiliseconds >= 501 && timeInMiliseconds <= 1000) {
    return '0.5-1s';
  } else if (timeInMiliseconds >= 1001 && timeInMiliseconds <= 2000) {
    return '1-2s';
  } else if (timeInMiliseconds >= 2001 && timeInMiliseconds <= 5000) {
    return '2-5s';
  } else if (timeInMiliseconds >= 5001 && timeInMiliseconds <= 10000) {
    return '5-10s';
  } else if (timeInMiliseconds >= 10001 && timeInMiliseconds <= 20000) {
    return '10-20s';
  } else if (timeInMiliseconds >= 20001 && timeInMiliseconds <= 30000) {
    return '20-30s';
  } else if (timeInMiliseconds >= 30001) {
    return '30+s';
  }
  return '';
};

export { bucketize, bucketizeTime, bucketizeTimeLong };
